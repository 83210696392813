import { render, staticRenderFns } from "./productPictureUpload.vue?vue&type=template&id=2ba5fb4d"
import script from "./productPictureUpload.ts?vue&type=script&lang=js&external"
export * from "./productPictureUpload.ts?vue&type=script&lang=js&external"
import style0 from "./productPictureUpload.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports