import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import i18n from "@/i18n";
import DropDownInput from "@/components/general/drop-down-input/dropDownInput.vue";

import VuePdfApp from "vue-pdf-app";

// Components
import Multiselect from '@/components/input/multiselect.vue';

// Helper
import { NotificationHelper } from "@/helpers/notificationHelper";

// Services
import { SupplierService } from '@/services/supplierService';

// Models
import { UserModel } from "@/models/userModel";
import { SupplierModel } from "@/models/supplierModel";
import { ProductQualityReportModel } from "@/models/productQualityReportModel";
import { DropdownModel } from "@/models/dropdownModel";
import { QualityReportResponseModel } from "@/models/qualityReportResponseModel";
import { mapState } from "vuex";

@Component({
  components: { VuePdfApp, DropDownInput, Multiselect },
  computed: mapState(['companyType'])
})
export default class UploadQualityReports extends Vue {

  @Prop()
  private supplier!: SupplierModel;

  @Prop()
	private issuersList!: DropdownModel;

  @Prop()
  private brands!: DropdownModel[];

  private tabIndex: number = 0;
  private otherCertName: string = "";
  private currentView: string = "";
  private disableUpload: boolean = true;
  private isUploading: boolean = false;
  private certificateType: string = "";

  private socialTypenames: string[] = Object.values(i18n.t('enums.social_certificates.social'));

  private sustainableTypenames: string[] = Object.values(i18n.t('enums.sustainability_certificates.sustainability'));

  private pdfDocument: any = {};

  private uploadReport: any = { files: [], description: "", brand: "", referenceissuer: "", issuingcompany: "", validtoDate: "", validfromDate: "" };
  private isUploadError: any = { files: false, description: false, brand: false, referenceissuer: false, issuingcompany: false, validtoDate: false, validfromDate: false };

  private geoLat!: string;
  private geoLong!: string;
  private fromDateFormat!: string;
  private toDateFormat!: string;
  private productGroupList: DropdownModel[] = [{"value": "testing1", "text": "testing text"}, {"value": "testing2", "text": "testing text"}, {"value": "testing1", "text": "testing text"}, {"value": "testing1", "text": "testing text"}, {"value": "testing1", "text": "testing text"}, {"value": "testing1", "text": "testing text"}, {"value": "testing1", "text": "testing text"}, {"value": "testing1", "text": "testing text"}, {"value": "testing1", "text": "testing text"}];

  private reportService: SupplierService;

  private brandList: DropdownModel[] = [];
  private selectedBrands: DropdownModel[] = [];
  private selectedBrandsArr: string[] = [];
  private companyType!: number;

  public constructor() {
    super();
    this.currentView = "upload";
    this.reportService = new SupplierService();
    this.fromDateFormat = this.$t('pages.supply_chain_partners.upload_cert_valid_from').toString();
    this.toDateFormat = this.$t('pages.supply_chain_partners.upload_cert_valid_to').toString();
  }

  private async created(): Promise<void> {
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(async (position) => {
        this.geoLat = position.coords.latitude.toString();
        this.geoLong = position.coords.longitude.toString();
      });
    }
    this.brands.splice(0,1);
    this.brandList =this.brands;
  }

  private valueSelected(item: DropdownModel) {
    this.uploadReport.issuingcompany = item.text;
  }

  private gotoBottom(): void {
    var element = document.getElementById("scrolldiv") as HTMLElement;
    element.scrollTop = element.scrollHeight * 0.90;
  }

  private async switchTab(tabIndex: number): Promise<void> {
    this.tabIndex = tabIndex;
    this.setIssuer(0, "");
    this.otherCertName = "";
  }

  private async updateDisableUpload(): Promise<void> {
    this.disableUpload = (this.isUploadError.files || this.isUploadError.description || this.isUploadError.referenceissuer || this.isUploadError.issuingcompany || this.isUploadError.validtoDate || this.isUploadError.validfromDate);
    if(!this.disableUpload && this.companyType !== 5) {
      this.disableUpload = this.isUploadError.brand;
    }
  }

  private onBrandSelect(brands: string[]): void {
    this.selectedBrandsArr = brands;
    this.selectedBrands = [];
    brands.forEach(brand=>{
      this.selectedBrands.push({value: brand, text: brand});
    });
    this.checkForUploadErrors("brand");
  }

  private async checkAllFieldsForErrors(): Promise<boolean> {
    await this.checkForUploadErrors("files");
    this.checkForUploadErrors("description");
    this.checkForUploadErrors("referenceissuer");
    this.checkForUploadErrors("brand");
    this.checkForUploadErrors("issuingcompany");
    this.checkForUploadErrors("validfromDate");
    this.checkForUploadErrors("validtoDate");

    this.disableUpload = (this.isUploadError.files || this.isUploadError.description || this.isUploadError.geoLat || this.isUploadError.geoLong || this.isUploadError.issuer || this.isUploadError.referenceissuer || this.isUploadError.validtoDate || this.isUploadError.validfromDate || this.isUploadError.rspholder || this.isUploadError.auditresult || this.isUploadError.amforiid || this.isUploadError.amforisiteid || this.isUploadError.oekoclass);
    return this.disableUpload;
  }

  private async checkForUploadErrors(field: string): Promise<void> {
    this.disableUpload = true;
    switch (field) {
      case "files":
        this.isUploadError.files = false;
        if (this.uploadReport.files.length == 1 && this.uploadReport.files[0].type.includes("pdf")) {
          this.pdfDocument = this.uploadReport.files[0].file;
          if (this.currentView == "viewpdf" && this.uploadReport.files.length == 1) {
            this.pdfDocument = await this.uploadReport.files[0].arrayBuffer();
          }
          this.updateDisableUpload();
          this.checkAll();
        } else {
          this.isUploadError.files = true;
        }
        break;
      case "description":
        this.isUploadError.description = false;
        if (this.uploadReport.description.trim() === "") {
          this.isUploadError.description = true;
        } else {
          this.updateDisableUpload();
          this.checkAll();
        }
        break;
      case "brand":
        this.isUploadError.brand = false;
        if (this.selectedBrandsArr.length==0) {
          this.isUploadError.brand = true;
        } else {
          this.updateDisableUpload();
          this.checkAll();
        }
        break;
      case "referenceissuer":
        this.isUploadError.referenceissuer = false;
        if (this.uploadReport.referenceissuer.trim() === "") {
          this.isUploadError.referenceissuer = true;
        } else {
          this.updateDisableUpload();
          this.checkAll();
        }
        break;
      case "issuingcompany":
          this.isUploadError.issuingcompany = false;
          if (this.uploadReport.issuingcompany.trim() === "") {
            this.isUploadError.issuingcompany = true;
          } else {
            this.updateDisableUpload();
            this.checkAll();
          }
          break;
      case "validfromDate":
        this.isUploadError.validfromDate = false;
        if (this.uploadReport.validfromDate == null || this.uploadReport.validfromDate === "") {
          this.isUploadError.validfromDate = true;
        } else {
          this.uploadReport.validfromDate = new Date(this.uploadReport.validfromDate).toJSON();

          if (this.uploadReport.validtoDate !== null) {
            const validTo = new Date(this.uploadReport.validtoDate).getTime();
            const validFrom = new Date(this.uploadReport.validfromDate).getTime();
            if (validFrom > validTo) {
              this.isUploadError.validfromDate = true;
            } else {
              this.checkForUploadErrors('validfromDate');
              this.updateDisableUpload();
              this.checkAll();
            }
          }
        }
        break;
      case "validtoDate":
        this.isUploadError.validtoDate = false;
        if (this.uploadReport.validtoDate == null || this.uploadReport.validtoDate === "") {
          this.isUploadError.validtoDate = true;
        } else {
          this.uploadReport.validtoDate = new Date(this.uploadReport.validtoDate).toJSON();

          if (this.uploadReport.validfromDate !== null) {
            const validTo = new Date(this.uploadReport.validtoDate).getTime();
            const validFrom = new Date(this.uploadReport.validfromDate).getTime();
            if (validFrom > validTo) {
              this.isUploadError.validtoDate = true;
            } else {
              this.checkForUploadErrors('validtoDate');
              this.updateDisableUpload();
              this.checkAll();
            }
          }
        }
        break;
    }
  }

  private async setIssuer(index: number, issuer: string, lCheck: boolean = true): Promise<void> {
    index = (issuer == "") ? 0 : index;
    if (lCheck && this.uploadReport.type == index) {
      this.uploadReport.type = 0;
      this.certificateType = "";
    } else {
      this.uploadReport.type = index;
      this.certificateType = issuer;
    }
  }

  private get certificateTypename(): string {
    let typeName = "";
    if (this.tabIndex == 0) {
      typeName = (this.sustainableTypenames.length == this.uploadReport.type) ? this.otherCertName : this.sustainableTypenames[this.uploadReport.type];
    } else {
      typeName = (this.socialTypenames.length == this.uploadReport.type) ? this.otherCertName : this.socialTypenames[this.uploadReport.type];
    }
    return typeName;
  }

  private async goBack() {
    switch (this.currentView) {
      case "viewpdf":
        this.switchView("upload");
        break;

      case "upload":
        this.switchView("");
        break;

      default:
        this.closeUploadModal();
        break;
    }
  }

  private async switchView(view: string): Promise<void> {
    if (view == "viewpdf" && this.disableUpload) {
      return;
    }
    if (view == "viewpdf" && this.uploadReport.files.length == 1) {
      setTimeout(async (app: any) => {
        app.pdfDocument = await app.uploadReport.files[0].arrayBuffer();
      }, 250, this);
    }
    if (view == "upload") {
      this.disableUpload = true;

      this.checkAllFieldsForErrors();
      this.checkAll();
    }

    this.currentView = view;
  }

  private async pdfReady(pdfApp: any): Promise<void> {
    const pdfZoom = "page-fit";
    await pdfApp.pdfViewer.pagesPromise;
    pdfApp.pdfViewer.currentScaleValue = pdfZoom;
  }

  private async uploadReportService(): Promise<void> {
    if (await this.checkAllFieldsForErrors()) {
      return;
    }

    this.isUploading = true;

    const user = this.$store.getters.user as UserModel;

    const reportFormData = new FormData();
    this.uploadReport.files.forEach((file: string | Blob) => {
      reportFormData.append("upload", file);
    });
    reportFormData.append("description", this.uploadReport.description);
    if(this.companyType === 5){
      reportFormData.append("brand", "");
    }
    else{
      reportFormData.append("brand", this.selectedBrandsArr.length==1?this.selectedBrandsArr[0]:this.selectedBrandsArr.join(', '));
    }
    reportFormData.append("issuer", this.uploadReport.issuingcompany);
    reportFormData.append("referenceIssuer", this.uploadReport.referenceissuer);
    reportFormData.append("geoLat", this.geoLat);
    reportFormData.append("geoLong", this.geoLong);
    reportFormData.append("validFromDate", this.uploadReport.validfromDate);
    reportFormData.append("validToDate", this.uploadReport.validtoDate);
    reportFormData.append("supplierID", this.supplier.id);
    reportFormData.append("supplierName", this.supplier.companyName);
    let response: QualityReportResponseModel;
    response = await this.reportService.uploadQualityReportAsync(reportFormData, user.companyId);
    this.isUploading = false;
    if(response.success){
      this.updateQualityReports(response.productQualityReport);
      this.closeUploadModal();
      NotificationHelper.createSucceededNotification("Product quality report is submitted successfully.");
    }
    else{
      NotificationHelper.createErrorNotification(i18n.t('errors.certificate_upload_failed').toString());
    }
  }

  private checkAll(): void {
    if(this.uploadReport.description=="" || this.uploadReport.referenceissuer=="" || this.uploadReport.issuingcompany=="" || this.uploadReport.validfromDate=="" || this.uploadReport.validtoDate=="" || this.uploadReport.files.length==0){
      this.disableUpload = true;
    }
    if(!this.disableUpload && this.companyType !== 5){
      this.disableUpload = this.selectedBrandsArr.length==0 ? true : false;
    }
  }

  @Watch("uploadReport.files")
  private checkValidFiles(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      if (newValue.length !== 1) {
        newValue = [];
      }
      this.checkForUploadErrors('files');
    }
  }

  @Watch("uploadReport.validfromDate")
  private checkValidFromDate(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('validfromDate');
    }
  }

  @Watch("uploadReport.validtoDate")
  private checkValidToDate(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('validtoDate');
    }
  }

  @Emit()
  private reloadCertificates() {
    const pageComplianceID = document.getElementById("scp-page") as HTMLElement;
    pageComplianceID.style.height = "100%";
    pageComplianceID.style.overflow = "inherit";
  }

  @Emit()
  private updateQualityReports(report: ProductQualityReportModel[]){}

  @Emit()
  private closeUploadModal() {
  }
}