import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import moment from "moment";
import { mapState } from 'vuex';

// Components
import ViewOriginalDocuments from "@/views/compliance/components/viewOriginalDocuments.vue";
import RemindComplianceDocument from "@/views/compliance/components/remindComplianceDocument.vue";
import ViewDocumentWithComments from "@/views/compliance/components/viewDocumentWithComments.vue";
import ViewSignedDocument from "@/views/compliance/components/viewSignedDocument.vue";
import SelectPartner from '@/components/general/select-partner/selectPartner.vue';

// Services
import { ComplianceService } from "@/services/complianceService";
import { ClientService } from "@/services/clientService";

// Modals
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";
import { ComplianceDocModel } from "@/models/complianceDocModel";
import { UserModel } from "@/models/userModel";
import { UserPermissionModel } from "@/models/permissionModel";
import { SelectedPartnerModel } from "@/models/supplierPartnerModel";
import { DropdownModel } from "@/models/dropdownModel";
import { ConfirmComplianceDocumentModal } from "@/models/confirmComplianceDocumentModal";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    components: { ViewOriginalDocuments, RemindComplianceDocument, ViewDocumentWithComments, ViewSignedDocument, SelectPartner },
	computed: mapState(['userPermissions','userRole', 'companyNameList'])
})
export default class SentComplianceDocuments extends Vue {

	@Prop()
	private documents!: ComplianceDocSentResponseModel[];

	@Prop()
	private isLoading!: boolean;

	@Prop()
	private selectedTab!: number;

	@Prop()
    private searchFilter!: string;

	@Prop()
    private selectedBrand!: string;

	private complianceService: ComplianceService;

	private showReminderPopup: boolean = false;

    private showPDFModal: boolean = false;
    private showSignedPDFModal: boolean = false;
    private showPdfCommentsModal: boolean = false;

    private currentDocument!: ComplianceDocSentResponseModel;

    private selectedChildDocument!: ComplianceDocSentResponseModel;

	private docWithSCP: ComplianceDocModel[] = [];

	private imgSrc: NodeRequire = require("../../../assets/no-document-sent.png");

	private reminderScpList: ComplianceDocSentResponseModel[] = [];

	private documentName: string = '';

	private selectedDocId: string = '';

	private supplierId: string = '';

	private loadingArchieveDoc: boolean = false;

	private userPermissions!: UserPermissionModel | null;

    private isDeleting: boolean = false;

    private showDeleteDocModal: boolean = false;

    private showSelectPartner: boolean = false;

    private showLoader: boolean = false;

	private documentForReminder!: ComplianceDocModel;

    private selectedPartners: SelectedPartnerModel[] = [];

    private supplierType: string = "";

	private linkedPartners: DropdownModel[] = [];

	private docSelected!: ComplianceDocModel;

    private user: UserModel = new UserModel;

    private clientService: ClientService;

	private userRole!: string;

	private companyNameList!: CompanyNameModel[]; // TTD-3961

	public constructor() {
		super();
		this.complianceService = new ComplianceService();
        this.clientService = new ClientService();
	}

	private created(): void {
        this.user = this.$store.getters.user as UserModel;
	}

	private get docs(): ComplianceDocModel[] {
		this.docWithSCP = [];
		this.documents.forEach(doc => {
			if(this.docWithSCP.findIndex(d => d.complianceDocID === doc.complianceDocID) === -1){
				const document: ComplianceDocModel = new ComplianceDocModel;
				document.ID = doc.ID;
                document.brands = doc.brands === null || doc.brands === undefined ? [] : doc.brands;
				document.complianceDocID = doc.complianceDocID;
				document.createdAt = doc.createdAt;
				document.docURL = doc.docURL;
				document.documentName = doc.documentName;
				document.documentStatus = doc.documentStatus;
				document.additionalInfo = doc.additionalInfo;
				document.documentVersion = doc.documentVersion;
				document.fromCompanyID = doc.fromCompanyID;
				document.fromCompanyName = this.companyNameList.filter(c => c.ID === doc.fromCompanyID).length > 0 ? this.companyNameList.filter(c => c.ID === doc.fromCompanyID)[0].name : doc.fromCompanyName;
				document.notifyDays = doc.notifyDays;
				document.originalFileHash = doc.originalFileHash; // TTD-4624
				document.originalFileName = doc.originalFileName;
				document.originalFileType = doc.originalFileType;
				document.originalDocURL = doc.originalDocURL;
				document.validtoDate = doc.validtoDate;
				document.toCompanyID = doc.toCompanyID;
				document.toCompanyName = this.companyNameList.filter(c => c.ID === doc.toCompanyID).length > 0 ? this.companyNameList.filter(c => c.ID === doc.toCompanyID)[0].name : doc.toCompanyName;
				document.toCompanyContactName = doc.toCompanyContactName;
				document.toCompanyContactEmail = doc.toCompanyContactEmail;
				document.signatureRequested = doc.signatureRequested;
				document.allSCP = this.documents.filter(d => d.complianceDocID === doc.complianceDocID);
				document.isComplianceCollapse = false;
				document.loadingArchieveDoc = false;
				document.allSCP.every(d => (d.brands === null || d.brands === undefined ? d.brands = [] : d.brands));
				this.docWithSCP.push(document);
			}
		});
		return this.docWithSCP;
	}

	private get sendReminderPermission(): number {
        return this.userPermissions !== null && this.userPermissions?.sendReminderCompliance && this.userPermissions?.sendReminderCompliance==1 ? 1 : 0;
    }

	private get archievePermission(): number {
        return this.userPermissions !== null && this.userPermissions?.archieveCompliance && this.userPermissions?.archieveCompliance==1 ? 1 : 0;
    }

	private get viewOriginalPermission(): number {
        return this.userPermissions !== null && this.userPermissions?.viewOriginalCompliance && this.userPermissions?.viewOriginalCompliance==1 ? 1 : 0;
    }

	private async archiveDocument(scpDocument:ComplianceDocModel){
        let result = false;
        try{
            scpDocument.loadingArchieveDoc = true;
            const user = this.$store.getters.user as UserModel;
            const companyId = user.companyId;
            const docIds = new Array;
            const documents = this.documents.filter(d => d.complianceDocID === scpDocument.complianceDocID);
            documents.forEach(item => {
                docIds.push({'ID': item.ID});
            });
            const archiveDoc = {'complianceDoc': docIds, 'complianceDocID': scpDocument.complianceDocID};
            result = await this.complianceService.archiveComplianceDocumentAsync(companyId, archiveDoc);
        }
        finally{
            scpDocument.loadingArchieveDoc = false;
            if(result){
                this.reloadSentDocuments();
            }
        }
    }

	private formattedDate(date: string): string {
		if (date==="9999-01-01T00:00:00Z") {
			return "No deadline"
		}
		return moment(date).format("D MMM YYYY");
	}

	private statusColor(id: string): string {
		const doc = this.docWithSCP.filter(d => d.complianceDocID === id)[0];
		let color = '';
		if(doc.allSCP.filter(d => d.scpSignedStatus === true || d.scpReadStatus === 'true').length === 0){
			color = "red";
		}
		else if(doc.allSCP.filter(d => d.scpSignedStatus === true || d.scpReadStatus === 'true').length === doc.allSCP.length){
			color = "green";
		}
		else{
			color = "orange";
		}
        return color;
    }

	private scpCount(id: string): string {
		const doc = this.docWithSCP.filter(d => d.complianceDocID === id)[0];
		return doc.allSCP.filter(d => d.scpSignedStatus === true || d.scpReadStatus === 'true').length + '/' + doc.allSCP.length;
	}

	private openPDFModal(selectedDocument: ComplianceDocSentResponseModel): void {
        this.currentDocument = selectedDocument;
        this.showPDFModal = true;
    }

	private closePdfModal(success: boolean): void {
        this.showPDFModal = false;
    }

    private openPdfCommentsModal(selectedDocument: ComplianceDocSentResponseModel, childDoc: ComplianceDocSentResponseModel): void {
        this.currentDocument = selectedDocument;
		this.selectedChildDocument = childDoc;
        this.showPdfCommentsModal = true;
    }

    private closePdfCommentsModal(): void {
        this.showPdfCommentsModal = false;
    }

    private openSignedPDFModal(selectedDocument: ComplianceDocSentResponseModel): void {
        this.currentDocument = selectedDocument;
        this.showSignedPDFModal = true;
    }

    private closeSignedPDFModal(): void {
        this.showSignedPDFModal = false;
    }

	private openReminderPopup(doc:ComplianceDocModel): void {
		this.documentForReminder = doc;
		this.documentName = doc.documentName;
		this.reminderScpList = this.documents.filter(d => d.complianceDocID === doc.complianceDocID);
		this.showReminderPopup = true;
	}

	private closeReminderPopup(): void {
		this.showReminderPopup = false;
	}

    private getComments(comments: any): any {
        return comments.filter((s: { state: number; }) => s.state < 2);
    }

	private deleteDoc(ID: string, scpId: string): void {
        this.showDeleteDocModal = true;
		this.selectedDocId = ID;
		this.supplierId = scpId;
    }

	private openSelectPartner(doc: ComplianceDocModel): void {
		this.docSelected = doc;
		this.linkedPartners = [];
		doc.allSCP.forEach(s => {
			const drop = {
				"value": s.toCompanyID,
				"text": s.toCompanyName,
				"disabled": true
			}
			this.linkedPartners.push(drop);
		})
		this.showSelectPartner = true;
	}

	private closeSelectPartnerModal(): void {
		this.showSelectPartner = false;
	}

	private async selectedPartnersHandler(partners: SelectedPartnerModel[]): Promise<void> {
		let result: ComplianceDocSentResponseModel[] = [];
		try{
			this.showLoader = true;
			this.selectedPartners = partners;
			if (partners.filter(p => p.isTier1===true).length > 0 && partners.filter(p => p.isTier1===false).length > 0) {
				this.supplierType = "T1SCP_SCP_STEPS";
			} else if(partners.filter(p => p.isTier1===true).length > 0 && partners.filter(p => p.isTier1===false).length == 0) {
				this.supplierType = "T1SCP";
			} else if(partners.filter(p => p.isTier1===true).length == 0 && partners.filter(p => p.isTier1===false).length > 0) {
				this.supplierType = "SCP";
			}
			let partnerData: any[] = [];
			partners.forEach(partner => {
				if (this.linkedPartners.filter(l => l.value===partner.ID).length===0) {
					const data = {
						"ID": partner.ID,
						"isTier1": partner.isTier1
					}
					partnerData.push(data);
				}
			})
			const docData: ConfirmComplianceDocumentModal = {
				signatureRequested: this.docSelected.signatureRequested,
				companyDetails: partnerData,
				complianceDocID: this.docSelected.complianceDocID,
				brands: this.docSelected.brands,
				additionalInfo: "",
				fromCompanyID: this.user.companyId,
				fromCompanyName: this.user.companyName,
				signRequestedTo: this.supplierType, // T1SCP_SCP or T1SCP or SCP
				documentStatus: "DOCUMENT_SENT",
				documentName: this.docSelected.documentName ,
				documentVersion: 1,
				originalFileType: this.docSelected.originalFileType,
				originalFileHash: this.docSelected.originalFileHash, // TTD-4624
				originalFileName: this.docSelected.originalFileName,
				originalDocURL: this.docSelected.originalDocURL,
				actualOwnerID: this.docSelected.actualOwnerID,
				validtoDate: this.docSelected.validtoDate,
			};
			result =  await this.complianceService.createRecepientDocAsync(this.user.companyId, docData);
		} finally {
			if(result.length > 0){
				this.showLoader = false;
                this.clientService.sendComplianceMailNotificationAsync(result);
                this.closeSelectPartnerModal();
				this.reloadSentDocuments();
            } else {
				this.showLoader = false;
			}
		}
	}

	private async removeDoc(): Promise<void> {
        try{
            this.isDeleting = true;
            const user: UserModel = this.$store.getters.user;
            const result = await this.complianceService.deleteSentCompolianceDoc(this.selectedDocId, this.supplierId);
            if (result) {
                this.showDeleteDocModal = false;
				this.reloadSentDocuments();
            }
        } finally {
            this.isDeleting = false;
        }
    }

	private toggleComplianceDoc(scpDocument:ComplianceDocModel): void {
        scpDocument.isComplianceCollapse = !scpDocument.isComplianceCollapse;
        this.docs.forEach((doc) => {
            if(doc.ID!==scpDocument.ID){
                doc.isComplianceCollapse = false;
            }
        })
    }

	@Emit()
	private reloadSentDocuments(){}

}
