import { DropdownModel } from "./dropdownModel";
import { Guid } from "./guid";
import { TransportFootprintModel, TransportFootprintRequestModel } from "./transportFootprintModel";
import { FiberModel } from "./yarnFootprintModel"

export class FabricFootprintRequestModel {
    public footprintDeadline!: string;
    public fabric!: FabricModel;
}

export class FabricTransportRequestModel {
    public footprintDeadline!: string;
    public yarn!: TransportFootprintRequestModel;
}

export class FabricModel {
    constructor(){
        this.fabricFootprint = [];
        this.footprintStatus = "";
    }
    public footprintStatus!: string;
    public fabricFootprint!: FabricFootprintModel[];
}

export class FabricDataModel {
    public footprintStatus!: string;
    public fabricFootprint!: FabricFootprintModel[];
    public transport!: TransportFootprintModel[];
    public transportStatus!: string;
}

export class FabricFootprintModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.toCompanyUser = {
            toCompanyID: Guid.Empty,
            toCompanyName: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true,
            isUnique: true
        };
        this.fabricType = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.yarnFabric = [];
        // this.weft = [new WeftModel()];
        // this.warp = [new WarpModel()];
        // this.loopYarn = [new LoopYarn()];
        this.total = {
            totalcount: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.fabricWeight = {
            weight : "",
            filledByPrimarySource : false,
            filledByCompanyID : Guid.Empty,
            isValid: true
        };
        this.fabricUnit = {
            unit : "",
            filledByPrimarySource : false,
            filledByCompanyID : Guid.Empty,
            isValid: true
        };
        this.fabricWidth = {
            width:"",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: true,
            isValid: true
        };
        this.fabricWeight = {
            weight:"",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: true,
            isValid: true
        };
        this.provideEnergyFlag = true;
        this.totalElectricEnergy = {
            value : "",
            filledByPrimarySource : false,
            filledByCompanyID : Guid.Empty,
            isValid: true
        };
        this.windEnergy = {
            value : "",
            filledByPrimarySource : false,
            filledByCompanyID : Guid.Empty,
            isValid: true
        };
        this.solarEnergy = {
            value : "",
            filledByPrimarySource : false,
            filledByCompanyID : Guid.Empty,
            isValid: true
        };
        this.energySupportingDocument = [];
        this.showFabric = false;
        this.yarnRefList = [];
        this.fiberFabric = [];
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
        this.stepID = "";
        this.status = "";
        this.sequence = 0;
    }
    public ID!: string;
    public toCompanyUser!: ToCompanyUserModel;
    public reference!: ReferenceModel;
    public fabricType!: FabricTypeModel;
    public yarnFabric!: YarnModel[];
    // public weft!: WeftModel[];
    // public warp!: WarpModel[];
    // public loopYarn!: LoopYarn[];
    public total!: TotalModel;
    public fabricWeight!: FabricWeightModel;
    public fabricUnit!: YarnUnitModel;
    public fabricWidth!: FabricWidthUnitModel;
    public provideEnergyFlag!: boolean;
    public totalElectricEnergy!: TotalElectricEnergyModel;
    public windEnergy!: EnergyModel;
    public solarEnergy!: EnergyModel;
    public energySupportingDocument!: EnergySupportingDocumentModel[];
    public showFabric!: boolean;
    public yarnRefList!: DropdownModel[];
    public fiberFabric!: FiberModel[];
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public stepID!: string;
    public status!: string;
    public sequence!: number;
}

export class YarnModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.yarnRefNumber = {
            referenceNumber: "",
            yarnID: "",
            filledByCompanyID : Guid.Empty,
            filledByPrimarySource : false,
            isValid: true
        };
        this.yarnPercent = {
            count: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
    }
    public ID!: string;
    public yarnRefNumber!: YarnRefModel;
    public yarnPercent!: YarnPercentModel;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

export class WeftModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.yarnRefNumber = {
            referenceNumber: "",
            yarnID: "",
            filledByCompanyID : Guid.Empty,
            filledByPrimarySource : false,
            isValid: true
        };
        this.yarnPercent = {
            count: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
    }
    public ID!: string;
    public yarnRefNumber!: YarnRefModel;
    public yarnPercent!: YarnPercentModel;
}

export class WarpModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.yarnRefNumber = {
            referenceNumber : "",
            yarnID: "",
            filledByCompanyID : Guid.Empty,
            filledByPrimarySource : false,
            isValid: true
        };
        this.yarnPercent = {
            count: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
    }
    public ID!: string;
    public yarnRefNumber!: YarnRefModel;
    public yarnPercent!: YarnPercentModel;
}

export class LoopYarn {
    public constructor(){
        this.ID = Guid.Empty;
        this.yarnRefNumber = {
            referenceNumber : "",
            yarnID: "",
            filledByCompanyID : Guid.Empty,
            filledByPrimarySource : false,
            isValid: true
        };
        this.yarnPercent = {
            count: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
    }
    public ID!: string;
    public yarnRefNumber!: YarnRefModel;
    public yarnPercent!: YarnPercentModel;
}

export class YarnRefModel{
    public constructor(){
        this.referenceNumber = "",
        this.yarnID = "",
        this.filledByCompanyID = Guid.Empty,
        this.filledByPrimarySource = false,
        this.isValid = true;
    }
    public referenceNumber!: string;
    public yarnID!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class ToCompanyUserModel{
    public constructor(){
        this.toCompanyID = Guid.Empty;
        this.toCompanyName = "";
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
        this.isValid = true;
    }
    public toCompanyID!: string;
    public toCompanyName!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class ReferenceModel{
    public constructor(){
        this.referenceNo = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
        this.isUnique = true;
    }
    public referenceNo!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
    public isUnique!: boolean;
}

export class FabricTypeModel{
    public constructor(){
        this.type = "";
        this.filledByCompanyID = "";
        this.filledByPrimarySource = false;
        this.isValid = true;
    }
    public type!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class YarnPercentModel{
    public constructor(){
        this.count = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public count!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class YarnUnitModel{
    public constructor(){
        this.unit = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public unit!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class FabricWidthUnitModel{
    public constructor(){
        this.width = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public width!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class FabricWeightModel{
    public constructor(){
        this.weight = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public weight!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class TotalModel{
    public constructor(){
        this.totalcount = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public totalcount!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class TotalElectricEnergyModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public value!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class EnergyModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public value!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class EnergySupportingDocumentModel{
    public ID!: string;
    public createdAt!: string;
    public createdBy!: string;
    public docUrl!: string;
    public fileHash!: string;
    public fileName!: string;
    public fileType!: string;
    public geoLat!: number;
    public geoLong!: number;
    public referenceNo!: string;
    public rejectionReason!: string;
    public status!: number;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

export class FabricStepsDataModel {
    public constructor(){
        this.stepID = "";
        this.supplierName = "";
        this.referenceNo = "";
        this.referenceNoPrefix = "";
        this.showWrapper = false;
        this.supplierCompanyId = Guid.Empty;
        this.yarnRefList = [];
    }
    public stepID!: string;
    public supplierName!: string;
    public referenceNo!: string;
    public referenceNoPrefix?: string;
    public showWrapper!: boolean;
    public supplierCompanyId!: string;
    public yarnRefList!: DropdownModel[];
}