import { Vue, Component } from "vue-property-decorator";

// Components
import Authpage from "@/components/general/authpage.vue";

// Services
import { MiddlewareService } from '@/services/middlewareService';

@Component({
  components: { Authpage }
})

export default class ResetSentEmail extends Vue {

  private middlewareService: MiddlewareService;
  private isLoading: boolean = false;
  private emailAddress: string | null = '';
  public constructor() {
    super();
    this.middlewareService = new MiddlewareService();
    // this.emailAddress = localStorage.getItem("maincontactemail");
  }

  private async created(): Promise<void> {
    this.emailAddress = localStorage.getItem("maincontactemail");
  }

  private async next(): Promise<void> {
    this.isLoading = true;
    this.$router.push({ name: 'Signin' });

   }

}
