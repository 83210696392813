import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { mapState } from 'vuex';

// Components
import ViewDocuments from "@/views/compliance/components/viewDocuments.vue";
import ViewDocumentReader from "@/views/compliance/components/viewDocumentReader.vue";

// Modals
import { ComplianceDocResponseModal } from "@/models/complianceDocResponseModal";
import { UserPermissionModel } from "@/models/permissionModel";

@Component({
	components: { ViewDocuments, ViewDocumentReader },
	computed: mapState(['userPermissions'])
})
export default class PendingComplianceDocuments extends Vue {

    @Prop()
	private documents!: ComplianceDocResponseModal[];

    @Prop()
    private isLoading!: boolean;

    @Prop()
    private allDocuments!: ComplianceDocResponseModal[];

    @Prop()
    private searchFilter!: string;

    @Prop()
    private selectedBrand!: string;

    private currentDocument!: ComplianceDocResponseModal;

	private showUploadModal: boolean = false;

    private showPDFModal: boolean = false;

    private hover: boolean = false;

	private userPermissions!: UserPermissionModel | null;

	public constructor() {
		super();
	}

    private openPDFModal(selectedDocument: ComplianceDocResponseModal): void {
        this.currentDocument = selectedDocument;
        this.showPDFModal = true;
    }

	private get uploadPermission(): number {
        return this.userPermissions !== null && this.userPermissions?.uploadCompliance && this.userPermissions?.uploadCompliance==1 ? 1 : 0;
    }

    private closePdfModal(success: boolean): void {
        this.showPDFModal = false;
        if(success){
            this.reloadPendingDocuments();
        }
    }

    private get pictureHover(): string {
        if(this.hover){
            return require('@/assets/compliance-pdf.png');
        }
        else{
            return require('@/assets/compliance-pdf-hover.png');
        }
    }

    private get pendingDocCount(): Number {
        return this.documents.length;
    }

    private fileName(docId: string): string {
        const name = this.documents.filter(d => d.complianceDocID === docId)[0].fileName;
        return name === '' ? 'name.pdf' : name;
    }

    @Emit()
    private reloadPendingDocuments(){}

}