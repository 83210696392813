import Vue from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import * as msal from "@azure/msal-browser";
import Notifications from 'vue-notification';
import { NotificationHelper } from './helpers/notificationHelper';
import VueSimpleAlert from "vue-simple-alert";
import "isomorphic-fetch";

import { UserAuthenticator } from './authenticators/userAuthenticator';
import MsalFactory from './factories/msalFactory';

// Bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

// Components
import Authpage from './components/general/authpage.vue';
import Modal from './components/general/modal.vue';
import CreateSupplyChain from './views/home/components/createSupplyChain.vue';
import Datepicker from 'vuejs-datepicker';

// Styles
import "./styles/main.scss";
import { AppInsightsHelper } from './helpers/appInsightsHelper';

// VeeValidate
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { VeeValidateInit } from './validation/veeValidateInit';
import { VueFilters } from './filters/filters';

// MsalFactory.initialize();

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Notifications);
Vue.use(VueSimpleAlert, { reverseButtons: true,  cancelButtonText : i18n.t('global.modal.no'), confirmButtonText : i18n.t('global.modal.yes')});

Vue.config.errorHandler = function (error: Error): void {
  if (error != null && error.message != null && error.message !== "") {
    NotificationHelper.createErrorNotification(i18n.t(error.message).toString());
  }
};

VeeValidateInit.setupRules();

Vue.component('modal', Modal);
Vue.component('createSupplyChain', CreateSupplyChain);
Vue.component('date-picker', Datepicker);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

AppInsightsHelper.initialize();
VueFilters.register();

// new UserAuthenticator().authenticateUserAsync().then((user: msal.AccountInfo | null) => {
//   if (user == null) {
//     return;
//   }

  new Vue({
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    render: h => h(App),
    router,
    store,
    i18n
  }).$mount('#app');
// });