import { AddressModel } from "./addressModel";
import { ContactModel } from "./contactModel";
import { TopBrandModel } from "./topBrandModel";

export class CompanyModel {
    public id!: string;
    public companyName!: string;
    public contact!: ContactModel;
    public admin!: ContactModel;
    public companyType?: number;
    public companyId!: string;
    public categories!: number[];
    public turnover?: number;
    public topBrands!: TopBrandModel[];
    public productGroups!: number[];
    public officeAddress?: AddressModel;
    public companyNameUpdated!: boolean;
    public mandatoryStepsType!: string;

    public constructor() {
        this.id = "";
        this.companyName = "";
        this.contact = new ContactModel();
        this.admin = new ContactModel();
        this.companyId = "";
        this.categories = [];
        this.topBrands = [];
        this.productGroups = [];
        this.officeAddress = new AddressModel();
        this.mandatoryStepsType = '';
    }
}

export class companyResponseModel {
    public exceptionMessage!: string | null;
    public message!: string;
    public result!: string;
    public responseObject!: CompanyModel;
}

export class CompanyNameModel {
    public constructor(id: string, name: string){
        this.ID = id;
        this.name = name;
    }
    public ID!: string;
    public name!: string;
}

export class CompanyNameResponseModel {
    public success!: string;
    public companies!: string;
}
