import { Vue, Component, Prop } from "vue-property-decorator";
import i18n from "@/i18n";
import store from "@/store";

// Modals
import { SupplierCertificateModal } from "@/models/supplierCertificateModal";
import {
    CertificateOnTypeModel,
    CertificateTypes,
  } from "@/models/certificateModel";

@Component
export default class ExpiringCertificateTile extends Vue {

    @Prop()
    private certificates!: SupplierCertificateModal[];

    @Prop()
    private isLoading!: boolean;

    private sustainableCerts: CertificateOnTypeModel[] = [];
    private sustainableAuditCerts: CertificateOnTypeModel[] = [];
    private sustainableMemCerts: CertificateOnTypeModel[] = [];
    private socialCerts: CertificateOnTypeModel[] = [];
    private socailAuditCerts: CertificateOnTypeModel[] = [];
    private socailMemCerts:  CertificateOnTypeModel[] = [];
    private membershipCerts: CertificateOnTypeModel[] = [];

    private async created(): Promise<void> {
        const sustainable = i18n.t(
          `enums.sustainability_certificates.sustainability`
        ) as any;
        for (const key of Object.keys(sustainable)) {
          const certifcateName = sustainable[key];
          this.sustainableCerts.push(
            new CertificateOnTypeModel(
              key,
              certifcateName,
              CertificateTypes.SUSTAINABILITY,
              CertificateTypes.SUSTAINABILITY
            )
          );
        }
        const susMembership = i18n.t(
          `enums.sustainability_certificates.membership`
        ) as any;
        for (const key of Object.keys(susMembership)) {
          const certifcateName = susMembership[key];
          this.sustainableMemCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SUSTAINABILITY));
          this.membershipCerts.push(
            new CertificateOnTypeModel(
              key,
              certifcateName,
              CertificateTypes.MEMBERSHIP,
              CertificateTypes.SUSTAINABILITY
            )
          );
        }
        const susAudit = i18n.t(`enums.sustainability_certificates.audit`) as any;
        for (const key of Object.keys(susAudit)) {
          const certifcateName = susAudit[key];
          this.sustainableAuditCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
        }
        const social = i18n.t(`enums.social_certificates.social`) as any;
        for (const key of Object.keys(social)) {
          const certifcateName = social[key];
          this.socialCerts.push(
            new CertificateOnTypeModel(
              key,
              certifcateName,
              CertificateTypes.SOCIAL,
              CertificateTypes.SOCIAL
            )
          );
        }
        const socialAudit = i18n.t(`enums.social_certificates.audit`) as any;
        for (const key of Object.keys(socialAudit)) {
          const certifcateName = socialAudit[key];
          this.socailAuditCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
        }
        const socialMembership = i18n.t(
          `enums.social_certificates.membership`
        ) as any;
        for (const key of Object.keys(socialMembership)) {
          const certifcateName = socialMembership[key];
          this.socailMemCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SOCIAL));
          this.membershipCerts.push(
            new CertificateOnTypeModel(
              key,
              certifcateName,
              CertificateTypes.MEMBERSHIP,
              CertificateTypes.SOCIAL
            )
          );
        }

      }

    private get expiringCertificates(): SupplierCertificateModal[] {
        this.certificates.sort((item1, item2) =>{
            const daysLeft1 = item1.daysLeft;
            const daysLeft2 = item2.daysLeft;
            return daysLeft1 - daysLeft2;
        });
        return this.certificates.filter(c => c.certificateId!==0 &&
          this.sustainableMemCerts.findIndex(m => m.ID === c.certificateId.toString() && c.certificateType === 'Sustainability')===-1 &&
          this.socailMemCerts.findIndex(m => m.ID === c.certificateId.toString() && c.certificateType === 'Social')===-1);
    }

    private getCertificateName(certificatetype: string, certificateTypeID: number, otherCertName: string): string {
        let certificateName: string;
        if (certificatetype.toLowerCase() === "sustainability") {
            if(this.sustainableCerts.findIndex(su => su.ID === certificateTypeID.toString())!==-1){
                certificateName = (certificateTypeID == 0 || i18n.t('enums.sustainability_certificates.sustainability.' + certificateTypeID).toString() == "Other") ? otherCertName : i18n.t(`enums.sustainability_certificates.sustainability.${certificateTypeID}`).toString();
            }else if(this.sustainableAuditCerts.findIndex(au => au.ID === certificateTypeID.toString())!==-1){
                certificateName = (certificateTypeID == 0 || i18n.t('enums.sustainability_certificates.audit.' + certificateTypeID).toString() == "Other") ? otherCertName : i18n.t(`enums.sustainability_certificates.audit.${certificateTypeID}`).toString();
            }else{
                certificateName = (certificateTypeID == 0 || i18n.t('enums.sustainability_certificates.membership.' + certificateTypeID).toString() == "Other") ? otherCertName : i18n.t(`enums.sustainability_certificates.membership.${certificateTypeID}`).toString();
            }
        } else {
            if(this.socialCerts.findIndex(so => so.ID === certificateTypeID.toString())!==-1){
                certificateName = (certificateTypeID == 0 || i18n.t('enums.social_certificates.social.' + certificateTypeID).toString() == "Other") ? otherCertName : i18n.t(`enums.social_certificates.social.${certificateTypeID}`).toString();
            }else if(this.socailAuditCerts.findIndex(au => au.ID === certificateTypeID.toString())!==-1){
                certificateName = (certificateTypeID == 0 || i18n.t('enums.social_certificates.audit.' + certificateTypeID).toString() == "Other") ? otherCertName : i18n.t(`enums.social_certificates.audit.${certificateTypeID}`).toString();
            }else{
                certificateName = (certificateTypeID == 0 || i18n.t('enums.social_certificates.membership.' + certificateTypeID).toString() == "Other") ? otherCertName : i18n.t(`enums.social_certificates.membership.${certificateTypeID}`).toString();
            }

        }
        return (!certificateName || certificateName == "") ? "Other" : certificateName;
    }

    private getOtherCertificateName(certificatetype: string, certificateID: string): string {
        let otherCertName = "";
        if(certificatetype.toLowerCase() === "sustainability"){
            const certs = this.$store.getters.sustainableCertificate;
            const cert = certs.filter((c: any) => {
                c.ID == certificateID;
            });
            otherCertName = (cert.otherCertName) ? cert.otherCertName : "";
        } else {
            const certs = this.$store.getters.socialCertificates;
            const cert = certs.filter((c: any) => {
                c.ID == certificateID;
            });
            otherCertName = (cert.otherCertName) ? cert.otherCertName : "";
        }
        return (!otherCertName || otherCertName=="") ? "Other" : otherCertName;
    }
}
