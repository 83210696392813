import { Vue, Component } from "vue-property-decorator";

// Components
import Authpage from "@/components/general/authpage.vue";

@Component({
  components: { Authpage }
})

export default class VerificationResend extends Vue {

  public constructor() {
    super();
  }

  private emailAddress: string = "";
  private otpCode: string = "";

  private async created(): Promise<void> {
    if (this.$route.params.emailAddress !== undefined) {
      this.emailAddress = this.$route.params.emailAddress;
    }
  }

  private async back(): Promise<void> {
    this.$router.push({ name: 'VerificationCode', params: { emailAddress: this.emailAddress } });
  }

}
