import { Vue, Component, Prop, Emit} from "vue-property-decorator";

// Components
import RemindComplianceDocument from "@/views/compliance/components/remindComplianceDocument.vue";

// Model
import { UserModel } from "@/models/userModel";
import { ComplianceDocResponseModal } from "@/models/complianceDocResponseModal";
import { ConfirmComplianceDocumentModal} from "@/models/confirmComplianceDocumentModal";
import { SelectedPartnerModel } from "@/models/supplierPartnerModel";
import moment from "moment";

// Service
import { ComplianceService } from "@/services/complianceService";
import { ClientService } from "@/services/clientService";
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";

@Component({
    components: { RemindComplianceDocument}
})
export default class ConfirmComplianceDocument extends Vue {

    @Prop()
    private document!: ComplianceDocResponseModal;

    @Prop()
    private documentName!: string;

    @Prop()
    private additionalInfo!: string;

    @Prop()
    private supplierType!: string;

    @Prop()
    private deadlineDate!: any;

    @Prop()
    private partnersSelected!: SelectedPartnerModel[];

    @Prop()
    private steps!: number[];

    @Prop()
    private signRequested!: boolean;

    private complianceService: ComplianceService;

    private clientService: ClientService;

    private user: UserModel = new UserModel;

    private showReminderPopup: boolean = false;

    private isLoading: boolean = false;

    private redirectUrl: string = "";

    public constructor() {
        super();
        this.complianceService = new ComplianceService();
        this.clientService = new ClientService();
    }

    private async created(): Promise<void> {
        this.user = this.$store.getters.user as UserModel;
        const baseUrl = window.location.origin;
        if(baseUrl.includes("my.tex-tracer.com")){
            this.redirectUrl = "https://dashboard.tex-tracer.com/compliance";
        }
        else if(baseUrl.includes("my-demo.tex-tracer.com")){
            this.redirectUrl = "https://dashboard-demo.tex-tracer.com/compliance";
        }
        else if(baseUrl.includes("my-acc.tex-tracer.com")){
            this.redirectUrl = "https://dashboard-acc.tex-tracer.com/compliance";
        }
        else {
            this.redirectUrl = "https://dashboard-test.tex-tracer.com/compliance";
        }
    }

    private get formattedDate(): string {
        return moment(this.deadlineDate).format("D MMMM YYYY");
    }

    private async sendMessage(): Promise<void>  {
        let result: ComplianceDocSentResponseModel[] = [];
        try{
            this.isLoading = true;
            let partnerData: any[] = [];
            this.partnersSelected.forEach(partner => {
                const data = {
                    "ID": partner.ID,
                    "isTier1": partner.isTier1
                }
                partnerData.push(data);
            })
            const docData: ConfirmComplianceDocumentModal = {
                signatureRequested: this.signRequested===true? "true": "false",
                companyDetails: partnerData,
                complianceDocID: this.document.complianceDocID,
                brands: this.document.brands,
                additionalInfo: this.additionalInfo,
                fromCompanyID: this.user.companyId,
                fromCompanyName: this.user.companyName,
                signRequestedTo: this.supplierType, // T1SCP_SCP or T1SCP or SCP
                documentStatus: "DOCUMENT_SENT",
                documentName: this.documentName.replace('.pdf','') ,
                documentVersion: 1,
                originalFileType: this.document.fileType,
                originalFileHash: this.document.fileHash,
                originalFileName: this.document.fileName,
                originalDocURL: this.document.originalDocURL,
                actualOwnerID: this.document.actualOwnerID,
                validtoDate: this.deadlineDate=='9999-01-01T00:00:00Z'?this.deadlineDate:this.deadlineDate.toISOString(),
            };
            result =  await this.complianceService.confirmComplianceDocumentAsync(this.user.companyId, docData);
        }
        finally{
            this.isLoading = false;
            if(result.length > 0){
                this.clientService.sendComplianceMailNotificationAsync(result);
                this.closeParentModal(true);
            }
            else{
                this.closeParentModal(false);
            }
        }
    }

    private openReminderPopup(): void {
        this.showReminderPopup = true;
    }

    public closeReminderPopup(): void {
        this.showReminderPopup = false;
    }

    private close(success: boolean): void {
        if(!this.isLoading){
            this.closeModal(success);
        }
    }

    @Emit()
    private closeModal(success: boolean){}

    @Emit()
    private closeParentModal(success: boolean) { }
}