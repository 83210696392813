import { OrderLineStepProof } from './orderLineStepProof';
import { OrderLineStepStatus } from './orderLineStepStatus';
import { OrderLineModel } from "./orderLineModel";
import { ProductGroup } from './productGroup';
import { ClientStepModel } from './clientStepModel';
import { Guid } from "./guid";
import { StepHistory } from './stepHistoryModal';
import { StepState } from './stepState';
import { OrderLineStepParallelModel } from './orderLineStepParallelModel';

export class OrderLineStepModel {

    public constructor(orderLine: OrderLineModel, clientStep: ClientStepModel) {
        this.id = orderLine.id!;
        this.orderId = orderLine.orderId!;
        this.orderNumber = orderLine.orderNumber!;
        this.orderLineId = orderLine.orderLineId!;
        this.stepId = clientStep.stepId;
        this.productGroup = clientStep.productGroup;
        this.displayName = clientStep.displayName;
        this.sequence = clientStep.sequence;
        this.fromCompanyId = Guid.Empty;
        this.toCompanyId = Guid.Empty;
        this.toCompanyName = "";
        this.showDetails = false;
        this.showNewsLetter = false;
        this.addedByAgent = false;
        this.updatedByAgent = false;
        this.deletedByAgent = false;
        this.showFactoryAddress = false;
        this.showProfile = false;
        this.showDetailsCount = 0;
        this.rejectionReason = "null";
        this.stepState = StepState.NONE;
        this.stepHistory = [];
        this.clientID = Guid.Empty;
        this.cpSupplierID = Guid.Empty;
        this.approvalRequestedTo = "";
        this.agentID = "";
        this.delegatedCompanyID = "";
        this.brandName = "";
        this.parallelSupplyChain0 = [];
        this.parallelSupplyChain1 = [];
        this.parallelSupplyChain2 = [];
        this.parallelSupplyChain3 = [];
        this.parallelSupplyChain4 = [];
        // Sprint 20, TTD-3932
        this.footprintRefNo = "";
        this.footprintRefID = Guid.Empty;
        this.footprintRefNoUpdatedBy = Guid.Empty;
        // Sprint 20, TTD-3940
        this.footprintRefNoPrefix = "";
    }

    public id!: string;
    public orderId!: string;
    public orderLineId!: string;
    public orderNumber!: string;
    public stepId!: number;
    public displayName!: string;
    public productGroup!: ProductGroup;
    public sequence!: number;
    public status!: OrderLineStepStatus;
    public fromCompanyId!: string;
    public toCompanyId!: string;
    public toCompanyName!: string;
    public evidencePhotos: OrderLineStepProof[] = [];
    public validSocialCertificates: number[] = [];
    public validSustainabilityCertificates: number[] = [];
    public showDetailsCount!: number;
    public showNewsLetter!: boolean;
    public showFactoryAddress!: boolean;
    public showDetails!: boolean;
    public showProfile!: boolean;
    public rejectionReason!: string;
    public compliantAtVerificationDate!: boolean;
    public currentlyValidCertificates!: null;
    public stepState!: StepState;
    public stepHistory!: StepHistory[];
    public isLastStep!: string;
    public parallelChainCount!: number;
    public isSelected?: boolean;
    public clientID!: string;
    public cpSupplierID!: string;
    public approvalRequestedTo!: string;
    //TTD-2620, Agent orderlines changes
    public addedByAgent?: boolean;
    public updatedByAgent?: boolean;
    public deletedByAgent?: boolean;
    //TTD-2620, Agent orderlines changes
    //TTD-3591, new keys added
    public agentID?: string;
    public delegatedCompanyID?: string;
    //TTD-3591, new keys added
    //TTD-3794, orderRefId & brandName field added
    public brandName?: string;
    //TTD-3794, orderRefId & brandName field added
    public parallelSupplyChain0!: OrderLineStepParallelModel[];
    public parallelSupplyChain1!: OrderLineStepParallelModel[];
    public parallelSupplyChain2!: OrderLineStepParallelModel[];
    public parallelSupplyChain3!: OrderLineStepParallelModel[];
    public parallelSupplyChain4!: OrderLineStepParallelModel[];
    // Sprint 20, TTD-3932
    public footprintRefNo!: string;
    public footprintRefID!: string;
    public footprintRefNoUpdatedBy!: string;
    // Sprint 20, TTD-3940
    public footprintRefNoPrefix?: string;
}

export class StepReferenceModel {
    public toCompanyId!: string;
    public toCompanyName!: string;
    public mcStepUniqueId!: string;
    public pcStepUniqueId!: string;
    public refNo!: string;
    public mcSequence!: number;
    public pcOfMC!: number;
    public pcSequence!: number;
    public stepId!: number;
    public footprintRefNoPrefix?: string;
}

export class FootprintStepsModel {
    public id!: string;
    public stepId!: number;
    public sequence!: number;
    public toCompanyId!: string;
    public toCompanyName!: string;
    public footprintRefNo!: string;
    public footprintRefNoPrefix?: string;

    public constructor(id:string, toCompanyName:string, toCompanyId:string, stepId:number, refNum:string, sequence:number, prefixRef:string) {
        this.id = id;
        this.stepId = stepId;
        this.sequence = sequence;
        this.toCompanyId = toCompanyId;
        this.toCompanyName = toCompanyName;
        this.footprintRefNo = refNum;
        this.footprintRefNoPrefix = prefixRef
    }
}

export class SetMandatoryStepsModel {
    id!: string;
    stepdId!: number;
    contractualPartner!: string;
    brands!: string;
    category!: string;
    createdBy!: string;
    createdAt!: string;
}