import { debounce } from "ts-debounce";
import { Vue, Component } from "vue-property-decorator";

// Components
import Authpage from "@/components/general/authpage.vue";

// Services
import { MiddlewareService } from '@/services/middlewareService';

@Component({
  components: { Authpage }
})

export default class ForgotPassword extends Vue {

  private middlewareService: MiddlewareService;
  public constructor() {
    super();
    this.middlewareService = new MiddlewareService();
  }

  private isLoading: boolean = false;
  private emailError: boolean = false;
  private emailAddress: string = "";
  private checkEmailIsValidDebounced: any;

  private async created(): Promise<void> {
    this.checkEmailIsValidDebounced = debounce(this.checkEmailIsValid, 200, {maxWait:1000, isImmediate: true});
  }

  private async checkEmailIsValid(event: any): Promise<void> {
    const emailAddress = event.target.value;

    if (emailAddress.length < 1) {
      return;
    }

    const matches = emailAddress.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    this.emailError =  (matches == null) ? true : false;
    return;
  }

  private async submitEmail(): Promise<void> {
    if (this.emailAddress.trim() == "" || this.emailError) {
      return;
   }
    this.isLoading = true;
    const otpCode = Math.floor(100000 + Math.random() * 900000);
    localStorage.setItem("verificationcode", otpCode.toString());
    localStorage.setItem("maincontactemail", this.emailAddress);

    const result = await this.middlewareService.resetsendVerificationCode(this.emailAddress, otpCode.toString());
    if (result.result == "success") {
      this.$router.push({ name: 'SentEmail', params: { emailAddress: this.emailAddress } });
    }
    else if (result.result == "User already exists"){
      this.$router.push({ name: 'SentEmail', params: { emailAddress: this.emailAddress } });
    }else {
      this.emailError=true;
    }
    this.isLoading = false;

    return;
  }
}
