import { Guid } from "./guid";

export class AddressModel {
    public id!: string;
    public line1!: string;
    public line2!: string;
    public city!: string;
    public country!: string;
    public countryInFull!: string;
    public state!: string;
    public postal!: string;
    public disabled!: boolean;
    public lookupAvailable!: boolean;
    public isOfficeAddress!: boolean;
    public geoLat!: number;
    public geoLong!: number;
    public officeName!: string;
    public companySubtypes!:string[];
    public addressStatus!: string;
    public isSelected!: boolean;
    public sequence!: number;
    public facilityType!: string;
    public isExpanded!: boolean;
    public employees!: number | string;
    public maleEmployees!: number | string;
    public femaleEmployees!: number | string;
    public monthlyCapacity!: number | string;
    public monthlyCapacityUnit!: string;
    public position!: number;

    public constructor() {
        this.id = Guid.Empty;
        this.line1 = "";
        this.line2 = "";
        this.city = "";
        this.country = "";
        this.countryInFull = "";
        this.state = "";
        this.postal = "";
        this.geoLat = 0;
        this.geoLong = 0;
        this.isOfficeAddress = false;
        this.lookupAvailable = false;
        this.officeName = '';
        this.companySubtypes = [];
        this.addressStatus = '';
        this.isSelected = false;
        this.sequence = -1;
        this.isExpanded = false;
        this.facilityType = '';
        this.employees = '';
        this.maleEmployees = '';
        this.femaleEmployees = '';
        this.monthlyCapacity = '';
        this.monthlyCapacityUnit = '';
        this.position = -1;
    }
}
