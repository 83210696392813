import i18n from "@/i18n";
import { DropdownModel } from "@/models/dropdownModel";

export class CountryHelper {
    public static getCountryList(): DropdownModel[] {
        const countries = i18n.messages[i18n.locale].countries as any;
        const result: DropdownModel[] = [];
        for (const key of Object.keys(countries)) {
            const countryName = countries[key];
            result.push({ value: key, text: countryName });
        }
        result.sort((a: DropdownModel, b: DropdownModel) => a.text.localeCompare(b.text));
        return result;
    }
}