export class FileHelper {

    public static getBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (): any => {
                const result = reader.result as string;
                let encoded = result.replace(/^data:(.*;base64,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            };
            reader.onerror = (error): any => reject(error);
        });
    }

    public static downloadBase64String(content: string, filename: string, fileType: string): void {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = "data:" + fileType + ";base64," + content;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}