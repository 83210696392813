import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";

// Model
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";

@Component({
    components: { VuePdfApp }
})
export default class ViewOriginalDocuments extends Vue {

    @Prop()
    private document!: ComplianceDocSentResponseModel;

    private pdfDocument!: any;
    private isPdfReady: boolean = false;
    private config: any = {
        toolbar: false
    };

    public constructor() {
        super();
    }

    private async created(): Promise<void> {
    }

    private async downloadPDF(): Promise<void> {
        if (this.isPdfReady) {
            this.pdfDocument.contentDispositionFilename = this.document.documentName;
            this.pdfDocument.download();
        }
    }

    private async pdfReady(pdfApp: any): Promise<void> {
        this.isPdfReady = true;
        this.pdfDocument = pdfApp;
    }

    @Emit()
    private closePdfModal(success: boolean){
    }
}