import { Guid } from './guid';

export class DelegateCompanyUserModel {

    public constructor () {
        this.orderRef = "";
        this.email = "";
        this.companyName = "";
        this.userID = Guid.Empty;
        this.firstName = "";
        this.lastName = "";
        this.geoLat = 0;
        this.geoLong = 0;
        this.delegatedBy = "";
        this.delegatedByCompanyID = Guid.Empty;
        this.delegatedTo = "";
    }

    public orderRef!: string;
    public email!: string;
    public companyName!: string;
    public userID!: string;
    public firstName!: string;
    public lastName!: string;
    public geoLat!: number;
    public geoLong!: number;
    public delegatedBy!: string;
    public delegatedByCompanyID!: string;
    public delegatedTo!: string;
}