export class CompanyAdminRequestModel {
  public Firstname!: string;
  public Lastname!: string;
  public Email!: string;
  public Password!: string;
}

export class CompanyUserRequestModel {
  public Email!: string;
  public Password!: string;
  public TempPassword!: string;
  public Scope!: string;
}

export class CompanyUserResponseModel {
  public result!: string;
  public message!: string;
  public Exceptionmessage!: string;
}