import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import Multiselect from 'vue-multiselect';
import i18n from "@/i18n";
import _ from "lodash";
import { mapState } from "vuex";

import DropDownEditable from "@/components/general/drop-down-editable/dropDownEditable.vue";
import InviteNew from "@/components/general/invite-new/inviteNew.vue";

// Services
import { InvitationService } from '@/services/invitationService';
import { SupplierService } from "@/services/supplierService";
import { GeolocationService } from '@/services/geolocationService';
import { CountryStateCityService } from '@/services/countryStateCityService';

// Helpers
import { NotificationHelper } from "@/helpers/notificationHelper";
import { CountryHelper } from "@/helpers/countryHelper";

// Models
import { DropdownModel } from '@/models/dropdownModel';
import { InvitationModel } from '@/models/invitationModel';
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { InvitedSupplierModel } from "@/models/invitedSupplierModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { debounce } from "ts-debounce";
import { UserModel } from "@/models/userModel";

@Component({
    components: { Multiselect, DropDownEditable, InviteNew },
    computed: mapState(['user', 'companyType', 'orderLoading', 'orders', 'orderLineStepsLoading', 'orderLineSteps']),
})
export default class SearchPartner extends Vue {
    @Prop()
    private items!: DropdownModel[];

    @Prop()
    private isLoading!: boolean;

    @Prop()
    private suppliers!: OrderCompanyModel[];

    @Prop()
    private isTier1!: boolean;

    @Prop()
    private title!: string;

    @Prop()
    private agent!: boolean;

    @Prop()
    private searchPlaceholder!: string;

    @Prop()
    private isForPendingOrders!: boolean;

    @Prop({default: false})
    private isCp!: boolean;

    
    private companyType!: number;
    private countries: DropdownModel[] = [];
    private countriesDropdown: DropdownModel[] = [];
    private statesDropdown: DropdownModel[] = [];
    private citiesDropdown: DropdownModel[] = [];

    private searchText: string = '';

    private inviteSendToContactEmail: string = '';

    private invitationService: InvitationService;

    private supplierService: SupplierService;

    private invitationModel: InvitationModel = new InvitationModel();

    private showSearchSupplierModal: boolean = true;

    private showInviteSupplierModal: boolean = false;

    private isInviting: boolean = false;

    private cscService: CountryStateCityService;
    private geolocationService: GeolocationService;

    private mapboxAccessToken: string = "";

    private mapboxGeolocationCoordinates: any;

    private selectedPartnerType: number = 0;

    //  error handling and validation
    private contactEmailError: boolean = false;

    private contactNameError: boolean = false;

    private companyNameError: boolean = false;

    private companyRegistrationNumberError: boolean = false;

    private cityError: boolean = false;

    private stateError: boolean = false;

    private countryError: boolean = false;

    private partnerTypeError: boolean = false;

    private showAllSuppliers: boolean = false;

    private showListLoading: boolean = false;

    private checkFieldsDebounced: any;

    private alteredSuppliers!: OrderCompanyModel[];

    private listItems: DropdownModel[] = [];

    private user!: UserModel;

    private orderLoading!: boolean;

    private orders!: OrderLineDisplayModel[];

    private orderLineStepsLoading!: boolean;

    private orderLineSteps!: OrderLineStepDisplayModel[];

    public constructor() {
        super();
        this.invitationService = new InvitationService();
        this.supplierService = new SupplierService();
        this.cscService = new CountryStateCityService();
        this.geolocationService = new GeolocationService();
    }

    private async created(): Promise<void> {
        this.countries = CountryHelper.getCountryList();
        this.countriesDropdown = [];
        this.countriesDropdown = _.orderBy(CountryHelper.getCountryList(),['text'],['asc']);
        this.checkFieldsDebounced = debounce(this.validateField, 250, { maxWait: 250, isImmediate: true });
    }

    private toggleSupplierFilter(): void{
        if (!this.showAllSuppliers) {
            let totalSuppliers: OrderCompanyModel[] = this.$store.getters.suppliers;
            if (this.isTier1 === false) {
                this.listItems = totalSuppliers.map(s => ({ value: s.id, text: s.isCompliant ? (s.id === this.user.companyId ? `${s.companyName} (me)` : `${s.companyName} (${s.country})`) : `${s.companyName} (${this.$t('pages.home.pending_invite')})` }));
            } else {
                totalSuppliers = totalSuppliers.filter(t=>t.isTier1===true);
                this.listItems = totalSuppliers.map(s => ({ value: s.id, text: s.isCompliant ? `${s.companyName} (${s.country})` : `${s.companyName} (${this.$t('pages.home.pending_invite')})` }));
            }
            this.showAllSuppliers = true;
        } else {
            this.listItems = this.filterSuppliers.map(s => ({ value: s.id, text: s.isCompliant ? (s.id === this.user.companyId ? `${s.companyName} (me)` : `${s.companyName} (${s.country})`) : `${s.companyName} (${this.$t('pages.home.pending_invite')})` }));
            this.showAllSuppliers = false;
        }
    }

    private get filteredItems(): DropdownModel[] {
        this.showListLoading = true;
        let totalSuppliers: OrderCompanyModel[] = this.$store.getters.suppliers;
        if (this.isTier1 === false) {
            if (this.agent) {
                totalSuppliers = totalSuppliers.filter(t => (t.isAgent === true));
            } else {
                totalSuppliers = totalSuppliers.filter(t => (t.isAgent === false));
            }
            this.listItems = totalSuppliers.map(s => ({ value: s.id, text: s.isCompliant ? (s.id === this.user.companyId ? `${s.companyName} (me)` : `${s.companyName} (${s.country})`) : `${s.companyName} (${this.$t('pages.home.pending_invite')})`, selected: false }));
        } else {
            totalSuppliers = totalSuppliers.filter(t => t.isTier1 === true);
            this.listItems = totalSuppliers.map(s => ({ value: s.id, text: s.isCompliant ? `${s.companyName} (${s.country})` : `${s.companyName} (${this.$t('pages.home.pending_invite')})`, selected: false }));
        }
        let finalList = this.listItems.filter(f => {
            const sanitizedItemText = f.text.replace(/[^a-zA-ZıİğĞşŞçÇüÜöÖ]/g, '');
            return f.text.toLowerCase().match(this.searchText.toLowerCase()) || f.text.replace(/[^a-zA-ZıİğĞşŞçÇüÜöÖ]/g, '').toLocaleLowerCase("tr-TR").includes(this.searchText.replace(/[^a-zA-ZıİğĞşŞçÇüÜöÖ]/g, '').toLocaleLowerCase("tr-TR"));
        });
        finalList = finalList.sort((user1,user2) => {
            return user2.text.toLowerCase() > user1.text.toLowerCase()
              ? -1
              : user2.text.toLowerCase() < user1.text.toLowerCase()
              ? 1
              : 0;
          });
        this.showListLoading = false;
        return finalList;
    }

    private get supplierLoading(): boolean {
        if (this.companyType === 5) {
            return this.isLoading;
        }
        if(this.isTier1 === false && (!this.agent || this.companyType !== 5)){
            return this.showListLoading || this.orderLineStepsLoading;
        }
        else if(this.isTier1 === false && this.agent){
            return false;
        }else{
            return this.showListLoading || this.orderLoading;
        }
    }

    private get filterSuppliers(): OrderCompanyModel[] {
        let uniqueToCompanyId: string[] = [];
        if (this.isTier1 === false) {
            uniqueToCompanyId = [... new Set(this.orderLineSteps.map(o => o.toCompanyId))];
        } else {
            uniqueToCompanyId = [... new Set(this.orders.map(o => o.toCompanyId))];
        }
        if(this.agent || this.companyType === 5){
            const agents = this.suppliers.filter((s) => s.isAgent === true);
            return agents;
        }
        return this.suppliers.filter(s=>uniqueToCompanyId.includes(s.id) && s.isAgent === false);
    }

    private get partnerType(): DropdownModel[] {
        return [
            { value: 1, text: i18n.t('pages.invitation.tier1_partner').toString() },
            { value: 2, text: i18n.t('pages.invitation.nominated_partner').toString() }
        ];
    }

    private closeInviteSupplierModal(isInvited: boolean): void {
        if (isInvited) {
            this.invitationSend();
        }
        this.showInviteSupplierModal = false;
        this.showSearchSupplierModal = true;
    }

    private openInviteSupplierModal(): void {
        this.invitationModel = new InvitationModel();
        this.inviteSendToContactEmail = "";
        this.showSearchSupplierModal = false;
        this.showInviteSupplierModal = true;
        // this.getMapboxTokenAsync();
    }

    private get sendInvitationDisabled(): boolean {
        let isError = (!this.invitationModel.isValid ||
            this.inviteSendToContactEmail === this.invitationModel.toContactEmail ||
            this.selectedPartnerType === 0);

        if (!isError) {
            isError = (this.contactEmailError ||
                this.contactNameError ||
                this.companyRegistrationNumberError ||
                this.companyNameError ||
                this.countryError ||
                this.stateError ||
                this.cityError ||
                this.partnerTypeError) ? true : false;
        }

        return isError;
      }

    @Watch('suppliers')
    private reloadOrders(): void {
        if (this.companyType === 5) {
            this.toggleSupplierFilter();
        }
    }

    @Emit()
    private valueSelected(value: string): void {
        this.searchText = '';
    }

    @Emit()
    private closeSearch(): void {
    }

    // Removed because token api is already being called from <invite-new> component
    // private async getMapboxTokenAsync(): Promise<void> {
    //     this.mapboxAccessToken = (await this.geolocationService.getMapboxToken()).token;
    //     return;
    // }

    @Emit()
    private invitationSend(): void {
    }

    private async validateField(event:any,fieldname:string): Promise<void> {
        const cityDropdown = this.$refs.cityDropdown as any;
        const stateDropdown = this.$refs.stateDropdown as any;

        switch (fieldname) {
            case "contactEmail": {
                const emailValid = event.target.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                this.contactEmailError = (emailValid == null) ? true : false;
                break;
            }
            case "contactName":
                this.contactNameError = (event.target.value.length < 2) ? true : false;
                break;
            case "companyRegistrationNumber":
                this.companyRegistrationNumberError = (event.target.value.length < 2) ? true : false;
                break;
            case "companyName":
                this.companyNameError = (event.target.value.length < 2) ? true : false;
                break;

            case "country":
              this.invitationModel.country = "";
              this.countryError = (event.fieldKey.length < 2) ? true : false;

              this.statesDropdown = [{ value: '', text: i18n.t('pages.invitation.loading').toString() }];
              stateDropdown.clearValues();
              this.invitationModel.state = "";

              this.citiesDropdown = [];
              cityDropdown.clearValues();
              this.invitationModel.city = "";

              if (!this.countryError) {
                const countriesList = await this.cscService.getAllCountries();
                const country = countriesList.find((x: { iso2: any; }) => x.iso2 == event.fieldKey);

                if (country !== undefined) {
                  this.invitationModel.country = event.fieldKey;
                  const states = await this.cscService.getStatesOfCountry(this.invitationModel.country);
                  this.statesDropdown = [];
                  if (states !== undefined) {
                    states.forEach((state: { iso2: string; name: string; }) => {
                      this.statesDropdown.push({ value: state.iso2, text: state.name });
                    });
                    this.statesDropdown = _.orderBy(this.statesDropdown,['text'],['asc']);
                  }
                  if (!states || states.length == 0) {
                    this.statesDropdown = [{ value: '', text: i18n.t('errors.no_records_found').toString() }];
                  }
                } else {
                  this.statesDropdown = [];
                }
              } else {
                this.statesDropdown = [];
            }
            break;

            case "state":
              if (!event.fieldKey || event.fieldKey.length == 0) {
                this.stateError = (event.fieldValue == i18n.t('errors.no_records_found').toString() || event.fieldValue == i18n.t('pages.invitation.loading').toString()) ? true : false;
                return;
              }

              this.citiesDropdown = [{ value: '', text: i18n.t('pages.invitation.loading').toString() }];
              cityDropdown.clearValues();
              this.invitationModel.city = "";

              this.invitationModel.state = "";
              this.stateError = (event.fieldValue.length < 2) ? true : false;

              if (!this.stateError) {
                this.invitationModel.state = event.fieldValue;
                if (event.fieldKey && event.fieldKey.length > 0) {
                  const cities = await this.cscService.getCitiesOfState(this.invitationModel.country, event.fieldKey);
                  this.citiesDropdown = [];
                  if (cities !== undefined) {
                    cities.forEach((city: { id: any; name: any; }) => {
                      this.citiesDropdown.push({ value: city.id, text: city.name });
                    });
                    this.citiesDropdown = _.orderBy(this.citiesDropdown,['text'],['asc']);
                  }
                  const gotCoordinates = await this.geolocationService.getCoordinates(this.mapboxAccessToken, this.invitationModel.state + ", " + this.invitationModel.country);
                    if (gotCoordinates.features.length > 0) {
                        this.invitationModel.longitude = parseFloat(gotCoordinates.features[0].center[0]);
                        this.invitationModel.latitude = parseFloat(gotCoordinates.features[0].center[1]);
                    }
                } else {
                  this.citiesDropdown = [];
                }
              } else {
                this.citiesDropdown = [];
              }
              break;

            case "city":
                this.invitationModel.city = "";
                this.cityError = (event.fieldValue.length < 2 || event.fieldValue == i18n.t('errors.no_records_found').toString()) ? true : false;
                if (!this.cityError) {
                    this.invitationModel.city = event.fieldValue;
                    const gotCoordinates = await this.geolocationService.getCoordinates(this.mapboxAccessToken, this.invitationModel.city.split(' ')[0] + ", " + this.invitationModel.state + ", " + this.invitationModel.country);
                    if (gotCoordinates.features.length > 0) {
                        this.invitationModel.longitude = parseFloat(gotCoordinates.features[0].center[0]);
                        this.invitationModel.latitude = parseFloat(gotCoordinates.features[0].center[1]);
                    }
                }
                break;

            case "partnerType":
                this.partnerTypeError = (event.target.value.length < 2) ? true : false;
                break;
            default:
                break;
        }
    }
}