import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import moment from "moment";

// Components
import YearList from "@/components/general/year-list/yearList.vue";
import DoughnutChart from "@/components/general/charts/doughnutChart/doughnutChart.vue";

// Modals
import { ChartModal } from "@/models/chartModal";
import { SocialEffortModal } from "@/models/socialEffortModal";

@Component({
    components: {YearList, DoughnutChart}
})
export default class SocialEffortsTile extends Vue {

    @Prop()
    private socialEffortCertificates!: SocialEffortModal[];

    @Prop()
    private isLoading!: boolean;

    private showYear: boolean = false;

    private year: string = moment().format('YYYY');

    private chartData: Object = {};

    private chartOptions: Object = {};

    private imgSrc: NodeRequire = require("../../../assets/chart-empty.png");

    private selectedYear(year: string): void{
        this.year = year;
        this.selectedYearValue(year, 'Social');
        this.closeYearList();
    }

    private showYearList(){
        this.showYear = true;
    }

    private get socialEfforts(): SocialEffortModal[] {
        return this.socialEffortCertificates.filter(s => (s.year === this.year || s.year === ''));
    }

    private get chartdetail(): ChartModal {

        let bsci = this.socialEfforts.filter(c => c.certificateName === 2).length;
        let sedex = this.socialEfforts.filter(c => c.certificateName === 3).length;
        let sa8000 = this.socialEfforts.filter(c => c.certificateName === 4).length;
        let none = this.socialEfforts.filter(c => c.certificateName === 0).length;
        let others = this.socialEfforts.filter(c => c.certificateName === 10).length;

        const total = bsci + sedex + sa8000 + none + others;

        bsci = (bsci / total) * 100;
        sedex = (sedex / total) * 100;
        sa8000 = (sa8000 / total) * 100;
        none = (none / total) * 100;
        others = (others / total) * 100;

        this.chartData = {
            labels: ['BSCI', 'Sedex', 'SA8000', 'Others', 'None'],
            datasets: [
                {
                    label: 'Social efforts',
                    backgroundColor: ['#7498AF', '#CBA76D', '#E5D3B6', '#F2E9DA', '#E7E7E7'],
                    data: [bsci.toFixed(2), sedex.toFixed(2), sa8000.toFixed(2), others.toFixed(2), none.toFixed(2)],
                    hoverOffset: 1
                }
            ]
        };

        this.chartOptions = {
            responsive: true,
            cutoutPercentage: 80,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    label: ((tooltipItem: any, data: any) => {
                        const label = data.labels[tooltipItem.index] + ": " + data.datasets[0].data[tooltipItem.index] + " %";
                        return label;
                    })
                }
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            }
        };

        const chartdetails = {'chartData': this.chartData, 'chartOptions': this.chartOptions};

        return chartdetails;
    }

    private closeYearList(): void {
        this.showYear = false;
    }

    @Emit()
    private selectedYearValue(year: string, componentName: string){
    }

}