import i18n from "@/i18n";
import { ProductGroupModel } from "@/models/productGroupModel";
import enLangData from "@/locales/en.json";

export class ProductGroupHelper {
    public static getProductGroups(languageCode?: string): ProductGroupModel[] {
        const stepNames = Object.values(enLangData.enums.product_group);
        return [
            { id: 1, description: languageCode !== undefined ? stepNames[1] : i18n.t("enums.product_group.1").toString() },
            { id: 2, description: languageCode !== undefined ? stepNames[2] : i18n.t("enums.product_group.2").toString() },
            { id: 3, description: languageCode !== undefined ? stepNames[3] : i18n.t("enums.product_group.3").toString() },
            { id: 4, description: languageCode !== undefined ? stepNames[4] : i18n.t("enums.product_group.4").toString() },
            { id: 5, description: languageCode !== undefined ? stepNames[5] : i18n.t("enums.product_group.5").toString() },
            { id: 6, description: languageCode !== undefined ? stepNames[6] : i18n.t("enums.product_group.6").toString() },
            { id: 7, description: languageCode !== undefined ? stepNames[7] : i18n.t("enums.product_group.7").toString() },
            { id: 8, description: languageCode !== undefined ? stepNames[8] : i18n.t("enums.product_group.8").toString() },
            { id: 9, description: languageCode !== undefined ? stepNames[9] : i18n.t("enums.product_group.9").toString() },
            { id: 10, description: languageCode !== undefined ? stepNames[10] : i18n.t("enums.product_group.10").toString() },
            { id: 11, description: languageCode !== undefined ? stepNames[11] : i18n.t("enums.product_group.11").toString() },
            { id: 12, description: languageCode !== undefined ? stepNames[12] : i18n.t("enums.product_group.12").toString() },
            { id: 13, description: languageCode !== undefined ? stepNames[13] : i18n.t("enums.product_group.13").toString() },
            { id: 14, description: languageCode !== undefined ? stepNames[14] : i18n.t("enums.product_group.14").toString() }

        ];
    }

    // Method return filtered product group with respect to Group description
    public static getIdFromProductGroupName(groupDescription: string): any {
        return this.getProductGroups('en').find(product => (product.description).toLowerCase() === (groupDescription).toLowerCase());
    }
}