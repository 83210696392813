import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import ClickOutside from 'vue-click-outside';
import { debounce } from "ts-debounce";

// Components
import ConfirmComplianceDocument from "@/views/compliance/components/confirmComplianceDocument.vue";
import Multiselect from '@/components/input/multiselect.vue';
import SelectPartner from '@/components/general/select-partner/selectPartner.vue';
import DropDownInput from "@/components/general/drop-down-input/dropDownInput.vue";

// Model
import { DropdownModel } from "@/models/dropdownModel";
import { ComplianceDocResponseModal } from "@/models/complianceDocResponseModal";
import { ClientStepDropdownModel } from "@/models/clientStepDropdownModel";
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { UserModel } from "@/models/userModel";
import { SelectedPartnerModel } from "@/models/supplierPartnerModel";

// Helpers
import { CompanyFormDropdownHelper } from "@/helpers/companyFormDropdownHelper";
import { StepsHelper } from "@/helpers/stepsHelper";
import { NotificationHelper } from "@/helpers/notificationHelper";

//Service
import { SupplierService } from '@/services/supplierService';
import { ComplianceService } from '@/services/complianceService';
import { ActionTypes } from "@/store/actions/actions";

@Component({
    components: { VuePdfApp, ConfirmComplianceDocument, Multiselect, SelectPartner, DropDownInput },
    directives: {
        ClickOutside
    }
})
export default class ViewDocuments extends Vue {

    @Prop()
    private document!: ComplianceDocResponseModal;

    @Prop()
    private allDocuments!: ComplianceDocSentResponseModel[];

    private config: any = {
        toolbar: false
    };

    private showConfirmationPopup: boolean = false;

    private isConfirmed: boolean = false;

    private checkFieldsDebounced: any;

    private suppliers: OrderCompanyModel[] = [];

    private isT1SCP: boolean = false;

    private isSCP: boolean = false;

    private additionalInfo: string = "";

    private deadlineDate: any = new Date();

    private selectedDeadline: string = this.$t("pages.compliance.deadline_placeholder").toString();

    private deadlineToSign: DropdownModel[] = [];

    private showDeadLine: boolean = false;

    private suppliersLoading: boolean = false;

    private showSelectPartnerModal: boolean = false;

    private supplierType: string = "";

    private signingSteps: number[] = [];

    private documentName: string = "";

    private selectedSteps: string = "All steps to be signed";

    private showStepDropdown: boolean = false;

    private scpAllSelected: boolean = true;

    private showDeleteDocModal: boolean = false;

    private allSteps: ClientStepDropdownModel[] = [];

    private uniqueName: boolean = true;

    private isDeleting: boolean = false;

    private originalDocumentName: string = '';

    private supplierService: SupplierService;

    private complianceService: ComplianceService;

    private selectedPartners: SelectedPartnerModel[] = [];

    private signRequested: boolean = true;

    private deadlineSelected: boolean = false;

    public constructor() {
        super();
        // this.documentName = this.document.fileName.replace('.pdf', '');
        // this.originalDocumentName = this.document.fileName.replace('.pdf', '');
        this.supplierService = new SupplierService();
        this.complianceService = new ComplianceService();''
    }

    private async created(): Promise<void> {
        try {
            this.documentName = this.document.fileName.replace('.pdf', '');
            this.originalDocumentName = this.document.fileName.replace('.pdf', '');
            this.deadlineToSign = CompanyFormDropdownHelper.getDeadlineTosign();
            const allSteps = StepsHelper.getStepsByProductGroup(1);
            this.checkFieldsDebounced = debounce(this.validateField, 250, { maxWait: 250, isImmediate: true });
            allSteps.forEach(step => {
                this.allSteps.push(new ClientStepDropdownModel(step));
            });
            this.suppliersLoading = true;
            await this.fetchSuppliers();
        }
        finally {
            this.suppliersLoading = false;
        }
    }

    private async fetchSuppliers(): Promise<void>{
        if (this.$store.getters.suppliers != null && this.$store.getters.suppliers.length > 0) {
            this.suppliers = this.$store.getters.suppliers;
        }
        else{
            // const suppliers = await this.supplierService.getSuppliersAsync();
            await this.$store.dispatch(ActionTypes.SET_SUPPLIER_CERTIFICATE);
            this.suppliers = this.$store.getters.suppliers;
        }
    }

    private updateSteps(): void {
        if (this.isSCP && this.isT1SCP) {
            this.supplierType = "T1SCP_SCP_STEPS";
            return;
        }

        if (this.isT1SCP) {
            this.supplierType = "T1SCP";
            return;
        }

        if (this.isSCP) {
            this.supplierType = "SCP";
            return;
        }
    }

    private updateDeadline(value: number | string): void {
        if (value===0) {
            this.deadlineDate = '9999-01-01T00:00:00Z';
        } else {
            const now = new Date();
            now.setDate(now.getDate() + parseInt(value.toString()) * 7);
            now.setHours(23, 59, 59, 59);
            this.deadlineDate = now;
        }
    }

    private async validateField(event:any,fieldname:string): Promise<void> {
        switch (fieldname) {
            case "deadline": {
                this.deadlineSelected = true;
                this.updateDeadline(event.value);
                break;
            }
            default:
                break;
        }
    }

    private deleteDoc(): void {
        this.showDeleteDocModal = true;
    }

    private closeModal(): void {
        this.showDeleteDocModal = false;
    }

    private async removeDoc(): Promise<void> {
        try{
            this.isDeleting = true;
            const user: UserModel = this.$store.getters.user;
            const result = await this.complianceService.deleteCompolianceDoc(user.companyId, this.document.complianceDocID);
            if (result) {
                this.showDeleteDocModal = false;
                this.closePdfModal(true);
            }
        } finally {
            this.isDeleting = false;
        }
    }

    private toggleConfirmed(): void {
        this.isConfirmed = !this.isConfirmed;
    }

    private toggleT1SCPCheckbox(): void {
        this.isT1SCP = !this.isT1SCP;
        this.updateSteps();
    }

    private toggleSCPCheckbox(): void {
        this.isSCP = !this.isSCP;
        if(this.isSCP){
            this.allSteps.forEach(s => {
                this.signingSteps.push(s.stepId);
            });
        }
        else{
            this.signingSteps = [];
        }
        this.updateSteps();
    }

    private toggleAllScpCheckbox(): void {
        if(this.scpAllSelected){
            this.allSteps.forEach((s, i) => {
                this.allSteps[i].isChecked = false;
            });
        }
        else{
            this.allSteps.forEach((s, i) => {
                this.allSteps[i].isChecked = true;
            });
        }
        this.scpAllSelected = !this.scpAllSelected;

    }

    private toggleStepsCheckbox(index: number): void {
        this.allSteps[index].isChecked = !this.allSteps[index].isChecked;
        if(this.allSteps.findIndex(s => s.isChecked === false) === -1){
            this.scpAllSelected = true;
        }
        else{
            this.scpAllSelected = false;
        }
    }

    private saveSteps(): void {
        this.selectedSteps = "";
        if(this.allSteps.findIndex(s => s.isChecked === true) === -1){
            NotificationHelper.createWarningNotification(this.$t('pages.compliance.validation_atleast_one_step').toString());
            return;
        }
        else{
            this.allSteps.forEach(s => {
                if(s.isChecked){
                    this.signingSteps.push(s.stepId);
                    this.selectedSteps += s.displayName + ', ';
                }
            });
            if(this.allSteps.findIndex(s => s.isChecked === false) === -1){
                this.selectedSteps = "All steps to be signed";
            }
            this.selectedSteps = this.selectedSteps.slice(0, -2);
            this.showStepDropdown = false;
        }
    }

    private get brands(): DropdownModel[] {
        const options: DropdownModel[] = [{ value: "", text: this.$t("pages.compliance.signing_deadline").toString() }];
        return options;
    }

    private openStepSelectHandler(): void {
        this.showStepDropdown = true;
    }

    private openConfirmationPopup(): void {
        const valid = this.validateName();
        if(valid){
            this.documentName = this.documentName.replace('.pdf', '');
            this.showConfirmationPopup = true;
        }
    }

    public closeConfirmationPopup(success: boolean): void {
        this.showConfirmationPopup = false;
    }

    private hideDeadlineDropdown(): void {
        this.showDeadLine = false;
    }

    private validateName(): boolean {
        if((this.allDocuments.findIndex(d => d.documentName.replace('.pdf', '').match(this.documentName.replace('.pdf', ''))) !== -1 || this.allDocuments.findIndex(d => d.documentName.match(this.documentName)) !== -1) && this.originalDocumentName !== this.documentName){
            this.uniqueName = false;
            NotificationHelper.createWarningNotification(this.$t('global.notifications.unique_name_failed').toString());
            return false;
        }
        this.uniqueName = true;
        return true;
    }

    private openSelectPartnerModal(): void {
        this.showSelectPartnerModal = true;
    }

    private closeSelectPartnerModal(): void {
        this.showSelectPartnerModal = false;
    }

    private selectedPartnersHandler(partners: SelectedPartnerModel[]): void {
        this.selectedPartners = partners;
        if (partners.filter(p => p.isTier1===true).length > 0 && partners.filter(p => p.isTier1===false).length > 0) {
            this.supplierType = "T1SCP_SCP_STEPS";
        } else if(partners.filter(p => p.isTier1===true).length > 0 && partners.filter(p => p.isTier1===false).length == 0) {
            this.supplierType = "T1SCP";
        } else if(partners.filter(p => p.isTier1===true).length == 0 && partners.filter(p => p.isTier1===false).length > 0) {
            this.supplierType = "SCP";
        }
        this.closeSelectPartnerModal();
    }


    @Emit()
    private closePdfModal(success: boolean){}

}