import { Vue, Component } from "vue-property-decorator";

// Services
import { UserService } from "@/services/userService";
import { MiddlewareService } from "@/services/middlewareService";

// Components
import Authpage from "@/components/general/authpage.vue";

// Models
import { SigninClientModel } from "@/models/signinClientModel";

@Component({
  components: { Authpage }
})

export default class SignupSuccess extends Vue {

  private userService: UserService;

  private middlewareService: MiddlewareService;

  private showNewUserSuccess: boolean = false;

  private userCred: string | null = '';

  private isLoading: boolean = false;

  public constructor() {
    super();
    this.userService = new UserService();
    this.middlewareService = new MiddlewareService();
  }

  private async created(): Promise<void> {
    if (Object.keys(this.$route.query).length > 0){
      this.showNewUserSuccess = (this.$route.query.newUser && this.$route.query.newUser == 'true') ? true : false;

      if (!this.showNewUserSuccess){
        this.$router.push({ name: 'Signin' });
      }
      else{
        this.userCred = sessionStorage.getItem("secondUser");
      }
    }
  }

  private async signinClient(): Promise<void> {
    if (this.userCred === null) {
      return;
    }

    this.isLoading = true;
    const userCred = JSON.parse(atob(this.userCred));
    const client = new SigninClientModel();
    client.Email = userCred.email.trim();
    client.Password = userCred.password.trim();

    const response = await this.middlewareService.signinClient(client);

    switch (response.response_type) {
      case "success":
        sessionStorage.setItem("access_token", response.access_token);
        try {
          const lInfoExists = await this.userService.loadUserInfoAsync(true);
          if (lInfoExists !== 'TRUE') {
            this.isLoading = false;
          } else {
            setTimeout( (app: any) => {
              app.$router.push({ name: "Home" });
            },500, this );
          }
        } catch (e) {
          // console.log('failed to login', e);
        }
        break;
    }
    this.isLoading = false;

    return;
  }

}
