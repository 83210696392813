export class TransactionCertsModel {
    public constructor(tranCertificate1: string, tranCertificate2: string, levelTC1: string, levelTC2: string, tc1Status: string, tc2Status: string){
        this.tranCertificate1 = tranCertificate1;
        this.tranCertificate2 = tranCertificate2;
        this.levelTC1 = levelTC1;
        this.levelTC2 = levelTC2;
        this.tc1Status = tc1Status;
        this.tc2Status = tc2Status;
    }

    public tranCertificate1!: string;
    public tranCertificate2!: string;
    public levelTC1!: string;
    public levelTC2!: string;
    public tc1Status!: string;
    public tc2Status!: string;
}

export class updateTCStatusRequestModel {
    public tranCertificateRefID!: string;
    public tcStatus!: string;
    public tcType!: string;
    public rejectionReason!: string;
    public orderList!: orderListDetailsModel[];
    public tcReferenceNumber!: string;
}

export class orderListDetailsModel {
    public ID!: string;
    public tranCertificate!: string;
    public tcType!: string;
    public tcStatusValue!: string;
    public tranCertificateRefID!: string;
}

export class TcMailModel {
    public constructor(){
        this.clientCompanyName = '';
        this.clientUserFirstName = '';
        this.clientUserLastName = '';
        this.tcType = '';
        this.tcDocType = '';
        this.tcReferenceNumber = '';
        this.supplierOrderData = [];
    }
    public clientCompanyName!: string;
    public clientUserFirstName!: string;
    public clientUserLastName!: string;
    public tcType!: string;
    public tcDocType!: string;
    public tcReferenceNumber!: string;
    public supplierOrderData!: SupplierOrderDataModel[];
}

export class SupplierOrderDataModel {
    public supplierID!: string;
    public orderNumbers!: string;
}

export class TcMailRequestModel {
    public constructor(){
        this.clientCompanyName = '';
        this.clientUserFirstName = '';
        this.clientUserLastName = '';
        this.orderNumbers = '';
        this.tcType = '';
        this.supplierIDs = [];
    }
    public clientCompanyName!: string;
    public clientUserFirstName!: string;
    public clientUserLastName!: string;
    public orderNumbers!: string;
    public tcType!: string;
    public supplierIDs!: string[];
}