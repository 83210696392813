import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import moment from "moment";

// Components
import YearList from "@/components/general/year-list/yearList.vue";
import LineChart from "@/components/general/charts/lineChart/lineChart.vue";

// Modals
import { TransparencyEffortModal } from "@/models/transparencyEffortModal";
import { ChartModal } from "@/models/chartModal";

@Component({
    components:{YearList, LineChart}
})
export default class TransparencyEffortsTile extends Vue {

    @Prop()
    private orders!: TransparencyEffortModal[];

    @Prop()
    private isLoading!: boolean;

    private chartData: Object = {};

    private chartOptions: Object = {};

    private showYear: boolean = false;

    private year: string = moment().format('YYYY');

    private prev: number = 100;

    private imgSrc: NodeRequire = require("../../../assets/chart-empty.png");

    private totalStyles!: {jan:number,feb:number,mar:number,apr:number,may:number,jun:number,jul:number,aug:number,sep:number,oct:number,nov:number,dec:number};

    public constructor(){
        super();
        this.totalStyles = {jan:0,feb:0,mar:0,apr:0,may:0,jun:0,jul:0,aug:0,sep:0,oct:0,nov:0,dec:0};
    }

    private get chartdetail(): ChartModal {

        const jan = this.orders.filter(o => o.month === '1');
        const feb = this.orders.filter(o => o.month === '2');
        const mar = this.orders.filter(o => o.month === '3');
        const apr = this.orders.filter(o => o.month === '4');
        const may = this.orders.filter(o => o.month === '5');
        const jun = this.orders.filter(o => o.month === '6');
        const jul = this.orders.filter(o => o.month === '7');
        const aug = this.orders.filter(o => o.month === '8');
        const sep = this.orders.filter(o => o.month === '9');
        const oct = this.orders.filter(o => o.month === '10');
        const nov = this.orders.filter(o => o.month === '11');
        const dec = this.orders.filter(o => o.month === '12');

        const reducer = (previousValue: number, currentValue: number) => previousValue + currentValue;

        const janQuantity = jan.map(o => o.pieces);
        const janStyles = jan.map(o => o.styles);
        const janTotalQuantity = janQuantity.length === 0 ? 0 : (janQuantity.reduce(reducer))/1000;
        this.totalStyles.jan = janStyles.length === 0 ? 0 : (janStyles.reduce(reducer));

        const febQuantity = feb.map(o => o.pieces);
        const febStyles = feb.map(o => o.styles);
        const febTotalQuantity = febQuantity.length === 0 ? 0 : (febQuantity.reduce(reducer))/1000;
        this.totalStyles.feb = febStyles.length === 0 ? 0 : (febStyles.reduce(reducer));

        const marQuantity = mar.map(o => o.pieces);
        const marStyles = mar.map(o => o.styles);
        const marTotalQuantity = marQuantity.length === 0 ? 0 : (marQuantity.reduce(reducer))/1000;
        this.totalStyles.mar = marStyles.length === 0 ? 0 : (marStyles.reduce(reducer));

        const aprQuantity = apr.map(o => o.pieces);
        const aprStyles = apr.map(o => o.styles);
        const aprTotalQuantity = aprQuantity.length === 0 ? 0 : (aprQuantity.reduce(reducer))/1000;
        this.totalStyles.apr = aprStyles.length === 0 ? 0 : (aprStyles.reduce(reducer));

        const mayQuantity = may.map(o => o.pieces);
        const mayStyles = may.map(o => o.styles);
        const mayTotalQuantity = mayQuantity.length === 0 ? 0 : (mayQuantity.reduce(reducer))/1000;
        this.totalStyles.may = mayStyles.length === 0 ? 0 : (mayStyles.reduce(reducer));

        const junQuantity = jun.map(o => o.pieces);
        const junStyles = jun.map(o => o.styles);
        const junTotalQuantity = junQuantity.length === 0 ? 0 : (junQuantity.reduce(reducer))/1000;
        this.totalStyles.jun = junStyles.length === 0 ? 0 : (junStyles.reduce(reducer));

        const julQuantity = jul.map(o => o.pieces);
        const julStyles = jul.map(o => o.styles);
        const julTotalQuantity = julQuantity.length === 0 ? 0 : (julQuantity.reduce(reducer))/1000;
        this.totalStyles.jul = julStyles.length === 0 ? 0 : (julStyles.reduce(reducer));

        const augQuantity = aug.map(o => o.pieces);
        const augStyles = aug.map(o => o.styles);
        const augTotalQuantity = augQuantity.length === 0 ? 0 : (augQuantity.reduce(reducer))/1000;
        this.totalStyles.aug = augStyles.length === 0 ? 0 : (augStyles.reduce(reducer));

        const sepQuantity = sep.map(o => o.pieces);
        const sepStyles = sep.map(o => o.styles);
        const sepTotalQuantity = sepQuantity.length === 0 ? 0 : (sepQuantity.reduce(reducer))/1000;
        this.totalStyles.sep = sepStyles.length === 0 ? 0 : (sepStyles.reduce(reducer));

        const octQuantity = oct.map(o => o.pieces);
        const octStyles = oct.map(o => o.styles);
        const octTotalQuantity = octQuantity.length === 0 ? 0 : (octQuantity.reduce(reducer))/1000;
        this.totalStyles.oct = octStyles.length === 0 ? 0 : (octStyles.reduce(reducer));

        const novQuantity = nov.map(o => o.pieces);
        const novStyles = nov.map(o => o.styles);
        const novTotalQuantity = novQuantity.length === 0 ? 0 : (novQuantity.reduce(reducer))/1000;
        this.totalStyles.nov = novStyles.length === 0 ? 0 : (novStyles.reduce(reducer));

        const decQuantity = dec.map(o => o.pieces);
        const decStyles = dec.map(o => o.styles);
        const decTotalQuantity = decQuantity.length === 0 ? 0 : (decQuantity.reduce(reducer))/1000;
        this.totalStyles.dec = decStyles.length === 0 ? 0 : (decStyles.reduce(reducer));

        this.chartData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [
                {
                    backgroundColor: '#F2E9DA',
                    data: [janTotalQuantity, febTotalQuantity, marTotalQuantity, aprTotalQuantity, mayTotalQuantity, junTotalQuantity, julTotalQuantity, augTotalQuantity, sepTotalQuantity, octTotalQuantity, novTotalQuantity, decTotalQuantity]
                }
            ]
        };

        this.chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
                labels: {
                    font: {
                        family: 'work-sans-regular'
                    }
                }
            },
            tooltips: {
                enabled: true,
                usePointStyle: true,
                callbacks: {
                    label: ((tooltipItem: any, data: any) => {
                        let totalStyle = 0;
                        switch (data.labels[tooltipItem.index]){
                            case 'Jan':
                                totalStyle = this.totalStyles.jan;
                                break;

                            case 'Feb':
                                totalStyle = this.totalStyles.feb;
                                break;

                            case 'Mar':
                                totalStyle = this.totalStyles.mar;
                                break;

                            case 'Apr':
                                totalStyle = this.totalStyles.apr;
                                break;

                            case 'May':
                                totalStyle = this.totalStyles.may;
                                break;

                            case 'June':
                                totalStyle = this.totalStyles.jun;
                                break;

                            case 'July':
                                totalStyle = this.totalStyles.jul;
                                break;

                            case 'Aug':
                                totalStyle = this.totalStyles.aug;
                                break;

                            case 'Sep':
                                totalStyle = this.totalStyles.sep;
                                break;

                            case 'Oct':
                                totalStyle = this.totalStyles.oct;
                                break;

                            case 'Nov':
                                totalStyle = this.totalStyles.nov;
                                break;

                            case 'Dec':
                                totalStyle = this.totalStyles.dec;
                                break;
                        }
                        const label = "Pieces: " + (data.datasets[0].data[tooltipItem.index] * 1000) + ", Styles: " + totalStyle;
                        return label;
                    }),
                }
            },
            scales:{
                xAxes: [{
                    ticks: {
                        callback: function(val: any, index: any) {
                            return index % 3 === 0 ? val : '';
                        }
                    }
                }],
                yAxes: [{
                    ticks: {
                      min: 0,
                      reverse: false,
                      beginAtZero: true,
                      stepSize: 10,
                    }
                }]
            },
            layout: {
                padding: {
                    left: 30,
                    right: 20,
                    top: 35,
                    bottom: 65
                }
            }
        };

        const chartdetails = {'chartData': this.chartData, 'chartOptions': this.chartOptions};

        return chartdetails;
    }

    private selectedYear(year: string): void{
        this.year = year;
        this.selectedYearValue(year, 'Transparency');
        this.closeYearList();
    }

    private showYearList(){
        this.showYear = true;
    }

    private closeYearList(): void {
        this.showYear = false;
    }

    @Emit()
    private selectedYearValue(year: string, componentName: string){
    }
}