import store from '@/store';
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { Guid } from "@/models/guid";
import { StepState } from '@/models/stepState';

export class GroupStepEvidenceHelper {
  public static updateApproveStepEvidece(orderLineSteps: OrderLineStepModel[]): OrderLineStepModel[]{
    const filteredOrderLineSteps = orderLineSteps.filter(step => step.stepState !== StepState.DELETED_BY_CLIENT && step.stepState !== StepState.DELETED_BY_AGENT && step.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && step.stepState !== StepState.DELETED_BY_SUPPLIER); // TTD-4331
    filteredOrderLineSteps.sort((step1, step2) => {
      if (step1.orderId === step2.orderId) {
        // Sort sequences in descending order
        return step2.sequence - step1.sequence;
      } else {
          // If orderIDs are different, sort by orderID
          return step1.orderId.localeCompare(step2.orderId);
      }
    });
    let orderId = filteredOrderLineSteps[0].orderId;
    let orders: OrderLineDisplayModel[] = [];
    orders = store.getters.orders as OrderLineDisplayModel[];
    let fromCompanyIDToWrite = orders.filter(o => o.orderId === orderId)[0].fromCompanyUser.ID;
    let toCompanyIDToWrite = orders.filter(o => o.orderId === orderId)[0].toCompanyId;
    let toCompanyId = filteredOrderLineSteps[0].toCompanyId;
    let brandName = orders.filter(o => o.orderId === orderId)[0].brandName;
    let topSequence = true;
    let agent = '';
    let delegate = '';

    for (var i = 0; i < filteredOrderLineSteps.length; i++) {
      if (orderId == filteredOrderLineSteps[i].orderId) {
          if (toCompanyId != filteredOrderLineSteps[i].toCompanyId) {
              if (filteredOrderLineSteps[i].toCompanyId == Guid.Empty || toCompanyId == Guid.Empty) {
                filteredOrderLineSteps[i].approvalRequestedTo = "";
              }else{
                filteredOrderLineSteps[i].approvalRequestedTo = toCompanyId;
              }
              toCompanyId = filteredOrderLineSteps[i].toCompanyId;
              filteredOrderLineSteps[i].clientID = fromCompanyIDToWrite;
              filteredOrderLineSteps[i].cpSupplierID = toCompanyIDToWrite;
              agent = orders.filter(o => o.orderId === orderId)[0].agentCompanyID;
              delegate = orders.filter(o => o.orderId === orderId)[0].delegateCompanyID;
              filteredOrderLineSteps[i].agentID = (agent !== null && agent !== undefined && agent !== '') ? agent : ''; 
              filteredOrderLineSteps[i].delegatedCompanyID === "" || filteredOrderLineSteps[i].delegatedCompanyID === Guid.Empty ? filteredOrderLineSteps[i].delegatedCompanyID = (delegate !== null && delegate !== undefined && delegate !== '' && delegate !== Guid.Empty) ? delegate : Guid.Empty : Guid.Empty;
              filteredOrderLineSteps[i].brandName = brandName;
              if(topSequence){
                filteredOrderLineSteps[i].isLastStep = "true";
              } else{
                filteredOrderLineSteps[i].isLastStep = "false";
              }
              topSequence = false;
          } else {
              //logger.info("i am here -1")
              // check if prev toCompanyID is same as current company
              if (filteredOrderLineSteps[i].toCompanyId != Guid.Empty) {
                  if (topSequence == false){
                      filteredOrderLineSteps[i].approvalRequestedTo = filteredOrderLineSteps[i - 1].approvalRequestedTo;
                  }else{
                      filteredOrderLineSteps[i].approvalRequestedTo = "";
                  }
              } else if (filteredOrderLineSteps[i].toCompanyId == Guid.Empty) {
                  filteredOrderLineSteps[i].approvalRequestedTo = "";
              }
              filteredOrderLineSteps[i].clientID = fromCompanyIDToWrite;
              filteredOrderLineSteps[i].cpSupplierID = toCompanyIDToWrite;
              agent = orders.filter(o => o.orderId === orderId)[0].agentCompanyID;
              delegate = orders.filter(o => o.orderId === orderId)[0].delegateCompanyID;
              filteredOrderLineSteps[i].agentID = (agent !== null && agent !== undefined && agent !== '') ? agent : ''; 
              // TTD-2630 Sprint 18
              filteredOrderLineSteps[i].delegatedCompanyID === "" || filteredOrderLineSteps[i].delegatedCompanyID === Guid.Empty ? filteredOrderLineSteps[i].delegatedCompanyID = (delegate !== null && delegate !== undefined && delegate !== '' && delegate !== Guid.Empty) ? delegate : Guid.Empty : Guid.Empty;
              filteredOrderLineSteps[i].brandName = brandName;
              if(topSequence){
                filteredOrderLineSteps[i].isLastStep = "true";
              } else{
                filteredOrderLineSteps[i].isLastStep = "false";
              }
              topSequence = false;
          }
      } else {
          orderId = filteredOrderLineSteps[i].orderId;
          toCompanyId = filteredOrderLineSteps[i].toCompanyId;
          fromCompanyIDToWrite = orders.filter(o => o.orderId === orderId)[0].fromCompanyUser.ID;
          toCompanyIDToWrite = orders.filter(o => o.orderId === orderId)[0].toCompanyId;
          filteredOrderLineSteps[i].approvalRequestedTo = "";
          filteredOrderLineSteps[i].clientID = fromCompanyIDToWrite;
          filteredOrderLineSteps[i].cpSupplierID = toCompanyIDToWrite;
          filteredOrderLineSteps[i].isLastStep = "true";
          topSequence = false;
          agent = orders.filter(o => o.orderId === orderId)[0].agentCompanyID;
          delegate = orders.filter(o => o.orderId === orderId)[0].delegateCompanyID;
          filteredOrderLineSteps[i].agentID = (agent !== null && agent !== undefined && agent !== '') ? agent : '';
          // TTD-2630 Sprint 18
          filteredOrderLineSteps[i].delegatedCompanyID === "" || filteredOrderLineSteps[i].delegatedCompanyID === Guid.Empty ? filteredOrderLineSteps[i].delegatedCompanyID = (delegate !== null && delegate !== undefined && delegate !== '' && delegate !== Guid.Empty) ? delegate : Guid.Empty : Guid.Empty;
          filteredOrderLineSteps[i].brandName = brandName;
      }
    }
    // TTD-4331
    const deletedSteps = orderLineSteps.filter(step => step.stepState === StepState.DELETED_BY_CLIENT || step.stepState === StepState.DELETED_BY_AGENT || step.stepState === StepState.DELETED_BY_DELEGATEPARTNER || step.stepState === StepState.DELETED_BY_SUPPLIER);
    filteredOrderLineSteps.push(...deletedSteps);
    return filteredOrderLineSteps;
  }
}
