import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import moment from "moment";

// Components
// Services
import { ComplianceService } from "@/services/complianceService";
// Model
import { SocialCertificatesModel } from "@/models/socialCertificatesModel";
import { TCConstant, TCtableModel } from "@/models/tcTableModel";
import { TCDocumentModel } from "@/models/transcationCertificateModal";
import { mapState } from "vuex";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    components: { VuePdfApp},
    computed: mapState(['orders', 'companyNameList'])
})

export default class ViewTcCertificates extends Vue {

    @Prop()
    private document!: TCtableModel;
    @Prop()
    private documentDetails!: TCDocumentModel;
    @Prop()
    private selectedTC!: string;

    private complianceService: ComplianceService;
    private pdfDocument!: any;
    private isPdfReady: boolean = false;
    private isConfirming: boolean = false;
    private isConfirmingModalBtn: boolean = false;
    private rejectedReason: string = '';
    private config: any = {
        toolbar: false
    };
    private showAccept: boolean = false;
    private showReject: boolean = false;
    private orders!: OrderLineDisplayModel[];
    private companyNameList!: CompanyNameModel[];

    public constructor() {
        super();
        this.complianceService = new ComplianceService();
    }

    private async created(): Promise<void> {
        this.documentDetails.tcData.cpSupplierName = this.companyNameList.filter(c => c.ID == this.documentDetails.tcData.cpSupplierID).length > 0 ? this.companyNameList.filter(c => c.ID == this.documentDetails.tcData.cpSupplierID)[0].name : this.documentDetails.tcData.cpSupplierName;
    }

    private formatDate(dateToBeFormatted: string): string {
        return moment(dateToBeFormatted).format("D MMMM YYYY");
    }

    private async downloadPDF(): Promise<void> {
        if (this.isPdfReady) {
            this.pdfDocument.contentDispositionFilename = this.document.fileName;
            this.pdfDocument.download();
        }
    }

    private async pdfReady(pdfApp: any): Promise<void> {
        this.isPdfReady = true;
        this.pdfDocument = pdfApp;
    }

    private getOrderNumber(ID: string): string {
        const order = this.orders.find(order => order.orderId === ID);
        return order ? order.orderNumber : '';
    }

    private getStyleNumber(ID: string): string {
        const order = this.orders.find(order => order.orderId === ID);
        return order ? order.styleNumber : '';
    }

    private removeSpace(): void{
        this.rejectedReason = this.rejectedReason.trim();
    }

    private getColour(ID: string): string {
        const order = this.orders.find(order => order.orderId === ID);
        return order ? order.orderLines[0].colourway ? order.orderLines[0].colourway : '' : '';
    }

    private approve(): void {
        if(this.document.linkedOrderIDs.length > 1){
            this.showAccept = true;
        } else {
            this.approveOrReject(TCConstant.APPROVED, this.document, '');
        }
    }

    private get getTitle(): string {
        if(this.documentDetails.tcData.tcStatus === TCConstant.APPROVED){
            if(this.documentDetails.tcData.tcType === "DRAFT"){
                 return this.$t(`pages.compliance.approve_draft`).toString();
            }
            return this.$t(`pages.compliance.approve_final_tc`).toString();
        }
        if(this.documentDetails.tcData.tcStatus === TCConstant.UPLOAD){
            if(this.documentDetails.tcData.tcType === "DRAFT"){
                 return this.$t(`pages.compliance.approve_draft`).toString();
            }
            return this.$t(`pages.compliance.approve_final_tc`).toString();
        }
        if(this.documentDetails.tcData.tcStatus === TCConstant.REJECTED){
            if(this.documentDetails.tcData.tcType === "DRAFT"){
                 return this.$t(`pages.compliance.rejected_draft`).toString();
            }
            return this.$t(`pages.compliance.rejected_final_tc`).toString();
        }
        return '';
    }

    @Emit()
    private close() {
    }

    @Emit()
    private approveOrReject(confirmType: string, document: TCtableModel, rejectReason: string){
        if((this.document.linkedOrderIDs.length > 1) || confirmType === TCConstant.REJECTED){
            this.isConfirmingModalBtn = true;
        } else {
            this.isConfirming = true;
        }
    }
}
