import CryptoJS from 'crypto-js';

export class CryptionHelper {
    public async encryptText(plaintext: string): Promise<string> {
        const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_aes_key);
        const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_aes_iv);

        const encryptedText = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plaintext), key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
        return encryptedText;
    }

    public async decryptText(encodedText: string): Promise<string> {
        const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_aes_key);
        const bytes  = CryptoJS.AES.decrypt(encodedText, key);

        const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedText;
    }
}