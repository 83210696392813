import { ClientBlockchain } from '@/clients/clientBlockchain';
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';
import { ComplianceDocResponseModal } from '@/models/complianceDocResponseModal';
import { ComplianceDocSentResponseModel } from '@/models/complianceDocSentResponseModel';
import { ConfirmComplianceDocumentModal } from '@/models/confirmComplianceDocumentModal';
import { UploadedDocResponseModal } from '@/models/uploadedDocResponseModal';
import { GenericResponseModel } from '@/models/genericResponseModel';
import { TCDocDataModel, TCDocumentModel } from '@/models/transcationCertificateModal';
import { updateTCStatusRequestModel } from '@/models/transactionCertsModel';
import { TcConfirmResponseModel } from '@/models/tcResponseModel';
import { TCConstant } from "@/models/tcTableModel";

export class ComplianceService {
    private readonly clientBlockchain: ClientBlockchain = new ClientBlockchain();

    public async uploadDocAsync(uploadData: FormData): Promise<UploadedDocResponseModal[]> {
        const result = await this.clientBlockchain.uploadDocAsync(uploadData);
        if (result.success) { // Only NoContent delivers no result
            if(result.item.length > 1){
                NotificationHelper.createSucceededNotification(i18n.t('global.notifications.documents_upload_succeeded').toString());
            }else{
                NotificationHelper.createSucceededNotification(i18n.t('global.notifications.document_upload_succeeded').toString()); 
            }
            return result.item;
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.document_upload_failed').toString());
            return [];
        }
    }

    public async getDocumentsAsync(companyId: string): Promise<ComplianceDocResponseModal[]>{
        const result = await this.clientBlockchain.getDocumentsAsync(companyId);
        if (result.success) { // Only NoContent delivers no result
            return result.complianceDoc;
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.fetch_document_failed').toString());
            return [];
        }
    }

    public async confirmComplianceDocumentAsync(companyId: string, data: ConfirmComplianceDocumentModal): Promise<ComplianceDocSentResponseModel[]>{
        const result = await this.clientBlockchain.confirmComplianceDocumentAsync(companyId, data);
        if (result.success) { // Only NoContent delivers no result
            if(result.complianceDocSent.length === 0){
                NotificationHelper.createWarningNotification(i18n.t('global.notifications.document_send_warning').toString());
                return result.complianceDocSent;
            }
            else{
                NotificationHelper.createSucceededNotification(i18n.t('global.notifications.document_send_succeeded').toString());
                return result.complianceDocSent;
            }
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.document_send_failed').toString());
            return [];
        }
    }

    public async createRecepientDocAsync(companyId: string, data: ConfirmComplianceDocumentModal): Promise<ComplianceDocSentResponseModel[]>{
        const result = await this.clientBlockchain.createRecepientDocAsync(companyId, data);
        if (result.success) { // Only NoContent delivers no result
            if(result.complianceDocSent.length === 0){
                NotificationHelper.createWarningNotification(i18n.t('global.notifications.document_send_warning').toString());
                return result.complianceDocSent;
            }
            else{
                NotificationHelper.createSucceededNotification(i18n.t('global.notifications.document_send_succeeded').toString());
                return result.complianceDocSent;
            }
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.document_send_failed').toString());
            return [];
        }
    }

    public async getSentDocumentsAsync(): Promise<ComplianceDocSentResponseModel[]>{
        const result = await this.clientBlockchain.getSentDocumentsAsync();
        if (result.success) { // Only NoContent delivers no result
            return result.complianceDocClient;
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.fetch_document_failed').toString());
            return [];
        }
    }

    public async archiveComplianceDocumentAsync(companyId: string, data: object): Promise<boolean>{
        const result = await this.clientBlockchain.archiveComplianceDocumentAsync(companyId, data);
        if (result) { // Only NoContent delivers no result
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.document_archive_successfully').toString());
            return true;
        }
        return false;
    }

    public async updateNotifyDaysAsync(data: object): Promise<boolean>{
        const result = await this.clientBlockchain.updateNotifyDaysAsync(data);
        if (result) { // Only NoContent delivers no result
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.reminder_succeeded').toString());
            return true;
        }
        return false;
    }

    public async getActivityLogAsync(clientId: string, supplierId: string): Promise<ComplianceDocSentResponseModel[]>{
        const result = await this.clientBlockchain.getActivityLogAsync(clientId, supplierId);
        if (result.success) { // Only NoContent delivers no result
            return result.complianceDocSent;
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.fetch_document_failed').toString());
            return [];
        }
    }

    public async deleteCompolianceDoc(clientId: string, ID: string): Promise<boolean>{
        const result = await this.clientBlockchain.deleteCompolianceDoc(clientId, ID);
        if (result.success) { // Only NoContent delivers no result
            NotificationHelper.createSucceededNotification("Compliance document is deleted successfully.");
            return true;
        } else {
            NotificationHelper.createErrorNotification("Compliance document is not deleted, please try again.");
            return false;
        }
    }

    public async deleteSentCompolianceDoc(ID: string, scpId: string): Promise<boolean>{
        const result = await this.clientBlockchain.deleteSentCompolianceDoc(ID, scpId);
        if (result.success) { // Only NoContent delivers no result
            NotificationHelper.createSucceededNotification("Compliance document is deleted successfully.");
            return true;
        } else {
            NotificationHelper.createErrorNotification("Compliance document is not deleted, please try again.");
            return false;
        }
    }
    //Sprint-24 TTD-4433
    public async deletePendingTC(data:any): Promise<boolean>{
        const result = await this.clientBlockchain.deletePendingTC(data);
        if (result.success) { // Only NoContent delivers no result
            NotificationHelper.createSucceededNotification("Pending request is deleted successfully.");
            return true;
        } else {
            NotificationHelper.createErrorNotification("Pending request not deleted, please try again.");
            return false;
        }
    }

    //Sprint-24 TTD-4484
    public async approveRejectArchiveTC(tcData: updateTCStatusRequestModel): Promise<TcConfirmResponseModel>{
        const result = await this.clientBlockchain.approveRejectArchiveTC(tcData);
        if (result.success) {
            if(tcData.tcStatus === TCConstant.APPROVED){
                NotificationHelper.createSucceededNotification("Your request is approved successfully.");
            } else if(tcData.tcStatus === TCConstant.REJECTED) {
                NotificationHelper.createSucceededNotification("Your request is rejected successfully.");
            } else{
                NotificationHelper.createSucceededNotification("Your request is archived successfully.");
            }
            return result;
        } else {
            if(tcData.tcStatus === TCConstant.APPROVED){
                NotificationHelper.createErrorNotification("Approving request is failed. Please try again.");
            } else if(tcData.tcStatus === TCConstant.REJECTED) {
                NotificationHelper.createErrorNotification("Rejecting request is failed. Please try again.");
            }else{
                NotificationHelper.createErrorNotification("Archiving request is failed. Please try again.");
            }
            return result;
        }
    }

    //Sprint-24 TTD-4484
    public async getViewTCDocument(tcRefID: string): Promise<TCDocumentModel>{
        const result = await this.clientBlockchain.getViewTCDocument(tcRefID);
        if (result.success) {
            return result;
        } else {
            NotificationHelper.createErrorNotification("Failed to fetch document details.");
            return result;
        }
    }
}