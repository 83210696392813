import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import moment from "moment";
import { UserPermissionModel } from "@/models/permissionModel";
import { cloneDeep, result, uniqBy } from "lodash";
import * as XLSX from 'xlsx';

// Components

// Services
import { ClientRequestFootPrintData } from '@/services/footPrintRequestService';
import { SupplierService } from '@/services/supplierService';

// Helpers
import { FootprintDropdownHelper } from "@/helpers/footprintDropdownHelper";
import { CountryHelper } from "@/helpers/countryHelper";

// Models
import { CPSCPModel } from "@/models/supplierPartnerModel";
import { YarnModel } from "@/models/fabricFootprintModel";
import { FiberModel } from "@/models/yarnFootprintModel";
import { Guid } from "@/models/guid";
import { GarmentFabricComposition } from "@/models/garmentFootprintModel";
import { supplierLocationModel } from "@/models/supplierModel";

@Component({
    components: {},
    computed: mapState(['userRole', 'userPermissions', 'orderLoading', 'orders', 'orderLineStepsLoading', 'orderLineSteps', 'user', 'company', 'suppliersLoading', 'companyType', 'allAssociatedPartners'])
})
export default class InProgressData extends Vue {

    @Prop()
    private filteredOrders!: OrderLineDisplayModel[];

    @Prop()
    private showViewData!: boolean;
    //Spint-22 TTD-4212
    @Prop()
    private tabIndex!: number;

    private orders!: OrderLineDisplayModel[];

    private orderLoading!: boolean;

    private userPermissions!: UserPermissionModel | null;
    //Sprint-22 TTD-3739 from
    private visible: boolean = false;

    private isAllOrdersSelected: boolean = false;

    private isOrderSelected: boolean = false;

    private isDeletingBulk: boolean = false;

    private footPrintOrders: OrderLineDisplayModel[] = [];

    private selectedOrders: OrderLineDisplayModel[] = [];

    private footprintService: ClientRequestFootPrintData;

    private supplierService: SupplierService;

    private isCompletingFootprint: boolean = false;

    private showCompleteConfirm: boolean = false;

    private showDelete: boolean = false;

    private selectedOrderId: string = '';
    //Spint-22 TTD-4212
    private isBackToInprogress: boolean = false;

    private companyType!: number;

    private userRole!: string;

    private isShowViewButton: boolean = false;

    private allAssociatedPartners!: CPSCPModel[];

    private mergeCells: any[] = [];

    private mergeYarnCells: any[] = [];

    private mergeFabricCells: any[] = [];

    private mergeWetProcessCells: any[] = [];

    private mergeProductCells: any[] = [];

    private supplierCountry: supplierLocationModel[] = [];

    public constructor() {
        super();
        this.footprintService = new ClientRequestFootPrintData();
        this.supplierService = new SupplierService();
    }
    //Sprint-22 TTD-3739 to

    private viewData(order: OrderLineDisplayModel): void {
        this.viewFootprintData(order);
    }

    private formattedDate(date: string): string {
        if (date !== undefined && date !== "") {
            return moment(date).format("DD MMM YYYY");
        }
        else {
            return "-";
        }
    }

    private get getfootprintPermission(): boolean {
        // if (this.userPermissions !== null) {
        //     return this.userPermissions.accessFootprintScreen===1 ? true : false;
        // }
        return true;
    }
    //Sprint-22 TTD-3739 from
    private toggleDropdown(): void {
        this.visible = !this.visible;
    }

    private selectAllOrders(): void {
        this.isAllOrdersSelected = true;
        this.footPrintOrders.forEach(o => o.isFpOrderSelected = true);
    }

    private deSelectAllOrders(): void {
        this.isAllOrdersSelected = false;
        this.footPrintOrders.forEach(o => o.isFpOrderSelected = false);
    }

    private get filterOrders(): OrderLineDisplayModel[] {
        this.footPrintOrders = cloneDeep(this.filteredOrders);
        this.isAllOrdersSelected = false;
        this.isShowViewButton = this.showViewData;
        //Spint-22 TTD-4212
        this.showViewButton();
        return this.footPrintOrders;
    }

    private toggleOrderSelect(order: OrderLineDisplayModel, fpOrderIndex: number): void {
        if (order) {
            order.isFpOrderSelected = !order.isFpOrderSelected;
            if (this.footPrintOrders.every(o => o.isFpOrderSelected === true)) {
                this.isAllOrdersSelected = true;
            } else {
                this.isAllOrdersSelected = false;
            }
        }
    }

    private get getSelectedOrders(): OrderLineDisplayModel[] {
        this.selectedOrders = this.footPrintOrders.filter(o => o.isFpOrderSelected === true);
        return this.selectedOrders
    }

    private async completeFootprint(): Promise<void> {
        if (this.selectedOrderId !== '') {
            const index = this.footPrintOrders.findIndex(f => f.orderId === this.selectedOrderId);
            if (index !== -1) {
                this.selectedOrders.push(this.footPrintOrders[index]);
                this.selectedOrderId = '';
            }
        }
        this.isCompletingFootprint = true;
        const orderIdList: string[] = [];
        this.selectedOrders.forEach(o => {
            orderIdList.push(o.orderId);
        });
        const result = await this.footprintService.completeFootprintOrders(orderIdList);
        if (result) {
            this.isCompletingFootprint = false;
            this.isAllOrdersSelected = false;
            this.showCompleteConfirm = false;
        }
    }

    private async deleteFootprint(): Promise<void> {
        if (this.selectedOrderId !== '') {
            const index = this.footPrintOrders.findIndex(f => f.orderId === this.selectedOrderId);
            if (index !== -1) {
                this.selectedOrders.push(this.footPrintOrders[index]);
                this.selectedOrderId = '';
            }
        }
        this.isDeletingBulk = true;
        const orderIdList: string[] = [];
        this.selectedOrders.forEach(o => {
            orderIdList.push(o.orderId);
        });
        const result = await this.footprintService.deleteFootprintOrders(orderIdList);
        if (result) {
            this.isDeletingBulk = false;
            this.isAllOrdersSelected = false;
            this.showDelete = false;
        }
    }
    //Spint-22 TTD-4212
    private async setBackToInprogress(order: OrderLineDisplayModel): Promise<void> {
        if (order) {
            this.selectedOrders.push(order);
        }
        this.isBackToInprogress = true;
        const orderIdList: string[] = [];
        this.selectedOrders.forEach(o => {
            orderIdList.push(o.orderId);
        });
        const result = await this.footprintService.setBackToInProgress(orderIdList);
        if (result) {
            this.isAllOrdersSelected = false;
            this.isBackToInprogress = false;
        }
    }

    private showCompleteConfirmPopup(order: OrderLineDisplayModel): void {
        if (order) {
            this.selectedOrderId = order.orderId;
        }
        this.showCompleteConfirm = true;
    }

    private showDeletePopup(order: OrderLineDisplayModel): void {
        if (order) {
            this.selectedOrderId = order.orderId;
        }
        this.showDelete = true;
    }
    //Sprint-22 TTD-3739 to
    //Spint-22 TTD-4212
    private showViewButton(): void {
        if (window.innerWidth <= 1140) {
            if (this.companyType === 1 && this.userRole !== 'Reader') {
                this.isShowViewButton = false;
            }
        } else if (window.innerWidth <= 1280 && window.innerWidth > 1140) {
            if (this.companyType === 1 && this.userRole !== 'Reader') {
                this.isShowViewButton = false;
            }
        } else {
            this.isShowViewButton = true;
        }
        window.onresize = () => {
            if (window.innerWidth <= 1140) {
                if (this.companyType === 1 && this.userRole !== 'Reader') {
                    this.isShowViewButton = false;
                }
            } else if (window.innerWidth <= 1400 && window.innerWidth > 1140) {
                if (this.companyType === 1 && this.userRole !== 'Reader') {
                    this.isShowViewButton = false;
                }
            } else {
                this.isShowViewButton = true;
            }
        };
    }

    private async exportFootprint(footprintOrder: OrderLineDisplayModel): Promise<void> {
        this.exportSelectedFootprint([footprintOrder]);
    }

    private async bulkExportFootprint(): Promise<void> {
        this.exportSelectedFootprint(this.selectedOrders);
    }

    @Emit()
    private exportSelectedFootprint(footprintOrders: OrderLineDisplayModel[]): void{}

    @Emit()
    private viewFootprintData(order: OrderLineDisplayModel): void { }
}
