import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import i18n from '@/i18n';
import { TopBrandModel } from "@/models/topBrandModel";

@Component({})

export default class AddFootprintDetails extends Vue {

  @Prop()
  private brandIndex!: number;

  @Prop()
  private brandFootprintDetails!: string;

  private footprintdetails: string = "";

  public constructor() {
    super();
  }

  public async created(): Promise<void> {
    if (this.brandFootprintDetails) {
      this.footprintdetails = this.brandFootprintDetails;
    }
  }

  @Emit()
  private close(index: number, footprintdetails: string) {
  }

}