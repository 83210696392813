import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import VuePdfApp from 'vue-pdf-app';

// Model
import { mapState } from 'vuex';
import { CompanyNameModel } from '@/models/companyModel';

@Component({
  components: { VuePdfApp }
})
export default class ViewDocuments extends Vue {
  @Prop()
  private fileName!: string;

  @Prop()
  private docUrl!: string;

  private pdfDocument!: any;
  private isPdfReady: boolean = false;
  private isDownloading: boolean = false;
  private config: any = {
    toolbar: false,
  };

  public constructor() {
    super();
  }

  private created(): void {}

  private async downloadPDF(): Promise<void> {
    if (this.isPdfReady) {
      this.isDownloading = true;
      this.pdfDocument.contentDispositionFilename = this.fileName;
      this.pdfDocument.download();
      this.isDownloading = false;
    }
  }

  private async pdfReady(pdfApp: any): Promise<void> {
    this.isPdfReady = true;
    this.pdfDocument = pdfApp;
  }

  @Emit()
  private closeViewDocument(success: boolean): void {}
}
