import { ConfirmOrderCompanyModel } from "./confirmOrderCompanyModel";
import { ConfirmOrderLineModel } from "./confirmOrderLineModel";
import { DelegateCompanyUserModel } from "./delegateCompanyUserModel";
import { FileObjectModel } from "./fileObjectModel";
import { FromCompanyUserModel } from "./fromCompanyUserModel";
import { DateTimeHelper } from "@/helpers/dateTimeHelper";
import { AgentCompanyUserModel } from "./agentCompanyUserModel";

export class ConfirmOrderModel {

    public constructor () {
        this.tier1NotifiedDate = "0001-01-01T00:00:00Z";
        this.allQrCodesGenerated = false;
        this.currency = "";
        this.delegateCompanyID = "";
        this.invoiceID = "";
        this.invoicePaymentID = "";
        this.orderItemID = "";
        this.orderName = "";
        this.orderRefID = "";
        this.orderRequestID = "";
        this.paymentPeriod = "";
        this.price = 0;
        this.terms = "";
        this.timeZone = "";
        this.userID = "";
        this.vatRate = "";
        this.validateDate = "0001-01-01T00:00:00Z";
        this.cancelledAt =  "0001-01-01T00:00:00Z";
        this.acceptedAt = "0001-01-01T00:00:00Z";
        this.createdBy = "";
        this.updatedAt = DateTimeHelper.convertFormattedDateToUTC('');
        this.updatedBy = "";
        this.delegateCompanyUser = new DelegateCompanyUserModel();
        this.file = new FileObjectModel();
        this.agentCompanyID = "";
        this.tranCertificate1 = "";
        this.tranCertificate2 = "";
        this.levelTC1 = "";
        this.levelTC2 = "";
        this.license = "";
        this.tc1Status = "";
        this.tc2Status = "";
        this.deliveryTerm = "";
        this.tranCertificate1RefId = "";
        this.tranCertificate2RefId = "";
        this.customer = "";
    }

    public ID!: string;
    public acceptedAt!: string;
    public allQrCodesGenerated!: boolean;
    public cancelledAt!: string;
    public createdAt!: string;
    public createdBy!: string;
    public currency!: string;
    public delegateCompanyID!: string;
    public delegateCompanyUser!: DelegateCompanyUserModel;
    public file!: FileObjectModel;
    public fromCompanyID!: string;
    public fromCompanyUser!: FromCompanyUserModel;
    public agentCompanyUser!: AgentCompanyUserModel;
    public invoiceID!: string;
    public invoicePaymentID!: string;
    public orderItemID!: string;
    public orderLines!: ConfirmOrderLineModel[];
    public orderName!: string;
    public orderNumber!: string;
    public orderRefID!: string;
    public orderRequestID!: string;
    public paymentPeriod!: string;
    public price!: number;
    public terms!: string;
    public tier1NotifiedDate!: string;
    public timeZone!: string;
    public toCompany!: ConfirmOrderCompanyModel;
    public toCompanyID!: string;
    public updatedAt!: string;
    public updatedBy!: string;
    public userID!: string;
    public validateDate!: string;
    public vatRate!: string;
    public agentCompanyID!: string;
    //Sprint-24 TTD-4483
    public tranCertificate1!: string;
    public tranCertificate2!: string;
    public levelTC1!: string;
    public levelTC2!: string;
    public license!: string;
    public deliveryTerm!: string;
    public tc1Status!: string;
    public tc2Status!: string;
    public tranCertificate1RefId!: string;
    public tranCertificate2RefId!: string; 
    public customer!: string;
}