import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { mapState } from 'vuex';
import lodash, { cloneDeep } from "lodash";
import { debounce } from "ts-debounce";
import { v4 as uuidv4 } from 'uuid';

// Components
import VuePdfApp from "vue-pdf-app";

// Services
import { ClientRequestFootPrintData } from "@/services/footPrintRequestService";

// Helpers
import { FootprintDropdownHelper } from "@/helpers/footprintDropdownHelper";
import { FootprintPermissionsHelper } from "@/helpers/footprintPermissionsHelper";

// Modals
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { WetProcessFootprintModel, DyingTreatmentModel, PrintTreatmentModel, PreTreatmentModel, CoatingOrFinishingModel, WetProcessFootprintRequestModel, DocumentModel, YarnWetProcessModel,
		WetprocessModel, FabricWetprocessModel, ThermalEnergyModel, WetProcessStepsDataModel } from "@/models/wetProcessFootprintModel";
import { UserModel } from "@/models/userModel";
import { DropdownModel } from "@/models/dropdownModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { FootprintPartnerModel, OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { TransportFootprintModel, ModeOfTransportModel, TransportFootprintRequestModel } from "@/models/transportFootprintModel";
import { DeleteDocRequestModel, FootPrintCompletedConstant, FootPrintConstant, FootPrintChangedConstant, FootPrintChangedTabConstant, FootprintDataResponseModel } from "@/models/footprintModel";
import { Guid } from "@/models/guid";
import { FootprintStepModel } from "@/models/footprintStepModel";
import { StepReferenceModel } from "@/models/orderLineStepModel";

@Component({
    components: { VuePdfApp },
	computed: mapState(['orderLoading', 'companyType', 'orders', 'suppliers', 'user', 'userRole', 'inprogressOrderStepsLoading', 'completedOrderStepsLoading', 'footprintPartner', 'isFootprintChanged'])
})
export default class WetProcessFootprint extends Vue {

	@Prop()
	private orderId!: string;

	@Prop()
	private deadline!: string;

	@Prop()
	private allFootprintSteps!:StepReferenceModel[];
	//Spint-22 TTD-4212
	@Prop()
	private tabIndex!: number;

	@Prop()
	private activeStep!: string;

	private clientRequestFootPrintData: ClientRequestFootPrintData;

	private wetProcessFootprint: WetProcessFootprintModel[] = [];

	private inprogressOrderStepsLoading!: boolean;

	private companyType!: number;

	private completedOrderStepsLoading!: boolean;

	private orders!: OrderLineDisplayModel[];

	private footprintPartner!: FootprintPartnerModel[];

	private user!: UserModel;

	private suppliers!: OrderCompanyModel[];

	private orderRelatedPartners: OrderCompanyModel[] = [];

	private showNamePartnerList: boolean = false;

	private yarnIndex: number = -1;

	private spinningMethodList: DropdownModel[] = [];

	private unitList: DropdownModel[] = [];

	private wetTransportChangedCounter: number = 0;

	private shadesList: DropdownModel[] = [];

	private sourcesList: DropdownModel[] = [];

	private typesOfDye: DropdownModel[] = [];

	private typesofCoating: DropdownModel[] = [];

	private processForDyeing: DropdownModel[] = [];

	private typesofPrinting: DropdownModel[] = [];

	private processForPrinting: DropdownModel[] = [];

	private processForPreTreatment: DropdownModel[] = [];

	private wetProcessFootprintClone: WetProcessFootprintModel[] = [];

	private wetProcessFootprintCloneInitialState: WetProcessFootprintModel[] = [];

	private changedCounter: number = 0;

    private checkFieldsDebounced: any;

	private disableSave: boolean = false;

	private showUploadDocModal: boolean = false;

	private accordianId: string = "";

	private accordianType: string = "";

	private docFile: any = "";

	private isDocUploading: boolean = false;

	private docPreview: DocumentModel = new DocumentModel();

	private pdfDocument!: any;

    private isPdfReady: boolean = false;

	private showPdfPreviewModal: boolean = false;

	private showWetTransport: boolean = false;

	private isSaving: boolean = false;

	private isMsdsDoc: boolean = false;

	private isDeletingDoc: boolean = false;

	private showWetFootprint: boolean = false;

	private showYarnTransport: boolean = false;

	private footprintStatus: string = 'red';

	private transportStatus: string = 'red';

	private showMsdsPdfPreviewModal: boolean = false;

	private modeOfTransportList: DropdownModel[] = [];

	private transportUnitList: DropdownModel[] = [];

	private wetTransport: TransportFootprintModel[] = [];

	private wetTransportClone: TransportFootprintModel[] = [];

	private showDeleteModal: boolean = false;

	private showMsdsDeleteModal: boolean = false;

	private showWarningModal: boolean = false;

	private transportToBeDeleted: boolean = false;

	private wetProcessDeleIndex: string ='';

	private wetProcessDeleDocIndex: string = '';

	private deleteDocType: string = '';
	private wetTransportCloneInitialState: TransportFootprintModel[] = [];

	private wetProcessDeletingDocIndex: number = 0;

	private isTabRemoved: boolean = false;

	private userRole!: string;

	private isWetProcessFieldEmpty: boolean = false;

	private isFootprintChanged!: string;

	private transportFieldEmpty: boolean = false;

	private selectedTreatmentType: string = '';

	private yarnRefList: DropdownModel[] = [];

	private fabricRefList: DropdownModel[] = [];

	private showWarningForTreatment: boolean = false;

	private companyName: string = '';

	private wetIndex: number = 0;

	private treatmentTypeIndex: number = 0;

	private treatmentIndex: number = 0;

	private componentId: string = '';

	private typeOfDocUpload: string = '';

	private treatmentDeletedCounter: number = 0;

	private typeOfTreatment: DropdownModel[] = [
		{ value: "YARN", text: "Yarn dye" },
		{ value: "FABRIC", text: "Fabric dye" }
	];

	private stepSequenceArr: number[] = [];

	private footprintOrder!: OrderLineDisplayModel;

	private userCompanyId!: string;

	private isCancelBtnEnable: boolean = false;

	private wetProcessWrapperData: WetProcessStepsDataModel[]= [];

	private activeWetStep: string = Guid.Empty;

	private config: any = {
        toolbar: false
    };

	public constructor() {
		super();
		this.clientRequestFootPrintData = new ClientRequestFootPrintData();
	}

	private wetProcessFootprintSteps: StepReferenceModel[]= [];

	private async created(): Promise<void> {
		this.userCompanyId = this.user.companyId !== undefined && this.user.companyId !== '' ? this.user.companyId : Guid.Empty;
		this.spinningMethodList = FootprintDropdownHelper.getSpinningMethodList();
		this.unitList = FootprintDropdownHelper.getUnitList();
		this.shadesList = FootprintDropdownHelper.getShadesList();
		this.sourcesList = FootprintDropdownHelper.getSourcesList();
		this.typesOfDye = FootprintDropdownHelper.getTypesofDye();
		this.typesofCoating = FootprintDropdownHelper.getTypesofCoating();
		this.processForDyeing = FootprintDropdownHelper.getProcessForDyeing();
		this.typesofPrinting = FootprintDropdownHelper.getTypesofPrinting();
		this.processForPrinting = FootprintDropdownHelper.getProcessForPrinting();
		this.processForPreTreatment = FootprintDropdownHelper.getProcessForPreTreatment();
		this.modeOfTransportList = FootprintDropdownHelper.getModeOfTransportList();
		this.transportUnitList = FootprintDropdownHelper.getTransportUnit();
		this.wetProcessFootprintClone = lodash.cloneDeep(this.wetProcessFootprint);
		this.checkFieldsDebounced = debounce(this.validateField, 250, { maxWait: 250, isImmediate: true });
		const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		this.footprintOrder = order;
		// TTD-2761 sprint-19 development
		if(order.yarn.footprint.length > 0){
            order.yarn.footprint.forEach(yarn => {
				if(yarn.reference.filledByCompanyID!==Guid.Empty){
					this.yarnRefList.push(new DropdownModel(yarn.ID, yarn.reference.referenceNo));
				}
            });
        }
		if(order.fabric.fabricFootprint.length > 0){
            order.fabric.fabricFootprint.forEach(fabric => {
				if(fabric.reference.filledByCompanyID!==Guid.Empty){
					this.fabricRefList.push(new DropdownModel(fabric.ID, fabric.reference.referenceNo));
				}
            });
        }
		if(order.wetprocess.wetprocessFootprint.length > 0){
			this.wetProcessFootprint = lodash.cloneDeep(order.wetprocess.wetprocessFootprint);
		}
		if(order.wetprocess.transport.length > 0){
			this.wetTransport = lodash.cloneDeep(order.wetprocess.transport);
			this.defaultWetTransport();
		}
		this.createWetProcessCards();
		this.wetProcessFootprintClone = lodash.cloneDeep(this.wetProcessFootprint);
		this.wetTransportClone = lodash.cloneDeep(this.wetTransport);
		this.wetTransportCloneInitialState = lodash.cloneDeep(this.wetTransport);
		this.wetProcessFootprintCloneInitialState = lodash.cloneDeep(this.wetProcessFootprint);
		this.wetFpStatus();
		this.wetTpStatus();
		this.checkNonEmptyFields();
	}

	private isFilledByPrimarySource(filledBy: string, toCompanyID: string): boolean {
		//Spint-22 TTD-4212
		if(this.tabIndex === 1){
			return true;
		}
		if(this.user && this.user.companyType === 1){
			if((filledBy !== Guid.Empty || filledBy !== '') && (filledBy === toCompanyID) && (toCompanyID !== this.userCompanyId)){
				return true;
			}
			return false;
		} else {
			if (filledBy === Guid.Empty || filledBy === '') {
				return false;
			} else if((filledBy !== Guid.Empty || filledBy !== '') && (filledBy === this.userCompanyId || this.userCompanyId === toCompanyID)) {
				return false;
			} else {
				return true;
			}
		}
		// return filledBy !== Guid.Empty && filledBy !== '' && filledBy === toCompanyID ? filledBy === this.userCompanyId ? false : true : false;
	}

	private canDeleteDyeingFootprint(dyeing: DyingTreatmentModel, toCompanyID:string): boolean {
		if(this.userCompanyId === toCompanyID || dyeing.filledByCompanyID === this.userCompanyId){
			return true;
		}
		return false;
	}

	private canDeletePreFootprint(pre: PreTreatmentModel, toCompanyID:string): boolean {
		if(this.userCompanyId === toCompanyID || pre.filledByCompanyID === this.userCompanyId){
			return true;
		}
		return false;
	}

	private canDeleteCoatingFootprint(coating: CoatingOrFinishingModel, toCompanyID:string): boolean {
		if(this.userCompanyId === toCompanyID || coating.filledByCompanyID === this.userCompanyId){
			return true;
		}
		return false;
	}

	private canDeletePrintingFootprint(printing: PrintTreatmentModel, toCompanyID:string): boolean {
		if(this.userCompanyId === toCompanyID || printing.filledByCompanyID === this.userCompanyId){
			return true;
		}
		return false;
	}

	private canDeleteFabricFootprint(fabric: FabricWetprocessModel, toCompanyID:string): boolean {
		if(this.userCompanyId === toCompanyID || fabric.filledByCompanyID === this.userCompanyId){
			return true;
		}
		return false;
	}

	private canDeleteYarnFootprint(yarn: YarnWetProcessModel, toCompanyID:string): boolean {
		if(this.userCompanyId === toCompanyID || yarn.filledByCompanyID === this.userCompanyId){
			return true;
		}
		return false;
	}

	private canDeleteTransport(filledBy: string, toCompanyID:string): boolean {
		if(this.userCompanyId === toCompanyID || filledBy === this.userCompanyId){
			return true;
		}
		return false;
	}

	private isFiledByClient(filledBy: string): boolean {
		//TTD-4276 issue3
		if(this.footprintOrder.fromCompanyUser.ID === filledBy && this.tabIndex !==1){
			return true;
		}
		return false;
	}

	private wetFpStatus(): void {
		if(this.wetProcessFootprint.every(wp => wp.status === FootPrintCompletedConstant.COMPLETED && this.wetProcessWrapperData.findIndex(wrp => wrp.stepID === wp.stepID)!==-1)){
			this.footprintStatus = 'green';
		}else if(this.wetProcessFootprint.filter(wp => wp.status === FootPrintCompletedConstant.PARTIAL && this.wetProcessWrapperData.findIndex(wrp => wrp.stepID === wp.stepID)!==-1).length > 0 || this.wetProcessFootprint.filter(wp => wp.status === FootPrintCompletedConstant.COMPLETED && this.wetProcessWrapperData.findIndex(wrp => wrp.stepID === wp.stepID)!==-1).length > 0){
			this.footprintStatus = 'orange';
		}else{
			this.footprintStatus = 'red';
		}
	}

	private get agentPermissions(): boolean {
		return FootprintPermissionsHelper.agentFootprintRole(this.companyType);
	}

	private wetTpStatus(): void {
		if(this.wetTransport.every(wp => wp.status === FootPrintCompletedConstant.COMPLETED && this.wetProcessWrapperData.findIndex(wrp => wrp.stepID === wp.stepID)!==-1)){
			this.transportStatus = 'green';
		}else if(this.wetTransport.filter(wp => wp.status === FootPrintCompletedConstant.COMPLETED && this.wetProcessWrapperData.findIndex(wrp => wrp.stepID === wp.stepID)!==-1).length > 0 || this.wetTransport.filter(wp => wp.status === FootPrintCompletedConstant.PARTIAL && this.wetProcessWrapperData.findIndex(wrp => wrp.stepID === wp.stepID)!==-1).length > 0){
			this.transportStatus = 'orange';
		}else{
			this.transportStatus = 'red';
		}
	}

	private clearWetFootprintData(): void {
		if ((this.changedCounter > 0 || this.isTabRemoved) || this.isCancelBtnEnable) {
			this.wetProcessFootprint = lodash.cloneDeep(this.wetProcessFootprintCloneInitialState);
			this.wetProcessFootprintClone = lodash.cloneDeep(this.wetProcessFootprintCloneInitialState);
			if(!this.isCancelBtnEnable){
				this.defaultWetTransport();
			}
			this.changedCounter = 0;
			this.treatmentDeletedCounter = 0;
			this.$store.commit("setFootprintChanged", '');
			this.$store.commit("setChangedFootprintName", '');
			this.$store.commit('setIncorrectFootprintData', false);
			this.isTabRemoved = false;
			this.isCancelBtnEnable = false;
		}
	}

	private clearWetTransportData() {
		if (this.wetTransportChangedCounter > 0) {
			this.wetTransport = lodash.cloneDeep(this.wetTransportCloneInitialState);
			this.wetTransportCloneInitialState.forEach((i, index) => {
				this.wetTransportCloneInitialState.length-1 === index? i.showTransport=true: i.showTransport=false;
			});
			this.wetTransportClone = lodash.cloneDeep(this.wetTransportCloneInitialState);
			this.wetTransportChangedCounter = 0;
			this.$store.commit("setFootprintChanged", '');
			this.$store.commit("setChangedFootprintName", '');
			this.$store.commit('setIncorrectFootprintData', false);
		}
		this.compareWetTransportClone();
	}

	private defaultWetTransport(): void {
		this.wetTransport.forEach(transport => {
			transport.modeOfTransport.forEach(m => {
				if(m.distance.value === -1){
					m.distance.value = '';
				}
				m.distance.isValid = true;
				m.transportType.isValid = true;
				m.distanceUnit.isValid = true;
			});
		});
		this.wetTransport[this.wetTransport.length-1].showTransport = true;
		this.wetTransportClone = lodash.cloneDeep(this.wetTransport);
		this.wetTransportCloneInitialState = lodash.cloneDeep(this.wetTransport);
		this.wetTpStatus();
		this.checkTransportEmptyFields();
		this.showWetTransport = false;
	}

	private createWetProcessCards(): void {
		// let filteredSteps: OrderLineStepDisplayModel[] = [];
		let mcSteps: StepReferenceModel[] = [];
		let pcSteps: StepReferenceModel[] = [];
		mcSteps = this.allFootprintSteps.filter(a => (a.stepId === 6 || a.stepId === 7 || a.stepId === 8 || a.stepId === 31) && a.pcStepUniqueId === Guid.Empty);
		pcSteps = this.allFootprintSteps.filter(a => (a.stepId === 6 || a.stepId === 7 || a.stepId === 8 || a.stepId === 31) && a.pcStepUniqueId !== Guid.Empty);
		if(mcSteps.length === 0 && pcSteps.length === 0){
			// this.showNoWetProcess = true;
		} else {
			if(mcSteps.length > 0){
				mcSteps.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1);
				this.filterArray(mcSteps);		
			}
			if(pcSteps.length > 0){
				pcSteps.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1);
				const mcStepUnique = lodash.uniqBy(pcSteps, 'mcStepUniqueId');
				mcStepUnique.forEach(mc => {
					const pc0Steps = pcSteps.filter(f => f.mcStepUniqueId === mc.mcStepUniqueId && f.pcOfMC === 0);
					if(pc0Steps.length > 0){
						this.filterParallelChain(pc0Steps);
						const pc1Steps = pcSteps.filter(f => f.mcStepUniqueId === mc.mcStepUniqueId && f.pcOfMC === 1);
						if(pc1Steps.length > 0){
							this.filterParallelChain(pc1Steps);
							const pc2Steps = pcSteps.filter(f => f.mcStepUniqueId === mc.mcStepUniqueId && f.pcOfMC === 2);
							if(pc2Steps.length > 0){
								this.filterParallelChain(pc2Steps);
								const pc3Steps = pcSteps.filter(f => f.mcStepUniqueId === mc.mcStepUniqueId && f.pcOfMC === 3);
								if(pc3Steps.length > 0){
									this.filterParallelChain(pc3Steps);
									const pc4Steps = pcSteps.filter(f => f.mcStepUniqueId === mc.mcStepUniqueId && f.pcOfMC === 4);
									if(pc4Steps.length > 0){
										this.filterParallelChain(pc4Steps);
									}
								}
							}
						}
					}
				})
			}
			this.createWetProcessWrapperData();
		}
		this.showWetFootprint = true;
	}

	private createWetProcessWrapperData(): void {
		if(this.wetProcessWrapperData.length > 0){
			this.wetProcessWrapperData.forEach((wpWrapper) => {
				if(this.wetProcessFootprint.length > 0){
					this.groupSavedWetFootprint(wpWrapper);
				} else {
					this.defaultWetProcessFootprint(wpWrapper);
				}
				if(this.wetTransport.length > 0){
					this.groupSavedWetTransport(wpWrapper);
				} else {
					this.defaultWetProcessTransport(wpWrapper);
				}
				wpWrapper.fabricRefList = [];
				wpWrapper.yarnRefList = [];
				const wpSteps = wpWrapper.stepID.split(',');
				if(this.allFootprintSteps.filter(s => s.mcStepUniqueId === wpSteps[0] && s.pcStepUniqueId === Guid.Empty).length > 0){
					if(this.footprintOrder.yarn.footprint.length > 0){
						const yarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && (s.stepId === 3 || s.stepId === 22));
						if(yarnStep.length > 0){
							yarnStep.forEach(ys => {
								const mcYarnStep = this.footprintOrder.yarn.footprint.filter(y => y.stepID === ys.mcStepUniqueId);
								if(mcYarnStep.length > 0){
									mcYarnStep.forEach(y => {
										if(y.reference.filledByCompanyID!==Guid.Empty){ 
											wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
										}
									})
								}
							})
						}
					}
					if(this.footprintOrder.fabric.fabricFootprint.length > 0){
						const fabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
						if(fabricStep.length > 0){
							fabricStep.forEach(ys => {
								const mcFabricStep = this.footprintOrder.fabric.fabricFootprint.filter(y => y.stepID === ys.mcStepUniqueId);
								if(mcFabricStep.length > 0){
									mcFabricStep.forEach(f => {
										if(f.reference.filledByCompanyID!==Guid.Empty){
											wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
										}
									})
								}
							})
						}
					}
					wpSteps.forEach((stepId) => {
						if(this.allFootprintSteps.filter(s => s.mcStepUniqueId === stepId && s.pcStepUniqueId !== Guid.Empty).length > 0){
							if(this.footprintOrder.yarn.footprint.length > 0){
								const pc0YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === stepId && s.pcOfMC === 0 && (s.stepId === 3 || s.stepId === 22));
								if(pc0YarnStep.length > 0){
									pc0YarnStep.forEach(pc0 => {
										const pc0Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc0.pcStepUniqueId);
										if(pc0Yarn.length > 0){
											pc0Yarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc1YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === stepId && s.pcOfMC === 1 && (s.stepId === 3 || s.stepId === 22));
								if(pc1YarnStep.length > 0){
									pc1YarnStep.forEach(pc1 => {
										const pc1Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc1.pcStepUniqueId);
										if(pc1Yarn.length > 0){
											pc1Yarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc2YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === stepId && s.pcOfMC === 2 && (s.stepId === 3 || s.stepId === 22));
								if(pc2YarnStep.length > 0){
									pc2YarnStep.forEach(pc2 => {
										const pc2Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc2.pcStepUniqueId);
										if(pc2Yarn.length > 0){
											pc2Yarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc3YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === stepId && s.pcOfMC === 3 && (s.stepId === 3 || s.stepId === 22));
								if(pc3YarnStep.length > 0){
									pc3YarnStep.forEach(pc3 => {
										const pc3Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc3.pcStepUniqueId);
										if(pc3Yarn.length > 0){
											pc3Yarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc4YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === stepId && s.pcOfMC === 4 && (s.stepId === 3 || s.stepId === 22));
								if(pc4YarnStep.length > 0){
									pc4YarnStep.forEach(pc4 => {
										const pc4Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc4.pcStepUniqueId);
										if(pc4Yarn.length > 0){
											pc4Yarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											})
										}
									})
								}
							}
							if(this.footprintOrder.fabric.fabricFootprint.length > 0){
								const pc0FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === stepId && s.pcOfMC === 0 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(pc0FabricStep.length > 0){
									pc0FabricStep.forEach(pc0 => {
										const pc0Fabric = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pc0.pcStepUniqueId);
										if(pc0Fabric.length > 0){
											pc0Fabric.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc1FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === stepId && s.pcOfMC === 1 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(pc1FabricStep.length > 0){
									pc1FabricStep.forEach(pc1 => {
										const pc1Fabric = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pc1.pcStepUniqueId);
										if(pc1Fabric.length > 0){
											pc1Fabric.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc2FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === stepId && s.pcOfMC === 2 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(pc2FabricStep.length > 0){
									pc2FabricStep.forEach(pc2 => {
										const pc2Fabric = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pc2.pcStepUniqueId);
										if(pc2Fabric.length > 0){
											pc2Fabric.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc3FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === stepId && s.pcOfMC === 3 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(pc3FabricStep.length > 0){
									pc3FabricStep.forEach(pc3 => {
										const pc3Fabric = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pc3.pcStepUniqueId);
										if(pc3Fabric.length > 0){
											pc3Fabric.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc4FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === stepId && s.pcOfMC === 4 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(pc4FabricStep.length > 0){
									pc4FabricStep.forEach(pc4 => {
										const pc4Fabric = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pc4.pcStepUniqueId);
										if(pc4Fabric.length > 0){
											pc4Fabric.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											})
										}
									})
								}
							}
						}
					})
					if(wpWrapper.yarnRefList.length === 0){
						wpWrapper.yarnRefList.push(new DropdownModel(Guid.Empty, 'No yarn data available'));
						wpWrapper.yarnRefList[0].disabled = true;
					} else {
						wpWrapper.yarnRefList = lodash.uniqBy(wpWrapper.yarnRefList, 'text');
					}
					if(wpWrapper.fabricRefList.length === 0){
						wpWrapper.fabricRefList.push(new DropdownModel(Guid.Empty, 'No fabric data available'));
						wpWrapper.fabricRefList[0].disabled = true;
					} else {
						wpWrapper.fabricRefList = lodash.uniqBy(wpWrapper.fabricRefList, 'text');
					}
				} else {
					wpSteps.forEach((stepId) => {
						const step = this.allFootprintSteps.filter(s => s.pcStepUniqueId === stepId);
						if(step.length > 0){
							if(this.footprintOrder.yarn.footprint.length > 0){
								const pc0YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && s.pcOfMC === 0 && (s.stepId === 3 || s.stepId === 22));
								if(pc0YarnStep.length > 0){
									pc0YarnStep.forEach(pc0 => {
										const pc0Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc0.pcStepUniqueId);
										if(pc0Yarn.length > 0){
											pc0Yarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc1YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && s.pcOfMC === 1 && (s.stepId === 3 || s.stepId === 22));
								if(pc1YarnStep.length > 0){
									pc1YarnStep.forEach(pc1 => {
										const pc1Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc1.pcStepUniqueId);
										if(pc1Yarn.length > 0){
											pc1Yarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc2YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && s.pcOfMC === 2 && (s.stepId === 3 || s.stepId === 22));
								if(pc2YarnStep.length > 0){
									pc2YarnStep.forEach(pc2 => {
										const pc2Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc2.pcStepUniqueId);
										if(pc2Yarn.length > 0){
											pc2Yarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc3YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && s.pcOfMC === 3 && (s.stepId === 3 || s.stepId === 22));
								if(pc3YarnStep.length > 0){
									pc3YarnStep.forEach(pc3 => {
										const pc3Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc3.pcStepUniqueId);
										if(pc3Yarn.length > 0){
											pc3Yarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc4YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && s.pcOfMC === 4 && (s.stepId === 3 || s.stepId === 22));
								if(pc4YarnStep.length > 0){
									pc4YarnStep.forEach(pc4 => {
										const pc4Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc4.pcStepUniqueId);
										if(pc4Yarn.length > 0){
											pc4Yarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											})
										}
									})
								}
								const parentYarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && (s.stepId === 3 || s.stepId === 22));
								if(parentYarnStep.length > 0){
									parentYarnStep.forEach(pys => {
										const parentYarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pys.mcStepUniqueId);
										if(parentYarn.length > 0){
											parentYarn.forEach(y => {
												if(y.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
												}
											});
										}
									});
								}
							}
							if(this.footprintOrder.fabric.fabricFootprint.length > 0){
								const pc0FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && s.pcOfMC === 0 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(pc0FabricStep.length > 0){
									pc0FabricStep.forEach(pc0 => {
										const pc0Fabric = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pc0.pcStepUniqueId);
										if(pc0Fabric.length > 0){
											pc0Fabric.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc1FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && s.pcOfMC === 1 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(pc1FabricStep.length > 0){
									pc1FabricStep.forEach(pc1 => {
										const pc1Fabric = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pc1.pcStepUniqueId);
										if(pc1Fabric.length > 0){
											pc1Fabric.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc2FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && s.pcOfMC === 2 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(pc2FabricStep.length > 0){
									pc2FabricStep.forEach(pc2 => {
										const pc2Fabric = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pc2.pcStepUniqueId);
										if(pc2Fabric.length > 0){
											pc2Fabric.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc3FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && s.pcOfMC === 3 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(pc3FabricStep.length > 0){
									pc3FabricStep.forEach(pc3 => {
										const pc3Fabric = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pc3.pcStepUniqueId);
										if(pc3Fabric.length > 0){
											pc3Fabric.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											})
										}
									})
								}
								const pc4FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && s.pcOfMC === 4 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(pc4FabricStep.length > 0){
									pc4FabricStep.forEach(pc4 => {
										const pc4Fabric = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pc4.pcStepUniqueId);
										if(pc4Fabric.length > 0){
											pc4Fabric.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											})
										}
									})
								}
								const parentFabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && s.mcStepUniqueId === step[0].mcStepUniqueId && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
								if(parentFabricStep.length > 0){
									parentFabricStep.forEach(pfs => {
										const fabricParent = this.footprintOrder.fabric.fabricFootprint.filter(f => f.stepID === pfs.mcStepUniqueId);
										if(fabricParent.length > 0){
											fabricParent.forEach(f => {
												if(f.reference.filledByCompanyID!==Guid.Empty){ 
													wpWrapper.fabricRefList.push(new DropdownModel(f.ID, f.reference.referenceNo));
												}
											});
										}
									});
								}
							}
						}
					})
					if(wpWrapper.yarnRefList.length === 0){
						wpWrapper.yarnRefList.push(new DropdownModel(Guid.Empty, 'No yarn data available'));
						wpWrapper.yarnRefList[0].disabled = true;
					} else {
						wpWrapper.yarnRefList = lodash.uniqBy(wpWrapper.yarnRefList, 'text');
					}
					if(wpWrapper.fabricRefList.length === 0){
						wpWrapper.fabricRefList.push(new DropdownModel(Guid.Empty, 'No fabric data available'));
						wpWrapper.fabricRefList[0].disabled = true;
					} else {
						wpWrapper.fabricRefList = lodash.uniqBy(wpWrapper.fabricRefList, 'text');
					}
				}
			});
			this.wetProcessWrapperData[0].showWetProcess = true;
		}
	}

	private groupSavedWetFootprint(wpWrapper: WetProcessStepsDataModel): void {
		let wetProcessIndex = -1;
		const stepIDs = wpWrapper.stepID.split(",");
		if(this.wetProcessFootprint.filter(wp => wp.stepID === stepIDs[0]).length > 0){
			stepIDs.forEach((id, index) => {
				if(index === 0){
					wetProcessIndex = this.wetProcessFootprint.findIndex(wp => wp.stepID === id);
					if(this.wetProcessFootprint[wetProcessIndex].yarnWetprocess.length > 0){
						this.wetProcessFootprint[wetProcessIndex].yarnWetprocess.forEach((yw, ywIndex) => {
							yw.reference.isValid = true;
							if(yw.dyiengTreatment.length > 0){
								yw.dyiengTreatment.forEach((dyeing, dIndex) => {
									// dyeing.toCompanyUser.isValid = true;
									dyeing.reference.isValid = true;
									dyeing.process.isValid = true;
									dyeing.dyeType.isValid = true;
									dyeing.shade.isValid = true;
									dyeing.waterUsedInFabric.isValid = true;
									dyeing.totalElectricEnergy.isValid = true;
									dyeing.windEnergy.isValid = true;
									dyeing.solarEnergy.isValid = true;
									dyeing.thermalEnergyNew.forEach((te) =>{
										te.isValid = true;
									});
									dyeing.thermalEnergySourceNew.forEach((ts) =>{
										ts.isValid = true;
									});
									dyeing.energyUsed.isValid = true;
									dyeing.showWetDyeing = false;
									if(dyeing.energyUsed.value === -1){
										dyeing.energyUsed.value = '';
									}
									if(dyeing.windEnergy.value === -1){
										dyeing.windEnergy.value = '';
									}
									if(dyeing.solarEnergy.value === -1){
										dyeing.solarEnergy.value = '';
									}
									dyeing.thermalEnergyNew.forEach((te) => {
										if(te.value === -1){
											te.value = '';
										}
									});
								});
							}
							if(yw.preTreatment.length > 0){
								yw.preTreatment.forEach((pre, preIndex) => {
									pre.toCompanyUser.isValid = true;
									pre.reference.isValid = true;
									pre.process.isValid = true;
									pre.waterUsedInFabric.isValid = true;
									pre.totalElectricEnergy.isValid = true;
									pre.windEnergy.isValid = true;
									pre.solarEnergy.isValid = true;
									pre.thermalEnergyNew.forEach((pr) =>{
										pr.isValid = true;
										if(pr.value === -1){
											pr.value = '';
										}
									});
									pre.thermalEnergySourceNew.forEach((pr) =>{
										pr.isValid = true;
									});
									pre.energyUsed.isValid = true;
									pre.showWetPre = false;
									if(pre.energyUsed.value === -1){
										pre.energyUsed.value = '';
									}
									if(pre.windEnergy.value === -1){
										pre.windEnergy.value = '';
									}
									if(pre.solarEnergy.value === -1){
										pre.solarEnergy.value = '';
									}
								});
							}
							if(yw.coatingFinishing.length > 0){
								yw.coatingFinishing.forEach((coating, cIndex) => {
									coating.toCompanyUser.isValid = true;
									coating.reference.isValid = true;
									coating.coatingOrFinishing.isValid = true;
									coating.waterUsedInFabric.isValid = true;
									coating.totalElectricEnergy.isValid = true;
									coating.windEnergy.isValid = true;
									coating.solarEnergy.isValid = true;
									coating.thermalEnergyNew.forEach((co) =>{
										co.isValid = true;
									});
									coating.thermalEnergySourceNew.forEach((co) =>{
										co.isValid = true;
									});
									coating.energyUsed.isValid = true;
									coating.showWetCoating = false;
									if(coating.energyUsed.value === -1){
										coating.energyUsed.value = '';
									}
									if(coating.windEnergy.value === -1){
										coating.windEnergy.value = '';
									}
									if(coating.solarEnergy.value === -1){
										coating.solarEnergy.value = '';
									}
									coating.thermalEnergyNew.forEach((co) => {
										if(co.value === -1){
											co.value = '';
										}
									});
								});
							}
							if(ywIndex === 0){
								yw.showYarn = true;
							} else {
								yw.showYarn = false;
							}
							if(yw.reference.referenceNo!==""){
								yw.reference.isValid = true
							}
						});
					}
					if(this.wetProcessFootprint[wetProcessIndex].fabricWetprocess.length > 0){
						this.wetProcessFootprint[wetProcessIndex].fabricWetprocess.forEach((fw) => {
							// if(fw.reference.referenceNo!==''){
								fw.reference.isValid = true;
							// }
							if(fw.dyiengTreatment.length > 0){
								fw.dyiengTreatment.forEach((dyeing, dIndex) => {
									dyeing.toCompanyUser.isValid = true;
									dyeing.reference.isValid = true;
									dyeing.process.isValid = true;
									dyeing.dyeType.isValid = true;
									dyeing.shade.isValid = true;
									dyeing.waterUsedInFabric.isValid = true;
									dyeing.totalElectricEnergy.isValid = true;
									dyeing.windEnergy.isValid = true;
									dyeing.solarEnergy.isValid = true;
									dyeing.thermalEnergyNew.forEach((te) =>{
										te.isValid = true;
									});
									dyeing.thermalEnergySourceNew.forEach((ts) =>{
										ts.isValid = true;
									});
									dyeing.energyUsed.isValid = true;
									dyeing.showWetDyeing = false;
									if(dyeing.energyUsed.value === -1){
										dyeing.energyUsed.value = '';
									}
									if(dyeing.windEnergy.value === -1){
										dyeing.windEnergy.value = '';
									}
									if(dyeing.solarEnergy.value === -1){
										dyeing.solarEnergy.value = '';
									}
									dyeing.thermalEnergyNew.forEach((te) => {
										if(te.value === -1){
											te.value = '';
										}
									});
								});
							}
							if(fw.preTreatment.length > 0){
								fw.preTreatment.forEach((pre, preIndex) => {
									pre.toCompanyUser.isValid = true;
									pre.reference.isValid = true;
									pre.process.isValid = true;
									pre.waterUsedInFabric.isValid = true;
									pre.totalElectricEnergy.isValid = true;
									pre.windEnergy.isValid = true;
									pre.solarEnergy.isValid = true;
									pre.thermalEnergyNew.forEach((te) =>{
										te.isValid = true;
									});
									pre.thermalEnergySourceNew.forEach((ts) =>{
										ts.isValid = true;
									});
									pre.energyUsed.isValid = true;
									pre.showWetPre = false;
									if(pre.energyUsed.value === -1){
										pre.energyUsed.value = '';
									}
									if(pre.windEnergy.value === -1){
										pre.windEnergy.value = '';
									}
									if(pre.solarEnergy.value === -1){
										pre.solarEnergy.value = '';
									}
									pre.thermalEnergyNew.forEach((te) => {
										if(te.value === -1){
											te.value = '';
										}
									});
								});
							}
							if(fw.printTreatment.length > 0){
								fw.printTreatment.forEach((print, printIndex) => {
									print.toCompanyUser.isValid = true;
									print.reference.isValid = true;
									print.process.isValid = true;
									print.shade.isValid = true;
									print.printType.isValid = true;
									print.waterUsedInFabric.isValid = true;
									print.totalElectricEnergy.isValid = true;
									print.windEnergy.isValid = true;
									print.solarEnergy.isValid = true;
									print.thermalEnergyNew.forEach((te) =>{
										te.isValid = true;
									});
									print.thermalEnergySourceNew.forEach((ts) =>{
										ts.isValid = true;
									});
									print.energyUsed.isValid = true;
									print.showWetPrint = false;
									if(print.energyUsed.value === -1){
										print.energyUsed.value = '';
									}
									if(print.windEnergy.value === -1){
										print.windEnergy.value = '';
									}
									if(print.solarEnergy.value === -1){
										print.solarEnergy.value = '';
									}
									print.thermalEnergyNew.forEach((te) => {
										if(te.value === -1){
											te.value = '';
										}
									});
								});
							}
							if(fw.coatingFinishing.length > 0){
								fw.coatingFinishing.forEach((coating, cIndex) => {
									coating.toCompanyUser.isValid = true;
									coating.reference.isValid = true;
									coating.coatingOrFinishing.isValid = true;
									coating.waterUsedInFabric.isValid = true;
									coating.totalElectricEnergy.isValid = true;
									coating.windEnergy.isValid = true;
									coating.solarEnergy.isValid = true;
									coating.thermalEnergyNew.forEach((te) =>{
										te.isValid = true;
									});
									coating.thermalEnergySourceNew.forEach((ts) =>{
										ts.isValid = true;
									});
									coating.energyUsed.isValid = true;
									coating.showWetCoating = false;
									if(coating.energyUsed.value === -1){
										coating.energyUsed.value = '';
									}
									if(coating.windEnergy.value === -1){
										coating.windEnergy.value = '';
									}
									if(coating.solarEnergy.value === -1){
										coating.solarEnergy.value = '';
									}
									coating.thermalEnergyNew.forEach((te) => {
										if(te.value === -1){
											te.value = '';
										}
									});
								});
							}
						});
					}
					if(this.wetProcessFootprint[wetProcessIndex].treatmentType.value!==''){
						this.selectedTreatmentType = this.wetProcessFootprint[wetProcessIndex].treatmentType.value;
					}
					if(this.wetProcessFootprint[wetProcessIndex].toCompanyUser.toCompanyID!=Guid.Empty){
						this.wetProcessFootprint[wetProcessIndex].toCompanyUser.isValid = true;
					}
					if(index === 0){
						this.wetProcessFootprint[wetProcessIndex].showWetProcess = true;
					} else {
						this.wetProcessFootprint[wetProcessIndex].showWetProcess = false;
					}
				} else {
					const index = this.wetProcessFootprint.findIndex(wp => wp.stepID === id);
					this.wetProcessFootprint.splice(index, 1);
					this.wetProcessFootprint[wetProcessIndex].stepID = this.wetProcessFootprint[wetProcessIndex].stepID + "," + id;
				}
			});
			if(stepIDs.length === 1 && this.wetProcessFootprint.length > 1 && lodash.uniqBy(this.wetProcessFootprint, 'ID').length === 1){
				this.wetProcessFootprint.splice(1, this.wetProcessFootprint.length-1)
			}
		} else {
			this.defaultWetProcessFootprint(wpWrapper);
		}
	}

	private groupSavedWetTransport(wpWrapper: WetProcessStepsDataModel): void {
		let wetProcessIndex = -1;
		const stepIDs = wpWrapper.stepID.split(",");
		if(this.wetTransport.filter(wp => wp.stepID === stepIDs[0]).length > 0){
			stepIDs.forEach((id, index) => {
				if(index === 0){
					wetProcessIndex = this.wetTransport.findIndex(wp => wp.stepID === id);
				} else {
					const index = this.wetTransport.findIndex(wp => wp.stepID === id);
					this.wetTransport.splice(index, 1);
					this.wetTransport[wetProcessIndex].stepID = this.wetTransport[wetProcessIndex].stepID + "," + id;
				}
			})
		} 
		else {
			this.defaultWetProcessTransport(wpWrapper);
		}
	}

	private defaultWetProcessFootprint(wpWrapper: WetProcessStepsDataModel): void {
		this.wetProcessFootprint.push(new WetProcessFootprintModel());
		const lastIndex = this.wetProcessFootprint.length - 1;
		this.wetProcessFootprint[lastIndex].ID = uuidv4();
		this.wetProcessFootprint[lastIndex].filledByCompanyID = this.user.companyId
		this.wetProcessFootprint[lastIndex].filledByPrimarySource = this.user.companyId === wpWrapper.toCompanyID ? true : false;
		this.wetProcessFootprint[lastIndex].stepID = wpWrapper.stepID;
		this.wetProcessFootprint[lastIndex].referenceNo = wpWrapper.prefixReferenceNo;
		this.wetProcessFootprint[lastIndex].supplierName = wpWrapper.toCompanyName;
		this.wetProcessFootprint[lastIndex].footprintRefNoPrefix = wpWrapper.prefixReferenceNo;
		this.wetProcessFootprint[lastIndex].toCompanyUser.toCompanyID = wpWrapper.toCompanyID;
		this.wetProcessFootprint[lastIndex].toCompanyUser.filledByCompanyID = this.user.companyId;
		this.wetProcessFootprint[lastIndex].toCompanyUser.toCompanyName = wpWrapper.toCompanyName;
		this.wetProcessFootprint[lastIndex].footprintStatus = FootPrintCompletedConstant.INCOMPLETE
	}

	private defaultWetProcessTransport(wpWrapper: WetProcessStepsDataModel): void {
		this.wetTransport.push(new TransportFootprintModel);
		const lastIndex = this.wetTransport.length - 1;
		this.wetTransport[lastIndex].ID = uuidv4();
		this.wetTransport[lastIndex].footprintID = Guid.Empty;
		this.wetTransport[lastIndex].modeOfTransport.push(new ModeOfTransportModel());
		this.wetTransport[lastIndex].toCompanyUser.toCompanyID = wpWrapper.toCompanyID;
		this.wetTransport[lastIndex].toCompanyUser.toCompanyName = wpWrapper.toCompanyName;
		this.wetTransport[lastIndex].toCompanyUser.filledByCompanyID = this.userCompanyId;
		this.wetTransport[lastIndex].toCompanyUser.filledByPrimarySource = this.userCompanyId === wpWrapper.toCompanyID ? true : false;
		this.wetTransport[lastIndex].filledByCompanyID = this.userCompanyId;
		this.wetTransport[lastIndex].filledByPrimarySource = this.userCompanyId === wpWrapper.toCompanyID ? true : false;
		this.wetTransport[lastIndex].stepID = wpWrapper.stepID;
	}

	private async validateField(treatmentType:string, event: any, fieldname: string, accordianName: string, index: number, yWfIndex:number, treatmentIndex:number, thermalIndex:number): Promise<void> {
		switch (fieldname) {
            case "partner": {
				const valid: boolean = event === '' ? false : true;
                if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.isValid ? this.disableSave=true : this.disableSave=false;
					const selectSCP = this.orderPartners.filter(s => s.value === event)[0];
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID = selectSCP.value.toString();
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyName = selectSCP.text;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}else if(accordianName == "pre"){
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.isValid ? this.disableSave=true : this.disableSave=false;
					const selectSCP = this.orderPartners.filter(s => s.value === event)[0];
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyID = selectSCP.value.toString();
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyName = selectSCP.text;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}else if(accordianName == "printing"){
					this.wetProcessFootprint[index].fabricWetprocess[treatmentIndex].printTreatment[index].toCompanyUser.isValid = valid;
					!this.wetProcessFootprint[index].fabricWetprocess[treatmentIndex].printTreatment[index].toCompanyUser.isValid ? this.disableSave=true : this.disableSave=false;
					const selectSCP = this.orderPartners.filter(s => s.value === event)[0];
					this.wetProcessFootprint[index].fabricWetprocess[treatmentIndex].printTreatment[index].toCompanyUser.toCompanyID = selectSCP.value.toString();
					this.wetProcessFootprint[index].fabricWetprocess[treatmentIndex].printTreatment[index].toCompanyUser.toCompanyName = selectSCP.text;
					this.wetProcessFootprint[index].fabricWetprocess[treatmentIndex].printTreatment[index].toCompanyUser.filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[treatmentIndex].printTreatment[index].toCompanyUser.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[treatmentIndex].printTreatment[index].toCompanyUser.toCompanyID ? true : false;
				}
				else if(accordianName == "wetprocesspartner"){
					this.wetProcessFootprint[index].toCompanyUser.isValid = valid;
					!this.wetProcessFootprint[index].toCompanyUser.isValid ? this.disableSave=true : this.disableSave=false;
					const selectSCP = this.orderPartners.filter(s => s.value === event)[0];
					this.wetProcessFootprint[index].toCompanyUser.toCompanyID = selectSCP.value.toString();
					this.wetProcessFootprint[index].toCompanyUser.toCompanyName = selectSCP.text;
					this.wetProcessFootprint[index].toCompanyUser.filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].toCompanyUser.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
					if(this.wetProcessFootprint[index].isPrefilledPartner){
						this.wetProcessFootprint[index].isPrefilledPartner = false;
					}
					if(this.wetProcessFootprint[index].toCompanyUser.isValid && this.wetProcessFootprint[index].treatmentType.value!== ''){
						this.checkIsCompanyNTypeUnique(index);
					}
				} else {
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.isValid ? this.disableSave=true : this.disableSave=false;
					const selectSCP = this.orderPartners.filter(s => s.value === event)[0];
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyID = selectSCP.value.toString();
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyName = selectSCP.text;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
            case "reference": {
                const valid: boolean = event.target.value.toLowerCase().match(/^[-a-zA-Z0-9 ]+(?:[\w -]*[-a-zA-Z0-9 ]+)*$/) ? true : false;
				if (accordianName == "dyeing") {
					// this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].reference.isUnique = this.checkUniqueness('dyeing', event.target.value);
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].reference.isValid = valid;
					!valid || !this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].reference.isUnique? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].reference.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].reference.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if(accordianName == "pre"){
					// this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].reference.isUnique = this.checkUniqueness('pre', event.target.value);
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].reference.isValid = valid;
					!valid || !this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].reference.isUnique ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].reference.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].reference.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if(accordianName == "printing"){
					// this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].reference.isUnique = this.checkUniqueness('printing', event.target.value);
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].reference.isValid = valid;
					!valid || !this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].reference.isUnique ? this.disableSave=true : this.disableSave=false;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].reference.filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].reference.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
				else {
					// this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].reference.isUnique = this.checkUniqueness('coating', event.target.value);
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].reference.isValid = valid;
					!valid || !this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].reference.isUnique ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].reference.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].reference.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "dyeType": {
				const valid: boolean = event === '' ? false : true;
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].dyeType.isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].dyeType.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].dyeType.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].dyeType.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if(accordianName == "printing"){
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].printType.isValid = valid;
					!this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].printType.isValid ? this.disableSave=true : this.disableSave=false;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].printType.filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].printType.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "shade": {
				const valid: boolean = event === '' ? false : true;
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].shade.isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].shade.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].shade.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].shade.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if(accordianName == "printing"){
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].shade.isValid = valid;
					!this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].shade.isValid ? this.disableSave=true : this.disableSave=false;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].shade.filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].shade.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "process": {
				const valid: boolean = event === '' ? false : true;
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].process.isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].process.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].process.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].process.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if(accordianName == "printing"){
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].process.isValid = valid;
					!this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].process.isValid ? this.disableSave=true : this.disableSave=false;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].process.filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].process.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if(accordianName == "pre"){
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].process.isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].process.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].process.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].process.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "coating": {
				const valid: boolean = event === '' ? false : true;
				if (accordianName == "coating") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].coatingOrFinishing.isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].coatingOrFinishing.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].coatingOrFinishing.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].coatingOrFinishing.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "waterUsed": {
				let validNumber: boolean = event.target.value.toLowerCase().match(/^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/) || event.target.value === '' ? true : false;
				if (event.target.value == '' || event.target.value == undefined) {
					validNumber = true;
				}
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].waterUsedInFabric.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].waterUsedInFabric.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].waterUsedInFabric.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].waterUsedInFabric.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if (accordianName == "pre") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].waterUsedInFabric.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].waterUsedInFabric.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].waterUsedInFabric.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].waterUsedInFabric.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if (accordianName == "printing") {
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].waterUsedInFabric.isValid = validNumber;
					!this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].waterUsedInFabric.isValid ? this.disableSave=true : this.disableSave=false;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].waterUsedInFabric.filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].waterUsedInFabric.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else {
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].waterUsedInFabric.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].waterUsedInFabric.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].waterUsedInFabric.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].waterUsedInFabric.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "energyUsed": {
				let validNumber: boolean = event.target.value.toLowerCase().match(/^(?!,)\d{0,20}(?:,\d{0,20})*$/) || event.target.value === '' ? true : false;
				if (event.target.value == '' || event.target.value == undefined) {
					validNumber = true;
				}else if(event.target.value == ','){
					event.target.value == ''
				}
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].energyUsed.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].energyUsed.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].energyUsed.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].energyUsed.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if (accordianName == "pre") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].energyUsed.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].energyUsed.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].energyUsed.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].energyUsed.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if (accordianName == "printing") {
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].energyUsed.isValid = validNumber;
					!this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].energyUsed.isValid ? this.disableSave=true : this.disableSave=false;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].energyUsed.filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].energyUsed.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else {
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].energyUsed.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].energyUsed.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].energyUsed.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].energyUsed.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "windEnergy": {
				const validNumber: boolean = event.target.value.match(/^([0-9]|[0-9][1-9]|[1-9][0-9]|100)$/) || event.target.value === '' ? true : false;
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].windEnergy.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].windEnergy.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].windEnergy.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].windEnergy.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if (accordianName == "pre") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].windEnergy.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].windEnergy.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].windEnergy.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].windEnergy.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if (accordianName == "printing") {
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].windEnergy.isValid = validNumber;
					!this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].windEnergy.isValid ? this.disableSave=true : this.disableSave=false;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].windEnergy.filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].windEnergy.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else {
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].windEnergy.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].windEnergy.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].windEnergy.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].windEnergy.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "solarEnergy": {
				const validNumber: boolean = event.target.value.match(/^([0-9]|[0-9][1-9]|[1-9][0-9]|100)$/) || event.target.value === '' ? true : false;
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].solarEnergy.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].solarEnergy.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].solarEnergy.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].solarEnergy.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if (accordianName == "pre") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].solarEnergy.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].solarEnergy.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].solarEnergy.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].solarEnergy.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if (accordianName == "printing") {
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].solarEnergy.isValid = validNumber;
					!this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].solarEnergy.isValid ? this.disableSave=true : this.disableSave=false;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].solarEnergy.filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].solarEnergy.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else {
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].solarEnergy.isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].solarEnergy.isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].solarEnergy.filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].solarEnergy.filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "thermalEnergy": {
				const validNumber: boolean = event.target.value.match(/^([0-9]|[0-9][1-9]|[1-9][0-9]|100)$/) || event.target.value === '' ? true : false;
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if (accordianName == "pre") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
				else if (accordianName == "printing") {
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].isValid = validNumber;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].isValid ? this.disableSave=true : this.disableSave=false;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].thermalEnergyNew[thermalIndex].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
				else {
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].thermalEnergyNew[thermalIndex].isValid = validNumber;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].thermalEnergyNew[thermalIndex].isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].thermalEnergyNew[thermalIndex].filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].thermalEnergyNew[thermalIndex].filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "thermalSource": {
				const valid: boolean = event !== "" ? true : false;
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				} else if (accordianName == "pre") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
				else if (accordianName == "printing") {
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].isValid = valid;
					!this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].isValid ? this.disableSave=true : this.disableSave=false;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].filledByCompanyID = this.user.companyId;
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].thermalEnergySourceNew[thermalIndex].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
				else {
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].thermalEnergySourceNew[thermalIndex].isValid = valid;
					!this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].thermalEnergySourceNew[thermalIndex].isValid ? this.disableSave=true : this.disableSave=false;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].thermalEnergySourceNew[thermalIndex].filledByCompanyID = this.user.companyId;
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].thermalEnergySourceNew[thermalIndex].filledByPrimarySource = this.user.companyId === this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].toCompanyUser.toCompanyID ? true : false;
				}
                break;
			}
			case "yarndyereference": {
				/* eslint-disable-next-line */
				this.wetProcessFootprint[index].yarnWetprocess[yWfIndex].ID = event;
				if(event!==Guid.Empty){
					this.wetProcessFootprint[index].yarnWetprocess[yWfIndex].reference.referenceNo = this.yarnRefList.filter((f) => f.value === event)[0] ? this.yarnRefList.filter((f) => f.value === event)[0].text.toString() : '';
				}
				this.wetProcessFootprint[index].yarnWetprocess[yWfIndex].reference.filledByCompanyID = this.user.companyId;
				this.wetProcessFootprint[index].yarnWetprocess[yWfIndex].reference.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
				this.wetProcessFootprint[index].yarnWetprocess[yWfIndex].reference.isValid = true;
				this.wetProcessFootprint[index].yarnWetprocess[yWfIndex].yarnFootprintID = event;
                break;
			}
			case "fabricdyereference": {
				/* eslint-disable-next-line */
				this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].ID = event;
				if(event!==Guid.Empty){
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].reference.referenceNo = this.fabricRefList.filter((f) => f.value === event)[0] ? this.fabricRefList.filter((f) => f.value === event)[0].text.toString() : '';
				}
				this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].reference.filledByCompanyID = this.user.companyId;
				this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].reference.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
				this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].reference.isValid = true;
				this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].fabricFootprintID = event;
                break;
			}
			default:
                break;
        }
		this.compareUserClone();
		this.checkNonEmptyFields();
    }

	private async validateWetFootprintRound(treatmentType:string, event: any, fieldname: string, accordianName: string, index: number, yWfIndex: number, treatmentIndex: number): Promise<void> {
		switch (fieldname) {
			case 'energyUsed': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					rounded = Number(val).toFixed(2);
				}
				if(val.includes(',') && this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].energyUsed.isValid){
					const firstCommaWithdot = val.replace(',', '.');
					const removAllComma =  firstCommaWithdot.replace(/,/g, '');
					rounded = Math.round(removAllComma).toString();
				}
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].energyUsed.value = rounded;
				} else if (accordianName == "pre") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].energyUsed.value = rounded;
				} else if (accordianName == "printing") {
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].energyUsed.value = rounded;
				} else {
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].energyUsed.value = rounded;
				}
				break;
			}
			case 'waterUsed': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					// rounded = Number(val).toFixed(2);
					rounded = Math.round(val).toString();
				}
				if (accordianName == "dyeing") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).dyiengTreatment[treatmentIndex].waterUsedInFabric.amount = rounded;
				} else if (accordianName == "pre") {
					this.yarnOrFabric(treatmentType,index,yWfIndex).preTreatment[treatmentIndex].waterUsedInFabric.amount = rounded;
				} else if (accordianName == "printing") {
					this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].waterUsedInFabric.amount = rounded;
				} else {
					this.yarnOrFabric(treatmentType,index,yWfIndex).coatingFinishing[treatmentIndex].waterUsedInFabric.amount = rounded;
				}
				break;
			}
			default:
				break;
		}
	}

	private openUploadDocModal(treatmentType: string, id: string, type: string, index:number, typeIndex:number, treatmentIndex:number): void {
		this.isDocUploading = false;
		this.showUploadDocModal = true;
		this.accordianId = id;
		this.accordianType = type;
		this.wetIndex = index;
		this.treatmentTypeIndex = typeIndex;
		this.treatmentIndex = treatmentIndex;
		this.typeOfDocUpload = treatmentType;
		this.docFile = "";
	}

	private openUploadMsdsDocModal(treatmentType: string, id: string, type: string, wpIndex:number, typeIndex:number, treatmentIndex:number): void {
		this.isDocUploading = false;
		this.showUploadDocModal = true;
		this.accordianId = id;
		this.accordianType = type;
		this.isMsdsDoc = true;
		this.typeOfDocUpload = treatmentType;
		this.wetIndex = wpIndex;
		this.treatmentTypeIndex = typeIndex;
		this.treatmentIndex = treatmentIndex;
	}

	private showDeletePopup(treatmentType: string, wetProceeDeleId: string, wetProcessDeleDocId: string, accordian: string, docIndex: number,
		wpIndex: number, treatementTypeIndex: number, treatmentIndex: number){
		this.showDeleteModal = true;
		this.accordianId = wetProceeDeleId;
		this.wetProcessDeleIndex = wetProceeDeleId;
		this.wetProcessDeleDocIndex = wetProcessDeleDocId;
		this.deleteDocType = accordian;
		this.wetProcessDeletingDocIndex = docIndex;
		this.typeOfDocUpload = treatmentType;
		this.wetIndex = wpIndex;
		this.treatmentTypeIndex = treatementTypeIndex;
		this.treatmentIndex = treatmentIndex;
		this.isDeletingDoc = false;
	}

	private showMSDSDeletePopup(treatmentType: string, wetProceeDeleId: string, wetProcessDeleDocId: string, accordian: string, docIndex: number,
		wpIndex: number, treatmentTypeIndex: number, treatmentIndex: number){
		this.showMsdsDeleteModal = true;
		this.accordianId = wetProceeDeleId;
		this.wetProcessDeleIndex = wetProceeDeleId;
		this.wetProcessDeleDocIndex = wetProcessDeleDocId;
		this.deleteDocType = accordian;
		this.wetProcessDeletingDocIndex = docIndex;
		this.typeOfDocUpload = treatmentType;
		this.wetIndex = wpIndex;
		this.treatmentTypeIndex = treatmentTypeIndex;
		this.treatmentIndex = treatmentIndex;
		this.isDeletingDoc = false;
	}

	private get uploadDisabled(): boolean {
		return this.docFile == '';
	}

	private closeUploadDocModal(): void {
		this.showUploadDocModal = false;
		this.isMsdsDoc = false;
		this.docFile = '';
	}

	private openPDFModal(doc: DocumentModel): void {
		this.docPreview = doc;
		this.showPdfPreviewModal = true;
	}

	private openMsdsPDFModal(doc: DocumentModel): void {
		this.docPreview = doc;
		this.showPdfPreviewModal = true;
		this.showMsdsPdfPreviewModal = true;
	}

	private closePdfModal(): void {
		this.showPdfPreviewModal = false;
		this.showMsdsPdfPreviewModal = false;
	}

	private async downloadPDF(): Promise<void> {
        if (this.isPdfReady) {
            this.pdfDocument.contentDispositionFilename = this.docPreview.fileName;
            this.pdfDocument.download();
        }
    }

    private async pdfReady(pdfApp: any): Promise<void> {
        this.isPdfReady = true;
        this.pdfDocument = pdfApp;
    }

	private async deleteMsdsDoc(): Promise<void> {
		this.isDeletingDoc = true;
		let type: string = "";
		if (this.deleteDocType == 'dyeing') {
			type =  FootPrintConstant.MSDSDOCS_WP_DYIENGT;
		} else if (this.deleteDocType == 'pre'){
			type =  FootPrintConstant.MSDSDOCS_WP_PRET;
		} else if (this.deleteDocType == 'print'){
			type =  FootPrintConstant.MSDSDOCS_WP_PRINTT;
		} else {
			type =  FootPrintConstant.MSDSDOCS_WP_CANDFT;
		}
		const doc: DeleteDocRequestModel = {
			footprintID: this.wetProcessDeleIndex,
			footprintType: type,
			docID: this.wetProcessDeleDocIndex
		};
		let result: FootprintDataResponseModel = new FootprintDataResponseModel();
		if(this.typeOfDocUpload === 'yarn'){
			result = await this.clientRequestFootPrintData.deleteYarnWetMsdsDocs(this.orderId, doc);
		}else{
			result = await this.clientRequestFootPrintData.deleteMsdsDocs(this.orderId, doc);
		}
		if(result.success){
			if (this.deleteDocType == 'dyeing') {
				if(this.typeOfDocUpload === 'yarn'){
					this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc;
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc;
					// }
				}else{
					this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc;
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc;
					// }
				}
			} else if (this.deleteDocType == 'pre'){
				if(this.typeOfDocUpload === 'yarn'){
					this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc;
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc;
					// }
				}else{
					this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc;
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc;
					// }
				}

			} else if (this.deleteDocType == 'print'){
					this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].msdsDoc;
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].msdsDoc;
					// }
			} else {
				if(this.typeOfDocUpload === 'yarn'){
					this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc;
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc;
					// }
				}else{
					this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc;
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc;
					// }
				}

			}
		}
		this.isDeletingDoc = false;
		this.showMsdsDeleteModal = false;
	}

	private async deleteSupDoc(): Promise<void> {
		this.isDeletingDoc = true;
		let type: string = "";
		if (this.deleteDocType == 'dyeing') {
			type =  FootPrintConstant.SUPPORTINGDOCS_WP_DYIENGT;
		} else if (this.deleteDocType == 'pre'){
			type =  FootPrintConstant.SUPPORTINGDOCS_WP_PRET;
		} else if (this.deleteDocType == 'print'){
			type =  FootPrintConstant.SUPPORTINGDOCS_WP_PRINTT;
		} else {
			type =  FootPrintConstant.SUPPORTINGDOCS_WP_CANDFT;
		}
		const doc: DeleteDocRequestModel = {
			footprintID: this.wetProcessDeleIndex,
			footprintType: type,
			docID: this.wetProcessDeleDocIndex
		};
		let result: FootprintDataResponseModel = new FootprintDataResponseModel();
		if(this.typeOfDocUpload === 'yarn'){
			result = await this.clientRequestFootPrintData.deleteWetYarnSupportDocs(this.orderId, doc);
		}else{
			result = await this.clientRequestFootPrintData.deleteSupportDocs(this.orderId, doc);
		}
		if(result.success){
			if (this.deleteDocType == 'dyeing') {
				if(this.typeOfDocUpload === 'yarn'){
					this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument;
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument;
					// }
				}else{
					this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument;
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument;
					// }
				}
			} else if (this.deleteDocType == 'pre'){
				if(this.typeOfDocUpload === 'yarn'){
					this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].energySupportingDocument;
				}else{
					this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].energySupportingDocument;
				}
			} else if (this.deleteDocType == 'print'){
				this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].energySupportingDocument;

			} else {
				if(this.typeOfDocUpload === 'yarn'){
					this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].energySupportingDocument;
				}else{
					this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].energySupportingDocument;
				}
			}
		}
		this.isDeletingDoc = false;
		this.showDeleteModal = false;
	}

	private async uploadWetSuppDocFootprintData(): Promise<void>{
		this.isDocUploading = true;
		const docFormData = new FormData();
		docFormData.append("upload", this.docFile);
		docFormData.append("geoLong", '0');
		docFormData.append("geoLat", '0');
		docFormData.append("docID", uuidv4());
		docFormData.append("footprintID", this.wetProcessFootprint[this.wetIndex].ID);
		if(this.wetProcessFootprint[this.wetIndex].stepID.includes(',')){
			let stepIds:string[] = this.wetProcessFootprint[this.wetIndex].stepID.split(',');
			if(stepIds.length > 0){
				docFormData.append("stepID", stepIds[0]);
			}
		}else{
			docFormData.append("stepID", this.wetProcessFootprint[this.wetIndex].stepID);
		}
		if (this.accordianType == 'dyeing') {
			docFormData.append("footprintType", FootPrintConstant.SUPPORTINGDOCS_WP_DYIENGT);
		} else if (this.accordianType == 'pre'){
			docFormData.append("footprintType", FootPrintConstant.SUPPORTINGDOCS_WP_PRET);
		} else if (this.accordianType == 'print'){
			docFormData.append("footprintType", FootPrintConstant.SUPPORTINGDOCS_WP_PRINTT);
		} else {
			docFormData.append("footprintType", FootPrintConstant.SUPPORTINGDOCS_WP_CANDFT);
		}
		const filledByCompanyID = this.user.companyId;
		const filledByPrimarySource = this.wetProcessFootprint[this.wetIndex].toCompanyUser.toCompanyID === this.user.companyId ? "true" : "false";
		docFormData.append("filledByCompanyID", filledByCompanyID);
		docFormData.append("filledByPrimarySource", filledByPrimarySource);
		docFormData.append("wetProcessType", this.wetProcessFootprint[this.wetIndex].treatmentType.value);
		let wpYarnDytFootprintID : string = '';
		let wpYarnFootprintID: string = '';
		if(this.typeOfDocUpload === 'yarn'){
			wpYarnFootprintID = this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].ID;
		}else{
			wpYarnFootprintID = this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].ID;
		}
		docFormData.append("wpYarnDytFootprintID", this.accordianId);
		docFormData.append("wpYarnFootprintID", wpYarnFootprintID);
		let result:FootprintDataResponseModel = new FootprintDataResponseModel();
		// if(this.typeOfDocUpload === 'yarn'){
			result = await this.clientRequestFootPrintData.uploadSupportingWetYarnDocsAsync(this.orderId, docFormData);
		// }else{
		// 	result = await this.clientRequestFootPrintData.uploadSupportingDocsAsync(this.orderId, docFormData);
		// }


		if (result.success) {
			if (this.accordianType=="dyeing") {
				// this.wetProcessFootprint.forEach((wp, wpIndex) => {
				// 	wp.yarnWetprocess.forEach((yw, ywIndex) => {
				// 		yw.dyiengTreatment
				// 		.filter(d => d.ID === this.accordianId)[0].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[wpIndex].yarnWetprocess[ywIndex].dyiengTreatment
				// 		.filter(d => d.ID === this.accordianId)[0].energySupportingDocument;
				// 	})
				// });
				if(this.typeOfDocUpload === 'yarn'){
					if(this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument;
					}
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument;
					// }
				}else{
					if(this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument;
					}
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].energySupportingDocument;
					// }
				}
			} else if(this.accordianType == 'pre'){
				if(this.typeOfDocUpload === 'yarn'){
					if(this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].energySupportingDocument;
					}
				}else{
					if(this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].energySupportingDocument;
					}
				}
			} else if(this.accordianType == 'print'){
				if(this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].ID === this.accordianId){
					this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].energySupportingDocument;
				}
			} else {
				if(this.typeOfDocUpload === 'yarn'){
					if(this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].energySupportingDocument;
					}
				}else{
					if(this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].energySupportingDocument = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].energySupportingDocument;
					}
				}
			}
			this.closeUploadDocModal();
			this.$store.commit("setFootprintChanged", '');
			this.$store.commit('setIncorrectFootprintData', false);
		}
		this.isDocUploading = false;
	}

	private async uploadWetMsdsFootprintData(): Promise<void>{
		this.isDocUploading = true;
		const docFormData = new FormData();
		docFormData.append("upload", this.docFile);
		docFormData.append("geoLong", '0');
		docFormData.append("geoLat", '0');
		docFormData.append("docID", uuidv4());
		// docFormData.append("footprintID", this.accordianId);
		if(this.wetProcessFootprint[this.wetIndex].stepID.includes(',')){
			let stepIds:string[] = this.wetProcessFootprint[this.wetIndex].stepID.split(',');
			if(stepIds.length > 0){
				docFormData.append("stepID", stepIds[0]);
			}
		}else{
			docFormData.append("stepID", this.wetProcessFootprint[this.wetIndex].stepID);
		}
		docFormData.append("footprintID", this.wetProcessFootprint[this.wetIndex].ID);
		docFormData.append("wetProcessType", this.wetProcessFootprint[this.wetIndex].treatmentType.value);
		docFormData.append("stepID", this.wetProcessFootprint[this.wetIndex].stepID);
		let wpYarnDytFootprintID : string = '';
		let wpYarnFootprintID: string = '';
		if(this.wetProcessFootprint[this.wetIndex].treatmentType.value === 'YARN'){
			wpYarnDytFootprintID = this.accordianId;
			wpYarnFootprintID = this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].ID;
		}
		if (this.accordianType == 'dyeing') {
			docFormData.append("footprintType", FootPrintConstant.MSDSDOCS_WP_DYIENGT);
		} else if (this.accordianType == 'pre'){
			docFormData.append("footprintType", FootPrintConstant.MSDSDOCS_WP_PRET);
		} else if (this.accordianType == 'print'){
			docFormData.append("footprintType", FootPrintConstant.MSDSDOCS_WP_PRINTT);
		} else {
			docFormData.append("footprintType", FootPrintConstant.MSDSDOCS_WP_CANDFT);
		}
		if(this.typeOfDocUpload === 'yarn'){
			wpYarnFootprintID = this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].ID;
		}else{
			wpYarnFootprintID = this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].ID;
		}
		docFormData.append("wpYarnDytFootprintID", this.accordianId);
		docFormData.append("wpYarnFootprintID", wpYarnFootprintID);
		const filledByCompanyID = this.user.companyId;
		const filledByPrimarySource = this.wetProcessFootprint[this.wetIndex].toCompanyUser.toCompanyID === this.user.companyId ? "true" : "false";
		docFormData.append("filledByCompanyID", filledByCompanyID);
		docFormData.append("filledByPrimarySource", filledByPrimarySource);
		let result:FootprintDataResponseModel = new FootprintDataResponseModel();
		// if(this.typeOfDocUpload === 'yarn'){
			result = await this.clientRequestFootPrintData.uploadYarnWetMsdsDocsAsync(this.orderId, docFormData);
		// }else{
		// 	result = await this.clientRequestFootPrintData.uploadWetMsdsDocsAsync(this.orderId, docFormData);
		// }
		if (result.success) {
			if (this.accordianType=="dyeing") {
				if(this.typeOfDocUpload === 'yarn'){
					// this.wetProcessFootprint.forEach((wp, wpIndex) => {
					// 	wp.yarnWetprocess.forEach((yw, ywIndex) => {
					// 		yw.dyiengTreatment
					// 		.filter(d => d.ID === this.accordianId)[0].msdsDoc = result.order.wetprocess.wetprocessFootprint[wpIndex].yarnWetprocess[ywIndex].dyiengTreatment
					// 		.filter(d => d.ID === this.accordianId)[0].msdsDoc;
					// 	});
					// });
					// this.wetProcessFootprintCloneInitialState.forEach((wp, wpIndex) => {
					// 	wp.yarnWetprocess.forEach((yw, ywIndex) => {
					// 		yw.dyiengTreatment
					// 		.filter(d => d.ID === this.accordianId)[0].msdsDoc = result.order.wetprocess.wetprocessFootprint[wpIndex].yarnWetprocess[ywIndex].dyiengTreatment
					// 		.filter(d => d.ID === this.accordianId)[0].msdsDoc;
					// 	});
					// });
					if(this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc;
					}
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc;
					// }
				}else{
					if(this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc;
					}
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].dyiengTreatment[this.treatmentIndex].msdsDoc;
					// }
				}
			} else if(this.accordianType == 'pre'){
				if(this.typeOfDocUpload === 'yarn'){
					if(this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc;
					}
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc;
					// }
				}else{
					if(this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc;
					}
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].preTreatment[this.treatmentIndex].msdsDoc;
					// }
				}
			} else if(this.accordianType == 'print'){
				if(this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].ID === this.accordianId){
					this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].msdsDoc;
				}
				// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].ID === this.accordianId){
				// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].printTreatment[this.treatmentIndex].msdsDoc;
				// }
			} else {
				if(this.typeOfDocUpload === 'yarn'){
					if(this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc;
					}
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].yarnWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc;
					// }
				}else{
					if(this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].ID === this.accordianId){
						this.wetProcessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc;
					}
					// if(this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].ID === this.accordianId){
					// 	this.wetProcessFootprintCloneInitialState[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc = result.order.wetprocess.wetprocessFootprint[this.wetIndex].fabricWetprocess[this.treatmentTypeIndex].coatingFinishing[this.treatmentIndex].msdsDoc;
					// }
				}
			}
			this.closeUploadDocModal();
			this.$store.commit("setFootprintChanged", '');
			this.$store.commit('setIncorrectFootprintData', false);
		}
		this.isDocUploading = false;
	}

	private removeDyeingFootprint(index: number, yarnWpIndex:number, dyeingIndex: number, type:string): void {
		if(this.isSaving){
            return;
        }
		this.wetProcessFootprint[index].treatmentType.value = type;
		this.isTabRemoved = true;
		this.yarnOrFabric(type,index,yarnWpIndex).dyiengTreatment.splice(dyeingIndex, 1);
		this.cloneYarnOrFabric(type,index,yarnWpIndex).dyiengTreatment.splice(dyeingIndex, 1);
		this.treatmentDeletedCounter++;
		this.compareUserClone();
		this.checkNonEmptyFields();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.WET_FP);
	}

	private removePreFootprint(index: number, yarnWpIndex:number, preIndex: number, type:string): void {
		if(this.isSaving){
            return;
        }
		this.wetProcessFootprint[index].treatmentType.value = type;
		this.isTabRemoved = true;
		this.yarnOrFabric(type,index,yarnWpIndex).preTreatment.splice(preIndex, 1);
		this.cloneYarnOrFabric(type,index,yarnWpIndex).preTreatment.splice(preIndex, 1);
		this.treatmentDeletedCounter++;
		this.compareUserClone();
		this.checkNonEmptyFields();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.WET_FP);
	}


	private removePrintFootprint(index: number, fwIndex:number, printIndex:number): void {
		if(this.isSaving){
            return;
        }
		this.isTabRemoved = true;
		this.wetProcessFootprint[index].fabricWetprocess[fwIndex].printTreatment.splice(printIndex, 1);
		this.wetProcessFootprintClone[index].fabricWetprocess[fwIndex].printTreatment.splice(index, 1);
		this.treatmentDeletedCounter++;
		this.compareUserClone();
		this.checkNonEmptyFields();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.WET_FP);
	}

	private removeCoatingFootprint(index: number, yarnWpIndex:number, coatingIndex: number, type:string): void {
		if(this.isSaving){
            return;
        }
		this.wetProcessFootprint[index].treatmentType.value = type;
		this.isTabRemoved = true;
		this.yarnOrFabric(type,index,yarnWpIndex).coatingFinishing.splice(coatingIndex, 1);
		this.cloneYarnOrFabric(type,index,yarnWpIndex).coatingFinishing.splice(coatingIndex, 1);
		this.treatmentDeletedCounter++;
		this.compareUserClone();
		this.checkNonEmptyFields();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.WET_FP);
	}

	private checkNonEmptyFields(): void {
		this.isWetProcessFieldEmpty = false;
		this.disableSave = false;
		const finalWPFp = lodash.cloneDeep(this.wetProcessFootprint);
			finalWPFp.forEach((wp, index) => {
				if(wp.yarnWetprocess.length > 0 && this.wetProcessWrapperData.findIndex(wrp => wrp.stepID === wp.stepID)!==-1){
					wp.yarnWetprocess.forEach((yw) => {
						if(yw.reference.referenceNo === ''){
							this.isWetProcessFieldEmpty = true;
							this.disableSave = true;
						}
						if(yw.dyiengTreatment.length > 0){
							yw.dyiengTreatment.forEach(dyeing => {
								if(dyeing.reference.referenceNo === '' || dyeing.dyeType.type === '' || dyeing.shade.type === '' || dyeing.process.type === '' ||
									dyeing.waterUsedInFabric.amount === '' || dyeing.energyUsed.value === '' || dyeing.windEnergy.value === '' || dyeing.solarEnergy.value === ''){
									// wp.isWetProcessFieldEmpty = true;
									this.isWetProcessFieldEmpty = true;
								}
								if (dyeing.reference.referenceNo === '' || !dyeing.reference.isValid || !dyeing.windEnergy.isValid || !dyeing.solarEnergy.isValid || dyeing.thermalEnergyNew.some(te => te.isValid === false)) {
									this.disableSave = true;
								}
								const thermalIndex = dyeing.thermalEnergyNew.findIndex(th => th.value === '')
								if(thermalIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
								const sourceIndex = dyeing.thermalEnergySourceNew.findIndex(th => th.value === '')
								if(sourceIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
							});
						}
						if(yw.preTreatment.length > 0){
							yw.preTreatment.forEach(pre => {
								if(pre.reference.referenceNo === '' || pre.process.type === '' || pre.waterUsedInFabric.amount === '' ||
									pre.energyUsed.value === '' || pre.windEnergy.value === '' || pre.solarEnergy.value === ''){
										this.isWetProcessFieldEmpty = true;
								}
								let isThermalEnergyValid = true;
								if(pre.thermalEnergyNew.some(te => te.isValid === false)){
									isThermalEnergyValid = false;
								}
								if (pre.toCompanyUser.toCompanyID === '' || pre.reference.referenceNo === '' || !pre.reference.isValid ||
									!pre.windEnergy.isValid || !pre.solarEnergy.isValid || pre.thermalEnergyNew.some(te => te.isValid === false)) {
									this.disableSave = true;
								}
								const thermalIndex = pre.thermalEnergyNew.findIndex(th => th.value === '')
								if(thermalIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
								const sourceIndex = pre.thermalEnergySourceNew.findIndex(th => th.value === '')
								if(sourceIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
							});
						}
						if(yw.coatingFinishing.length > 0){
							yw.coatingFinishing.forEach(coating => {
								if(coating.reference.referenceNo === '' || coating.coatingOrFinishing.type === '' ||
									coating.waterUsedInFabric.amount === '' || coating.energyUsed.value === '' || coating.windEnergy.value === '' ||
									coating.solarEnergy.value === ''){
										this.isWetProcessFieldEmpty = true;
								}
								if (coating.reference.referenceNo === '' || !coating.reference.isValid || 
									!coating.windEnergy.isValid || !coating.solarEnergy.isValid || coating.thermalEnergyNew.some(te => te.isValid === false)) {
									this.disableSave = true;
								}
								const thermalIndex = coating.thermalEnergyNew.findIndex(th => th.value === '')
								if(thermalIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
								const sourceIndex = coating.thermalEnergySourceNew.findIndex(th => th.value === '')
								if(sourceIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
							});
						}
					});
				}
				if(wp.fabricWetprocess.length > 0 && this.wetProcessWrapperData.findIndex(wrp => wrp.stepID === wp.stepID)!==-1){
					wp.fabricWetprocess.forEach((fw) => {
						if(fw.reference.referenceNo === ''){
							this.isWetProcessFieldEmpty = true;
							this.disableSave = true;
						}
						if(fw.dyiengTreatment.length > 0){
							fw.dyiengTreatment.forEach(dyeing => {
								if(dyeing.reference.referenceNo === '' || dyeing.dyeType.type === '' || dyeing.shade.type === '' || dyeing.process.type === '' ||
									dyeing.waterUsedInFabric.amount === '' || dyeing.energyUsed.value === '' || dyeing.windEnergy.value === '' || dyeing.solarEnergy.value === ''){
										this.isWetProcessFieldEmpty = true;
								}
								if (dyeing.reference.referenceNo === '' || !dyeing.reference.isValid ||
									!dyeing.windEnergy.isValid || !dyeing.solarEnergy.isValid || dyeing.thermalEnergyNew.some(te => te.isValid === false)) {
									this.disableSave = true;
								}
								const thermalIndex = dyeing.thermalEnergyNew.findIndex(th => th.value === '')
								if(thermalIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
								const sourceIndex = dyeing.thermalEnergySourceNew.findIndex(th => th.value === '')
								if(sourceIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
							});
						}
						if(fw.preTreatment.length > 0){
							fw.preTreatment.forEach(pre => {
								if(pre.reference.referenceNo === '' || pre.process.type === '' || pre.waterUsedInFabric.amount === '' ||
									pre.energyUsed.value === '' || pre.windEnergy.value === '' || pre.solarEnergy.value === ''){
										this.isWetProcessFieldEmpty = true;
								}
								if (pre.reference.referenceNo === '' || !pre.reference.isValid ||
									!pre.windEnergy.isValid || !pre.solarEnergy.isValid || pre.thermalEnergyNew.some(te => te.isValid === false)) {
									this.disableSave = true;
								}
								const thermalIndex = pre.thermalEnergyNew.findIndex(th => th.value === '')
								if(thermalIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
								const sourceIndex = pre.thermalEnergySourceNew.findIndex(th => th.value === '')
								if(sourceIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
							});
						}
						if(fw.printTreatment.length > 0){
							fw.printTreatment.forEach(print => {
								if(print.reference.referenceNo === '' || print.printType.type === '' || print.shade.type === '' ||
									print.process.type === '' || print.waterUsedInFabric.amount === '' || print.energyUsed.value === '' || print.windEnergy.value === '' ||
									print.solarEnergy.value === ''){
										this.isWetProcessFieldEmpty = true;
								}
								if (print.reference.referenceNo === '' || !print.reference.isValid ||
									!print.windEnergy.isValid || !print.solarEnergy.isValid || print.thermalEnergyNew.some(te => te.isValid === false)) {
									this.disableSave = true;
								}
								const thermalIndex = print.thermalEnergyNew.findIndex(th => th.value === '')
								if(thermalIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
								const sourceIndex = print.thermalEnergySourceNew.findIndex(th => th.value === '')
								if(sourceIndex!==-1){
									this.isWetProcessFieldEmpty = true
								}
							});
						}
						if(fw.coatingFinishing.length > 0){
							fw.coatingFinishing.forEach(coating => {
								if(coating.reference.referenceNo === '' || coating.coatingOrFinishing.type === '' ||
									coating.waterUsedInFabric.amount === '' || coating.energyUsed.value === '' || coating.windEnergy.value === '' ||
									coating.solarEnergy.value === ''){
										this.isWetProcessFieldEmpty = true;
								}
								if (coating.reference.referenceNo === '' || !coating.reference.isValid ||
									!coating.windEnergy.isValid || !coating.solarEnergy.isValid || coating.thermalEnergyNew.some(te => te.isValid === false)) {
									this.disableSave = true;
								}
							});
						}
					});
				}
			});
			if(this.disableSave){
				this.$store.commit('setIncorrectFootprintData', true);
			}else{
				this.$store.commit('setIncorrectFootprintData', false);
			}
	}

	private checkTransportEmptyFields(): void {
		this.transportFieldEmpty = false;
		const transport = lodash.cloneDeep(this.wetTransport);
		transport.forEach(tp => {
			if(tp.toCompanyUser.toCompanyID === Guid.Empty || tp.toCompanyUser.toCompanyID === ''){
				this.transportFieldEmpty = true;
			}
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					this.transportFieldEmpty = true;
				}
			});
		});
	}

	private beforeSave(): void {
		// let showWarning: boolean = false;
		// const showSaveWarning: boolean = false;
		this.wetProcessFootprint.forEach((wp) => {
			if(wp.toCompanyUser.toCompanyID === Guid.Empty){
				this.disableSave = true;
				wp.toCompanyUser.isValid = false;
			}
			if(wp.yarnWetprocess.length > 0 && this.wetProcessWrapperData.findIndex(f=> f.stepID === wp.stepID)!==-1){
				wp.yarnWetprocess.forEach((yw) => {
					if(yw.reference.referenceNo === '' && wp.toCompanyUser.toCompanyID!==Guid.Empty){
						yw.reference.isValid = false;
						this.disableSave = true;
					}
					yw.dyiengTreatment.forEach(d => {
						if ((d.reference.referenceNo === "" || d.reference.referenceNo === null)) {
							this.disableSave = true;
							if (d.reference.referenceNo === "") {
								d.reference.isValid = false;
							}
							if (d.toCompanyUser.toCompanyName === "") {
								d.toCompanyUser.isValid = false;
							}
						}
					});
				})
			}
			if(wp.fabricWetprocess.length > 0 && this.wetProcessWrapperData.findIndex(f=> f.stepID === wp.stepID)!==-1){
				wp.fabricWetprocess.forEach((fw) => {
					if(fw.reference.referenceNo === '' && wp.toCompanyUser.toCompanyID!==Guid.Empty){
						fw.reference.isValid = false;
						this.disableSave = true;
					}
					fw.dyiengTreatment.forEach(d => {
						if ((d.reference.referenceNo === "" || d.reference.referenceNo === null)) {
							this.disableSave = true;
							if (d.reference.referenceNo === "") {
								d.reference.isValid = false;
							}
							if (d.toCompanyUser.toCompanyName === "") {
								d.toCompanyUser.isValid = false;
							}
						}
					});
				})
			}
		})
		if (this.disableSave) {
			return;
		}
		this.saveWetProcess();
	}

	private async saveWetProcess(): Promise<void> {
		const wetProcessFootprint = lodash.cloneDeep(this.wetProcessFootprint);
		let wetFieldsEmpty = '';
		//TTD-2761 psrint-19 development
		// if (wetProcessFootprint.length === 0) {
		// 	wetProcessFootprint.footprintStatus = FootPrintCompletedConstant.PARTIAL;
		// } else {
			let finalWp: WetProcessFootprintModel[] = [];
			wetProcessFootprint.forEach((wp) => {
				let isWpCardCompleted: boolean = true
				if(wp.yarnWetprocess.length > 0){
					wp.yarnWetprocess.forEach((yw) => {
						yw.reference.isValid = true;
						if(yw.dyiengTreatment.length > 0){
							yw.dyiengTreatment.forEach((c, ywIndex) => {
								c.thermalEnergy.value = 0
								if (c.solarEnergy.value !== "") {
									if(c.solarEnergy.value.toString().indexOf(".") !== -1){
										c.solarEnergy.value = parseFloat(c.solarEnergy.value.toString());
									}
									else{
										c.solarEnergy.value = parseInt(c.solarEnergy.value.toString());
									}
								} else {
									c.solarEnergy.value = -1;
									wetFieldsEmpty = "solar,";
									isWpCardCompleted = false;
								}
								c.thermalEnergyNew.forEach((te) => {
									if (te.value !== "") {
										if(te.value.toString().indexOf(".") !== -1){
											te.value = parseFloat(te.value.toString());
										}
										else{
											te.value = parseInt(te.value.toString());
										}
									} else {
										te.value = -1;
										wetFieldsEmpty = "thermal,";
										isWpCardCompleted = false;
									}
								});
								if (c.windEnergy.value !== "") {
									if(c.windEnergy.value.toString().indexOf(".") !== -1){
										c.windEnergy.value = parseFloat(c.windEnergy.value.toString());
									}
									else{
										c.windEnergy.value = parseInt(c.windEnergy.value.toString());
									}
								} else {
									c.windEnergy.value = -1;
									wetFieldsEmpty = "wind,";
									isWpCardCompleted = false;
								}
								if (c.energyUsed.value !== "") {
									if(c.energyUsed.value.toString().indexOf(".") !== -1){
										c.energyUsed.value = parseFloat(c.energyUsed.value.toString());
									}
									else{
										c.energyUsed.value = parseInt(c.energyUsed.value.toString());
									}
								} else {
									c.energyUsed.value = -1;
									wetFieldsEmpty = "energy,";
									isWpCardCompleted = false;
								}
								if (c.dyeType.type === '') {
									wetFieldsEmpty = "dyeType,";
									isWpCardCompleted = false;
								}
								if (c.shade.type === '') {
									wetFieldsEmpty = "shade,";
									isWpCardCompleted = false;
								}
								if (c.process.type === '') {
									wetFieldsEmpty = "process,";
									isWpCardCompleted = false;
								}
								if (c.waterUsedInFabric.amount === '') {
									wetFieldsEmpty = "water,";
									isWpCardCompleted = false;
								}
								c.thermalEnergyNew.forEach((ts, tsIndex) => {
									if(ts.value === ''){
										wetFieldsEmpty = "thermalEnergySource,";
										isWpCardCompleted = false;
									}
								});
							});
						}
						if(yw.preTreatment.length > 0){
							yw.preTreatment.forEach(c => {
								c.thermalEnergy.value = 0
								if (c.solarEnergy.value !== "") {
									if(c.solarEnergy.value.toString().indexOf(".") !== -1){
										c.solarEnergy.value = parseFloat(c.solarEnergy.value.toString());
									}
									else{
										c.solarEnergy.value = parseInt(c.solarEnergy.value.toString());
									}
								} else {
									c.solarEnergy.value = -1;
									wetFieldsEmpty = "solar,";
									isWpCardCompleted = false;
								}
								c.thermalEnergyNew.forEach((te) => {
									if (te.value !== "") {
										if(te.value.toString().indexOf(".") !== -1){
											te.value = parseFloat(te.value.toString());
										}
										else{
											te.value = parseInt(te.value.toString());
										}
									} else {
										te.value = -1;
										wetFieldsEmpty = "thermal,";
										isWpCardCompleted = false;
									}
								});
								if (c.windEnergy.value !== "") {
									if(c.windEnergy.value.toString().indexOf(".") !== -1){
										c.windEnergy.value = parseFloat(c.windEnergy.value.toString());
									}
									else{
										c.windEnergy.value = parseInt(c.windEnergy.value.toString());
									}
								} else {
									c.windEnergy.value = -1;
									wetFieldsEmpty = "wind,";
									isWpCardCompleted = false;
								}
								if (c.energyUsed.value !== "") {
									if(c.energyUsed.value.toString().indexOf(".") !== -1){
										c.energyUsed.value = parseFloat(c.energyUsed.value.toString());
									}
									else{
										c.energyUsed.value = parseInt(c.energyUsed.value.toString());
									}
								} else {
									c.energyUsed.value = -1;
									wetFieldsEmpty = "energy,";
									isWpCardCompleted = false;
								}
								if (c.process.type === '') {
									wetFieldsEmpty = "coatingType,";
									isWpCardCompleted = false;
								}
								if (c.waterUsedInFabric.amount === '') {
									wetFieldsEmpty = "water,";
									isWpCardCompleted = false;
								}
								// c.thermalEnergy.forEach((ts) => {
								// 	if(ts.value === ''){
								// 		wetFieldsEmpty = "thermalEnergySource,";
								// 	}
								// });
							});
						}
						if(yw.coatingFinishing.length > 0){
							yw.coatingFinishing.forEach(c => {
								c.thermalEnergy.value = 0
								if (c.solarEnergy.value !== "") {
									if(c.solarEnergy.value.toString().indexOf(".") !== -1){
										c.solarEnergy.value = parseFloat(c.solarEnergy.value.toString());
									}
									else{
										c.solarEnergy.value = parseInt(c.solarEnergy.value.toString());
									}
								} else {
									c.solarEnergy.value = -1;
									wetFieldsEmpty = "solar,";
									isWpCardCompleted = false;
								}
								c.thermalEnergyNew.forEach((te) => {
									if (te.value !== "") {
										if(te.value.toString().indexOf(".") !== -1){
											te.value = parseFloat(te.value.toString());
										}
										else{
											te.value = parseInt(te.value.toString());
										}
									} else {
										te.value = -1;
										wetFieldsEmpty = "thermal,";
										isWpCardCompleted = false;
									}
								});
								if (c.windEnergy.value !== "") {
									if(c.windEnergy.value.toString().indexOf(".") !== -1){
										c.windEnergy.value = parseFloat(c.windEnergy.value.toString());
									}
									else{
										c.windEnergy.value = parseInt(c.windEnergy.value.toString());
									}
								} else {
									c.windEnergy.value = -1;
									wetFieldsEmpty = "wind,";
									isWpCardCompleted = false;
								}
								if (c.energyUsed.value !== "") {
									if(c.energyUsed.value.toString().indexOf(".") !== -1){
										c.energyUsed.value = parseFloat(c.energyUsed.value.toString());
									}
									else{
										c.energyUsed.value = parseInt(c.energyUsed.value.toString());
									}
								} else {
									c.energyUsed.value = -1;
									wetFieldsEmpty = "energy,";
									isWpCardCompleted = false;
								}
								if (c.coatingOrFinishing.type === '') {
									wetFieldsEmpty = "coatingType,";
									isWpCardCompleted = false;
								}
								if (c.waterUsedInFabric.amount === '') {
									wetFieldsEmpty = "water,";
									isWpCardCompleted = false;
								}
							});
						}
					})
				}
				if(wp.fabricWetprocess.length > 0){
					wp.fabricWetprocess.forEach((fw) => {
						fw.reference.isValid = true;
						if(fw.dyiengTreatment.length > 0){
							fw.dyiengTreatment.forEach(c => {
								c.thermalEnergy.value = 0
								if (c.solarEnergy.value !== "") {
									if(c.solarEnergy.value.toString().indexOf(".") !== -1){
										c.solarEnergy.value = parseFloat(c.solarEnergy.value.toString());
									}
									else{
										c.solarEnergy.value = parseInt(c.solarEnergy.value.toString());
									}
								} else {
									c.solarEnergy.value = -1;
									wetFieldsEmpty = "solar,";
									isWpCardCompleted = false;
								}
								c.thermalEnergyNew.forEach((te) => {
									if (te.value !== "") {
										if(te.value.toString().indexOf(".") !== -1){
											te.value = parseFloat(te.value.toString());
										}
										else{
											te.value = parseInt(te.value.toString());
										}
									} else {
										te.value = -1;
										wetFieldsEmpty = "thermal,";
										isWpCardCompleted = false;
									}
								});
								if (c.windEnergy.value !== "") {
									if(c.windEnergy.value.toString().indexOf(".") !== -1){
										c.windEnergy.value = parseFloat(c.windEnergy.value.toString());
									}
									else{
										c.windEnergy.value = parseInt(c.windEnergy.value.toString());
									}
								} else {
									c.windEnergy.value = -1;
									wetFieldsEmpty = "wind,";
									isWpCardCompleted = false;
								}
								if (c.energyUsed.value !== "") {
									if(c.energyUsed.value.toString().indexOf(".") !== -1){
										c.energyUsed.value = parseFloat(c.energyUsed.value.toString());
									}
									else{
										c.energyUsed.value = parseInt(c.energyUsed.value.toString());
									}
								} else {
									c.energyUsed.value = -1;
									wetFieldsEmpty = "energy,";
									isWpCardCompleted = false;
								}
								if (c.dyeType.type === '') {
									wetFieldsEmpty = "dyeType,";
									isWpCardCompleted = false;
								}
								if (c.shade.type === '') {
									wetFieldsEmpty = "shade,";
									isWpCardCompleted = false;
								}
								if (c.process.type === '') {
									wetFieldsEmpty = "process,";
									isWpCardCompleted = false;
								}
								if (c.waterUsedInFabric.amount === '') {
									wetFieldsEmpty = "water,";
									isWpCardCompleted = false;
								}
							});
						}
						if(fw.preTreatment.length > 0){
							fw.preTreatment.forEach(c => {
								c.thermalEnergy.value = 0
								if (c.solarEnergy.value !== "") {
									if(c.solarEnergy.value.toString().indexOf(".") !== -1){
										c.solarEnergy.value = parseFloat(c.solarEnergy.value.toString());
									}
									else{
										c.solarEnergy.value = parseInt(c.solarEnergy.value.toString());
									}
								} else {
									c.solarEnergy.value = -1;
									wetFieldsEmpty = "solar,";
									isWpCardCompleted = false;
								}
								c.thermalEnergyNew.forEach((te) => {
									if (te.value !== "") {
										if(te.value.toString().indexOf(".") !== -1){
											te.value = parseFloat(te.value.toString());
										}
										else{
											te.value = parseInt(te.value.toString());
										}
									} else {
										te.value = -1;
										wetFieldsEmpty = "thermal,";
										isWpCardCompleted = false;
									}
								});
								if (c.windEnergy.value !== "") {
									if(c.windEnergy.value.toString().indexOf(".") !== -1){
										c.windEnergy.value = parseFloat(c.windEnergy.value.toString());
									}
									else{
										c.windEnergy.value = parseInt(c.windEnergy.value.toString());
									}
								} else {
									c.windEnergy.value = -1;
									wetFieldsEmpty = "wind,";
									isWpCardCompleted = false;
								}
								if (c.energyUsed.value !== "") {
									if(c.energyUsed.value.toString().indexOf(".") !== -1){
										c.energyUsed.value = parseFloat(c.energyUsed.value.toString());
									}
									else{
										c.energyUsed.value = parseInt(c.energyUsed.value.toString());
									}
								} else {
									c.energyUsed.value = -1;
									wetFieldsEmpty = "energy,";
									isWpCardCompleted = false;
								}
								if (c.process.type === '') {
									wetFieldsEmpty = "coatingType,";
									isWpCardCompleted = false;
								}
								if (c.waterUsedInFabric.amount === '') {
									wetFieldsEmpty = "water,";
									isWpCardCompleted = false;
								}
							});
						}
						if(fw.printTreatment.length > 0){
							fw.printTreatment.forEach(c => {
								c.thermalEnergy.value = 0
								if (c.solarEnergy.value !== "") {
									if(c.solarEnergy.value.toString().indexOf(".") !== -1){
										c.solarEnergy.value = parseFloat(c.solarEnergy.value.toString());
									}
									else{
										c.solarEnergy.value = parseInt(c.solarEnergy.value.toString());
									}
								} else {
									c.solarEnergy.value = -1;
									wetFieldsEmpty = "solar,";
									isWpCardCompleted = false;
								}
								c.thermalEnergyNew.forEach((te) => {
									if (te.value !== "") {
										if(te.value.toString().indexOf(".") !== -1){
											te.value = parseFloat(te.value.toString());
										}
										else{
											te.value = parseInt(te.value.toString());
										}
									} else {
										te.value = -1;
										wetFieldsEmpty = "thermal,";
										isWpCardCompleted = false;
									}
								});
								if (c.windEnergy.value !== "") {
									if(c.windEnergy.value.toString().indexOf(".") !== -1){
										c.windEnergy.value = parseFloat(c.windEnergy.value.toString());
									}
									else{
										c.windEnergy.value = parseInt(c.windEnergy.value.toString());
									}
								} else {
									c.windEnergy.value = -1;
									wetFieldsEmpty = "wind,";
									isWpCardCompleted = false;
								}
								if (c.energyUsed.value !== "") {
									if(c.energyUsed.value.toString().indexOf(".") !== -1){
										c.energyUsed.value = parseFloat(c.energyUsed.value.toString());
									}
									else{
										c.energyUsed.value = parseInt(c.energyUsed.value.toString());
									}
								} else {
									c.energyUsed.value = -1;
									wetFieldsEmpty = "energy,";
									isWpCardCompleted = false;
								}
								if (c.printType.type === '') {
									wetFieldsEmpty = "printType,";
									isWpCardCompleted = false;
								}
								if (c.shade.type === '') {
									wetFieldsEmpty = "shade,";
									isWpCardCompleted = false;
								}
								if (c.process.type === '') {
									wetFieldsEmpty = "process,";
									isWpCardCompleted = false;
								}
								if (c.waterUsedInFabric.amount === '') {
									wetFieldsEmpty = "water,";
									isWpCardCompleted = false;
								}
							});
						}
						if(fw.coatingFinishing.length > 0){
							fw.coatingFinishing.forEach(c => {
								c.thermalEnergy.value = 0
								if (c.solarEnergy.value !== "") {
									if(c.solarEnergy.value.toString().indexOf(".") !== -1){
										c.solarEnergy.value = parseFloat(c.solarEnergy.value.toString());
									}
									else{
										c.solarEnergy.value = parseInt(c.solarEnergy.value.toString());
									}
								} else {
									c.solarEnergy.value = -1;
									wetFieldsEmpty = "solar,";
									isWpCardCompleted = false;
								}
								c.thermalEnergyNew.forEach((te) => {
									if (te.value !== "") {
										if(te.value.toString().indexOf(".") !== -1){
											te.value = parseFloat(te.value.toString());
										}
										else{
											te.value = parseInt(te.value.toString());
										}
									} else {
										te.value = -1;
										wetFieldsEmpty = "thermal,";
										isWpCardCompleted = false;
									}
								});
								if (c.windEnergy.value !== "") {
									if(c.windEnergy.value.toString().indexOf(".") !== -1){
										c.windEnergy.value = parseFloat(c.windEnergy.value.toString());
									}
									else{
										c.windEnergy.value = parseInt(c.windEnergy.value.toString());
									}
								} else {
									c.windEnergy.value = -1;
									wetFieldsEmpty = "wind,";
									isWpCardCompleted = false;
								}
								if (c.energyUsed.value !== "") {
									if(c.energyUsed.value.toString().indexOf(".") !== -1){
										c.energyUsed.value = parseFloat(c.energyUsed.value.toString());
									}
									else{
										c.energyUsed.value = parseInt(c.energyUsed.value.toString());
									}
								} else {
									c.energyUsed.value = -1;
									wetFieldsEmpty = "energy,";
									isWpCardCompleted = false;
								}
								if (c.coatingOrFinishing.type === '') {
									wetFieldsEmpty = "coatingType,";
									isWpCardCompleted = false;
								}
								if (c.waterUsedInFabric.amount === '') {
									wetFieldsEmpty = "water,";
									isWpCardCompleted = false;
								}
							});
						}
					});
				}
				// wp.status = isWpCardCompleted ? FootPrintCompletedConstant.COMPLETED : FootPrintCompletedConstant.PARTIAL;
				if(wp.stepID.includes(",")){
					let stepIds:string[] = wp.stepID.split(",");
					stepIds.forEach(id => {
						const wetProcess: WetProcessFootprintModel = cloneDeep(wp);
						wetProcess.stepID = id;
						finalWp.push(wetProcess);
					})   
				}else{
					finalWp.push(wp);
				}
			});
		this.isSaving = true;
		this.wetFpStatus();
		this.wetTpStatus();
		let finalWetProcessStatus = FootPrintCompletedConstant.INCOMPLETE;
		if(this.footprintStatus === 'green'){
			finalWetProcessStatus = FootPrintCompletedConstant.COMPLETED;
		}else if(this.footprintStatus === 'orange'){
			finalWetProcessStatus = FootPrintCompletedConstant.PARTIAL;
		}
		let finalWetTransportStatus = FootPrintCompletedConstant.INCOMPLETE;
		if(this.transportStatus === 'green'){
			finalWetTransportStatus = FootPrintCompletedConstant.COMPLETED;
		}else if(this.transportStatus === 'orange'){
			finalWetTransportStatus = FootPrintCompletedConstant.PARTIAL;
		}
		const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		const wp: WetprocessModel = {
			wetprocessFootprint: finalWp,
			footprintStatus: finalWetProcessStatus,
			transportStatus: finalWetTransportStatus,
			dyiengTreatment: [],
			printTreatment: [],
			preTreatment: [],
			coatingFinishing: [],
			transport: [],
		}
		const wetProcess: WetProcessFootprintRequestModel = {
			wetprocess:wp,
			footprintDeadline: order.footprintDeadline !== (this.deadline !== "" ? new Date(this.deadline).toISOString() : "") ? new Date(this.deadline).toISOString(): order.footprintDeadline,
			footprintFilledByCompanyID: order.footprintDeadline !== '' ? this.user.companyId:Guid.Empty,
		};
		const result = await this.clientRequestFootPrintData.saveWetProcessData(this.orderId, wetProcess);
		if(result.success){
			this.wetProcessFootprint = lodash.cloneDeep(result.order.wetprocess.wetprocessFootprint);
			this.createWetProcessWrapperData();
			this.wetProcessFootprintClone = lodash.cloneDeep(this.wetProcessFootprint);
			this.wetProcessFootprintCloneInitialState = lodash.cloneDeep(this.wetProcessFootprint);
			this.compareUserClone();
			this.wetFpStatus();
			this.checkNonEmptyFields();
			this.showWetFootprint = true;
			this.wetTransportChangedCounter = 0;
			this.changedCounter = 0;
		}
		this.isSaving = false;
		this.showWarningModal = false;
		this.isCancelBtnEnable = false;
		this.$store.commit("setFootprintChanged", '');
		this.$store.commit("setChangedFootprintName", '');
		this.$store.commit('setIncorrectFootprintData', false);
	}

	// TTD-3061 Sprint19
	private handleFpPrevious(): void {
		this.showWetFootprint = false;
		this.navigateTab(1);
	}

	// TTD-3061 Sprint19
	private handleTpPrevious(): void {
		this.showWetFootprint = true;
		this.showWetTransport = false;
	}

	// TTD-3061 Sprint19
	private handleTpNext(): void {
		this.showWetTransport = false;
		//TTD-4276 issue1
		if(this.isFootprintChanged === FootPrintChangedConstant.DATA){
			this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
			this.saveChanges();
			this.showWetFootprint = true;
			return;
		}else{
			this.navigateTab(3);
		}
	}

	// TTD-3061 Sprint19
	private handleFpNext(): void {
		this.showWetFootprint = false;
		this.showWetTransport = true;
	}

	private toggleWetFootprint(): void {
		// if(this.changedCounter > 0){
		// 	this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
		// }
		// else{
			this.showWetFootprint = !this.showWetFootprint;
			if(this.showWetTransport){
				this.showWetTransport = false;
			}
		// }
	}

	private toggleTransport(index: number): void {
		this.wetTransport[index].showTransport = !this.wetTransport[index].showTransport;
	}

	private removeTransportFootprint(index: number): void {
		if(this.isSaving){
            return;
        }
		let flag: boolean = false;
		this.wetTransport.splice(index, 1);
		this.wetTransportClone.splice(index, 1);
		this.wetTransport.findIndex(t => t.showDelete===true) !== -1 ? flag = true: flag = false;
		this.transportToBeDeleted = flag;
		this.compareWetTransportClone();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.WET_TP);
		this.wetTransportChangedCounter += 1;
	}

	private toggleWetTransport(): void {
		const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		// TTD-2761 sprint-19 development
		let isTreatmentsFilled: boolean = false;
		let isToCompanyName: boolean = false;
		order.wetprocess.wetprocessFootprint.forEach((wp) => {
			if(wp.toCompanyUser.toCompanyID!==Guid.Empty){
				isToCompanyName = true
			}
			if(wp.yarnWetprocess.length > 0){
				wp.yarnWetprocess.forEach((yw) => {
					if(yw.coatingFinishing.length >0 || yw.dyiengTreatment.length > 0 || yw.preTreatment.length > 0){
						isTreatmentsFilled = true
					}

				})
			}
			if(wp.fabricWetprocess.length > 0){
				wp.fabricWetprocess.forEach((fw) => {
					if(fw.coatingFinishing.length >0 || fw.dyiengTreatment.length > 0 || fw.preTreatment.length > 0 || fw.printTreatment.length > 0){
						isTreatmentsFilled = true
					}

				})
			}
		})
		// if(isTreatmentsFilled && isToCompanyName && this.changedCounter === 0 && (this.wetProcessFootprint.length === this.wetProcessFootprintCloneInitialState.length)){
			this.showWetTransport = !this.showWetTransport;
			if(this.showWetTransport){
				this.showWetFootprint = false;
			}
		// }
		// else{
		// 	this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
		// }
	}

	private addModeTransport(index: number): void {
		if(this.isSaving || this.userRole === 'Reader'){
			return;
		}
		this.wetTransport[index].modeOfTransport.push(new ModeOfTransportModel);
		this.wetTransportClone[index].modeOfTransport.push(new ModeOfTransportModel);
		this.compareWetTransportClone();
		this.checkTransportEmptyFields();
	}

	private get showWarningTransport(): boolean {
		let count = 0;
		this.wetTransport.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					count++;
				}
			});
		});
		if (count > 0) {
			return true;
		}
		return false;
	}

	private async saveWetTransportData(): Promise<void> {
		this.isSaving = true;
		const finalWetTransPort : TransportFootprintModel[] = [];
		const transport = lodash.cloneDeep(this.wetTransport);
		transport.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value !== ""){
					mode.distance.value = Number(mode.distance.value);
				}
				else{
					mode.distance.value = -1;
				}
			});
			if(tp.stepID.includes(",")){
				let stepIds:string[] = tp.stepID.split(",");
				stepIds.forEach(id => {
					const wetTp: TransportFootprintModel = cloneDeep(tp);
					wetTp.stepID = id;
					finalWetTransPort.push(wetTp);
				})   
			}else{
				finalWetTransPort.push(tp);
			}
		});
		this.wetTpStatus();
		let finalWetTransportStatus = FootPrintCompletedConstant.INCOMPLETE;
		if(this.transportStatus === 'green'){
			finalWetTransportStatus = FootPrintCompletedConstant.COMPLETED;
		}else if(this.transportStatus === 'orange'){
			finalWetTransportStatus = FootPrintCompletedConstant.PARTIAL;
		}
		const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		const wetTransport: TransportFootprintRequestModel = {
			footprintDeadline: order.footprintDeadline !== (this.deadline !== "" ? new Date(this.deadline).toISOString() : "") ? new Date(this.deadline).toISOString(): order.footprintDeadline,
			data: {
				transportType: FootPrintConstant.WETPROCESS,
				transportStatus: finalWetTransportStatus,
				transport: finalWetTransPort
			}
		};
		const result = await this.clientRequestFootPrintData.saveYarnTransportAsync(this.orderId, wetTransport);
		if(result.success){
			this.wetTransport = [];
			result.order.wetprocess.transport.forEach((tp) => {
				const transPortIndex = this.wetProcessWrapperData.findIndex(wp => tp.stepID === wp.stepID);
				if(transPortIndex!==-1){
					this.wetTransport.push(tp);
				}
			})
			// this.wetTransport = lodash.cloneDeep(result.order.wetprocess.transport);
			this.wetTransport.forEach(transport => {
				transport.modeOfTransport.forEach(m => {
					if(m.distance.value === -1){
						m.distance.value = '';
					}
					m.distance.isValid = true;
					m.transportType.isValid = true;
					m.distanceUnit.isValid = true;
				});
			});
			if(this.wetTransport.length > 0){
				this.wetTransport[this.wetTransport.length-1].showTransport = true;
			}
			this.wetTransportClone = lodash.cloneDeep(this.wetTransport);
			this.wetTransportCloneInitialState = lodash.cloneDeep(this.wetTransport);
			this.wetTpStatus();
			this.showWetTransport = true;
			this.wetTransportChangedCounter = 0;
			// this.navigateTab(3);
		}
		this.isSaving = false;
		this.wetTransportChangedCounter = 0;
		this.$store.commit("setFootprintChanged", '');
		this.$store.commit("setChangedFootprintName", '');
		this.$store.commit('setIncorrectFootprintData', false);
	}

	private async validateWetTransport(event: any, fieldname: string, transportIndex: number, modeTransportIndex: number): Promise<void> {
        switch (fieldname) {
            case "transport-type": {
				event !== '' ? this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.isValid = true : this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.isValid = false;
				!this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.isValid ? this.disableSave=true : this.disableSave=false;
				this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.value = event;
				this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.filledByCompanyID = this.user.companyId;
				this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.filledByPrimarySource = this.user.companyId === this.wetTransport[transportIndex].toCompanyUser.toCompanyID ? true : false;
                break;
			}
			case "distance": {
				/* eslint-disable-next-line */
				event.target.value.match(/^([0-9][0-9]{0,4})$/) || event.target.value === '' ? Number(event.target.value) <= 50000 ? this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.isValid = true : this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.isValid = false : this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.isValid = false;
				event.target.value !== "" && event.target.value.match(/^([0-9][0-9]{0,4})$/) === null ? this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.isValid = true : '';
				!this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.isValid ? this.disableSave=true : this.disableSave=false;
				this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.value = event.target.value !== "" && event.target.value.match(/^([0-9][0-9]{0,4})$/) !== null ? Number(event.target.value) : "";
				this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.filledByCompanyID = this.user.companyId;
				this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.filledByPrimarySource = this.user.companyId === this.wetTransport[transportIndex].toCompanyUser.toCompanyID ? true : false;
                break;
			}
			case "distance-unit": {
				event !== '' ? this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.isValid = true : this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.isValid = false;
				!this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.isValid ? this.disableSave=true : this.disableSave=false;
				this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.unit = event;
				this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.filledByCompanyID = this.user.companyId;
				this.wetTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.filledByPrimarySource = this.user.companyId === this.wetTransport[transportIndex].toCompanyUser.toCompanyID ? true : false;
                break;
			}
			default:
                break;
        }
		this.compareWetTransportClone();
    }

	private get orderPartners(): DropdownModel[] {
		const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		const steps = this.footprintPartner.filter(p => p.orderId === order.orderId);
		let partnerList: DropdownModel[] = steps.map(s => new DropdownModel(s.toCompanyId, s.toCompanyName));
		partnerList = lodash.uniqBy(partnerList, 'value');
		return partnerList;
	}

	private addDyeing(wrapperIndex:number,wpIndex:number,wpYarnIndex:number, type:string): void {
		if(this.userRole === "Reader" || this.isSaving){
			return;
		}
		this.wetProcessFootprint[wpIndex].treatmentType.value = type;
			this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment.push(new DyingTreatmentModel());
			this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment.forEach((dye, index) => {
				if(index === (this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment.length - 1)){
					dye.ID = uuidv4();
				}
			});
			this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment[this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment.length -1].showWetDyeing = true;
			this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment[this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment.length -1].filledByCompanyID = this.user.companyId;
			this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment[this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment.length -1].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[wpIndex].toCompanyUser.toCompanyID ? true : false;
		
		this.compareUserClone();
		this.checkNonEmptyFields();
		if(this.wetProcessFootprint[wpIndex].treatmentType.value === 'YARN'){
			this.scrollToComponent('ydyeing', wrapperIndex, wpIndex, wpYarnIndex, this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment.length -1);
		}else{
			this.scrollToComponent('fdyeing', wrapperIndex, wpIndex, wpYarnIndex, this.yarnOrFabric(type,wpIndex,wpYarnIndex).dyiengTreatment.length -1);
		}
	}

	private addPreTreatment(wrapperIndex:number, wpIndex:number,wpYarnIndex:number, type:string): void {
		if(this.userRole === "Reader" || this.isSaving){
			return;
		}
		this.wetProcessFootprint[wpIndex].treatmentType.value = type;
		this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.push(new PreTreatmentModel());
		let id: string = "";
		this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.forEach((pre, index) => {
			if(index === (this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.length - 1)){
				id = uuidv4();
				pre.ID = id;
			}
		});
		this.cloneYarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.push(new PreTreatmentModel());
		this.cloneYarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.forEach((pre, index) => {
			if(index === (this.cloneYarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.length - 1)){
				id = uuidv4();
				pre.ID = id;
			}
		});
		this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment[this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.length -1].showWetPre = true;
		this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment[this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.length -1].filledByCompanyID = this.user.companyId;
		this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment[this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.length -1].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[wpIndex].toCompanyUser.toCompanyID ? true : false;
		this.compareUserClone();
		this.checkNonEmptyFields();
		if(this.wetProcessFootprint[wpIndex].treatmentType.value === 'YARN'){
			this.scrollToComponent('ypre', wrapperIndex, wpIndex, wpYarnIndex, this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.length -1);
		}else{
			this.scrollToComponent('fpre', wrapperIndex, wpIndex, wpYarnIndex, this.yarnOrFabric(type,wpIndex,wpYarnIndex).preTreatment.length -1);
		}
	}

	private addPrintTreatment(wrapperIndex:number, wpIndex:number,wpYarnIndex:number): void {
		if(this.userRole === "Reader" || this.isSaving){
			return;
		}
		this.wetProcessFootprint[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment.push(new PrintTreatmentModel());
		this.wetProcessFootprint[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment[this.wetProcessFootprint[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment.length -1].showWetPrint = true;
		this.wetProcessFootprint[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment[this.wetProcessFootprint[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment.length -1].filledByCompanyID = this.user.companyId;
		this.wetProcessFootprint[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment[this.wetProcessFootprint[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment.length -1].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[wpIndex].toCompanyUser.toCompanyID ? true : false;
		let id: string = "";
		this.wetProcessFootprint[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment.forEach((print, index) => {
			if(index === (this.wetProcessFootprint[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment.length - 1)){
				id = uuidv4();
				print.ID = id;
			}
		});

		this.wetProcessFootprintClone[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment.push(new PrintTreatmentModel());
		this.wetProcessFootprintClone[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment.forEach((print, index) => {
			if(index === (this.wetProcessFootprintClone[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment.length - 1)){
				id = uuidv4();
				print.ID = id;
			}
		});
		this.compareUserClone();
		this.checkNonEmptyFields();
		this.scrollToComponent('print', wrapperIndex, wpIndex, wpYarnIndex, this.wetProcessFootprint[wpIndex].fabricWetprocess[wpYarnIndex].printTreatment.length - 1);
	}

	private addCoatingOrFinishing(wrapperIndex:number, wpIndex:number,wpYarnIndex:number, type:string): void {
		if(this.userRole === "Reader" || this.isSaving){
			return;
		}
		this.wetProcessFootprint[wpIndex].treatmentType.value = type;
		this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.push(new CoatingOrFinishingModel());
		let id: string = "";
		this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.forEach((print, index) => {
			if(index === (this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.length - 1)){
				id = uuidv4();
				print.ID = id;
			}
		});
		this.cloneYarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.push(new CoatingOrFinishingModel());
		this.cloneYarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.forEach((coating, index) => {
			if(index === (this.cloneYarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.length - 1)){
				id = uuidv4();
				coating.ID = id;
			}
		});
		this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing[this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.length -1].showWetCoating = true;
		this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing[this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.length -1].filledByCompanyID = this.user.companyId;
		this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing[this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.length -1].filledByPrimarySource = this.wetProcessFootprint[wpIndex].toCompanyUser.toCompanyID ? true : false;
		this.compareUserClone();
		this.checkNonEmptyFields();
		if(this.wetProcessFootprint[wpIndex].treatmentType.value === 'YARN'){
			this.scrollToComponent('ycoating', wrapperIndex, wpIndex, wpYarnIndex, this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.length -1);
		}else{
			this.scrollToComponent('fcoating', wrapperIndex, wpIndex, wpYarnIndex, this.yarnOrFabric(type,wpIndex,wpYarnIndex).coatingFinishing.length -1);
		}
	}

	private compareUserClone() {
		let changes: number = 0;
		const wetProcess: [string, any][] = Object.entries(this.wetProcessFootprint);
		const wetProcessClone: [string, any][] = Object.entries(this.wetProcessFootprintClone);

		const getObjectDiff = (obj1: any, obj2: any) => {
		const obj1Props = Object.keys(obj1);
		const obj2Props = Object.keys(obj2);

		const keysWithDiffValue = obj1Props.reduce((keysWithDiffValueAccumulator, key) => {
			// eslint-disable-next-line no-prototype-builtins
			const propExistsOnObj2 = obj2.hasOwnProperty(key);
			const hasNestedValue = obj1[key] instanceof Object && obj2[key] instanceof Object;
			const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];

			if (!propExistsOnObj2) {
			keysWithDiffValueAccumulator.push(key);
			} else if (hasNestedValue) {
			const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
			if (keyIndex >= 0) {
				keysWithDiffValueAccumulator.splice(keyIndex, 1);
			}
			const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
			for (const diff of nestedDiffs) {
				keysWithDiffValueAccumulator.push(`${key}.${diff}`);
			}
			} else if (keyValuePairBetweenBothObjectsIsEqual) {
			const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
			keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
			}
			return keysWithDiffValueAccumulator;
		}, obj2Props);

		return keysWithDiffValue;
		};
		const differences = getObjectDiff(wetProcess, wetProcessClone);
		changes += differences.length;
		differences.forEach(element => {
			if (((element.includes('status') || element.includes('isValid') || element.includes('filledByCompanyID') || element.includes('filledByPrimarySource') || element.includes('toCompanyName') || element.includes('isUnique') || element.includes('transportStatus') || element.includes('footprintStatus') || element.includes('showWetDyeing') || element.includes('showWetPrint') || element.includes('showWetPre') || element.includes('showWetCoating') || element.includes('showTransport') || element.includes('msdsDoc') || element.includes('energySupportingDocument') ||
			element.includes('ID') || element.includes('yarnFootprintID') || element.includes('showWetProcess')) && !element.includes('toCompanyUser.toCompanyName'))
			) {
				changes = changes - 1;
				}
		});
		this.changedCounter = changes + this.treatmentDeletedCounter;
		changes > 0? this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA): '';
		changes > 0? this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.WET_FP): '';
	}

	private compareWetTransportClone() {
		let changes: number = 0;
		const wetTransport: [string, any][] = Object.entries(this.wetTransport);
		const wetTransportClone: [string, any][] = Object.entries(this.wetTransportClone);

		const getObjectDiff = (obj1: any, obj2: any) => {
		const obj1Props = Object.keys(obj1);
		const obj2Props = Object.keys(obj2);

		const keysWithDiffValue = obj1Props.reduce((keysWithDiffValueAccumulator, key) => {
			// eslint-disable-next-line no-prototype-builtins
			const propExistsOnObj2 = obj2.hasOwnProperty(key);
			const hasNestedValue = obj1[key] instanceof Object && obj2[key] instanceof Object;
			const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];

			if (!propExistsOnObj2) {
			keysWithDiffValueAccumulator.push(key);
			} else if (hasNestedValue) {
			const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
			if (keyIndex >= 0) {
				keysWithDiffValueAccumulator.splice(keyIndex, 1);
			}
			const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
			for (const diff of nestedDiffs) {
				keysWithDiffValueAccumulator.push(`${key}.${diff}`);
			}
			} else if (keyValuePairBetweenBothObjectsIsEqual) {
			const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
			keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
			}
			return keysWithDiffValueAccumulator;
		}, obj2Props);

		return keysWithDiffValue;
		};
		const differences = getObjectDiff(wetTransport, wetTransportClone);
		changes += differences.length;
		differences.forEach(element => {
			if (element.includes('isValid') || element.includes('filledByCompanyID') || element.includes('showTransport') || element.includes('showDelete') || element.includes('filledByPrimarySource')) {
				changes = changes-1;
			}
		});
		this.wetTransportChangedCounter = changes;
		changes > 0? this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA): '';
		changes > 0? this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.WET_TP): '';
		this.checkTransportEmptyFields();
	}
	// TTD-2761 development
	private removeWetProcess(index: number): void{
		if(this.isSaving){
			return
		}
		this.wetProcessFootprint.splice(index, 1);
		this.wetProcessFootprintClone.splice(index, 1);
		this.isCancelBtnEnable = true;
	}

	private addYarnOrFabric(wrapperIndex: number, type:string, index:number): void{
		if(this.userRole === "Reader" || this.isSaving){
			return;
		}
		this.wetProcessFootprint[index].treatmentType.filledByCompanyID = this.user.companyId;
		this.wetProcessFootprint[index].treatmentType.filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
		if(type === 'yarn'){
			this.wetProcessFootprint[index].treatmentType.value= 'YARN';
			this.wetProcessFootprint[index].yarnWetprocess.push(new YarnWetProcessModel());
			this.wetProcessFootprintClone[index].yarnWetprocess.push(new YarnWetProcessModel());
			this.wetProcessFootprint[index].yarnWetprocess[this.wetProcessFootprint[index].yarnWetprocess.length-1].ID = uuidv4();
			this.wetProcessFootprintClone[index].yarnWetprocess[this.wetProcessFootprintClone[index].yarnWetprocess.length-1].ID = uuidv4();
			this.wetProcessFootprint[index].yarnWetprocess[this.wetProcessFootprint[index].yarnWetprocess.length -1].showYarn = true;
			this.wetProcessFootprint[index].yarnWetprocess[this.wetProcessFootprint[index].yarnWetprocess.length -1].filledByCompanyID = this.user.companyId;
			this.wetProcessFootprint[index].yarnWetprocess[this.wetProcessFootprint[index].yarnWetprocess.length -1].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
			this.scrollToComponent('yarn', wrapperIndex, this.wetProcessFootprint[index].yarnWetprocess.length -1,0,0);
		}else{
			this.wetProcessFootprint[index].treatmentType.value= 'FABRIC';
			this.wetProcessFootprint[index].fabricWetprocess.push(new FabricWetprocessModel());
			this.wetProcessFootprintClone[index].fabricWetprocess.push(new FabricWetprocessModel());
			this.wetProcessFootprint[index].fabricWetprocess[this.wetProcessFootprint[index].fabricWetprocess.length-1].ID = uuidv4();
			this.wetProcessFootprint[index].fabricWetprocess[this.wetProcessFootprint[index].fabricWetprocess.length -1].showFabric = true;
			this.wetProcessFootprintClone[index].fabricWetprocess[this.wetProcessFootprintClone[index].fabricWetprocess.length-1].ID = uuidv4();
			this.wetProcessFootprint[index].fabricWetprocess[this.wetProcessFootprint[index].fabricWetprocess.length -1].filledByCompanyID = this.user.companyId;
			this.wetProcessFootprint[index].fabricWetprocess[this.wetProcessFootprint[index].fabricWetprocess.length -1].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
			this.scrollToComponent('fabric', wrapperIndex, this.wetProcessFootprint[index].fabricWetprocess.length -1,0,0);
		}
		this.defaultDyeingTreatment(index);
		this.compareUserClone();
		this.checkNonEmptyFields();
	}

	private removeYarnOrFabric(type:string, wpIndex: number,typeIndex:number): void{
		if(this.isSaving){
			return
		}
		if(type === 'yarn'){
			this.wetProcessFootprint[wpIndex].yarnWetprocess.splice(typeIndex, 1);
			this.wetProcessFootprintClone[wpIndex].yarnWetprocess.splice(typeIndex, 1);
		}else{
			this.wetProcessFootprint[wpIndex].fabricWetprocess.splice(typeIndex, 1);
			this.wetProcessFootprintClone[wpIndex].fabricWetprocess.splice(typeIndex, 1);
		}
		this.treatmentDeletedCounter++
		this.checkNonEmptyFields();
		this.compareUserClone();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.WET_FP);
	}

	private get treatmentTitle(): string{
		if(this.selectedTreatmentType !== 'YARN'){
			return 'Fabric'
		}
		return 'Yarn'
	}

	private yarnRefOptions(index: number, wrapperIndex: number, wpYarn:YarnWetProcessModel): DropdownModel[] {
		if(wpYarn){
			if(wpYarn.yarnFootprintID === Guid.Empty){
				wpYarn.yarnFootprintID = ''
			}
		}
		const refList = lodash.cloneDeep(this.wetProcessWrapperData[wrapperIndex].yarnRefList);
		refList.forEach(r => {
			if(this.wetProcessFootprint[index].yarnWetprocess.findIndex(y => y.yarnFootprintID === r.value || r.value === Guid.Empty) !== -1){
				r.disabled = true;
			}
			else{
				r.disabled = false;
			}
		});
		if(refList.length === 0){
			const defaultRefList: DropdownModel[] = [];
			defaultRefList.push(new DropdownModel('', 'No yarn data available yet'));
			defaultRefList[0].disabled = true;
			return defaultRefList;
		}
		return refList;
	}

	private fabricRefOptions(index: number, wrapperIndex: number, wpFabric:FabricWetprocessModel): DropdownModel[] {
		if(wpFabric){
			if(wpFabric.fabricFootprintID === Guid.Empty){
				wpFabric.fabricFootprintID = ''
			}
		}
		const refList = lodash.cloneDeep(this.wetProcessWrapperData[wrapperIndex].fabricRefList);
		refList.forEach(r => {
			if(this.wetProcessFootprint[index].fabricWetprocess.findIndex(f => f.fabricFootprintID === r.value || r.value === Guid.Empty) !== -1){
				r.disabled = true;
			}
			else{
				r.disabled = false;
			}
		});
		if(refList.length === 0){
			const defaultRefList: DropdownModel[] = [];
			defaultRefList.push(new DropdownModel(Guid.Empty, 'No fabric data available yet'));
			defaultRefList[0].disabled = true;
			return defaultRefList;
		}
		return refList;
	}

	private defaultDyeingTreatment(index:number){
		// this.checkIsCompanyNTypeUnique(index);
		if(this.wetProcessFootprint[index].treatmentType.value === 'YARN'){
			if(this.wetProcessFootprint[index].yarnWetprocess.length === 0){
				this.wetProcessFootprint[index].yarnWetprocess.push(new YarnWetProcessModel());
				this.wetProcessFootprint[index].yarnWetprocess[0].ID = uuidv4();
				this.wetProcessFootprint[index].yarnWetprocess[0].dyiengTreatment.push(new DyingTreatmentModel());
				this.wetProcessFootprint[index].yarnWetprocess[0].dyiengTreatment[0].showWetDyeing = true;
				this.wetProcessFootprint[index].yarnWetprocess[0].showYarn = true;
				this.wetProcessFootprint[index].yarnWetprocess[0].filledByCompanyID = this.user.companyId;
				this.wetProcessFootprint[index].yarnWetprocess[0].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
				this.wetProcessFootprint[index].yarnWetprocess[0].dyiengTreatment[0].ID = uuidv4();
				// this.wetProcessFootprint[index].yarnWetprocess[0].dyiengTreatment[0].showWetDyeing = true;

				this.wetProcessFootprintClone[index].yarnWetprocess.push(new YarnWetProcessModel());
				this.wetProcessFootprintClone[index].yarnWetprocess[0].dyiengTreatment.push(new DyingTreatmentModel());
				this.wetProcessFootprintClone[index].yarnWetprocess[0].showYarn = true;
				this.wetProcessFootprintClone[index].yarnWetprocess[0].dyiengTreatment[0].ID = uuidv4();
				this.wetProcessFootprintClone[index].yarnWetprocess[0].filledByCompanyID = this.user.companyId;
				this.wetProcessFootprintClone[index].yarnWetprocess[0].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
				// this.wetProcessFootprint[index].fabricWetprocess = [];
			}
		}else{
			if(this.wetProcessFootprint[index].fabricWetprocess.length === 0){
				this.wetProcessFootprint[index].fabricWetprocess.push(new FabricWetprocessModel());
				this.wetProcessFootprint[index].fabricWetprocess[0].ID = uuidv4();
				this.wetProcessFootprint[index].fabricWetprocess[0].dyiengTreatment.push(new DyingTreatmentModel());
				this.wetProcessFootprint[index].fabricWetprocess[0].dyiengTreatment[0].showWetDyeing = true;
				this.wetProcessFootprint[index].fabricWetprocess[0].showFabric = true;
				this.wetProcessFootprint[index].fabricWetprocess[0].dyiengTreatment[0].ID = uuidv4();
				this.wetProcessFootprint[index].fabricWetprocess[0].filledByCompanyID = this.user.companyId;
				this.wetProcessFootprint[index].fabricWetprocess[0].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
				// this.wetProcessFootprint[index].fabricWetprocess[0].dyiengTreatment[0].showWetDyeing = true;

				this.wetProcessFootprintClone[index].fabricWetprocess.push(new FabricWetprocessModel());
				this.wetProcessFootprintClone[index].fabricWetprocess[0].dyiengTreatment.push(new DyingTreatmentModel());
				this.wetProcessFootprintClone[index].fabricWetprocess[0].showFabric = true;
				this.wetProcessFootprintClone[index].fabricWetprocess[0].dyiengTreatment[0].ID = uuidv4();
				this.wetProcessFootprintClone[index].fabricWetprocess[0].filledByCompanyID = this.user.companyId;
				this.wetProcessFootprintClone[index].fabricWetprocess[0].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
				// this.wetProcessFootprint[index].yarnWetprocess = [];
			}
		}
	}

	private yarnOrFabric(treatment:string, index: number, typeIndex: number): YarnWetProcessModel | FabricWetprocessModel {
		this.wetProcessFootprint[index].treatmentType.value = treatment;
		if(this.wetProcessFootprint[index].treatmentType.value === 'YARN'){
			return this.wetProcessFootprint[index].yarnWetprocess[typeIndex]

		}
		return this.wetProcessFootprint[index].fabricWetprocess[typeIndex]

	}

	private addSource(type:string, sourceType: string, index:number, yWfIndex: number, treatmentIndex:number){
		this.wetProcessFootprint[index].treatmentType.value = type;
		if(sourceType === 'dyeing'){
			this.yarnOrFabric(type,index, yWfIndex).dyiengTreatment[treatmentIndex].thermalEnergyNew.push(new ThermalEnergyModel());
			this.yarnOrFabric(type,index, yWfIndex).dyiengTreatment[treatmentIndex].thermalEnergySourceNew.push(new ThermalEnergyModel());
		}else if(sourceType === 'pre'){
			this.yarnOrFabric(type,index, yWfIndex).preTreatment[treatmentIndex].thermalEnergyNew.push(new ThermalEnergyModel());
			this.yarnOrFabric(type,index, yWfIndex).preTreatment[treatmentIndex].thermalEnergySourceNew.push(new ThermalEnergyModel());
		}else if(sourceType === 'printing'){
			this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].thermalEnergyNew.push(new ThermalEnergyModel());
			this.wetProcessFootprint[index].fabricWetprocess[yWfIndex].printTreatment[treatmentIndex].thermalEnergySourceNew.push(new ThermalEnergyModel());
		}else{
			this.yarnOrFabric(type,index, yWfIndex).coatingFinishing[treatmentIndex].thermalEnergyNew.push(new ThermalEnergyModel());
			this.yarnOrFabric(type,index, yWfIndex).coatingFinishing[treatmentIndex].thermalEnergySourceNew.push(new ThermalEnergyModel());
		}
		this.checkNonEmptyFields();
		this.compareUserClone();
	}

	private cloneYarnOrFabric(type:string, index: number, typeIndex: number): YarnWetProcessModel | FabricWetprocessModel {
		this.wetProcessFootprint[index].treatmentType.value = type
		if(this.wetProcessFootprint[index].treatmentType.value === 'YARN'){
			return this.wetProcessFootprintClone[index].yarnWetprocess[typeIndex]

		}
		return this.wetProcessFootprintClone[index].fabricWetprocess[typeIndex]

	}

	private checkIsCompanyNTypeUnique(index:number){
		const isCompanyTreatmentUnique = this.wetProcessFootprint.findIndex((f, i) =>
		i !== index &&
		f.toCompanyUser.toCompanyID === this.wetProcessFootprint[index].toCompanyUser.toCompanyID &&
		f.treatmentType.value === this.wetProcessFootprint[index].treatmentType.value
		);

		if (isCompanyTreatmentUnique !== -1) {
			this.wetProcessFootprint[index].treatmentType.value = '';
			this.companyName = this.wetProcessFootprint[index].toCompanyUser.toCompanyName;
			this.showWarningForTreatment = true;
			this.changedCounter -=1;
			return
		}
	}

	private scrollToComponent(type:string, wrapperIndex: number, index:number,indexFabYarn:number, indexDyieng:number) : void{
		if(type === 'wp'){
			this.componentId = 'wpf-wetprocess-' + index;
		}else if(type === 'yarn'){
			this.componentId = 'wpYarn-' + index;
		}else if(type === 'fabric'){
			this.componentId = 'wpFabric-' + index;
		}else if(type === 'ydyeing'){
			this.wetProcessFootprint[index].yarnWetprocess[indexFabYarn].coatingFinishing.forEach(c => c.showWetCoating = false);
			this.wetProcessFootprint[index].yarnWetprocess[indexFabYarn].preTreatment.forEach(p => p.showWetPre  = false);
			this.componentId = 'wp_' + wrapperIndex + index + 'ywp_' + indexFabYarn + 'dyeing-ref' + indexDyieng + 'yarn';
		}else if(type === 'fdyeing'){
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].coatingFinishing.forEach(c => c.showWetCoating = false);
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].preTreatment.forEach(p => p.showWetPre  = false);
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].printTreatment.forEach(p => p.showWetPrint  = false);
			this.componentId = 'wp_f' + wrapperIndex + index + 'ywp_' + indexFabYarn + 'dyeing-ref' + indexDyieng + 'fabric';
		}else if(type === 'ypre'){
			this.wetProcessFootprint[index].yarnWetprocess[indexFabYarn].dyiengTreatment.forEach(d => d.showWetDyeing = false);
			this.wetProcessFootprint[index].yarnWetprocess[indexFabYarn].coatingFinishing.forEach(c => c.showWetCoating = false);
			this.componentId = 'wp_' + wrapperIndex + index + 'ywp_' + indexFabYarn + 'pre-ref' + indexDyieng + 'yarn';
		}else if(type === 'fpre'){
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].coatingFinishing.forEach(c => c.showWetCoating = false);
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].dyiengTreatment.forEach(d => d.showWetDyeing = false);
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].printTreatment.forEach(p => p.showWetPrint  = false);
			this.componentId = 'wp_f' + wrapperIndex + index + 'ywp_' + indexFabYarn + 'pre-ref' + indexDyieng + 'fabric';
		}else if(type === 'print'){
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].coatingFinishing.forEach(c => c.showWetCoating = false);
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].dyiengTreatment.forEach(d => d.showWetDyeing = false);
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].preTreatment.forEach(p => p.showWetPre  = false);
			this.componentId = 'wp_f' + wrapperIndex + index + 'ywp_' + indexFabYarn + 'print-ref' + indexDyieng + 'fabric';
		}else if(type === 'ycoating'){
			this.wetProcessFootprint[index].yarnWetprocess[indexFabYarn].dyiengTreatment.forEach(d => d.showWetDyeing = false);
			this.wetProcessFootprint[index].yarnWetprocess[indexFabYarn].preTreatment.forEach(p => p.showWetPre  = false);
			this.componentId = 'wp_' + wrapperIndex + index + 'ywp_' + indexFabYarn + 'coating-ref' + indexDyieng + 'yarn';
		}else{
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].printTreatment.forEach(p => p.showWetPrint  = false);
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].dyiengTreatment.forEach(d => d.showWetDyeing = false);
			this.wetProcessFootprint[index].fabricWetprocess[indexFabYarn].preTreatment.forEach(p => p.showWetPre  = false);
			this.componentId = 'wp_f' + wrapperIndex + index + 'ywp_' + indexFabYarn + 'coating-ref' + indexDyieng + 'fabric';
		}
		this.$nextTick(() => {
			const element = document.getElementById(this.componentId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		});
	}

	private isWetProcessFootprintStep(stepId:number): boolean{
		if (stepId === 6 || stepId === 7 || stepId === 8 || stepId === 31) {
			return true;
		}
		return false;
	}

	private dynamicTreatment(step:OrderLineStepDisplayModel): void{
			this.wetProcessFootprint.push(new WetProcessFootprintModel());
			let index = this.wetProcessFootprint.length;
			this.wetProcessFootprint[index].ID = uuidv4();
			this.wetProcessFootprint[index].filledByCompanyID = this.user.companyId
			this.wetProcessFootprint[index].filledByPrimarySource = this.user.companyId === this.wetProcessFootprint[index].toCompanyUser.toCompanyID ? true : false;
			this.wetProcessFootprint[index].yarnWetprocess.push(new YarnWetProcessModel());
			this.wetProcessFootprint[index].fabricWetprocess.push(new FabricWetprocessModel());
			this.wetProcessFootprint[index].yarnWetprocess.forEach((yw) => {
				yw.dyiengTreatment.push(new DyingTreatmentModel());
			});
			this.wetProcessFootprint[index].fabricWetprocess.forEach((fw) => {
				fw.dyiengTreatment.push(new DyingTreatmentModel());
			});
			this.wetProcessFootprint[index].yarnWetprocess.forEach((fw)=>{
			fw.ID = uuidv4();
			fw.dyiengTreatment.forEach((de) => {
				de.thermalEnergyNew.forEach((te) => {
					te.isValid = true;
				})
			})
		});
		if(index === 0){
			this.wetProcessFootprint[index].showWetProcess = true;
		}
		this.wetProcessFootprint[index].stepID = step.id;
		this.wetProcessFootprint[index].referenceNo = step.footprintRefNo;
		this.wetProcessFootprint[index].supplierName = step.toCompanyName;
		this.wetProcessFootprint[index].footprintRefNoPrefix = step.footprintRefNoPrefix === undefined ? '' : step.footprintRefNoPrefix;
		this.wetProcessFootprint[index].yarnWetprocess[0].stepID = step.id;
	}

	private toggleTransportWrapper(index: number): void {
		const clonedWetTransport = lodash.cloneDeep(this.wetTransport);
		clonedWetTransport[index].showTransport = !this.wetTransport[index].showTransport;
		this.wetTransport.splice(index, 1);
		this.wetTransport.splice(index, 0, clonedWetTransport[index]);
	}

	private getWetCardStatus(stepId: string): string {
		const wetProcess = this.wetProcessFootprint.filter(fb => fb.stepID === stepId)
		const completed = wetProcess.every(fb => fb.status === FootPrintCompletedConstant.COMPLETED);
		const inComplete = wetProcess.some(fb => fb.status === '');
		if(completed){
			return 'green'
		}else if(inComplete){
			return 'red'
		}
		return 'orange'
	}

	private getWetPrefixRefNumber(stepID: string): string {
		let refNum = '';
		const wrapper = this.wetProcessWrapperData.filter(wp => wp.stepID === stepID)[0];
		if(wrapper){
			refNum = wrapper.prefixReferenceNo;
		}
		return refNum;
	}

	private prefixRefNumber(stepId: string): string {
		let refNum = '';
		const wetProcess = this.wetProcessFootprint.filter(f => f.stepID === stepId)[0];
		if(wetProcess.footprintRefNoPrefix!==''){
			refNum = wetProcess.footprintRefNoPrefix === undefined ? '' : wetProcess.footprintRefNoPrefix;
		}
		return refNum;
	}

	private removeModeOfTransport(id: string, modeIndex: number){
		const fabricTransport = this.wetTransport.filter((ft) => ft.ID === id)[0];
		fabricTransport.modeOfTransport.splice(modeIndex,1)
	}

	private filterParallelChain(steps: StepReferenceModel[]): void{
		const uniqueSupplier = lodash.uniqBy(steps, 'toCompanyId');
		uniqueSupplier.forEach(sup => {
			const supplierFilteredSteps = steps.filter(s => s.toCompanyId === sup.toCompanyId);
			supplierFilteredSteps.sort((a, b)=> a.pcSequence > b.pcSequence ? -1 : 1);
			let stepID: string[] = [];
			let prefixRefNo = '';
			for(let i=0;i<supplierFilteredSteps.length;i++){
				if(supplierFilteredSteps[i+1] !== undefined && supplierFilteredSteps[i].pcSequence - 1 === supplierFilteredSteps[i+1].pcSequence){
					stepID.push(supplierFilteredSteps[i].pcStepUniqueId);
					if(supplierFilteredSteps[i].footprintRefNoPrefix !== undefined && supplierFilteredSteps[i].footprintRefNoPrefix !== ''){
						prefixRefNo = prefixRefNo + supplierFilteredSteps[i].footprintRefNoPrefix + ', ';
					}
				} else {
					stepID.push(supplierFilteredSteps[i].pcStepUniqueId);
					if(supplierFilteredSteps[i].footprintRefNoPrefix !== undefined && supplierFilteredSteps[i].footprintRefNoPrefix !== ''){
						prefixRefNo = prefixRefNo + supplierFilteredSteps[i].footprintRefNoPrefix + ', ';
					}
					const groupCard: WetProcessStepsDataModel = {
						stepID: stepID.join(','),
						prefixReferenceNo: prefixRefNo !== ''? prefixRefNo.substring(0, prefixRefNo.length - 2) : '',
						toCompanyID: supplierFilteredSteps[i].toCompanyId,
						toCompanyName: supplierFilteredSteps[i].toCompanyName,
						showWetProcess: false,
						yarnRefList: [],
						fabricRefList: []
					}
					this.wetProcessWrapperData.push(groupCard);
					stepID = [];
					prefixRefNo = '';
				}
			}
		})
	}

	private wetProcessFpStatus(stepID: string): string {
		const wp = this.wetProcessFootprint.filter(wp => wp.stepID === stepID || stepID.includes(wp.stepID))[0];
		if(wp){
			wp.status = FootPrintCompletedConstant.INCOMPLETE;
			let yarnAllFieldsFilled = false;
			let fabricAllFieldsFilled = false;
			if(wp.yarnWetprocess.length > 0){
				for (const yarnProcess of wp.yarnWetprocess) {
					if(yarnProcess.dyiengTreatment.length === 0 && yarnProcess.preTreatment.length === 0 && yarnProcess.coatingFinishing.length === 0){
						if(yarnProcess.reference.referenceNo !== ''){
							yarnAllFieldsFilled = true;
						}else{
							yarnAllFieldsFilled = false;
						}
					}
					if(yarnProcess.dyiengTreatment.length > 0){
						for(const dyeing of yarnProcess.dyiengTreatment){
							if(yarnProcess.reference.referenceNo !== '' && dyeing.reference.referenceNo !== '' && dyeing.dyeType.type !== '' && dyeing.shade.type !== '' && dyeing.process.type !== '' &&
								dyeing.waterUsedInFabric.amount !== '' && dyeing.energyUsed.value !== '' && dyeing.windEnergy.value !== '' && dyeing.solarEnergy.value !== ''){
									yarnAllFieldsFilled = true;
							}else{
								yarnAllFieldsFilled = false;
							}
							dyeing.thermalEnergyNew.forEach((te, index) => {
								if(te.value==='' || dyeing.thermalEnergySourceNew[index].value===''){
									yarnAllFieldsFilled = false;
								}
							});
						}
					}
					if(yarnProcess.preTreatment.length > 0){
						for(const pre of yarnProcess.preTreatment){
							if(yarnProcess.reference.referenceNo !== '' && pre.reference.referenceNo !== '' && pre.process.type !== '' &&
								pre.waterUsedInFabric.amount !== '' && pre.energyUsed.value !== '' && pre.windEnergy.value !== '' && pre.solarEnergy.value !== '' &&
								(yarnProcess.dyiengTreatment.length === 0 || (yarnProcess.dyiengTreatment.length > 0 && yarnAllFieldsFilled))){
									yarnAllFieldsFilled = true;
								}else{
									yarnAllFieldsFilled = false;
								}
								pre.thermalEnergyNew.forEach((te, index) => {
									if(te.value==='' || pre.thermalEnergySourceNew[index].value===''){
										yarnAllFieldsFilled = false;
									}
								});
						}
					}
					if(yarnProcess.coatingFinishing.length > 0){
						for(const coating of yarnProcess.coatingFinishing){
							if(yarnProcess.reference.referenceNo !== '' && coating.reference.referenceNo !== '' && coating.waterUsedInFabric.amount !== ''
								&& coating.energyUsed.value !== '' && coating.windEnergy.value !== '' && coating.solarEnergy.value !== '' && (yarnProcess.dyiengTreatment.length === 0 ||
									(yarnProcess.dyiengTreatment.length > 0 && yarnAllFieldsFilled)) && (yarnProcess.preTreatment.length === 0 || (yarnProcess.preTreatment.length > 0 && yarnAllFieldsFilled))){
									yarnAllFieldsFilled = true;
								}else{
									yarnAllFieldsFilled = false;
								}
								coating.thermalEnergyNew.forEach((te, index) => {
									if(te.value==='' || coating.thermalEnergySourceNew[index].value===''){
										yarnAllFieldsFilled = false;
									}
								});
						}
					}
				}
			}
			if(wp.fabricWetprocess.length > 0){
				for (const fabricProcess of wp.fabricWetprocess) {
					if(fabricProcess.dyiengTreatment.length === 0 && fabricProcess.preTreatment.length === 0 && fabricProcess.coatingFinishing.length === 0 && fabricProcess.printTreatment.length === 0 && (wp.yarnWetprocess.length === 0 || (wp.yarnWetprocess.length > 0 && yarnAllFieldsFilled))){
						if(fabricProcess.reference.referenceNo !== '' && (wp.yarnWetprocess.length === 0 || ((wp.yarnWetprocess.length > 0 && yarnAllFieldsFilled)))){
							fabricAllFieldsFilled = true;
						}else{
							fabricAllFieldsFilled = false;
						}
					}
					if(fabricProcess.dyiengTreatment.length > 0){
						for(const dyeing of fabricProcess.dyiengTreatment){
							if(fabricProcess.reference.referenceNo !== ''  && dyeing.shade.type !== '' && dyeing.reference.referenceNo !== '' && dyeing.process.type !== '' &&
								dyeing.waterUsedInFabric.amount !== '' && dyeing.energyUsed.value !== '' && dyeing.windEnergy.value !== '' && dyeing.solarEnergy.value !== '' && (wp.yarnWetprocess.length === 0 || (wp.yarnWetprocess.length > 0 && yarnAllFieldsFilled))){
									fabricAllFieldsFilled = true;
							}else{
								fabricAllFieldsFilled = false;
							}
							dyeing.thermalEnergyNew.forEach((te, index) => {
								if(te.value==='' || dyeing.thermalEnergySourceNew[index].value==='' && (wp.yarnWetprocess.length === 0 || (wp.yarnWetprocess.length > 0 && yarnAllFieldsFilled))){
									fabricAllFieldsFilled = false;
								}
							});
						}
					}
					if(fabricProcess.preTreatment.length > 0){
						for(const pre of fabricProcess.preTreatment){
							if(fabricProcess.reference.referenceNo !== '' && pre.reference.referenceNo !== '' && pre.process.type !== '' &&
								pre.waterUsedInFabric.amount !== '' && pre.energyUsed.value !== '' && pre.windEnergy.value !== '' && pre.solarEnergy.value !== '' && (wp.yarnWetprocess.length === 0 || (wp.yarnWetprocess.length > 0 && yarnAllFieldsFilled))){
									fabricAllFieldsFilled = true;
							}else{
								fabricAllFieldsFilled = false;
							}
							pre.thermalEnergyNew.forEach((te, index) => {
								if(te.value==='' || pre.thermalEnergySourceNew[index].value==='' && (wp.yarnWetprocess.length === 0 || (wp.yarnWetprocess.length > 0 && yarnAllFieldsFilled))){
									fabricAllFieldsFilled = false;
								}
							});
						}
					}
					if(fabricProcess.printTreatment.length > 0){
						for(const print of fabricProcess.printTreatment){
							if(fabricProcess.reference.referenceNo !== '' && print.reference.referenceNo !== '' && print.shade.type !== '' && print.process.type !== '' &&
								print.waterUsedInFabric.amount !== '' && print.energyUsed.value !== '' && print.windEnergy.value !== '' && print.solarEnergy.value !== '' && (wp.yarnWetprocess.length === 0 || (wp.yarnWetprocess.length > 0 && yarnAllFieldsFilled))){
									fabricAllFieldsFilled = true;
							}else{
								fabricAllFieldsFilled = false;
							}
							print.thermalEnergyNew.forEach((te, index) => {
								if(te.value==='' || print.thermalEnergySourceNew[index].value==='' && (wp.yarnWetprocess.length === 0 || (wp.yarnWetprocess.length > 0 && yarnAllFieldsFilled))){
									fabricAllFieldsFilled = false;
								}
							});
						}
					}
					if(fabricProcess.coatingFinishing.length > 0){
						for(const coating of fabricProcess.coatingFinishing){
							if(fabricProcess.reference.referenceNo !== '' && coating.reference.referenceNo !== '' && 
								coating.waterUsedInFabric.amount !== '' && coating.energyUsed.value !== '' && coating.windEnergy.value !== '' && coating.solarEnergy.value !== '' && (wp.yarnWetprocess.length === 0 || (wp.yarnWetprocess.length > 0 && yarnAllFieldsFilled))){
									fabricAllFieldsFilled = true;
							}else{
								fabricAllFieldsFilled = false;
							}
							coating.thermalEnergyNew.forEach((te, index) => {
								if(te.value==='' || coating.thermalEnergySourceNew[index].value==='' && (wp.yarnWetprocess.length === 0 || (wp.yarnWetprocess.length > 0 && yarnAllFieldsFilled))){
									fabricAllFieldsFilled = false;
								}
							});
						}
					}
				}
			}
			if(wp.yarnWetprocess.length === 0 && wp.fabricWetprocess.length > 0){
				yarnAllFieldsFilled = true
			}
			if(wp.fabricWetprocess.length === 0 && wp.yarnWetprocess.length > 0){
				fabricAllFieldsFilled = true
			}
			if (yarnAllFieldsFilled && fabricAllFieldsFilled) {
				wp.status = FootPrintCompletedConstant.COMPLETED;
				return "green";
			} else {
			let anyFieldFilled = false;
			if(wp.yarnWetprocess.length > 0){
				for (const yarnProcess of wp.yarnWetprocess) {
					if(yarnProcess.dyiengTreatment.length === 0 && yarnProcess.preTreatment.length === 0 && yarnProcess.coatingFinishing.length === 0){
						if(yarnProcess.reference.referenceNo !== ''){
							anyFieldFilled = true;
						}
					}
					if(yarnProcess.dyiengTreatment.length > 0){
						for(const dyeing of yarnProcess.dyiengTreatment){
							if(yarnProcess.reference.referenceNo !== '' || dyeing.reference.referenceNo !== '' || dyeing.dyeType.type !== '' || dyeing.shade.type !== '' || dyeing.process.type !== '' ||
								dyeing.waterUsedInFabric.amount !== '' || dyeing.energyUsed.value !== '' || dyeing.windEnergy.value !== '' || dyeing.solarEnergy.value !== ''){
									anyFieldFilled = true;
							}
						}
					}
					if(yarnProcess.preTreatment.length > 0){
						for(const pre of yarnProcess.preTreatment){
							if(yarnProcess.reference.referenceNo !== '' || pre.reference.referenceNo !== '' || pre.process.type !== '' &&
								pre.waterUsedInFabric.amount !== '' || pre.energyUsed.value !== '' || pre.windEnergy.value !== '' || pre.solarEnergy.value !== ''){
									anyFieldFilled = true;
								}
						}
					}
					if(yarnProcess.coatingFinishing.length > 0){
						for(const coating of yarnProcess.coatingFinishing){
							if(yarnProcess.reference.referenceNo !== '' || coating.reference.referenceNo !== '' || coating.waterUsedInFabric.amount !== ''
								||coating.energyUsed.value !== '' || coating.windEnergy.value !== '' || coating.solarEnergy.value !== ''){
									anyFieldFilled = true;
								}
							}
					}
				}
			}
			if(wp.fabricWetprocess.length > 0){
				for (const fabricProcess of wp.fabricWetprocess) {
					if(fabricProcess.dyiengTreatment.length === 0 && fabricProcess.preTreatment.length === 0 && fabricProcess.coatingFinishing.length === 0 && fabricProcess.printTreatment.length === 0){
						if(fabricProcess.reference.referenceNo !== ''){
							anyFieldFilled = true;
						}
					}
					if(fabricProcess.dyiengTreatment.length > 0){
						for(const dyeing of fabricProcess.dyiengTreatment){
							if(fabricProcess.reference.referenceNo !== ''  || dyeing.shade.type !== '' || dyeing.reference.referenceNo !== '' || dyeing.process.type !== '' &&
								dyeing.waterUsedInFabric.amount !== '' || dyeing.energyUsed.value !== '' || dyeing.windEnergy.value !== '' || dyeing.solarEnergy.value !== ''){
									anyFieldFilled = true;
							}
						}
					}
					if(fabricProcess.preTreatment.length > 0){
						for(const pre of fabricProcess.preTreatment){
							if(fabricProcess.reference.referenceNo !== '' || pre.reference.referenceNo !== '' || pre.process.type !== '' &&
								pre.waterUsedInFabric.amount !== '' || pre.energyUsed.value !== '' || pre.windEnergy.value !== '' && pre.solarEnergy.value !== ''){
									anyFieldFilled = true;
							}
						}
					}
					if(fabricProcess.printTreatment.length > 0){
						for(const dyeing of fabricProcess.printTreatment){
							if(fabricProcess.reference.referenceNo !== '' || dyeing.reference.referenceNo !== '' || dyeing.shade.type !== '' || dyeing.process.type !== '' ||
								dyeing.waterUsedInFabric.amount !== '' || dyeing.energyUsed.value !== '' || dyeing.windEnergy.value !== '' || dyeing.solarEnergy.value !== ''){
									anyFieldFilled = true;
							}
						}
					}
					if(fabricProcess.coatingFinishing.length > 0){
						for(const coating of fabricProcess.coatingFinishing){
							if(fabricProcess.reference.referenceNo !== '' || coating.reference.referenceNo !== '' || 
								coating.waterUsedInFabric.amount !== '' || coating.energyUsed.value !== '' || coating.windEnergy.value !== '' || coating.solarEnergy.value !== ''){
									anyFieldFilled = true;
							}
						}
					}
				}
			}
			if(anyFieldFilled){
				wp.status = FootPrintCompletedConstant.PARTIAL;
			}else{
				wp.status = FootPrintCompletedConstant.INCOMPLETE;
			}
			return anyFieldFilled ? "orange" : "red";
			}
		}
		return "red";
	}

	private wpTransportStatus(stepID: string): string {
		let status = 'red';
		let count = 0;
		let filledCount = 0;
		const relativeTrans = this.wetTransport.filter(y => y.stepID === stepID);
		relativeTrans.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					count++;
				}
				if(mode.distance.value !== '' || mode.distanceUnit.unit !== '' || mode.transportType.value !== ''){
					filledCount++;
				}
			});
			const tpIndex = this.wetTransport.findIndex(y => y.stepID === stepID && y.ID === tp.ID);
			if(tpIndex !== -1){
				if (count>0 && filledCount>0) {
					this.wetTransport[tpIndex].status = FootPrintCompletedConstant.PARTIAL;
				} else if(count === 0) {
					this.wetTransport[tpIndex].status = FootPrintCompletedConstant.COMPLETED;
				} else {
					this.wetTransport[tpIndex].status = FootPrintCompletedConstant.INCOMPLETE;
				}
			}
		})
		if (count>0 && filledCount>0) {
			status = "orange";
		} else if(count === 0) {
			status = "green";
		}
		return status;
	}

	private filterArray(steps: StepReferenceModel[]): void {
		const uniqueSupplier = lodash.uniqBy(steps, 'toCompanyId');
		uniqueSupplier.forEach(sup => {
			const supplierFilteredSteps = steps.filter(s => s.toCompanyId === sup.toCompanyId);
			supplierFilteredSteps.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1);
			let stepID: string[] = [];
			let prefixRefNo = '';
			for(let i=0;i<supplierFilteredSteps.length;i++){
				if(supplierFilteredSteps[i+1] !== undefined && supplierFilteredSteps[i].mcSequence + 1 === supplierFilteredSteps[i+1].mcSequence){
					stepID.push(supplierFilteredSteps[i].mcStepUniqueId);
					if(supplierFilteredSteps[i].footprintRefNoPrefix !== undefined && supplierFilteredSteps[i].footprintRefNoPrefix !== ''){
						prefixRefNo = prefixRefNo + supplierFilteredSteps[i].footprintRefNoPrefix + ', ';
					}
				} else {
					stepID.push(supplierFilteredSteps[i].mcStepUniqueId);
					if(supplierFilteredSteps[i].footprintRefNoPrefix !== undefined && supplierFilteredSteps[i].footprintRefNoPrefix !== ''){
						prefixRefNo = prefixRefNo + supplierFilteredSteps[i].footprintRefNoPrefix + ', ';
					}
					const groupCard: WetProcessStepsDataModel = {
						stepID: stepID.join(','),
						prefixReferenceNo: prefixRefNo !== ''? prefixRefNo.substring(0, prefixRefNo.length - 2) : '',
						toCompanyID: supplierFilteredSteps[i].toCompanyId,
						toCompanyName: supplierFilteredSteps[i].toCompanyName,
						showWetProcess: false,
						yarnRefList: [],
						fabricRefList: []
					}
					this.wetProcessWrapperData.push(groupCard);
					stepID = [];
					prefixRefNo = '';
				}
			}
		})
	}

	private get selectedCard(): string {
		const wrapperIndex = this.wetProcessWrapperData.findIndex(w => w.stepID === this.activeWetStep || w.stepID.includes(this.activeWetStep));
		const wetIndex = this.wetProcessFootprint.findIndex(y => y.stepID === this.activeWetStep || y.stepID.includes(this.activeWetStep));
		if(wrapperIndex!== -1 && wetIndex!== -1){
			this.wetProcessWrapperData.forEach(w => w.showWetProcess = false);
			this.wetProcessFootprint.forEach(w => w.showWetProcess= false);
			this.wetProcessWrapperData[wrapperIndex].showWetProcess = true;
			this.wetProcessFootprint[wetIndex].showWetProcess = true;
		}
		return this.activeWetStep;
	}

	private toggleWetYarn(wpIndex:number, wetYarnIndex:number): void {
		const clonedWetYarn = lodash.cloneDeep(this.wetProcessFootprint[wpIndex].yarnWetprocess);
		if(clonedWetYarn[wetYarnIndex]!==undefined){
			clonedWetYarn[wetYarnIndex].showYarn = !clonedWetYarn[wetYarnIndex].showYarn;
			this.wetProcessFootprint[wpIndex].yarnWetprocess.splice(wetYarnIndex, 1);
			this.wetProcessFootprint[wpIndex].yarnWetprocess.splice(wetYarnIndex, 0, clonedWetYarn[wetYarnIndex]);
		}
	}

	@Emit()
	private navigateTab(index: number): void {}
	
	//TTD-4276 issue1
	@Emit()
	private saveChanges(): void {}

	@Watch('activeStep')
    private openCardForSelected(){
		this.activeWetStep = this.activeStep;
    }

}