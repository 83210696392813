import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { mapState } from 'vuex';

// Components
import DropDown from "@/components/general/drop-down/dropDown.vue";

// Helpers
import { ProductGroupHelper } from "@/helpers/productGroupHelper";

// Modals
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { TopSuppliersModal } from "@/models/topSuppliersModal";
import { Guid } from "@/models/guid";
import { DropdownModel } from "@/models/dropdownModel";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    components: {DropDown},
    computed: mapState(['companyNameList'])
})
export default class TopSupplyChainPartnerTile extends Vue {
    @Prop()
    private orders!: OrderLineDisplayModel[];

    @Prop()
    private isLoading!: boolean;

    private uniqueToCompanyId: string[] = [];

    private productGroupList: DropdownModel[] = [];

    private productGroupName: string = this.$t('pages.order_lines.all_productgroups').toString();

    private showProductGroup: boolean = false;

    private companyNameList!: CompanyNameModel[];

    private async created(): Promise<void> {
        this.productGroupList = ProductGroupHelper.getProductGroups().map(p => ({value: p.id, text: p.description.split(/(?=[A-Z])/).join(" ")}));
        this.productGroupList = this.productGroupList.map(p => ({value: p.value, text: p.text.charAt(0).toUpperCase() + p.text.slice(1).toLowerCase()}));
        this.productGroupList.push({value: 0, text: this.$t('pages.order_lines.all_productgroups').toString()});
        this.productGroupList.sort((item1, item2) => {
            const sortItem1: number = parseInt(item1.value.toString());
            const sortItem2: number = parseInt(item2.value.toString());
            return sortItem1 - sortItem2;
        });
    }

    private get topSuppliers(): TopSuppliersModal[] {
        const topSuppliers: TopSuppliersModal[] = [];
        this.uniqueToCompanyId = [... new Set(this.orders.map(o => o.toCompanyId))];
        this.uniqueToCompanyId = this.uniqueToCompanyId.filter(id => id !== Guid.Empty);
        this.uniqueToCompanyId.forEach(id => {
            const styleProcessed = this.orders.filter(o => o.toCompanyId === id).length;
            // const companyName = this.companyNameList.filter(c => c.ID === id).length > 0 ? this.companyNameList.filter(c => c.ID === id)[0].name : this.orders.find(o => o.toCompanyId === id)?.toCompanyName;
            const companyName = this.orders.find(o => o.toCompanyId === id)?.toCompanyName;
            topSuppliers.push(new TopSuppliersModal(styleProcessed, companyName));
        });
        topSuppliers.sort((item1, item2) =>{
            return item2.styleProcessed - item1.styleProcessed;
        });
        return topSuppliers.slice(0,9);
    }

    private openProductGroupDropdown(): void {
        this.showProductGroup = true;
    }

    private valueSelected(value: number): void {
        if(value === 0){
            this.productGroupName = this.$t('pages.order_lines.all_productgroups').toString();
        }
        else{
            const productName = ProductGroupHelper.getProductGroups().filter(p => p.id === value)[0].description.toString().split(/(?=[A-Z])/).join(" ");
            this.productGroupName=productName.charAt(0).toUpperCase() + productName.slice(1).toLowerCase();
        }
        this.selectedProductGroup(value);
        this.closeDropDown();
    }

    private closeDropDown(): void {
        this.showProductGroup = false;
    }

    @Emit()
    private selectedProductGroup(value: number){}
}
