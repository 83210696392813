import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { Drag, Drop, DropList } from "vue-easy-dnd";
import lodash from "lodash";

import i18n from '@/i18n';
import { TopBrandModel } from "@/models/topBrandModel";
import { TopBrandModelSteps } from "@/models/topBrandModelSteps";

@Component({
  components: { Drag, Drop, DropList }
})

export default class AddNextSteps extends Vue {

  @Prop()
  private brandIndex!: number;

  @Prop()
  private propTopBrand!: TopBrandModel;

  private isShowEmptyFields: boolean = false;

  private isErrorNextStepDesc: boolean = false;
  private isErrorNextStepLabel: boolean = false;
  private isErrorNextStepSubtitle: boolean = false;
  private isErrorNextStepIntroText: boolean = false;

  private isErrorNextStepLinkText: boolean = false;
  private isErrorNextStepLinkKeyword: boolean = false;

  private stepIndex: number = -1;

  private topBrand: TopBrandModel = new TopBrandModel("", "", false, null, "", null, "", "", "", [], "");
  private topBrandNextStep: TopBrandModelSteps = new TopBrandModelSteps("", "", "", "", 0, false);

  public constructor() {
    super();
  }

  public async created(): Promise<void> {
    if (this.propTopBrand) {
      this.topBrand = lodash.cloneDeep(this.propTopBrand);
      if (this.topBrand.steps.length === 0) {
        this.isShowEmptyFields = true;
      }
    }
  }

  private delNextStep(index: number) {
    if (index !== -1 && this.topBrand.steps) {
      this.topBrand.steps.splice(index, 1);
    }
  }

  private showNextStep(index: number, topBrandNextStep: TopBrandModelSteps) {
    this.stepIndex = index;
    this.topBrandNextStep = topBrandNextStep;
    this.isErrorNextStepDesc = false;
    this.isErrorNextStepLabel = false;

    this.isErrorNextStepLinkText = false;
    this.isErrorNextStepLinkKeyword = false;
  }

  private hideNextStep() {
    this.stepIndex = -1;
    this.topBrandNextStep = new TopBrandModelSteps("", "", "", "", 0, false);
  }

  private addNextSteps(index: number, step: TopBrandModelSteps): boolean {
    this.isErrorNextStepDesc = false;
    this.isErrorNextStepLabel = false;
    this.isErrorNextStepLinkText = false;
    this.isErrorNextStepLinkKeyword = false;

    if (index < 0 && !this.isShowEmptyFields) {
      this.stepIndex = -1;
      this.isShowEmptyFields = true;
      return true;
    }

    this.isErrorNextStepDesc = false;
    this.isErrorNextStepLabel = false;

    if (step.stepLabel.trim().length <= 0) {
      this.isErrorNextStepLabel = true;
    }

    if (step.stepDesc.trim().length <= 0) {
      this.isErrorNextStepDesc = true;
    }

    if (step.stepLinkKeyword.trim().length > 0 && step.stepLinkText.trim().length <= 0) {
      this.isErrorNextStepLinkText = true;
    }

    if(step.stepLinkText.trim().length>0 && step.stepLinkKeyword.trim().length<=0) {
      this.isErrorNextStepLinkKeyword = true;
    }

    if (this.isErrorNextStepDesc || this.isErrorNextStepLabel || this.isErrorNextStepLinkText || this.isErrorNextStepLinkKeyword) {
      return false;
    }

    if (!this.topBrand.steps){
      this.topBrand.steps = [];
    }

    if(index>=0 && index<this.topBrand.steps.length) {
      this.topBrand.steps?.splice(index, 1, step);
    } else {
      step.stepDesc = step.stepDesc.trim();
      step.stepLabel = step.stepLabel.trim();
      step.stepLinkKeyword = step.stepLinkKeyword.trim();
      step.stepLinkText = step.stepLinkText.trim();
      this.topBrand.steps?.push(step);
    }
    this.topBrandNextStep = new TopBrandModelSteps("", "", "", "", 0, false);
    this.isShowEmptyFields = false;
    this.stepIndex = -1;

    this.updateSequence();
    return true;
  }

  private confirm(index: number, topBrand: TopBrandModel) {
    this.isErrorNextStepSubtitle = false;
    this.isErrorNextStepIntroText = false;

    if (topBrand.nextStepIntroText.trim().length <= 0) {
      this.isErrorNextStepIntroText = true;
    }

    if (topBrand.nextStepSubtitle.trim().length <= 0) {
      this.isErrorNextStepSubtitle = true;
    }

    if (this.isErrorNextStepSubtitle || this.isErrorNextStepIntroText) {
      return;
    }

    if (this.isShowEmptyFields){
      if(this.addNextSteps(this.stepIndex, this.topBrandNextStep)) {
        this.close(index, topBrand);
      }
    } else {
      this.close(index, topBrand);
    }
  }

  private reorder(event: any) {
    if (event) {
      event.apply(this.topBrand.steps);
      this.updateSequence();
    }
  }

  private updateSequence(): void {
    this.topBrand.steps.forEach((step, index) => {
      step.sequence = index+1;
    });
  }

  @Emit()
  private cancel() {}

  @Emit()
  private close(index: number, topBrand: TopBrandModel) {
  }

  @Watch("topBrand.steps", { deep: true })
  private topBrandStepsChanged(): void {
    this.updateSequence();
  }

  @Watch("propTopBrand", { deep: true })
  private propTopBrandChanged(): void {
    this.topBrand = lodash.cloneDeep(this.propTopBrand);
    this.isShowEmptyFields = (this.topBrand.steps.length === 0) ? false : true;
  }
}