import { Vue, Component, Prop, Emit } from "vue-property-decorator";

// Modals
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { EditOrderModel, EditOrderResponseModel } from "@/models/bulkOrderModel";
import { DropdownModel } from "@/models/dropdownModel";

// Services
import { OrderService } from '@/services/orderService';

@Component({})

export default class EditOrder extends Vue {
    @Prop()
    private orders!: OrderLineDisplayModel[];

    private orderService: OrderService;

    private checkedAll: boolean = true;

    private editSelectedOrders: EditOrderModel[] = [];

    private category: string = '';

    private selectedCategoryValue: string = '';

    private categoryError: boolean = false;

    private categoryValueError: boolean = false;

    private isLoading: boolean = false;

    public constructor() {
        super();
        this.orderService = new OrderService();
    }

    private async created(): Promise<void> {
        try {
            this.orders.forEach(o => {
                const order: EditOrderModel = new EditOrderModel();
                order.orderID = o.orderId;
                order.orderNumber = o.orderNumber;
                order.styleNumber = o.styleNumber;
                order.styleName = o.styleName;
                order.colourway = o.orderLines[0].colourway;
                order.season = o.orderLines[0].season;
                order.etd = o.orderLines[0].etd;
                order.compositionMainFabric = o.orderLines[0].compositionMainFabric;
                order.checked = true;
                this.editSelectedOrders.push(order);
            });
        }
        finally {
            // commented
        }
    }

    private get items(): DropdownModel[] {
        const options: DropdownModel[] = [{ value: "STYLENAME", text: "Style name" }, { value: "COLOUR", text: "Colour" }, { value: "SEASON", text: "Season" }, { value: "COMPOSITION", text: "Composition" }];
        return options;
    }

    private get getCount(): number {
        return this.editSelectedOrders.filter(f => f.checked===true).length;
    }

    private get isOrderSelected(): boolean {
        return this.editSelectedOrders.findIndex(o => o.checked === true) === -1;
    }

    private checkAllOrders(): void {
        if(this.checkedAll){
            this.editSelectedOrders.forEach(o => {
                o.checked = false;
            });
            this.checkedAll = false;
        }
        else{
            this.editSelectedOrders.forEach(o => {
                o.checked = true;
            });
            this.checkedAll = true;
        }
    }

    private checkOrder(index: number): void {
        this.editSelectedOrders[index].checked = !this.editSelectedOrders[index].checked;
        if(this.editSelectedOrders.findIndex(o => o.checked === false) !== -1){
            this.checkedAll = false;
        } else {
            this.checkedAll = true;
        }
    }

    private validateFields(field: string): void {
        switch (field){
            case 'category':
                if (this.category===null || this.category==="") {
                    this.categoryError = true;
                } else {
                    this.categoryError = false;
                }
            break;

            case 'selectedCategoryValue':
                this.selectedCategoryValue = this.selectedCategoryValue.trim();
                if (this.selectedCategoryValue===null || this.selectedCategoryValue==="") {
                    this.categoryValueError = true;
                } else {
                    this.categoryValueError = false;
                }
            break;
        }
    }

    private replaceAll(): void {
        if(this.category === ''){
            this.categoryError = true;
            return;
        }
        if(this.selectedCategoryValue === ''){
            this.categoryValueError = true;
            return;
        }
        switch (this.category){
            case 'STYLENAME': this.editSelectedOrders.filter(o => o.checked === true).forEach(o => {
                                o.styleName = this.selectedCategoryValue;
                            });
                            break;

            case 'COLOUR': this.editSelectedOrders.filter(o => o.checked === true).forEach(o => {
                                o.colourway = this.selectedCategoryValue;
                            });
                            break;

            case 'SEASON': this.editSelectedOrders.filter(o => o.checked === true).forEach(o => {
                                o.season = this.selectedCategoryValue;
                            });
                            break;

            case 'COMPOSITION': this.editSelectedOrders.filter(o => o.checked === true).forEach(o => {
                                o.compositionMainFabric = this.selectedCategoryValue;
                            });
                            break;
        }
    }

    private async updateOrders(): Promise<void> {
        this.isLoading = true;
        const finalEditedOrders = this.editSelectedOrders.filter(o => o.checked === true);
        const result = await this.orderService.updateOrdersAsync(this.orders[0].fromCompanyUser.ID, finalEditedOrders);
        if(result.success){
            this.closeEditOrderModal(true);
        }
        this.isLoading = false;
    }

    @Emit()
    private closeEditOrderModal(edited: boolean): void {
    }

}
