import { ProductGroupClient } from '@/clients/productGroupClient';
import { ProductGroupModel } from '@/models/productGroupModel';
import { ClientStepModel } from '@/models/clientStepModel';

export class ProductGroupService {
    private readonly client: ProductGroupClient = new ProductGroupClient();

    public async getProductGroupsAsync(): Promise<ProductGroupModel[]> {
        const result = await this.client.getProductGroupsAsync();
        return result;
    }

    public async getClientStepsForProductGroupAsync(productGroupId: number): Promise<ClientStepModel[]> {
        const result = await this.client.getClientStepsForProductGroupAsync(productGroupId);
        return result;
    }
}
