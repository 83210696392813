import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import Multiselect from 'vue-multiselect';
import ClickOutside from 'vue-click-outside';
import i18n from "@/i18n";

// Models
import { DropdownModel } from '@/models/dropdownModel';

@Component({
    components: { Multiselect },
    directives: {
        ClickOutside
    }
})
export default class DropDownInput extends Vue {
    @Prop()
    private items!: DropdownModel[];

    @Prop()
    private searchPlaceholder!: string;

    @Prop()
    private fieldName!: string;

    @Prop()
    private agent!: boolean;

    // Sprint 23 - TTD-3647
    @Prop({default: false})
    private isCp!: boolean;

    private selectedValue!: DropdownModel[];

    private isClickOutsideActive: boolean = true;

    private searchText: string = '';

    private get filteredItems(): DropdownModel[] {
        return this.items.filter(f => f.text.toLowerCase().match(this.searchText.toLowerCase()));
    }

    // Sprint 23 - TTD-3647, updated
    private created() {
        if(this.agent){
            this.valueSelected({value: 5, text: i18n.t('pages.invitation.agent_partner').toString()})
        }
        if (this.isCp) {
            this.valueSelected({value: 1, text: i18n.t('pages.invitation.tier1_partner').toString()})
        }
    }

    private valueSelected(value: DropdownModel): void {
        this.searchText = value.text;
        const optionsElement = document.getElementById('options') as HTMLElement;
        if(optionsElement){
            optionsElement.style.display = 'none';
        }
    }

    private inputClick(): void {
        this.searchText = "";
        const optionsElement = document.getElementById('options') as HTMLElement;
        optionsElement.style.display = 'block';
    }

    private clickedOutsideElement() {
        if(this.isClickOutsideActive){
            const optionsElement = document.getElementById('options') as HTMLElement;
            optionsElement.style.display = 'none';
        }
    }

    // private get isAgentSelected(): boolean {
    //     return this.agent;
    // }

    // private get isCpSelected(): boolean {
    //     return this.isCp;
    // }

@Watch("searchText", { deep: true })
    private selectedItem(): void {
        const item = this.items.filter(d => d.text== this.searchText)[0];
        if (item) {
            this.selected(item);
        } else {
            const item: DropdownModel = {text:"", value:""};
            item.text = this.searchText;
            item.value = this.searchText;
            this.selected(item);
        }
    }

    @Watch('agent')
    private checkAgent(): void {
        if (this.agent) {
            this.valueSelected({value: 5, text: i18n.t('pages.invitation.agent_partner').toString()});
        }
    }

    @Watch('isCp')
    private checkCp(): void {
        if (!this.agent && this.isCp) {
            this.valueSelected({value: 1, text: i18n.t('pages.invitation.tier1_partner').toString()});
        }
        
    }

@Emit()
private selected(item: DropdownModel){}

}

