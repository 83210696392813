export class FileObjectModel {

    public constructor () {
        this.fileType = "";
        this.geoLat = 0;
        this.geoLong = 0;
        this.hash = "";
        this.name = "";
        this.owner = "";
        this.source = "";
        this.url = "";
    }

    public fileType!: string;
    public geoLat!: number;
    public geoLong!: number;
    public hash!: string;
    public name!: string;
    public owner!: string;
    public source!: string;
    public url!: string;
}