
export class SustainableCertificatesModel {

    public constructor (ID: string, type: number, issuer: string, referenceIssuer: string, createdAt: string, validtoDate: string, otherCertName: string, certType: string){
        this.ID = ID;
        this.type = type;
        this.issuer = issuer;
        this.referenceIssuer = referenceIssuer;
        this.createdAt = createdAt;
        this.validtoDate = validtoDate;
        this.otherCertName = otherCertName;
        this.certType = certType;
    }
    public ID!: string;
    public description!: string;
    public companyID!: string;
    public companyName!: string;
    public docURL!: string;
    public fileName!: string;
    public fileType!: string;
    public geoLat!: string;
    public geoLong!: string;
    public issuer!: string;
    public referenceIssuer!: string;
    public type!: number;
    public oekoClass!: string;
    public otherCertName!: string;
    public validtoDate!: string;
    public validFromDate!: string;
    public createdAt!: string;
    public certType!: string;
}
