export class CreateClientModel {
  public CompanyName!: string;
  public CompanyType!: number;
  public CompanyRegistrationNumber!: string;
  public MainContactEmailAddress!: string;
  public City!: string;
  public State!: string;
  public Country!: string;
  public CompanyWebsite!: string;
  public MainContactFirstName!: string;
  public MainContactLastName!: string;
  public MainContactPhone!: string;
}