import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { mapState } from 'vuex';

// Componets
import SetMandatorySteps from "@/views/orderLines/components/setMandatorySteps.vue";

// Modals
import { DropdownModel } from "@/models/dropdownModel";

@Component({
    components: { SetMandatorySteps },
    computed: mapState(['userRole']),
})
export default class Settings extends Vue {
 @Prop()
 private searchFilter!: string;
}
