export class OrderCompanyModel {
    public id!: string;
    public companyName!: string;
    public country!: string;
    public isCompliant!: boolean;
    public isTier1!: boolean;
    public onBoarded!: boolean;
    public invitedPersonEmailID!: string;
    public isAgent!: boolean;
    public companyEmail!: string;
    public firstName!: string;
    public lastName!: string;
    public isDelegatePartner!: boolean;

    public constructor(id: string, companyName: string, country: string, isCompliant: boolean, onBoarded: boolean) {
        this.id = id;
        this.companyName = companyName;
        this.country = country;
        this.isCompliant = false;
        this.isTier1 = true;
        this.onBoarded = false;
        this.isAgent = false;
        this.invitedPersonEmailID = "";
        this.companyEmail = "";
        this.firstName = "";
        this.lastName =  "";
        this.isDelegatePartner = false;
    }
}