import { MiddlewareClient } from '@/clients/middlewareClient';
import { CreateClientModel } from '@/models/createClientModel';
import { CompanyAdminRequestModel, CompanyUserRequestModel, CompanyUserResponseModel } from '@/models/createCompanyUsersModel';
import { ResendInviteBRNRequestModel, ResendInviteRequestModel, ResendInviteResponseModel, SigninClientModel } from '@/models/signinClientModel';
import { CryptionHelper } from '@/helpers/cryptionHelper';

// models
import { ApprovePendingUserModel, RejectPendingUserModel, SecondUser } from '@/models/secondUser';
import { SecondUserRequest } from '@/models/secondUser';
import { AgentPilotPermissionReqModel, UserPermissionRequestModel } from '@/models/permissionModel';
import { UserModelResponse } from '@/models/userModel';
import { NotificationHelper } from '@/helpers/notificationHelper';

export class MiddlewareService {
  private readonly mwClient: MiddlewareClient = new MiddlewareClient();
  private readonly cryption: CryptionHelper = new CryptionHelper();

  public async sendVerificationCode(email: string, verificationcode: string): Promise<any> {
    const result = await this.mwClient.sendVerificationCode(email, verificationcode);
    return result;
  }

  public async resetsendVerificationCode(email: string, verificationcode: string): Promise<any> {
    const result = await this.mwClient.resetsendVerificationCode(email, verificationcode);
    return result;
  }

  public async updatePassword(email: string, newpassword: string): Promise<any> {
    newpassword = await this.cryption.encryptText(newpassword);
    const result = await this.mwClient.updatePassword(email, newpassword);
    return result;
  }

  public async createClient(client: CreateClientModel): Promise<any> {
    const result = await this.mwClient.createClient(client);
    return result;
  }

  public async signinClient(client: SigninClientModel): Promise<any> {
    client.Scope = process.env.VUE_APP_Api_scope!;
    client.Password = await this.cryption.encryptText(client.Password);
    const clientBase64 = btoa(JSON.stringify(client));
    localStorage.setItem("qrcode_client", clientBase64);
    const result = await this.mwClient.signinClient(client);
    return result;
  }

  public async signinConsumerApp(): Promise<any> {
    let client = new SigninClientModel();
    const qrcodeClient = localStorage.getItem("qrcode_client");
    if (qrcodeClient !== null && qrcodeClient !== "" && qrcodeClient !== undefined) {
      client = JSON.parse(atob(qrcodeClient));
    }
    client.Scope = process.env.VUE_APP_Consumer_Api_scope!;
    const result = await this.mwClient.signinClient(client);
    return result;
  }

  public async createCompanyAdmin(user: CompanyAdminRequestModel): Promise<any> {
    user.Password = await this.cryption.encryptText(user.Password);
    const result = await this.mwClient.createCompanyAdmin(user);
    return result;
  }

  public async getUserByEmailForInviteLink(email: string): Promise<any> {
    const result = await this.mwClient.getUserByEmailForInviteLink(email);
    return result;
  }

  public async createSecondUser(data: SecondUser, companyId: string): Promise<any> {
    const result = await this.mwClient.createSecondUser(data, companyId);
    return result;
  }

  public async blockUser(userId: string): Promise<any> {
    const result = await this.mwClient.blockUser(userId);
    return result;
  }

  public async unBlockUser(userId: string): Promise<any> {
    const result = await this.mwClient.unBlockUser(userId);
    return result;
  }

  public async editSecondUser(data: SecondUserRequest, companyId: string): Promise<any> {
    const result = await this.mwClient.editSecondUser(data, companyId);
    return result;
  }

  public async resendinvite(data: SecondUserRequest): Promise<any> {
    const result = await this.mwClient.resendinvite(data);
    return result;
  }

  public async setNewUserPassword(data: CompanyUserRequestModel): Promise<CompanyUserResponseModel> {
    const secondUserCred = {'email': data.Email, 'password': data.Password};
    data.Password = await this.cryption.encryptText(data.Password);
    data.TempPassword = await this.cryption.encryptText(data.TempPassword);
    const response = await this.mwClient.setNewUserPassword(data);
    if(response.result === 'success'){
      const secondUser = btoa(JSON.stringify(secondUserCred));
      sessionStorage.setItem('secondUser', secondUser);
    }
    return response;
  }

  public async updateUserPermission(data: UserPermissionRequestModel): Promise<UserModelResponse> {
    const result = await this.mwClient.updateUserPermission(data);
    return result;
  }

  public async resendInviteLink(data: ResendInviteRequestModel): Promise<ResendInviteResponseModel> {
    const result = await this.mwClient.resendInviteLink(data);
    return result;
  }

  public async resendInviteLinkWithBRN(data: ResendInviteBRNRequestModel): Promise<ResendInviteResponseModel> {
    const result = await this.mwClient.resendInviteLinkWithBRN(data);
    return result;
  }

  public async approvePendingUserAsync(companyId: string ,data: ApprovePendingUserModel): Promise<UserModelResponse> {
    const response = await this.mwClient.approvePendingUserAsync(companyId, data);
    if(response.result === 'success'){
      NotificationHelper.createSucceededNotification('New user successfully added to your company account');
    }
    else{
      NotificationHelper.createErrorNotification('Failed to add new user to your company account. Please try again!');
    }
    return response;
  }

  public async rejectPendingUserAsync(data: RejectPendingUserModel): Promise<UserModelResponse> {
    const response = await this.mwClient.rejectPendingUserAsync(data);
    if(response.result === 'success'){
      NotificationHelper.createSucceededNotification('Pending user rejected successfully.');
    }
    else{
      NotificationHelper.createErrorNotification('Failed to reject pending user. Please try again!');
    }
    return response;
  }

  // TTD-3323
  // Update Agent footprint pilot permission - service method
  public async updateAgentPilotPermission(data: AgentPilotPermissionReqModel): Promise<CompanyUserResponseModel> {
    const response = await this.mwClient.updateAgentPilotPermission(data);
    return response;
  }
}
