import { Vue, Component, Watch, Prop, Emit } from "vue-property-decorator";
import lodash from "lodash";
import moment from "moment";
import { mapState } from 'vuex';
import i18n from "@/i18n";

// Components
import PendingOrderLine from '@/views/home/components/pendingOrderLine.vue';

// Models
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { DropdownModel } from '@/models/dropdownModel';
import { OrderModel } from '@/models/orderModel';
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { SupplierCertificateModal } from "@/models/supplierCertificateModal";
import {
  CertificateModel,
  CertificateOnTypeModel,
  CertificateTypes,
} from "@/models/certificateModel";

// Services
import { OrderService } from "@/services/orderService";
import { SupplierService } from "@/services/supplierService";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { OrderLineModel } from "@/models/orderLineModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { ActionTypes } from "@/store/actions/actions";
import { TCConstant } from "@/models/tcTableModel";
import { Guid } from "@/models/guid";

@Component({
  computed: mapState(['companyType', 'suppliers', 'pendingFootprintCount'])
})
export default class PendingActionsTile extends Vue {

  @Prop()
  private order!: CombinedOrderLineModel;

  @Prop()
  private orders!: OrderLineDisplayModel[];

  @Prop()
  private orderDetails!: OrderCompanyModel;

  @Prop()
  private certificates!: SupplierCertificateModal[];

  @Prop()
  private isLoading!: boolean;

  private suppliersLoading: boolean = false;

  private today: Date = new Date();

  private isLoadingCertificate: boolean = false;

  private suppliers!: OrderCompanyModel[];

  private orderService: OrderService;

  private editOrder: CombinedOrderLineModel = new CombinedOrderLineModel();

  private supplierService: SupplierService;

  private supplierChanged: boolean = false;

  private companyType!: number;

  private pendingFootprintCount!: number;

  private membershipCerts: CertificateOnTypeModel[] = [];

  private susMemCerts: CertificateOnTypeModel[] = [];

  private socailMemCerts: CertificateOnTypeModel[] = [];

  public constructor() {
    super();
    this.orderService = new OrderService();
    this.supplierService = new SupplierService();
    this.editOrder = this.order;
  }

  private async created(): Promise<void> {
    try {
      this.suppliersLoading = true;
      // await this.fetchSuppliers();
    } finally {
      this.suppliersLoading = false;
    }

    const susMembership = i18n.t(
      `enums.sustainability_certificates.membership`
    ) as any;
    for (const key of Object.keys(susMembership)) {
      const certifcateName = susMembership[key];
      this.susMemCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SUSTAINABILITY
        )
      );
      this.membershipCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SUSTAINABILITY
        )
      );
    }
    const socialMembership = i18n.t(
      `enums.social_certificates.membership`
    ) as any;
    for (const key of Object.keys(socialMembership)) {
      const certifcateName = socialMembership[key];
      this.socailMemCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SUSTAINABILITY
        )
      );
      this.membershipCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SOCIAL
        )
      );
    }
  }

  private async fetchSuppliers(): Promise<void> {
    if (
      this.$store.getters.suppliers != null &&
      this.$store.getters.suppliers.length > 0 &&
      !this.supplierChanged
    ) {
      this.suppliers = this.$store.getters.suppliers;
    } else {
      // const suppliers = await this.supplierService.getSuppliersAsync();
      await this.$store.dispatch(ActionTypes.SET_SUPPLIER_CERTIFICATE);
      this.suppliers = this.$store.getters.suppliers;
    }
  }

  /**
   * This function will get the count of pending T1 supply chain partner invites
   * @returns {number}
   * @memberof PendingActionsTile
   */
  private get partnerInvite(): number {
    if (this.companyType === 5) {
      return this.suppliers.filter(s => s.onBoarded === false).length;
    }
    return this.suppliers.filter(s => s.isTier1 === true && s.onBoarded === false).length;
  }

  /**
   * This function will get the certificate expiring in next 7 days
   * @returns {SupplierCertificateModal[]}
   * @memberof PendingActionsTile
   */
  private get expiringCertificate(): SupplierCertificateModal[] {
    const expiringCertificates: SupplierCertificateModal[] =
      this.certificates.filter(
        (certificate: SupplierCertificateModal) => {
          const expiryDate: Date = moment(certificate.expiryDate).toDate();
          const today: Date = moment(this.today).toDate();
          const diff: number = moment(expiryDate).diff(today, "days");
          const isExpiring: boolean = diff <= 7;
          return isExpiring;
        }
      );
    return this.certificates.filter(c => c.certificateId!==0 && 
      this.susMemCerts.findIndex(m => m.ID === c.certificateId.toString() && c.certificateType === 'Sustainability')===-1 &&
      this.socailMemCerts.findIndex(m => m.ID === c.certificateId.toString() && c.certificateType === 'Social')===-1);
  }

  private get supplyCount(): number {
    if (this.companyType === 5) {
      return this.waitingCount + this.partnerInvite;
    }
    return this.waitingCount + this.partnerInvite + this.expiringCertificate.length;
  }

  private get waitingCount(): number {
    const waitingOrderLines = this.orders.filter((orderLine: any) => {
      return (
        orderLine?.orderLinePhase === 1 && orderLine?.orderLineStatus === 0
      );
    });
    return waitingOrderLines.length;
  }

  private get pendingCount(): number {
    const pendingOrderLines = this.orders.filter((orderLine: any) => {
      return (
        orderLine?.orderLinePhase === 0 && orderLine?.orderLineStatus === 0
      );
    });
    return pendingOrderLines.length;
  }

  // TTD-3772 Sprint19
  private get footprintCount(): number {
    const footprintOrders = this.pendingFootprintCount;
    return footprintOrders;
  }

  private get uploadedTCCount(): number{
    const filterTC1Orders = this.orders.filter(o => o.tc1Status === TCConstant.UPLOAD && o.tranCertificate1RefId !== "" && o.tranCertificate1RefId !== Guid.Empty);
    const filterTC2Orders = this.orders.filter(o => o.tc2Status === TCConstant.UPLOAD && o.tranCertificate2RefId !== "" && o.tranCertificate2RefId !== Guid.Empty);
    const tc1RefId = lodash.uniqBy(filterTC1Orders, "tranCertificate1RefId").map(o => o.tranCertificate1RefId);
    const tc2RefId = lodash.uniqBy(filterTC2Orders, "tranCertificate2RefId").map(o => o.tranCertificate2RefId);
    const uniqTcRefId: string[] = lodash.uniq([...tc1RefId, ...tc2RefId]);
    let tcCount = uniqTcRefId.length;
    return tcCount;
  }

  /**
   * This function will get unique brand names of the suppliers
   * @returns {string}
   * @memberof PendingActionsTile
   */
  private get uniqueBrands(): string {
    // spread all orderLines in one array
    const pendingOrders = this.orders.filter(o => o.orderLinePhase === 0 && o.orderLineStatus === 0);
    // get unique brand
    const uniqueBrands = lodash.uniqBy(pendingOrders, "brandName");
    //  remove duplicates names ignoring case
    const uniqueBrandsWithoutDuplicates = lodash.uniqBy(
      uniqueBrands,
      (brand: OrderLineDisplayModel) => {
        return brand.brandName.toLowerCase();
      }
    );
    const brandsString = uniqueBrandsWithoutDuplicates
      .map((brand) => brand.brandName)
      .join(", ");
    //  add ... if text is more than 20 characters long
    if (brandsString.length > 20) {
      return brandsString.substring(0, 20) + "...";
    }
    return brandsString;
  }

  private scrollToCertifications() {
    const certificates = document.getElementById('expiring-certificate');
    certificates!.scrollIntoView({behavior: "smooth", block: "center"});
  }

  @Emit()
  private ordersConfirmed(order: CombinedOrderLineModel): void {}

  @Emit()
  private confirmed(
    order: CombinedOrderLineModel,
    confirmOrderLineStep: OrderLineStepDisplayModel[],
    isBulk: boolean
  ): void {}

  @Watch("orders", { deep: true, immediate: true })
  private ordersChanged(): void {}

  @Watch("order", { deep: true })
  private orderChanged(): void {
    this.editOrder = this.order;
  }
}
