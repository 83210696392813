import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { mapState } from "vuex";

// Components
import SearchSupplier from '@/components/general/search-supplier/searchSupplier.vue';
import CreateSupplyChain from '@/views/home/components/createSupplyChain.vue';
import CreateBulkOrder from '@/views/home/components/bulkOrder.vue';

// Models
import { DropdownModel } from '@/models/dropdownModel';
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { Guid } from "@/models/guid";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { UserPermissionModel } from "@/models/permissionModel";

// Services
import { OrderService } from '@/services/orderService';
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    components: { SearchSupplier, CreateSupplyChain, CreateBulkOrder },
    computed: mapState(['userRole','userPermissions', 'companyNameList'])

})
export default class PendingOrderLine extends Vue {
    @Prop()
    private order!: CombinedOrderLineModel;

    @Prop()
    private suppliers!: OrderCompanyModel[];

    @Prop()
    private supplierDropdownData!: DropdownModel[];

    @Prop()
    private suppliersLoading!: boolean;

    private orderService: OrderService;

    private editOrder: CombinedOrderLineModel = new CombinedOrderLineModel();

    private showSearch: boolean = false;

    private isConfirming: boolean = false;

    private isDeleting: boolean = false;

    private showAddStepModal: boolean = false;

    private showBulkOrderModal: boolean = false;

    private userPermissions!: UserPermissionModel | null;

    // TTD-3961
    private companyNameList!: CompanyNameModel[];

    public constructor() {
        super();
        this.orderService = new OrderService();
        this.editOrder = this.order;
    }

    private get statusColor(): string {
        return this.supplierConnected ? "green" : "red";
    }

    private get supplierName(): string {
        if (this.editOrder.supplierName == null) {
            return this.$t("pages.home.unknown").toString();
        }
        return this.editOrder.supplierName;
    }

    private get userName(): string {
        if (this.editOrder.userName == null) {
            return this.$t("pages.home.unknown").toString();
        }
        return this.editOrder.userName;
    }

    private get supplierConnected(): boolean {
        const supplierConnected = this.suppliers.findIndex(s => s.id === this.editOrder.supplierId && s.isTier1 === true) !== -1 ? true : false;
        if(!supplierConnected){
            this.editOrder.supplierId = Guid.Empty;
        }
        return supplierConnected;
    }

    private get tier1Supplier(): DropdownModel[] {
        const tier1Supplier = this.suppliers.filter(t => t.isTier1 === true);
        return tier1Supplier.map(s => ({ value: s.id, text: s.isCompliant ? `${s.companyName} (${s.country})` : `${s.companyName} (${this.$t('pages.home.pending_invite')})`}));
    }

    private get deletePermission(): number {
        return this.userPermissions !== null ? this.userPermissions.deleteOrderLine : 0;
    }

    private get assignScpPermission(): number {
        return this.userPermissions !== null ? this.userPermissions.assignSCPOrderLine : 0;
    }

    private get confirmOrderline(): number {
        return this.userPermissions !== null ? this.userPermissions.confirmOrderLine : 0;
    }

    private closeSearchHandler(): void {
        this.showSearch = false;
    }

    private openSearchSelectHandler(): void {
        if (this.isDeleting) {
            return;
        }
        this.showSearch = true;
    }

    private setSupplierNameHandler(value: string): void {
        const supplier = this.suppliers.filter(s => s.id === value)[0];
        this.editOrder.supplierId = supplier.id;
        this.editOrder.supplierName = supplier.companyName;
        this.editOrder.supplierCountry = supplier.country;
        this.showSearch = false;
    }

    private invitationSendHandler(): void {
        this.suppliersChanged();
    }

    private remove(): void {
        if (this.isConfirming) {
            return;
        }
        const confirmationText = this.$t('pages.home.delete_confirmation').toString();
        this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false}).then(() => {
            this.isDeleting = true;
            this.deleteOrders(this.editOrder.orderIds);
        }).catch(onrejected => { });
    }

    private openAddStepModal(): void {
        if(this.editOrder.orderCount > 1){
            if(this.editOrder.supplierId !== undefined){
                this.editOrder.orderDetails.forEach(item => {
                    item.fromCompanyName = this.companyNameList.filter(c => c.ID === item.fromCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === item.fromCompanyId)[0].name : item.fromCompanyName;
                    item.toCompany.id = this.editOrder.supplierId;
                    item.toCompany.companyName = this.editOrder.supplierName;
                    item.toCompany.country = this.editOrder.supplierCountry;
                });
            }
            this.showBulkOrderModal = true;
        }
        else{
            this.editOrder.orderDetails[0].fromCompanyName = this.companyNameList.filter(c => c.ID === this.editOrder.orderDetails[0].fromCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === this.editOrder.orderDetails[0].fromCompanyId)[0].name : this.editOrder.orderDetails[0].fromCompanyName;
            this.showAddStepModal = true;
        }
    }

    private closeAddStepModal(created: boolean): void {
        this.showAddStepModal = false;
        this.showBulkOrderModal = false;
    }

    private async save(): Promise<void> {
        if (this.isDeleting) {
            return;
        }
        this.isConfirming = true;
        this.ordersConfirmed(this.editOrder);

    }

    @Emit()
    private reloadOrderLines(): void {
    }

    @Emit()
    private ordersConfirmed(order: CombinedOrderLineModel): void {
    }

    @Emit()
    private confirmed(order: CombinedOrderLineModel, confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean): void {
    }

    @Emit()
    private deleteOrders(orderIds: string[]): void {
    }

    @Emit()
    private suppliersChanged(): void {
    }

    @Watch("order", { deep: true })
    private orderChanged(): void {
        this.editOrder = this.order;
    }
}
