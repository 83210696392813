import ClickOutside from 'vue-click-outside';
import Multiselect from 'vue-multiselect';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import { DropdownModel } from '@/models/dropdownModel';
import lodash from 'lodash';
import _ from 'lodash';
import { MandatoryStepRules, MandatoryStepsRuleModel, MandatoryStepType } from "@/models/mandatoryStepsModel";

@Component({
    components: { Multiselect },
    directives: {
        ClickOutside
    }
})
export default class MultiSelectDropDown extends Vue {

    @Prop()
    private options!: DropdownModel[];

    @Prop()
    private disabled!: boolean;

    @Prop()
    private invalid!: boolean;

    @Prop()
    private allSelected!: boolean;

    @Prop()
    private placeholder!: string;

    @Prop()
    private selectedValues?: string[] | number[];

    @Prop()
    private isEdit!: boolean;

    @Prop()
    private isMandatorySteps!: boolean;

    @Prop()
    private setMandataoryStepsType!: string;

    @Prop()
    private mandatorySteps!: MandatoryStepRules;

    @Prop()
    private mandatoryStepsToEdit!: MandatoryStepsRuleModel;

    @Prop()
    private isAllSelected!: boolean;

    private selectedOptions: DropdownModel[] = [];

    private onlyValues: string[] = [];

    private selectAll: boolean = false;

    private created(): void {
        this.setSelectedOptions();
        if(this.allSelected && !this.isEdit) {
            const allSelectedValues = this.concatAll();
            this.onlyValues = allSelectedValues;
            this.optionsSelected(_.uniq(allSelectedValues));
        }
    }

    private isSelected(option: DropdownModel): void {
        if(option.disabled){
            return;
        }
        if (option.value=="" && option.text === this.options.filter(o => o.value === '')[0].text) {
            const allSelectedValues = this.concatAll();
            this.onlyValues = allSelectedValues;
            this.optionsSelected(_.uniq(allSelectedValues));
            this.selectAll = true;
        } else{
            this.selectAll = false;
            const allSelectedValues = this.selectedOptions.map((option) => option.value)
            .concat(option.value);
            this.optionsSelected(_.uniq(allSelectedValues));
            this.selectedOptions = lodash.uniqBy(this.selectedOptions, 'value');
            if(this.options.length === this.selectedOptions.length + 1){
                this.selectAll = true;
                const allSelectedValues = this.concatAll();
                this.onlyValues = allSelectedValues;
                this.optionsSelected(_.uniq(allSelectedValues));
            }
        }
        this.setSelected(option, true, this.selectAll);
    }

    private concatAll(): string[] {
        const allOptions: string[] = [];
        this.selectedOptions.splice(0, this.selectedOptions.length);
        this.options.forEach(opt => {
            if(!opt.disabled){
                allOptions.push(opt.value.toString());
                this.selectedOptions.push(opt);
            }
        });
        const values: string[] = [];
        values.push(...allOptions);
        values.splice(0, 1, "");
        return values;
    }

    private isRemoved(option: DropdownModel): void {
        if(option.disabled){
            return;
        }
        if (option.value=="" && option.text === this.options.filter(o => o.value === '')[0].text) {
            this.selectedOptions.splice(0, this.selectedOptions.length);
            this.optionsSelected([]);
            this.onlyValues = [];
            this.selectAll = false;
        } else {
            this.selectAll = false;
            if (this.onlyValues.indexOf('') !== -1) {
                this.onlyValues = this.onlyValues.filter((item) => item !== "" && item !== option.value);
                this.selectedOptions = this.selectedOptions.filter((item) => item.value !== "");
                const allSelectedValues = this.selectedOptions.map((item) => item.value)
                    .filter((item) => item !== option.value);
                this.optionsSelected(_.uniq(allSelectedValues));
                this.selectedOptions = this.selectedOptions.filter((item) => item.value !== option.value);
            } else {
                const allSelectedValues = this.selectedOptions.map((item) => item.value)
                    .filter((item) => item !== option.value);
                this.optionsSelected(_.uniq(allSelectedValues));
            }
        }
        this.setSelected(option, false, this.selectAll);
    }

    private get label(): string {
        if (this.selectedOptions.length === 0) {
            return this.placeholder;
        }
        else {
            this.selectedOptions = lodash.uniqBy(this.selectedOptions, 'value');
            if(this.selectAll){
                return this.options.filter(o => o.value === '')[0].text;
            }
            const filteredOptions = this.selectedOptions.filter(f=> f.value!=="" && f.disabled!== true);
            return  filteredOptions.map(s => s.text).join(', ');
        }
    }

    private isStepsAssigned(val: string, isDisable: boolean): boolean{
        if(isDisable){
            return true;
        }
        const assignedValues:string[] = [];
        if(this.setMandataoryStepsType === 'CONTRACTUAL PARTNER'){
            this.mandatorySteps.mandatoryStepRule.forEach(m => {
                m.list.forEach(l => {
                 assignedValues.push(l.ID);
                });
             });
             const index = assignedValues.findIndex(a => a === val);
             if(index!==-1 && this.selectedValues!==null && this.selectedValues?.findIndex(f => f === val)===-1 && !this.isExistInMandatorySteps(val)){
                this.setDisable(val);
                return true;
             }
        }else{
            this.mandatorySteps.mandatoryStepRule.forEach(m => {
                m.list.forEach(l => {
                 assignedValues.push(l.name.toLowerCase());
                });
             });
             const index = assignedValues.findIndex(a => a.toLowerCase() === val.toLowerCase() && val!=='' && !this.isExistInMandatorySteps(val));
             if(index!==-1 && this.selectedValues!==null && this.selectedValues?.findIndex(f => f === val)===-1){
                this.setDisable(val);
                return true;
             }
        }
        return false;
    }

    private setSelectedOptions(): void{
        if (this.selectedValues != null && this.selectedValues.length > 0) {
            this.selectedOptions = this.options.filter(option => this.selectedValues?.some((s: string | number) => s === option.value));
            if(this.selectedOptions.length === this.options.length -1){
                this.selectAll = true;
                this.setSelected(this.options.filter(o => o.value === '')[0], true, true);
            }
        }
    }

    private isExistInMandatorySteps(val:string): boolean{
        if(this.isEdit){
            if(this.mandatoryStepsToEdit.list.findIndex(l => l.name === val)!== -1 || this.mandatoryStepsToEdit.list.findIndex(l => l.ID === val)!== -1){
                return true;
            }
        }
        return false;
    }

    @Emit()
    private optionsSelected(option: (string | number)[]): void {
    }

    @Emit()
    private setDisable(val:string): void{}

    @Emit()
    private setSelected(option:DropdownModel, isSelected:boolean, isAllSelected:boolean): void{}

    @Watch('isAllSelected')
    private updateSelectedOptions(): void{
            this.selectAll = this.isAllSelected;
            this.setSelectedOptions();
    }
}
