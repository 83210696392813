import { Vue, Component, Watch } from "vue-property-decorator";
import i18n from "@/i18n";
import lodash from 'lodash';

// Components
import QrOrderLine from '@/views/qrCodes/components/qrOrderLine.vue';
import QrOrderLineLoader from '@/views/qrCodes/components/qrOrderLineLoader.vue';

// Store
import store from '@/store';
import { mapState } from 'vuex';
import { ActionTypes } from "@/store/actions/actions";

// Services
import { OrderService } from "@/services/orderService";

// Models
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { ProductGroup } from "@/models/productGroup";
import { DropdownModel } from "@/models/dropdownModel";

@Component({
  components: { QrOrderLine, QrOrderLineLoader },
  computed: mapState(['orderLoading', 'orders'])
})
export default class QrCodes extends Vue {

  private orderService: OrderService;

  private orderLoading!: boolean;

  private orders!: OrderLineDisplayModel[];

  private filteredOrders: OrderLineDisplayModel[] = [];

  private orderNumberFilter: string = "";

  private selectedBrand: string = "";

  private selectedProductGroup: ProductGroup = ProductGroup.None;

  private selectedUsername: string = "";

  private selectedSeason: string = "";

  private scrollPosition: number = 0;

  public constructor() {
    super();
    this.orderService = new OrderService();
  }

  private async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if(this.orderLoading === false && this.orders.length === 0){
      await store.dispatch(ActionTypes.SET_ORDER_DETAIL);
    }
    else{
      this.filterOrders();
    }
  }

  private scrollEv(event: any): void {
    this.scrollPosition = event.currentTarget.scrollTop;
  }

  private get showNoFilterResults(): boolean {
    return !this.orderLoading && this.orders.length > 0 && this.orders.every(o => !o.show);
  }

  private get locale(): string {
    if (i18n.availableLocales.some(l => l === i18n.locale)) {
      return i18n.locale;
    }
    return i18n.fallbackLocale as string;
  }

  private get brands(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_brands").toString() }];
    this.orders.forEach(line => {
      if (options.findIndex(option => option.value === line.brandName) === -1) {
        options.push(new DropdownModel(line.brandName, line.brandName));
      }
    });

    return options;
  }

  private get productGroups(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: 0, text: this.$t("pages.order_lines.all_productgroups").toString() }];
    this.orders.forEach(line => {
      if (options.findIndex(option => option.value === line.productGroup) === -1) {
        const productGroupName = this.$t(`enums.product_group.${line.productGroup}`).toString().split(/(?=[A-Z])/).join(" ");
        options.push(new DropdownModel(line.productGroup, productGroupName.charAt(0).toUpperCase() + productGroupName.slice(1).toLowerCase()));
      }
    });
    return options;
  }

  private get username(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_usernames").toString() }];
    const distinctUsers : any[]= [];
    this.orders.filter((item) => {
      const i = distinctUsers.findIndex(x => (x.fromCompanyUser.userId == item.fromCompanyUser.userId));
      if(i <= -1){
        distinctUsers.push(item);
      }
      return null;
    });
    distinctUsers.forEach(line => {
        let fullname = line.fromCompanyUser.firstName + ' ' + line.fromCompanyUser.lastName;
        if (fullname.length>20) {
          fullname = fullname.slice(0, 14).concat('...');
        }
        options.push(new DropdownModel(line.fromCompanyUser.userId, fullname));
    });
    return options;
  }

  private get seasons(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_seasons").toString() }];
    const distinctSeasons : any[]= [];
    this.orders.filter((item) => {
      const i = distinctSeasons.findIndex(x => (x.orderLines[0].season == item.orderLines[0].season));
      if(i <= -1 && item.orderLines[0].season!==null && item.orderLines[0].season!==""){
        distinctSeasons.push(item);
      }
      return null;
    });
    distinctSeasons.forEach(line => {
      if (line.orderLines[0].season !== null) {
        options.push(new DropdownModel(line.orderLines[0].season, line.orderLines[0].season));
      }
    });

    return options;
  }

  private get orderLines(): OrderLineDisplayModel[] {
    this.filteredOrders.forEach(o => o.show = true);
    let orders = this.filteredOrders;
    if (this.orderNumberFilter !== "") {
      orders = orders.filter(o =>{
         return o.orderNumber.toLowerCase().includes(this.orderNumberFilter.toLowerCase())
         || ( o.orderLines[0].season !==  null && o.orderLines[0].season.toLowerCase().includes(this.orderNumberFilter.toLowerCase()))
         || ( o.orderLines[0].colourway?.toLowerCase().includes(this.orderNumberFilter.toLowerCase()))
         || ( o.brandName.toLowerCase().includes(this.orderNumberFilter.toLowerCase()))
         || ( o.styleNumber.toLowerCase().includes(this.orderNumberFilter.toLowerCase()))
         || ( o.styleName.toLowerCase().includes(this.orderNumberFilter.toLowerCase()));
        });
    }
    if (this.selectedBrand !== "") {
      orders = orders.filter(o => o.brandName === this.selectedBrand);
    }
    if (this.selectedUsername !== "") {
      orders = orders.filter(o => o.fromCompanyUser.userId === this.selectedUsername);
    }
    if (this.selectedSeason !== "") {
      orders = orders.filter(o => o.orderLines[0].season === this.selectedSeason);
    }
    if (this.selectedProductGroup !== ProductGroup.None) {
      orders = orders.filter(o => o.productGroup === this.selectedProductGroup);
    }

    const finalOrders: OrderLineDisplayModel[] = orders;
    // TTD-3545 changes made to improve page performance
    // orders.forEach(o => {
    //   if (o.orderLinePhase === 0 && o.orderLineStatus === 0) {
    //     return;
    //   }
    //   else {
    //     finalOrders.push(o);
    //   }
    // });

    // const hideOrders = this.orders.filter(order => orders.indexOf(order) < 0);
    // hideOrders.forEach(order => {
    //   const orderToHide = this.orders.filter(o => o.id === order.id)[0];
    //   orderToHide.show = false;
    // });

    let latestOrders: OrderLineDisplayModel[] = [];
    latestOrders = finalOrders.filter(o => o.orderLineStatus !== 7);
    if (latestOrders.length <= 1400) {
      return latestOrders;
    }
    if (this.scrollPosition === 0) {
      latestOrders = finalOrders.filter(o => o.orderLineStatus !== 7);
      latestOrders = latestOrders.slice(0, 20);
    } else {
      if(document.getElementById('scrollableDiv') !== null && document.getElementById('widgetDiv') !== null){
        const parentDiv = document.getElementById('scrollableDiv')!.getBoundingClientRect();
        const widgetDiv = document.getElementById('widgetDiv')!.getBoundingClientRect().height;
        const totalHeight = (widgetDiv+10)*2; //here +10 is for margin-bottom to div
        const orderCount = (Math.floor(this.scrollPosition/totalHeight)+1)*20;
        latestOrders = finalOrders.filter(o => o.orderLineStatus !== 7);
        latestOrders = latestOrders.slice(0, orderCount);
      }
      else{
        this.scrollPosition = 0;
      }
    }
    return latestOrders;
  }

  @Watch('orders', {deep: true})
  private filterOrders(): void {
    if(this.orders.length > 0){
      this.filteredOrders = [];
      this.orders.forEach(o => {
        if (o.orderLinePhase === 0 && o.orderLineStatus === 0) {
          return;
        }
        else {
          this.filteredOrders.push(o);
        }
      });
    }
    else{
      this.filteredOrders = lodash.cloneDeep(this.orders);
    }
  }
}
