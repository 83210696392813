import { Guid } from "./guid";
import { TransportFootprintModel, TransportFootprintRequestModel } from "./transportFootprintModel";

export class GarmentFootprintRequestModel {
    public footprintDeadline!: string;
    public garment!: GarmentModel;
}

export class GarmentModel {
    constructor(){
        this.footprintStatus = "";
        this.garmentDetails = {
            ID: Guid.Empty,
            toCompanyUser: {
                toCompanyID: Guid.Empty,
                toCompanyName: "",
                filledByCompanyID: Guid.Empty,
                filledByPrimarySource: false,
                isValid: true
            },
            garmentTotalWeight: {
                weight: "",
                filledByCompanyID: Guid.Empty,
                filledByPrimarySource: false,
                isValid: true
            },
            garmentFabric: [],
            total: {
                totalcount: "",
                filledByCompanyID: Guid.Empty,
                filledByPrimarySource: false,
                isValid: true
            },
            garmentFabricComposition: [],
            productCategory: {
                category: "",
                filledByCompanyID: Guid.Empty,
                filledByPrimarySource: false,
                isValid: true
            },
            productType: {
                type: "",
                filledByCompanyID: Guid.Empty,
                filledByPrimarySource: false,
                isValid: true
            },
            showGarment: true,
            stepID: "",
            status: "",
        };
        this.cuttingWaste = [];
        this.packaging = [];
        this.postTreatment = [];
        this.accessories = [];
    }
    public footprintStatus!: string;
    public garmentDetails!: GarmentFootprintModel;
    public cuttingWaste!: CuttingFootprintModel[];
    public packaging!: PackagingModel[];
    public postTreatment!: PostTreatmentModel[];
    public accessories!: AccessoriesModel[];
}

export class GarmentStepsDataModel {
    public constructor(){
        this.stepID = "";
        this.footprintStatus = "";
        this.supplierName = "";
        this.referenceNo = "";
        this.referenceNoPrefix = "";
        this.showWrapper = false;
    }
    public stepID!: string;
    public supplierName!: string;
    public referenceNo?: string;
    public referenceNoPrefix?: string;
    public footprintStatus!: string;
    public showWrapper!: boolean;
}

export class GarmentDataModel {
    public footprintStatus!: string;
    public garmentDetails!: GarmentFootprintModel;
    public cuttingWaste!: CuttingFootprintModel[];
    public packaging!: PackagingModel[];
    public postTreatment!: PostTreatmentModel[];
    public accessories!: AccessoriesModel[];
    public transport!: TransportFootprintModel[];
    public transportStatus!: string;
}

export class GarmentFootprintModel {
    public constructor() {
        this.ID = Guid.Empty;
        this.toCompanyUser = {
            toCompanyID: Guid.Empty,
            toCompanyName: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.garmentFabric = [];
        this.garmentTotalWeight = {
            weight: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
    // TTD-3061 Sprint19
        this.productCategory = {
            category: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.productType = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.total = {
            totalcount: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.showGarment = true;
        this.stepID = "";
        this.status = "";
    }
    public ID!: string;
    public toCompanyUser!: ToCompanyUserModel;
    public garmentTotalWeight!: GarmentWeightModel;
    public productCategory!: ProductCategoryModel;
    public productType!: ProductTypeModel;
    public garmentFabric!: FabricModel[];
    public garmentFabricComposition!: GarmentFabricComposition[];
    public total!: TotalModel;
    public showGarment!: boolean;
    public stepID!: string;
    public status!: string;
}

export class CuttingFootprintModel {
    public constructor(){
        this.fabricUniqueID = Guid.Empty;
        this.cuttingWasteRecycle = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.fabricWastePercent = {
            percent: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.stepID = "";
        this.status = "";
    }
    public fabricUniqueID!: string;
    public fabricWastePercent!: WastePercentageModel;
    public cuttingWasteRecycle!: WasteRecycleModel;
    public stepID!: string;
    public status!: string;
}

export class WastePercentageModel {
    public constructor() {
        this.percent = "";
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
        this.isValid = true;
    }
    public percent!: string | number;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class WasteRecycleModel {
    public value!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class PackagingModel {
    public constructor(){
        this.fabricUniqueID = Guid.Empty;
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.matterial = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.weight = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.colliPerPackaging = {
            number: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.showPackaging = false;
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
        this.stepID = '';
        this.status = "";
    }
    public fabricUniqueID!: string;
    public reference!: ReferenceModel;
    public matterial!: EnergyModel;
    public weight!: EnergyModel;
    public colliPerPackaging!: ColliPackagingModel;
    public showPackaging!: boolean;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public stepID!: string;
    public status!: string;
}

export class PostTreatmentModel {
    public constructor() {
        this.ID = Guid.Empty;
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.treatmentType = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.waterUsedInFabric = {
            amount: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.msdsDoc = [];
        this.windEnergy = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.solarEnergy = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.thermalEnergy = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.totalEnergyUsed = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.thermalEnergySource = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.thermalEnergyNew = [new ThermalEnergyModel()];
        this.thermalEnergySourceNew = [new ThermalEnergyModel()];
        this.energySupportingDocument = [];
        this.showPostTreatment = false;
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
        this.stepID = '';
        this.status = '';
    }
    public ID!: string;
    public reference!: ReferenceModel;
    public treatmentType!: EnergyModel;
    public waterUsedInFabric!: WaterUsedModel;
    public msdsDoc!: EnergySupportingDocumentModel[];
    public windEnergy!: EnergyModel;
    public solarEnergy!: EnergyModel;
    public thermalEnergy!: EnergyModel;
    public thermalEnergySource!: EnergySourceModel;
    public thermalEnergyNew!: ThermalEnergyModel[];
    public thermalEnergySourceNew!: ThermalEnergyModel[];
    public totalEnergyUsed!: EnergyModel;
    public energySupportingDocument!: EnergySupportingDocumentModel[];
    public showPostTreatment!: boolean;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public stepID!: string;
    public status!: string;
}

export class AccessoriesModel {
    public constructor() {
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.accessoriesType = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.matterial = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.weight = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.accessoriesPergarment = {
            number: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.originCountry = {
            country: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.showAccessories = false;
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
        this.stepID = '';
        this.status = '';
    }
    public reference!: ReferenceModel;
    public accessoriesType!: EnergyModel;
    public matterial!: EnergyModel;
    public weight!: EnergyModel;
    public accessoriesPergarment!: ColliPackagingModel;
    public originCountry!: CountryModel;
    public showAccessories!: boolean;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public stepID!: string;
    public status!: string;
}

export class FabricModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.fabricReference = {
            referenceNo: "",
            fabricID: Guid.Empty,
            filledByCompanyID : Guid.Empty,
            filledByPrimarySource : false,
            isValid: true,
        };
        this.fabricPercent = {
            percent: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true,
        };
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
    }
    public ID!: string;
    public fabricReference!: FabricRefModel;
    public fabricPercent!: WastePercentageModel;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

    // TTD-3061 Sprint19
export class GarmentFabricComposition {
    public constructor(comp: string, percentage: string){
        this.composition = comp;
        this.fabricPercent = percentage;
    }
    public composition!: string;
    public fabricPercent!: string;
}

export class FabricRefModel{
    public constructor(){
        this.referenceNo = "",
        this.fabricID = Guid.Empty,
        this.filledByCompanyID = Guid.Empty,
        this.filledByPrimarySource = false,
        this.isValid= false;
    }
    public referenceNo!: string;
    public fabricID!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class ToCompanyUserModel{
    public constructor(){
        this.toCompanyID = Guid.Empty;
        this.toCompanyName = "";
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
        this.isValid= false;
    }
    public toCompanyID!: string;
    public toCompanyName!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class ReferenceModel{
    public constructor(){
        this.referenceNo = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid= false;
    }
    public referenceNo!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class GarmentWeightModel{
    public constructor(){
        this.weight = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid= false;
    }
    public weight!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class TotalModel{
    public constructor(){
        this.totalcount = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid= false;
    }
    public totalcount!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

    // TTD-3061 Sprint19
export class ProductCategoryModel{
    public constructor(){
        this.category = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid= false;
    }
    public category!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

    // TTD-3061 Sprint19
export class ProductTypeModel{
    public constructor(){
        this.type = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid= false;
    }
    public type!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class ColliPackagingModel{
    public constructor(){
        this.number = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid= false;
    }
    public number!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class EnergyModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid= false;
    }
    public value!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class EnergySourceModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid= false;
    }
    public value!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class WaterUsedModel {
    public constructor(){
        this.amount = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid= true;
    }
    public amount!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class CountryModel {
    public constructor(){
        this.country = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid= true;
    }
    public country!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class EnergySupportingDocumentModel{
    public ID!: string;
    public createdAt!: string;
    public createdBy!: string;
    public docUrl!: string;
    public fileHash!: string;
    public fileName!: string;
    public fileType!: string;
    public geoLat!: number;
    public geoLong!: number;
    public referenceNo!: string;
    public rejectionReason!: string;
    public stepID!: string;
    public status!: number;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

export class ThermalEnergyModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public value!: string|number;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}