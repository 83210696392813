import { OrderLineDisplayModel } from "./orderLineDisplayModel";
import { OrderModel } from "./orderModel";

export class CombinedOrderLineModel {
    public supplierId!: string;
    public supplierName!: string;
    public supplierCountry!: string;
    public orderCount!: number;
    public orderIds!: string[];
    public userName!: string;
    //TTD-2620, Agent orderlines changes
    public toCompanyName!: string;
    public toCompanyId!: string;
    //TTD-2620, Agent orderlines changes
    public isCompliant!: boolean;
    public productGroup!: string;
    public orderDetails!: OrderLineDisplayModel[];
    public newlySet?: boolean;
    public groupId!: string;
    public agentCompanyID!: string;
    public isCombineOrderSelected: boolean = false;
    public isOrdersConfirmed: boolean = false;
}

export class RemoveCombinedConfirmedOrders {

    public constructor(groupId: string, orderIds: string[]){
        this.groupId = groupId;
        this.orderIds = orderIds;
    }
    public groupId!: string;
    public orderIds!: string[];
}