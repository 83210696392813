import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import moment from 'moment';
import lodash from 'lodash';
import i18n from '@/i18n';
import { mapState } from 'vuex';

// Components
import ViewSustainableDocument from '@/views/compliance/components/viewSustainableDocument.vue';
import ViewSocialDocument from '@/views/compliance/components/viewSocialDocument.vue';
import ViewCertificatesUploaded from '@/views/compliance/components/viewCertificatesUploaded.vue';

// Services
import { ComplianceService } from '@/services/complianceService';

// Modals
import { ComplianceDocSentResponseModel } from '@/models/complianceDocSentResponseModel';
import { SustainableCertificatesModel } from '@/models/sustainableCertificatesModel';
import { SocialCertificatesModel } from '@/models/socialCertificatesModel';
import { SustainableDocModel } from '@/models/SustainableDocModel';
import { SocialDocModel } from '@/models/socialDocModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import {
  CertificateModel,
  CertificateOnTypeModel,
  CertificateTypes,
} from '@/models/certificateModel';
import { CompanyNameModel } from "@/models/companyModel";

@Component({
  components: { ViewSustainableDocument, ViewSocialDocument, ViewCertificatesUploaded },
  computed: mapState([
    'orderLoading',
    'orderLineStepsLoading',
    'uniqueSuppliersListLoading',
    'orders', 'companyNameList',
    'sustainableCertificate',
    'socialCertificates',
  ]),
})
export default class Certificates extends Vue {
  @Prop()
  private documents!: ComplianceDocSentResponseModel[];

  @Prop()
  private isLoading!: boolean;

  @Prop()
  private selectedTab!: number;

  @Prop()
  private selectedScps!: string[];

  @Prop()
  private searchFilter!: string;

  @Prop()
  private selectedBrand!: string;

  @Prop()
  private selectedPartner!: string;

  private complianceService: ComplianceService;

  private orderLoading!: boolean;
  private sustainableCertificate!: CertificateModel[];
  private sustainableAuditCerts: CertificateOnTypeModel[] = [];
  private sustainableMemCerts: CertificateOnTypeModel[] = [];
  private socialCertificates!: CertificateModel[];
  private socailAuditCerts: CertificateOnTypeModel[] = [];
  private socailMemCerts: CertificateOnTypeModel[] = [];
  private showPDFModal: boolean = false;
  private showSusPDFModal: boolean = false;
  private showSocialPDFModal: boolean = false;
  private showPdfCommentsModal: boolean = false;
  private sortGroupType: string = '';
  private sortGroupTypename: string = '';
  private sortValidToLatest: boolean = true;
  private sortValidToLatestArchive: boolean = true;

  private currentSustainableDocument!: SustainableCertificatesModel;

  private currentSocialDocument!: SocialCertificatesModel;

  private currentDocument!: ComplianceDocSentResponseModel;

  private imgSrc: NodeRequire = require('../../../assets/no-document-sent.png');

  private reminderScpList: ComplianceDocSentResponseModel[] = [];

  private documentName: string = '';

  private showRSP: boolean = false;
  private showOeko: boolean = false;

  private pdfDocument!: any;

  private loadingArchieveDoc: boolean = false;

  private sort: string = 'desc';

  private docWithSustainable: SustainableDocModel[] = [];

  private docWithSocial: SocialDocModel[] = [];

  private sortIcon: NodeRequire = require('../../../assets/icons/general/desc-sort.svg');

  private ovalIcon: NodeRequire = require('../../../assets/icons/general/oval-orange.svg');

  private selectedScp: [] = [];

  private susFocusCertificateGroup: string = '';
  private socFocusCertificateGroup: string = '';
  private auditFocusCertificateGroup: string = '';
  private memFocusCertificateGroup: string = '';

  private orderLineStepsLoading!: boolean;

  private uniqueSuppliersListLoading!: boolean;

  private orders!: OrderLineDisplayModel[];

  private loadingDoc: boolean = false;

  private sustainableCerts: CertificateOnTypeModel[] = [];
  private auditCerts: CertificateOnTypeModel[] = [];
  private socialCerts: CertificateOnTypeModel[] = [];
  private membershipCerts: CertificateOnTypeModel[] = [];
  private showSocialArchive: boolean = false;
  private showSustainableArchive: boolean = false;
  private showAuditArchive: boolean = false;
  private showMembershipArchive: boolean = false;
  private documentDetail: CertificateModel = new CertificateModel();

	private companyNameList!: CompanyNameModel[]; // TTD-3961

  public constructor() {
    super();
    this.complianceService = new ComplianceService();
  }

  private created(): void {
    const sustainable = i18n.t(
      `enums.sustainability_certificates.sustainability`
    ) as any;
    for (const key of Object.keys(sustainable)) {
      const certifcateName = sustainable[Number(key)];
      this.sustainableCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          'SUSTAINABLE',
          'SUSTAINABLE'
        )
      );
    }
    const susMembership = i18n.t(
      `enums.sustainability_certificates.membership`
    ) as any;
    for (const key of Object.keys(susMembership)) {
      const certifcateName = susMembership[Number(key)];
      this.sustainableMemCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SUSTAINABILITY));
      this.membershipCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SOCIAL
        )
      );
    }
    const susAudit = i18n.t(`enums.sustainability_certificates.audit`) as any;
    for (const key of Object.keys(susAudit)) {
      const certifcateName = susAudit[Number(key)];
      this.sustainableAuditCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
      this.auditCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.AUDIT,
          CertificateTypes.SUSTAINABILITY
        )
      );
    }
    const social = i18n.t(`enums.social_certificates.social`) as any;
    for (const key of Object.keys(social)) {
      const certifcateName = social[Number(key)];
      this.socialCerts.push(
        new CertificateOnTypeModel(key, certifcateName, 'SOCIAL', 'SOCIAL')
      );
    }
    const socialAudit = i18n.t(`enums.social_certificates.audit`) as any;
    for (const key of Object.keys(socialAudit)) {
      const certifcateName = socialAudit[Number(key)];
      this.socailAuditCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
      this.auditCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.AUDIT,
          CertificateTypes.SUSTAINABILITY
        )
      );
    }
    const socialMembership = i18n.t(
      `enums.social_certificates.membership`
    ) as any;
    for (const key of Object.keys(socialMembership)) {
      const certifcateName = socialMembership[Number(key)];
      this.socailMemCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SOCIAL));
      this.membershipCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SOCIAL
        )
      );
    }
  }

  private async updateFocusCertificateGroup(
    group: string,
    type: string
  ): Promise<void> {
    if (type === 'SUSTAINABILITY') {
      if (this.susFocusCertificateGroup !== group) {
        this.susFocusCertificateGroup = group;
      } else {
        this.susFocusCertificateGroup = '';
      }
    } else if (type === 'SOCIAL') {
      if (this.socFocusCertificateGroup !== group) {
        this.socFocusCertificateGroup = group;
      } else {
        this.socFocusCertificateGroup = '';
      }
    } else if (type === 'AUDIT') {
      if (this.auditFocusCertificateGroup !== group) {
        this.auditFocusCertificateGroup = group;
      } else {
        this.auditFocusCertificateGroup = '';
      }
    } else {
      if (this.memFocusCertificateGroup !== group) {
        this.memFocusCertificateGroup = group;
      } else {
        this.memFocusCertificateGroup = '';
      }
    }
  }

  private get allSocialCertificates(): {} {
    return this.filterDocuments('social');
  }

  private get allSustainableCertificates(): {} {
    const sus = this.filterDocuments('sustainable');
    return sus;
  }

  private get allAuditCertificates(): {} {
    return this.filterDocuments('audit');
  }

  private get allMemberShipCertificates(): {} {
    return this.filterDocuments('membership');
  }

  private get archivedSustainable(): {} {
    return this.filterDocuments('archiveSustainable');
  }
  private get archivedSocial(): {} {
    return this.filterDocuments('archiveSocial');
  }
  private get archivedAudit(): {} {
    return this.filterDocuments('archiveAudit');
  }
  private get archivedMembership(): {} {
    return this.filterDocuments('archiveMembership');
  }

  private sortByValidTo(type: number, typename: string): void {
    this.sortValidToLatest = !this.sortValidToLatest;
    this.sortGroupType = type.toString();
    this.sortGroupTypename = typename;
    this.uploadedCertificates(type, typename);
  }

  private sortByValidToArchive(type: number, typename: string): void {
    this.sortValidToLatestArchive = !this.sortValidToLatestArchive;
    this.sortGroupType = type.toString();
    this.sortGroupTypename = typename;
  }

  private filterDocuments(name: string): {} {
    let documents: CertificateModel[] = [];
    if (name === 'social') {
      documents = this.socialCertificates.filter(s => s.certificateType !==CertificateTypes.AUDIT && s.certificateType !==CertificateTypes.MEMBERSHIP && s.type!==0 && this.socailAuditCerts.findIndex(au => au.ID === s.type.toString())===-1 && this.socailMemCerts.findIndex(mem => mem.ID === s.type.toString())===-1);
    } else if (name === 'audit') {
      documents = [...this.socialCertificates.filter(s => s.certificateType === CertificateTypes.AUDIT || (s.certificateType === '' && this.socailAuditCerts.findIndex(au => au.ID === s.type.toString())!==-1)), ...this.sustainableCertificate.filter(s => s.certificateType === CertificateTypes.AUDIT || (s.certificateType === '' && this.sustainableAuditCerts.findIndex(au => au.ID === s.type.toString())!==-1))];
    } else if (name === 'membership') {
      documents = [...this.socialCertificates.filter(s => s.certificateType === CertificateTypes.MEMBERSHIP && s.membershipID!=='' && s.status!==''), ...this.sustainableCertificate.filter(s => s.certificateType === CertificateTypes.MEMBERSHIP && s.membershipID!=='' && s.status!=='')];
    } else {
      documents = this.sustainableCertificate.filter(s => s.certificateType !==CertificateTypes.AUDIT && s.certificateType !==CertificateTypes.MEMBERSHIP && s.type!==0 && this.sustainableAuditCerts.findIndex(au => au.ID === s.type.toString())===-1 && this.sustainableMemCerts.findIndex(mem => mem.ID === s.type.toString())===-1);
    }
    if(this.searchFilter !== ''){
      documents = documents.filter((d) => (d.companyName.toLowerCase().includes(this.searchFilter.toLowerCase())) || (d.issuer.toLowerCase().includes(this.searchFilter.toLowerCase())) || (d.referenceIssuer.toLowerCase().includes(this.searchFilter.toLowerCase())));
    }
    if (this.selectedPartner !== '') {
      documents = documents.filter((d) => d.toCompanyID === this.selectedPartner);
    }
    const app = this;
    const groupedCertificateModel = documents.reduce(
      (acc: any, certificate: any) => {
        const filteredCertificate = lodash.clone(certificate);
        if (name === 'social') {
          const cert = this.socialCerts.filter(
            (soc) => soc.ID === certificate.type.toString()
          )[0];
          if (cert) {
            filteredCertificate.typeName = cert.Name;
          }
        } else if (name === 'audit') {
          const cert = this.auditCerts.filter(
            (aud) => aud.ID === certificate.type.toString()
          )[0];
          if (cert) {
            filteredCertificate.typeName = cert.Name;
          }
        } else if (name === 'membership') {
          const cert = this.membershipCerts.filter(
            (mem) => mem.ID === certificate.type.toString()
          )[0];
          if (cert) {
            filteredCertificate.typeName = cert.Name;
          }
        } else {
          const cert = this.sustainableCerts.filter(
            (sus) => sus.ID === certificate.type.toString()
          )[0];
          if (cert) {
            filteredCertificate.typeName = cert.Name;
          }
        }

        filteredCertificate.expiryInfo = '';
        filteredCertificate.expiryStatus = '';
        const daysDifference = moment(certificate.validtoDate).diff(
          moment(),
          'days'
        );
        if (daysDifference <= 30) {
          if (daysDifference > 0) {
            filteredCertificate.expiryInfo = `Expiring in ${daysDifference} days`;
          } else if (daysDifference == 0) {
            filteredCertificate.expiryInfo = 'Expiring';
          } else {
            filteredCertificate.expiryInfo = 'Expired';
          }
          filteredCertificate.expiryStatus =
            daysDifference >= 0 ? `expiring` : `expired`;
        }
        (
          acc[filteredCertificate.typeName] ||
          (acc[filteredCertificate.typeName] = [])
        ).push(filteredCertificate);
        return acc;
      },
      {}
    );
    const orderedGroupedCertificateModel = Object.keys(groupedCertificateModel)
      .sort()
      .reduce((obj: any, key) => {
        obj[key] = groupedCertificateModel[key];
        return obj;
      }, {});
    return orderedGroupedCertificateModel;
  }

  private isStatusUploadedExist(certs: CertificateModel[]): boolean {
    if (
      certs.findIndex(
        (c) => c.status === CertificateTypes.UPLOADED || c.status === '' || c.status === CertificateTypes.EXPIRED
      ) === -1
    ) {
      return false;
    }
    return true;
  }

  private uploadedCertificates(
    typeValue: number,
    type: string
  ): CertificateModel[] {
    if (type === CertificateTypes.SUSTAINABILITY) {
      let documents = this.sustainableCertificate.filter(
        (s) =>
          s.type === typeValue &&
          s.status !== CertificateTypes.ARCHIVED &&
          s.certificateType !== CertificateTypes.AUDIT &&
          s.certificateType !== CertificateTypes.MEMBERSHIP
      );
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, 'ID');
    } else if (type === CertificateTypes.SOCIAL) {
      let documents = this.socialCertificates.filter(
        (s) =>
          s.type === typeValue &&
          s.status !== CertificateTypes.ARCHIVED &&
          s.certificateType !== CertificateTypes.AUDIT &&
          s.certificateType !== CertificateTypes.MEMBERSHIP
      );
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, 'ID');
    } else if (type === CertificateTypes.AUDIT) {
      const suAudit = this.sustainableCertificate.filter(
        (s) =>
          s.type === typeValue &&
          s.status !== CertificateTypes.ARCHIVED &&
          s.certificateType === CertificateTypes.AUDIT
      );
      const socialAudit = this.socialCertificates.filter(
        (s) =>
          s.type === typeValue &&
          s.status !== CertificateTypes.ARCHIVED &&
          (s.certificateType === CertificateTypes.AUDIT ||
            (s.certificateType === '' &&
              this.auditCerts.findIndex(
                (au) => au.ID === typeValue.toString()
              ) !== -1))
      );
      let documents = [...suAudit, ...socialAudit];
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, 'ID');
    } else {
      const suAudit = this.sustainableCertificate.filter(
        (s) =>
          s.type === typeValue &&
          (s.status === CertificateTypes.UPLOADED) &&
          (s.certificateType === CertificateTypes.MEMBERSHIP) &&
          s.membershipID!==''
      );
      const socialAudit = this.socialCertificates.filter(
        (s) =>
          s.type === typeValue &&
          s.status === CertificateTypes.UPLOADED &&
          (s.certificateType === CertificateTypes.MEMBERSHIP ||
            s.certificateType === "") && 
            s.membershipID!==''
      );
      let documents = [...suAudit, ...socialAudit];
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, "ID");
    }
  }

  private sortByValidToDate(documents: CertificateModel[]): CertificateModel[] {
    const app = this;
    documents.sort(function (a: CertificateModel, b: CertificateModel) {
      const dateA = new Date(a.validtoDate),
        dateB = new Date(b.validtoDate);
      if (
        a.type.toString() == app.sortGroupType ||
        b.type.toString() == app.sortGroupType
      ) {
        return app.sortValidToLatest
          ? moment(dateB).unix() - moment(dateA).unix()
          : moment(dateA).unix() - moment(dateB).unix();
      }
      return moment(dateB).unix() - moment(dateA).unix();
    });
    return documents;
  }

  private showArchivedCertificates(
    typeValue: number,
    type: string
  ): CertificateModel[] {
    if (type === CertificateTypes.SUSTAINABILITY) {
      let documents = this.sustainableCertificate.filter(
        (s) =>
          s.type === typeValue &&
          s.status === CertificateTypes.ARCHIVED &&
          s.certificateType !== CertificateTypes.AUDIT &&
          s.certificateType !== CertificateTypes.MEMBERSHIP
      );
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, 'ID');
    } else if (type === CertificateTypes.SOCIAL) {
      let documents = this.socialCertificates.filter(
        (s) =>
          s.type === typeValue &&
          s.status === CertificateTypes.ARCHIVED &&
          s.certificateType !== CertificateTypes.AUDIT &&
          s.certificateType !== CertificateTypes.MEMBERSHIP
      );
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, 'ID');
    } else if (type === CertificateTypes.AUDIT) {
      const suAudit = this.sustainableCertificate.filter(
        (s) =>
          s.type === typeValue &&
        (s.status === CertificateTypes.ARCHIVED || s.status === '') &&
        (s.certificateType === CertificateTypes.AUDIT || s.certificateType === '')
      );
      const socialAudit = this.socialCertificates.filter(
        (s) =>
          s.type === typeValue &&
        (s.status === CertificateTypes.ARCHIVED || s.status === '') &&
        (s.certificateType === CertificateTypes.AUDIT || s.certificateType === '')
      );
      let documents = [...suAudit, ...socialAudit];
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, 'ID');
    } else {
      const suAudit = this.sustainableCertificate.filter(
        (s) =>
          s.type === typeValue &&
          s.status === CertificateTypes.ARCHIVED &&
          s.certificateType === CertificateTypes.MEMBERSHIP &&
          s.membershipID!==''
      );
      const socialAudit = this.socialCertificates.filter(
        (s) =>
          s.type === typeValue &&
          s.status === CertificateTypes.ARCHIVED &&
          s.certificateType === CertificateTypes.MEMBERSHIP &&
          s.membershipID!==''
      );
      let documents = [...suAudit, ...socialAudit];
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, 'ID');
    }
  }

  private sortByArchiveLatest(
    documents: CertificateModel[]
  ): CertificateModel[] {
    const app = this;
    documents.sort(function (a: CertificateModel, b: CertificateModel) {
      const dateA = new Date(a.validtoDate),
        dateB = new Date(b.validtoDate);
      if (
        a.type.toString() == app.sortGroupType ||
        b.type.toString() == app.sortGroupType
      ) {
        return app.sortValidToLatestArchive
          ? moment(dateB).unix() - moment(dateA).unix()
          : moment(dateA).unix() - moment(dateB).unix();
      }
      return moment(dateB).unix() - moment(dateA).unix();
    });
    return documents;
  }

  private toggleArchive(typeValue: number, type: string, id:string): void {
    const susIndex = this.sustainableCertificate.findIndex(
      (f) => f.type === typeValue && f.ID === id
    );
    const socIndex = this.socialCertificates.findIndex(
      (f) => f.type === typeValue && f.ID === id
    );
    if (susIndex !== -1 && type === CertificateTypes.SUSTAINABILITY) {
      this.sustainableCertificate[susIndex].isShowArchive =
        !this.sustainableCertificate[susIndex].isShowArchive;
      const cert = this.sustainableCertificate.filter(
        (s) => s.type === typeValue && s.ID === id
      )[0];
      this.sustainableCertificate.splice(susIndex, 0, cert);
    }
    if (socIndex !== -1 && CertificateTypes.SOCIAL) {
      this.socialCertificates[socIndex].isShowArchive =
        !this.socialCertificates[socIndex].isShowArchive;
      const cert = this.socialCertificates.filter(
        (s) => s.type === typeValue && s.ID === id
      )[0];
      this.socialCertificates.splice(socIndex, 0, cert);
    }
  }

  private async downloadSusPDF(
    selectedDocument: SustainableCertificatesModel
  ): Promise<void> {
    this.pdfDocument.contentDispositionFilename = selectedDocument.fileName;
    this.pdfDocument.download();
  }

  private formattedDate(date: string): string {
    return moment(date).format('D MMM YYYY');
  }

  private getExpirationStatusIcon(date: string): string {
    const days = this.getDateDifference(date);
    if (days < 0) {
      return require('../../../assets/icons/general/oval-red.svg');
    } else if (days > 0 && days <= 30) {
      return require('../../../assets/icons/general/oval-orange.svg');
    } else {
      return require('../../../assets/icons/general/oval-green.svg');
    }
  }

  private getExpiration(date: string): string {
    const days = this.getDateDifference(date);
    if (days < 0) {
      return 'Expired';
    } else if (days > 0 && days <= 30) {
      let text = `Expiring in ${days} days`;
      if(days === 1){
        text = `Expiring in ${days} day`;
      }
      return text;
    } else {
      return '';
    }
  }

  private getDateDifference(date: string): number {
    const start = moment(Date.now());
    const end = moment(date);
    const duration = moment.duration(end.diff(start));
    return Math.ceil(duration.asDays());
  }

  private openPDFModal(doc: CertificateModel, certName:string): void {
    this.documentDetail = doc;
    this.documentDetail.typeName = certName;
    this.documentDetail.amforiID = doc.licenseID;
    this.showPDFModal = true;
  }

  private closePDFModal(success: boolean): void {
    this.showPDFModal = false;
  }

  private openSusPDFModal(
    selectedSusDocument: SustainableCertificatesModel
  ): void {
    if (selectedSusDocument.type == 6) {
      this.showOeko = true;
    } else {
      this.showOeko = false;
    }

    this.currentSustainableDocument = selectedSusDocument;
    this.showSusPDFModal = true;
  }

  private openSocialPDFModal(
    selectedSocialDocument: SocialCertificatesModel
  ): void {
    if (selectedSocialDocument.type == 2) {
      this.showRSP = true;
    } else {
      this.showRSP = false;
    }
    this.currentSocialDocument = selectedSocialDocument;
    this.showSocialPDFModal = true;
  }

  private closeSusPDFModal(): void {
    this.showSusPDFModal = false;
  }

  private closeSocialPDFModal(): void {
    this.showSocialPDFModal = false;
  }

  private sortSustainableList(type: number, key: number): void {
    if (this.sort == 'desc') {
      const sortIcon = require('../../../assets/icons/general/asc-sort.svg');
      var relativeIcon = document.getElementById(
        'sort-sust-' + key
      ) as HTMLImageElement;
      relativeIcon.src = sortIcon;
      this.sort = 'asc';
      this.docWithSustainable.forEach((doc) => {
        if (doc.type == type) {
          return doc.allCerts.sort(
            (a, b) => Date.parse(a.validtoDate) - Date.parse(b.validtoDate)
          );
        }
      });
    } else {
      const sortIcon = require('../../../assets/icons/general/desc-sort.svg');
      var relativeIcon = document.getElementById(
        'sort-sust-' + key
      ) as HTMLImageElement;
      relativeIcon.src = sortIcon;
      this.sort = 'desc';
      this.docWithSustainable.forEach((doc) => {
        if (doc.type == type) {
          return doc.allCerts.sort(
            (a, b) => Date.parse(b.validtoDate) - Date.parse(a.validtoDate)
          );
        }
      });
    }
  }

  private sortSocialList(type: number, key: number): void {
    if (this.sort == 'desc') {
      const sortIcon = require('../../../assets/icons/general/asc-sort.svg');
      var relativeIcon = document.getElementById(
        'sort-social-' + key
      ) as HTMLImageElement;
      relativeIcon.src = sortIcon;
      this.sort = 'asc';
      this.docWithSocial.forEach((doc) => {
        if (doc.type == type) {
          return doc.allCerts.sort(
            (a, b) => Date.parse(a.validtoDate) - Date.parse(b.validtoDate)
          );
        }
      });
    } else {
      const sortIcon = require('../../../assets/icons/general/desc-sort.svg');
      var relativeIcon = document.getElementById(
        'sort-social-' + key
      ) as HTMLImageElement;
      relativeIcon.src = sortIcon;
      this.sort = 'desc';
      this.docWithSocial.forEach((doc) => {
        if (doc.type == type) {
          return doc.allCerts.sort(
            (a, b) => Date.parse(b.validtoDate) - Date.parse(a.validtoDate)
          );
        }
      });
    }
  }

  private isLastIndex(allCertificates: any): string {
    const certs = Object.keys(allCertificates);
    const lastCert = certs[certs.length - 1];
    return lastCert;
  }

  @Watch('selectedbrand')
  private onselectedbrand(): void {}

  @Watch('selectedPartner')
  private onselectedPartner(): void {}

  @Emit()
  private reloadSentDocuments() {}
}
