import moment from "moment";

export class ComplianceDataModel {
    public id!: string;
    public issuer!: string;
    public type!: number;
    public validToDate!: string;
    public referenceIssuer!: string;
    public displayName?: string;

    public constructor(id: string, issuer: string, referenceIssuer: string, type: number, validToDate: string, certificateName: string, typeName: string) {
        this.id = id;
        this.issuer = issuer;
        this.referenceIssuer = referenceIssuer;
        this.type = type;
        this.validToDate = moment(validToDate).format("D MMM YYYY");
        this.displayName = `${certificateName} (${typeName})`;
    }
}