import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class QrCodeTile extends Vue {

    @Prop()
    private totalQrCodeLive!: number;

    @Prop()
    private totalStyles!: number;

    @Prop()
    private countLoading!: boolean;

}