import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import lodash from "lodash";
import { mapState } from "vuex";

// Components
import SearchSupplier from '@/components/general/search-supplier/searchSupplier.vue';
import CreateSupplyChain from '@/views/home/components/createSupplyChain.vue';
import CreateBulkOrder from '@/views/home/components/bulkOrder.vue';
import AllBulkOrder from '@/views/home/components/allBulkOrder.vue'

// Models
import { DropdownModel } from '@/models/dropdownModel';
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { Guid } from "@/models/guid";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { UserPermissionModel } from "@/models/permissionModel";

// Services
import { OrderService } from '@/services/orderService';
import { companyResponseModel } from "@/models/companyModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";

@Component({
    components: { SearchSupplier, CreateSupplyChain, CreateBulkOrder, AllBulkOrder },
    computed: mapState(['userRole', 'userPermissions'])
})
export default class PendingOrderLineSelected extends Vue {

    @Prop()
    private pendingType!: string;

    @Prop()
    private combinedOrders!: CombinedOrderLineModel[];

    @Prop()
    private suppliers!: OrderCompanyModel[];

    @Prop()
    private supplierDropdownData!: DropdownModel[];

    @Prop()
    private suppliersLoading!: boolean;

    @Prop()
    private allOrdersSelected!: boolean;

    @Prop()
    private orderConfirming!: boolean;

    private isSupplierSelected: boolean = false;

    private modifiableCombinedOrders: CombinedOrderLineModel[] = [];

    private orderService: OrderService;

    private showSearch: boolean = false;

    private isConfirming: boolean = false;

    private isDeleting: boolean = false;

    private showAddStepModal: boolean = false;

    private showBulkOrderModal: boolean = false;

    private userRole!: string;

    private userPermissions!: UserPermissionModel | null;

    private reviewSelectType: string = 'confirm'

    public constructor() {
        super();
        this.orderService = new OrderService();
        // this.modifiableCombinedOrders = lodash.cloneDeep(this.combinedOrders);
    }

    public async created(): Promise<void> {
        this.modifiableCombinedOrders = lodash.cloneDeep(this.combinedOrders);
    }

    private isSupplierConnected(order: CombinedOrderLineModel): boolean {
        const isSupplierConnected = this.suppliers.findIndex(s => s.id === order.supplierId && s.isTier1 === true) !== -1 ? true : false;
        if (!isSupplierConnected) {
            order.supplierId = Guid.Empty;
        }
        return isSupplierConnected;
    }

    private get countCombinedOrders(): number {
        let count = 0;
        this.modifiableCombinedOrders.forEach(combinedOrder => {
            count += combinedOrder.orderCount;
        });
        return count;
    }

    private get tier1Supplier(): DropdownModel[] {
        const tier1Supplier = this.suppliers.filter(t => t.isTier1 === true);
        return tier1Supplier.map(s => ({ value: s.id, text: s.isCompliant ? `${s.companyName} (${s.country})` : `${s.companyName} (${this.$t('pages.home.pending_invite')})` }));
    }

    private get assignSCPPermission(): number {
        return this.userPermissions !== null ? this.userPermissions.assignSCPOrderLine : 0;
    }

    private get confirmOrderLinePermission(): number {
        return this.userPermissions !== null ? this.userPermissions.confirmOrderLine : 0;
    }

    private get deleteOrderLinePermission(): number {
        return this.userPermissions !== null ? this.userPermissions.deleteOrderLine : 0;
    }

    private closeSearchHandler(): void {
        this.showSearch = false;
    }

    private openSearchSelectHandler(type: string): void {
        if (this.isDeleting) {
            return;
        }
        // this.showSearch = true;
        this.showBulkOrderModal = true;
        this.reviewSelectType = type;
    }

    private setSupplierNameHandler(value: string): void {
        const supplier = this.suppliers.filter(s => s.id === value)[0];
        if (supplier.id !== "") {
            this.modifiableCombinedOrders.forEach(combinedOrder => {
                combinedOrder.supplierId = supplier.id;
                combinedOrder.supplierName = supplier.companyName;
                combinedOrder.supplierCountry = supplier.country;
            });
            this.isSupplierSelected = true;
        }
        this.showSearch = false;
    }

    private invitationSendHandler(): void {
        this.suppliersChanged();
    }
    private showBlkModal(){
        this.showBulkOrderModal = true;
    }

    private remove(): void {
        if (this.isConfirming) {
            return;
        }
        const confirmationText = this.$t('pages.home.delete_confirmation').toString();
        this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false}).then(() => {
            let orderIds: string[] = [];
            this.modifiableCombinedOrders.forEach(order => {
                orderIds = orderIds.concat(order.orderIds);
            });
            this.isDeleting = true;
            this.deleteOrders(orderIds);
        }).catch(onrejected => { });
    }

    private closeAddStepModal(created: boolean): void {
        this.showAddStepModal = false;
        this.showBulkOrderModal = false;
        if(this.reviewSelectType === 'agent'){
            this.reloadOrderLines();
        }
    }

    private async save(bulkOrders:CombinedOrderLineModel[],selectedOrders:string[], agentDetails: OrderCompanyModel): Promise<void> {
        // this.showBulkOrderModal = false;
        if (this.isDeleting) {
            return;
        }
        this.isConfirming = true;
        bulkOrders.forEach((orders)=> {
            const selectedOrderDetails = orders.orderDetails.filter((order) => selectedOrders.includes(order.orderId));
            orders.orderDetails = [...selectedOrderDetails];
            orders.orderIds = [...selectedOrders];
            orders.orderCount = selectedOrderDetails.length;
        })
        this.confirmed(bulkOrders, [], bulkOrders.length > 0 ? true : false, agentDetails);
    }
    private selectedBulkOrders(orders:OrderLineDisplayModel, ids:string[]){
        this.showBulkOrderModal = false;
        this.resetBulkOrders(orders,ids)
    }

    @Emit()
    private reloadOrderLines(): void {
    }

    @Emit()
    private confirmed(bulkOrders: CombinedOrderLineModel[], confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean, agentDetails: OrderCompanyModel): void {
    }

    @Emit()
    private deleteOrders(orderIds: string[]): void {
    }

    @Emit()
    private suppliersChanged(): void {
    }

    @Emit()
    private toggleOrderSelection(): void {
    }

    @Emit()
    private toggleSelectAll(): void {
    }

    @Emit()
    private toggleDeselectAll(): void {
    }

    @Emit() 
    resetBulkOrders(orders:OrderLineDisplayModel, ids:string[]){}

    @Watch("combinedOrders", { deep: true })
    private combinedOrdersChanged(): void {
        this.isSupplierSelected = false;
        this.modifiableCombinedOrders = lodash.cloneDeep(this.combinedOrders);
    }
}