import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import lodash from 'lodash';
import { mapState } from "vuex";

// Components
import SearchSupplier from '@/components/general/search-supplier/searchSupplier.vue';

// Modals
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { BulkOrderModel } from "@/models/bulkOrderModel";
import { DropdownModel } from "@/models/dropdownModel";
import { Guid } from "@/models/guid";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { updatePhaseStatusRequestModel } from "@/models/publishUnpublishOrderModel";

// Services
import { OrderService } from '@/services/orderService';
import { SupplierService } from "@/services/supplierService";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    components: { SearchSupplier },
    computed: mapState(['companyType', 'companyNameList'])
})

export default class CreateBulkOrder extends Vue {
    @Prop()
    private orders!: CombinedOrderLineModel[];

    @Prop()
    private suppliers!: OrderCompanyModel[];

    @Prop()
    private tier1SupplierList!: DropdownModel[];

    @Prop()
    private suppliersLoading!: boolean;

    @Prop()
    private orderConfirming!: boolean;

    @Prop()
    private reviewSelectType!: string;

    @Prop()
    private pendingOrdersBlkSelect!: boolean;

    private orderService: OrderService;

    //TTD-2620, Agent orderlines changes
    private companyType!: number;

    private supplierService: SupplierService;

    private combinedOrders: BulkOrderModel[];

    private order: BulkOrderModel = new BulkOrderModel();

    private selected: boolean = false;

    private selectAll: boolean = false;

    private isLoading: boolean = false;

    private showAddStepModal: boolean = false;

    private orderIds: string[] = [];

    private showSearch: boolean = false;

    private partnerNameIndex: number = -1;

    private isDeleting: boolean = false;

    private isConfirming: boolean = false;

    private isOrderNotSelected: boolean = false;

    private isForwardToCp: boolean = false;

    private forwardToCPLoading: boolean = false;

    private selectedAgentDetials: OrderCompanyModel;

    private companyNameList!: CompanyNameModel[];

    public constructor() {
        super();
        this.orderService = new OrderService();
        this.supplierService = new SupplierService();
        this.combinedOrders = [];
        this.selectedAgentDetials = new OrderCompanyModel("","","",false,false);
    }

    private async created(): Promise<void> {
        try {
            this.isLoading = true;
            this.selectedAgentDetials = new OrderCompanyModel("","","",false,false);
            await this.getOrderList();
            this.checkContractualPartner();
        }
        finally {
            this.isLoading = false;
        }
    }

    private getOrderList() {
        this.orders.forEach((order) => order.orderDetails.sort((item1, item2) => {
            if(item1.orderNumber < item2.orderNumber || item1.styleNumber < item2.styleNumber) { return -1; }
            if(item1.orderNumber > item2.orderNumber || item1.styleNumber > item2.styleNumber) { return 1; }
            return 0;
        }));
        this.orders.forEach((order) => order.orderDetails.forEach(item => {
            this.order = new BulkOrderModel();
            this.order.orderId = item.orderId;
            item.orderLines.forEach(orderItem => {
                this.order.styleNumber = orderItem.styleNumber;
            });
            this.order.orderNumber = item.orderNumber;
            this.order.styleName = item.styleName;
            this.order.colourway = item.orderLines[0].colourway === undefined || item.orderLines[0].colourway.trim() === '' ? '-' : item.orderLines[0].colourway.trim();
            this.order.supplierId = item.toCompanyId;
            this.order.season = item.orderLines[0].season;
            this.order.compositionMainFabric = item.orderLines[0].compositionMainFabric;
            this.order.supplierName = item.toCompanyId === Guid.Empty ? '' : item.toCompanyName;
            this.order.checked = false;
            this.combinedOrders.push(this.order);
        }));
        this.combinedOrders.forEach((order)=> {
            order.checked = true;
            this.orderIds.push(order.orderId)
        });
        this.selectAll = true;
    }

    private openAddStepModal(): void {
        this.showAddStepModal = true;
    }

    private select(): void {
        if (!this.selectAll) {
            this.selectAll = true;
            this.combinedOrders.forEach(order => {
                order.checked = true;
                if(this.orderIds.indexOf(order.orderId) === -1){
                    this.orderIds.push(order.orderId);
                }
            });
            this.isOrderNotSelected = false;
            return;
        }
        this.combinedOrders.forEach(order => {
            order.checked = false;
            if(this.orderIds.indexOf(order.orderId) !== -1){
                const orderIndex = this.orderIds.indexOf(order.orderId);
                this.orderIds.splice(orderIndex, 1);
            }
        });
        this.selectAll = false;
        this.isOrderNotSelected = true;
    }

    private orderValue(orderId: string, value: boolean): void {
        if(value){
            const index = this.combinedOrders.findIndex(o => o.orderId === orderId);
            this.combinedOrders[index].checked = value;
            this.isOrderNotSelected = false;
            this.orderIds.push(orderId);
            if(this.orderIds.length === this.combinedOrders.length){
                this.selectAll = true;
            }
            else{
                this.selectAll = false;
            }
            const allOrders = this.combinedOrders;
            this.combinedOrders = [];
            this.combinedOrders = allOrders;
            this.checkContractualPartner();
        }
        else{
            const index = this.combinedOrders.findIndex(o => o.orderId === orderId);
            this.combinedOrders[index].checked = value;
            const orderIndex = this.orderIds.indexOf(orderId);
            this.orderIds.splice(orderIndex, 1);
            this.selectAll = false;
            const allOrders = this.combinedOrders;
            this.combinedOrders = [];
            this.combinedOrders = allOrders;
            this.checkContractualPartner();
        }
    }

    private openSearchSelectHandler(index: number): void {
        if (this.isDeleting) {
            return;
        }
        this.showSearch = true;
        this.partnerNameIndex = index;
    }

    private setSupplierNameHandler(value: string): void {
        const supplier = this.suppliers.filter(s => s.id === value)[0];
        if(this.partnerNameIndex !== -1){
            this.combinedOrders[this.partnerNameIndex].supplierName = supplier.companyName;
            this.combinedOrders[this.partnerNameIndex].supplierId = supplier.id;
            this.combinedOrders[this.partnerNameIndex].supplierCountry = supplier.country;
        }else{
            if(this.orderIds.length > 0){
                this.orders.forEach((order) => {
                    this.orderIds.forEach((id) => {
                        const combinedOrder = order.orderDetails.find((details) => details.orderId === id);
                        if(combinedOrder){
                            order.supplierId = supplier.id;
                            order.supplierCountry = supplier.country;
                            order.supplierName = supplier.companyName;
                            combinedOrder.supplierName = supplier.companyName;
                            combinedOrder.supplierId = supplier.id;
                            combinedOrder.supplierCountry = supplier.country;
                        }
                    })
                })
            }
            this.selectedBulkOrders(this.orders, this.orderIds);
        }
        this.checkContractualPartner();
        this.showSearch = false;
    }

    private closeSearchHandler(): void {
        this.showSearch = false;
    }

    private invitationSendHandler(): void {
        this.suppliersChanged();
    }

    private remove(orderId: string, index: number): void {
        const confirmationText = this.$t('pages.home.delete_confirmation').toString();
        this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false}).then(() => {
            this.combinedOrders[index].isDeleted = true;
            const combinedOrders = this.combinedOrders;
            this.combinedOrders = [];
            this.combinedOrders = combinedOrders;
            this.deleteOrders([orderId]);
        }).catch(onrejected => { });
    }

    private get isOrderConfirming(): boolean {
        if(this.orderConfirming === false && this.isConfirming === true){
            this.isConfirming = false;
            this.close(false);
            return false;
        }
        else{
            return this.orderConfirming;
        }
    }

    private get isCPEmpty(): boolean {
        return this.combinedOrders.findIndex(co => co.supplierName === "") !== -1 ? true : false;
    }

    // check if orders has multiple contractual partner or not
    private checkContractualPartner(): void {
        let uniqueSupplier: string[] = this.combinedOrders.filter(o => o.checked === true).map(o => o.supplierId);
        uniqueSupplier = lodash.uniq(uniqueSupplier);
        uniqueSupplier.length === 1 ? this.isOrderNotSelected = false : this.isOrderNotSelected = true;
    }

    private closeAll(created: boolean){
        this.close(created);
    }

    private save(){
        this.orders.forEach((ord) => {
            for(let order of ord.orderDetails){
                for(let combinedOrder of this.combinedOrders){
                    if(order.orderId === combinedOrder.orderId){
                        order.supplierName =  combinedOrder.supplierName
                        order.supplierId =  combinedOrder.supplierId
                        order.supplierCountry =  combinedOrder.supplierCountry
                    }
                }
            }
        })
        this.confirmSave(this.orders,this.orderIds, this.selectedAgentDetials);
    }

    private async forwardOrdersToCP(): Promise<void> {
        this.forwardToCPLoading = true;
        const selectedOrderIds = this.combinedOrders.filter(o => o.checked).map(or => or.orderId);
        const orderIds: updatePhaseStatusRequestModel = {
            orderIdList: selectedOrderIds
        }
        const response = await this.supplierService.updateOrderlinePhaseStatus(orderIds, 1, 0);
        this.forwardToCPLoading = false;
        if(response.success){
            this.isForwardToCp = false;
            this.close(true);
        }
    }

    @Emit()
    private suppliersChanged(): void {
    }


    @Emit()
    private deleteOrders(orderIds: string[]): void {
    }

    @Emit()
    private close(created: boolean): void {
    }

    @Emit()
    private confirmSave(combinedOrders: CombinedOrderLineModel[],orderIds:string[], agentDetails: OrderCompanyModel):void { }

    @Emit()
    private selectedBulkOrders(orders:CombinedOrderLineModel[], orderIds:string[]):void{}
}
