import { ClientStep } from '@/clients/clientStep';
import { ClientStepModel } from '@/models/clientStepModel';

export class ClientStepService {
    private readonly client: ClientStep = new ClientStep();

    public async getClientStepsAsync(): Promise<ClientStepModel[]> {
        const result = await this.client.getClientStepsAsync();
        return result;
    }
}