import { OrderCompanyModel } from "./orderCompanyModel";

export class BrandWithSupplierModel{
    public brandName!: string;
    public IDs!: string[];
}

export class BrandRelatedSuppliersModel{
    public constructor(){
        this.brandName = '';
        this.supplierId = [];
    }
    public brandName!: string;
    public supplierId!: SupplierModel[];
}

export class SupplierModel{
    public constructor(){
        this.id = '';
        this.name = '';
    }
    public id!: string;
    public name!: string;
}

export class AssociatedPartnersModel{
    public constructor(){
        this.invitedByCompanyID = '';
        this.invitedBycompanyType = '';
        this.id = '';
        this.name = '';
        this.companySubType = [];
    }
    public invitedByCompanyID!: string;
    public invitedBycompanyType!: string;
    public id!: string;
    public name!: string;
    public companySubType!: number[];
}

export class BrandAssociatedPartnersModel{
    public constructor(){
        this.brands = [];
        this.associatedPartners = [];
    }
    public brands!: BrandRelatedSuppliersModel[];
    public associatedPartners!: AssociatedPartnersModel[];
}

export class SupplierPartnersBrandsModel {
    public ID!: string;
    public suppliers!: OrderCompanyModel[];
    public brands!: BrandRelatedSuppliersModel[];
    public associatedPartners!: AssociatedPartnersModel[];
}