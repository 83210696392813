export class SigninClientModel {
  public Email!: string;
  public Password!: string;
  public Scope!: string;
}

export class ResendInviteRequestModel {
  public Email!: string;
}

export class ResendInviteBRNRequestModel {
  public Email!: string;
  public BRN!: string;
}

export class ResendInviteResponseModel {
  public result!: string;
}