import { Vue, Component, Watch, Prop, Emit } from "vue-property-decorator";
import lodash from "lodash";

// Components
import PendingOrderLine from '@/views/home/components/pendingOrderLine.vue';

// Models
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { DropdownModel } from '@/models/dropdownModel';
import { OrderModel } from '@/models/orderModel';
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";

// Services
import { OrderService } from '@/services/orderService';
import { SupplierService } from '@/services/supplierService';
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { OrderLineModel } from '@/models/orderLineModel';

// Helper
import { DateTimeHelper } from "@/helpers/dateTimeHelper";
import { ActionTypes } from "@/store/actions/actions";

@Component({
    components: { PendingOrderLine }
})
export default class PendingOrderLinesTile extends Vue {

    @Prop()
    private pendingOrders!: OrderModel[];

    @Prop()
    private isLoading!: boolean;

    private suppliersLoading: boolean = false;

    private orders: OrderModel[] = [];

    private combinedOrders: CombinedOrderLineModel[] = [];

    private suppliers: OrderCompanyModel[] = [];

    private orderService: OrderService;

    private supplierService: SupplierService;

    private imgSrc: NodeRequire = require("../../../assets/no-pending-order-lines.png");

    private supplierChanged: boolean = false;

    public constructor() {
        super();
        this.orderService = new OrderService();
        this.supplierService = new SupplierService();
        this.orders = this.pendingOrders;
    }

    private async created(): Promise<void> {
        try {
            this.suppliersLoading = true;
            await this.fetchSuppliers();
        }
        finally {
            this.suppliersLoading = false;
        }
    }

    private async fetchSuppliers(): Promise<void>{
        if (this.$store.getters.suppliers != null && this.$store.getters.suppliers.length > 0 && !this.supplierChanged) {
            this.suppliers = this.$store.getters.suppliers;
        }
        else{
            // const suppliers = await this.supplierService.getSuppliersAsync();
            await this.$store.dispatch(ActionTypes.SET_SUPPLIER_CERTIFICATE);
            this.suppliers = this.$store.getters.suppliers;
        }
    }

    private get pendingCount(): number {
        return this.orders.length;
    }

    private get combinedOrdersData(): CombinedOrderLineModel[] {
        return this.combinedOrders;
    }

    private get supplierDropdownData(): DropdownModel[] {
        const suppliersOrdered = lodash.orderBy(this.suppliers, ['name'], ['asc']);
        return suppliersOrdered.map(s => new DropdownModel(s.id, this.getSupplierDropdownText(s)));
    }

    private getSupplierDropdownText(supplierModel: OrderCompanyModel): string {
        if (supplierModel.isCompliant)
        {
            return `${supplierModel.companyName} (${supplierModel.country})`;
        }

        return `${supplierModel.companyName} (${this.$t('pages.home.pending_invite')})`;
    }

    private getKey(order: CombinedOrderLineModel, index: number): string {
        return `${order.supplierName}-${order.supplierCountry}-${index}`;
    }

    @Emit()
    private ordersConfirmed(order: CombinedOrderLineModel): void {
    }

    @Emit()
    private confirmed(order: CombinedOrderLineModel, confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean): void {
    }

    @Emit()
    private deleteOrders(orderIds: string[]): void {
    }

    @Emit()
    private reloadOrderLines(): void {
    }

    private async suppliersChangedHandler() : Promise<void>{
        try {
            this.suppliersLoading = true;
            this.supplierChanged = true;
            await this.fetchSuppliers();
        }
        finally {
            this.suppliersLoading = false;
        }
    }

    @Watch("pendingOrders", { deep: true })
    private ordersChanged(): void {
        this.orders = this.pendingOrders;
    }
}