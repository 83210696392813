import { ClientStepModel } from "./clientStepModel";

export class ClientStepDropdownModel{

    public constructor (steps: ClientStepModel){
        this.displayName = steps.displayName;
        this.productGroup = steps.productGroup;
        this.sequence = steps.sequence;
        this.showDefault = steps.showDefault;
        this.stepId = steps.stepId;
        this.isChecked = true;
    }
    public productGroup!: number;
    public stepId!: number;
    public displayName!: string;
    public sequence!: number;
    public showDefault?: boolean;
    public isChecked!: boolean;
}