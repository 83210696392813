import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';
import VuePdfApp from 'vue-pdf-app';

// Components
import Watermark from '@/views/compliance/components/watermark.vue';

// Model
import { CertificateModel } from '@/models/certificateModel';

@Component({
  components: { VuePdfApp, Watermark },
})
export default class ViewCertificatesUploaded extends Vue {
  @Prop()
  private documentDetail!: CertificateModel;

  private pdfDocument!: any;
  private isPdfReady: boolean = false;
  private config: any = {
    toolbar: false,
  };

  public constructor() {
    super();
  }

  private async pdfReady(pdfApp: any): Promise<void> {
    const pdfZoom = 'page-fit';
    await pdfApp.pdfViewer.pagesPromise;
    pdfApp.pdfViewer.currentScaleValue = pdfZoom;
    this.pdfDocument = pdfApp;
  }

  private validFromAndToFormatted(date: string): string {
    return moment(date).format('D MMM YYYY');
  }

  @Emit()
  private closePdf(success: boolean): void {
  }
}
