import { FileModel } from "./fileModel";

export class ProductPictureModel extends FileModel {
    public id!: string;
    public shown!: boolean;
    public key!: number;
    public sequence!: number;
    public docUrl!: string;

    public constructor(fileName: string, type: string, content: string, key: number, sequence: number, shown: boolean = true, id: string = "") {
        super(fileName, type, content);
        this.id = id;
        this.shown = shown;
        this.key = key;
        this.sequence = sequence;
        this.docUrl = content;
    }
}