import { debounce } from "ts-debounce";
import { Vue, Component } from "vue-property-decorator";

// Components
import Authpage from "@/components/general/authpage.vue";

// Services
import { MiddlewareService } from '@/services/middlewareService';
import { ResendInviteBRNRequestModel, ResendInviteRequestModel, ResendInviteResponseModel } from "@/models/signinClientModel";

@Component({
  components: { Authpage }
})

export default class Signup extends Vue {

  private middlewareService: MiddlewareService;
  public constructor() {
    super();
    this.middlewareService = new MiddlewareService();
  }

  private isLoading: boolean = false;
  private emailError: boolean = false;
  private emailInUseError: boolean = false;
  private emailAddress: string = "";
  private regNoError: boolean = false;
  private busRegNo: string = "";
  private checkEmailIsValidDebounced: any;
  private showResendInviteBtn: boolean = false;
  private showBRNInput: boolean = false;
  private disableResendInvite: boolean = true;
  private showAssistanceBtn: boolean = false;
  private showAssistanceModal: boolean = false;

  private async created(): Promise<void> {
    this.checkEmailIsValidDebounced = debounce(this.checkEmailIsValid, 120, {maxWait:500, isImmediate: true});
  }

  private async checkEmailIsValid(event: any): Promise<void> {
    this.emailInUseError = false;
    this.showResendInviteBtn = false;
    this.showAssistanceBtn = false;
    const emailAddress = event.target.value;

    if (emailAddress.length < 1) {
      return;
    }

    const matches = emailAddress.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    this.emailError =  (matches == null) ? true : false;
    return;
  }

  private async sendVerificationCode(): Promise<void> {
    if (this.emailAddress.trim() == "" || this.emailError) {
      return;
    }
    this.isLoading = true;
    const otpCode = Math.floor(100000 + Math.random() * 900000);
    localStorage.setItem("verificationcode", otpCode.toString());
    localStorage.setItem("maincontactemail", this.emailAddress);

    const result = await this.middlewareService.sendVerificationCode(this.emailAddress, otpCode.toString());
    if (result.result == "success") {
      this.$router.push({ name: 'SignupOTP', params: { emailAddress: this.emailAddress } });
    } else if (result.result == "Invitaion_link_Exists"){
      this.emailInUseError = true;
      this.showResendInviteBtn = true;
      this.disableResendInvite = false;
    } else if (result.result == "Duplicate_Invitation_link_Exists") {
      this.showBRNInput = true;
      this.showResendInviteBtn = true;
      this.disableResendInvite = true;
    } else {
      this.emailInUseError = true;
    }
    this.isLoading = false;
    return;
  }

  private async resendInviteLink(): Promise<void> {
    this.isLoading = true;
    let response: ResendInviteResponseModel;
    if(this.showBRNInput){
      const resendInviteBRNPayload: ResendInviteBRNRequestModel = {
        Email: this.emailAddress,
        BRN: this.busRegNo
      };
      response = await this.middlewareService.resendInviteLinkWithBRN(resendInviteBRNPayload);
    }
    else{
      const resendInvitePayload: ResendInviteRequestModel = {
        Email: this.emailAddress
      };
      response = await this.middlewareService.resendInviteLink(resendInvitePayload);
    }
    this.isLoading = false;
    if(response.result === "success"){
      this.$router.push({ name: 'SignupSuccess', params: { resendInvite: 'SUCCESS', resendInviteEmail: this.emailAddress } });
    } else if (response.result === "BRN_NOT_EXISTS") {
      this.regNoError = true;
      this.showResendInviteBtn = false;
      this.showAssistanceBtn = true;
    }
    else {
      this.$router.push({ name: 'SignupEmailError', params: { resendInvite: 'FAILED', resendInviteEmail: this.emailAddress } });
    }
  }

  private navigateSignIn(): void {
    this.emailAddress = "";
    this.isLoading = false;
    this.showAssistanceModal = false;
    this.showAssistanceBtn = false;
    this.showBRNInput = false;
    this.showResendInviteBtn = false;
  }
}
