import { debounce } from "ts-debounce";
import { Vue, Component } from "vue-property-decorator";

// Components
import Authpage from "@/components/general/authpage.vue";

// Services
import { MiddlewareService } from '@/services/middlewareService';
import { InvitationService } from '@/services/invitationService';

// Helpers
import { CryptionHelper } from "@/helpers/cryptionHelper";

// Store
import store from '@/store';

// Models
import { SigninClientModel } from "@/models/signinClientModel";
import { UserModel } from "@/models/userModel";
import { CompanyAdminRequestModel, CompanyUserRequestModel } from "@/models/createCompanyUsersModel";

@Component({
  components: { Authpage }
})

export default class SignupOTP extends Vue {

  private middlewareService: MiddlewareService;

  private invitationService: InvitationService;

  private cryptionHelper: CryptionHelper;

  private isLoading: boolean = false;

  private userExists: boolean = false;

  private signinError: boolean = false;

  private passwordPlaceholder: string = "New password";

  private confirmPasswordPlaceholder: string = "Confirm new password";

  private apiError: boolean = false;
  private apiErrorMessage: string = "";

  private firstName: string = "";
  private firstNameError: boolean = false;

  private lastName: string = "";
  private lastNameError: boolean = false;

  private emailAddress: string = "";
  private emailAddressError: boolean = false;

  private password: string = "";
  private passwordError: boolean = false;

  private passwordConfirm: string = "";
  private passwordConfirmError: boolean = false;

  private emailAddressNewUser: string = '';
  private emailAddressNewUserError: boolean = false;

  private oldPasswordNewUser: string = '';
  private oldPasswordNewUserError: boolean = false;

  private passwordNewUser: string = '';
  private passwordNewUserError: boolean = false;

  private passwordConfirmNewUser: string = '';
  private passwordConfirmNewUserError: boolean = false;

  private allowConfirm: boolean = false;

  private checkFieldsDebounced: any;

  private otpCode: string = "";

  private newUser: boolean = false;

  private showNewUserFail: boolean = false;

  private pageLoading: boolean = false;

  private fNameSpecCharErr: boolean = false;

  public constructor() {
    super();
    this.middlewareService = new MiddlewareService();
    this.cryptionHelper = new CryptionHelper();
    this.invitationService = new InvitationService();
  }

  private async created(): Promise<void> {
    this.pageLoading = true;
    if (Object.keys(this.$route.query).length > 0){
      this.newUser = (this.$route.query.newUser && this.$route.query.newUser == 'true') ? true : false;

      if (!this.newUser){
        this.$router.push({ name: 'Signin' });
      }
    }

    this.checkFieldsDebounced = debounce(this.checkFields, 250, { maxWait: 250, isImmediate: true });
    if (this.$route.params.emailAddress !== undefined) {
      this.emailAddress = this.$route.params.emailAddress;
      localStorage.setItem("invitationEmail", this.emailAddress);
    } else {
      const emailAddress = localStorage.getItem("invitationEmail");
      if (emailAddress != null && emailAddress.indexOf("@") > 1) {
        this.emailAddress = emailAddress;
      }
    }
    if (this.emailAddress.indexOf("@") > 1) {
      const result = await this.middlewareService.getUserByEmailForInviteLink(this.emailAddress);
      if (result.result == "User exists") {
        this.userExists = true;
        this.passwordPlaceholder = "Old password";
        this.confirmPasswordPlaceholder = "Confirm old password";
      } else if(result.result == "You have already created your account, please sign in.") {
        localStorage.removeItem("invitationId");
        this.$router.push({
          name: 'Signin',
          params: { accountCreated: '1' },
        });
      } else if(result.result == "Invite link is expired, check email for new invite link.") {
        localStorage.removeItem("invitationId");
        this.$router.push({
          name: 'Signin',
          params: { linkExpired: '1' },
        });
      }
    }
    this.pageLoading = false;
  }

  private async checkEnterKey(event: any): Promise<void> {
    if (event.keyCode == 13) {
      this.createCompanyAdmin();
    }
  }

  private async containsSpecialChars(str: string): Promise<boolean> {
    const specialChars = /[`!@#$%^&*()_+\\=\\[\]{};':"\\|,.<>\\/?~]/;
    return specialChars.test(str);
  }

  private async checkFields(event: any, fieldname: string): Promise<boolean> {
    this.allowConfirm = false;

    if (!this.newUser) {
      if (fieldname === 'firstName') {
        var specials=/[@()[\];:<>, ]/;
        if (event.target.value.length < 2 && event.target.value!=='') {
          this.firstNameError = true;
          return true;
        }else{
          this.firstNameError = false;
        }
        // TTD-4477, for adding validations on firstname
        if (specials.test(event.target.value) && !(event.target.value.length < 2) && event.target.value!=='' && !this.firstNameError) {
          this.fNameSpecCharErr = true;
          return true;
        }else{
          this.fNameSpecCharErr = false;
        }
      }

      if (fieldname === 'lastName') {
        if (event.target.value.length < 2) {
          this.lastNameError = true;
          return true;
        }else{
          this.lastNameError = false;
        }
      }

      if (fieldname === 'emailAddress') {
        const matches = event.target.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        this.emailAddressError = (matches == null) ? true : false;
        if (this.emailAddressError) {
          return true;
        }
      }

      if (fieldname === 'password') {
        const matches = event.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);
        this.passwordError = (matches == null) ? true : false;
        if (this.passwordError) {
          return true;
        }
      }

      if (fieldname === 'passwordConfirm') {
        this.passwordConfirmError = (event.target.value.length < 6) ? true : false;
        if (this.passwordConfirmError) {
          return true;
        }

        this.passwordConfirmError = (event.target.value !== this.password) ? true : false;
        if (this.passwordConfirmError) {
          return true;
        }
      }

      const matches = this.emailAddress.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      this.emailAddressError = (matches == null) ? true : false;

      this.allowConfirm = true;
      if ((this.firstName.length < 2 || this.fNameSpecCharErr) || (this.lastName.length < 2) ||
        (this.password.length < 6) || (this.passwordConfirm.length < 6) || (matches == null) ||
        (this.passwordConfirm !== this.password)) {
        this.allowConfirm = false;
      }

      if (event.keyCode == 13 && this.allowConfirm) {
        this.createCompanyAdmin();
      }
    }
    else {
      if (fieldname === 'emailAddressNewUser') {
        const matches = event.target.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        this.emailAddressNewUserError = (matches == null) ? true : false;
        if (this.emailAddressNewUserError) {
          return true;
        }
      }

      if (fieldname === 'oldPasswordNewUser') {
        this.oldPasswordNewUserError = (event.target.value == null || event.target.value == '') ? true : false;
        if (this.oldPasswordNewUserError) {
          return true;
        }
      }

      if (fieldname === 'passwordNewUser') {
        const matches = event.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);
        this.passwordNewUserError = (matches == null) ? true : false;
        if (this.passwordNewUserError) {
          return true;
        }
      }

      if (fieldname === 'passwordConfirmNewUser') {
        this.passwordConfirmNewUserError = (event.target.value.length < 6) ? true : false;
        if (this.passwordConfirmNewUserError) {
          return true;
        }

        this.passwordConfirmNewUserError = (event.target.value !== this.passwordNewUser) ? true : false;
        if (this.passwordConfirmNewUserError) {
          return true;
        }
      }

      const matches = this.emailAddressNewUser.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      this.emailAddressNewUserError = (matches == null) ? true : false;

      this.allowConfirm = true;
      if ((this.passwordNewUser.length < 6) || (this.passwordConfirmNewUser.length < 6) || (matches == null) || (this.oldPasswordNewUserError) ||
        (this.passwordConfirmNewUser !== this.passwordNewUser)) {
        this.allowConfirm = false;
      }
      if (event.keyCode == 13 && this.allowConfirm) {
        this.createCompanyUser();
      }
    }

    return false;
  }

  private async createCompanyAdmin(): Promise<void> {
    this.isLoading = true;
    const user = new CompanyAdminRequestModel();
    user.Firstname = this.firstName.trim();
    user.Lastname = this.lastName.trim();
    user.Email = this.emailAddress;
    user.Password = this.password;

    try {

      if (!this.userExists) {
        const result = await this.middlewareService.createCompanyAdmin(user);
        if (result.result !== "success") {
          this.isLoading = false;
          this.apiError = true;
          this.apiErrorMessage = result.result;
          return;
        }
      }

      const userProfile = {
        companyId: null,
        companyName: "",
        email: this.emailAddress,
        firstName: this.firstName,
        lastName: this.lastName,
        name: this.firstName + " " + this.lastName,
        companyType: 0
      };

      const client = new SigninClientModel();
      client.Email = this.emailAddress.trim();
      client.Password = this.password.trim();
      const response = await this.middlewareService.signinClient(client);
      const invitaionId: string|null = localStorage.getItem('invitationId');
      switch (response.response_type) {
        case "success":
          store.commit("setUser", userProfile);
          store.commit("setEncryptedPwd", this.cryptionHelper.encryptText(client.Password));
          localStorage.setItem("user", JSON.stringify(userProfile));
          sessionStorage.setItem("access_token", response.access_token);
          localStorage.removeItem("invitationEmail");
          if (invitaionId && invitaionId!==null) {
            const company = await this.invitationService.getClientByInvitationIdAsync(
              invitaionId
            );
            store.commit('setMyAccount', company);
            store.commit("setCompanyType", company.companyType);
          }
          setTimeout(
            (app: any) => {
              app.$router.push({ name: "Home" }).catch(() => { });
            },
            500,
            this
          );
          break;
        default:
          this.signinError = true;
          this.isLoading = false;
          break;
      }
    } catch (error: any) {
      this.isLoading = false;
      if (error.statuscodeBackingField == 500) {
        localStorage.setItem('invitationId', '');
        this.$router.push({
          name: 'Signin',
          params: { accountCreated: '1' },
        });
      }
    }
  }

  private async createCompanyUser(): Promise<void> {
    this.isLoading = true;
    const user = new CompanyUserRequestModel();
    user.Email = this.emailAddressNewUser.replace(/\s/g, '');
    user.TempPassword = this.oldPasswordNewUser.replace(/\s/g, '');
    user.Password = this.passwordNewUser.replace(/\s/g, '');
    user.Scope = process.env.VUE_APP_Api_scope!;

    try{
      const response = await this.middlewareService.setNewUserPassword(user);
      if(response.result === "success"){
        this.$router.push({name: 'LastStepSuccess', query: { newUser: 'true' }});
      } else if(response.result=='fail' && response.message=='User is Blocked') {
        this.$router.push({name: 'SignupRegistrationError', query: { newUser: 'true' }, params: { 'status': 'blocked'}});
      } else if(response.result=='fail' && response.message=='We can’t find your account.') {
        this.$router.push({name: 'SignupRegistrationError', query: { newUser: 'true' }, params: { 'status': 'emailError'}});
      } else if(response.result=='fail' && response.message=='Invalid old password. Please try again with valid old password.') {
        this.$router.push({name: 'SignupRegistrationError', query: { newUser: 'true' }, params: { 'status': 'pwdError'}});
      } else {
        this.showNewUserFail = true;
        this.$router.push({name: 'SignupRegistrationError', query: { newUser: 'true' }, params: { 'status': 'errror'}});
      }
    }
    catch{
      // commit
    }
    finally{
      this.isLoading = false;
    }
  }
}
