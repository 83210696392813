import { Vue, Component } from "vue-property-decorator";
import { UserModel } from '@/models/userModel';
import { ValidationObserver } from "vee-validate";
import MsalFactory from "@/factories/msalFactory";
import { DropdownModel } from "@/models/dropdownModel";
import { InvitationService } from "@/services/invitationService";
import ClickOutside from 'vue-click-outside';

// Services
import { UserService } from '@/services/userService';
import { CountryHelper } from "@/helpers/countryHelper";
import { CompanyModel } from "@/models/companyModel";
import { CardPlugin } from "bootstrap-vue";
import store from "@/store";

@Component({
    directives: { ClickOutside },
})
export default class SiteHeader extends Vue {
    private applicationLogo: NodeRequire = require("../../assets/logo.svg");

    private showMenu: boolean = false;
    private userService: UserService;
    private invitationService: InvitationService;
    private companyDetails: CompanyModel = new CompanyModel();
    private countries: DropdownModel[] = [];
    private countryData: Object = {};
    private showData: Object = {};
    private showUploadModal: boolean = false;
    private isLoading: boolean = false;
    private languages: object[] = [];
    private selectedLang: any = "";
    private selectedLangFlag: string = "";
    private selectedFlag: NodeRequire|string = '';
    private showLanguageMenu: boolean = false;
    private profile: string = '';

    public constructor() {
        super();
        this.userService = new UserService();
        this.invitationService = new InvitationService();
        this.showData = {};
        this.languages = [];
    }

    private async created(): Promise<void> {
        await this.userService.loadUserInfoAsync();
        await this.loadLangDropdown();
        const invitationId = localStorage.getItem("invitationId");
        if (invitationId) {
            if (store.getters.company && store.getters.company !== null) {
                this.companyDetails = store.getters.company;
            } else {
                this.companyDetails = await this.invitationService.getClientByInvitationIdAsync(invitationId);
            }
        }
        this.countries = CountryHelper.getCountryList();
        const url = window.navigator.language;

        const getCountrySelect = sessionStorage.getItem("countrySelect");
        if (getCountrySelect != null) {
            this.showData = JSON.parse(getCountrySelect);
        }
    }

    private get isProfileUploaded(): boolean{
        const user = this.$store.getters.user as UserModel;
        if (user && user.userProfilePics && user.userProfilePics.length>0) {
          this.profile = user.userProfilePics[user.userProfilePics.length-1].docURL;
          return true;
        } else {
          return false;
        }
      }

    private toggleLanguageMenu(): void {
        this.showLanguageMenu = !this.showLanguageMenu;
    }

    private closeMenu(): void {
        if (screen.width > 636) {
          this.showMenu = false;
        }
    }

    private loadLangDropdown() {
        const getCountry = sessionStorage.getItem("country");
        let langs = require('../../locales/langFlag.json');
        langs = JSON.parse(JSON.stringify(langs));
        if (getCountry !== null) {
            this.selectedLang = getCountry;
            const selectedFlagObj = Object.fromEntries(Object.entries(langs.flags).filter(([key]) => key.includes(getCountry)));
            const flag = Object.values(selectedFlagObj)[0];
            const flagPath = this.countryImage(flag);
            this.selectedFlag = flagPath;
        } else {
            this.selectedLang = process.env.VUE_APP_I18N_LOCALE!.toUpperCase();
            const selectedFlagObj = Object.fromEntries(Object.entries(langs.flags).filter(([key]) => key.includes(this.selectedLang)));
            const flag = Object.values(selectedFlagObj)[0];
            const flagPath = this.countryImage(flag);
            this.selectedFlag = flagPath;
        }
        for (const [key, value] of Object.entries(langs.language)) {
            const flag = langs.flags[key];
            this.languages.push({ code: key, language: value, flag: flag });
        }
    }

    private countrySelect(item: any) {
        this.selectedLang = item.code;
        const flagPath = this.countryImage(item.flag);
        this.selectedFlag = flagPath;
        const setCountry = sessionStorage.setItem("country", item.code);
        const getCountry = sessionStorage.getItem("country");
        const countryLanguage = require('../../locales/countryLang.json');
        const country = JSON.parse(JSON.stringify(countryLanguage));
        this.countryData = country;
        let jsonName = null;
        const countries = country;
        Object.entries(country.countries).forEach((entry) => {
            const [key, value] = entry;
            if (key == getCountry) {
                jsonName = value;
            }
        });
        const countryJson = require('../../locales/' + jsonName);
        const countryJsonString = JSON.stringify(countryJson);
        const countrySelect = JSON.parse(countryJsonString);
        const setCountrySelect = sessionStorage.setItem("countrySelect", countryJsonString);
        location.reload();
    }

    private closeUploadModal(): void {
        this.showUploadModal = false;
    }

    private openUploadModal(): void {
        this.showUploadModal = true;
    }

    private get userName(): string {
        const user = this.$store.getters.user as UserModel;
        if (user == null || user.name == null) {
            console.error("No user info found");
            return "-";
        }
        return user.name;
    }

    private get userCompany(): string {
        const user = this.$store.getters.user as UserModel;

        if (this.companyDetails?.companyName != null && this.companyDetails.companyName != "") {
            return this.companyDetails.companyName;
        } else if (user == null || user.companyName == null || user.companyName == "") {
            console.error("No user info found");
            return "-";
        }

        return user.companyName;

    }

    private toggleMenu(): void {
        this.showMenu = !this.showMenu;
    }

    private countryImage(countryCode: any): NodeRequire {
        return require(`../../assets/icons/general/${countryCode}.svg`);
    }

    private logout(): void {
        // MsalFactory.logoutAllAccounts(null);
        localStorage.removeItem("user");
        localStorage.removeItem("invitationId");
        sessionStorage.removeItem("access_token");
        store.commit('setInitialState');
        this.$router.push({ name: "Signin" });
    }
}