import { DashboardApiAuthenticator } from '@/authenticators/dashboardApiAuthenticator';
import { ApplicationConfig } from '@/config';
import { ClientStepModel } from '@/models/clientStepModel';
import { ProductGroupModel } from '@/models/productGroupModel';
import { BaseClient } from './baseClient';

export class ProductGroupClient extends BaseClient {
    private readonly endPoint: string = "api/productgroup";

    public constructor() {
        super(ApplicationConfig.endpoints.dashboardApi, new DashboardApiAuthenticator());
    }

    public async getProductGroupsAsync(): Promise<ProductGroupModel[]> {
        return await this.get(`${this.endPoint}`);
    }

    public async getClientStepsForProductGroupAsync(productGroupId: number): Promise<ClientStepModel[]> {
        return await this.get<ClientStepModel[]>(`${this.endPoint}/${productGroupId}/supplyChainSteps`);
    }
}