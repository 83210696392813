import { IAuthenticator } from "@/authenticators/interfaces/IAuthenticator";
import { NotificationHelper } from "@/helpers/notificationHelper";
import i18n from "@/i18n";
import { HttpError } from "@/models/httpError";

type CRUDOperation = "GET" | "PUT" | "PATCH" | "POST" | "DELETE";

export abstract class BaseClient {
    public constructor(
        private resource: string,
        private authenticator?: IAuthenticator
    ) { }

    public get<T>(query: string): Promise<T> {
        return this.execute<T>(query, "GET");
    }

    public post(query: string, data: any): Promise<Response> {
        return this.execute(query, "POST", data);
    }

    public postWithResponse<T>(query: string, data: any): Promise<T> {
        return this.execute(query, "POST", data);
    }

    public put(query: string, data: any): Promise<Response> {
        return this.execute(query, "PUT", data);
    }

    public putWithResponse<T>(query: string, data: any): Promise<T> {
        return this.execute(query, "PUT", data);
    }

    public patch(query: string, data: any): Promise<Response> {
        return this.execute(query, "PATCH", data);
    }

    protected delete(query: string): Promise<Response> {
        return this.execute(query, "DELETE");
    }

    private async execute<T>(
        query: string,
        method: CRUDOperation,
        data?: any
    ): Promise<T> {
        const url = `${this.resource}/${query}`;
        let request: RequestInit = {
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            method
        };

        if (data) {
            request.body = JSON.stringify(data);
        }

        if (this.authenticator) {
            request = await this.authenticator.authenticateRequestAsync(request);
        }

        const response = await fetch(url, request);
        switch (response.status) {
            case 401:
                document.location.href = "/auth/signin";
                return undefined as unknown as T;
            case 403:
                NotificationHelper.createErrorNotification(i18n.t('errors.unauthorized').toString());
                return undefined as unknown as T;
            case 204:
                return undefined as unknown as T;
            default:
                return await this.getResultFromResponse(response);
        }
    }

    private async getResultFromResponse(response: Response): Promise<any> {
        let result = {
            code:"",
            message: i18n.t("errors.general").toString(),
            parameters:[]
        };

        try {
            result = await response.json();
        }
        catch {
            throw new HttpError(response.status, result.message);
        }

        if (!response.ok) {
            // check whether this is a localized error response or a custom error response
            if ("code" in result) {
                NotificationHelper.createErrorNotification(i18n.t(result.code, result.parameters).toString());
                throw new HttpError(response.status, result.message);
            }
        }
        return result;
    }
}