import { BaseFileUploadResultModel } from './baseFileUploadResultModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { SocialEffortModal } from './socialEffortModal';
import { TransparencyEffortModal } from './transparencyEffortModal';

export class OrdersWithStepsModal  extends BaseFileUploadResultModel{
    public bookmark!: string;
    public count!: number;
    public orders!: OrderLineDisplayModel[];
    public orderLineSteps!: OrderLineStepDisplayModel[];
    public totalQrCodeLive!: number;
    public provideFootprint!: number;
    public totalStyles!: number;
    public socialEfforts!: SocialEffortModal[];
    public transparencyEfforts!: TransparencyEffortModal[];
}