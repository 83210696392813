// Sprint-26 3068
import { Vue, Component, Prop, Emit} from "vue-property-decorator";
import { mapState } from "vuex";
import { v4 as uuidv4 } from 'uuid';

// Services
import { ComplianceService } from "@/services/complianceService";
import { ClientService } from "@/services/clientService";

// Model
import { UserModel } from "@/models/userModel";
import { EsgAddressStatus, EsgSendReminderFacilityModel, EsgSendReminderModel, EsgSendReminderResModel, EsgSocialModel } from "@/models/esgModel";
import { SupplierModel } from "@/models/supplierModel";

@Component({
  components: {},
  computed: mapState(["esgSocial", "user"]),
})
export default class RemindEsg extends Vue {

    @Prop()
    private selectedSupplier!: SupplierModel;

    @Prop()
    private allAddressess!: EsgAddressStatus[];

    @Prop()
    private esgType!: string;

    private complianceService: ComplianceService;

    private clientService: ClientService;

    private esgSocial!: EsgSocialModel[];

    private isConfirmed: boolean = false;

    private isConfirmedSingle: boolean = true;

    private reminderdays: number = 14;

    private user!: UserModel;

    private saveDisabled: boolean = false;

    private reminderCheckStatus: boolean = true;

    private companyName: string = "";

    private firstName: string = "";

    private lastName: string = "";

    private isLoading: boolean = false;

    private redirectUrl: string = "";

    public constructor() {
        super();
        this.complianceService = new ComplianceService();
        this.clientService = new ClientService();
    }

    private async created(): Promise<void> {
        this.companyName = this.user.companyName;
        this.firstName = this.selectedSupplier.contact.firstName;
        this.lastName = this.selectedSupplier.contact.lastName;
        const baseUrl = window.location.origin;
        if(baseUrl.includes("my.tex-tracer.com")){
            this.redirectUrl = "https://dashboard.tex-tracer.com/esg";
        }
        else if(baseUrl.includes("my-demo.tex-tracer.com")){
            this.redirectUrl = "https://dashboard-demo.tex-tracer.com/esg";
        }
        else if(baseUrl.includes("my-acc.tex-tracer.com")){
            this.redirectUrl = "https://dashboard-acc.tex-tracer.com/esg";
        }
        else {
            this.redirectUrl = "https://dashboard-test.tex-tracer.com/esg";
        }
	}

    private get selectedFacilities(): EsgAddressStatus[] {
        return this.allAddressess.filter(add => add.isSelected);
    }

    private async sendReminder(): Promise<void> {
        let mailResult: EsgSendReminderResModel = new EsgSendReminderResModel();
        try{
            this.isLoading = true;
            const data: EsgSendReminderModel = new EsgSendReminderModel();
            data.SupplierFirstName = this.selectedSupplier.contact.firstName;
            data.SupplierLastName = this.selectedSupplier.contact.lastName;
            data.SupplierEmail = this.selectedSupplier.contact.email;
            data.ClientCompanyName = this.user.companyName;
            const reminderEsgData: EsgSendReminderFacilityModel[] = [];
            this.selectedFacilities.forEach(facility => {
                const esgdataWithID: EsgSendReminderFacilityModel = new EsgSendReminderFacilityModel();
                esgdataWithID.name = facility.facilityName;
                esgdataWithID.addressID = facility.addressID;
                esgdataWithID.supplierID = this.selectedSupplier.id;
                const index = this.esgSocial.findIndex(esg => esg.addressID === facility.addressID);
                if(index !== -1){
                    esgdataWithID.ID = this.esgSocial[index].ID;
                } else {
                    esgdataWithID.ID = uuidv4();
                }
                reminderEsgData.push(esgdataWithID);
            });
            data.Facilities.push(...reminderEsgData);
            mailResult = await this.clientService.sendEsgReminderMail(data, this.esgType);
        }
        finally{
            this.isLoading = false;
            this.closeModal(false, mailResult);
        }
    }

    private toggleConfirmed(): void {
        this.isConfirmed = !this.isConfirmed;
        this.isConfirmedSingle = false;
    }

    private toggleConfirmedSingle(): void {
        this.isConfirmedSingle = !this.isConfirmedSingle;
        this.isConfirmed = false;
    }

    private send(): void{
        // this.sendMessage();
    }

    @Emit()
    private closeModal(status: boolean, result: EsgSendReminderResModel): void {}
}
