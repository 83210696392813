import { Vue, Component, Prop, Emit} from "vue-property-decorator";

// Services
import { ComplianceService } from "@/services/complianceService";
import { ClientService } from "@/services/clientService";

// Model
import { DropdownModel } from "@/models/dropdownModel";
import { UserModel } from "@/models/userModel";
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";
import { ComplianceDocModel } from "@/models/complianceDocModel";

@Component({
  components: {}
})
export default class RemindComplianceDocument extends Vue {

    @Prop()
    private documentName!: string;

    @Prop()
    private reminderScpList!: ComplianceDocSentResponseModel[];

    @Prop()
    private document!: ComplianceDocModel;
     //Sprint-24 TTD-4433
    @Prop()
    private isTCTab!: boolean;

    @Prop()
    private selectedTcList!: string[];

    private complianceService: ComplianceService;

    private clientService: ClientService;

    private isConfirmed: boolean = false;

    private isConfirmedSingle: boolean = true;

    private reminderdays: number = 14;

    private user: UserModel = new UserModel;

    private saveDisabled: boolean = false;

    private reminderCheckStatus: boolean = true;

    private companyName: string = "";

    private firstName: string = "";

    private lastName: string = "";

    private isLoading: boolean = false;

    private redirectUrl: string = "";

    public constructor() {
        super();
        this.complianceService = new ComplianceService();
        this.clientService = new ClientService();
    }

    private async created(): Promise<void> {
		const user = this.$store.getters.user as UserModel;
        this.companyName = user.companyName;
        if(this.isTCTab){
            this.firstName = "[Supplier ";
            this.lastName = "name]";
        } else {
            this.firstName = user.firstName;
            this.lastName = user.lastName;
        }
        const baseUrl = window.location.origin;
        if(baseUrl.includes("my.tex-tracer.com")){
            this.redirectUrl = "https://dashboard.tex-tracer.com/compliance";
        }
        else if(baseUrl.includes("my-demo.tex-tracer.com")){
            this.redirectUrl = "https://dashboard-demo.tex-tracer.com/compliance";
        }
        else if(baseUrl.includes("my-acc.tex-tracer.com")){
            this.redirectUrl = "https://dashboard-acc.tex-tracer.com/compliance";
        }
        else {
            this.redirectUrl = "https://dashboard-test.tex-tracer.com/compliance";
        }
	}

    private async sendMessage(): Promise<void> {
        try{
            this.isLoading = true;
            const data: any[] = [];
            this.document.allSCP.forEach(s => {
                if (s.scpSignedStatus === false && (s.scpReadStatus === 'false' || s.scpReadStatus == '')) {
                    const scp = {
                        "ID": s.ID,
                        "complianceDocID": s.complianceDocID,
                        "toCompanyID": s.toCompanyID,
                        "notifyDays": !this.isConfirmed ? 0 :this.reminderdays,
                        "toCompanyName": s.toCompanyName,
                        "toCompanyContactName": s.toCompanyContactName,
                        "toCompanyContactEmail": s.toCompanyContactEmail,
                        "fromCompanyID": s.fromCompanyID,
                        "fromCompanyName": s.fromCompanyName,
                        "documentName": s.documentName
                    }
                    data.push(scp);
                }
            });
            const mail = await this.clientService.sendDocNotificationAsync(data);
            if (this.isConfirmed) {
                const docIds: {}[] = [];
                this.reminderScpList.forEach(item => {
                    docIds.push({'ID': item.ID});
                });
                const archiveDoc = {'complianceDoc': docIds, 'notifyDays': this.reminderdays};
                const result = await this.complianceService.updateNotifyDaysAsync(archiveDoc);
            }
        }
        finally{
            this.isLoading = false;
            this.closeModal();
        }
    }
     //Sprint-24 TTD-4433
    private async sendTCRemainder(): Promise<void> {
        try{
            this.isLoading = true;
            const data: any = {
                "clientCompanyName": this.companyName,
                "supplierIDs":this.selectedTcList
            }
            const mail = await this.clientService.sendTCRemainderAsync(data);
        }
        finally{
            this.isLoading = false;
            this.closeModal();
        }
    }

    private get reminderdaysoptions(): DropdownModel[] {
        const options: DropdownModel[] = [
            { value: 14, text: '14' },
            { value: 28, text: "28" }];
        return options;
    }

    private toggleConfirmed(): void {
        this.isConfirmed = !this.isConfirmed;
        this.isConfirmedSingle = false;
    }

    private toggleConfirmedSingle(): void {
        this.isConfirmedSingle = !this.isConfirmedSingle;
        this.isConfirmed = false;
    }
     //Sprint-24 TTD-4433
    private send(): void{
        if(this.isTCTab){
            this.sendTCRemainder();
        }else{
            this.sendMessage();
        }
    }

    @Emit()
    private closeModal(): void {}
}