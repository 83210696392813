import { ComplianceActivityLog } from "./complianceActivityLog";
import { ComplianceAdditionalInfoModel } from "./complianceAdditionalInfoModel";

export class ComplianceDocSentResponseModel {
    public ID!: string;
    public additionalInfo!: ComplianceAdditionalInfoModel[];
    public brands!: string[];
    public complianceDocID!: string;
    public createdAt!: string;
    public documentName!: string;
    public documentStatus!: string;
    public documentVersion!: number;
    public emailNotificationDate!: string;
    public fromCompanyID!: string;
    public fromCompanyName!: string;
    public notifyDays!: number;
    public originalFileHash!: string;
    public originalFileName!: string;
    public originalFileType!: string;
    public originalDocURL!: string;
    public docURL!: string;
    public scpComment!: ComplianceAdditionalInfoModel[];
    public scpSignedStatus!: boolean;
    public signRequestedTo!: string;
    public t1Scp!: boolean;
    public toCompanyContactEmail!: string;
    public toCompanyContactName!: string;
    public toCompanyID!: string;
    public toCompanyEmail!: string;
    public toCompanyName!: string;
    public updatedAt!: string;
    public validtoDate!: string;
    public scpSignedDate!: string;
    public scpReadDate!: string;
    public activityLogs!: ComplianceActivityLog[];
    public signatureRequested!: string;
    public scpReadStatus!: string;
}
