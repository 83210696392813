import { Guid } from "./guid";

export class MandatoryStepsModel {
    public ID!: string;
    public clientID!: string;
    public clientName!: string;
    public mandatoryStepType!: string;
    public mandatoryStepRule!: MandatoryStepsRuleModel[];
}

export class MandatoryStepsRuleModel {
    public ID!: string;
    public processes!: string[];
    public list!: MandatoryStepsListModel[];
    public createdBy!: string;
    public createdByUser!: string;
    public createdAt!: string;
    public updatedAt!: string;
    public updatedBy!: string;
}

export class MandatoryStepsListModel {
    public type!: string;
    public ID!: string;
    public name!: string;
}

export class MandatoryStepsProcessModel {
    public processID!: string;
    public processSteps!: MandatoryProcessStepIDsModel[];
    public selected!: boolean;
    public stepsExistCount!: number;
}

export class MandatoryProcessStepIDsModel {
    public stepID!: number;
    public stepName!: string;
}

export enum MandatoryStepType {
    BRAND = 'BRAND',
    CONT_PART = 'CONTRACTUAL PARTNER'
}

export enum MandatoryStepsProcess {
    RAW_MATERIAL = 'RAW_MATERIAL',
    RAW_MATERIAL_PROCESSING = 'RAW_MATERIAL_PROCESSING',
    MATERIAL_PROCESSING = 'MATERIAL_PROCESSING',
    WET_PROCESS = 'WET_PROCESS',
    PRODUCT_MANUFACTURING = 'PRODUCT_MANUFACTURING',
    TRIMS_ACCESSORIES = 'TRIMS_ACCESSORIES',
    LOGISTICS = 'LOGISTICS'
}

export class MandatoryStepsResponseModel {
    public mandatoryStepRules!: MandatoryStepRules;
    public success!: boolean;
}

export class MandatoryStepRules {
    public ID!: string;
    public clientID!: string;
    public clientName!: string;
    public createdAt!: string;
    public createdBy!: string;
    public mandatoryStepRule!: MandatoryStepsRuleModel[];
    public mandatoryStepType!: string;
    public updatedAt!: string;
    public updatedBy!: string;

    public constructor(){
        this.ID = Guid.Empty;
        this.clientID = Guid.Empty;
        this.clientName = '';
        this.createdAt = '0001-01-01T00:00:00Z';
        this.createdBy = '';
        this.mandatoryStepRule = [];
        this.mandatoryStepType = '';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.updatedBy = '';
    }
}

export class addNewMandatoryStep {
    public ID!: string;
    public mandatoryStepRuleID!: string;
    public processes!: string[];
    public list!: MandatoryStepsListModel[];
}

export class stepDeleteResponse {
    public success!: boolean;
}

export class MandatoryStepsRuleResponse {
    public success!: boolean;
    public processes!: string[];
}

export class MandatoryStepTypeResponse {
    public success!: boolean;
    public mandatoryStepRuleType!: string;
}
