import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import Multiselect from 'vue-multiselect';
import i18n from "@/i18n";

// Models
import { DropdownModel } from '@/models/dropdownModel';

@Component({
    components: { Multiselect }
})
export default class DropDown extends Vue {
    @Prop()
    private items!: DropdownModel[];

    @Prop()
    private searchPlaceholder!: string;

    private searchText: string = '';

    private get filteredItems(): DropdownModel[] {
        return this.items.filter(f => f.text.toLowerCase().match(this.searchText.toLowerCase()));
    }

    @Emit()
    private valueSelected(value: string | number): void {
        this.searchText = '';
    }

    @Emit()
    private closeDropDown(): void {
    }

}