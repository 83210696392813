// @ts-nocheck

import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const localeVar = sessionStorage.getItem("country");
let langName = null;
const countryLanguage = require('./locales/countryLang.json');
const country= JSON.parse(JSON.stringify(countryLanguage));

Object.entries(country.Language).forEach((entry) => {
  const [key, value] = entry;
  if(key==localeVar){
    langName = value;
  }
});

export default new VueI18n({
  locale: langName || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: langName || process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages()
});
