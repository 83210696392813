import { Component, Vue } from 'vue-property-decorator';

// Components
import Sidebar from '@/components/navigation/sidebar.vue';
import SiteHeader from '@/components/navigation/siteHeader.vue';

@Component({
  components: { Sidebar, SiteHeader }
})

export default class Dashboard extends Vue {
  private collapsedSidebar: boolean = false;

  private collapsesIcon: NodeRequire = require('../assets/icons/navigation/path.svg');

  private async toggleSidebar(): Promise<void> {
    if (this.collapsedSidebar) {
      this.collapsedSidebar = false;
      this.collapsesIcon = await require('../assets/icons/navigation/path.svg');
      localStorage.setItem('collapsedSidebar', 'false');
      this.$store.commit('setCollapsedSidebar', false);
    } else {
      this.collapsedSidebar = true;
      this.collapsesIcon =
        await require('../assets/icons/navigation/path-reverse.svg');
      localStorage.setItem('collapsedSidebar', 'true');
      this.$store.commit('setCollapsedSidebar', true);
    }
  }
}