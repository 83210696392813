/* eslint-disable no-empty */
import { debounce } from "ts-debounce";
import { Vue, Component } from "vue-property-decorator";
import i18n from "@/i18n";

// Components
import Authpage from "@/components/general/authpage.vue";

// Models
import { SigninClientModel } from "@/models/signinClientModel";

// Services
import { UserService } from "@/services/userService";
import { MiddlewareService } from "@/services/middlewareService";

@Component({
  components: { Authpage },
})
export default class Signin extends Vue {
  private userService: UserService;
  private middlewareService: MiddlewareService;
  public constructor() {
    super();
    this.userService = new UserService();
    this.middlewareService = new MiddlewareService();
  }

  private rememberMe: boolean = false;
  private isLoading: boolean = false;
  private inviteError: boolean = false;
  private emailError: boolean = false;
  private pwdError: boolean = false;
  private loginError: boolean = false;
  private loginErrorMesssage: string = "";
  private emailAddress: string = "";
  private password: string = "";
  private checkEmailIsValidDebounced: any;
  private errorType: number = 0;
  private noCompany: string = '';
  private secondUserNotconfirmed: boolean = false;

  private async created(): Promise<void> {
    localStorage.removeItem("user");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("qrcode_client");
    this.checkEmailIsValidDebounced = debounce(this.checkEmailIsValid, 350);
    if (this.$route.params.accountCreated) {
      this.errorType = 1;
    }
    if (this.$route.params.linkExpired) {
      this.errorType = 2;
    }
  }

  private async checkEnterKey(event: any): Promise<void> {
    if (event.keyCode == 13) {
      this.signinClient();
    }
  }

  private async checkEmailIsValid(event: any): Promise<void> {
    const emailAddress = event.target.value;

    if (emailAddress.length < 1) {
      return;
    }

    const matches = emailAddress
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    this.emailError = matches == null ? true : false;
    return;
  }

  private async signinClient(): Promise<void> {
    if (this.emailAddress.trim() == "" || this.emailError) {
      return;
    }
    if (this.password.trim() == "") {
      this.loginErrorMesssage = "Please enter your password";
      this.loginError = true;
      return;
    }
    this.noCompany = '';
    this.isLoading = true;

    const client = new SigninClientModel();
    client.Email = this.emailAddress.trim();
    client.Password = this.password.trim();

    const response = await this.middlewareService.signinClient(client);

    switch (response.response_type) {
      case "success":
        this.loginError = false;
        this.pwdError = false;
        sessionStorage.setItem("access_token", response.access_token);
        try {
          const lInfoExists = await this.userService.loadUserInfoAsync(true);
          if (lInfoExists === 'TRUE') {
            if (this.$route.params.settings) {
              setTimeout( (app: any) => {
                app.$router.push({ name: "Profile", params: { 'admin': '1'}});
              },500, this );
            } else {
              setTimeout( (app: any) => {
                app.$router.push({ name: "Home" });
              },500, this );
            }
          }
          else if(lInfoExists === 'NOUSER'){
            const base_url = window.location.origin;
            let appUrl = '';
            if(base_url.includes('test')){
              appUrl = 'dashboard-test.tex-tracer.com';
            }
            else if(base_url.includes('acc')){
              appUrl = 'dashboard-acc.tex-tracer.com';
            }
            else if(base_url.includes('demo')){
              appUrl = 'dashboard-demo.tex-tracer.com';
            }
            else{
              appUrl = 'dashboard.tex-tracer.com';
            }
            this.noCompany = this.$t("pages.auth.company_not_match", [appUrl]).toString();
            this.isLoading = false;
            this.password = "";
          } else if(lInfoExists === 'BLOCKED') {
            this.isLoading = false;
            this.password = "";
          } else if(lInfoExists === 'REQUESTED'){
            this.secondUserNotconfirmed = true;
          } else {
            this.isLoading = false;
            this.inviteError = true;
            this.password = "";
          }

          if (this.rememberMe) {
            this.saveUserDetailsToLocalStorage();
          } else {
            localStorage.removeItem("rememberUser");
          }
        } catch (e) {
          this.inviteError = true;
          this.isLoading = false;
          this.password = "";
        }
        break;
      default:
        if (response.response_message=="Password Incorrect") {
          this.pwdError = true;
          this.loginErrorMesssage = response.response_message;
          break;
        }
        this.loginError = true;
        this.loginErrorMesssage = response.response_message;
        break;
    }
    this.isLoading = false;

    return;
  }

  private async toggleRememberMe(): Promise<void> {
    this.rememberMe = !this.rememberMe;
    localStorage.setItem(
      "rememberUser",
      JSON.stringify({ save: this.rememberMe })
    );
  }

  private async saveUserDetailsToLocalStorage(): Promise<void> {
    // save user details to local storage when remember me is checked
    localStorage.setItem(
      "rememberUser",
      JSON.stringify({
        email: this.emailAddress,
        save: this.rememberMe,
      })
    );
  }

  private async mounted(): Promise<void> {
    // check if user has checked remember me checkbox
    const rememberUser = JSON.parse(
      localStorage.getItem("rememberUser") || "{}"
    );

    if (rememberUser.save && rememberUser.email) {
      this.emailAddress = rememberUser.email;
      this.rememberMe = rememberUser.save;
    }
  }
}
