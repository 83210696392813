import { DashboardApiAuthenticator } from '@/authenticators/dashboardApiAuthenticator';
import { ApplicationConfig } from '@/config';
import { CompanyModel, companyResponseModel } from '@/models/companyModel';
import { ComplianceDocSentResponseModel } from '@/models/complianceDocSentResponseModel';
import { ProductPictureModel } from '@/models/productPictureModel';
import { sendMailFpModal } from "@/models/sendMailForFootPrintRequestModel";
import { SignedComplianceDocResponse } from '@/models/signedComplianceDocResponse';
import { BaseClient } from './baseClient';
import { TcMailModel, TcMailRequestModel } from '@/models/transactionCertsModel';
import { EsgSendReminderModel, EsgSendReminderResModel } from '@/models/esgModel';

export class ClientClient extends BaseClient {
    private readonly endPoint: string = "api/client";

    public constructor() {
        super(ApplicationConfig.endpoints.dashboardApi, new DashboardApiAuthenticator());
    }

    public async getClientAsync(): Promise<CompanyModel> {
        return await this.get(`${this.endPoint}`);
    }

    public async updateClientAsync(client: CompanyModel): Promise<companyResponseModel> {
        return await this.putWithResponse(`${this.endPoint}/${client.id}`, client);
    }

    public async uploadOrderProductPicturesAsync(clientId: string, orderId: string, orderLineId: string, pictures: ProductPictureModel[]): Promise<ProductPictureModel[]> {
        return await this.postWithResponse<ProductPictureModel[]>(`${this.endPoint}/${clientId}/order/${orderId}/line/${orderLineId}/productpictures`, pictures);
    }

    public async downloadSignedPdf(data: any): Promise<SignedComplianceDocResponse> {
        return await this.postWithResponse(`${this.endPoint}/downloadSignedPdf`, data);
    }

    public async sendComplianceMailNotificationAsync(mailData: ComplianceDocSentResponseModel[]): Promise<Response>{
        return await this.post(`${this.endPoint}/sendComplianceNotification`, mailData);
    }

    public async sendDocNotificationAsync(mailData: any[]): Promise<Response>{
        return await this.post(`${this.endPoint}/SendComplianceReminderManualMail`, mailData);
    }

    public async sendMailForRequestFp(data:sendMailFpModal): Promise<Response>{
        const response =  await this.post(`${this.endPoint}/SendFootprintDataNotificationEmailToAllPartner`, data);
        return response;
    }
    // Sprint-24 TTD-4433
    public async sendTCRemainderAsync(mailData: any[]): Promise<Response>{
        return await this.post(`${this.endPoint}/SendTCDocReminderManualMail`, mailData);
    }
    // Sprint-24 TTD-4484
    public async sendApprovedTCMail( tcMailData: TcMailModel): Promise<Response> {
        return await this.post(`${this.endPoint}/SendTCDocApprovedEmail`, tcMailData);
    }
    // Sprint-24 TTD-4484
    public async sendRejectedTCMail( tcMailData: TcMailModel): Promise<Response> {
        return await this.post(`${this.endPoint}/SendTCDocRejectedEmail`, tcMailData);
    }
    // Sprint-24 TTD-4484
    public async sendTCRequestMail( tcMailData: TcMailRequestModel): Promise<Response> {
        return await this.post(`${this.endPoint}/SendTCRequestedEmailToSCP`, tcMailData);
    }
    // Sprint-26 TTD-3068
    public async sendSocialEsgReminderMail(data: EsgSendReminderModel): Promise<EsgSendReminderResModel> {
        return await this.postWithResponse(`${this.endPoint}/SendSocialESGDataReminderEmail`, data);
    }
    // Sprint-26 TTD-4769
    public async sendEnvEsgReminderMail(data: EsgSendReminderModel): Promise<EsgSendReminderResModel> {
        return await this.postWithResponse(`${this.endPoint}/SendEnvESGDataReminderEmail`, data);
    }
}
