export class ProductQualityReportModel {

    public constructor(report: ProductQualityReportModel){
        this.ID = report.ID;
        this.brand = report.brand;
        this.createdAt = report.createdAt;
        this.description =report.description;
        this.docURL = report.docURL;
        this.fileURL = report.fileURL;
        this.fileName = report.fileName;
        this.fileType = report.fileType;
        this.geoLat = report.geoLat;
        this.geoLong = report.geoLong;
        this.issuer = report.issuer;
        this.owner = report.owner;
        this.referenceIssuer = report.referenceIssuer;
        this.source = report.source;
        this.supplierID = report.supplierID;
        this.supplierName = report.supplierName;
        this.title = report.title;
        this.validFromDate = report.validFromDate;
        this.validToDate = report.validToDate;
        this.isChecked = false;
    }
    public ID!: string;
    public issuer!: string;
    public referenceIssuer!: string;
    public description!: string;
    public fileType!: string;
    public fileName!: string;
    public docURL!: string;
    public fileURL!: string;
    public owner!: string;
    public source!: string;
    public title!: string;
    public geoLat!: number;
    public geoLong!: number;
    public validToDate!: string;
    public validFromDate!: string;
    public createdAt!: string;
    public brand!: string;
    public supplierName!: string;
    public supplierID!: string;
    public isChecked!: boolean;
}