import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import moment from "moment";

// Components
import SupplyChainPartnerLoader from "@/views/supplyChainPartners/components/supplyChainPartnerLoader.vue";

// Models
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";
import { DropdownModel } from "@/models/dropdownModel";
import { ComplianceActivityLog } from "@/models/complianceActivityLog";
import { ComplianceAdditionalInfoModel } from "@/models/complianceAdditionalInfoModel";
import { mapState } from "vuex";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    components: {SupplyChainPartnerLoader},
    computed: mapState(['companyNameList'])
})
export default class PartnerComplianceDetails extends Vue {

    @Prop()
    private documents!: ComplianceDocSentResponseModel[];

    @Prop()
    private loadingDoc!: boolean;

    @Prop()
    private selectedSupplierName!: string;

    private showMessagePopup: boolean = false;

    private currentDoc: ComplianceDocSentResponseModel = new ComplianceDocSentResponseModel();

    private currentActivty: ComplianceActivityLog = new ComplianceActivityLog();

    private reminderdays: number = 14;

    private redirectUrl: string = "";

    private companyNameList!: CompanyNameModel[];

    public constructor() {
        super();
    }

    private async created(): Promise<void> {
        const baseUrl = window.location.origin;
        if(baseUrl.includes("my.tex-tracer.com")){
            this.redirectUrl = "https://dashboard.tex-tracer.com/compliance";
        }
        else if(baseUrl.includes("my-demo.tex-tracer.com")){
            this.redirectUrl = "https://dashboard-demo.tex-tracer.com/compliance";
        }
        else if(baseUrl.includes("my-acc.tex-tracer.com")){
            this.redirectUrl = "https://dashboard-acc.tex-tracer.com/compliance";
        }
        else {
            this.redirectUrl = "https://dashboard-test.tex-tracer.com/compliance";
        }
	}

    private get docs(): ComplianceDocSentResponseModel[]{
        return this.documents;
    }

    private formattedDate(date: string){
        if (date==="9999-01-01T00:00:00Z") {
			return "No deadline"
		}
        return moment(date).format("DD MMM YYYY");
    }

    private showMessage(doc: ComplianceDocSentResponseModel, activity: ComplianceActivityLog): void {
        this.currentDoc = doc;
        this.currentActivty = activity;
        this.showMessagePopup = true;
    }

    private get reminderdaysoptions(): DropdownModel[] {
        const options: DropdownModel[] = [
            { value: 14, text: '14' },
            { value: 28, text: "28" }];
        return options;
    }

    private sortActivityLog(activityList: ComplianceActivityLog[]): ComplianceActivityLog[]{
		const logList = activityList.filter(log => log.mailTextType === 'DOCUMENT_SENT' || log.mailTextType === 'AUTOMATIC_MAIL_TYPE' || log.mailTextType === 'MANUAL_MAIL_TYPE');
        const activityLogList = logList.sort((a,b) => {
			return (a.mailSentDate > b.mailSentDate) ? -1 : ((a.mailSentDate < b.mailSentDate) ? 1 : 0);
		});
		return activityLogList;
	}

    private closeModal(): void{
        this.showMessagePopup = false;
    }

    private partnerName(partnerId: string, currentName: string): string {
        return this.companyNameList.filter(c => c.ID === partnerId).length > 0 ? this.companyNameList.filter(c => c.ID === partnerId)[0].name : currentName;
    }

}
