import { DropdownModel } from "@/models/dropdownModel";

export class FootprintDropdownHelper {
    public static getSpinningMethodList(): DropdownModel[] {
        return [
            { value: "OSW-COMBED", text: "Open end spinning for weaving, combed yarn" },
            { value: "OSW-CARDED", text: "Open end spinning for weaving, carded yarn" },
            { value: "RSW-COMBED", text: "Ring spinning for weaving, combed yarn" },
            { value: "RSW-CARDED", text: "Ring spinning for weaving, carded yarn" },
            { value: "OSK-COMBED", text: "Open end spinning for knitting, combed yarn" },
            { value: "OSK-CARDED", text: "Open end spinning for knitting, carded yarn" },
            { value: "RSK-COMBED", text: "Ring spinning for knitting, combed yarn" },
            { value: "RSK-CARDED", text: "Ring spinning for knitting, carded yarn" },
            { value: "MONO-EXTRUSION", text: "Mono-filament extrusion" },
            { value: "MULTI-EXTRUSION", text: "Multi-filament extrusion" },
            { value: "NO-SPINNING", text: "No spinning" }
        ];
    }

    public static getMaterialList(): DropdownModel[] {
        return [
            // { value: "Wood", text: "Wood" },
            { value: "Card board", text: "Cardboard" },
            // { value: "Metal", text: "Metal" },
            { value: "Paper", text: "Paper" },
            { value: "Recycled paper", text: "Recycled paper" },
            { value: "HDPE", text: "Packaging film (HDPE)" },
            { value: "LDPE", text: "Packaging film (LDPE)" },
            // { value: "Recycled HDPE", text: "Recycled HDPE" },
            // { value: "Recycled LDPE", text: "Recycled LDPE" },
            { value: "P-PP", text: "Pallet PP" },
            { value: "P-wood", text: "Pallet wood" },
            { value: "PACK-F", text: "Packaging film (PP)" }
        ];
    }

    public static getUnitList(): DropdownModel[] {
        return [
            { value: "NM", text: "Nm" },
            { value: "DENIER", text: "Denier" },
            { value: "TEX", text: "Tex" },
            { value: "DTEX", text: "Dtex" },
            { value: "NE1", text: "Ne1" }
        ];
    }

    public static getShadesList(): DropdownModel[] {
        return [
            { value: "Pale", text: "Pale" },
            // { value: "Normal", text: "Normal" },
            { value: "Dark", text: "Dark" },
            { value: "NOT-COLOURED", text: "Not coloured" },
            { value: "WHITE", text: "White" },
            { value: "MEDIUM", text: "Medium" }
        ];
    }

    public static getSourcesList(): DropdownModel[] {
        return [
            { value: "Gas", text: "Gas" },
            { value: "Coal", text: "Coal" },
            { value: "Oil", text: "Oil" }
        ];
    }

    public static getTreatmentList(): DropdownModel[] {
        return [
            { value: "SW", text: "Stone wash" },
            { value: "LT", text: "Laser finishing" },
            { value: "ET", text: "Enzyme wash" },
            { value: "MT", text: "Mechanical treatment (jeans)" },
            { value: "D-SRT", text: "Decatizing" },
            { value: "PT", text: "Screen print (large)" },
            { value: "PD", text: "Screen print (small)" },
            { value: "DYE", text: "Garment dyeing" },
            { value: "CB", text: "Chemical bleaching" },
            { value: "EMB-S-L", text: "Embroidery (small logo)" },
            { value: "EMB-L", text: "Embroidery (large)" },
            { value: "NANO-F", text: "Nano/plasma finishing" },
            { value: "OZONE-T", text: "Ozone treatment" },
            { value: "PEBBLE-W", text: "Pebble wash" },
            { value: "SOFTENER-W", text: "Softener wash" },
            { value: "TRAN-P", text: "Transfer print" },
            { value: "TRAN-H", text: "Transfer print - Heat seal" },
            { value: "IRON", text: "Ironing" }
        ];
    }

    public static getAccessoriesList(): DropdownModel[] {
        return [
            { value: "ZIPPER", text: "Zipper" },
            { value: "BUTTON", text: "Button" },
            { value: "P-BUTTON", text: "Push button" },
            { value: "T-LABEL", text: "Textile label" },
            { value: "EMBROIDERY", text: "Embroidery" },
        ];
    }

    public static getTypesofDye(): DropdownModel[] {
        return [
            { value: "REACTIVE", text: "Reactive" },
            { value: "DIRECT", text: "Direct" },
            { value: "DISPERSE", text: "Disperse" },
            { value: "DISPERSE-REACTIVE", text: "Disperse/ reactive" },
            { value: "DISPERSE-ACID", text: "Disperce/ acid" },
            { value: "DISPERSE-VAT", text: "Disperse/ vat" },
            { value: "VAT", text: "Vat" },
            { value: "SULFUR", text: "Sulfur" },
            { value: "ACID", text: "Acid" },
            { value: "PIGMENT", text: "Pigment" },
            { value: "BASIC", text: "Basic" },
            { value: "OTHER", text: "Other" }
        ];
    }

    public static getTypesofCoating(): DropdownModel[] {
        return [
            { value: "CF-CR", text: "Chemical finishing - Crease resistant" },
            { value: "CF-S", text: "Chemical finishing - Softening" },
            { value: "CF-WSR", text: "Chemical finishing - Water/soil repellent" },
            { value: "CF-OB", text: "Chemical finishing - Optical brightening" },
            { value: "CF-AS", text: "Chemical finishing - Anti-static" },
            { value: "CF-AB", text: "Chemical finishing - Anti-bacterial / anti microbial" },
            { value: "CF-MR", text: "Chemical finishing - Moth repellent" },
            { value: "CF-FR", text: "Chemical finishing - Fire retardancy" },
            { value: "CF-OTHER", text: "Chemical finishing - Other" },
            { value: "C-ACRYLIC", text: "Coating - Acrylic" },
            { value: "C-POLY", text: "Coating - Poly urethane" },
            { value: "C-STYRENE", text: "Coating - Styrene Butadiene" },
            { value: "C-PVC", text: "Coating - PVC" },
            { value: "C-UV-c", text: "Coating - UV-curable coating" },
            { value: "C-SILICONE", text: "Coating - Silicone" },
            { value: "C-OTHER", text: "Coating - Other" },
            { value: "L-PTFE", text: "Lamination - PTFE (e.g. Goretex ®)" },
            { value: "L-POLYESTER", text: "Lamination - Polyester (e.g. Sympatex ®)" },
            { value: "L-VINYL", text: "Lamination - Vinyl" },
            { value: "L-SILICONE", text: "Lamination - Silicone" },
            { value: "L-POLYURETHANE", text: "Lamination - Polyurethane" },
            { value: "L-OTHER", text: "Lamination - Other" },
            { value: "MF-C", text: "Mechanical finishing - Calendering" },
            { value: "MF-E", text: "Mechanical finishing - Embossing" },
            { value: "MF-HSP", text: "Mechanical finishing - Heat setting Polyester" },
            { value: "MF-S", text: "Mechanical finishing - Sanfor (crimp resistant)" },
            { value: "MF-D", text: "Mechanical finishing - decatizing (wool)" },
            { value: "MF-B", text: "Mechanical finishing - Brushing/raising" },
            { value: "MF-OTHER", text: "Mechanical finishing - Other" }
        ];
    }

    public static getProcessForDyeing(): DropdownModel[] {
        return [
            { value: "PAD-STEAM", text: "Pad-steam" },
            // { value: "PAD-BATCH", text: "Pad-batch" },
            { value: "JET", text: "Jet" },
            // { value: "AIRFLOW", text: "Airflow" },
            // { value: "OTHER", text: "Other" },
            { value: "THERMOSOL", text: "Thermosol" },
            { value: "JIGGER", text: "Jigger" },
            { value: "AJ", text: "Air-jet" },
            { value: "WINCH", text: "Winch" },
            { value: "SEMI-C", text: "Semi-continu" }
        ];
    }

    public static getTypesofPrinting(): DropdownModel[] {
        return [
            { value: "Reactive", text: "Reactive" },
            { value: "Direct", text: "Direct" },
            { value: "Disperse", text: "Disperse" },
            { value: "Disperse/ reactive", text: "Disperse/ reactive" },
            { value: "Disperce/ acid", text: "Disperce/ acid" },
            { value: "Disperse/ vat", text: "Disperse/ vat" },
            { value: "Vat", text: "Vat" },
            { value: "Sulfur", text: "Sulfur" },
            { value: "Acid", text: "Acid" },
            { value: "Pigment", text: "Pigment" },
            { value: "Basic", text: "Basic" },
            { value: "Other", text: "Other" }
        ];
    }

    public static getProcessForPrinting(): DropdownModel[] {
        return [
            { value: "Flatscreen", text: "Flat screen printed" },
            { value: "Rotational", text: "Rotational screen printed (AOP)" },
            { value: "Inkjet printing", text: "Ink jet printed" },
            { value: "Transfer printing", text: "Transfer printed" },
            { value: "SPRAYING", text: "Spraying" }
        ];
    }

    public static getProcessForPreTreatment(): DropdownModel[] {
        return [
            { value: "Desizing batch", text: "Desizing batch" },
            { value: "Desizing continuous", text: "Desizing continuous" },
            { value: "Scouring batch", text: "Scouring batch" },
            { value: "Scouring continuous", text: "Scouring continuous" },
            { value: "Bleaching batch", text: "Bleaching batch" },
            { value: "Bleaching continuous", text: "Bleaching continuous" },
            { value: "Drying", text: "Drying" },
            { value: "Mercerizing (cellulose)", text: "Mercerizing (cellulose)" }
        ];
    }

    public static getFiberTypeList(): DropdownModel[] {
        return [
            { value: "ACRYLIC-F", text: "Acrylic Fiber" },
            { value: "ARAMID-F", text: "Aramid Fiber" },
            { value: "COTTON-BAN", text: "Cotton (Bangladesh)" },
            { value: "C-CN", text: "Cotton (China)" },
            { value: "C-IN", text: "Cotton (India)" },
            { value: "C-US", text: "Cotton (USA)" },
            { value: "C-GBL", text: "Cotton (Global)" },
            { value: "OC-CN", text: "Cotton, organic (China)" },
            { value: "OC-IN", text: "Cotton, organic (India)" },
            { value: "OC-TR", text: "Cotton, organic (Turkey)" },
            { value: "OC-US", text: "Cotton, organic (USA)" },
            { value: "OC-GBL", text: "Cotton, organic (Global)" },
            { value: "RC", text: "Cotton, recycled" },
            { value: "ECONYL-YARN", text: "Econyl (yarn)" },
            { value: "EF", text: "Elastane fiber" },
            { value: "HDF", text: "HDPE fibers" },
            { value: "HPF", text: "Hemp fiber" },
            { value: "JTF", text: "Jute fiber" },
            { value: "KLF", text: "Kenal fiber" },
            { value: "LDF", text: "LDPE fibers" },
            { value: "LNF", text: "Linen fiber" },
            { value: "ORF", text: "Other recycled fibers" },
            { value: "PTF", text: "PET fibers" },
            { value: "RPTF", text: "PET fibers, recycled" },
            { value: "PLF", text: "PLA fibers" },
            { value: "P6F", text: "Polyamide 6 fibers" },
            { value: "P66F", text: "Polyamide 6-6 fibers" },
            { value: "PRF", text: "Polyester fiber" },
            { value: "PPF", text: "PP fibers" },
            { value: "PVCF", text: "PVC fibers" },
            { value: "SISAL-BR", text: "Sisal Brasil" },
            { value: "SISAL-TANZ", text: "Sisal Tanzania" },
            { value: "VEF", text: "Viscose fiber" },
            { value: "VISCOSE-A", text: "Viscose Asia" },
            { value: "VISCOSE-AUS", text: "Viscose Austria" },
            { value: "VISCOSE-LYO", text: "Viscose Lyocell" },
            { value: "WL-US", text: "Wool (USA)" },
            { value: "WL-GBL", text: "Wool (Global)" },
            { value: "RDW", text: "Wool, recycled" },
        ];
    }

    public static getModeOfTransportList(): DropdownModel[] {
        return [
            { value: "L-3.5", text: "Lorry <3.5 t" },
            { value: "BG-EU", text: "Barge Europe" },
            { value: "BG-WORLD", text: "Barge rest of the world" },
            { value: "C-SHIP", text: "Container ship" },
            { value: "AIRPLANE", text: "Airplane" },
            { value: "TRAIN-EU", text: "Train Europe" },
            { value: "TRAIN-WORLD", text: "Train rest of the world" },
            { value: "TRUCK-3.5-EURO4-EU", text: "Truck, 3.5 - 7.5 t, Euro 4 Europe " },
            { value: "TRUCK-3.5-EURO4-WORLD", text: "Truck, 3.5 - 7.5 t, Euro 4 rest of the world" },
            { value: "TRUCK-3.5-EURO5-EU", text: "Truck, 3.5 - 7.5 t, Euro 5 Europe " },
            { value: "TRUCK-3.5-EURO5-WORLD", text: "Truck, 3.5 - 7.5 t, Euro 5 rest of the world" },
            { value: "TRUCK-3.5-EURO6-EU", text: "Truck, 3.5 - 7.5 t, Euro 6 Europe" },
            { value: "TRUCK-3.5-EURO6-WORLD", text: "Truck, 3.5 - 7.5 t, Euro 6 rest of the world" },
            { value: "TRUCK-7.5-EURO4-EU", text: "Truck, 7.5 - 16 t, Euro 4 Europe" },
            { value: "TRUCK-7.5-EURO4-WORLD", text: "Truck, 7.5 - 16 t, Euro 4 rest of the world" },
            { value: "TRUCK-7.5-EURO5-EU", text: "Truck, 7.5 - 16 t, Euro 5 Europe" },
            { value: "TRUCK-7.5-EURO5-WORLD", text: "Truck, 7.5 - 16 t, Euro 5 rest of the world" },
            { value: "TRUCK-7.5-EURO6-EU", text: "Truck, 7.5 - 16 t, Euro 6 Europe" },
            { value: "TRUCK-7.5-EURO6-WORLD", text: "Truck, 7.5 - 16 t, Euro 6 rest of the world" },
            { value: "TRUCK-16-EURO4-EU", text: "Truck, 16 - 32 t, Euro 4 Europe" },
            { value: "TRUCK-16-EURO4-WORLD", text: "Truck, 16 - 32 t, Euro 4 rest of the world" },
            { value: "TRUCK-16-EURO5-EU", text: "Truck, 16 - 32 t, Euro 5 Europe" },
            { value: "TRUCK-16-EURO5-WORLD", text: "Truck, 16 - 32 t, Euro 5 rest of the world" },
            { value: "TRUCK-16-EURO6-EU", text: "Truck, 16 - 32 t, Euro 6 Europe" },
            { value: "TRUCK-16-EURO6-WORLD", text: "Truck, 16 - 32 t, Euro 6 rest of the world" },
            { value: "TRUCK-32-EURO4-EU", text: "Truck, > 32 t, Euro 4 Europe" },
            { value: "TRUCK-32-EURO4-WORLD", text: "Truck, > 32 t, Euro 4 rest of the world" },
            { value: "TRUCK-32-EURO5-EU", text: "Truck, > 32 t, Euro 5 Europe" },
            { value: "TRUCK-32-EURO5-WORLD", text: "Truck, > 32 t, Euro 5 rest of the world" },
            { value: "TRUCK-32-EURO6-EU", text: "Truck, > 32 t, Euro 6 Europe" },
            { value: "TRUCK-32-EURO6-WORLD", text: "Truck, > 32 t, Euro 6 rest of the world" },
            { value: "V-EU", text: "Van Europe" },
            { value: "V-WORLD", text: "Van rest of the world" }
        ];
    }

    public static getTransportUnit(): DropdownModel[] {
        return [
            { value: "kM", text: "Km" },
            { value: "MILES", text: "Miles" },
            { value: "N-MILES", text: "Nautic Miles" }
        ];
    }

    public static getFabricType(): DropdownModel[] {
        return[
        { value: "KNITTED", text: "Knitted" },
        { value: "WOVEN", text: "Woven" }
        ];
    }

    public static getFabricWeightUnits(): DropdownModel[]{
        return [
            {value: "GSM", text: "gsm"},
            {value: "RUNNING LENGTH", text: "Running length"}
        ];
    }

    public static getFabricTypes(): DropdownModel[]{
        return [
            {value: "KNITTING-C", text: "Knitting - Circular"},
            {value: "KNITTING-FB", text: "Knitting - Flat bed"},
            {value: "KNITTING-W", text: "Knitting - Warp"},
            {value: "KNITTING-FF", text: "Knitting - Fully fashioned"},
            {value: "WEAVING-P", text: "Weaving - Projectile"},
            {value: "WEAVING-R", text: "Weaving - Rapier"},
            {value: "WEAVING-WJ", text: "Weaving - Water jet"},
            {value: "WEAVING-AJ", text: "Weaving - Air jet"},
            {value: "WEAVING-3D", text: "Weaving - 3D"},
            {value: "NON-WOVEN-NP", text: "Non woven: needle punch"},
            {value: "NON-WOVEN-SB", text: "Non woven: spun bonded"}
        ];
    }

    public static getProductCategory(): DropdownModel[]{
        return [
            {value: "LADIES", text: "Ladies"},
            {value: "MEN", text: "Men"},
            {value: "CHILD", text: "Child"},
            {value: "BABY", text: "Baby"},
            {value: "SPORTS-W", text: "Sports wear"},
            {value: "WORK-W", text: "Work wear"},
            {value: "PROTECTION-W", text: "Protection wear"},
            {value: "BATH-L", text: "Bath linen"},
            {value: "BED-L", text: "Bed linen"},
            {value: "HOME-T", text: "Home textiles"},
            {value: "TABLE-L", text: "Table linen"},
            {value: "INTERIOR-T", text: "Interior textiles"},
            {value: "YARNS-F", text: "Yarns Fabrics"},
            {value: "BAGS", text: "Bags"},
            {value: "ACC", text: "Accessories"},
            {value: "MISC", text: "Miscellaneous"}
        ];
    }

    public static getCuttingWasteType(): DropdownModel[]{
        return [
            {value: "RE-USE", text: "Re-use"},
            {value: "RECYCLING", text: "Recycling"},
            {value: "INCINERATION", text: "Incineration"},
            {value: "LANDFILL", text: "Landfill"},
            {value: "UNKNOWN", text: "Unknown"}
        ];
    }
    
    public static getProductType(category: string): DropdownModel[]{
        let type: DropdownModel[] = [];
        switch (category) {
            case "LADIES": {
                type = [
                    {value: "T-S", text: "T-shirt"},
                    {value: "POLO", text: "Polo"},
                    {value: "BLOUSE-P", text: "Blouse polyester"},
                    {value: "BLOUSE-C", text: "Blouse cotton"},
                    {value: "SWEATER-WA", text: "Sweater Wool/Acrylic"},
                    {value: "FLEECE", text: "Fleece"},
                    {value: "COAT-W", text: "Coat Winter"},
                    {value: "JACKET", text: "Jacket"},
                    {value: "DRESS-W", text: "Dress, woven"},
                    {value: "DRESS-K", text: "Dress, knitted"},
                    {value: "SKIRT", text: "Skirt"},
                    {value: "SHORTS", text: "Shorts"},
                    {value: "PANTS-L", text: "Pants (long)"},
                    {value: "JEANS", text: "Jeans"},
                    {value: "LINGERIE-B", text: "Lingerie brief"},
                    {value: "LINGERY-BRA", text: "Lingery bra"},
                    {value: "SOCKS", text: "Socks"},
                    {value: "PYJAMA", text: "Pyjama"},
                    {value: "THERMIC-U", text: "Thermic underwear"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "MEN": {
                type = [
                    {value: "T-S", text: "T-shirt"},
                    {value: "POLO", text: "Polo"},
                    {value: "BUSINESS-S", text: "Business shirt"},
                    {value: "CASUAL-S", text: "Casual shirt"},
                    {value: "SWEATER", text: "Sweater"},
                    {value: "FLEECE", text: "Fleece"},
                    {value: "COAT-W", text: "Coat Winter"},
                    {value: "JACKET", text: "Jacket"},
                    {value: "PANTS-L", text: "Pants (long)"},
                    {value: "SHORTS", text: "Shorts"},
                    {value: "JEANS", text: "Jeans"},
                    {value: "SOCKS", text: "Socks"},
                    {value: "UNDERPANTS", text: "Underpants"},
                    {value: "BUSINESS-J", text: "Business Jacket"},
                    {value: "BUSINESS-S", text: "Business Suits"},
                    {value: "PYJAMA", text: "Pyjama"},
                    {value: "THERMIC-U", text: "Thermic underwear"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "CHILD": {
                type = [
                    {value: "T-S", text: "T-shirt"},
                    {value: "POLO", text: "Polo"},
                    {value: "SWEATER", text: "Sweater"},
                    {value: "FLEECE", text: "Fleece"},
                    {value: "COAT-W", text: "Coat Winter"},
                    {value: "JACKET", text: "Jacket"},
                    {value: "DRESS", text: "Dress"},
                    {value: "SKIRT", text: "Skirt"},
                    {value: "PANTS-L", text: "Pants (long)"},
                    {value: "SHORT", text: "Short"},
                    {value: "JEANS", text: "Jeans"},
                    {value: "SOCKS", text: "Socks"},
                    {value: "UNDERPANTS", text: "Underpants"},
                    {value: "TIGHTS", text: "Tights"},
                    {value: "PYJAMA", text: "Pyjama"},
                    {value: "THERMIC-U", text: "Thermic underwear"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "BABY": {
                type = [
                    {value: "ROMPER", text: "Romper"},
                    {value: "TEXTILE-D", text: "Textile diaper"},
                    {value: "DIAPER", text: "Diaper pants"},
                    {value: "BURP-C", text: "Burp cloth"},
                    {value: "SLEEPING-B", text: "Sleeping bag"},
                    {value: "PYJAMA", text: "Pyjama"},
                    {value: "JOGGING-T", text: "Jogging trouser"},
                    {value: "BLOUSE", text: "Blouse"},
                    {value: "LEGGING-S", text: "Legging (stretch)"},
                    {value: "T-S", text: "t-shirt"},
                    {value: "POLO-S", text: "Polo-shirt"},
                    {value: "DRESS", text: "Dress"},
                    {value: "VEST", text: "Vest"},
                    {value: "SWEATER", text: "Sweater"},
                    {value: "JACK-W)", text: "Jack (winter)"},
                    {value: "JACKET-S", text: "Jacket (summer)"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "SPORTS-W": {
                type = [
                    {value: "SHIRT-L", text: "Shirt ladies"},
                    {value: "SHIRT-M", text: "Shirt man"},
                    {value: "SHORTS-L", text: "Shorts ladies"},
                    {value: "SHORTS-M", text: "Shorts man"},
                    {value: "TRAINING-S", text: "Training suit"},
                    {value: "LEGGINGS", text: "Leggings"},
                    {value: "BIKINI", text: "Bikini"},
                    {value: "SWIMSUIT", text: "Swimsuit"},
                    {value: "SWIM-S", text: "Swim shorts"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "WORK-W": {
                type = [
                    {value: "WINDSTOPPER", text: "Windstopper"},
                    {value: "OVERALL", text: "Overall"},
                    {value: "WORKER-P", text: "Worker Pants"},
                    {value: "PARKA", text: "Parka"},
                    {value: "WORK-J", text: "Work jacket"},
                    {value: "RAIN-S", text: "Rain suit"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "PROTECTION-W": {
                type = [
                    {value: "JACKET-F-I", text: "Jacket (FR Inherent)"},
                    {value: "JACKET-F-F", text: "Jacket (FR finished)"},
                    {value: "JACKET-H-V", text: "Jacket (Hi-Vis)"},
                    {value: "JACKET-A-S", text: "Jacket (Anti-static)"},
                    {value: "JACKET-C-R", text: "Jacket (Chem. resist)"},
                    {value: "PARKA-F-I", text: "Parka (FR Inherent)"},
                    {value: "PARK-F-F", text: "Parka (FR finished)"},
                    {value: "PARKA-H-V", text: "Parka (Hi-Vis)"},
                    {value: "PARKA-A-S", text: "Parka (Anti-static)"},
                    {value: "PARKA-C-R", text: "Parka (Chem. resist)"},
                    {value: "TSHIRT-F-I", text: "T-shirt (FR Inherent)"},
                    {value: "TSHIRT-F-F", text: "T-shirt (FR finished)"},
                    {value: "TSHIRT-H-V", text: "T-shirt (Hi-Vis)"},
                    {value: "TSHIRT-A-S", text: "T-shirt (Anti-static)"},
                    {value: "TSHIRT-C-R", text: "T-shirt (Chem. resist)"},
                    {value: "POLOSHIRT-F-I", text: "Polo shirt (FR Inherent)"},
                    {value: "POLOSHIRT-F-F", text: "Polo shirt (FR finished)"},
                    {value: "POLOSHIRT-H-V", text: "Polo shirt (Hi-Vis)"},
                    {value: "POLOSHIRT-A-S", text: "Polo shirt (Anti-static)"},
                    {value: "POLOSHIRT-C-R", text: "Polo shirt (Chem. resist)"},
                    {value: "SWEATER-F-I", text: "Sweater (FR Inherent)"},
                    {value: "SWEATER-H-V", text: "Sweater (Hi-Vis)"},
                    {value: "SWEATER-A-S", text: "Sweater (Anti-static)"},
                    {value: "SWEATER-C-R", text: "Sweater (Chem. resist)"},
                    {value: "SOFTSHELL-F-I", text: "Softshell (FR Inherent)"},
                    {value: "SOFTSHELL-H-V", text: "Softshell (Hi-Vis)"},
                    {value: "SOFTSHELL-A-S", text: "Softshell (Anti-static)"},
                    {value: "SOFTSHELL-C-R", text: "Softshell (Chem. resist)"},
                    {value: "FLEECEVEST-F-I", text: "Fleece vest (FR Inherent)"},
                    {value: "FLEECEVEST-H-V", text: "Fleece vest (Hi-Vis)"},
                    {value: "FLEECEVEST-A-S", text: "Fleece vest (Anti-static)"},
                    {value: "FLEECEVEST-C-R", text: "Fleece vest (Chem. resist)"},
                    {value: "TROUSER-F-I", text: "Trouser (FR Inherent)"},
                    {value: "TROUSER-F-F", text: "Trouser (FR finished)"},
                    {value: "TROUSER-H-V", text: "Trouser (Hi-Vis)"},
                    {value: "TROUSER-A-S", text: "Trouser (Anti-static)"},
                    {value: "TROUSER-C-R", text: "Trouser (Chem. resist)"},
                    {value: "OVERALL-F-I", text: "Overall (FR Inherent)"},
                    {value: "OVERALL-F-F", text: "Overall (FR finished)"},
                    {value: "OVERALL-H-V", text: "Overall (Hi-Vis)"},
                    {value: "OVERALL-A-S", text: "Overall (Anti-static)"},
                    {value: "OVERALL-C-R", text: "Overall (Chem. resist)"},
                    {value: "AMOVERALL-F-I", text: "Am. Overall (FR Inherent)"},
                    {value: "AMOVERALL-F-F", text: "Am. Overall (FR finished)"},
                    {value: "AMOVERALL-H-V", text: "Am. Overall (Hi-Vis)"},
                    {value: "AMOVERALL-A-S", text: "Am. Overall (Anti-static)"},
                    {value: "AMOVERALL-C-R", text: "Am. Overall (Chem. resist)"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "BATH-L": {
                type = [
                    {value: "TOWEL", text: "Towel"},
                    {value: "BATH-S", text: "Bath sheet"},
                    {value: "WASH-C", text: "Wash cloth"},
                    {value: "BATH-R", text: "Bath Robe"},
                    {value: "BATH-M", text: "Bath Mat"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "BED-L": {
                type = [
                    {value: "LADIES", text: "Pillow case"},
                    {value: "BOTTOM-S", text: "Bottom sheet"},
                    {value: "BOTTOM-S-D", text: "Bottom sheet double"},
                    {value: "TOP-S", text: "Top sheet"},
                    {value: "TOP-S-D", text: "Top sheet double"},
                    {value: "DUVET-C", text: "Duvet cover"},
                    {value: "DUVET-C-D", text: "Duvet cover double"},
                    {value: "BED-R", text: "Bed runner"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "HOME-T": {
                type = [
                    {value: "KITCHEN-T", text: "Kitchen towel"},
                    {value: "DISH-C", text: "Dish cloth"},
                    {value: "MICROFIBER-C", text: "Microfiber cloth"},
                    {value: "CUSHION-C", text: "Cushion cover"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "TABLE-L": {
                type = [
                    {value: "Napkin", text: "Napkin"},
                    {value: "TABLE-C", text: "Table cloth"},
                    {value: "TABLE-R", text: "Table runner"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "INTERIOR-T": {
                type = [
                    {value: "CURTAINS-M2", text: "Curtains (m2)"},
                    {value: "DRAPES", text: "Drapes"},
                    {value: "UPHOLSTERY-F", text: "Upholstery fabrics"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "YARNS-F": {
                type = [
                    {value: "KNITTING-YARN-C", text: "Knitting yarn cotton"},
                    {value: "KNITTING-YARN-W", text: "Knitting yarn wool"},
                    {value: "KNITTING-YARN-A", text: "Knitting yarn acrylic"},
                    {value: "SEWING-YARN-C", text: "Sewing yarn (cotton)"},
                    {value: "SEWING-YARN-P", text: "Sewing yarn (polyester)"},
                    {value: "EMBROIDERY-Y", text: "Embroidery yarn"},
                    {value: "DENIM", text: "Denim"},
                    {value: "FLEECE", text: "Fleece"},
                    {value: "COTTON-W", text: "Cotton - white"},
                    {value: "COTTON-C", text: "Cotton - coloured"},
                    {value: "POLYESTER-W", text: "Polyester - white"},
                    {value: "POLYESTER-C", text: "Polyester - coloured"},
                    {value: "FELT-W", text: "Felt - wool"},
                    {value: "FELT-P", text: "Felt - polyester"},
                    {value: "FABRIC-WOVEN", text: "Fabric woven (general)"},
                    {value: "FABRIC-KNITTED", text: "Fabric knitted (general)"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            case "BAGS": {
                type = [
                    {value: "GYMSAC", text: "Gymsac"},
                    {value: "STUFF", text: "Stuff bag"},
                    {value: "MESH", text: "Mesh Bag"},
                    {value: "RUCKSACK", text: "Rucksack"},
                    {value: "TOTE", text: "Tote Bag"},
                    {value: "COTTON", text: "Bag (Cotton Bag)"}
                ];
            break;
            }
            case "ACC": {
                type = [
                    {value: "POUCH", text: "Pouch"},
                    {value: "TRUG", text: "Trug"}
                ];
            break;
            }
            case "MISC": {
                type = [
                    {value: "OVEN-GLOVE", text: "Oven glove"},
                    {value: "POTHOLDER", text: "Potholder"},
                    {value: "APRON", text: "Apron"},
                    {value: "SHAWL", text: "Shawl"},
                    {value: "HAT-F", text: "Hat (fleece)"},
                    {value: "HAT-K", text: "Hat (knitted)"},
                    {value: "EARMUFFS", text: "Earmuffs"},
                    {value: "BASE-BALL-C", text: "Base-ball cap"},
                    {value: "C", text: "Cap"},
                    {value: "UNSPECIFIED", text: "Unspecified"}
                ];
            break;
            }
            default: {
                type = [
                    {value: "d", text: "Select product category", disabled: true}
                ];
            break;
            }
        }
        return type;
    }
}