
export class TopBrandModelSteps {
    public constructor(
      public stepLabel: string,
      public stepDesc: string,
      public stepLinkText: string,
      public stepLinkKeyword: string,
      public sequence: number,
      public isDeleted: boolean
    )
    {}
}