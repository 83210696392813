import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { Guid } from "@/models/guid";
import lodash from "lodash";

@Component
export default class Steps extends Vue {

    @Prop()
    private steps!: OrderLineStepDisplayModel[];

    @Prop()
    private lineId!: string;

    @Prop()
    private showInfo!: boolean;

    private selectedStep: OrderLineStepDisplayModel = new OrderLineStepDisplayModel();

    private parentStepId: string = '';

    private selectedParallelStep: OrderLineStepDisplayModel = new OrderLineStepDisplayModel();

    private parallelChainIndex: number = -1;

    private mounted(): void {
        if (this.showInfo) {
            this.calculateDistance(true);
        }
    }

    private get getSteps(): OrderLineStepDisplayModel[] {
        const all: OrderLineStepDisplayModel[] = lodash.cloneDeep(this.steps); 
        all.sort((step1, step2) =>{
            return step1.sequence - step2.sequence;
        });
        all.forEach(step => {
            if (step.parallelSupplyChain0 && step.parallelSupplyChain0.length > 0) {
                step.parallelSupplyChain0.sort((step1, step2) =>{
                    return step1.sequence - step2.sequence;
                });
            }
        })
        all.forEach(step => {
            if (step.parallelSupplyChain1 && step.parallelSupplyChain1.length > 0) {
                step.parallelSupplyChain1.sort((step1, step2) =>{
                    return step1.sequence - step2.sequence;
                });
            }
        })
        all.forEach(step => {
            if (step.parallelSupplyChain2 && step.parallelSupplyChain2.length > 0) {
                step.parallelSupplyChain2.sort((step1, step2) =>{
                    return step1.sequence - step2.sequence;
                });
            }
        })
        all.forEach(step => {
            if (step.parallelSupplyChain3 && step.parallelSupplyChain3.length > 0) {
                step.parallelSupplyChain3.sort((step1, step2) =>{
                    return step1.sequence - step2.sequence;
                });
            }
        })
        all.forEach(step => {
            if (step.parallelSupplyChain4 && step.parallelSupplyChain4.length > 0) {
                step.parallelSupplyChain4.sort((step1, step2) =>{
                    return step1.sequence - step2.sequence;
                });
            }
        })
        return all;
    }

    private calculateDistance(onRender: boolean): void {
        if (this.showInfo) {
            setTimeout(async (app: any) => {
                const leftPoint = document.getElementById('scrollContent-'+this.lineId)!.getBoundingClientRect();
                if (document.getElementById('leftest-title-'+this.lineId) && document.getElementById('leftest-title-'+this.lineId)!.getBoundingClientRect().x < leftPoint.x) {
                    let padding = 0;
                    if (onRender) {
                        const required = leftPoint.x - document.getElementById('leftest-title-'+this.lineId)!.getBoundingClientRect().x;
                        padding = required;
                        const idname = 'hideborder-'+this.lineId;
                        const line = document.getElementById(idname) as HTMLElement;
                        line.style.left = '-'+padding+'px';
                        line.style.width = padding+20+'px';
                    } else {
                        padding = 200;
                    }
                    const outerElement = document.getElementById('scrollContent-'+this.lineId);
                    outerElement!.style.paddingLeft = padding+'px';
                }
            }, 250, this);
        } else {
            const outerElement = document.getElementById('scrollContent-'+this.lineId);
            outerElement!.style.paddingLeft = '0px';
        }

    }

    private selectStep(step: OrderLineStepDisplayModel): void {
        this.selectedStep = step;
        this.parentStepId = '';
        this.selectedParallelStep = new OrderLineStepDisplayModel();
        this.parallelChainIndex = -1;
        this.stepSelected(this.selectedStep);
    }

    private selectParallelStep(parentStepId: string, step: OrderLineStepDisplayModel, parallelChainIndex: number): void {
        this.selectedStep = new OrderLineStepDisplayModel();
        this.parentStepId = parentStepId;
        this.selectedParallelStep = step;
        this.parallelChainIndex = parallelChainIndex;
        this.stepSelected(step);
    }

    private swipeLeft(lineId: string): void {
        const id = 'scrollContent-'+lineId;
        const contentToScroll = document.getElementById(
            id
          ) as HTMLInputElement;
        contentToScroll.scrollLeft -= 200;
    }

    private removeLineOnScroll(event: any) {
        const id = 'scrollContent-'+this.lineId;
        const scrollDiv = document.getElementById(id) as HTMLInputElement;
        if (event.currentTarget.scrollTop > 0) {
            scrollDiv.style.backgroundPositionY = 45-event.currentTarget.scrollTop+'px';
        } else {
            scrollDiv.style.backgroundPositionY = 45+event.currentTarget.scrollTop+'px';
        }
    }

    private swipeRight(lineId: string): void {
        const id = 'scrollContent-'+lineId;
        const contentToScroll = document.getElementById(
            id
          ) as HTMLInputElement;
        contentToScroll.scrollLeft += 100;
    }

    private stepActive(step: OrderLineStepDisplayModel): boolean {
        return this.selectedStep != null && step.id !== Guid.Empty ? this.selectedStep.id === step.id : this.selectedStep.stepId === step.stepId;
    }

    private parallelStepActive(parentStepId: string, step: OrderLineStepDisplayModel, parallelChainIndex: number): boolean {
        return this.selectedParallelStep != null && this.parentStepId === parentStepId && step.id !== Guid.Empty ? this.selectedParallelStep.id === step.id : this.selectedParallelStep.stepId === step.stepId;
    }
    private displayName(stepId: number): string{
        return this.$t(`enums.steps.${stepId}`).toString();
    }

    @Emit()
    private stepSelected(step: OrderLineStepDisplayModel): void { }

    @Watch('showInfo')
    private async onShowInfoChanged(showInfo: boolean): Promise<void> {
        this.calculateDistance(true);
    }

}