import { Vue, Component, Emit } from "vue-property-decorator";
import ClickOutside from 'vue-click-outside';
import { debounce } from "ts-debounce";
import i18n from "@/i18n";
import _ from "lodash";

// Components
import Terms from "@/views/auth/signup/terms.vue";
import Policy from "@/views/auth/signup/policy.vue";
import Authpage from "@/components/general/authpage.vue";
import DropDownEditable from "@/components/general/drop-down-editable/dropDownEditable.vue";

// Models
import { CreateClientModel } from "@/models/createClientModel";

// Services
import { MiddlewareService } from '@/services/middlewareService';
import { CountryStateCityService } from '@/services/countryStateCityService';
import { GeolocationService } from '@/services/geolocationService';

// Helpers
import { DropdownModel } from "@/models/dropdownModel";
import { CountryHelper } from "@/helpers/countryHelper";

@Component({
  components: { Authpage, DropDownEditable, "terms-popup": Terms, "policy-popup": Policy },
  directives: { ClickOutside }
})

export default class SignupForm extends Vue {

  private middlewareService: MiddlewareService;
  private cscService: CountryStateCityService;
  private geolocationService: GeolocationService;

  public constructor() {
    super();
    this.middlewareService = new MiddlewareService();
    this.cscService = new CountryStateCityService();
    this.geolocationService = new GeolocationService();
  }

  private isLoading: boolean = false;

  private companyTypeList: any = i18n.t("dropdown.client_company_type");

  private mainContactFirstName: string = "";
  private mainContactFirstNameError: boolean = false;

  private mainContactLastName: string = "";
  private mainContactLastNameError: boolean = false;

  private mainContactPhone: string = "";
  private mainContactPhoneError: boolean = false;

  private companyName: string = "";
  private companyNameError: boolean = false;

  private companyRegistrationNumber: string = "";
  private companyRegistrationNumberError: boolean = false;

  private companyType: string = "";
  private companyTypeID: number = 0;
  private companyTypeError: boolean = false;

  private companyWebsite: string = "";
  private companyWebsiteError: boolean = false;

  private city: string = "";
  private cityError: boolean = false;

  private state: string = "";
  private stateError: boolean = false;

  private country: string = "";
  private countryCode: string = "";
  private countryError: boolean = false;

  private allowSignup: boolean = false;
  private acceptedTerms: boolean = false;
  private acceptedPolicy: boolean = false;

  private companyDropdownShow: boolean = false;

  private countriesDropdown: DropdownModel[] = [];
  private statesDropdown: DropdownModel[] = [];
  private citiesDropdown: DropdownModel[] = [];

  private countryDropdownShow: boolean = false;

  private showModalTerms: boolean = false;
  private showModalPolicy: boolean = false;

  private searchCountryDebounced: any;
  private searchCompanyTypeDebounced: any;

  private mainContactPhoneErrorMsg: string = "";
  private checkFieldsDebounced: any;

  private async created(): Promise<void> {
    this.countriesDropdown = [];
    this.countriesDropdown = _.orderBy(CountryHelper.getCountryList(), ['text'], ['asc']);
    this.acceptedTerms = false;
    this.acceptedPolicy = false;
    this.searchCompanyTypeDebounced = debounce(this.searchCompanyType, 250, { isImmediate: true });
    this.checkFieldsDebounced = debounce(this.validateField, 250, { maxWait: 250, isImmediate: true });
  }

  private scrollCompanyTypeFocus: any = null;
  private async searchCompanyType(event: any): Promise<void> {
    const filteredCompanyTypeList = this.companyTypeList.filter((companytype: { value: string; }) => companytype.value.toLowerCase().includes(this.companyType.toLowerCase()));

    if (!this.companyDropdownShow && (event.keyCode == 38 || event.keyCode == 40)) {
      this.companyDropdownShow = true;
    }

    if (this.companyDropdownShow && (event.keyCode == 38 || event.keyCode == 40 || event.keyCode == 13)) {
      switch (event.keyCode) {
        case 38:
          if (this.scrollCompanyTypeFocus === null) {
            this.scrollCompanyTypeFocus = 0;
          } else if (this.scrollCompanyTypeFocus > 0) {
            this.scrollCompanyTypeFocus--;
          }
          break;
        case 40:
          if (this.scrollCompanyTypeFocus === null) {
            this.scrollCompanyTypeFocus = 0;
          } else if (this.scrollCompanyTypeFocus < filteredCompanyTypeList.length - 1) {
            this.scrollCompanyTypeFocus++;
          }
          break;
        case 13:
          if (this.scrollCompanyTypeFocus !== null) {
            this.companyType = filteredCompanyTypeList[this.scrollCompanyTypeFocus].value.toString();
            this.companyTypeID = parseInt(filteredCompanyTypeList[this.scrollCompanyTypeFocus].key.toString());
            this.companyDropdownShow = false;
          }
          this.validateField(event, 'companyType');
          break;
      }
      if (this.scrollCompanyTypeFocus !== null) {
        setTimeout((app: any) => {
          const listItem = app.$refs['scrollCompanyTypeFocus' + app.scrollCompanyTypeFocus] as Vue;
          Object.entries(listItem).forEach(item => {
            item[1].scrollIntoView({ behavior: "smooth", block: "center" });
          });
        }, 150, this);
      }
      return;
    }

    if (this.companyType.length > 0) {
      this.companyDropdownShow = true;
    } else {
      this.companyDropdownShow = false;
    }
  }

  private async hideCompanyDropdown(): Promise<void> {
    if (this.companyDropdownShow) {
      this.validateField(event, 'companyType');
    }
    this.companyDropdownShow = false;
  }

  private scrollCountryFocus: any = null;

  @Emit()
  private closeModalTerms(): void {
    this.showModalTerms = false;
  }

  private updateTerms(acceptedTerms: boolean): void {
    this.acceptedTerms = acceptedTerms;
    this.showModalTerms = false;
  }

  private updatePolicy(acceptedPolicy: boolean): void {
    this.acceptedPolicy = acceptedPolicy;
    this.showModalPolicy = false;
  }

  private async validateField(event: any, fieldname: string): Promise<void> {
    const cityDropdown = this.$refs.cityDropdown as any;
    const stateDropdown = this.$refs.stateDropdown as any;
    let matches;

    switch (fieldname) {
      case "mainContactFirstName":
        this.mainContactFirstNameError = (this.mainContactFirstName.length < 2) ? true : false;
        break;
      case "mainContactLastName":
        this.mainContactLastNameError = (this.mainContactLastName.length < 2) ? true : false;
        break;
      case "mainContactPhone":
        matches = this.mainContactPhone.match(/^[+]?\d+$/g);
        if (matches !== null && this.mainContactPhone.length >= 10 && this.mainContactPhone.length <= 20) {
          this.mainContactPhoneError = false;
        }
        else {
          if (this.mainContactPhone.length > 20) {
            this.mainContactPhoneErrorMsg = "This field may not contain more than 20 characters";
          }
          else {
            this.mainContactPhoneErrorMsg = "Invalid phone number";
          }
          this.mainContactPhoneError = true;
        }
        break;
      case "companyName":
        this.companyNameError = (this.companyName.length < 3) ? true : false;
        break;

      case "companyRegistrationNumber":
        this.companyRegistrationNumberError = (this.companyRegistrationNumber.length < 3) ? true : false;
        break;

      case "companyType":
        this.companyTypeError = (this.companyTypeID == 0) ? true : false;
        break;

      case "companyWebsite":
        this.companyWebsiteError = (this.companyWebsite.length > 0 && this.companyWebsite.length < 6) ? true : false;
        break;

      case "country":
        this.country = "";
        this.countryError = (event.fieldKey.length < 2) ? true : false;

        this.statesDropdown = [{ value: '', text: i18n.t('pages.invitation.loading').toString() }];
        stateDropdown.clearValues();
        this.state = "";

        this.citiesDropdown = [];
        cityDropdown.clearValues();
        this.city = "";

        if (!this.countryError) {
          const countriesList = await this.cscService.getAllCountries();
          const country = countriesList.find((x: { iso2: any; }) => x.iso2 == event.fieldKey);

          if (country !== undefined) {
            this.country = event.fieldKey;
            const states = await this.cscService.getStatesOfCountry(this.country);
            this.statesDropdown = [];
            if (states !== undefined) {
              states.forEach((state: { iso2: string; name: string; }) => {
                this.statesDropdown.push({ value: state.iso2, text: state.name });
              });
              this.statesDropdown = _.orderBy(this.statesDropdown, ['text'], ['asc']);
            }
            if (!states || states.length == 0) {
              this.statesDropdown = [{ value: '', text: i18n.t('errors.no_records_found').toString() }];
            }
          } else {
            this.statesDropdown = [];
          }
        } else {
          this.statesDropdown = [];
        }
        break;

      case "state":
        this.citiesDropdown = [{ value: '', text: i18n.t('pages.invitation.loading').toString() }];
        cityDropdown.clearValues();
        this.city = "";

        this.state = "";
        this.stateError = (event.fieldValue.length < 2 || event.fieldValue == i18n.t('errors.no_records_found').toString() || event.fieldValue == i18n.t('pages.invitation.loading').toString()) ? true : false;

        if (!this.stateError) {
          this.state = event.fieldValue;
          if (event.fieldKey && event.fieldKey.length > 0) {
            const cities = await this.cscService.getCitiesOfState(this.country, event.fieldKey);
            this.citiesDropdown = [];
            if (cities !== undefined) {
              cities.forEach((city: { id: any; name: any; }) => {
                this.citiesDropdown.push({ value: city.id, text: city.name });
              });
              this.citiesDropdown = _.orderBy(this.citiesDropdown, ['text'], ['asc']);
            }
          } else {
            this.citiesDropdown = [];
          }
        } else {
          this.citiesDropdown = [];
        }
        break;

      case "city":
        this.city = "";
        this.cityError = (event.fieldValue.length < 2 || event.fieldValue == i18n.t('errors.no_records_found').toString() || event.fieldValue == i18n.t('pages.invitation.loading').toString()) ? true : false;
        if (!this.cityError) {
          this.city = event.fieldValue;
        }
        break;

      default:
        break;
    }
    this.checkSignupAllowed();
  }

  private checkSignupAllowed(): boolean {
    this.allowSignup = true;
    if (this.mainContactFirstNameError || (this.mainContactFirstName.length < 2) || this.mainContactLastNameError || (this.mainContactLastName.length < 2) || this.mainContactPhone === "" || this.mainContactPhoneError || (this.companyName.length < 3) || this.companyNameError || (this.companyRegistrationNumber.length < 3) || this.companyRegistrationNumberError || (this.companyTypeID == 0) || this.companyTypeError || this.companyWebsiteError || (this.city.length < 3) || this.cityError || (this.state.length < 3) || this.stateError || (this.country.length < 1) || this.countryError) {
      this.allowSignup = false;
    }
    return this.allowSignup;
  }

  private async createClient(): Promise<void> {
    this.isLoading = true;
    const client = new CreateClientModel();
    client.CompanyName = this.companyName.trim();
    client.CompanyType = this.companyTypeID;
    client.CompanyWebsite = this.companyWebsite.trim();
    client.CompanyRegistrationNumber = this.companyRegistrationNumber.trim();

    const maincontactemail = localStorage.getItem("maincontactemail");
    client.MainContactEmailAddress = (maincontactemail !== null && maincontactemail !== undefined) ? maincontactemail : "";
    client.City = this.city.trim();
    client.State = this.state.trim();
    client.Country = this.country.trim();
    client.MainContactFirstName = this.mainContactFirstName.trim();
    client.MainContactLastName = this.mainContactLastName.trim();
    client.MainContactPhone = this.mainContactPhone.trim();

    if (maincontactemail == null || maincontactemail == undefined) {
      this.isLoading = false;
      return;
    }

    try {
      const result = await this.middlewareService.createClient(client);
      if (result.result == "success") {
        this.$router.push({ name: 'SignupSuccess' });
      } else {
        this.$router.push({ name: 'SignupRegistrationError' });
      }
    } catch (error) {
      this.isLoading = false;
    }
  }
}
