import { TopBrandModel } from './topBrandModel';
import { UserProfileModel } from './userProfileModel';

export class UserModel {
    public name!: string;
    public firstName!: string;
    public lastName!: string;
    public companyName!: string;
    public companyId!: string;
    public companyType!: number;
    public email!: string;
    public status!: string;
    public userProfilePics!: UserProfileModel[];
    public isAdmin!: string;
    public isBlocked!: string;
    public isT1Scp!: boolean;
    public role!: string | null;
    public userId!: string;
    public userType!: string;
    public isPlatformAdmin!: boolean;
    public topBrands!: TopBrandModel[];
    public phone!: string;
    public invitedByCompanyEmailID!: string;
    public invitedByCompanyID!: string;
    public invitedByCompanyName!: string;
}

export class UserModelRequest {
     public userId!: string;
     public firstName!: string;
     public lastName!: string;
     public email!: string;
     public phone!: string;
   }

   export class UserModelResponse {
     public Exceptionmessage!: string | null;
     public message!: string;
     public result!: string;
     public user!: UserModel;
   }