import moment from "moment";

export class DateTimeHelper {
    public static convertDateStringToDesiredFormat(dateString: string, currentFormat: string, desiredFormat: string): string {
        if(moment(dateString, desiredFormat).isValid()){
            return dateString;
        }
        else{
            const date = moment(dateString, currentFormat);
            const result = date.format(desiredFormat);
            return result;
        }
    }

    public static convertFormattedDateToUTC(dateString: string): string{
        let result;
        if(dateString === ''){
            result = moment().format();
        }
        else{
            result = moment(dateString).format();
        }
        return result;
    }

    public static convertDateDesiredFormat(date: moment.Moment, desiredFormat: string): string {
        const result = moment(date).format(desiredFormat);
        return result;
    }
}