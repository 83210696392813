export class TCModel{
   public tcSection!: string;
   public isSelectedAll!: boolean;
   public isCollapse!: boolean;
   public isOpen!: boolean;
   public isSortAtoZ!: boolean;
   public isSortZtoA!: boolean;
   public sortType!: string;
   public selectedSortOption!: string;
}

export class TCDocumentModel{
   public success!: boolean;
   public tcData!: TCDocDataModel;
   public docUrl!: string;
}

export class TCDocDataModel {
   public ID!: string;
   public archieveDate!: string;
   public archieveDate1!: string;
   public clientID!: string;
   public clientName!: string;
   public cpSupplierID!: string;
   public cpSupplierName!: string;
   public createdAt!: string;
   public createdBy!: string;
   public createdByName!: string;
   public rejectionReason!: string;
   public tcComments!: string;
   public tcReferenceNumber!: string;
   public tcStatus!: string;
   public tcType!: string;
   public tranCertificate!: string;
   public tranCertificateRefID!: string;
   public tranCertificateUrls!: TCCertificateUrlsModel;
   public updatedAt!: string;
   public updatedBy!: string;
}

export class TCCertificateUrlsModel{
   public ID!: string;
   public createdAt!: string;
   public createdBy!: string;
   public docUrl!: string;
   public fileHash!: string;
   public fileName!: string;
   public fileType!: string;
   public shown!: boolean;
}