import { Vue, Component, Watch } from "vue-property-decorator";
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';
import moment from "moment";
import { ActionTypes } from "@/store/actions/actions";

// Components
import PendingActionsTile from '@/views/home/components/pendingActionsTile.vue';
import PendingOrderLinesTile from '@/views/home/components/pendingOrderLinesTile.vue';
import QrCodeTile from '@/views/home/components/qrCodeTile.vue';
import SustainabilityEffortsTile from '@/views/home/components/sustainabilityEffortsTile.vue';
import TransparencyEffortsTile from '@/views/home/components/transparencyEffortsTile.vue';
import SocialEffortsTile from "@/views/home/components/socialEffortsTile.vue";
import ExpiringCertificateTile from "@/views/home/components/expiringCertificate.vue";
import TopSupplyChainPartnerTile from "@/views/home/components/topSupplyChainPartner.vue";

// Models
import { FileModel } from '@/models/fileModel';
import { OrderModel } from '@/models/orderModel';
import { ConfirmOrderCompanyModel } from '@/models/confirmOrderCompanyModel';
import { FromCompanyUserModel } from "@/models/fromCompanyUserModel";
import { ConfirmOrderLineModel } from "@/models/confirmOrderLineModel";
import { CombinedOrderLineModel } from "@/models/combinedOrderLineModel";
import { ConfirmOrderModel } from "@/models/confirmOrderModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { UserModel } from '@/models/userModel';
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { SupplierCertificateModal } from "@/models/supplierCertificateModal";
import { SocialEffortModal } from "@/models/socialEffortModal";
import { TransparencyEffortModal } from "@/models/transparencyEffortModal";
import { DeleteOrderRequestModel } from "@/models/deleteOrderRequestModel";

// Services
import { OrderService } from '@/services/orderService';
import { SupplierService } from "@/services/supplierService";
import { ClientService } from "@/services/clientService";
import { UserClient } from '@/clients/userClient';
import { CountryStateCityService } from '@/services/countryStateCityService';

// Helper
import { FileHelper } from '@/helpers/fileHelper';
import { DateTimeHelper } from "@/helpers/dateTimeHelper";
import { ProductGroupHelper } from "@/helpers/productGroupHelper";
import { SuppliersHelper } from "@/helpers/suppliersHelper";
import { UserPermissionModel } from "@/models/permissionModel";
import { CountryListModel } from "@/models/countryLanguageModel";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
  components: { PendingActionsTile, PendingOrderLinesTile, QrCodeTile, SustainabilityEffortsTile, TransparencyEffortsTile, SocialEffortsTile, ExpiringCertificateTile, TopSupplyChainPartnerTile },
  computed: mapState(['totalQrCodeLive', 'totalStyles', 'transparencyEfforts', 'orders', 'orderLineSteps', 'userRole', 'userPermissions', 'orderLoading', 'orderLineStepsLoading', 'suppliersCertificateLoading', 'suppliersCertificate', 'socialEffortsLoading', 'socialEfforts', 'companyType', 'countryList', 'companyNameList'])
})
export default class Home extends Vue {

  private orderService: OrderService;

  private supplierService: SupplierService;

  private clientService: ClientService;

  private cscService: CountryStateCityService;

  private userClient: UserClient;

  private pendingOrders: OrderModel[] = [];

  private orders!: OrderLineDisplayModel[];

  private orderLineSteps!: OrderLineStepDisplayModel[];

  private suppliersCertificate!: SupplierCertificateModal[];

  private showUploadModal: boolean = false;

  private showUploadFootprintModal: boolean = false;

  private isConfirmBulk: boolean = false;

  private file: File | null = null;

  private isLoading: boolean = false;

  private orderLoading!: boolean;

  private orderLineStepsLoading!: boolean;

  private imgSrc: NodeRequire = require("../../assets/no-pending-order-lines.png");

  private pendingOrderConfirm: boolean = false;

  private ordersConfirm: boolean = false;

  private totalQrCodeLive!: number;

  private totalStyles!: number;

  private socialEffortsLoading!: boolean;

  private socialEfforts!: SocialEffortModal[];

  private transparencyEfforts!: TransparencyEffortModal[];

  private data: string = "";

  private filteredSustainableEfforts: SupplierCertificateModal[] = [];

  private filteredTransparencyEfforts: TransparencyEffortModal[] = [];

  private filteredOrders: OrderLineDisplayModel[] = [];

  private userRole!: string;

  private userPermissions!: UserPermissionModel | null;

  private suppliersCertificateLoading!: boolean;

  private companyType!: number;

  private today: Date = new Date();

  private countryList!: CountryListModel[];

  private companyNameList!: CompanyNameModel[];

  public constructor() {
    super();
    this.orderService = new OrderService();
    this.supplierService = new SupplierService();
    this.clientService = new ClientService();
    this.userClient = new UserClient();
    this.cscService = new CountryStateCityService();
  }

  private async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const user = this.$store.getters.user as UserModel;
    if(user.companyId === null || user.companyId === undefined){
      const result = await this.userClient.getUserInfoAsync();
      store.commit('setUser', result);
    }
    this.getOrderLinesAsync();
    // this.getSuppliersCertificateAsync();
    this.getCountries();
  }

  private async getOrderLinesAsync(): Promise<void> {
    if(this.orderLoading === false && this.orders.length === 0){
      await store.dispatch(ActionTypes.SET_ORDER_DETAIL);
    }
    this.filteredOrders = this.orders;
    this.filteredTransparencyEfforts = this.transparencyEfforts.filter(t => t.year === moment().format('YYYY'));
  }

  // private async getSuppliersCertificateAsync(): Promise<void> {
  //   if(this.suppliersCertificateLoading === false && this.suppliersCertificate.length === 0){
  //     await store.dispatch(ActionTypes.SET_SUPPLIER_CERTIFICATE);
  //   }
  //   this.filteredSustainableEfforts = this.suppliersCertificate.filter(c => c.certificateType === "Sustainability" && (c.createdAt === moment().format('YYYY') || c.createdAt === '' || c.validateToDate === moment().format('YYYY')) && (new Date(c.expiryDate).getTime() >= this.today.getTime() || c.expiryDate == null));
  // }

  private async getCountries(): Promise<void> {
    if(this.countryList.length === 0){
      await this.cscService.getAllCountries();
    }
  }

  private get uploadPermission(): number {
    return this.userPermissions !== null ? this.userPermissions.uploadOrderLine : 0;
  }

  private get isAgent(): boolean {
    let result = true;
    if(this.companyType !== 5){
        result = false;
    }
    return result;
}

  private get userName(): string {
    const user = this.$store.getters.user as UserModel;
    if (user == null || user.name == null) {
      console.error("No user info found");
      return "-";
    }
    return user.firstName;
  }

  private get uploadDisabled(): boolean {
    return this.file == null;
  }

  private get expiringCertificate(): SupplierCertificateModal[] {
    // TTD-4337 For agent certificates removed from getSupplierList api response
    if(this.companyType !== 5){
      const supplierCertificate = this.suppliersCertificate.filter(c => c.daysLeft !== null);
      const uniqueCertificates: SupplierCertificateModal[] = [];
      // TTD-3212 changes
      const date = new Date();
      date.setDate(date.getDate() + 30);
      const currentDate = date.toISOString();
      supplierCertificate.forEach(cert => {
        const certificate = uniqueCertificates.filter(c => c.certificateId === cert.certificateId && c.t1SCPCompanyName === cert.t1SCPCompanyName && c.certificateType === cert.certificateType)[0];
        cert.daysLeft = cert.daysLeft < 0 ? 0 : cert.daysLeft;
        if (certificate === null || certificate === undefined) {
          if(cert.expiryDate <= currentDate){
            cert.t1SCPCompanyName = this.companyNameList.filter(c => c.ID === cert.id).length > 0 ? this.companyNameList.filter(c => c.ID === cert.id)[0].name : cert.t1SCPCompanyName;
            uniqueCertificates.push(cert);
          }
        }
        else {
          if (moment(certificate.uploadedAt).toDate() < moment(cert.uploadedAt).toDate()) {
            const index = uniqueCertificates.indexOf(certificate);
            // TTD-3212 changes
            if(cert.expiryDate > currentDate){
              uniqueCertificates.splice(index, 1);
            } else{
              uniqueCertificates.splice(index, 1);
              cert.t1SCPCompanyName = this.companyNameList.filter(c => c.ID === cert.id).length > 0 ? this.companyNameList.filter(c => c.ID === cert.id)[0].name : cert.t1SCPCompanyName;
              uniqueCertificates.push(cert);
            }
          }
        }
      });
      return uniqueCertificates;
    } else {
      return [];
    }
  }

  private get isDataLoading(): boolean {
    return this.orderLoading || this.suppliersCertificateLoading;
  }

  private selectedYearValue(year: string, component: string) {
    let filteredCert: SupplierCertificateModal[] = [];
    switch (component) {
      case 'Sustainability':
        filteredCert = this.suppliersCertificate.filter(c => c.certificateType === "Sustainability" && (c.createdAt === year || c.createdAt === '' || c.validateToDate === year));
        if(moment().format('YYYY') === year){
          filteredCert = filteredCert.filter(c => new Date(c.expiryDate).getTime() >= this.today.getTime() || c.expiryDate == null)
        }
        this.filteredSustainableEfforts = filteredCert;
        break;

      case 'Transparency':
        this.filteredTransparencyEfforts = this.transparencyEfforts.filter(t => t.year === year);
        break;
    }
  }

  private selectedProductGroup(productGroup: number) {
    if (productGroup === 0) {
      this.filteredOrders = this.orders;
    }
    else {
      this.filteredOrders = this.orders.filter(o => o.productGroup === productGroup);
    }
  }

  private closeUploadModal(): void {
    this.showUploadModal = false;
  }

  private openUploadModal(): void {
    this.showUploadModal = true;
  }

  private closeUploadFootprintModal(): void {
    this.showUploadFootprintModal = false;
  }

  private openUploadFootprintModal(): void {
    this.showUploadFootprintModal = true;
  }

  private async confirmedHandler(order: CombinedOrderLineModel, confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean): Promise<void> {
    try {
      this.isConfirmBulk = isBulk;
      await this.ordersConfirmedHandler(order, confirmOrderLineStep, isBulk);
    }
    finally {
      this.isLoading = false;
    }
  }

  private async ordersConfirmedHandler(order: CombinedOrderLineModel, confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean): Promise<void> {
    this.isLoading = true;
    const confirmOrders: ConfirmOrderModel[] = [];
    const uuid = uuidv4();
    for (const id of order.orderIds) {
      const confirmOrder: ConfirmOrderModel = new ConfirmOrderModel();
      const confirm = this.pendingOrders.filter(order => order.id === id)[0];

      confirmOrder.createdAt = moment(confirm.createdAt).toISOString();
      confirmOrder.updatedAt = moment(confirmOrder.updatedAt).toISOString();
      confirmOrder.fromCompanyID = confirm.fromCompanyUser.companyId;
      const fromCompanyUser = new FromCompanyUserModel(confirm.fromCompanyUser.companyId, confirm.fromCompanyUser.contact, confirm.fromCompanyUser.userId);
      confirmOrder.fromCompanyUser = fromCompanyUser;
      if (isBulk) {

        confirmOrder.toCompanyID = confirm.toCompany.id;
        confirmOrder.toCompany = new ConfirmOrderCompanyModel(confirm.toCompany.id, confirm.toCompany.country, confirm.toCompany.companyName);
      }
      else {
        confirmOrder.toCompanyID = order.supplierId;
        const supplier1 = new ConfirmOrderCompanyModel(order.supplierId, order.supplierCountry, order.supplierName);
        confirmOrder.toCompany = supplier1;
      }
      const orderLines: ConfirmOrderLineModel[] = [];
      confirm.orderLines.forEach(line => {
        const orderLine: ConfirmOrderLineModel = new ConfirmOrderLineModel();
        orderLine.brandName = line.brandName;
        orderLine.certificate = line.certificate;
        orderLine.colourway = line.colourway;
        orderLine.compositionMainFabric = line.compositionMainFabric;
        orderLine.compositionSecondFabric = line.compositionSecondFabric;
        orderLine.etd = line.expectedTimeOfDelivery;
        orderLine.lineID = line.id;
        orderLine.orderLinePhase = 1;
        orderLine.orderLineStatus = 0;
        orderLine.productGroup = ProductGroupHelper.getIdFromProductGroupName(line.productGroup).id;
        orderLine.productPictureUrls = line.productPictures;
        orderLine.qrCodeRef = '';
        orderLine.showNewsLetter = false;
        orderLine.styleName = line.styleName;
        orderLine.styleNumber = line.styleNumber;
        orderLine.totalQuantityPerCw = line.quantity;

        orderLines.push(orderLine);
      });
      confirmOrder.orderLines = orderLines;
      confirmOrder.ID = confirm.id;
      confirmOrder.orderNumber = confirm.orderNumber;
      confirmOrder.orderRefID = uuid;

      confirmOrders.push(confirmOrder);
    }
  }

  private async deleteOrdersHandler(orderIds: string[]): Promise<void> {
    const data: DeleteOrderRequestModel = {orderList:[]};
    orderIds.forEach(o => {
      data.orderList.push({'ID': o});
    });
    const result = await this.orderService.deleteOrderAsync(data);
    if (result.success) {
      this.pendingOrderConfirm = true;
      store.commit("setOrderConfirmed", true);
      this.ordersConfirm = true;
      this.getOrderLinesAsync();
    }
  }

  private async reloadOrderLines(): Promise<void> {
    this.pendingOrderConfirm = true;
  }

  private async upload(): Promise<void> {
    if (this.uploadDisabled || this.file == null) {
      return;
    }

    try {
      this.isLoading = true;

      const documentAsBase64 = await FileHelper.getBase64(this.file) as string;
      const file = new FileModel(this.file.name, this.file.type, documentAsBase64);
      const uploadResult = await this.orderService.uploadOrderCsvAsync(file);
      if (uploadResult.orders && uploadResult.orders.length>0) {
        store.commit("setOrders", uploadResult.orders);
        store.commit("setTransparencyEfforts", uploadResult.transparencyEfforts);
        store.commit("setTotalStyleCount", uploadResult.totalStyles);
        store.commit('setQrCodeCount', uploadResult.totalQrCodeLive);
        this.getOrderLinesAsync();
      }
      this.file = null;
    }
    finally {
      this.isLoading = false;
      this.closeUploadModal();
    }
  }

  private async uploadFootprint(): Promise<void> {
    if (this.uploadDisabled || this.file == null) {
      return;
    }

    try {
      this.isLoading = true;
      const documentAsBase64 = await FileHelper.getBase64(this.file) as string;
      const file = new FileModel(this.file.name, this.file.type, documentAsBase64);
      const uploadResult = await this.orderService.uploadFootprintCsvAsync(file);
      this.file = null;
    }
    finally {
      this.isLoading = false;
      this.closeUploadFootprintModal();
    }
  }

  @Watch('orders')
  private reloadOrders(): void {
    this.getOrderLinesAsync();
    // this.getSuppliersCertificateAsync();
  }
}
