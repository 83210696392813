import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import ClickOutside from 'vue-click-outside';
import { debounce } from "ts-debounce";
import { mapState } from "vuex";

// Models
import { DropdownModel } from '@/models/dropdownModel';

@Component({
    directives: { ClickOutside },
    computed: mapState(['userRole'])
})

export default class DropDownEditable extends Vue {

    @Prop()
    private isReadonly!: boolean;

    @Prop()
    private moreSpacing!: boolean;

    @Prop()
    private showOntop!: boolean;

    @Prop()
    private dropdownList!: DropdownModel[];

    @Prop()
    private placeholder!: string;

    @Prop()
    private fieldName!: string;

    @Prop({ default: '' })
    private defaultValue!: string;

    private userRole!: string;

    public constructor() {
        super();
    }

    private fieldKey: string = "";
    private fieldValue: string = "";

    private inputError: boolean = false;
    private dropdownShow: boolean = false;
    private showSelectionError: boolean = false;

    private searchDebounced: any;

    private async created(): Promise<void> {
        this.searchDebounced = debounce(this.searchDropdown, 250, { isImmediate: true });
    }

    private async mounted(): Promise<void> {
        if (this.defaultValue.length > 0) {
          this.fieldValue = this.defaultValue;
        }
      }

    public async clearValues(): Promise<void> {
        this.fieldKey = "";
        this.fieldValue = "";
    }

    private async checkValid(): Promise<boolean> {
        this.inputError = false;
        if (this.isReadonly && this.fieldKey.length == 0) {
            this.inputError = true;
        }

        if (!this.inputError) {
            if (this.fieldKey.length > 0) {
                this.fieldKey = this.fieldKey.trim();
            }
            if (this.fieldValue.length > 0) {
                this.fieldValue = this.fieldValue.trim();
            }
            this.emitValueSelected(this.fieldKey, this.fieldValue);
        }
        return this.inputError;
    }

    // Sprint 23 - TTD-3647, updated
    private vueClickedOutside(): void {
        this.dropdownShow = false;
        if ((this.fieldValue!=='' && this.fieldKey === '') && this.defaultValue === '') {
            this.showSelectionError = true;
        }
    }

    // Sprint 23 - TTD-3647, updated
    private async hideDropdownShow(fieldKey: string, fieldValue: string): Promise<void> {
        this.fieldKey = fieldKey;
        this.fieldValue = fieldValue;
        if (this.dropdownShow) {
            const lError = await this.checkValid();
        }
        this.dropdownShow = false;
        this.showSelectionError = false;
    }

    private scrollFocus: any = null;
    private async searchDropdown(event: any): Promise<void> {
        if (this.dropdownList.length === 0) {
            this.dropdownShow = false;
            return;
        }
        const filteredList = this.dropdownList.filter(item => item.text.toLowerCase().includes(this.fieldValue.toLowerCase()));

        if (!this.dropdownShow && (event.keyCode == 9 || event.keyCode == 38 || event.keyCode == 40)) {
            this.dropdownShow = true;
            if(this.fieldKey.length === 0){
                this.emitValueSelected('','');
            }
        }

        if (this.dropdownShow && (event.keyCode == 38 || event.keyCode == 40 || event.keyCode == 13)) {
            switch (event.keyCode) {
                case 38:
                    if (this.scrollFocus === null) {
                        this.scrollFocus = 0;
                    } else if (this.scrollFocus > 0) {
                        this.scrollFocus--;
                    }
                    break;
                case 40:
                    if (this.scrollFocus === null) {
                        this.scrollFocus = 0;
                    } else if (this.scrollFocus < filteredList.length - 1) {
                        this.scrollFocus++;
                    }
                    break;
                case 13:
                    if (this.scrollFocus !== null) {
                        this.fieldKey = filteredList[this.scrollFocus].value.toString();
                        this.fieldValue = filteredList[this.scrollFocus].text.toString();
                        this.dropdownShow = false;
                    }
                    this.checkValid();
                    break;
            }
            if (this.scrollFocus !== null) {
                setTimeout((app: any) => {
                    const listItem = app.$refs['scrollFocus' + app.scrollFocus] as Vue;
                    Object.entries(listItem).forEach(item => {
                        item[1].scrollIntoView({ behavior: "smooth", block: "center" });
                    });
                }, 150, this);
            }
            return;
        }

        if (filteredList.length > 0) {
            this.dropdownShow = true;
            this.fieldKey = "";
        } else {
            this.dropdownShow = false;
        }
    }

    private emitValueSelected(fieldKey: string, fieldValue: string ): void {
        this.fieldKey = fieldKey;
        this.fieldValue = fieldValue.trim();
        this.valueSelected({ fieldKey: fieldKey, fieldValue: fieldValue });
    }

    private get calculateHeight(): string {
        const allItemsCount = this.dropdownList.filter(item => item.text.toLowerCase().includes(this.fieldValue.toLowerCase())).length;
        let height: number = 0;
        if (allItemsCount>=5) {
            height = 200;
        } else {
            height = 40*allItemsCount+4;
        }
        return height.toString()+'px';
    }

    private get calculateTop(): string {
        const allItemsCount = this.dropdownList.filter(item => item.text.toLowerCase().includes(this.fieldValue.toLowerCase())).length;
        let height: number = 0;
        if (allItemsCount>=5) {
            height = -(200);
        } else {
            height = -(40*allItemsCount+4);
        }
        return height.toString()+'px';
    }

    @Emit()
    private valueSelected(fieldObject: {}): void {

    }

    @Watch('defaultValue', { deep: true })
    private updateDefaultValue(): void {
        if (this.defaultValue && this.defaultValue.length > 0) {
        this.fieldValue = this.defaultValue;
        } else {
            this.fieldValue = "";
        }
    }
}