import { SupplierBlockchain } from '@/clients/supplierBlockchain';
import { ClientRequestFootPrintModel } from "@/models/clientRequestFootprintModel";
import { ClientClient } from '@/clients/clientClient';
import { sendMailFpModal } from "@/models/sendMailForFootPrintRequestModel";
import { DeleteDocRequestModel, FootprintDataResponseModel, FootprintDeadlineResponseModel, SaveFootprintDeadlineModel, ViewFootprintResponseModel,
        FootprintCompletedResponseModel, FootPrintCompletedConstant } from '@/models/footprintModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import store from '@/store';
import { YarnFootprintRequestModel, YarnFootprintResponseModel, WetProcessFootprintResponseModel, YarnModel } from '@/models/yarnFootprintModel';
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';
import { FabricFootprintRequestModel } from '@/models/fabricFootprintModel';
import { WetProcessFootprintRequestModel } from '@/models/wetProcessFootprintModel';
import { GarmentFootprintRequestModel } from '@/models/garmentFootprintModel';
import { FootprintPartnerModel, FootprintPartnerResponseModel} from '@/models/orderLineStepDisplayModel';
import { DeleteOrderRequestModel } from '@/models/deleteOrderRequestModel';
import { StepState } from '@/models/stepState';

export class ClientRequestFootPrintData {
    private readonly supplier: SupplierBlockchain = new SupplierBlockchain();
    private readonly clientClient: ClientClient = new ClientClient();

    public async sendMailForRequestFp(data:sendMailFpModal): Promise<Response> {
        const result = await this.clientClient.sendMailForRequestFp(data);
        return result;
    }

    public async requestFootprintData(orderId: string, data:[], mailData: sendMailFpModal): Promise<ClientRequestFootPrintModel> {
        const result = await this.supplier.requestFootprintData(orderId, data);
        if(result.success){
            this.sendMailForRequestFp(mailData);
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.client_requested_foot_print_data_success').toString());
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.client_requested_foot_print_data_failed').toString());
        }
        return result;
    }

    public async saveFootprintDeadline(orderId: string, data: SaveFootprintDeadlineModel, userCompanyId: string): Promise<FootprintDeadlineResponseModel> {
        const result = await this.supplier.saveFootprintDeadline(orderId, data);
        if(result.success){
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders[orderIndex].footprintDeadline = data.footprintDeadline;
            orders[orderIndex].footprintFilledByCompanyID = userCompanyId;
            store.commit("setAllOrders", orders);
        }
        return result;
    }

    public async saveYarnFootprintData(orderId: string, data: YarnFootprintRequestModel): Promise<YarnFootprintResponseModel> {
        const result = await this.supplier.saveYarnFootprintDataAsync(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_yarn_saved').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_yarn_failed').toString());
        }
        return result;
    }

    public async saveWetProcessData(orderId: string, data: WetProcessFootprintRequestModel): Promise<WetProcessFootprintResponseModel> {
        const result = await this.supplier.saveWetProcessDataAsync(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_wet_saved').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_wet_failed').toString());
        }
        return result;
    }

    public async uploadSupportingDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.uploadSupportingDocsAsync(orderId, uploadData);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.supporting_uploadDoc_saved').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.supporting_doc_upload_failed').toString());
        }
        return result;
    }

    public async uploadWetMsdsDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.uploadWetMsdsDocsAsync(orderId, uploadData);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.msds_footprint_uploadDoc_saved').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.msds_doc_upload_failed').toString());
        }
        return result;
    }

    public async getFootprintOnOrderAsync(orderId: string): Promise<boolean>{
        const result = await this.supplier.getFootprintOnOrderAsync(orderId);
        if(result.success){
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            const filterOrder = orders[orderIndex];
            const updatedOrder = result.orders;
            updatedOrder.productPictures = filterOrder.productPictures;
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, updatedOrder);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_fetch_failed').toString());
        }
        return result.success;
    }

    public async saveYarnTransportAsync(orderId: string, data: any): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.saveYarnTransportAsync(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.yarn_transport_saved').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.yarn_transport_failed').toString());
        }
        return result;
    }

    public async deleteSupportDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel>{
        let success = true;
        const result = await this.supplier.deleteSupportDocs(orderId, data);
        if(result){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_doc_delete_success').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_doc_delete_failed').toString());
        }
        return result;
    }

    public async deleteMsdsDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel>{
        let success = true;
        const result = await this.supplier.deleteMsdsDocs(orderId, data);
        if(result){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_doc_delete_success').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_doc_delete_failed').toString());
        }
        return result;
    }

    public async saveFabricFootprintDataAsync(orderId: string, data: FabricFootprintRequestModel): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.saveFabricFootprintDataAsync(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.fabric_footprint_saved').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.fabric_footprint_failed').toString());
        }
        return result;
    }

    public async saveGarmentFootprintDataAsync(orderId: string, data: GarmentFootprintRequestModel): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.saveGarmentFootprintDataAsync(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_garment_saved').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_garment_failed').toString());
        }
        return result;
    }

    public async getFootprintPartnerAsync(orderId: string, lineId: string): Promise<FootprintPartnerResponseModel>{
        const result = await this.supplier.getFootprintPartnerAsync(orderId, lineId);
        if(result.success){
            const partner = store.getters.footprintPartner as FootprintPartnerModel[];
            partner.push(...result.orderLineSteps.filter(s => (s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && s.stepState !== StepState.NONE)));
            store.commit('setFootprintPartner', partner);
        }
        return result;
    }

    public async uploadYarnWetMsdsDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.uploadYarnWetMsdsDocsAsync(orderId, uploadData);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.msds_footprint_uploadDoc_saved').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.msds_doc_upload_failed').toString());
        }
        return result;
    }

    public async deleteYarnWetMsdsDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel>{
        // let success = true;
        const result = await this.supplier.deleteYarnWetMsdsDocs(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_doc_delete_success').toString());
            // success = true;
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_doc_delete_failed').toString());
            // success = false;
        }
        return result;
    }

    public async uploadSupportingWetYarnDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.uploadSupportingWetYarnDocsAsync(orderId, uploadData);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.supporting_uploadDoc_saved').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.supporting_doc_upload_failed').toString());
        }
        return result;
    }

    public async deleteWetYarnSupportDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.deleteWetYarnSupportDocs(orderId, data);
        if(result){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_doc_delete_success').toString());
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setAllOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_doc_delete_failed').toString());
        }
        return result;
    }
    //Sprint-22 TTD-3739 complete footprint orders
    public async completeFootprintOrders(orderId:string[]): Promise<FootprintCompletedResponseModel> {
        const data: DeleteOrderRequestModel = {orderList:[]};
        orderId.forEach(o => {
        data.orderList.push({'ID': o});
        });
        const result = await this.supplier.completeFootprintOrders(data);
        if(result.success){
            if(result.order.length === 1){
                NotificationHelper.createSucceededNotification('Footprint order completed successfully.');
            }else{
                NotificationHelper.createSucceededNotification('Footprint orders completed successfully.');
            }
            const orders = store.getters.orders as OrderLineDisplayModel[];
            result.order.forEach(order => {
                const orderIndex = orders.findIndex(o => o.orderId === order.orderId);
                if(orderIndex !== -1){
                    orders[orderIndex].actionOnFootprint = order.actionOnFootprint;
                }
                });
                store.commit("setAllOrders", orders);
                const updatedOrders = store.getters.orders as OrderLineDisplayModel[];
                const provideFootprintCount = updatedOrders.filter(o => o.footprintFlag === 'TRUE' && (o.finalFootprintStatus === FootPrintCompletedConstant.PARTIAL || o.finalFootprintStatus === "" || o.actionOnFootprint.status === FootPrintCompletedConstant.FORCEINPROGRESS)
                && o.actionOnFootprint.status !== FootPrintCompletedConstant.FORCECOMPLETED).length;
                store.commit("setPendingFootprintCount", provideFootprintCount);
        }else{
            NotificationHelper.createErrorNotification('Failed to complete footprint.');
        }
        return result;
    }
    // Sprint-22 TTD-3739 delete footprint orders
    public async deleteFootprintOrders(orderId:string[]): Promise<FootprintCompletedResponseModel> {
        const data: DeleteOrderRequestModel = {orderList:[]};
        orderId.forEach(o => {
        data.orderList.push({'ID': o});
        });
        const result = await this.supplier.deleteFootprintOrders(data);
        if(result.success){
            if(result.order.length === 1){
                NotificationHelper.createSucceededNotification('Footprint order deleted successfully.');
            }else{
                NotificationHelper.createSucceededNotification('Footprint orders deleted successfully.');
            }
            const orders = store.getters.orders as OrderLineDisplayModel[];
            result.order.forEach(order => {
                const orderIndex = orders.findIndex(o => o.orderId === order.orderId);
                if(orderIndex !== -1){
                    orders.splice(orderIndex, 1);
                    orders.splice(orderIndex, 0, order)
                }
                });
                store.commit("setAllOrders", orders);
                const provideFootprintCount = orders.filter(o => o.footprintFlag === 'TRUE' && (o.finalFootprintStatus === FootPrintCompletedConstant.PARTIAL || o.finalFootprintStatus === "" || o.actionOnFootprint.status === FootPrintCompletedConstant.FORCEINPROGRESS)
                && o.actionOnFootprint.status !== FootPrintCompletedConstant.FORCECOMPLETED).length;
                store.commit("setPendingFootprintCount", provideFootprintCount);
        }else{
            NotificationHelper.createErrorNotification('Failed to delete footprint.');
        }
        return result;
    }

    // Sprint-22 TTD-4212 set back to Inprogress
    public async setBackToInProgress(orderId:string[]): Promise<FootprintCompletedResponseModel> {
        const data: DeleteOrderRequestModel = {orderList:[]};
        orderId.forEach(o => {
        data.orderList.push({'ID': o});
        });
        const result = await this.supplier.setBackToInprogressFootprintOrders(data);
        if(result.success){
            if(result.order.length === 1){
                NotificationHelper.createSucceededNotification('Footprint order is set back to in progress successfully.');
            }else{
                NotificationHelper.createSucceededNotification('Footprint orders set back to in progress successfully.');
            }
            const orders = store.getters.orders as OrderLineDisplayModel[];
            result.order.forEach(order => {
                const orderIndex = orders.findIndex(o => o.orderId === order.orderId);
                if(orderIndex !== -1){
                    orders[orderIndex].actionOnFootprint = order.actionOnFootprint;
                }
                });
                store.commit("setAllOrders", orders);
                const provideFootprintCount = orders.filter(o => o.footprintFlag === 'TRUE' && (o.finalFootprintStatus === FootPrintCompletedConstant.PARTIAL || o.finalFootprintStatus === "" || o.actionOnFootprint.status === FootPrintCompletedConstant.FORCEINPROGRESS)
                && o.actionOnFootprint.status !== FootPrintCompletedConstant.FORCECOMPLETED).length;
                store.commit("setPendingFootprintCount", provideFootprintCount);
        }else{
            NotificationHelper.createErrorNotification('footprint order to set back to in progress.');
        }
        return result;
    }

    // TTD-3941 platform admin
    public async getPAFootprintOnOrderAsync(orderId: string): Promise<ViewFootprintResponseModel>{
        const result = await this.supplier.getFootprintOnOrderAsync(orderId);
        if(!result.success){
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_fetch_failed').toString());
        }
        return result;
    }
}
