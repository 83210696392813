import { Vue, Component, Watch } from "vue-property-decorator";
import i18n from "@/i18n";
import store from '@/store';
import { mapState } from 'vuex';
import lodash from "lodash";

// Services
import { ClientService } from "@/services/clientService";
import { ComplianceService } from "@/services/complianceService";
import { OrderService } from '@/services/orderService';

// Components
import Multiselect from '@/components/input/multiselect.vue';
import ComplianceDocuments from "@/views/compliance/components/complianceDocuments.vue";

// Helpers
import { NotificationHelper } from "@/helpers/notificationHelper";
import { ActionTypes } from "@/store/actions/actions";
import { CertificateType } from "@/helpers/certficatesTypeHelper";

// Modals
import { DropdownModel } from "@/models/dropdownModel";
import { CompanyModel, CompanyNameModel } from "@/models/companyModel";
import { UploadedDocResponseModal } from "@/models/uploadedDocResponseModal";
import { UserModel } from "@/models/userModel";
import { ComplianceDocResponseModal } from "@/models/complianceDocResponseModal";
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";
import { SocialCertificatesModel } from "@/models/socialCertificatesModel";
import { SustainableCertificatesModel } from "@/models/sustainableCertificatesModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { SocialEffortModal } from "@/models/socialEffortModal";
import { TransparencyEffortModal } from "@/models/transparencyEffortModal";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { SuppliersHelper } from "@/helpers/suppliersHelper";
import { TopBrandModel } from "@/models/topBrandModel";
import { UserPermissionModel } from "@/models/permissionModel";
import { Guid } from "@/models/guid";

@Component({
	components: { Multiselect, ComplianceDocuments },
	computed: mapState(['totalQrCodeLive', 'totalStyles', 'userPermissions', 'socialEfforts', 'transparencyEfforts', 'orders', 'orderLineSteps', 'orderLoading', 'documents', 'documentsLoading', 'sentArchiveDocs', 'sentArchiveDocsLoading', 'socialCertificates', 'sustainableCertificate', 'orderLineStepsLoading', 'uniqueSuppliersListLoading', 'company', 'user', 'companyNameList', 'clientPartners'])
})
export default class Compliance extends Vue {

	private sustainableCerts: SustainableCertificatesModel[] = [];

	private socialCerts: SocialCertificatesModel[] = [];

	private selectedScps: string[] = [];

	private optionsCount: number = 0;

	private clientService: ClientService;

	private complianceService: ComplianceService;

	private orderService: OrderService;

	private uploading: boolean = false;

	private user!: UserModel;

	private selectedBrands: string[] = [];

	private selectedBrand: string = '';

	private showUploadModal: boolean = false;

	private files: File[] = [];

	private pdfFileType: boolean = false;

	private company!: CompanyModel;

	private uploadedDocuments: UploadedDocResponseModal[] = [];

	private documents!: ComplianceDocResponseModal[];

	private tabIndex: number = 0;

	private isLoading: boolean = false;

	private sentArchiveDocsLoading!: boolean;

	private sentArchiveDocs!: ComplianceDocSentResponseModel[];

	private orders!: OrderLineDisplayModel[];

	private orderLineSteps!: OrderLineStepDisplayModel[];

	private totalQrCodeLive!: number;

	private totalStyles!: number;

	private socialEfforts!: SocialEffortModal[];

	private transparencyEfforts!: TransparencyEffortModal[];

	private orderLoading!: boolean;

	private topBrands: TopBrandModel[] = [];

	private documentsLoading!: boolean;

	private sustainableCertificate!: SustainableCertificatesModel[];

	private socialCertificates!: SocialCertificatesModel[];

	private orderLineStepsLoading!: boolean;

	private uniqueSuppliersListLoading!: boolean;

	private finalBrandList: string[] = [];

	private userPermissions!: UserPermissionModel | null;

	private searchFilter: string = '';

	private selectedPartner: string = '';

	private selectedType: string = '';

	private showClearFilter: boolean = false;

	private companyNameList!: CompanyNameModel[];

	private clientPartners!: DropdownModel[];

	public constructor() {
		super();
		this.clientService = new ClientService();
		this.complianceService = new ComplianceService();
		this.orderService = new OrderService();
	}

	private async created(): Promise<void> {
		if (this.$route.params.selectTab=='tc') {
			this.selectedTab(4);
		}
		try {
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
			this.isLoading = true;
			this.getDocuments(false);
			this.getSentArchiveDocs(false);
			this.getOrderLinesAsync();
		}
		finally {
			this.isLoading = false;
		}
	}

	private get brandFilterList(): DropdownModel[] {
		const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_brands").toString() }];
		this.brands().forEach(item => {
			options.push(item);
		});
		return options;
	}
	//Sprint-24 TTD-4483
	private get types(): DropdownModel[] {
		let options: DropdownModel[] = [];
		options = CertificateType.getCertificatesType();
		options.splice(0, 0, { value: "", text: this.$t("pages.order_lines.all_types").toString() });
		return options;
	}

	//Sprint-24 TTD-4483
	private get partners(): DropdownModel[] {
		let options: DropdownModel[] = [];
		if(this.tabIndex === 3){
			options = this.clientPartners;
		} else {
			options = this.orders.filter(o => (o.orderLinePhase !== 0 && o.orderLineStatus !== 0) || ( o.orderLinePhase !== 0 && o.orderLineStatus == 0) && o.toCompanyId!==Guid.Empty).map(o => new DropdownModel(o.toCompanyId, this.companyNameList.filter(c => c.ID === o.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === o.toCompanyId)[0].name : o.toCompanyName));
		}
		options =  lodash.uniqBy(options, 'value');
		options.splice(0, 0, { value: "", text: this.$t("pages.order_lines.all_partners").toString() });
		return options;
	}

	private get uploadPermission(): number {
        return this.userPermissions !== null && this.userPermissions?.uploadCompliance && this.userPermissions?.uploadCompliance==1 ? 1 : 0;
    }

	private get sendReminderPermission(): number {
        return this.userPermissions !== null && this.userPermissions?.sendReminderCompliance && this.userPermissions?.sendReminderCompliance==1 ? 1 : 0;
    }

	private get archievePermission(): number {
        return this.userPermissions !== null && this.userPermissions?.archieveCompliance && this.userPermissions?.archieveCompliance==1 ? 1 : 0;
    }

	private get supplychainFilterList(): DropdownModel[] {
		this.isLoading = true;
		const options: DropdownModel[] = [{ value: "", text: this.$t("pages.supply_chain_partners.select_all").toString() }];
		this.scps().forEach(item => {
			options.push(item);
		});
		this.isLoading = false;
		return options;
	}

	private get brandList(): DropdownModel[] {
		return this.brands();
	}

	private scps(): DropdownModel[] {
		const options: DropdownModel[] = [];
		this.sustainableCertificate.forEach(line => {
			if (options.findIndex(option => option.value === line.companyName) === -1) {
				line.companyName = this.companyNameList.filter(c => c.ID === line.companyID).length > 0 ? this.companyNameList.filter(c => c.ID === line.companyID)[0].name : line.companyName;
				options.push(new DropdownModel(line.companyName, line.companyName));
			}
		});
		this.socialCertificates.forEach(line => {
			if (options.findIndex(option => option.value === line.companyName) === -1) {
				line.companyName = this.companyNameList.filter(c => c.ID === line.companyID).length > 0 ? this.companyNameList.filter(c => c.ID === line.companyID)[0].name : line.companyName;
				options.push(new DropdownModel(line.companyName, line.companyName));
			}
		});
		let sortedOptions: DropdownModel[] = [];
		sortedOptions = options.sort((supplier1, supplier2) => {
			if (supplier1.text.toLowerCase() > supplier2.text.toLowerCase()) {
				return 1;
			} else {
				return -1;
			}
			return 0;
		});
		this.optionsCount = options.length;
		return sortedOptions;
	}
	private brands(): DropdownModel[] {
		if (this.user && this.user.topBrands && this.user.topBrands.length>0) {
			this.topBrands = this.user.topBrands;
		} else {
			if (this.company!==null) {
				this.topBrands = this.company.topBrands;
			}
		}
		const options: DropdownModel[] = [];
		this.topBrands.forEach(line => {
			if (options.findIndex(option => option.value === line.brandName) === -1) {
				options.push(new DropdownModel(line.brandName, line.brandName));
			}
			if (this.selectedBrands.findIndex(s => s === line.brandName) === -1) {
				this.selectedBrands.push(line.brandName);
			}
		});
		return options;
	}

	private openUploadModal(): void {
		this.finalBrandList = this.selectedBrands;
		this.showUploadModal = true;
	}

	private closeUploadModal(): void {
		this.showUploadModal = false;
		this.files = [];
		this.pdfFileType = false;
	}

	private get uploadDisabled(): boolean {
		return (this.files.length === 0 || this.pdfFileType === false) || this.finalBrandList.length === 0;
	}

	private brandSelected(brandNames: string[]): void {
		this.selectedBrands = [];
		this.finalBrandList = [];
		this.selectedBrands.push(...brandNames);
		this.finalBrandList.push(...brandNames);
		if (this.brandList.length === brandNames.length && this.brandList.length!==1) {
			setTimeout((app: any) => {
				const myContainer = document.getElementsByClassName('multiselect__single')[0] as HTMLInputElement;
				if (myContainer !== undefined) {
					myContainer.value = "All brands selected";
				}
			})
		}
	}

	private selectedTab(index: number): void {
		this.tabIndex = index;
		if (index == 0) {
			this.getDocuments(false);
		} else if(index == 3) {
			if(this.socialCertificates.length === 0 && this.sustainableCertificate.length === 0){
				store.dispatch(ActionTypes.SET_UNIQUE_SUPPLIER_LIST);
			}
		}
		// else if(index === 4){
		//
		// }
		else{
			this.getSentArchiveDocs(false);
		}
	}

	private async getDocuments(forceReload: boolean): Promise<void> {
		try {
			if((this.documentsLoading === false && this.documents.length === 0) || forceReload){
				await store.dispatch(ActionTypes.SET_DOCUMENTS);
			}
		} finally {
			//
		}
	}

	private async getSentArchiveDocs(forceReload: boolean): Promise<void> {
		try {
			if((this.sentArchiveDocsLoading === false && this.sentArchiveDocs.length === 0) || forceReload){
				await store.dispatch(ActionTypes.SET_SENT_ARCHIVE_DOCS);
			}
		}
		finally {
			// this.isSentArchiveDocLoading = false;
		}
	}

	private async reloadDocuments(): Promise<void> {
		this.getSentArchiveDocs(true);
		this.getDocuments(true);
	}

	private async upload(): Promise<void> {
		if (this.uploadDisabled || this.files == null) {
			return;
		}

		try {
			this.uploading = true;
			const formData = new FormData();
			this.files.forEach(file => {
				formData.append("upload", file);
			});
			formData.append("issuer", this.user && this.user.companyName !=='' ? this.user.companyName : '');
			const brandUniq = lodash.uniq(this.finalBrandList);
			brandUniq.forEach((brand, index) => {
				formData.append(`brands[${index}]`, brand);
			});
			formData.append("documentStatus", "DOCUMENT_UPLOADED");
			formData.append("description", "");
			const uploadResult = await this.complianceService.uploadDocAsync(formData);

			if (uploadResult.length > 0) {
				this.uploadedDocuments = uploadResult;
				this.files = [];
				this.pdfFileType = false;
			}
		}
		finally {
			this.uploading = false;
			this.closeUploadModal();
			this.getDocuments(true);
		}
	}

	private scpsSelected(scps: string[]): void {
		this.selectedScps = scps;
	}

	private async getOrderLinesAsync(): Promise<void> {
		if(this.orderLoading === false && this.orders.length === 0){
			await store.dispatch(ActionTypes.SET_ORDER_DETAIL);
			await store.dispatch(ActionTypes.SET_SUPPLIER_CERTIFICATE);
		}
	}

	@Watch("files", { deep: true })
	private validateFileUploaded(): void {
		if (this.files.length > 20) {
			this.pdfFileType = false;
			NotificationHelper.createWarningNotification(i18n.t('global.notifications.document_length').toString());
			return;
		}
		this.files.forEach(file => {
			if (file.type !== 'application/pdf') {
				this.pdfFileType = false;
				NotificationHelper.createWarningNotification(i18n.t('global.notifications.document_type').toString());
			}
			else {
				this.pdfFileType = true;
			}
		});
    }
	//Sprint-24 TTD-4483
	private clearFilterShow(): void{
		this.showClearFilter = true;
	}
	//Sprint-24 TTD-4483
	private clearAllFilters(): void {
		this.selectedBrand = '';
		this.selectedPartner = '';
		this.selectedType = '';
		this.searchFilter = '';
		this.showClearFilter = false;
	}
}
