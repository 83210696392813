import { BaseClient } from "./baseClient";
import { DashboardApiAuthenticator } from "../authenticators/dashboardApiAuthenticator";
import { ApplicationConfig } from "../config";
import { FileModel } from '@/models/fileModel';
import { OrderLineUploadResultModel } from '@/models/orderLineUploadResultModel';
import { OrderModel } from '@/models/orderModel';
import { OrderLineStepModel } from "@/models/orderLineStepModel";
import { OrderLineStepDisplayModel, OrderLineStepResponseModel } from "@/models/orderLineStepDisplayModel";
import { ConfirmOrderModel } from "@/models/confirmOrderModel";
import { OrdersWithStepsModal } from "@/models/ordersWithStepsModel";
import { ProductFootprintUploadResultModel } from "@/models/productFootprintUploadResultModel";

export class OrderClient extends BaseClient {
    private readonly endPoint: string = "api/order";
    private readonly agentEndPoint: string = "api/agent";

    public constructor() {
        super(ApplicationConfig.endpoints.dashboardApi, new DashboardApiAuthenticator());
    }

    // public async getOrdersAsync(bookmark: string, pageSize: number, brands: string): Promise<OrdersWithStepsModal> {
    //     const encodedBrands: string = encodeURIComponent(brands);
    //     return await this.get(`${this.endPoint}/orderDetails?bookmark=${bookmark}&pagesize=${pageSize}&phase=0,1,2&status=0,1,2,3,4,5,6&brands=${encodedBrands}`);
    // }

    public async getOrdersAsyncOnCompanyType(bookmark: string, pageSize: number, companyType: number): Promise<OrdersWithStepsModal> {
        return await this.get(`${this.agentEndPoint}/orderDetails?bookmark=${bookmark}&pagesize=${pageSize}&phase=0,1,2&status=0,1,2,3,4,5,6&companyType=${companyType}`);
    }

    public async getOrderLineStepsAsync(orderId: string, orderLineId: string): Promise<OrderLineStepModel[]> {
        return await this.get<OrderLineStepModel[]>(`${this.endPoint}/${orderId}/line/${orderLineId}/steps`);
    }

    public async getPendingOrdersAsync(brands: string): Promise<OrderModel[]> {
        const encodedBrands: string = encodeURIComponent(brands);
        return await this.get(`${this.endPoint}/pending?brands=${encodedBrands}`);
    }

    public async getScpOrdersCountAsync(supplierId: string): Promise<any> {
        return await this.get(`${this.endPoint}/supplierID/${supplierId}`);
    }

    public async uploadOrderCsvAsync(file: FileModel, brands: string): Promise<OrdersWithStepsModal> {
        return await this.postWithResponse(`${this.endPoint}/upload?brands=${brands}`, file);
    }

    public async uploadFootprintCsvAsync(file: FileModel): Promise<ProductFootprintUploadResultModel> {
        return await this.postWithResponse(`${this.endPoint}/uploadfootprintdata`, file);
    }

    public async confirmOrderAsync(orders: ConfirmOrderModel[]): Promise<OrdersWithStepsModal> {
        return await this.putWithResponse<OrdersWithStepsModal>(`${this.endPoint}/confirm/bulkOrder`, orders);
    }

    public async publishOrderAsync(orderId: string, lineId: string): Promise<void> {
        await this.patch(`${this.endPoint}/${orderId}/line/${lineId}/publish`, null);
    }

    public async unpublishOrderAsync(orderId: string, lineId: string): Promise<void> {
        await this.patch(`${this.endPoint}/${orderId}/line/${lineId}/unpublish`, null);
    }

    public async createSupplyChainStepsAsync(orderLineStep: OrderLineStepDisplayModel[]): Promise<OrderLineStepResponseModel> {
        return await this.postWithResponse<OrderLineStepResponseModel>(`${this.endPoint}/orderlinesteps`, orderLineStep);
    }

    public async editSupplyChainStepsAsync(orderLineStep: OrderLineStepDisplayModel[]): Promise<OrderLineStepResponseModel>{
        return await this.putWithResponse<OrderLineStepResponseModel>(`${this.endPoint}/editorderlinesteps`, orderLineStep);
    }
}
