import { ProductPictureDisplayModel } from "./productPictureDisplayModel";
import { ProductQualityReportModel } from "./productQualityReportModel";

export class ConfirmOrderLineModel {
    public brandName!: string;
    public productGroup!: number;
    public styleNumber!: string;
    public styleName!: string;
    public colourway?: string;
    public certificate?: string;
    public compositionMainFabric!: string;
    public compositionSecondFabric?: string;
    public etd!: string;
    public lineID!: string;
    public orderLinePhase!: number;
    public orderLineStatus!: number;
    public productPictureUrls?: ProductPictureDisplayModel[];
    public qrCodeRef!: string;
    public showNewsLetter!: boolean;
    public totalQuantityPerCw!: number;
    public season!: string;
    public repeat!: string;
    public productQualityRef!: ProductQualityReportModel[];
}