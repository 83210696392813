import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import store from '@/store';
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

// Components
import Steps from '@/views/orderLines/components/steps.vue';
import StepsLoader from '@/views/orderLines/components/stepsLoader.vue';
import ProductPictureUpload from '@/views/orderLines/components/productPictureUpload.vue';
import moment from "moment";
import EditOrderLine from '@/views/orderLines/components/editOrderLine.vue';
import AddTcCertificate from '@/views/orderLines/components/addTcCertificate.vue';

// Services
import { OrderService } from "@/services/orderService";
import { SupplierService } from "@/services/supplierService";
import { UserService } from "@/services/userService";
import { ClientRequestFootPrintData } from '@/services/footPrintRequestService';
import { ClientService } from "@/services/clientService";

// Helpers
import { StepsHelper } from "@/helpers/stepsHelper";

// Models
import { OrderLineStepStatus } from "@/models/orderLineStepStatus";
import { Guid } from "@/models/guid";
import { ProductPictureModel } from "@/models/productPictureModel";
import { ProductPictureDisplayModel } from "@/models/productPictureDisplayModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { CombinedOrderLineModel } from "@/models/combinedOrderLineModel";
import { StepState } from "@/models/stepState";
import { ProductQualityReportModel } from "@/models/productQualityReportModel";
import { NotificationHelper } from "@/helpers/notificationHelper";
import i18n from "@/i18n";
import { UserModel } from "@/models/userModel";
import ViewQualityDocument from "@/views/supplyChainPartners/components/viewQualityDocument.vue";
import { UserPermissionModel } from "@/models/permissionModel";
import { sendMailFpModal } from "@/models/sendMailForFootPrintRequestModel";
import { OrderLineStepParallelModel } from "@/models/orderLineStepParallelModel";
import lodash from "lodash";
import { ClientStepModel } from "@/models/clientStepModel";
import { ConfirmOrderModel } from "@/models/confirmOrderModel";
import { ConfirmOrderCompanyModel } from '@/models/confirmOrderCompanyModel';
import { updatePhaseStatusRequestModel } from "@/models/publishUnpublishOrderModel";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    components: { Steps, StepsLoader, ProductPictureUpload, EditOrderLine, ViewQualityDocument, AddTcCertificate},
    computed: mapState(['userRole', 'userPermissions', 'orderLineStepsLoading', 'orderLineSteps', 'companyType', 'orders', 'companyNameList'])
})
export default class OrderLine extends Vue {

    @Prop()
    private orderLine!: OrderLineDisplayModel;

    @Prop()
    private lineId!: string;

    @Prop()
    private isStepsLoading!: boolean;

    @Prop()
    private selectedTabName!: string;

    @Prop()
    private hasScrollBar!: boolean;

    private orderLineData: OrderLineDisplayModel = new OrderLineDisplayModel();

    private orderService: OrderService;

    private supplierService: SupplierService;

    private supplierBlockChainService: ClientRequestFootPrintData;

    private userService: UserService;

    private clientService: ClientService;

    private orderLineStepsLoading!: boolean;

    private orderLineSteps!: OrderLineStepDisplayModel[];

    //TTD-2620, Agent orderlines changes,
    private orderStepsForAgent: OrderLineStepDisplayModel[] = [];

    private showInfo: boolean = false;

    private showParallelChain: boolean = false;

    private selectedStep: OrderLineStepDisplayModel = new OrderLineStepDisplayModel();

    private showPictureUploadModal: boolean = false;

    private pictureIndex: number = 0;

    private isDeleting: boolean = false;

    private showAddStepModal: boolean = false;

    private orders!: OrderLineDisplayModel[];

    private productQualityReports: ProductQualityReportModel[] = [];

    private showLinkModal: boolean = false;

    private supplierQualityReport!: ProductQualityReportModel[];

    private isReportDeleting: boolean = false;

    private currentReportDocument!: ProductQualityReportModel;

    private showReportPDFModal: boolean = false;

    private selectedReportList: string[] = [];

    private isConfirming: boolean = false;

    private isLoading: boolean = false;

    private allQualityReports: ProductQualityReportModel[] = [];

    private userRole!: string;

    private userPermissions!: UserPermissionModel | null;

    private companyType!: number;

    private isParallelSupplyChainAdded: boolean = false;

    private stepLoading: boolean = false;

    private isRequestFootPrint: boolean = false;

    private isGotoFootPrintPage: boolean = false;

    private isRequestFootPrintActive: string = "FALSE";

    private pictureQualityReportLoading: boolean = false;

    private isAgentCompanyNameUpdated: boolean = false;

    private isOrderLineStepsUpdated: boolean = false;
    //TTD-4209 Sprint-23
    private showNotAllowEdit: boolean = false;
    //TTD-4208 Sprint-23 from
    private isYarnStepMissed: boolean = false;

    private isFabricStepMissed: boolean = false;

    private isProductStepMissed: boolean = false;

    private isFootprintStepMissed: boolean = false;

    private showEditTCModal: boolean = false;

    private companyNameList!: CompanyNameModel[];

    //TTD-4208 Sprint-23 to
    public constructor() {
        super();
        this.orderService = new OrderService();
        this.userService = new UserService();
        this.supplierService = new SupplierService();
        this.supplierBlockChainService = new ClientRequestFootPrintData();
        this.clientService = new ClientService();
    }

    private async created(): Promise<void> {
        this.orderLineData = this.orderLine;
        this.productQualityReports = this.orderLine.productQualityReport;
    }

    private async getAllReports(): Promise<void> {
        const user = this.$store.getters.user as UserModel;
        this.allQualityReports = await this.supplierService.getQualityReportsAsync(user.companyId, this.orderLineData.toCompanyId);
    }

    private async toggleInfo(): Promise<void> {
        this.showInfo = !this.showInfo;
        if(this.showInfo && this.orderLineInfo.productPictures?.length === 0){
            this.pictureQualityReportLoading = true;
            //TTD-2620, Agent orderlines changes
            //TTD-3853, redis not sending all steps due to which calling this method
            this.fetchEditSteps();
            //TTD-2620, Agent orderlines changes
            const result = await this.orderService.getPictureQualityReportAsync(this.orderLineInfo.orderId);
            this.productQualityReports = result.productQualityReport;
            this.pictureQualityReportLoading = false;
        }
    }

    // Sprint 24, TTD-4471
    private get getCerts(): string {
        let certs = '';
        if (this.orderLineInfo.tranCertificate1 !== '' && this.orderLineInfo.tranCertificate2 !== '') {
            certs = this.orderLineInfo.tranCertificate1+', '+ this.orderLineInfo.tranCertificate2;
        } else if (this.orderLineInfo.tranCertificate1 !== '') {
            certs = this.orderLineInfo.tranCertificate1;
        } else if (this.orderLineInfo.tranCertificate2 !== '') {
            certs = this.orderLineInfo.tranCertificate2;
        }
        return certs;
    }

    private async fetchEditSteps(): Promise<void> {
        try {
            this.stepLoading = true;
            if(this.companyType === 5){
                const steps: OrderLineStepDisplayModel[] = this.$store.getters.orderLineSteps;
                const filteredSteps = steps.filter(s => s.orderId === this.orderLine.orderId);
                if (filteredSteps.length === 0) {
                    this.orderStepsForAgent = await this.orderService.getOrderLineStepsAsync(this.orderLine.orderId, this.orderLine.orderLines[0].lineID);
                } else {
                    this.orderStepsForAgent = lodash.cloneDeep(filteredSteps);
                }
            }
            else{
                if(this.orderLineSteps.filter(s => s.orderId === this.orderLine.orderId).length === 0){
                    await this.clientService.getStepsOnOrderID([this.orderLine.orderId]);
                }
            }
        } finally {
            this.stepLoading = false;
        }
    }

    private updateSteps(): void {
        this.fetchEditSteps();
        this.getOrderLineSteps();
    }

    private async openLinkModal(): Promise<void> {
        this.isLoading = true;
        this.showLinkModal = true;
        await this.getAllReports();
        this.supplierQualityReport = this.allQualityReports.filter(report => report.supplierID === this.orderLineData.toCompanyId);
        if(this.productQualityReports.length > 0){
            this.productQualityReports.forEach(report => {
                const index = this.supplierQualityReport.findIndex(r => r.ID === report.ID);
                if(index !== -1){
                    this.supplierQualityReport.splice(index, 1);
                }
            });
        }
        this.supplierQualityReport = this.supplierQualityReport.map(report => new ProductQualityReportModel(report));
        this.isLoading = false;
    }

    private checkQualityReport(id: string): boolean {
        const found = this.selectedReportList.find(item => item === id);
        return found !== undefined ? true : false;
    }

    private closeLinkModal(): void {
        this.showLinkModal = false;
    }

    private addSelectedReport(id: string): void {
        this.selectedReportList.push(id);
    }

    private removeSelectedReport(id: string): void {
        const index = this.selectedReportList.findIndex(item => item === id);
        this.selectedReportList.splice(index, 1);
    }

    private async confirmLinkReport(): Promise<void> {
        this.isConfirming = true;
        const selectedReport: ProductQualityReportModel[] = [];
        this.selectedReportList.forEach(id => {
            const reportData = this.supplierQualityReport.filter(r => r.ID === id)[0];
            reportData.docURL = reportData.fileURL;
            selectedReport.push(reportData);
        });
        const supplier = await this.supplierService.linkScpQualityReportAsync(this.orderLineData.orderId, this.orderLineData.id, selectedReport);
        if(supplier.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.link_report_succeeded').toString());
            supplier.productQualityReport.forEach(report => {
                this.productQualityReports.push(report);
            });
            this.selectedReportList = [];
        }
        this.isConfirming = false;
        this.closeLinkModal();
    }

    private getAllFootprintSteps(): any[] {
        const notifySteps: any[] = [];
        this.steps.forEach(step => {
            if (step.parallelSupplyChain0.length > 0) {
                if (step.parallelSupplyChain0.filter(p => p.stepId === 3).length > 0) {
                    notifySteps.push(step.parallelSupplyChain0.filter(p =>  p.stepId === 3)[0]);
                } else if(step.parallelSupplyChain0.filter(p => p.stepId === 22).length > 0) {
                    notifySteps.push(step.parallelSupplyChain0.filter(p =>  p.stepId === 22)[0]);
                }

                if (step.parallelSupplyChain0.filter(p => (p.stepId === 4 || p.stepId === 5)).length > 0) {
                    notifySteps.push(...step.parallelSupplyChain0.filter(p => (p.stepId === 4 || p.stepId === 5)));
                } else if(step.parallelSupplyChain0.filter(p => p.stepId === 23).length > 0) {
                    notifySteps.push(step.parallelSupplyChain0.filter(p => p.stepId === 23)[0]);
                }
                if (step.parallelSupplyChain0.filter(p => (p.stepId === 6 || p.stepId === 7 || p.stepId === 8 || p.stepId === 31)).length > 0 ) {
                    notifySteps.push(...step.parallelSupplyChain0.filter(p => (p.stepId === 6 || p.stepId === 7 || p.stepId === 8 || p.stepId === 31)));
                }

            }
            if (step.parallelSupplyChain1.length > 0) {
                if (step.parallelSupplyChain1.filter(p => p.stepId === 3).length > 0) {
                    notifySteps.push(step.parallelSupplyChain1.filter(p =>  p.stepId === 3)[0]);
                } else if(step.parallelSupplyChain1.filter(p => p.stepId === 22).length > 0) {
                    notifySteps.push(step.parallelSupplyChain1.filter(p =>  p.stepId === 22)[0]);
                }

                if (step.parallelSupplyChain1.filter(p => (p.stepId === 4 || p.stepId === 5)).length > 0) {
                    notifySteps.push(...step.parallelSupplyChain1.filter(p => (p.stepId === 4 || p.stepId === 5)));
                } else if(step.parallelSupplyChain1.filter(p => p.stepId === 23).length > 0) {
                    notifySteps.push(step.parallelSupplyChain1.filter(p => p.stepId === 23)[0]);
                }
                if (step.parallelSupplyChain1.filter(p => (p.stepId === 6 || p.stepId === 7 || p.stepId === 8 || p.stepId === 31)).length > 0 ) {
                    notifySteps.push(...step.parallelSupplyChain1.filter(p => (p.stepId === 6 || p.stepId === 7 || p.stepId === 8 || p.stepId === 31)));
                }
            }
            if (step.parallelSupplyChain2.length > 0) {
                if (step.parallelSupplyChain2.filter(p => p.stepId === 3).length > 0) {
                    notifySteps.push(step.parallelSupplyChain2.filter(p =>  p.stepId === 3)[0]);
                } else if(step.parallelSupplyChain2.filter(p => p.stepId === 22).length > 0) {
                    notifySteps.push(step.parallelSupplyChain2.filter(p =>  p.stepId === 22)[0]);
                }

                if (step.parallelSupplyChain2.filter(p => (p.stepId === 4 || p.stepId === 5)).length > 0) {
                    notifySteps.push(...step.parallelSupplyChain2.filter(p => (p.stepId === 4 || p.stepId === 5)));
                } else if(step.parallelSupplyChain2.filter(p => p.stepId === 23).length > 0) {
                    notifySteps.push(step.parallelSupplyChain2.filter(p => p.stepId === 23)[0]);
                }
                if (step.parallelSupplyChain2.filter(p => (p.stepId === 6 || p.stepId === 7 || p.stepId === 8 || p.stepId === 31)).length > 0 ) {
                    notifySteps.push(...step.parallelSupplyChain2.filter(p => (p.stepId === 6 || p.stepId === 7 || p.stepId === 8 || p.stepId === 31)));
                }
            }
            if (step.parallelSupplyChain3.length > 0) {
                if (step.parallelSupplyChain3.filter(p => p.stepId === 3).length > 0) {
                    notifySteps.push(step.parallelSupplyChain3.filter(p =>  p.stepId === 3)[0]);
                } else if(step.parallelSupplyChain3.filter(p => p.stepId === 22).length > 0) {
                    notifySteps.push(step.parallelSupplyChain3.filter(p =>  p.stepId === 22)[0]);
                }

                if (step.parallelSupplyChain3.filter(p => (p.stepId === 4 || p.stepId === 5)).length > 0) {
                    notifySteps.push(...step.parallelSupplyChain3.filter(p => (p.stepId === 4 || p.stepId === 5)));
                } else if(step.parallelSupplyChain3.filter(p => p.stepId === 23).length > 0) {
                    notifySteps.push(step.parallelSupplyChain3.filter(p => p.stepId === 23)[0]);
                }
                if (step.parallelSupplyChain3.filter(p => (p.stepId === 6 || p.stepId === 7 || p.stepId === 8 || p.stepId === 31)).length > 0 ) {
                    notifySteps.push(...step.parallelSupplyChain3.filter(p => (p.stepId === 6 || p.stepId === 7 || p.stepId === 8 || p.stepId === 31)));
                }
            }
            if (step.parallelSupplyChain4.length > 0) {
                if (step.parallelSupplyChain4.filter(p => p.stepId === 3).length > 0) {
                    notifySteps.push(step.parallelSupplyChain4.filter(p =>  p.stepId === 3)[0]);
                } else if(step.parallelSupplyChain4.filter(p => p.stepId === 22).length > 0) {
                    notifySteps.push(step.parallelSupplyChain4.filter(p =>  p.stepId === 22)[0]);
                }

                if (step.parallelSupplyChain4.filter(p => (p.stepId === 4 || p.stepId === 5)).length > 0) {
                    notifySteps.push(...step.parallelSupplyChain4.filter(p => (p.stepId === 4 || p.stepId === 5)));
                } else if(step.parallelSupplyChain4.filter(p => p.stepId === 23).length > 0) {
                    notifySteps.push(step.parallelSupplyChain4.filter(p => p.stepId === 23)[0]);
                }
                if (step.parallelSupplyChain4.filter(p => (p.stepId === 6 || p.stepId === 7 || p.stepId === 8 || p.stepId === 31)).length > 0 ) {
                    notifySteps.push(...step.parallelSupplyChain4.filter(p => (p.stepId === 6 || p.stepId === 7 || p.stepId === 8 || p.stepId === 31)));
                }
            }
        });
        if (this.steps.filter(s => s.stepId ===3).length > 0) {
            notifySteps.push(this.steps.filter(s => s.stepId ===3)[0]);
        } else if(this.steps.filter(s => s.stepId ===22).length > 0) {
            notifySteps.push(this.steps.filter(s => s.stepId ===22)[0]);
        }

        if (this.steps.filter(s => s.stepId ===4 || s.stepId === 5).length > 0) {
            notifySteps.push(...this.steps.filter(s => s.stepId ===4 || s.stepId === 5));
        } else if(this.steps.filter(s => s.stepId ===23).length > 0) {
            notifySteps.push(this.steps.filter(s => s.stepId ===23)[0]);
        }

        if (this.steps.filter(s => s.stepId ===6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31).length > 0) {
            notifySteps.push(...this.steps.filter(s => s.stepId ===6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31));
        }

        if (this.steps.filter(s => s.stepId ===10 || s.stepId === 25 || s.stepId === 32).length > 0) {
            let lastStep = this.steps.filter(s => s.stepId ===10 || s.stepId === 25 || s.stepId === 32)[this.steps.filter(s => s.stepId ===10 || s.stepId === 25 || s.stepId === 32).length-1];
            notifySteps.push(lastStep);
        }
        return notifySteps;
    }

    // Updated in TTD-3934 for notifications, sprint21
    private async requestFootPrintData(): Promise<void> {
        this.isConfirming = true;
        const suppliers: OrderCompanyModel[] = store.getters.suppliers;
        const user = this.$store.getters.user as UserModel;
        let uniqueSupplierIds: string[] = [];
        const footprintSteps = this.getAllFootprintSteps();
        const allSteps = footprintSteps.filter(f => f.toCompanyId!==Guid.Empty && f.toCompanyId!==user.companyId).map((s) => s.toCompanyId);
        uniqueSupplierIds = lodash.uniq(allSteps); //to push all steps tocompanyid
        uniqueSupplierIds.push(this.orderLineData.toCompanyId); //to send mail to CP
        this.orderLineData.agentCompanyID !== Guid.Empty ? uniqueSupplierIds.push(this.orderLineData.agentCompanyID) : '';  //to send email to agent
        this.orderLineData.delegateCompanyID !== Guid.Empty ? uniqueSupplierIds.push(this.orderLineData.delegateCompanyID) : '';  //to send email to delegated
        uniqueSupplierIds = lodash.uniq(uniqueSupplierIds);
        const FpPayload: sendMailFpModal = {
            "clientCompanyName": user.companyName,
            "partnerIds": uniqueSupplierIds
        };

        this.isRequestFootPrintActive = "FALSE";
        const requestFp = await this.supplierBlockChainService.requestFootprintData(this.orderLineData.orderId, [], FpPayload);
        if(requestFp.orders && requestFp.success){
            const orderIndex = this.orders.findIndex(o => o.orderId === requestFp.orders.orderId);
            if(orderIndex !== -1){
                this.orders.splice(orderIndex, 1);
                this.orders.splice(orderIndex, 0, requestFp.orders);
            }
            //TTD-4208 Sprint-23
            if(requestFp.orders.orderId === this.orderLineData.orderId){
                this.orderLineData.footprintFlag = requestFp.orders.footprintFlag;
            }
            store.commit("setAllOrders", this.orders);
            this.isRequestFootPrint = false;
            this.isGotoFootPrintPage = true;
            this.isRequestFootPrintActive = "TRUE";
            this.isFootprintStepMissed = false;
        }
        this.isConfirming = false;
    }

    private isFootprintStep(step: any): boolean {
        const allFootprintStepIds = StepsHelper.getFootprintSteps().map(s => s.id);
        if (allFootprintStepIds.includes(step.stepId)) {
            return true;
        }
        return false;
    }

    private redirect(): void{
        this.isGotoFootPrintPage = false;
        this.$router.push({ name: 'Footprint', params: { data: 'true' } });
    }

    // Sprint 24, TTD-4471
    private openEditTC(): void {
        this.showEditTCModal = true;
    }

    // Sprint 24, TTD-4471
    private closeTC(): void {
        this.showEditTCModal = false;
    }

    private async removeLinkReport(index: number): Promise<void> {
        const linkedQualityReports = this.productQualityReports;
        const confirmationText = this.$t("global.notifications.confirm_remove_link_report").toString();
        this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false}).then(async () => {
            this.isReportDeleting = true;
            linkedQualityReports.splice(index, 1);
            const supplier = await this.supplierService.linkScpQualityReportAsync(this.orderLineData.orderId, this.orderLineData.id, linkedQualityReports);
            if(supplier.success){
                NotificationHelper.createSucceededNotification(i18n.t('global.notifications.remove_link_report_succeeded').toString());
                this.productQualityReports = linkedQualityReports;
            }
        }).catch(onrejected => { });
    }

    private openReportPdfModal(selectedReportDocument: ProductQualityReportModel): void {
        this.currentReportDocument = selectedReportDocument;
        this.showReportPDFModal = true;
    }

    private closeReportPDFModal(): void {
        this.showReportPDFModal = false;
    }

    private get isRequestedFPBtnActive(): boolean {
        let isActive = true;
        if(this.orderLineData.footprintFlag === 'TRUE' || this.isRequestFootPrintActive === 'TRUE'){
            isActive = false;
        }
        return isActive;
    }

    private get stepsLoading(): boolean {
        let isloading = false;
        //TTD-2620, Agent orderlines changes, added condition
        if (this.companyType === 5) {
            return false;
        }
        if(this.orderLineSteps.length > 0){
           const steps =  this.orderLineSteps.filter(s => s.orderId === this.orderLine.orderId);
           if(steps.length > 0){
            isloading = false;
           }
           else{
            isloading = this.isStepsLoading ? true : false;
           }
        }
        else{
           isloading = this.isStepsLoading;
        }
        return isloading;
    }

    private getOrderLineSteps(): OrderLineStepDisplayModel[] {
        let steps: OrderLineStepDisplayModel[] = [];
        //TTD-2620, Agent orderlines changes,
        let orderlineSteps = []
        if(this.companyType === 5){
            orderlineSteps = this.orderStepsForAgent.filter(s => s.orderId === this.orderLine.orderId);
        }else{
            orderlineSteps = this.orderLineSteps.filter(s => s.orderId === this.orderLine.orderId);
        }

        let count = 0;
        this.isParallelSupplyChainAdded = false;

        steps = orderlineSteps;
        if(steps.length === 0 && this.isStepsLoading === false){
            const defaultSteps = StepsHelper.getStepsByProductGroup(this.orderLineData.productGroup);
            defaultSteps.filter(s => s.showDefault === true).forEach(s => {
                const orderLineStep: OrderLineStepDisplayModel = new OrderLineStepDisplayModel();
                orderLineStep.id = Guid.Empty;
                orderLineStep.orderId = this.orderLineData.orderId;
                orderLineStep.orderLineId = this.orderLineData.orderLines[0].lineID;
                orderLineStep.orderNumber = this.orderLineData.orderNumber;
                orderLineStep.stepId = s.stepId;
                orderLineStep.displayName = s.displayName;
                orderLineStep.productGroup = this.orderLineData.productGroup;
                orderLineStep.sequence = s.sequence;
                orderLineStep.status = OrderLineStepStatus.Pending;
                orderLineStep.fromCompanyId = Guid.Empty;
                orderLineStep.toCompanyId = Guid.Empty;
                orderLineStep.toCompanyName = "";
                orderLineStep.evidencePhotos = [];
                orderLineStep.showDetails = false;
                orderLineStep.showDetailsCount = 0;
                orderLineStep.showFactoryAddress = false;
                orderLineStep.showNewsLetter = false;
                orderLineStep.showProfile = false;
                orderLineStep.stepHistory = [];
                orderLineStep.stepState = StepState.CREATED_BY_CLIENT;
                orderLineStep.validSocialCertificates = [];
                orderLineStep.validSustainabilityCertificates = [];
                orderLineStep.parallelChainCount = 0;
                orderLineStep.parallelSupplyChain0 = [];
                orderLineStep.parallelSupplyChain1 = [];
                orderLineStep.parallelSupplyChain2 = [];
                orderLineStep.parallelSupplyChain3 = [];
                orderLineStep.parallelSupplyChain4 = [];
                if(s.parallelChain && s.parallelChain.length > 0){
                    const orderLine = lodash.cloneDeep(this.orderLineData);
                    orderLine.toCompanyId = '';
                    orderLine.toCompanyName = '';
                    if (s.showDefaultParallelChain) {
                        s.parallelChain!.forEach((defaultchild, defaultchildIndex) => {
                            const defaultStep = {
                                displayName: i18n.t(`enums.steps.${defaultchild}`).toString(),
                                stepId: defaultchild,
                                sequence: 1,
                                productGroup: s.productGroup,
                                showDefault: true
                            };
                            const newStep: OrderLineStepParallelModel = this.mapOrderLineParallelStep(orderLine, defaultStep, s.stepId);
                            if (defaultchildIndex == 0) {
                                orderLineStep.parallelSupplyChain0 = [newStep];
                            }
                            if (defaultchildIndex == 1) {
                                orderLineStep.parallelSupplyChain1 = [newStep];
                            }
                        })
                    }
                }
                orderLineStep.isLastStep = s.sequence === defaultSteps.filter(s => s.showDefault === true).length ? 'true' : 'false';
                steps.push(orderLineStep);
            });
        }
        steps.forEach(o => {
            o.parallelChainCount = count;
            if (o.parallelSupplyChain0!.length>0) {
                this.isParallelSupplyChainAdded = true;
                o.parallelSupplyChain0.forEach(s => {
                    s.parentStepId = o.stepId.toString();
                });
                count++;
            }
            if (o.parallelSupplyChain1!.length>0) {
                this.isParallelSupplyChainAdded = true;
                o.parallelSupplyChain1.forEach(s => {
                    s.parentStepId = o.stepId.toString();
                });
                count++;
            }
            if (o.parallelSupplyChain2!.length>0) {
                this.isParallelSupplyChainAdded = true;
                o.parallelSupplyChain2.forEach(s => {
                    s.parentStepId = o.stepId.toString();
                });
                count++;
            }
            if (o.parallelSupplyChain3!.length>0) {
                this.isParallelSupplyChainAdded = true;
                o.parallelSupplyChain3.forEach(s => {
                    s.parentStepId = o.stepId.toString();
                });
                count++;
            }
            if (o.parallelSupplyChain4!.length>0) {
                this.isParallelSupplyChainAdded = true;
                o.parallelSupplyChain4.forEach(s => {
                    s.parentStepId = o.stepId.toString();
                });
                count++;
            }
        });

        if(this.showInfo === true && this.isParallelSupplyChainAdded){
            this.showParallelChain = true;
        }
        else{
            this.showParallelChain = false;
        }
        steps.sort((step1, step2) =>{
            return step1.sequence - step2.sequence;
        });
        return steps;
    }

    private get updateOrderLinePermission(): number {
        return this.userPermissions !== null ? this.userPermissions.updateOrderLine : 0;
    }

    private get linkQualityReportPermission(): number {
        return this.userPermissions !== null ? this.userPermissions.linkQualityReport : 0;
    }

    private get linkOrderToFootPrintPermission(): number {
        return this.userPermissions !== null ? this.userPermissions.linkOrderToFootprint : 0;
    }

    private get accessFootprintScreenPermission(): number {
        return this.userPermissions !== null ? this.userPermissions.accessFootprintScreen : 0;
    }

    private get viewFootPrintDataForOrderPermission(): number {
        return this.userPermissions !== null ? this.userPermissions.viewFootPrintDataForOrder : 0;
    }

    private get orderLineInfo(): OrderLineDisplayModel {
        return this.orderLineData;
    }

    private get steps(): OrderLineStepDisplayModel[] {
        const steps = this.getOrderLineSteps().filter(s => (s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && s.stepState !== StepState.NONE));
        let count = 0;
        steps.forEach(o => {
            o.toCompanyName = this.companyNameList.filter(c => c.ID === o.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === o.toCompanyId)[0].name : o.toCompanyName;
            o.parallelChainCount = count;
            if (o.parallelSupplyChain0!.length>0) {
                o.parallelSupplyChain0.forEach(s => {
                    s.toCompanyName = this.companyNameList.filter(c => c.ID === s.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === s.toCompanyId)[0].name : s.toCompanyName;
                });
                count++;
            }
            if (o.parallelSupplyChain1!.length>0) {
                o.parallelSupplyChain1.forEach(s => {
                    s.toCompanyName = this.companyNameList.filter(c => c.ID === s.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === s.toCompanyId)[0].name : s.toCompanyName;
                });
                count++;
            }
            if (o.parallelSupplyChain2!.length>0) {
                o.parallelSupplyChain2.forEach(s => {
                    s.toCompanyName = this.companyNameList.filter(c => c.ID === s.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === s.toCompanyId)[0].name : s.toCompanyName;
                });
                count++;
            }
            if (o.parallelSupplyChain3!.length>0) {
                o.parallelSupplyChain3.forEach(s => {
                    s.toCompanyName = this.companyNameList.filter(c => c.ID === s.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === s.toCompanyId)[0].name : s.toCompanyName;
                });
                count++;
            }
            if (o.parallelSupplyChain4!.length>0) {
                o.parallelSupplyChain4.forEach(s => {
                    s.toCompanyName = this.companyNameList.filter(c => c.ID === s.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === s.toCompanyId)[0].name : s.toCompanyName;
                });
                count++;
            }
        });
        return steps;
    }

    private get productGroup(): string {
        if (this.orderLineInfo.productGroup == null) {
            return "-";
        }

        const productGroupName = this.$t(`enums.product_group.${this.orderLineInfo.productGroup}`).toString().split(/(?=[A-Z])/).join(" ");
        return productGroupName.charAt(0).toUpperCase() + productGroupName.slice(1).toLowerCase();
    }

    private get deliveryDate(): string {
        if (this.orderLineInfo.expectedTimeOfDelivery == null) {
            return "-";
        }
        return moment(this.orderLineInfo.expectedTimeOfDelivery).format("D MMM YYYY");
    }

    private get proofText(): string {
        if (this.orderLineInfo.evidenceCreatedBy !== "" && this.orderLineInfo.evidenceCreatedBy !== undefined && this.orderLine.stepStatus !== OrderLineStepStatus.None && this.orderLine.stepStatus !== undefined) {
            const date = moment(this.orderLineInfo.evidenceCreatedAt).format("D MMM YYYY");
            const name = this.orderLineInfo.evidenceCreatedBy;
            return `${this.$t("pages.order_lines.uploaded_by", [date, name])}`;
        }
        return "-";
    }

    private get compliantText(): string {
       let combined: string[] = [];
        if (this.selectedStep.validSocialCertificates && this.selectedStep.validSustainabilityCertificates) {
          const  validSocialCertificates =
            this.selectedStep.validSocialCertificates
                .map(item => this.$t(`enums.social_certificates.social.${item}`).toString());
            const    validSustainabilityCertificates =
            this.selectedStep.validSustainabilityCertificates
            .map(item => this.$t(`enums.sustainability_certificates.sustainability.${item}`).toString());

             combined = validSocialCertificates.concat(validSustainabilityCertificates);
             return combined.join(", ");
        }
        return this.$t("pages.order_lines.not_scp_compliant").toString();
    }

    private get stepVerifiedText(): string {
        if (this.selectedStep.evidencePhotos.length > 0 && this.orderLineInfo.stepStatus != OrderLineStepStatus.None) {
            if (this.orderLineInfo.stepStatus === OrderLineStepStatus.Accepted) {
                return this.$t("pages.order_lines.verified_next_step").toString();
            } else {
                return this.$t("pages.order_lines.step_not_verified").toString();
            }
        }
        return "-";
    }

    private get geolocationVerifiedText(): string {
        return "-";
    }

    private get productPicturesToShow(): ProductPictureDisplayModel[] {
        if (this.orderLineInfo.productPictures == null) {
            return [];
        }
        const productPictures = this.orderLineInfo.productPictures.filter(p => p.shown === true);
        productPictures.sort((picture1, picture2) => {
            return picture1.sequence - picture2.sequence;
        });
        return productPictures;
    }

    private openPictureUploadModal(): void {
        if(this.userPermissions !== null && (this.userPermissions.uploadProductPicture === undefined || this.userPermissions.uploadProductPicture === 0)) {
            return;
        }
        this.showPictureUploadModal = true;
    }

    private closePictureUploadModal(): void {
        this.showPictureUploadModal = false;
    }

    private showNextPicture(): void {
        const maxIndex = this.productPicturesToShow.length - 1;
        if (this.pictureIndex === maxIndex) {
            this.pictureIndex = 0;
        } else {
            this.pictureIndex++;
        }
    }

    private showPreviousPicture(): void {
        const maxIndex = this.productPicturesToShow.length - 1;
        if (this.pictureIndex === 0) {
            this.pictureIndex = maxIndex;
        } else {
            this.pictureIndex--;
        }
    }

    private async stepSelected(step: OrderLineStepDisplayModel): Promise<void> {
        this.showInfo = true;
        if(this.showInfo === true && this.isParallelSupplyChainAdded === true){
            this.showParallelChain = true;
        }
        else{
            this.showParallelChain = false;
        }
        this.selectedStep = step;
        this.orderLineData.stepStatus = step.status;
        this.orderLineData.scpCompanyName = step.toCompanyName;
        this.orderLineData.evidenceCreatedBy = "";
        if (step.evidencePhotos.length > 0) {
            this.orderLineData.evidenceCreatedAt = step.evidencePhotos[0].createdAt;
        }
        await Promise.all([this.getUserFromEvidenceAsync()]);
    }

    private async getUserFromEvidenceAsync(): Promise<void> {
        if (this.selectedStep?.evidencePhotos.length > 0 && (this.orderLineInfo.evidenceCreatedBy == null || this.orderLineInfo.evidenceCreatedBy === "")) {
            const user = await this.userService.getUserInfoByIdAsync(this.selectedStep.evidencePhotos[0].createdBy!);
            this.orderLineData.evidenceCreatedBy = `${user.firstName} ${user.lastName}`;
        }
        return Promise.resolve();
    }

    private async getSupplierNameAsync(): Promise<void> {
        if (this.orderLineData.scpCompanyName == null || this.orderLineData.scpCompanyName === "" && this.selectedStep.toCompanyId != Guid.Empty) {
            const supplier = await this.supplierService.getSupplierAsync(this.selectedStep.toCompanyId);
            this.orderLineData.scpCompanyName = supplier.companyName;
        }
        return Promise.resolve();
    }

    private getImageSrc(picture: ProductPictureDisplayModel): string {
        if (picture == null) {
            return "";
        }
        if (picture.docUrl.startsWith("http")) {
            return picture.docUrl;
        }
        return `data:${picture.fileType};base64,${picture.docUrl}`;
    }

    private selectedPicturesUpdated(pictures: ProductPictureModel[]): void {
        this.orderLineInfo.productPictures = [];
        this.pictureIndex = 0;
        pictures.forEach(p => {
            this.orderLineInfo.productPictures!.push({
                id: p.id,
                fileName: p.name,
                fileType: p.type,
                docUrl: p.docUrl,
                shown: p.shown,
                sequence: p.sequence
            });
        });
    }

    private remove(): void {
        const confirmationText = this.$t('pages.order_lines.delete_confirmation').toString();
        this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false}).then(() => {
            this.isDeleting = true;
            this.deleteOrder([this.orderLine.orderId]);
        }).catch(onrejected => {
            return "";
        });
    }

    private openAddStepModal(requested:string): void{
        // TTD-4209 Sprint-23
        if(requested === 'TRUE'){
            this.showNotAllowEdit = true;
        }else{
            this.isFootprintStepMissed = false;
            this.showAddStepModal = true;
        }

    }

    private async closeAddStepModal(edited: boolean, isDataChanged: boolean): Promise<void>{
        if(edited){
            this.getOrderLineSteps();
            this.showAddStepModal = false;
        }
        else if(edited === false && isDataChanged === true){
            this.stepLoading = true;
            this.showAddStepModal = false;
            await this.orderService.getOrderLineStepsAsync(this.orderLine.orderId, this.orderLine.orderLines[0].lineID);
            this.getOrderLineSteps();
            this.stepLoading = false;
        }
        else{
            this.showAddStepModal = false;
        }
        if(this.isAgentCompanyNameUpdated){
            const user = this.$store.getters.user as UserModel;
            const confirmOrders: ConfirmOrderModel[] = [];
            const confirmOrder: ConfirmOrderModel = new ConfirmOrderModel();
            confirmOrder.createdAt = this.orderLine.createdAt;
            // confirmOrder.createdAt = moment(this.orderLine.createdAt).toISOString(); Commenting this due to point highlighted in TTD-4738 point-3
            confirmOrder.createdBy = user.userId;
            confirmOrder.updatedAt = moment(this.orderLine.updatedAt).toISOString();
            confirmOrder.fromCompanyID = this.orderLine.fromCompanyUser.ID;
            confirmOrder.fromCompanyUser = this.orderLine.fromCompanyUser;
            confirmOrder.agentCompanyUser = this.orderLine.agentCompanyUser;
            confirmOrder.delegateCompanyID = this.orderLine.delegateCompanyID;
            confirmOrder.delegateCompanyUser = this.orderLine.delegateCompanyUser;
            confirmOrder.toCompanyID = this.orderLine.toCompanyId;
            const suppliers: OrderLineDisplayModel[] = store.getters.suppliers;
            const supplier = suppliers.filter(s => s.id === this.orderLine.toCompanyId)[0];
            const supplierObj = new ConfirmOrderCompanyModel(supplier.id, supplier.country, supplier.companyName);
            confirmOrder.toCompany = supplierObj;
            confirmOrder.orderLines = this.orderLine.orderLines;
            confirmOrder.ID = this.orderLine.orderId;
            confirmOrder.orderNumber = this.orderLine.orderNumber;
            confirmOrder.orderRefID = this.isOrderLineStepsUpdated ? uuidv4() : this.orderLine.orderRefId;
            confirmOrder.agentCompanyID = this.orderLine.agentCompanyID;
            confirmOrders.push(confirmOrder);
            const orderResponse = await this.orderService.confirmOrderAsync(confirmOrders);
            if(orderResponse && orderResponse.orders.length > 0){
                this.isAgentCompanyNameUpdated = false;
                this.isOrderLineStepsUpdated = false;
            }
        }
        else{
            if(this.isOrderLineStepsUpdated) {
                const orderIds: updatePhaseStatusRequestModel = {
                    orderIdList: [this.orderLine.orderId]
                }
                const response = await this.supplierService.updateOrderlinePhaseStatus(orderIds, this.orderLine.orderLines[0].orderLinePhase, this.orderLine.orderLines[0].orderLineStatus);
                if(response.success){
                    this.isAgentCompanyNameUpdated = false;
                    this.isOrderLineStepsUpdated = false;
                }
            }
        }
    }

    private mapOrderLineParallelStep(order: OrderLineDisplayModel, defaultStep: ClientStepModel, stepId: number): OrderLineStepParallelModel {
        return {
          id: Guid.Empty,
          parentStepId: stepId.toString(),
          orderId: order.orderId,
          orderNumber: order.orderNumber,
          orderLineId: order.orderLines[0].lineID,
          stepId: defaultStep.stepId,
          productGroup: order.productGroup,
          displayName: defaultStep.displayName,
          sequence: 1,
          fromCompanyId: Guid.Empty,
          toCompanyId: Guid.Empty,
          toCompanyName: '',
          showDetails: false,
          showNewsLetter: false,
          showFactoryAddress: false,
          showProfile: false,
          showDetailsCount: 0,
          rejectionReason: 'null',
          stepState: StepState.NONE,
          stepHistory: [],
          status: OrderLineStepStatus.Pending,
          evidencePhotos: [],
          validSocialCertificates: [],
          validSustainabilityCertificates: [],
          compliantAtVerificationDate: false,
          currentlyValidCertificates: null,
          isLastStep: 'false',
          footprintRefID: Guid.Empty,
          footprintRefNo: '',
          footprintRefNoUpdatedBy: Guid.Empty
        }
      }

    private updateAgentData(isAgentUpdated: boolean, isOrderLineStepsUpdated: boolean){
        this.isAgentCompanyNameUpdated = isAgentUpdated;
        this.isOrderLineStepsUpdated = isOrderLineStepsUpdated;
    }

    private get isAgentOrClientNameExist(): boolean{
        if(this.companyType === 5){
            if(this.orderLineData.fromCompanyName !== ''){
                return true
            }
        }else{
            if(this.orderLineData.agentCompanyUser.name !== ''){
                return true
            }
        }
        return false;
    }
    //TTD-4208 Sprint-23
    private checkFootprintSteps(){
        this.isYarnStepMissed = false;
        this.isFabricStepMissed = false;
        this.isProductStepMissed = false;
        if(this.steps.findIndex(s => s.stepId === 3) === -1 && this.steps.findIndex(s => s.stepId === 22) === -1 && !this.yarnExistInParallel()){
            this.isYarnStepMissed = true;
        }
        if(this.steps.findIndex(s => s.stepId === 4) === -1 && this.steps.findIndex(s => s.stepId === 5) === -1 && this.steps.findIndex(s => s.stepId === 23) === -1 && !this.fabricExistInParallel()){
            this.isFabricStepMissed = true;
        }
        if(this.steps.findIndex(s => s.stepId === 10) === -1 && this.steps.findIndex(s => s.stepId === 25) === -1 && this.steps.findIndex(s => s.stepId === 32) === -1){
            this.isProductStepMissed = true;
        }
        this.steps.forEach(o => {
                if (o.parallelSupplyChain0!.length>0) {
                    if(o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 3) === -1 && o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 22) === -1 && (o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 4) !== -1 ||
                        o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 5) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 23) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 6) !== -1 ||
                        o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 7) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 8) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 31) !== -1)
                        && o.stepId!==3 && o.stepId!==22 && (o.stepId!==4 && o.stepId!==5 && o.stepId!== 23 && !this.isYarnStepLinked(o))){
                        this.isYarnStepMissed = true;
                    }
                    if(o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 4) === -1 && o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 5) === -1 && o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 23) === -1 &&
                        (o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 3) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 22) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 6) !== -1 ||
                        o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 7) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 8) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 31) !== -1)
                        && o.stepId!==4 && o.stepId!==5 && o.stepId!== 23 && (o.stepId !== 3 && o.stepId !==22 && !this.isFabricStepLinked(o))){
                        this.isFabricStepMissed = true;
                    }
                }
            if (o.parallelSupplyChain1!.length>0) {
                if(o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 3) === -1 && o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 22) === -1 && (o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 4) !== -1 ||
                    o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 5) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 23) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 6) !== -1 ||
                    o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 7) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 8) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 31) !== -1)
                    && o.stepId!==3 && o.stepId!==22 && (o.stepId!==4 && o.stepId!==5 && o.stepId!== 23 && !this.isYarnStepLinked(o))){
                    this.isYarnStepMissed = true;
                }
                if(o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 4) === -1 && o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 5) === -1 && o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 23) === -1 &&
                    (o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 3) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 22) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 6) !== -1 ||
                    o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 7) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 8) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 31) !== -1)
                    && o.stepId!==4 && o.stepId!==5 && o.stepId!== 23 && (o.stepId !== 3 && o.stepId !==22 && !this.isFabricStepLinked(o))){
                    this.isFabricStepMissed = true;
                }
            }
            if (o.parallelSupplyChain2!.length>0) {
                if(o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 3) === -1 && o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 22) === -1 && (o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 4) !== -1 ||
                    o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 5) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 23) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 6) !== -1 ||
                    o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 7) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 8) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 31) !== -1)
                    && o.stepId!==3 && o.stepId!==22 && (o.stepId!==4 && o.stepId!==5 && o.stepId!== 23 && !this.isYarnStepLinked(o))){
                    this.isYarnStepMissed = true;
                }
                if(o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 4) === -1 && o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 5) === -1 && o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 23) === -1 &&
                    (o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 3) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 22) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 6) !== -1 ||
                    o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 7) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 8) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 31) !== -1)
                    && o.stepId!==4 && o.stepId!==5 && o.stepId!== 23 && (o.stepId !== 3 && o.stepId !==22 && !this.isFabricStepLinked(o))){
                    this.isFabricStepMissed = true;
                }
            }
            if (o.parallelSupplyChain3!.length>0) {
                if(o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 3) === -1 && o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 22) === -1 && (o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 4) !== -1 ||
                    o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 5) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 23) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 6) !== -1 ||
                    o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 7) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 8) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 31) !== -1)
                    && o.stepId!==3 && o.stepId!==22 && (o.stepId!==4 && o.stepId!==5 && o.stepId!== 23 && !this.isYarnStepLinked(o))){
                    this.isYarnStepMissed = true;
                }
                if(o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 4) === -1 && o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 5) === -1 && o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 23) === -1 &&
                    (o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 3) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 22) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 6) !== -1 ||
                    o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 7) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 8) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 31) !== -1)
                     && o.stepId!==4 && o.stepId!==5 && o.stepId!== 23 && (o.stepId !== 3 && o.stepId !==22 && !this.isFabricStepLinked(o))){
                    this.isFabricStepMissed = true;
                }
            }
            if (o.parallelSupplyChain4!.length>0) {
                if(o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 3) === -1 && o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 22) === -1 && (o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 4) !== -1 ||
                    o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 5) !== -1 || o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 23) !== -1 || o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 6) !== -1 ||
                    o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 7) !== -1 || o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 8) !== -1 || o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 31) !== -1)
                    && o.stepId!==3 && o.stepId!==22 && (o.stepId!==4 && o.stepId!==5 && o.stepId!== 23 && !this.isYarnStepLinked(o))){
                    this.isYarnStepMissed = true;
                }
                if(o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 4) === -1 && o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 5) === -1 && o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 23) === -1 &&
                    (o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 3) !== -1 || o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 22) !== -1 || o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 6) !== -1 ||
                    o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 7) !== -1 || o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 8) !== -1 || o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 31) !== -1)
                    && o.stepId!==4 && o.stepId!==5 && o.stepId!== 23 && (o.stepId !== 3 && o.stepId !==22 && !this.isFabricStepLinked(o))){
                    this.isFabricStepMissed = true;
                }
            }
        });
        if(this.isYarnStepMissed || this.isFabricStepMissed || this.isProductStepMissed){
            this.isFootprintStepMissed = true;
        }else{
            this.isRequestFootPrint = true;
        }
    }

    private yarnExistInParallel(): boolean {
        let isYarnExist = false;
        this.steps.forEach(o => {
            if(this.isFootprintStep(o) && ((o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 3) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 22) !== -1) ||
            (o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 3) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 22) !== -1) ||
            (o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 3) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 22) !== -1) ||
            (o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 3) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 22) !== -1) ||
            (o.parallelSupplyChain3.findIndex(p4 => p4.stepId === 3) !== -1 || o.parallelSupplyChain3.findIndex(p4 => p4.stepId === 22) !== -1))){
                isYarnExist = true;
            }
        });
        return isYarnExist;
    }

    private fabricExistInParallel(): boolean {
        let isFabricExist = false;
        this.steps.forEach(o => {
            if(this.isFootprintStep(o) && ((o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 4) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 5) !== -1 || o.parallelSupplyChain0.findIndex(p0 => p0.stepId === 23) !== -1) ||
            (o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 4) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 5) !== -1 || o.parallelSupplyChain1.findIndex(p1 => p1.stepId === 23) !== -1) ||
            (o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 4) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 5) !== -1 || o.parallelSupplyChain2.findIndex(p2 => p2.stepId === 23) !== -1) ||
            (o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 4) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 5) !== -1 || o.parallelSupplyChain3.findIndex(p3 => p3.stepId === 23) !== -1) ||
            (o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 4) !== -1 || o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 5) !== -1 || o.parallelSupplyChain4.findIndex(p4 => p4.stepId === 23) !== -1))){
                isFabricExist = true;
            }
        });
        return isFabricExist;
    }

    private isFabricStepLinked(mcStep:OrderLineStepDisplayModel): boolean {
        if((mcStep.stepId===3 || mcStep.stepId===22) && ((mcStep.parallelSupplyChain0.findIndex(p0 => p0.stepId === 4) !== -1 || mcStep.parallelSupplyChain0.findIndex(p0 => p0.stepId === 5) !== -1 || mcStep.parallelSupplyChain0.findIndex(p0 => p0.stepId === 23) !== -1) ||
            (mcStep.parallelSupplyChain1.findIndex(p1 => p1.stepId === 4) !== -1 || mcStep.parallelSupplyChain1.findIndex(p1 => p1.stepId === 5) !== -1 || mcStep.parallelSupplyChain1.findIndex(p1 => p1.stepId === 23) !== -1) ||
            (mcStep.parallelSupplyChain2.findIndex(p2 => p2.stepId === 4) !== -1 || mcStep.parallelSupplyChain2.findIndex(p2 => p2.stepId === 5) !== -1 || mcStep.parallelSupplyChain2.findIndex(p2 => p2.stepId === 23) !== -1) ||
            (mcStep.parallelSupplyChain3.findIndex(p3 => p3.stepId === 4) !== -1 || mcStep.parallelSupplyChain3.findIndex(p3 => p3.stepId === 5) !== -1 || mcStep.parallelSupplyChain3.findIndex(p3 => p3.stepId === 23) !== -1) ||
            (mcStep.parallelSupplyChain3.findIndex(p4 => p4.stepId === 4) !== -1 || mcStep.parallelSupplyChain4.findIndex(p4 => p4.stepId === 5) !== -1 || mcStep.parallelSupplyChain4.findIndex(p4 => p4.stepId === 23) !== -1))){
                return true;
            }
        return false;
    }

    private isYarnStepLinked(mcStep:OrderLineStepDisplayModel): boolean {
        if((mcStep.stepId === 4 || mcStep.stepId === 5 || mcStep.stepId === 23) && ((mcStep.parallelSupplyChain0.findIndex(p0 => p0.stepId === 3) !== -1 || mcStep.parallelSupplyChain0.findIndex(p0 => p0.stepId === 22) !== -1) ||
            (mcStep.parallelSupplyChain1.findIndex(p1 => p1.stepId === 3) !== -1 || mcStep.parallelSupplyChain1.findIndex(p1 => p1.stepId === 22) !== -1) ||
            (mcStep.parallelSupplyChain2.findIndex(p2 => p2.stepId === 3) !== -1 || mcStep.parallelSupplyChain2.findIndex(p2 => p2.stepId === 22) !== -1) ||
            (mcStep.parallelSupplyChain3.findIndex(p3 => p3.stepId === 3) !== -1 || mcStep.parallelSupplyChain3.findIndex(p3 => p3.stepId === 22) !== -1) ||
            (mcStep.parallelSupplyChain3.findIndex(p4 => p4.stepId === 3) !== -1 || mcStep.parallelSupplyChain4.findIndex(p4 => p4.stepId === 22) !== -1))){
                return true;
            }
        return false;
    }

    @Emit()
    private deleteOrder(orderId: string[]): void {}

    @Emit()
    private reloadOrderline(): void {}

    @Emit()
    private selectOrder(): void {}
}
