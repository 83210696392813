import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { Vue, Component, Prop } from "vue-property-decorator";
import { mapState } from "vuex";

// Components
import { OrderService } from "@/services/orderService";
import { SupplierService } from "@/services/supplierService";

// Services
import { MiddlewareService } from "@/services/middlewareService";
import { UserPermissionModel } from "@/models/permissionModel";
import { PublishUnpublishModel } from "@/models/publishUnpublishOrderModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { OrderLineStepStatus } from "@/models/orderLineStepStatus";
import { Guid } from "@/models/guid";
import { NotificationHelper } from "@/helpers/notificationHelper";
import i18n from "@/i18n";

@Component({
    computed: mapState(['userRole', 'userPermissions', 'companyType', 'totalQrCodeLive'])
})
export default class QrOrderLine extends Vue {

    @Prop()
    private orderLine!: OrderLineDisplayModel;

    private orderLineData: OrderLineDisplayModel = new OrderLineDisplayModel();

    private orderService: OrderService;
    private middlewareService: MiddlewareService;
    private supplierService: SupplierService;

    private showPublishButton: boolean = true;

    private orderStatusChanging: boolean = false;
    private accessToken: string = "";

    private userRole!: string;

    private userPermissions!: UserPermissionModel | null;

    private companyType!: number;

    private totalQrCodeLive!: number;

    public constructor() {
        super();
        this.orderService = new OrderService();
        this.middlewareService = new MiddlewareService();
        this.supplierService = new SupplierService();
    }

    private async created(): Promise<void> {
        this.orderLineData = this.orderLine;
        this.showPublishButton =
            this.orderLineData.orderLineStatus !== 5
            && this.orderLineData.orderLineStatus !== 6;
    }

  private async showPreview(): Promise<void> {
        if (this.accessToken==''){
            const response = await this.middlewareService.signinConsumerApp();
            if (response.response_type == "success") {
                this.accessToken = response.access_token;
            }
        }
        window.open(`${process.env.VUE_APP_Consumer_app_preview_url}?o=${this.orderLineData.orderId}&l=${this.orderLineData.id}&at=${this.accessToken}`, "_blank");
    }

    private async publish(): Promise<void> {
        const confirmationText = this.$t('pages.qr_codes.publish_confirm').toString();
        this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false}).then(async () => {
            try {
                this.orderStatusChanging = true;
                const steps = this.$store.getters.orderLineSteps as OrderLineStepDisplayModel[];
                const filteredSteps = steps.filter(s => s.orderId === this.orderLineData.orderId);
                let hasStepEvidence: boolean = false;
                if(filteredSteps.length > 0){
                    const index = filteredSteps.findIndex(s => s.status !== OrderLineStepStatus.Accepted);
                    if(index === -1){
                        hasStepEvidence = true;
                    }
                }
                const publishPhaseStatus: PublishUnpublishModel = {
                    lineID: this.orderLineData.id,
                    orderLinePhase: 2,
                    orderLineStatus: hasStepEvidence ? 6 : 5
                };
                const result = await this.supplierService.updatePublishOrderlinePhaseStatus(this.orderLineData.orderId, publishPhaseStatus);
                if(result.success){
                    NotificationHelper.createSucceededNotification(i18n.t('global.notifications.publish_succeeded').toString());
                    this.showPublishButton = false;
                    this.$store.commit("setAllQrCodeCount", this.totalQrCodeLive + 1);
                }
            }
            finally {
                this.orderStatusChanging = false;
            }
        }).catch(onrejected => { });
    }

    private async unpublish(): Promise<void> {
        const confirmationText = this.$t('pages.qr_codes.unpublish_confirm').toString();
        this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false}).then(async () => {
            try {
                this.orderStatusChanging = true;
                const steps = this.$store.getters.orderLineSteps as OrderLineStepDisplayModel[];
                const filteredSteps = steps.filter(s => s.orderId === this.orderLineData.orderId);
                const hasStepEvidence: boolean = false;
                let phase = 1;
                let status = 0;
                if(filteredSteps.length > 0){
                    if(filteredSteps.findIndex(s => s.toCompanyId === Guid.Empty) === -1){
                        if(filteredSteps.findIndex(s => s.status !== OrderLineStepStatus.Accepted) === -1){
                            phase = 2;
                            status = 4;
                        }
                        else {
                            if(filteredSteps.findIndex(s => s.status === OrderLineStepStatus.WaitingForApproval) !== -1){
                                phase = 1;
                                status = 2;
                            }
                            else{
                                phase = 1;
                                status = 1;
                            }
                        }
                    }
                }
                const publishPhaseStatus: PublishUnpublishModel = {
                    lineID: this.orderLineData.id,
                    orderLinePhase: phase,
                    orderLineStatus: status
                };
                const result = await this.supplierService.updatePublishOrderlinePhaseStatus(this.orderLineData.orderId, publishPhaseStatus);
                if(result.success){
                    NotificationHelper.createSucceededNotification(i18n.t('global.notifications.unpublish_succeeded').toString());
                    this.showPublishButton = true;
                    this.$store.commit("setAllQrCodeCount", this.totalQrCodeLive - 1);
                }
            }
            finally {
                this.orderStatusChanging = false;
            }
        }).catch(onrejected => { });
    }

    private get orderLineInfo(): OrderLineDisplayModel {
        return this.orderLineData;
    }

    private get publishPermission(): number {
        return this.userPermissions !== null ? this.userPermissions.publishUnpublishQrCode : 0;
    }
}