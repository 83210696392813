import { Vue, Component } from "vue-property-decorator";

// Components
import Authpage from "@/components/general/authpage.vue";

@Component({
  components: { Authpage }
})

export default class SignupForm extends Vue {

  private showInviteFailed: boolean = false;
  private emailAddress: string = "";

  public constructor() {
    super();
  }

  private otpCode: string = "";

  private async created(): Promise<void> {
    if (this.$route.params.resendInvite === "FAILED") {
      this.showInviteFailed = true;
      this.emailAddress = this.$route.params.resendInviteEmail;
    }
  }
}
