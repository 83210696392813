import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { Drag, Drop, DropList } from "vue-easy-dnd";

import i18n from '@/i18n';
import { TopBrandModel } from "@/models/topBrandModel";
import { TopBrandModelSteps } from "@/models/topBrandModelSteps";

@Component({
  components: { Drag, Drop, DropList }
})

export default class AddNextSteps extends Vue {

  @Prop()
  private propTopBrand!: TopBrandModel;

  public constructor() {
    super();
  }

  private isValidUrl(url: string): boolean {
    var res = url.match(/^[a-z][a-z0-9+.-]*:/);
    return (res !== null);
  }

  @Emit()
  private close() {
  }

}