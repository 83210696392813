import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import lodash from "lodash";
import { mapState } from "vuex";

// Components
import SearchSupplier from '@/components/general/search-supplier/searchSupplier.vue';
import SearchPartner from '@/components/general/search-partner/searchPartner.vue';
import CreateSupplyChain from '@/views/home/components/createSupplyChain.vue';
import CreateBulkOrder from '@/views/home/components/bulkOrder.vue';

// Models
import { DropdownModel } from '@/models/dropdownModel';
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { Guid } from "@/models/guid";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { UserPermissionModel } from "@/models/permissionModel";

// Services
import { OrderService } from '@/services/orderService';

@Component({
    components: { SearchSupplier, CreateSupplyChain, CreateBulkOrder, SearchPartner },
    computed: mapState(['userRole', 'userPermissions', 'companyType'])
})
export default class PendingOrderLine extends Vue {

    @Prop()
    private pendingType!: string;

    @Prop()
    private selectedCount!: number;

    @Prop()
    private order!: CombinedOrderLineModel;

    @Prop()
    private suppliers!: OrderCompanyModel[];

    @Prop()
    private supplierDropdownData!: DropdownModel[];

    @Prop()
    private suppliersLoading!: boolean;

    @Prop()
    private isDeleted!: boolean;

    @Prop()
    private checkIfSelected!: boolean;

    @Prop()
    private orderConfirming!: boolean;

    @Prop()
    private agent!: boolean;

    @Prop()
    private isOrdersConfirmed!: boolean;

    private orderService: OrderService;

    //TTD-2620, Agent orderlines changes, 
    private companyType!: number;

    private editOrder: CombinedOrderLineModel = new CombinedOrderLineModel();

    private showSearch: boolean = false;

    private isConfirming: boolean = false;

    private isDeleting: boolean = false;

    private showAddStepModal: boolean = false;

    private showBulkOrderModal: boolean = false;

    private userRole!: string;

    private userPermissions!: UserPermissionModel | null;

    private pendingOrdersBlkSelect: boolean = false;

    private reviewSelectType: string = "customise"

    private blkOrdersIds: string[] = [];
    
    private isForwardToCp: boolean = false;

    private agentName: string = '';

    public constructor() {
        super();
        this.orderService = new OrderService();
    }

    private created(): void {
        this.editOrder = this.order;
    }

    private get statusColor(): string {
        return this.supplierConnected ? "green" : "red";
    }

    private get supplierName(): string {
        if (this.editOrder.supplierName == null) {
            return this.$t("pages.home.unknown").toString();
        }
        return this.editOrder.supplierName;
    }

    private get userName(): string {
        if (this.editOrder.userName == null) {
            return this.$t("pages.home.unknown").toString();
        }
        return this.editOrder.userName;
    }

    private get supplierConnected(): boolean {

        const supplierConnected = this.suppliers.findIndex(s => s.id === this.editOrder.supplierId && s.isTier1 === true) !== -1 ? true : false;
        if (!supplierConnected) {
            this.editOrder.supplierId = Guid.Empty;
        }
        return supplierConnected;
    }

    private get tier1Supplier(): DropdownModel[] {
        const tier1Supplier = this.suppliers.filter(t => t.isTier1 === true);
        return tier1Supplier.map(s => ({ value: s.id, text: s.isCompliant ? `${s.companyName} (${s.country})` : `${s.companyName} (${this.$t('pages.home.pending_invite')})` }));
    }

    private get assignSCPPermission(): number {
        return this.userPermissions !== null ? this.userPermissions.assignSCPOrderLine : 0;
    }

    private get confirmOrderLinePermission(): number {
        return this.userPermissions !== null ? this.userPermissions.confirmOrderLine : 0;
    }

    private get deleteOrderLinePermission(): number {
        return this.userPermissions !== null ? this.userPermissions.deleteOrderLine : 0;
    }

    private closeSearchHandler(): void {
        this.showSearch = false;
    }

    private openSearchSelectHandler(type: string): void {
        if (this.isDeleting) {
            return;
        }
        this.pendingOrdersBlkSelect = true;
        if(type === 'agent'){
            this.reviewSelectType = type;
        }
    }

    private setSupplierNameHandler(value: string): void {
        this.pendingOrdersBlkSelect = false
        const supplier = this.suppliers.filter(s => s.id === value)[0];
        this.editOrder.supplierId = supplier.id;
        this.editOrder.supplierName = supplier.companyName;
        this.editOrder.supplierCountry = supplier.country;
        this.editOrder.newlySet = true;
        this.showSearch = false;
        this.pendingOrdersBlkSelect = false;
        this.selectedBulkOrdersIds(this.blkOrdersIds)
    }

    private invitationSendHandler(): void {
        this.suppliersChanged();
    }

    private remove(): void {
        if (this.isConfirming) {
            return;
        }
        const confirmationText = this.$t('pages.home.delete_confirmation').toString();
        this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false}).then(() => {
            this.isDeleting = true;
            this.deleteOrders(this.editOrder.orderIds);
        }).catch(onrejected => { });
    }

    private openAddStepModal(type:string): void {
        if(type === "customise"){
            this.reviewSelectType = "customise"
        }else{
            this.reviewSelectType = "confirm"
        }
        if (this.editOrder.supplierId !== undefined) {
            this.editOrder.orderDetails.forEach(item => {
                item.toCompanyId = this.companyType !== 5 ? this.editOrder.supplierId : item.toCompanyId;
                item.toCompanyName = this.editOrder.supplierName;
            });
        }
        if (this.editOrder.orderCount > 1) {
            this.showBulkOrderModal = true;
        }
        else if (this.editOrder.orderCount === 1 && this.reviewSelectType === "confirm") {
            const agentDetaisl = new OrderCompanyModel("","","",false,false);
            this.editOrder.isOrdersConfirmed = true;
            this.confirmed([this.editOrder], [], false, agentDetaisl);
        } else {
            this.showAddStepModal = true;
        }
    }

    private closeAddStepModal(created: boolean): void {
        this.showAddStepModal = false;
        this.showBulkOrderModal = false;
        this.pendingOrdersBlkSelect = false;
        if (created) {
            this.reloadOrderLines();
        }
        this.createSupplyModalOpen(false);
    }

    private async save(bulkOrders:CombinedOrderLineModel,selectedOrders:string[], isSupplierUpdated:boolean): Promise<void> {
        // this.showBulkOrderModal = false;
        if (this.isDeleting) {
            return;
        }
        // this.isConfirming = true;
        const blkOrder = [bulkOrders]
        const orders = blkOrder[0]
        this.editOrder.isOrdersConfirmed = true;
        let newBulkOrder: CombinedOrderLineModel[] = []
        let count = this.editOrder.orderDetails.length;
        if(orders.orderDetails.length === selectedOrders.length){
            newBulkOrder = [orders];
        }else{
            const selectedOrderDetails = orders.orderDetails.filter((o)=> selectedOrders.includes(o.orderId));
            orders.orderDetails = [...selectedOrderDetails]
            orders.orderIds = [...selectedOrders]
            orders.orderCount = count
            newBulkOrder = [orders]
        }
        const agentDetaisl = new OrderCompanyModel("","","",false,false);
        if(isSupplierUpdated){
            this.createSupplyModalOpen(isSupplierUpdated);
        }
        this.confirmed(newBulkOrder, [], this.editOrder.orderDetails.length > 1 ? true : false, agentDetaisl);
    }

    private async confirmedArray(order: CombinedOrderLineModel, confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean, agentDetails:OrderCompanyModel): Promise<void> {
        const newBulkOrder: CombinedOrderLineModel[] = [order];
        this.confirmed(newBulkOrder, confirmOrderLineStep, isBulk, agentDetails);
    }

    private showSearchForBulk(orderIds:string[]){
        this.blkOrdersIds = [];
        this.blkOrdersIds = orderIds;
        // this.pendingOrdersBlkSelect = false;
        this.showSearch = true;
    }

    private showBlkSelectModal(bool: boolean){
        this.pendingOrdersBlkSelect = bool
    }

    // private showWarning(val: boolean, agentName:string){
    //     this.showBulkOrderModal = false;
    //     this.isForwardToCp = val;
    //     this.agentName = agentName;
    // }

    @Emit()
    private reloadOrderLines(): void {
    }

    @Emit()
    private confirmed(bulkOrders: CombinedOrderLineModel[], confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean, agentDetails:OrderCompanyModel): void {
    }

    @Emit()
    private deleteOrders(orderIds: string[]): void {
    }

    @Emit()
    private suppliersChanged(): void {
    }

    @Emit()
    private toggleOrderSelection(): void {
    }

    @Emit()
    private selectedBulkOrdersIds(orderIds:string[]):void{}

    @Emit()
    private createSupplyModalOpen(create:boolean):void{}

    @Watch("order", { deep: true })
    private orderChanged(): void {
        this.editOrder = this.order;
    }

    @Watch("isDeleted")
    private delete(): void {
        if (this.isDeleted) {
            this.isDeleting = false;
        }
    }

    @Watch("isOrdersConfirmed")
    private ordersConfirme(): void {
        if(this.isOrdersConfirmed && this.showBulkOrderModal){
            this.showBulkOrderModal = false;
        }
    }
}