import { Vue, Component, Prop, Emit } from "vue-property-decorator";

// Components
import PendingComplianceDocuments from "@/views/compliance/components/pendingComplianceDocuments.vue";
import SentComplianceDocuments from "@/views/compliance/components/sentComplianceDocuments.vue";
import Certificates from "@/views/compliance/components/certificates.vue";
import TransactionCertificates from "@/views/compliance/components/transactionCertificates.vue"

// Services
import { ComplianceService } from "@/services/complianceService";

// Modals
import { ComplianceDocResponseModal } from "@/models/complianceDocResponseModal";
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";

@Component({
	components: { PendingComplianceDocuments, SentComplianceDocuments, Certificates, TransactionCertificates }
})
export default class ComplianceDocuments extends Vue {

	@Prop()
	private pendingDocs!: ComplianceDocResponseModal[];

	@Prop()
	private sentArchiveDocs!: ComplianceDocSentResponseModel[];

	@Prop()
	private isLoading!: boolean;

	@Prop()
	private selectedTab!: number;

	@Prop()
	private selectedBrand!: string;
	//Sprint-24 TTD-4483
	@Prop()
	private selectedType!: string;

    @Prop()
	private selectedPartner!: string;

	@Prop()
	private selectedScps!: [];

	@Prop()
	private isSentArchiveLoading!: boolean;

	@Prop()
	private searchFilter!: string;

	@Prop()
	private orders!: OrderLineDisplayModel[];

	private complianceService: ComplianceService;

    private showPDFModal: boolean = false;

    private currentDocument!: ComplianceDocResponseModal;

	public constructor() {
		super();
		this.complianceService = new ComplianceService();
	}

	private get pendingDocuments(): ComplianceDocResponseModal[]{
		let documents = this.pendingDocs.filter(d => d.documentStatus === "DOCUMENT_UPLOADED");
		if(this.searchFilter!==''){
			const lowerCaseFilter = this.searchFilter.toLowerCase();
			documents = documents.filter((doc) => doc.fileName.toLowerCase().includes(lowerCaseFilter));
		}
		if(this.selectedBrand !== ""){
			const docs: ComplianceDocResponseModal[] = [];
			documents.forEach(doc => {
				if(doc.brands !== null && doc.brands.findIndex(b => b === this.selectedBrand) !== -1){
					docs.push(doc);
				}
			});
			documents = docs;
		}
		return documents;
	}

	private get sentArchiveDocuments(): ComplianceDocSentResponseModel[] {
		let documents = this.selectedTab === 1 ? this.sentArchiveDocs.filter(d => d.documentStatus === 'DOCUMENT_SENT') : this.sentArchiveDocs.filter(d => d.documentStatus === 'DOCUMENT_ARCHIEVED');
		if(this.searchFilter!==''){
			const lowerCaseFilter = this.searchFilter.toLowerCase();
			documents = documents.filter((doc) => doc.documentName.toLowerCase().includes(lowerCaseFilter) || 
			doc.toCompanyName.toLowerCase().includes(lowerCaseFilter));
		}
		if(this.selectedBrand !== ""){
			const docs: ComplianceDocSentResponseModel[] = [];
			documents.forEach(doc => {
				if(doc.brands !== null && doc.brands.findIndex(b => b === this.selectedBrand) !== -1){
					docs.push(doc);
				}
			});
			documents = docs;
		}
		return documents;
	}

    private openPDFModal(selectedDocument: ComplianceDocResponseModal): void {
        this.currentDocument = selectedDocument;
        this.showPDFModal = true;
    }

	private closePDFModal(): void {
        this.showPDFModal = false;
    }

	private get pendingDocCount(): Number {
		return this.pendingDocs.length;
	}

	private fileName(docId: string): string {
		const name = this.pendingDocs.filter(d => d.complianceDocID === docId)[0].fileName;
		return name === '' ? 'name.pdf' : name;
	}

	@Emit()
	private reloadPendingDocuments(){}

	@Emit()
	private reloadSentDocuments(){}

}