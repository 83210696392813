import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { mapState } from "vuex";
import { v4 as uuidv4 } from 'uuid';

// Components

// Services
import { SupplierService } from "@/services/supplierService";

// Models
import { AddressModel } from "@/models/addressModel";
import { EsgAddressStatus, EsgSocialModel } from "@/models/esgModel";
import { UserModel } from "@/models/userModel";
import { CertificateModel } from "@/models/certificateModel";
import { cloneDeep } from "lodash";
import { SupplierModel } from "@/models/supplierModel";

@Component({
    computed: mapState(["company", "user", "esgSocial", "socialCertificate", "sustainableCertificate"]),
})
export default class EsgFacilityModal extends Vue {
    @Prop()
    private addressess!: EsgAddressStatus[];
    @Prop()
    private selectedSupplier!: SupplierModel;
    @Prop()
    private esgType!: string;
    private allAddressess!: EsgAddressStatus[];

    public constructor() {
        super();
    }

    private created(): void {
        this.allAddressess = cloneDeep(this.addressess);
    }

    private selectUnselectFacility(index: number, isSelected: boolean): void {
        if(this.addressess.findIndex(add => add.isSelected === true) !== index) {
            this.allAddressess[index].isSelected = isSelected;
            this.allAddressess.splice(index, 1, this.allAddressess[index]);
            this.$forceUpdate();
        }
    }

    private showPreviewMessage(): void {
        this.previewMessage(this.allAddressess);
    }

    @Emit()
    private showFacilityModal(status: boolean): void {}

    @Emit()
    private previewMessage(selectedFacilities: EsgAddressStatus[]): void {}
}
