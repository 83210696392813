import store from '@/store';
import lodash from "lodash";

// Models
import { Guid } from "@/models/guid";
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { StepState } from '@/models/stepState';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { UserModel } from '@/models/userModel';
import { CompanyModel } from '@/models/companyModel';
import { AssociatedPartnersModel, BrandAssociatedPartnersModel, BrandRelatedSuppliersModel, SupplierModel } from '@/models/brandWithSupplierModel';
import { CPSCPModel, PartnersModel, UniqueSupplierWithStepModel } from '@/models/supplierPartnerModel';

export class SuppliersHelper {

    public static getClientRelatedSuppliers(): string[] {
        const regularSupplierIds: string[] = store.getters.clientRelatedSupplierIds;
        if(regularSupplierIds.length > 0){
            return regularSupplierIds;
        }
        else{
            let uniqueSuppliers: string[] = [];
            let steps: OrderLineStepDisplayModel[] = [];
            const orders: OrderLineDisplayModel[] = store.getters.orders;
            let allSteps: OrderLineStepDisplayModel[] = store.getters.orderLineSteps;
            allSteps = allSteps.filter(s => s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && s.stepState !== StepState.NONE && s.toCompanyId !== Guid.Empty && s.toCompanyId !== "");
            orders.forEach(o => {
                steps.push(...allSteps.filter(s => s.orderId === o.orderId));
            });
            // let steps = allSteps.filter(s => orders.filter(o => s.orderId === o.orderId));
            let suppliers: OrderCompanyModel[] = store.getters.suppliers;
            suppliers = suppliers.filter(s => s.isTier1 === false);
            uniqueSuppliers = steps.map(s => s.toCompanyId);
            uniqueSuppliers = lodash.uniq(uniqueSuppliers);
            suppliers.forEach(s => {
                if(uniqueSuppliers.indexOf(s.id) === -1){
                    uniqueSuppliers.push(s.id);
                }
            });
            store.commit("setClientRelatedSupplierIds", uniqueSuppliers);
            return uniqueSuppliers;
        }
    }

    // TTD-3916 get unique suppliers based on cp id
    public static getCPRelatedSuppliers(cpID: string): UniqueSupplierWithStepModel[] {
        const partners: PartnersModel[] = store.getters.associatedPartners;
        const partnerIndex = partners.findIndex(p => p.partnerId === cpID);
        if(partnerIndex === -1){
            let uniqueSuppliersWithSteps: UniqueSupplierWithStepModel[] = [];
            let uniqueSuppliers: string[] = [];
            let steps: OrderLineStepDisplayModel[] = [];
            // const orders: OrderLineDisplayModel[] = store.getters.orders;
            // let cpRelatedOrders: OrderLineDisplayModel[] = orders.filter(o => o.toCompanyId === cpID);
            const allSteps: OrderLineStepDisplayModel[] = store.getters.orderLineSteps;
            // let steps = allSteps.filter(s => cpRelatedOrders.some(o => s.orderId === o.orderId));
            steps = allSteps.filter(s => (s.cpSupplierID === cpID && (s.fromCompanyId === cpID || s.stepState === StepState.CREATED_BY_SUPPLIER || s.stepState === StepState.UPDATED_BY_SUPPLIER) && s.toCompanyId !== Guid.Empty && s.toCompanyId !== "" && s.toCompanyId !== cpID));
            // let suppliers: OrderCompanyModel[] = store.getters.suppliers;
            // suppliers = suppliers.filter(s => s.isTier1 === false);
            uniqueSuppliers = steps.map(s => s.toCompanyId);
            uniqueSuppliers = lodash.uniq(uniqueSuppliers);
            // uniqueSuppliers = uniqueSuppliers.filter(s => s !== Guid.Empty);
            uniqueSuppliers.forEach(s => {
                const supplierSteps = steps.filter(step => (step.fromCompanyId === cpID || step.stepState === StepState.CREATED_BY_SUPPLIER || step.stepState === StepState.UPDATED_BY_SUPPLIER) && step.toCompanyId === s);
                const uniqueSupplierSteps = lodash.uniqBy(supplierSteps, 'stepId').map(step => step.stepId);
                uniqueSuppliersWithSteps.push({ supplierId: s, steps: uniqueSupplierSteps });
            });
            // suppliers.forEach(s => {
            //     if(uniqueSuppliersWithSteps.findIndex(step => step.supplierId === s.id) === -1){
            //         uniqueSuppliersWithSteps.push({supplierId: s.id, steps: []});
            //     }
            // });
            if(partners.findIndex(p => p.partnerId === cpID) === -1){
                const partner = new PartnersModel();
                partner.partnerId = cpID;
                partner.linkedPartners = uniqueSuppliersWithSteps;
                store.commit('setAssociatedPartners', partner);
            }
            return uniqueSuppliersWithSteps;
        } else {
            return partners[partnerIndex].linkedPartners;
        }
    }

    // TTD-3978 get unique suppliers based on agent id
    public static getAgentRelatedSuppliers(agentID: string): UniqueSupplierWithStepModel[] {
        const partners: PartnersModel[] = store.getters.associatedPartners;
        const partnerIndex = partners.findIndex(p => p.partnerId === agentID);
        if(partnerIndex === -1){
            let uniqueSuppliersWithSteps: UniqueSupplierWithStepModel[] = [];
            let uniqueSuppliers: string[] = [];
            let steps: OrderLineStepDisplayModel[] = [];
            // const orders: OrderLineDisplayModel[] = store.getters.orders;
            // let agentRelatedOrders: OrderLineDisplayModel[] = orders.filter(o => o.agentCompanyID === agentID);
            const allSteps: OrderLineStepDisplayModel[] = store.getters.orderLineSteps;
            // let steps = allSteps.filter(s => agentRelatedOrders.some(o => s.orderId === o.orderId && s.fromCompanyId === agentID));
            steps = allSteps.filter(s => (s.agentID === agentID && (s.fromCompanyId === agentID || s.stepState === StepState.CREATED_BY_AGENT || s.stepState === StepState.UPDATED_BY_AGENT) && s.toCompanyId !== Guid.Empty && s.toCompanyId !== "" && s.toCompanyId !== agentID));
            uniqueSuppliers = steps.map(s => s.toCompanyId);
            uniqueSuppliers = lodash.uniq(uniqueSuppliers);
            // uniqueSuppliers = uniqueSuppliers.filter(s => s !== Guid.Empty);
            uniqueSuppliers.forEach(s => {
                const supplierSteps = steps.filter(step => (step.fromCompanyId === agentID || step.stepState === StepState.CREATED_BY_AGENT || step.stepState === StepState.UPDATED_BY_AGENT) && step.toCompanyId === s);
                const uniqueSupplierSteps = lodash.uniqBy(supplierSteps, 'stepId').map(step => step.stepId);
                uniqueSuppliersWithSteps.push({ supplierId: s, steps: uniqueSupplierSteps });
            });
            if(partners.findIndex(p => p.partnerId === agentID) === -1){
                const partner = new PartnersModel();
                partner.partnerId = agentID;
                partner.linkedPartners = uniqueSuppliersWithSteps;
                store.commit('setAssociatedPartners', partner);
            }
            return uniqueSuppliersWithSteps;
        } else {
            return partners[partnerIndex].linkedPartners;
        }
    }

    // TTD-4339 get brand related suppliers
    public static getBrandAndAssociatedPartners(): BrandAssociatedPartnersModel {
        const user = store.getters.user as UserModel;
        const orders: OrderLineDisplayModel[] = store.getters.orders;
        const orderLineSteps: OrderLineStepDisplayModel[] = store.getters.orderLineSteps;
        const brandAssociatedPartners: BrandAssociatedPartnersModel = new BrandAssociatedPartnersModel();
        let suppliers: OrderCompanyModel[] = store.getters.suppliers;
        let brands: string[] = [];
        // Brand related suppliers logic
        if((user.topBrands !== null || user.topBrands !== undefined) && user.topBrands.length > 0){
            brands = user.topBrands.map(b => b.brandName);
        }
        else{
            const company = store.getters.company as CompanyModel;
            brands = company.topBrands.map(brand => brand.brandName);
        }
        brands.forEach(brand => {
            const ordersWithBrand = orders.filter(o => (o.brandName === brand));
            const brandSupplier = new BrandRelatedSuppliersModel(); 
            brandSupplier.brandName = brand;
            if(ordersWithBrand.length > 0){
                const uniqueT1SCP = lodash.uniqBy(ordersWithBrand, 'toCompanyId');
                uniqueT1SCP.forEach(t1scp => {
                    if(t1scp.toCompanyId !== Guid.Empty && brandSupplier.supplierId.findIndex(s => s.id === t1scp.toCompanyId) === -1){
                        brandSupplier.supplierId.push({ id: t1scp.toCompanyId, name: t1scp.toCompanyName });
                    }
                });
                // brandSupplier.supplierId.push(...uniqueT1SCP.map(item => ({ id: item.toCompanyId, name: item.toCompanyName })));
                const uniqueAgent = lodash.uniqBy(ordersWithBrand, 'agentCompanyID');
                uniqueAgent.forEach(agent => {
                    if(agent.toCompanyId !== Guid.Empty && brandSupplier.supplierId.findIndex(s => s.id === agent.toCompanyId) === -1){
                        brandSupplier.supplierId.push({ id: agent.agentCompanyID, name: agent.agentCompanyName });
                    }
                });
                // brandSupplier.supplierId.push(...uniqueAgent.map(item => ({ id: item.toCompanyId, name: item.toCompanyName })));
                const NPs = suppliers.filter(s => s.isAgent === false && s.isTier1 === false);
                NPs.forEach(np => {
                    const stepsWithNP = lodash.uniqBy(orderLineSteps.filter(o => o.toCompanyId === np.id), 'orderId');
                    stepsWithNP.forEach(step => {
                        const order = orders.filter(o => o.orderId === step.orderId && o.brandName === brand);
                        if(order.length > 0 && brandSupplier.supplierId.findIndex(s => s.id === np.id) === -1){
                            brandSupplier.supplierId.push({ id: np.id, name: np.companyName });
                        }
                    });
                })
            } else {
                brandSupplier.supplierId = [];
            }
            brandAssociatedPartners.brands.push(brandSupplier);
        })
        // Associated partners logic
        // let uniqueSuppliers: string[] = [];
        // let steps: OrderLineStepDisplayModel[] = [];
        // let allSteps: OrderLineStepDisplayModel[] = store.getters.orderLineSteps;
        // let t1AssociatedPartners: CPSCPModel[] = store.getters.t1AssociatedPartners;
        // let agentAssociatedPartners: CPSCPModel[] = store.getters.agentAssociatedPartners;
        // allSteps = allSteps.filter(s => s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && s.stepState !== StepState.NONE && s.toCompanyId !== Guid.Empty && s.toCompanyId !== "");
        // orders.forEach(o => {
        //     steps.push(...allSteps.filter(s => s.orderId === o.orderId));
        // });
        // const partners: AssociatedPartnersModel[] = [];
        // const filteredSteps = suppliers.filter(s => s.isTier1 === true || s.isAgent === true);
        // filteredSteps.forEach(sup => {
        //     const indirectPartners: CPSCPModel | undefined = t1AssociatedPartners.find(t1 => t1.companyID === sup.id) || agentAssociatedPartners.find(agent => agent.companyID === sup.id);
        //     if(indirectPartners !== undefined){
        //         indirectPartners.suppliers.forEach(indirect => {
        //             const stepsWithIndirectpartners = steps.filter(s => s.toCompanyId === indirect.invitedCompanyID && s.fromCompanyId === sup.id);
        //             if(stepsWithIndirectpartners.length > 0){
        //                 const index = partners.findIndex(p => p.id === indirect.invitedCompanyID && p.invitedByCompanyID === sup.id);
        //                 if(index !== -1){
        //                     if(sup.isTier1){
        //                         partners[index].invitedBycompanyType = partners[index].invitedBycompanyType + ',CP';
        //                     } else if(sup.isAgent){
        //                         partners[index].invitedBycompanyType = partners[index].invitedBycompanyType + ',AGENT';
        //                     } else if(!sup.isTier1 && !sup.isAgent){
        //                         partners[index].invitedBycompanyType = partners[index].invitedBycompanyType + ',NP';
        //                     }
        //                 } else {
        //                     const partner = new AssociatedPartnersModel();
        //                     partner.id = indirect.invitedCompanyID;
        //                     partner.name = indirect.companyName;
        //                     partner.companySubType = lodash.uniq(stepsWithIndirectpartners.map(s => s.stepId));
        //                     partner.invitedByCompanyID = sup.id;
        //                     partner.invitedBycompanyType = sup.isTier1 ? 'CP' : 'AGENT';
        //                     partners.push(partner);
        //                 }
        //             }
        //             if(indirect.isDelegatePartner){
        //                 const delegatedPartnerSteps = steps.filter(s => s.fromCompanyId === indirect.invitedCompanyID);
        //                 if(delegatedPartnerSteps.length > 0){
        //                     let uniqueDelegatedInvitedPartners = lodash.uniqBy(delegatedPartnerSteps, 'toCompanyId');
        //                     uniqueDelegatedInvitedPartners.forEach(up => {
        //                         const stepsWithIndirectpartners = delegatedPartnerSteps.filter(s => s.toCompanyId === up.toCompanyId);
        //                         if(stepsWithIndirectpartners.length > 0){
        //                             const index = partners.findIndex(p => p.id === up.toCompanyId && p.invitedByCompanyID === indirect.invitedCompanyID);
        //                             if(index !== -1){
        //                                 if(!partners[index].invitedBycompanyType.includes('DP')){
        //                                     partners[index].invitedBycompanyType = partners[index].invitedBycompanyType + ',DP';
        //                                 }
        //                             } else {
        //                                 const partner = new AssociatedPartnersModel();
        //                                 partner.id = up.toCompanyId;
        //                                 partner.name = up.toCompanyName;
        //                                 partner.companySubType = lodash.uniq(stepsWithIndirectpartners.map(s => s.stepId));
        //                                 partner.invitedByCompanyID = indirect.invitedCompanyID;
        //                                 partner.invitedBycompanyType = 'DP';
        //                                 partners.push(partner);
        //                             }
        //                         }
        //                     })
                            
                            
        //                 }
        //             }
        //         })
        //     }
        // });
        // brandAssociatedPartners.associatedPartners = partners;
        brandAssociatedPartners.associatedPartners = [];
        return brandAssociatedPartners;
    }
}