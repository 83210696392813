import { Vue, Component } from "vue-property-decorator";

// Components
import Authpage from "@/components/general/authpage.vue";

@Component({
  components: { Authpage }
})

export default class SignupForm extends Vue {

  public constructor() {
    super();
  }

  private otpCode: string = "";
  private isBlocked: boolean = false;
  private isError: boolean = false;
  private isEmailErr: boolean = false;
  private isPwdErr: boolean = false;
  private newUserError: boolean = false;

  private async created(): Promise<void> {
    if (Object.keys(this.$route.query).length > 0){
      if (this.$route.params.status=="blocked") {
        this.isBlocked = true;
      } else if (this.$route.params.status=="emailError") {
        this.isEmailErr = true;
      } else if (this.$route.params.status=="pwdError") {
        this.isPwdErr = true;
      } else {
        this.isError = true;
      }
      this.newUserError = true;
    }
  }

  private get getDesc(): string{
    if (this.isBlocked) {
      return this.$t('pages.auth.user_blocked').toString();
    } else if (this.isEmailErr) {
      return this.$t('pages.auth.new_user_email_error').toString();
    } else if (this.isPwdErr) {
      return this.$t('pages.auth.new_user_pwd_error').toString();
    } else if (this.isError) {
      return this.$t('pages.auth.new_user_error').toString();
    } else {
      return "";
    }
  }
}
