import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import moment from "moment";

// Components
import DoughnutChart from "@/components/general/charts/doughnutChart/doughnutChart.vue";
import YearList from "@/components/general/year-list/yearList.vue";

// Modals
import { SupplierCertificateModal } from "@/models/supplierCertificateModal";
import { ChartModal } from "@/models/chartModal";

@Component({
    components: { DoughnutChart, YearList }
})

export default class SustainabilityEffortsTile extends Vue {

    @Prop()
    private sustainabilityCertificates!: SupplierCertificateModal[];

    @Prop()
    private isLoading!: boolean;

    private chartData: Object = {};

    private chartOptions: Object = {};

    // private today: Date = new Date();

    private showYear: boolean = false;

    private year: string = moment().format('YYYY');

    private imgSrc: NodeRequire = require("../../../assets/chart-empty.png");

    private centerText: string = "";

    private filterSustainaiblityCert: SupplierCertificateModal[] = [];

    private async created(): Promise<void> {
    }

    private selectedYear(year: string): void {
        this.year = year;
        this.selectedYearValue(year, 'Sustainability');
        this.closeYearList();
    }

    private showYearList() {
        this.showYear = true;
    }

    private get chartdetail(): ChartModal {
        let organic: number = 0;
        let recycled: number = 0;
        let otherSustainable: number = 0;
        let none: number = 0;
        this.centerText = '';
        //const currentYear = moment().format('YYYY');
        let filterData = this.sustainabilityCertificates;
        // if (this.year == currentYear) {
        //     filterData = filterData.filter(c => new Date(c.expiryDate).getTime() >= this.today.getTime() || c.expiryDate == null);
        // }
        organic = filterData.filter(c => c.certificateCategory === "Organic").length;
        recycled = filterData.filter(c => c.certificateCategory === "Recycled").length;
        otherSustainable = filterData.filter(c => c.certificateCategory === "Other Sustainable").length;
        none = filterData.filter(c => c.certificateCategory === "None").length;

        const total = organic + recycled + otherSustainable + none;

        organic = (organic / total) * 100;
        recycled = (recycled / total) * 100;
        otherSustainable = (otherSustainable / total) * 100;
        none = (none / total) * 100;
        const sustainablePercentage = (organic + recycled + otherSustainable);
        this.centerText = ((Number.isNaN(sustainablePercentage)) ? '0.00' : sustainablePercentage.toFixed(2)) + '% Sustainable';

        this.chartData = {
            labels: ['Organic', 'Recycled', 'Other sustainable', 'None'],
            datasets: [
                {
                    label: 'Sustainability efforts',
                    backgroundColor: ['#7498AF', '#C1D2DC', '#CBA76D', '#E7E7E7'],
                    data: [organic.toFixed(2), recycled.toFixed(2), otherSustainable.toFixed(2), none.toFixed(2)],
                    hoverOffset: 1
                }
            ]
        };

        this.chartOptions = {
            responsive: true,
            cutoutPercentage: 80,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    label: ((tooltipItem: any, data: any) => {
                        const label = data.labels[tooltipItem.index] + ": " + data.datasets[0].data[tooltipItem.index] + " %";
                        return label;
                    })
                }
            }
        };

        const chartdetails = { 'chartData': this.chartData, 'chartOptions': this.chartOptions };

        return chartdetails;
    }

    private closeYearList(): void {
        this.showYear = false;
    }

    @Emit()
    private selectedYearValue(year: string, componentName: string) {
    }

}