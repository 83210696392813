// Sprint-26 TTD-3068
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import VuePdfApp from 'vue-pdf-app';
import { mapState } from 'vuex';
import i18n from '@/i18n';
import moment from 'moment';
import { cloneDeep, uniqBy } from 'lodash';

// Components
import ViewCertificatesUploaded from '@/views/supplyChainPartners/components/viewCertificatesUploaded.vue';
import EsgFacilityModal from '@/views/supplyChainPartners/components/esgFacilityModal.vue';
import ViewDocuments from '@/views/supplyChainPartners/components/viewDocuments.vue';
import RemindEsg from '@/views/supplyChainPartners/components/remindESG.vue';

// Services
import { ComplianceService } from '@/services/complianceService';
import { SupplierService } from '@/services/supplierService';

//Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';

// Modals
import { SupplierModel } from '@/models/supplierModel';
import { CertificateModel, CertificateOnTypeModel, CertificateTypes } from '@/models/certificateModel';
import { FacilitiesModel } from '@/models/facilitiesModel';
import { EsgAddressStatus, EsgDocsModel, EsgSendReminderResModel, EsgSocialDocUrlReq, EsgSocialModel, EsgStatus } from '@/models/esgModel';
import { AddressModel } from '@/models/addressModel';

@Component({
  components: {
    VuePdfApp,
    ViewCertificatesUploaded,
    EsgFacilityModal,
    ViewDocuments,
    RemindEsg
  },
  computed: mapState([
    'userRole',
    'userPermissions',
    'companyType',
    'esgSocial',
    'esgDataLoading',
  ]),
})
export default class SocialData extends Vue {
  @Prop()
  private activeAddress!: AddressModel;

  @Prop()
  private activeAddEsgSocial!: EsgSocialModel;

  @Prop()
  private allAddressess!: AddressModel[];

  @Prop()
  private selectedSupplier!: SupplierModel;

  @Prop()
  private documents!: {
    social: CertificateModel[];
    sustainable: CertificateModel[];
  };

  private esgSocial!: EsgSocialModel[];

  private esgDataLoading!: boolean;

  private pdfDocument!: any;

  private complianceService: ComplianceService;

  private supplierService: SupplierService;

  private documentDetail: CertificateModel = new CertificateModel();

  private showPDFModal: boolean = false;

  private showReminderPopup: boolean = false;

  private previewMessage: boolean = false;

  private status: string = 'red';

  private activeButton: number = 0;

  private names: FacilitiesModel[] = [];

  private socialData: EsgSocialModel = new EsgSocialModel();

  private isEsgDataLoading: boolean = false;

  private isFacilityModalVisible: boolean = false;

  private addressess: EsgAddressStatus[] = [];

  private esgType: string = 'SOCIAL';

  private esgDocUrl: string = '';

  private auditTypes: CertificateOnTypeModel[] = [];

  private viewDocFileName: string = '';
  private showViewDocument: boolean = false;
  private selectedFacilities: EsgAddressStatus[] = [];

  public constructor() {
    super();
    this.complianceService = new ComplianceService();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    const socialAudit = i18n.t(`enums.social_certificates.audit`) as any;
    for (const key of Object.keys(socialAudit)) {
        const certifcateName = socialAudit[key];
        this.auditTypes.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SOCIAL));
    }
    this.socialData = this.activeAddEsgSocial;
    this.parseNegativeValueToString();
    this.facilityAddresses();
  }

  private get notStartedStatus(): string {
    return '';
  }

  private get inProgressStatus(): string {
    return EsgStatus.INPROGRESS;
  }

  private get completedStatus(): string {
    return EsgStatus.COMPLETED;
  }

  private get yes(): string {
    return EsgStatus.YES;
  }

  private get no(): string {
    return EsgStatus.NO;
  }

  private get dataUpdatedAt(): string {
    return this.socialData.sdLastUpdatedAt !== '' ? moment(this.socialData.sdLastUpdatedAt).format('DD MMM YYYY') : '';
  }

  private formatText(text: string): string {
    if(text === this.yes){
      return "Yes";
    } else if (text === this.no){
      return "No";
    } else {
      return text;
    }
  }

  private openPDFModal(doc: CertificateModel, certName: string): void {
    this.documentDetail = doc;
    this.documentDetail.typeName = certName;
    this.documentDetail.amforiID = doc.licenseID;
    this.showPDFModal = true;
  }

  private officeSelected(index: number): void {
    this.activeButton = index;
  }

  private get getSocialAudit(): CertificateModel[] {
    let socialAuditCerts: CertificateModel[] = [];
    this.socialData.socialCert.forEach(cert => {
      socialAuditCerts.push(...this.documents.social.filter(sc => sc.ID === cert.ID));
    })
    socialAuditCerts = uniqBy(socialAuditCerts, 'ID');
    socialAuditCerts.forEach(ac => {
      const daysDifference = this.getDateDifference(ac.validtoDate);
            if (daysDifference <= 30) {
               if (daysDifference >= 0 ){
                ac.expiryInfo = 'Expiring'
              }
              else{
                ac.expiryInfo = 'Expired';
              }
              ac.expiryStatus =
                daysDifference >= 0 ? `expiring` : `expired`;
              }
    });
    return socialAuditCerts;
  }

  private parseNegativeValueToString(): void {
    this.socialData.sddeoQ04.totalEmployees.value = this.socialData.sddeoQ04.totalEmployees.value !== -1 ? this.socialData.sddeoQ04.totalEmployees.value : (this.activeAddress.employees !== -1 ? this.activeAddress.employees : '-');
    this.socialData.sddeoQ04.femaleEmployees.value = this.socialData.sddeoQ04.femaleEmployees.value !== -1 ? this.socialData.sddeoQ04.femaleEmployees.value : (this.activeAddress.femaleEmployees !== -1 ? this.activeAddress.femaleEmployees : '-');
    this.socialData.sddeoQ04.maleEmployees.value = this.socialData.sddeoQ04.maleEmployees.value !== -1 ? this.socialData.sddeoQ04.maleEmployees.value : (this.activeAddress.maleEmployees !== -1 ? this.activeAddress.maleEmployees : '-');
    this.socialData.sddeoQ05.value = this.socialData.sddeoQ05.value !== -1 ? this.socialData.sddeoQ05.value : '-';
    this.socialData.sddeoQ06.value = this.socialData.sddeoQ06.value !== -1 ? this.socialData.sddeoQ06.value : '-';
    this.socialData.sdwccQ05.value = this.socialData.sdwccQ05.value !== -1 ? this.socialData.sdwccQ05.value : '-';
    this.socialData.sdwccQ06.value = this.socialData.sdwccQ06.value !== -1 ? this.socialData.sdwccQ06.value : '-';
  }

  private async getSelectedFacilitesDetails(): Promise<void> {
    if (this.esgSocial.length === 0) {
      this.enableLoader(true);
      this.socialData = new EsgSocialModel();
      this.socialData.status
      this.isEsgDataLoading = true;
      const result = await this.supplierService.getSocialDataByAddressIdAsync(
        this.activeAddress.id
      );
      if (result.success) {
        this.isEsgDataLoading = false;
        this.enableLoader(false);
        if (result.socialData[0]) {
          this.socialData = result.socialData[0];
        }
      } else {
        this.isEsgDataLoading = false;
        NotificationHelper.createErrorNotification(
          i18n.t('global.notifications.some_thing_went_wrong').toString()
        );
      }
    } else {
      this.socialData = this.esgSocial.filter(
        (esg) => esg.addressID === this.activeAddress.id
      )[0];
    }
  }

  private formattedDate(date: string, dateFormat: string): string {
    return moment(date).format(dateFormat);
  }

  private get defaultDate(): string {
    return '0001-01-01T00:00:00Z';
  }

  private facilityAddresses(): void {
    this.addressess = [];
    this.allAddressess.forEach((address) => {
      const facilityAddress: EsgAddressStatus = {
        addressID: address.id,
        status: '',
        facilityName: address.officeName !== '' ? address.officeName : address.line1,
        isSelected: this.activeAddress.id === address.id ? true : false,
      };
      this.addressess.push(facilityAddress);
    });
  }

  private showFacilityModal(): void {
    this.facilityAddresses();
    this.isFacilityModalVisible = !this.isFacilityModalVisible;
  }

  private getDateDifference(date: string): number {
    const start = moment(Date.now());
    const end = moment(date);
    const duration = moment.duration(end.diff(start));
    return Math.ceil(duration.asDays());
  }

  private async getDocUrl(doc: EsgDocsModel, key: string): Promise<void> {
    const index = this.esgSocial.findIndex(s => s.addressID === this.activeAddress.id);
    if(index !== -1){
      const data: EsgSocialDocUrlReq = {
        ID: this.esgSocial[index].ID,
        companyID: this.socialData.companyID,
        socialDataDocType: key,
        socialDataDocTypeID: doc.ID
      }
      const response = await this.supplierService.getDocUrlAsync(data);
      if(response.success){
        this.esgDocUrl = response.socialDoc[0].docUrl;
      }
    }
  }

  private getCertName(type: number): string{
    return this.auditTypes.find(a => a.ID === type.toString())!.Name;
  }

  private closePDFModal(): void {
    this.showPDFModal = false;
  }

  private async openDoc(doc: EsgDocsModel, key: string): Promise<void> {
    await this.getDocUrl(doc, key);
    this.viewDocFileName = doc.fileName;
    this.showViewDocument = true;
  }

  private closeViewDocument(status: boolean): void {
    this.showViewDocument = status;
  }

  private async downloadDoc(doc: EsgDocsModel, key: string): Promise<void> {
    await this.getDocUrl(doc, key);
    const docElem = document.getElementById('esgDoc');
    if(docElem){
      docElem.click();
    }
  }

  private showPreviewMessage(selectedFacilities: EsgAddressStatus[]): void {
    this.selectedFacilities = selectedFacilities;
    this.isFacilityModalVisible = false;
    this.previewMessage = true;
  }

  private closePreview(status: boolean, result: EsgSendReminderResModel): void {
    if(result !== undefined){
      this.isFacilityModalVisible = false;
      this.previewMessage = false;
      this.socialData.reminderSentDate = result.reminderSentDate;
    } else {
      this.isFacilityModalVisible = true;
      this.previewMessage = false;
    }
  }

  @Emit()
  private enableLoader(val: boolean): void {}

  @Watch('addressId')
  private getSocialData(): void {
    this.getSelectedFacilitesDetails();
  }

  @Watch('activeAddEsgSocial', { deep: true })
  private onSelectedAddChanged(): void {
    this.socialData = cloneDeep(this.activeAddEsgSocial);
    this.parseNegativeValueToString();
  }
}
