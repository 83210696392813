import { DashboardApiAuthenticator } from '@/authenticators/dashboardApiAuthenticator';
import { ApplicationConfig } from '@/config';
import { CompanyModel, companyResponseModel } from '@/models/companyModel';
import { InvitationModel } from '@/models/invitationModel';
import { AddSecondUserResponeModel, InvitedSupplierResponseModel } from '@/models/invitedSupplierModel';
import { BaseClient } from './baseClient';

export class InvitationClient extends BaseClient {
    private readonly endPoint: string = "api/invitation";

    public constructor() {
        super(ApplicationConfig.endpoints.dashboardApi, new DashboardApiAuthenticator());
    }

    public async getClientByInvitationIdAsync(invitationId: string): Promise<CompanyModel> {
        return await this.get<CompanyModel>(`${this.endPoint}/client?invitationId=${invitationId}`);
    }

    public async updateClientAsync(invitationId: string, client: CompanyModel): Promise<companyResponseModel> {
        return await this.putWithResponse(`${this.endPoint}/${invitationId}/client/${client.id}`, client);
    }

    public async sendInvitation(invitationModel: InvitationModel): Promise<InvitedSupplierResponseModel> {
        return await this.postWithResponse(`${this.endPoint}`, invitationModel);
    }

    public async addSecondUserAsync(invitedSupplierModel: InvitationModel, userId: string): Promise<AddSecondUserResponeModel> {
        return await this.postWithResponse<AddSecondUserResponeModel>(`${this.endPoint}/requestAddSecondUser/${userId}`, invitedSupplierModel);
    }
}