export class FileModel {
    public fileName!: string;
    public documentType!: string;
    public content!: string;

    public get base64Content(): string {
        return this.content;
    }

    public get type(): string {
        return this.documentType;
    }

    public get name(): string {
        return this.fileName;
    }

    public constructor(fileName: string, type: string, content: string) {
        this.fileName = fileName;
        this.documentType = type;
        this.content = content;
    }
}