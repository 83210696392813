import { ClientClient } from '@/clients/clientClient';
import { ClientBlockchain } from '@/clients/clientBlockchain';
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';
import store from '@/store';
import { cloneDeep } from 'lodash';
import { CompanyModel, companyResponseModel } from '@/models/companyModel';
import { ProductPictureModel } from '@/models/productPictureModel';
import { UploadedDocResponseModal } from '@/models/uploadedDocResponseModal';
import { ComplianceDocSentResponseModel } from '@/models/complianceDocSentResponseModel';
import { SocialCertificatesModel } from '@/models/socialCertificatesModel';
import { SustainableCertificatesModel } from '@/models/sustainableCertificatesModel';
import { ClientRelatedSupplierModel } from '@/models/clientRelatedSupplierModel';
import { SignedComplianceDocResponse } from '@/models/signedComplianceDocResponse';
import { ClientRelatedSupplierResponseModel } from '@/models/clientRelatedSupplierResponseModel';
import { PartnerRequestModel, PartnerResponseModel } from '@/models/supplierPartnerModel';
import { SuppliersListModel } from '@/models/supplierModel';
import { OrderLineStepDisplayModel, OrderStepsResponseModel } from '@/models/orderLineStepDisplayModel';
import { StepState } from '@/models/stepState';
import { TcMailModel, TcMailRequestModel } from '@/models/transactionCertsModel';
import { TCConstant } from '@/models/tcTableModel';
import { EsgSendReminderModel, EsgSendReminderResModel } from '@/models/esgModel';

export class ClientService {
    private readonly client: ClientClient = new ClientClient();
    private readonly clientBlockchain: ClientBlockchain = new ClientBlockchain();

    public async getClientAsync(): Promise<CompanyModel> {
        const result = await this.client.getClientAsync();
        store.commit("setProfile", result);
        return result;
    }

    public async updateClientAsync(client: CompanyModel): Promise<companyResponseModel> {
        const response = await this.client.updateClientAsync(client);
        if (response.result === "success" && response.responseObject.companyName!=="") { // Only NoContent delivers no result
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.client_update_succeeded').toString());
        }
        return response;
    }

    public async uploadOrderProductPicturesAsync(clientId: string, orderId: string, orderLineId: string, pictures: ProductPictureModel[]): Promise<ProductPictureModel[]> {
        const result = await this.client.uploadOrderProductPicturesAsync(clientId, orderId, orderLineId, pictures);
        if (result.length > 0) { // Only NoContent delivers no result
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.product_pictures_uploaded_successfully').toString());
        }
        return result;
    }

    public async sendComplianceMailNotificationAsync(mailData: ComplianceDocSentResponseModel[]): Promise<boolean>{
        const result = await this.client.sendComplianceMailNotificationAsync(mailData);
        if(!result){
            return true;
        }
        return false;
    }

    public async sendDocNotificationAsync(mailData: any[]): Promise<boolean>{
        const result = await this.client.sendDocNotificationAsync(mailData);
        if(!result){
            return true;
        }
        return false;
    }
    //Sprint-24 TTD-4433
    public async sendTCRemainderAsync(mailData: any[]): Promise<boolean>{
        const result = await this.client.sendTCRemainderAsync(mailData);
        if(!result){
            NotificationHelper.createSucceededNotification('Reminder sent successfully.')
            return true;
        }else{
            NotificationHelper.createSucceededNotification('Reminder sending failed.')
        }
        return false;
    }

    public async downloadSignedPdf(data: any): Promise<SignedComplianceDocResponse>{
        const result = await this.client.downloadSignedPdf(data);
        return result;
    }

    public async setSuppliersListAsync(suppliers: string[], clientId: string): Promise<ClientRelatedSupplierResponseModel>{
        const result = await this.clientBlockchain.setSuppliersListAsync(suppliers, clientId);
        return result;
    }

    public async setSuppliersListForReportAsync(suppliers: string[], clientId: string): Promise<boolean>{
        const result = await this.clientBlockchain.setSuppliersListForReportAsync(suppliers, clientId);
        if(result){
            const supplierCompaniesList: ClientRelatedSupplierModel[] = [];
            supplierCompaniesList.push(...result.company);
            result.t1ScpCompany.forEach(company => {
                if(supplierCompaniesList.findIndex(c => c.ID === company.ID) === -1){
                    supplierCompaniesList.push(company);
                }
            });
            store.commit('setSupplierCompanies', supplierCompaniesList);
            return true;
        }
        else{
            return false;
        }
    }

    public async uploadProfilePictureAsync(
        userId: string,
        data: FormData
    ): Promise<boolean> {
        const result = await this.clientBlockchain.uploadProfilePictureAsync(userId, data);
        if (result) {
            NotificationHelper.createSucceededNotification(
                i18n.t('global.notifications.user_profile_upload').toString()
            );
            return true;
        } else {
            NotificationHelper.createErrorNotification(
                i18n.t('errors.general').toString()
            );
            return false;
        }
    }

    public async updateProfilePictureAsync(
        userId: string,
        data: FormData
    ): Promise<boolean> {
        const result = await this.clientBlockchain.updateProfilePictureAsync(userId, data);
        if (result) {
            NotificationHelper.createSucceededNotification(
                i18n.t('global.notifications.user_profile_update').toString()
            );
            return true;
        } else {
            NotificationHelper.createErrorNotification(
                i18n.t('errors.general').toString()
            );
            return false;
        }
    }

    public async deleteProfilePictureAsync(
        userId: string,
        profileId: string
    ): Promise<void> {
        const result = await this.clientBlockchain.deleteProfilePictureAsync(userId, profileId);
        NotificationHelper.createSucceededNotification(
            i18n.t('global.notifications.user_profile_delete').toString()
        );
    }

    public async getPartners(data: PartnerRequestModel): Promise<PartnerResponseModel> {
        const result = await this.clientBlockchain.getPartners(data);
        if(!result.success){
            NotificationHelper.createErrorNotification('Failed to fetch partners');
        }
        return result;
    }

    public async getSuppliersListAsync(id: string): Promise<SuppliersListModel> {
        let result: SuppliersListModel;
        if(store.getters.user.companyType !== 5){
            result = await this.clientBlockchain.getSuppliersListAsync(id);
        } else {
            result = await this.clientBlockchain.getAgentSuppliersListAsync(id);
        }
        if(!result.success){
            NotificationHelper.createErrorNotification('Failed to fetch supplier list');
        }
        return result;
    }

    public async getStepsOnOrderID(orderIds: string[]): Promise<OrderStepsResponseModel> {
        const result = await this.clientBlockchain.getStepsOnOrderID(orderIds);
        if(!result.success){
            NotificationHelper.createErrorNotification('Failed to fetch steps for order');
        } else {
            let allSteps = cloneDeep(store.getters.orderLineSteps as OrderLineStepDisplayModel[]);
            let responseSteps = cloneDeep(result.orderLineSteps);
            orderIds.forEach(id => {
                allSteps = allSteps.filter(s => s.orderId !== id);
                const steps = responseSteps.filter(s => s.orderId === id && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && s.stepState !== StepState.NONE);
                steps.forEach(step => {
                    const filteredSteps = steps.filter(st => st.stepId === step.stepId);
                    if(filteredSteps.length > 1){
                        let clientDuplicateStep: OrderLineStepDisplayModel[] = [];
                        if(filteredSteps.findIndex(fs => (fs.stepState !== StepState.CREATED_BY_CLIENT && fs.stepState !== StepState.UPDATED_BY_CLIENT)) !== -1) {
                            clientDuplicateStep = filteredSteps.filter(fs => fs.stepState === StepState.CREATED_BY_CLIENT || fs.stepState === StepState.UPDATED_BY_CLIENT);
                        } else {
                            clientDuplicateStep = filteredSteps.filter(fs => fs.stepState === StepState.UPDATED_BY_CLIENT);
                        }
                        clientDuplicateStep.forEach(ds => {
                            const index = responseSteps.findIndex(s => s.id === ds.id);
                            if(index !== -1){
                                responseSteps.splice(index, 1);
                            }
                        })
                    }
                })
            })
            allSteps.push(...responseSteps);
            store.commit('setAllOrderlineSteps', allSteps);
        }
        return result;
    }

    // TTD-4484 Sprint-24 send mail for approved and rejected TC
    public async sendTCMail( tcMailData: TcMailModel, tcStatus: string): Promise<void> {
        if(tcStatus === TCConstant.APPROVED){
            await this.client.sendApprovedTCMail(tcMailData);
        } else {
            await this.client.sendRejectedTCMail(tcMailData);
        }
    }

    // Sprint-24 TTD-4484 send mail for requested TC
    public async sendTCRequestMail( tcMailData: TcMailRequestModel): Promise<void> {
        await this.client.sendTCRequestMail(tcMailData);
    }

    // Sprint-26 TTD-3068 send reminder mail
    public async sendEsgReminderMail(data: EsgSendReminderModel, type: string): Promise<EsgSendReminderResModel> {
        let response: EsgSendReminderResModel = new EsgSendReminderResModel();
        if(type === 'SOCIAL'){
            response = await this.client.sendSocialEsgReminderMail(data);
        } else {
            response = await this.client.sendEnvEsgReminderMail(data);
        }
        if(response.result === "success") {
            NotificationHelper.createSucceededNotification("Reminder mail sent successfully.");
        } else {
            NotificationHelper.createErrorNotification("Failed to send reminder mail. Please try again.");
        }
        return response;
    }
}
