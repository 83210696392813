// Sprint-26 TTD-4759
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import { mapState } from "vuex";
import moment from "moment";

// Components
import EsgFacilityModal from '@/views/supplyChainPartners/components/esgFacilityModal.vue';
import ViewDocuments from '@/views/supplyChainPartners/components/viewDocuments.vue';
import RemindEsg from '@/views/supplyChainPartners/components/remindESG.vue';

// Services
import { ComplianceService } from "@/services/complianceService";
import { SupplierService } from "@/services/supplierService";

// Modals
import { SupplierModel } from "@/models/supplierModel";
import {
  CertificateModel
} from "@/models/certificateModel";
import { EsgAddressStatus, EsgDocsModel, EsgEnvDocUrlReq, EsgEnvModel, EsgSendReminderResModel, EsgStatus, EsgTextAnsModel, Facilities } from '@/models/esgModel';
import { AddressModel } from '@/models/addressModel';
import { cloneDeep } from "lodash";

@Component({
  components: {
    VuePdfApp,
    EsgFacilityModal,
    ViewDocuments,
    RemindEsg
  },
  computed: mapState(["userRole", "userPermissions", "companyType", "esgEnv"]),
})
export default class EnvironmentalData extends Vue {

  @Prop()
  private activeAddress!: AddressModel;

  @Prop()
  private activeAddEsgEnv!: EsgEnvModel;

  @Prop()
  private allAddressess!: AddressModel[];

  @Prop()
  private selectedSupplier!: SupplierModel;

  private esgEnv!: EsgEnvModel[];

  private envData: EsgEnvModel = new EsgEnvModel();

  private pdfDocument!: any;

  private complianceService: ComplianceService;

  private supplierService: SupplierService;

  private documentDetail: CertificateModel = new CertificateModel();

  private showPDFModal: boolean = false;

  private isShowEnergyUse: boolean = false;

  private isShowWaterUse: boolean = false;

  private isShowWaste: boolean = false;

  private isShowCirculrEco: boolean = false;

  private isShowCarbonEmi: boolean = false;

  private status: string = 'red';

  private activeButton: number = 0;

  private showReminderPopup: boolean = false;

  private addressess: EsgAddressStatus[] = [];

  private isFacilityModalVisible: boolean = false;
  private selectedFacilities: EsgAddressStatus[] = [];
  private previewMessage: boolean = false;
  private showViewDocument: boolean = false;
  private viewDocFileName: string = '';
  private esgDocUrl: string = '';
  private esgType: string = 'ENV';

  public constructor() {
    super();
    this.complianceService = new ComplianceService();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    this.envData = cloneDeep(this.activeAddEsgEnv);
    this.parseNegativeValueToString();
    this.facilityAddresses();
  }

  private openPDFModal(doc: CertificateModel, certName: string): void {
    this.documentDetail = doc;
    this.documentDetail.typeName = certName;
    this.documentDetail.amforiID = doc.licenseID;
    this.showPDFModal = true;
  }

  private officeSelected(index:number):void{
    this.activeButton = index;
  }

  private get notStartedStatus(): string {
    return '';
  }

  private get inProgressStatus(): string {
    return EsgStatus.INPROGRESS;
  }

  private get completedStatus(): string {
    return EsgStatus.COMPLETED;
  }

  private get yes(): string {
    return EsgStatus.YES;
  }

  private get no(): string {
    return EsgStatus.NO;
  }

  private get na(): string {
    return EsgStatus.NA;
  }

  private formatDate(dateToBeFormatted: string): string {
    return moment(dateToBeFormatted).format("D MMMM YYYY");
  }

  private formattedDate(date: string, dateFormat: string): string {
    return moment(date).format(dateFormat);
  }

  private get defaultDate(): string {
    return '0001-01-01T00:00:00Z';
  }

  private formatText(text: string): string {
    if(text === this.yes){
      return "Yes";
    } else if (text === this.no){
      return "No";
    } else if(text === this.na) {
      return "Not applicable";
    }else {
      return text
    }
  }

  private joinData(data: EsgTextAnsModel[]): string {
    return data.map(d => d.value).join(', ');
  }

  private facilityAddresses(): void {
    this.addressess = [];
    this.allAddressess.forEach((address) => {
      const facilityAddress: EsgAddressStatus = {
        addressID: address.id,
        status: '',
        facilityName: address.officeName !== '' ? address.officeName : address.line1,
        isSelected: this.activeAddress.id === address.id ? true : false,
      };
      this.addressess.push(facilityAddress);
    });
  }

  private showPreviewMessage(selectedFacilities: EsgAddressStatus[]): void {
    this.selectedFacilities = selectedFacilities;
    this.isFacilityModalVisible = false;
    this.previewMessage = true;
  }

  private closePreview(status: boolean, result: EsgSendReminderResModel): void {
    if(result !== undefined){
      this.isFacilityModalVisible = false;
      this.previewMessage = false;
      this.envData.reminderSentDate = result.reminderSentDate;
    } else {
      this.isFacilityModalVisible = true;
      this.previewMessage = false;
    }
  }

  private parseNegativeValueToString(): void {
    this.envData.edeguQ01.value = this.envData.edeguQ01.value !== -1 ? this.envData.edeguQ01.value : '-';
    this.envData.edeguQ02.value = this.envData.edeguQ02.value !== -1 ? this.envData.edeguQ02.value : '-';
    this.envData.edeguQ03.value = this.envData.edeguQ03.value !== -1 ? this.envData.edeguQ03.value : '-';
    this.envData.edeguQ04.value = this.envData.edeguQ04.value !== -1 ? this.envData.edeguQ04.value : '-';
    this.envData.edwtuQ01.value = this.envData.edwtuQ01.value !== -1 ? this.envData.edwtuQ01.value : '-';
    this.envData.edwtuQ02.value = this.envData.edwtuQ02.value !== -1 ? this.envData.edwtuQ02.value : '-';
    this.envData.edwtuQ07.value = this.envData.edwtuQ07.value !== -1 ? this.envData.edwtuQ07.value : '-';
    this.envData.edwasQ02.value = this.envData.edwasQ02.value !== -1 ? this.envData.edwasQ02.value : '-';
    this.envData.edcleQ04.value = this.envData.edcleQ04.value !== -1 ? this.envData.edcleQ04.value : '-';
    this.envData.edcleQ05.value = this.envData.edcleQ05.value !== -1 ? this.envData.edcleQ05.value : '-';
    this.envData.edcleQ06.value = this.envData.edcleQ06.value !== -1 ? this.envData.edcleQ06.value : '-';
    this.envData.edcleQ07.value = this.envData.edcleQ07.value !== -1 ? this.envData.edcleQ07.value : '-';
    this.envData.edcbeQ02.value = this.envData.edcbeQ02.value !== -1 ? this.envData.edcbeQ02.value : '-';
    this.envData.edcbeQ04.value = this.envData.edcbeQ04.value !== -1 ? this.envData.edcbeQ04.value : '-';
    this.envData.edcbeQ06.value = this.envData.edcbeQ06.value !== -1 ? this.envData.edcbeQ06.value : '-';
  }

  private showFacilityModal(): void {
    this.facilityAddresses();
    this.isFacilityModalVisible = !this.isFacilityModalVisible;
  }

  private async openDoc(doc: EsgDocsModel, key: string): Promise<void> {
    await this.getDocUrl(doc, key);
    this.viewDocFileName = doc.fileName;
    this.showViewDocument = true;
  }

  private async downloadDoc(doc: EsgDocsModel, key: string): Promise<void> {
    await this.getDocUrl(doc, key);
    const docElem = document.getElementById('esgDoc');
    if(docElem){
      docElem.click();
    }
  }

  private closeViewDocument(status: boolean): void {
    this.showViewDocument = status;
  }

  private async getDocUrl(doc: EsgDocsModel, key: string): Promise<void> {
    const index = this.esgEnv.findIndex(s => s.addressID === this.activeAddress.id);
    if(index !== -1){
      const data: EsgEnvDocUrlReq = {
        ID: this.esgEnv[index].ID,
        companyID: this.envData.companyID,
        envDataDocType: key,
        envDataDocTypeID: doc.ID
      }
      const response = await this.supplierService.getEnvDocUrlAsync(data);
      if(response.success){
        this.esgDocUrl = response.envDoc[0].docUrl;
      }
    }
  }

  @Watch('activeAddEsgEnv', { deep: true })
  private onSelectedAddChanged(): void {
    this.envData = cloneDeep(this.activeAddEsgEnv);
    this.parseNegativeValueToString();
  }

  @Emit()
  private reloadDocuments(): void {}
}
