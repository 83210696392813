import { OrderLineDisplayModel } from "./orderLineDisplayModel";

export class BulkOrderModel {
    public orderId!: string;
    public orderNumber!: string;
    public styleNumber!: string;
    public styleName!: string;
    public supplierName!: string;
    public colourway?: string;
    public supplierId!: string;
    public supplierCountry!: string;
    public checked!: boolean;
    public isDeleted!: boolean;
    public groupId!: string;
    public compositionMainFabric!: string;
    public season!: string;
}

export class EditOrderModel {
    public orderID!: string;
    public orderNumber!: string;
    public styleNumber!: string;
    public styleName!: string;
    public colourway?: string;
    public season!: string;
    public etd!: string;
    public compositionMainFabric!: string;
    public checked!: boolean;
}

export class EditOrderResponseModel {
    public success!: boolean;
    public orders!: OrderLineDisplayModel[];
}