import { DashboardApiAuthenticator } from '@/authenticators/dashboardApiAuthenticator';
import { ApplicationConfig } from '@/config';
import { InvitedSupplierModel, LinkSupplierResponeModel } from '@/models/invitedSupplierModel';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { SupplierCertificateModal } from '@/models/supplierCertificateModal';
import { SupplierModel } from '@/models/supplierModel';
import { SupplierPartnerModel } from '@/models/supplierPartnerModel';
import { BaseClient } from './baseClient';

export class SupplierClient extends BaseClient {
    private readonly endPoint: string = "api/supplier";

    public constructor() {
        super(ApplicationConfig.endpoints.dashboardApi, new DashboardApiAuthenticator());
    }

    // public async getSuppliersAsync(): Promise<OrderCompanyModel[]> {
    //     return await this.get(`${this.endPoint}`);
    // }

    public async linkSupplierAsync(invitedSupplierModel: InvitedSupplierModel): Promise<LinkSupplierResponeModel> {
        return await this.postWithResponse<LinkSupplierResponeModel>(`${this.endPoint}/link`, invitedSupplierModel);
    }

    public async getSupplierAsync(supplierId: string): Promise<SupplierModel> {
        return await this.get(`${this.endPoint}/${supplierId}`);
    }

    public async getSupplierDetailsAsync(supplierId: string): Promise<SupplierModel> {
        return await this.get(`${this.endPoint}/${supplierId}/partners`);
    }

    public async getSupplierPartnersAsync(supplierId: string): Promise<SupplierPartnerModel[]> {
        return await this.get(`${this.endPoint}/${supplierId}/partners`);
    }

    // public async getSuppliersCertificateAsync(): Promise<SupplierCertificateModal[]> {
    //     return await this.get(`${this.endPoint}/getT1scp`);
    // }
}