import { debounce } from "ts-debounce";
import { Vue, Component } from "vue-property-decorator";

// Components
import Authpage from "@/components/general/authpage.vue";

// Services
import { MiddlewareService } from '@/services/middlewareService';

@Component({
  components: { Authpage }
})

export default class VerificationCode extends Vue {

  private isLoading: boolean = false;
  private otpError: boolean = false;
  private emailError: boolean = false;
  private emailAddress: string = "";
  private checkEmailIsValidDebounced: any;

  private otpCode: string = "";

  private middlewareService: MiddlewareService;
  public constructor() {
    super();
    this.middlewareService = new MiddlewareService();
  }

  private async created(): Promise<void> {
    const emailAddress=localStorage.getItem("maincontactemail");
    if (emailAddress !== undefined && emailAddress !== null) {
      this.emailAddress = emailAddress;
    }
    this.checkEmailIsValidDebounced = debounce(this.checkEmailIsValid, 1000, { maxWait: 2000, isImmediate: false });
  }

  private async checkEmailIsValid(event: any): Promise<void> {
    const emailAddress = event.target.value;

    if (emailAddress.length < 8) {
      return;
    }

    const matches = emailAddress.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    this.emailError = (matches == null) ? true : false;
    return;
  }

  private async checkOTPError(): Promise<void> {
    if (this.otpCode.trim() == "") {
      this.otpError = false;
    }
  }

  private async verifyOTP(): Promise<void> {

    const savedOTPCode = localStorage.getItem("verificationcode");

    if (this.otpCode.trim() == "") {
      this.otpError = true;
      return;
    }

    if (this.otpCode != savedOTPCode) {
      this.otpError = true;
      return;
    }

    this.$router.push({ name: 'ResetPassword'});
  }

  private async resendOTP(): Promise<void> {
    if (this.emailAddress.trim() == "" || this.emailError) {
      return;
    }

    this.isLoading = true;
    const otpCode = Math.floor(100000 + Math.random() * 900000);
    localStorage.setItem("verificationcode", otpCode.toString());
    localStorage.setItem("maincontactemail", this.emailAddress);

    const result = await this.middlewareService.resetsendVerificationCode(this.emailAddress, otpCode.toString());
    if (result.result == "success") {
      this.$router.push({ name: 'VerificationResend', params: { emailAddress: this.emailAddress } });
    } else {
      this.otpError = true;
    }
    this.isLoading = false;

    return;
  }
}
