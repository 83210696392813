import { Line, mixins } from 'vue-chartjs';
import { Vue, Component, Prop } from 'vue-property-decorator';

const { reactiveProp } = mixins;

@Component({
  extends: Line,
  mixins: [reactiveProp],
})

export default class LineChart extends Vue {
  @Prop()
  chartData: any;

  @Prop({ default: function () { return {}; } })
  options!: object;

  mounted() {
    this.renderChart(this.chartData, this.options);
  }

  public renderChart!: (chartData: any, options: any) => void;
}