
export class AgentCompanyUserModel {

    public constructor (id: string, userId: string) {
        this.ID = id;
        this.email = "";
        this.firstName = "";
        this.lastName = "";
        this.name = "";
        this.userId = userId;
    }

    public ID!: string;
    public email!: string;
    public firstName!: string;
    public lastName!: string;
    public name!: string;
    public userId!: string;
}