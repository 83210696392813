import ClickOutside from 'vue-click-outside';
import Multiselect from 'vue-multiselect';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { DropdownModel } from '@/models/dropdownModel';

@Component({
    components: { Multiselect },
    directives: {
        ClickOutside
    }
})
export default class MultiSelect extends Vue {

    @Prop()
    private options!: DropdownModel[];

    @Prop()
    private disabled!: boolean;

    @Prop()
    private invalid!: boolean;

    @Prop()
    private allSelected!: boolean;

    @Prop()
    private placeholder!: string;

    @Prop()
    private selectedValues?: string[] | number[];

    @Prop()
    private isEdit!: boolean;

    private selectedOptions: DropdownModel[] = [];

    private onlyValues: string[] = [];

    private selectAll: boolean = false;

    private created(): void {
        if (this.selectedValues != null && this.selectedValues.length > 0) {
            this.selectedOptions = this.options.filter(option => this.selectedValues?.some((s: string | number) => s === option.value));
        }
        if(this.allSelected && !this.isEdit) {
            const allSelectedValues = this.concatAll();
            this.onlyValues = allSelectedValues;
            this.optionsSelected(allSelectedValues);
        }
    }

    private isSelected(option: DropdownModel): void {
        if (option.value=="" && option.text==this.$t("pages.supply_chain_partners.select_all").toString()) {
            const allSelectedValues = this.concatAll();
            this.onlyValues = allSelectedValues;
            this.optionsSelected(allSelectedValues);
            this.selectAll = true;
        } else{
            this.selectAll = false;
            const allSelectedValues = this.selectedOptions.map((option) => option.value)
            .concat(option.value);
            this.optionsSelected(allSelectedValues);
        }
    }

    private concatAll(): string[] {
        const allOptions: string[] = [];
        this.selectedOptions.splice(0, this.selectedOptions.length);
        this.options.forEach(opt => {
            allOptions.push(opt.text);
            this.selectedOptions.push(opt);
        });
        const values: string[] = [];
        values.push(...allOptions);
        values.splice(0, 1, "");
        return values;
    }

    private isRemoved(option: DropdownModel): void {
        if (option.value=="" && option.text==this.$t("pages.supply_chain_partners.select_all").toString()) {
            this.selectedOptions.splice(0, this.selectedOptions.length);
            this.optionsSelected([]);
            this.onlyValues = [];
            this.selectAll = false;
        } else {
            this.selectAll = false;
            if (this.onlyValues.indexOf('') !== -1) {
                this.onlyValues = this.onlyValues.filter((item) => item !== "" && item !== option.value);
                this.selectedOptions = this.selectedOptions.filter((item) => item.value !== "");
                const allSelectedValues = this.selectedOptions.map((item) => item.value)
                    .filter((item) => item !== option.value);
                this.optionsSelected(allSelectedValues);
                this.selectedOptions = this.selectedOptions.filter((item) => item.value !== option.value);
            } else {
                const allSelectedValues = this.selectedOptions.map((item) => item.value)
                    .filter((item) => item !== option.value);
                this.optionsSelected(allSelectedValues);
            }
        }
    }

    private get label(): string {
        if (this.selectedOptions.length === 0) {
            return this.placeholder;
        }
        else if (this.selectedOptions.length <= 2) {
            const filteredOptions = this.selectedOptions.filter(f=> f.value!=="");
            return  filteredOptions.map(s => s.text).join(', ');
        } else {
            let length = this.selectedOptions.length;
            if (this.selectAll) {
                // this.selectedOptions.splice(0, 1);
                length = this.selectedOptions.length-1;
            } else if(this.selectedOptions.some(e => e.value === '')) {
                length = this.selectedOptions.length-1;
            }
            return this.$t('pages.profile.options_selected', [length, this.placeholder.toLowerCase()]).toString();
        }
    }

    @Emit()
    private optionsSelected(option: (string | number)[]): void {
    }
}
