export class ContactModel {

    public constructor () {
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.phone = "";
        this.name = "";
        this.isAdmin = false;
    }

    public firstName!: string;
    public lastName!: string;
    public email!: string;
    public phone!: string;
    public isAdmin!: boolean;
    public name!: string;
}