import { Doughnut, mixins } from 'vue-chartjs';
import { Vue, Component, Prop } from 'vue-property-decorator';

const { reactiveProp } = mixins;

@Component({
  extends: Doughnut,
  mixins: [reactiveProp],
})

export default class DoughnutChart extends Vue {
  @Prop()
  chartData: any;

  @Prop()
  options: any;

  @Prop()
  centerText: any;

  private plugin(chart: any) {
    const width = chart.chart.width;
    const height = chart.chart.height;
    const ctx = chart.chart.ctx;

    ctx.restore();
    const fontSize = (height / 13).toFixed(2);
    ctx.font = fontSize + "px Arial";
    ctx.textBaseline = "middle";

    const text = this.centerText === undefined ? '' : this.centerText;
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  }

  mounted() {
    this.addPlugin({
      id: 'my-plugin',
      beforeDraw: this.plugin
    });
    this.renderChart(this.chartData, this.options);
  }

  public renderChart!: (chartData: any, options: any) => void;

  public addPlugin!: (temp: object) => void;
}