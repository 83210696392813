import { Guid } from './guid';
import { OrderLineStepParallelModel } from './orderLineStepParallelModel';
import { OrderLineStepProof } from './orderLineStepProof';
import { OrderLineStepStatus } from './orderLineStepStatus';
import { ProductGroup } from './productGroup';
import { StepHistory } from './stepHistoryModal';
import { StepState } from './stepState';

export class BulkOrderLineStepModel {

    public constructor (orderLineStep : BulkOrderLineStepModel){
        this.id = orderLineStep.id;
        this.orderId = orderLineStep.orderId;
        this.orderLineId = orderLineStep.orderLineId;
        this.orderNumber = orderLineStep.orderNumber;
        this.productGroup = orderLineStep.productGroup;
        this.sequence = orderLineStep.sequence;
        this.displayName = orderLineStep.displayName;
        this.stepId = orderLineStep.stepId;
        this.status = orderLineStep.status;
        this.fromCompanyId = orderLineStep.fromCompanyId;
        this.toCompanyId = orderLineStep.toCompanyId;
        this.toCompanyName = orderLineStep.toCompanyName;
        this.validSocialCertificates = orderLineStep.validSocialCertificates;
        this.validSustainabilityCertificates = orderLineStep.validSustainabilityCertificates;
        this.evidencePhotos = orderLineStep.evidencePhotos;
        this.showDetailsCount = orderLineStep.showDetailsCount;
        this.showDetails = orderLineStep.showDetails;
        this.showFactoryAddress = orderLineStep.showFactoryAddress;
        this.showNewsLetter = orderLineStep.showNewsLetter;
        this.showProfile = orderLineStep.showProfile;
        this.compliantAtVerificationDate = orderLineStep.compliantAtVerificationDate;
        this.currentlyValidCertificates = orderLineStep.currentlyValidCertificates;
        this.rejectionReason = orderLineStep.rejectionReason;
        this.stepState = orderLineStep.stepState;
        this.stepHistory = orderLineStep.stepHistory;
        this.isLastStep = orderLineStep.isLastStep;
        this.clientID = orderLineStep.clientID;
        this.cpSupplierID = orderLineStep.cpSupplierID;
        //TTD-2620, Agent orderlines changes
        this.addedByAgent = orderLineStep.addedByAgent;
        this.updatedByAgent = orderLineStep.updatedByAgent;
        this.deletedByAgent = orderLineStep.deletedByAgent;
        //TTD-2620, Agent orderlines changes
        this.approvalRequestedTo = orderLineStep.approvalRequestedTo;
        this.parallelSupplyChain0 = orderLineStep.parallelSupplyChain0;
        this.parallelSupplyChain1 = orderLineStep.parallelSupplyChain1;
        this.parallelSupplyChain2 = orderLineStep.parallelSupplyChain2;
        this.parallelSupplyChain3 = orderLineStep.parallelSupplyChain3;
        this.parallelSupplyChain4 = orderLineStep.parallelSupplyChain4;
        // Sprint 20, TTD-3932
        this.footprintRefNo = orderLineStep.footprintRefNo;
        this.footprintRefID = orderLineStep.footprintRefID;
        this.footprintRefNoUpdatedBy = orderLineStep.footprintRefNoUpdatedBy;
        // Sprint 20, TTD-3940
        this.footprintRefNoPrefix = "";
    }

    public id!: string;
    public orderId!: string;
    public orderLineId!: string;
    public orderNumber!: string;
    public stepId!: number;
    public displayName!: string;
    public productGroup!: ProductGroup;
    public sequence!: number;
    public status!: OrderLineStepStatus;
    public fromCompanyId!: string;
    public toCompanyId!: string;
    public toCompanyName!: string;
    public evidencePhotos: OrderLineStepProof[] = [];
    public validSocialCertificates: number[] = [];
    public validSustainabilityCertificates: number[] = [];
    public showDetailsCount!: number;
    public showNewsLetter!: boolean;
    public showFactoryAddress!: boolean;
    public showDetails!: boolean;
    public showProfile!: boolean;
    public rejectionReason!: string;
    public compliantAtVerificationDate!: boolean;
    public currentlyValidCertificates!: null;
    public stepState!: StepState;
    public stepHistory!: StepHistory[];
    public isLastStep!: string;
    public parallelChainCount!: number;
    public isSelected?: boolean;
    public clientID!: string;
    public cpSupplierID!: string;
    public addedByAgent?: boolean;
    public updatedByAgent?: boolean;
    public deletedByAgent?: boolean;
    public approvalRequestedTo!: string;
    public parallelSupplyChain0!: OrderLineStepParallelModel[];
    public parallelSupplyChain1!: OrderLineStepParallelModel[];
    public parallelSupplyChain2!: OrderLineStepParallelModel[];
    public parallelSupplyChain3!: OrderLineStepParallelModel[];
    public parallelSupplyChain4!: OrderLineStepParallelModel[];
    // Sprint 20, TTD-3932
    public footprintRefNo!: string;
    public footprintRefID!: string;
    public footprintRefNoUpdatedBy!: string;
    // Sprint 20, TTD-3940
    public footprintRefNoPrefix?: string;
}