import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import ClickOutside from 'vue-click-outside';
import moment from "moment";

@Component({
    directives: {
        ClickOutside
    }
})

export default class YearList extends Vue {

    @Prop()
    private checkedYear!: string;

    private isClickOutsideActive: boolean = true;

    private get years(): string[] {
        const years = [];
        let currentYear = moment().format('YYYY');
        years.push(currentYear);
        for(let i=0; i<3; i++){
            const year = parseInt(currentYear);
            currentYear = (year - 1).toString();
            years.push(currentYear);
        }
        return years;
    }

    private clickedOutsideElement() {
        this.isClickOutsideActive = !this.isClickOutsideActive;
        if(this.isClickOutsideActive){
            this.close();
        }
    }

    @Emit()
    private selectedYear(year: string){}

    @Emit()
    private close(){}
}