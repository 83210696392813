import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import Multiselect from 'vue-multiselect';
import { cloneDeep } from "lodash";
import { mapState } from "vuex";

import DropDownEditable from "@/components/general/drop-down-editable/dropDownEditable.vue";
import DropDownInput from "@/components/general/drop-down-input/dropDownInput.vue";

// Services
import { SupplierService } from "@/services/supplierService";
import { ClientService } from "@/services/clientService";

// Helpers
import { CompanyFormDropdownHelper } from "@/helpers/companyFormDropdownHelper";

// Models
import { DropdownModel } from '@/models/dropdownModel';
import { PartnerModel, PartnerRequestModel, SelectedPartnerModel, CPSCPModel } from "@/models/supplierPartnerModel";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { UserModel } from "@/models/userModel";
import { SupplierPartnersBrandsModel } from "@/models/brandWithSupplierModel";
import { SuppliersHelper } from "@/helpers/suppliersHelper";

@Component({
    components: { Multiselect, DropDownEditable, DropDownInput },
    computed: mapState(['user', 'companyType', 'suppliers', 't1AssociatedPartners', 'supplierPartnersBrands'])
})
export default class SelectPartner extends Vue {

    @Prop({default: false})
    private forceReload!: boolean;

    @Prop({default: 'Select partner'})
    private title!: string;

    @Prop({default: 'Search partner'})
    private searchPlaceholder!: string;

    @Prop()
    private linkedPartners!: DropdownModel[];

    @Prop()
    private prevSelectedPartners!: SelectedPartnerModel[];

    @Prop({default: false})
    private showLoading!: boolean;

    private supplierService: SupplierService;

    private clientService: ClientService;

    private searchText: string = '';

    private user!: UserModel;

    private suppliers!: OrderCompanyModel[];

    private partners: PartnerModel[] = [];

    private isLoading: boolean = false;

    private partnerTypes: DropdownModel[] = [];

    private filteredPartners: PartnerModel[] = [];

    private selectedPartnerType: number = 0;

    private selectedPartnersCount: number = 0;

    private t1AssociatedPartners!: CPSCPModel[];

    private supplierPartnersBrands!: SupplierPartnersBrandsModel;

    public constructor() {
        super();
        this.supplierService = new SupplierService();
        this.clientService = new ClientService();
    }

    private async created(): Promise<void> {
      this.partnerTypes = CompanyFormDropdownHelper.getPartnerTypes();
      this.isLoading = true;
      const allSuppliers = this.suppliers.filter(s => s.isAgent !== true);
      const cpList: PartnerRequestModel = {
        t1SCPList: allSuppliers.filter(s => s.isTier1 === true).map(p => p.id)
      }
      let partners: CPSCPModel[] = [];
      if((this.t1AssociatedPartners === undefined || this.t1AssociatedPartners.length === 0) && cpList.t1SCPList.length > 0){
        const response = await this.clientService.getPartners(cpList);
        if(response.success){
          partners = response.t1SCPpartners;
        }
      } else {
        partners = this.t1AssociatedPartners;
      }
      if(partners.length > 0){
        allSuppliers.forEach(s => {
          if(s.id !== this.user.companyId){
            const partner: PartnerModel = new PartnerModel();
            partner.ID = s.id;
            partner.name = s.companyName;
            partner.isSelected = false;
            partner.isTier1 = s.isTier1;
            partner.isDisabled = false;
            partner.isexpanded = false;
            partner.showDDIcon = false;
            partner.partners = [];
            if(this.linkedPartners !== undefined && this.linkedPartners.length > 0){
              if(this.linkedPartners.findIndex(p => p.value === s.id) !== -1){
                partner.isDisabled = true;
                partner.isSelected = true;
              }
            }
            if(this.prevSelectedPartners !== undefined && this.prevSelectedPartners.length > 0){
              if(this.prevSelectedPartners.findIndex(p => p.ID === s.id) !== -1){
                partner.isSelected = true;
              }
            }
            const index = partners.findIndex(t => t.companyID === s.id);
            if(index !== -1){
              partner.showDDIcon = false;
              partner.isexpanded = false;
              const uniquePartnerList = SuppliersHelper.getCPRelatedSuppliers(s.id); // TTD-4350
              partners[index].suppliers.forEach(scp => {
                if(partners[index].companyID !== scp.invitedCompanyID && uniquePartnerList.findIndex(up => up.supplierId === scp.invitedCompanyID) !== -1){
                  const scpPartner: PartnerModel = new PartnerModel();
                  scpPartner.ID = scp.invitedCompanyID;
                  scpPartner.name = scp.companyName;
                  scpPartner.isSelected = false;
                  scpPartner.isexpanded = false;
                  scpPartner.showDDIcon = false;
                  scpPartner.isTier1 = false;
                  scpPartner.partners = [];
                  scpPartner.isDisabled = false;
                  if(this.linkedPartners !== undefined && this.linkedPartners.length > 0){
                    if(this.linkedPartners.findIndex(p => p.value === scp.invitedCompanyID) !== -1){
                      scpPartner.isDisabled = true;
                      scpPartner.isSelected = true;
                    }
                  }
                  if(this.prevSelectedPartners !== undefined && this.prevSelectedPartners.length > 0){
                    if(this.prevSelectedPartners.findIndex(p => p.ID === scp.invitedCompanyID) !== -1){
                      scpPartner.isSelected = true;
                    }
                  }
                  partner.showDDIcon = true;
                  partner.isexpanded = false;
                  partner.partners.push(scpPartner);
                }
              })
            }
            this.partners.push(partner);
          }
        });
      }
      else{
        this.partners = [];
      }
      this.filteredPartners = cloneDeep(this.partners);
      this.selectedPartnerType = 4;
      this.checkSelectedPartnersCount();
      this.isLoading = false;
    }

    private expandCollapse(item: PartnerModel): void {
        item.isexpanded = !item.isexpanded;
    }

    private async getPartners(): Promise<void> {
      if(this.partners.length === 0 || this.forceReload){
        this.isLoading = true;
        await this.supplierService.getSuppliersAsync();
      }
    }

    private updatePartnerList(): void {
      const filterPartners = this.searchText !== '' ? cloneDeep(this.filteredPartners) : cloneDeep(this.partners);
      switch(this.selectedPartnerType){
        case 1:
          filterPartners.forEach(p => {
            p.isSelected = true;
            p.isexpanded = false;
            p.showDDIcon = false;
            if(p.partners.length > 0){
              p.partners.forEach(scp => {
                scp.isSelected = true;
                scp.isexpanded = false;
                scp.showDDIcon = false;
              });
              p.isexpanded = false;
              p.showDDIcon = true;
            }
          });
          this.filteredPartners = cloneDeep(filterPartners);
          break;

        case 2:
          filterPartners.forEach(p => {
            if(!p.isDisabled){
                p.isSelected = p.isTier1 ? true : false;
            }
            p.isexpanded = false;
            p.showDDIcon = false;
            if(p.partners.length > 0){
              p.partners.forEach(scp => {
                if(!scp.isDisabled){
                  scp.isSelected = false;
                }
                scp.isSelected = false;
                scp.isexpanded = false;
                scp.showDDIcon = false;
              });
              p.isexpanded = false;
              p.showDDIcon = true;
            }
          });
          this.filteredPartners = cloneDeep(filterPartners);
          break;

        case 3:
          filterPartners.forEach(p => {
            if(!p.isDisabled){
              p.isSelected = p.isTier1 ? false : true;
            }
            p.isexpanded = false;
            p.showDDIcon = false;
            if(p.partners.length > 0){
              p.partners.forEach(scp => {
                if(!scp.isDisabled){
                  scp.isSelected = true;
                }
                scp.isexpanded = false;
                scp.showDDIcon = false;
              });
              p.isexpanded = true;
              p.showDDIcon = true;
            }
          });
          this.filteredPartners = cloneDeep(filterPartners);
          break;

        default:
          filterPartners.forEach(p => {
            if(!p.isDisabled){
              p.isSelected = false;
            }
            p.isexpanded = false;
            p.showDDIcon = false;
            if(p.partners.length > 0){
              p.partners.forEach(scp => {
                if(!scp.isDisabled){
                  scp.isSelected = false;
                }
                scp.isexpanded = false;
                scp.showDDIcon = false;
              });
              p.isexpanded = true;
              p.showDDIcon = true;
            }
          });
          this.filteredPartners = cloneDeep(filterPartners);
          break;
      }
      this.checkSelectedPartnersCount();
    }

    private checkSelectedPartnersCount(): void {
      let count = 0;
      this.filteredPartners.forEach(p => {
        if(p.isSelected){
          count = count + 1;
        }
        if(p.partners.filter(s => s.isSelected).length > 0){
          count = count + p.partners.filter(s => s.isSelected).length;
        }
      })
      this.selectedPartnersCount = count;
    }

    private deleteSearchText(): void {
      this.searchText = '';
      this.updatePartnerListOnSearch();
    }

    private updatePartnerListOnSearch(): void {
      const filteredList: PartnerModel[] = [];
      const partners = cloneDeep(this.partners);
      partners.forEach(p => {
        const filteredSCP = p.partners.filter(scp => scp.name.toLowerCase().includes(this.searchText.toLowerCase())); // TTD-4615
        if(filteredSCP.length > 0){
          p.partners = filteredSCP;
          filteredList.push(p);
        }
        else{
          if(p.name.toLowerCase().includes(this.searchText.toLowerCase())){ // TTD-4615
            p.partners = [];
            filteredList.push(p);
          }
        }
      });
      this.filteredPartners = cloneDeep(filteredList);
    }

    private get isPartnerSelected(): boolean {
      let selected: boolean = false;
      this.filteredPartners.forEach(f => {
        if (f.isSelected) {
          selected = true;
          return true;
        } else if (f.partners.filter(p => p.isSelected === true).length > 0) {
          selected = true;
          return true;
        }
      })
      return selected;
    }

    private sendSelectedPartners(): void {
      const selectedPartnersList: SelectedPartnerModel[] = [];
      this.filteredPartners.forEach(p => {
        if(p.isSelected && !p.isDisabled){
          selectedPartnersList.push(new SelectedPartnerModel(p.ID, p.name, p.isTier1));
        }
        const scps: SelectedPartnerModel[] = p.partners.filter(scp => scp.isSelected).map(s => new SelectedPartnerModel(s.ID, s.name, s.isTier1));
        if(scps.length > 0){
          selectedPartnersList.push(...scps);
        }
      });
      this.selectedPartners(selectedPartnersList);
    }

    private updatePartnerType(){
      this.checkSelectedPartnersCount();
      this.selectedPartnerType = 4;
    }

    @Emit()
    private selectedPartners(partners: SelectedPartnerModel[]): void {}

    @Emit()
    private closeSelectPartner(isClosed: boolean): void {
    }
}
