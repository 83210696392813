import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import lodash from "lodash";
import moment from "moment";
import { mapState } from "vuex";

// Model
import { UserModel } from "@/models/userModel";
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    components: { VuePdfApp },
    computed: mapState(['companyNameList'])
})

export default class ViewDocumentWithComments extends Vue {

    @Prop()
    private document!: ComplianceDocSentResponseModel;

    @Prop()
    private childDoc!: ComplianceDocSentResponseModel;

    private pdfDocument!: any;
    private isPdfReady: boolean = false;
    private config: any = {
        toolbar: false
    };

    private user: UserModel = new UserModel;

    private companyNameList!: CompanyNameModel[];

    public constructor() {
        super();
    }

    private async created(): Promise<void> {
        this.user = this.$store.getters.user as UserModel;
        this.document.fromCompanyName = this.companyNameList.filter(c => c.ID == this.document.fromCompanyID).length > 0 ? this.companyNameList.filter(c => c.ID == this.document.fromCompanyID)[0].name : this.document.fromCompanyName;
    }

    private get brands(): string {
        const uniqBrands = lodash.uniq(this.document.brands);
        return uniqBrands.join(', ');
    }

    private get formattedDate(): string {
        return moment(this.document.validtoDate).format("D MMMM YYYY");
    }

    private formatDate(dateToBeFormatted: string): string {
        return moment(dateToBeFormatted).format("D MMMM YYYY");
    }

    private getComments(comments: any): any {
        if(comments == null) {
            return [];
        }
        return comments.filter((s: { state: number; }) => s.state < 2);
    }

    @Emit()
    private close(success: boolean) {
    }
}
