import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { mapState } from 'vuex';
import lodash from "lodash";
import { v4 as uuidv4 } from 'uuid';

// Components
import VuePdfApp from "vue-pdf-app";

// Services
import { ClientRequestFootPrintData } from "@/services/footPrintRequestService";

// Helpers
import { FootprintDropdownHelper } from "@/helpers/footprintDropdownHelper";
import { FootprintPermissionsHelper } from "@/helpers/footprintPermissionsHelper";

// Modals
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { UserModel } from "@/models/userModel";
import { DropdownModel } from "@/models/dropdownModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { FootprintPartnerModel } from "@/models/orderLineStepDisplayModel";
import { TransportFootprintModel, ModeOfTransportModel, TransportFootprintRequestModel } from "@/models/transportFootprintModel";
import { DeleteDocRequestModel, FootPrintChangedConstant, FootPrintChangedTabConstant, FootPrintCompletedConstant, FootPrintConstant, FootprintDataResponseModel } from "@/models/footprintModel";
import { AccessoriesModel, CuttingFootprintModel, EnergySupportingDocumentModel, FabricModel, GarmentDataModel, GarmentFabricComposition, GarmentFootprintModel, GarmentFootprintRequestModel, GarmentModel, PackagingModel, PostTreatmentModel, GarmentStepsDataModel, ThermalEnergyModel } from "@/models/garmentFootprintModel";
import { CountryHelper } from "@/helpers/countryHelper";
import { Guid } from "@/models/guid";
import { FootprintStepsModel, StepReferenceModel } from "@/models/orderLineStepModel";

@Component({
	components: { VuePdfApp },
	computed: mapState(['orderLoading', 'companyType', 'orders', 'suppliers', 'user', 'inprogressOrderStepsLoading', 'completedOrderStepsLoading', 'footprintPartner', 'userRole', 'isFootprintChanged'])
})
export default class GarmentFootprint extends Vue {

	@Prop()
	private orderId!: string;

	@Prop()
	private deadline!: string;

	@Prop()
	private allFootprintSteps!: StepReferenceModel[];
	
	@Prop()
	private activeStep!: string;
	//Spint-22 TTD-4212
	@Prop()
	private tabIndex!: number;

	private clientRequestFootPrintData: ClientRequestFootPrintData;

	private garment: GarmentModel = new GarmentModel;

	private companyType!: number;

	private orders!: OrderLineDisplayModel[];

	private footprintPartner!: FootprintPartnerModel[];

	private user!: UserModel;

	private suppliers!: OrderCompanyModel[];

	private orderRelatedPartners: OrderCompanyModel[] = [];

	private showNamePartnerList: boolean = false;

	private treatmentIndex: number = -1;

	private materialsList: DropdownModel[] = [];

	private sourcesList: DropdownModel[] = [];

	private accessoriesList: DropdownModel[] = [];

	private treatmentList: DropdownModel[] = [];

	private showGarmtFootprint: boolean = true;

	private garmentTransport: TransportFootprintModel[] = [];

	private garmentFootprintClone: GarmentModel = new GarmentModel;

	private garmentTransportClone: TransportFootprintModel[] = [];

	private garmentFootprintCloneInitialState: GarmentModel = new GarmentModel;

	private garmentTransportCloneInitialState: TransportFootprintModel[] = [];

	private garmentFpChangedCounter: number = 0;

	private garmentTpChangedCounter: number = 0;

	private disableSave: boolean = false;

	private isSaving: boolean = false;

	private showUploadDocModal: boolean = false;

	private docFile: any = null;

	private isDocUploading: boolean = false;

	private docPreview: EnergySupportingDocumentModel = new EnergySupportingDocumentModel();

	private pdfDocument!: any;

	private isPdfReady: boolean = false;

	private showPdfPreviewModal: boolean = false;

	private showGarmentTransport: boolean = false;

	private isMsdsDoc: boolean = false;

	private isDeletingDoc: boolean = false;

	private deleteMsds: boolean = false;

	private deletedDocID: string = "";

	private footprintStatus: string = 'red';

	private transportStatus: string = 'red';

	private showDeleteModal: boolean = false;

	private treatmentDelIndex: number = 0;

	private treatmentDelDocIndex: number = 0;

	private userRole!: string;

	private showMsdsPdfPreviewModal: boolean = false;

	private modeOfTransportList: DropdownModel[] = [];

	private transportUnitList: DropdownModel[] = [];

	private productCategory: DropdownModel[] = [];

	private accOriginList: DropdownModel[] = [];

	private showGarment: boolean = true;

	private showCutting: boolean = true;

	private showWarningModal: boolean = false;

	private transportToBeDeleted: boolean = false;

	private isTabRemoved: boolean = false;

	private isGarmentFieldEmpty: boolean = false;

	private transportFieldEmpty: boolean = false;

	private isFootprintChanged!: string;

	private fiberList: DropdownModel[] = [];

	private cuttingWasteList: DropdownModel[] = [];

	private FpAccDeletedCounter: number = 0;

	private FpPostDeletedCounter: number = 0;

	private FpPackDeletedCounter: number = 0;

	private TpDeletedCounter: number = 0;

	private userCompanyId!: string;

	private footprintOrder!: OrderLineDisplayModel;

	private garmentWrapper: GarmentStepsDataModel = new GarmentStepsDataModel();

	private noGarmentSteps: boolean = false;

	private stepForWrapper: StepReferenceModel[] = [];

	private fpStatus: string = "red";

	private tpStatus: string = "red";

	private showNoGarmntStepMsg: boolean = false;

	private activeGarmentStep: string = Guid.Empty;

	private config: any = {
		toolbar: false
	};

	public constructor() {
		super();
		this.clientRequestFootPrintData = new ClientRequestFootPrintData();
	}

	private async created(): Promise<void> {
		this.productCategory = FootprintDropdownHelper.getProductCategory();
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		this.userCompanyId = this.user.companyId !== undefined && this.user.companyId !== '' ? this.user.companyId : Guid.Empty;
		this.sourcesList = FootprintDropdownHelper.getSourcesList();
		this.materialsList = FootprintDropdownHelper.getMaterialList();
		this.treatmentList = FootprintDropdownHelper.getTreatmentList();
		this.accessoriesList = FootprintDropdownHelper.getAccessoriesList();
		this.modeOfTransportList = FootprintDropdownHelper.getModeOfTransportList();
		this.transportUnitList = FootprintDropdownHelper.getTransportUnit();
		this.cuttingWasteList = FootprintDropdownHelper.getCuttingWasteType();
		this.fiberList = FootprintDropdownHelper.getFiberTypeList();
		this.accOriginList = lodash.orderBy(CountryHelper.getCountryList(), ['text'], ['asc']);
		const allOrders = lodash.cloneDeep(this.orders);
		const order = allOrders.filter(o => o.orderId === this.orderId)[0];
		const garmentSteps: StepReferenceModel[] = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && (s.stepId === 10 || s.stepId === 25 || s.stepId === 32));
		this.footprintOrder = order;
		if (garmentSteps.length>=1) {
			this.showNoGarmntStepMsg = false;
			this.stepForWrapper.push(garmentSteps[garmentSteps.length-1]);
			if (order.garment.garmentDetails.ID !== "") {
				this.garment.garmentDetails = lodash.cloneDeep(order.garment.garmentDetails);
				this.garment.garmentDetails.toCompanyUser.isValid = true;
				this.garment.garmentDetails.garmentTotalWeight.isValid = true;
				this.garment.garmentDetails.productCategory.isValid = true;
				this.garment.garmentDetails.productType.isValid = true;
				this.garment.garmentDetails.total.isValid = true;
				if(this.garment.garmentDetails.garmentTotalWeight.weight === -1){
					this.garment.garmentDetails.garmentTotalWeight.weight = '';
				}
				this.garment.garmentDetails.garmentFabric.forEach(fabric => {
					fabric.fabricReference.isValid = true;
					fabric.fabricPercent.isValid = true;
					if(fabric.fabricPercent.percent === -1){
						fabric.fabricPercent.percent = '';
					}
				});
				this.garment.cuttingWaste = order.garment.cuttingWaste;
				this.garment.cuttingWaste.forEach(cut => {
					cut.cuttingWasteRecycle.isValid = true;
					cut.fabricWastePercent.isValid = true;
					if(cut.fabricWastePercent.percent === -1){
						cut.fabricWastePercent.percent = '';
					}
				});
				this.garment.garmentDetails.total.totalcount = 0;
				if (order.fabric.fabricFootprint.length < this.garment.garmentDetails.garmentFabric.length) {
					this.garment.garmentDetails.garmentFabric.forEach((gf, index) => {
						const len = order.fabric.fabricFootprint.filter(ff => ff.reference.referenceNo === gf.fabricReference.referenceNo).length;
						if (len === 0) {
							this.garment.garmentDetails.garmentFabric.splice(index, 1);
						}
					})
				}
				order.fabric.fabricFootprint.forEach((fabric, index) => {
					const garmIndex = this.garment.garmentDetails.garmentFabric.findIndex(g => g.fabricReference.fabricID === fabric.ID);
					if(garmIndex === -1){
						this.garment.garmentDetails.garmentFabric.push(new FabricModel());
						this.garment.garmentDetails.garmentFabric[index].ID = uuidv4();
						this.garment.garmentDetails.garmentFabric[index].fabricReference.referenceNo = fabric.reference.referenceNo;
						this.garment.garmentDetails.garmentFabric[index].fabricReference.fabricID = fabric.ID;
						this.garment.garmentDetails.garmentFabric[index].fabricPercent.percent = "";
						this.garment.garmentDetails.garmentFabric[index].filledByCompanyID = this.userCompanyId;
						this.garment.garmentDetails.garmentFabric[index].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
						this.garment.cuttingWaste.push(new CuttingFootprintModel());
						this.garment.cuttingWaste[index].fabricUniqueID = fabric.ID;
						this.garment.cuttingWaste[index].stepID = this.stepForWrapper[0].mcStepUniqueId;
					}
					else{
						this.garment.garmentDetails.garmentFabric[garmIndex].fabricPercent.isValid = true;
						this.garment.garmentDetails.garmentFabric[garmIndex].fabricReference.isValid = true;
					}
					this.garment.garmentDetails.garmentFabric[index].fabricReference.referenceNo = fabric.reference.referenceNo;
					this.garment.garmentDetails.garmentFabric[index].fabricReference.fabricID = fabric.ID;
					this.garment.garmentDetails.total.totalcount = Number(this.garment.garmentDetails.total.totalcount) + (Number(this.garment.garmentDetails.garmentFabric[index].fabricPercent.percent) ? Number(this.garment.garmentDetails.garmentFabric[index].fabricPercent.percent) : 0);
				});
				this.garment.garmentDetails.garmentFabric.forEach(garmFab => {
					const index = order.fabric.fabricFootprint.findIndex(f => f.ID === garmFab.fabricReference.fabricID);
					if(index === -1){
						this.garment.garmentDetails.garmentFabric.splice(index, 1);
						this.garment.cuttingWaste.splice(index, 1);
					}
				});
				if(order.garment.transport.length > 0){
					this.garmentTransport = order.garment.transport;
				}
				this.defaultGarmtTransport();
			} else {
				this.garment.garmentDetails = new GarmentFootprintModel();
				this.garment.garmentDetails.ID = uuidv4();
				this.garment.garmentDetails.stepID = this.stepForWrapper[0].mcStepUniqueId;
				this.garment.garmentDetails.toCompanyUser.isValid = true;
				this.garment.garmentDetails.garmentTotalWeight.isValid = true;
				this.garment.garmentDetails.productCategory.isValid = true;
				this.garment.garmentDetails.productType.isValid = true;
				this.garment.garmentDetails.total.isValid = true;
				this.garment.garmentDetails.garmentFabric = [];
				this.garment.cuttingWaste = [];
				if(this.allFootprintSteps.filter(a => a.mcStepUniqueId === garmentSteps[0].mcStepUniqueId).length > 1){
					const fabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
					if(fabricStep.length > 0){
						fabricStep.forEach(fs => {	
							const mcFabricStep = order.fabric.fabricFootprint.filter(f => f.stepID === fs.mcStepUniqueId);
							if(mcFabricStep.length > 0){
								mcFabricStep.forEach(fabric => {
									this.garment.garmentDetails.garmentFabric.push(new FabricModel());
									const lastIndex = this.garment.garmentDetails.garmentFabric.length-1; 
									this.garment.garmentDetails.garmentFabric[lastIndex].ID = uuidv4();
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.referenceNo = fabric.reference.referenceNo;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.fabricID = fabric.ID;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricPercent.percent = "";
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByCompanyID = this.userCompanyId;
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
									this.garment.cuttingWaste.push(new CuttingFootprintModel());
									this.garment.cuttingWaste[lastIndex].fabricUniqueID = fabric.ID;
								})
							}
						})
					}
					const pc0FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === garmentSteps[0].mcStepUniqueId && s.pcOfMC === 0 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
					if(pc0FabricStep.length > 0){
						pc0FabricStep.forEach(pc0 => {
							const pc0Fabric = order.fabric.fabricFootprint.filter(f => f.stepID === pc0.pcStepUniqueId);
							if(pc0Fabric.length > 0){
								pc0Fabric.forEach(fabric => {
									this.garment.garmentDetails.garmentFabric.push(new FabricModel());
									const lastIndex = this.garment.garmentDetails.garmentFabric.length-1; 
									this.garment.garmentDetails.garmentFabric[lastIndex].ID = uuidv4();
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.referenceNo = fabric.reference.referenceNo;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.fabricID = fabric.ID;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricPercent.percent = "";
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByCompanyID = this.userCompanyId;
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
									this.garment.cuttingWaste.push(new CuttingFootprintModel());
									this.garment.cuttingWaste[lastIndex].fabricUniqueID = fabric.ID;
								})
							}
						})
					}
					const pc1FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === garmentSteps[0].mcStepUniqueId && s.pcOfMC === 1 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
					if(pc1FabricStep.length > 0){
						pc1FabricStep.forEach(pc1 => {
							const pc1Fabric = order.fabric.fabricFootprint.filter(f => f.stepID === pc1.pcStepUniqueId);
							if(pc1Fabric.length > 0){
								pc1Fabric.forEach(fabric => {
									this.garment.garmentDetails.garmentFabric.push(new FabricModel());
									const lastIndex = this.garment.garmentDetails.garmentFabric.length-1; 
									this.garment.garmentDetails.garmentFabric[lastIndex].ID = uuidv4();
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.referenceNo = fabric.reference.referenceNo;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.fabricID = fabric.ID;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricPercent.percent = "";
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByCompanyID = this.userCompanyId;
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
									this.garment.cuttingWaste.push(new CuttingFootprintModel());
									this.garment.cuttingWaste[lastIndex].fabricUniqueID = fabric.ID;
								})
							}
						})
					}
					const pc2FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === garmentSteps[0].mcStepUniqueId && s.pcOfMC === 2 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
					if(pc2FabricStep.length > 0){
						pc2FabricStep.forEach(pc2 => {
							const pc2Fabric = order.fabric.fabricFootprint.filter(f => f.stepID === pc2.pcStepUniqueId);
							if(pc2Fabric.length > 0){
								pc2Fabric.forEach(fabric => {
									this.garment.garmentDetails.garmentFabric.push(new FabricModel());
									const lastIndex = this.garment.garmentDetails.garmentFabric.length-1; 
									this.garment.garmentDetails.garmentFabric[lastIndex].ID = uuidv4();
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.referenceNo = fabric.reference.referenceNo;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.fabricID = fabric.ID;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricPercent.percent = "";
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByCompanyID = this.userCompanyId;
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
									this.garment.cuttingWaste.push(new CuttingFootprintModel());
									this.garment.cuttingWaste[lastIndex].fabricUniqueID = fabric.ID;
								})
							}
						})
					}
					const pc3FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === garmentSteps[0].mcStepUniqueId && s.pcOfMC === 3 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
					if(pc3FabricStep.length > 0){
						pc3FabricStep.forEach(pc3 => {
							const pc3Fabric = order.fabric.fabricFootprint.filter(f => f.stepID === pc3.pcStepUniqueId);
							if(pc3Fabric.length > 0){
								pc3Fabric.forEach(fabric => {
									this.garment.garmentDetails.garmentFabric.push(new FabricModel());
									const lastIndex = this.garment.garmentDetails.garmentFabric.length-1; 
									this.garment.garmentDetails.garmentFabric[lastIndex].ID = uuidv4();
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.referenceNo = fabric.reference.referenceNo;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.fabricID = fabric.ID;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricPercent.percent = "";
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByCompanyID = this.userCompanyId;
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
									this.garment.cuttingWaste.push(new CuttingFootprintModel());
									this.garment.cuttingWaste[lastIndex].fabricUniqueID = fabric.ID;
								})
							}
						})
					}
					const pc4FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === garmentSteps[0].mcStepUniqueId && s.pcOfMC === 4 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
					if(pc4FabricStep.length > 0){
						pc4FabricStep.forEach(pc4 => {
							const pc4Fabric = order.fabric.fabricFootprint.filter(f => f.stepID === pc4.pcStepUniqueId);
							if(pc4Fabric.length > 0){
								pc4Fabric.forEach(fabric => {
									this.garment.garmentDetails.garmentFabric.push(new FabricModel());
									const lastIndex = this.garment.garmentDetails.garmentFabric.length-1; 
									this.garment.garmentDetails.garmentFabric[lastIndex].ID = uuidv4();
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.referenceNo = fabric.reference.referenceNo;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.fabricID = fabric.ID;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricPercent.percent = "";
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByCompanyID = this.userCompanyId;
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
									this.garment.cuttingWaste.push(new CuttingFootprintModel());
									this.garment.cuttingWaste[lastIndex].fabricUniqueID = fabric.ID;
								})
							}
						})
					}
				} else if(this.allFootprintSteps.filter(a => a.mcStepUniqueId === garmentSteps[0].mcStepUniqueId).length === 1){
					const fabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
					if(fabricStep.length > 0){
						fabricStep.forEach(fs => {	
							const mcFabricStep = order.fabric.fabricFootprint.filter(f => f.stepID === fs.mcStepUniqueId);
							if(mcFabricStep.length > 0){
								mcFabricStep.forEach(fabric => {
									this.garment.garmentDetails.garmentFabric.push(new FabricModel());
									const lastIndex = this.garment.garmentDetails.garmentFabric.length-1; 
									this.garment.garmentDetails.garmentFabric[lastIndex].ID = uuidv4();
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.referenceNo = fabric.reference.referenceNo;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricReference.fabricID = fabric.ID;
									this.garment.garmentDetails.garmentFabric[lastIndex].fabricPercent.percent = "";
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByCompanyID = this.userCompanyId;
									this.garment.garmentDetails.garmentFabric[lastIndex].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
									this.garment.cuttingWaste.push(new CuttingFootprintModel());
									this.garment.cuttingWaste[lastIndex].fabricUniqueID = fabric.ID;
								})
							}
						})
					}
				}
				this.disableSave = true;
			}
			if (order.garment.packaging.length > 0) {
				this.garment.packaging = order.garment.packaging;
				this.garment.packaging.forEach(pack => {
					pack.colliPerPackaging.isValid = true;
					pack.matterial.isValid = true;
					pack.reference.isValid = true;
					pack.weight.isValid = true;
					if(pack.weight.value === -1){
						pack.weight.value = '';
					}
					if(pack.colliPerPackaging.number === -1){
						pack.colliPerPackaging.number = '';
					}
				});
			} else {
				if(this.garment.garmentDetails.toCompanyUser.toCompanyID === Guid.Empty || this.garment.garmentDetails.toCompanyUser.toCompanyID === ""){
					this.garment.packaging.push(new PackagingModel());
					this.garment.packaging.push(new PackagingModel());
					this.garment.packaging.push(new PackagingModel());
					this.garment.packaging.forEach(pack => {
						pack.fabricUniqueID = uuidv4();
						pack.stepID = this.stepForWrapper[0].mcStepUniqueId;
						pack.filledByCompanyID = this.userCompanyId;
						pack.filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
					});
				}
			}
			if (order.garment.postTreatment.length > 0) {
				this.garment.postTreatment = order.garment.postTreatment;
				this.garment.postTreatment.forEach(pt => {
					pt.reference.isValid = true;
					pt.solarEnergy.isValid = true;
					pt.thermalEnergy.isValid = true;
					pt.thermalEnergySource.isValid = true;
					pt.totalEnergyUsed.isValid = true;
					pt.treatmentType.isValid = true;
					pt.waterUsedInFabric.isValid = true;
					pt.windEnergy.isValid = true;
					if(pt.totalEnergyUsed.value === -1){
						pt.totalEnergyUsed.value = '';
					}
					if(pt.windEnergy.value === -1){
						pt.windEnergy.value = '';
					}
					if(pt.solarEnergy.value === -1){
						pt.solarEnergy.value = '';
					}
					if(pt.thermalEnergy.value === -1){
						pt.thermalEnergy.value = '';
					}
					if(pt.thermalEnergyNew.length === 0){
						pt.thermalEnergyNew.push(new ThermalEnergyModel());
						pt.thermalEnergySourceNew.push(new ThermalEnergyModel());
					}else{
						pt.thermalEnergyNew.forEach((te) =>{
							if(te.value === -1){
								te.value = '';
							}
							te.isValid = true;
						});
						pt.thermalEnergySourceNew.forEach((ts) => {
							ts.isValid = true;
						});
					}
				});
			}
			if (order.garment.accessories.length > 0) {
				this.garment.accessories = order.garment.accessories;
				this.garment.accessories.forEach(acc => {
					acc.accessoriesPergarment.isValid = true;
					acc.accessoriesType.isValid = true;
					acc.matterial.isValid = true;
					acc.originCountry.isValid = true;
					acc.reference.isValid = true;
					acc.weight.isValid = true;
					if(acc.weight.value === -1){
						acc.weight.value = '';
					}
					if(acc.accessoriesPergarment.number === -1){
						acc.accessoriesPergarment.number = '';
					}
				});
			} else {
				if(this.garment.garmentDetails.toCompanyUser.toCompanyID === Guid.Empty || this.garment.garmentDetails.toCompanyUser.toCompanyID === ""){
					this.garment.accessories.push(new AccessoriesModel());
					this.garment.accessories[this.garment.accessories.length-1].stepID = this.stepForWrapper[0].mcStepUniqueId;
					this.garment.accessories[this.garment.accessories.length-1].filledByCompanyID = this.userCompanyId;
					this.garment.accessories[this.garment.accessories.length-1].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
				}
			}
			this.garment.garmentDetails.stepID = this.stepForWrapper[0].mcStepUniqueId;
			this.garmentFootprintClone = lodash.cloneDeep(this.garment);
			this.garmentTransportClone = lodash.cloneDeep(this.garmentTransport);
			this.garmentFootprintCloneInitialState = lodash.cloneDeep(this.garment);
			this.garmentTransportCloneInitialState = lodash.cloneDeep(this.garmentTransport);
			this.garmentFpStatus();
			this.checkNonEmptyFields();
			this.createGarmentCard();
		} else {
			this.showNoGarmntStepMsg = true;
		}
	}

	private createGarmentCard(): void {
		this.garmentWrapper.stepID = this.stepForWrapper[0].mcStepUniqueId;
		this.garmentWrapper.referenceNo = this.stepForWrapper[0].footprintRefNoPrefix;
		this.garmentWrapper.referenceNoPrefix = this.stepForWrapper[0].footprintRefNoPrefix;
		this.garmentWrapper.supplierName = this.stepForWrapper[0].toCompanyName;
		this.garmentWrapper.showWrapper = true;
	}

	// Added for TTD-3061
	private get fabricComposition(): GarmentFabricComposition[][] {
		const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		let compositionsGroups: GarmentFabricComposition[][] = [];
		const garmentSteps: StepReferenceModel[] = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && (s.stepId === 10 || s.stepId === 25 || s.stepId === 32));
		if (order && order.fabric.fabricFootprint) {
			if(this.allFootprintSteps.filter(a => a.mcStepUniqueId === garmentSteps[0].mcStepUniqueId).length > 1){
				const fabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
				if(fabricStep.length > 0){
					fabricStep.forEach(fs => {	
						const mcFabricStep = order.fabric.fabricFootprint.filter(f => f.stepID === fs.mcStepUniqueId);
						if(mcFabricStep.length > 0){
							mcFabricStep.forEach(fabric => {
								let compositions: GarmentFabricComposition[] = [];
								if (fabric.yarnFabric.length>0) {
									fabric.yarnFabric.forEach(yarnF => {
										compositions.push({'composition': yarnF.yarnRefNumber.referenceNumber, 'fabricPercent': yarnF.yarnPercent.count !== -1 ? yarnF.yarnPercent.count.toString() : ''});
									})
								}
								if (fabric.fiberFabric.length>0) {
									fabric.fiberFabric.forEach(fibreF => {
										const refText = this.fiberList.filter(f => f.value===fibreF.fiberType.type).length > 0 ? this.fiberList.filter(f => f.value===fibreF.fiberType.type)[0].text : '';
										compositions.push({'composition': refText, 'fabricPercent': fibreF.fiberValue.value !== -1 ? fibreF.fiberValue.value.toString(): ''});
									})
								}
								compositionsGroups.push(compositions);
							})
						}
					})
				}
				const pc0FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === garmentSteps[0].mcStepUniqueId && s.pcOfMC === 0 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
				if(pc0FabricStep.length > 0){
					pc0FabricStep.forEach(pc0 => {
						const pc0Fabric = order.fabric.fabricFootprint.filter(f => f.stepID === pc0.pcStepUniqueId);
						if(pc0Fabric.length > 0){
							pc0Fabric.forEach(fabric => {
								let compositions: GarmentFabricComposition[] = [];
								if (fabric.yarnFabric.length>0) {
									fabric.yarnFabric.forEach(yarnF => {
										compositions.push({'composition': yarnF.yarnRefNumber.referenceNumber, 'fabricPercent': yarnF.yarnPercent.count !== -1 ? yarnF.yarnPercent.count.toString() : ''});
									})
								}
								if (fabric.fiberFabric.length>0) {
									fabric.fiberFabric.forEach(fibreF => {
										const refText = this.fiberList.filter(f => f.value===fibreF.fiberType.type).length > 0 ? this.fiberList.filter(f => f.value===fibreF.fiberType.type)[0].text : '';
										compositions.push({'composition': refText, 'fabricPercent': fibreF.fiberValue.value !== -1 ? fibreF.fiberValue.value.toString() : ''});
									})
								}
								compositionsGroups.push(compositions);
							})
						}
					})
				}
				const pc1FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === garmentSteps[0].mcStepUniqueId && s.pcOfMC === 1 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
				if(pc1FabricStep.length > 0){
					pc1FabricStep.forEach(pc1 => {
						const pc1Fabric = order.fabric.fabricFootprint.filter(f => f.stepID === pc1.pcStepUniqueId);
						if(pc1Fabric.length > 0){
							pc1Fabric.forEach(fabric => {
								let compositions: GarmentFabricComposition[] = [];
								if (fabric.yarnFabric.length>0) {
									fabric.yarnFabric.forEach(yarnF => {
										compositions.push({'composition': yarnF.yarnRefNumber.referenceNumber, 'fabricPercent': yarnF.yarnPercent.count !== -1 ? yarnF.yarnPercent.count.toString() : ''});
									})
								}
								if (fabric.fiberFabric.length>0) {
									fabric.fiberFabric.forEach(fibreF => {
										const refText = this.fiberList.filter(f => f.value===fibreF.fiberType.type).length > 0 ? this.fiberList.filter(f => f.value===fibreF.fiberType.type)[0].text : '';
										compositions.push({'composition': refText, 'fabricPercent': fibreF.fiberValue.value !== -1 ? fibreF.fiberValue.value.toString() : ''});
									})
								}
								compositionsGroups.push(compositions);
							})
						}
					})
				}
				const pc2FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === garmentSteps[0].mcStepUniqueId && s.pcOfMC === 2 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
				if(pc2FabricStep.length > 0){
					pc2FabricStep.forEach(pc2 => {
						const pc2Fabric = order.fabric.fabricFootprint.filter(f => f.stepID === pc2.pcStepUniqueId);
						if(pc2Fabric.length > 0){
							pc2Fabric.forEach(fabric => {
								let compositions: GarmentFabricComposition[] = [];
								if (fabric.yarnFabric.length>0) {
									fabric.yarnFabric.forEach(yarnF => {
										compositions.push({'composition': yarnF.yarnRefNumber.referenceNumber, 'fabricPercent': yarnF.yarnPercent.count !== -1 ? yarnF.yarnPercent.count.toString() : ''});
									})
								}
								if (fabric.fiberFabric.length>0) {
									fabric.fiberFabric.forEach(fibreF => {
										const refText = this.fiberList.filter(f => f.value===fibreF.fiberType.type).length > 0 ? this.fiberList.filter(f => f.value===fibreF.fiberType.type)[0].text : '';
										compositions.push({'composition': refText, 'fabricPercent': fibreF.fiberValue.value !== -1 ? fibreF.fiberValue.value.toString() : ''});
									})
								}
								compositionsGroups.push(compositions);
							})
						}
					})
				}
				const pc3FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === garmentSteps[0].mcStepUniqueId && s.pcOfMC === 3 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
				if(pc3FabricStep.length > 0){
					pc3FabricStep.forEach(pc3 => {
						const pc3Fabric = order.fabric.fabricFootprint.filter(f => f.stepID === pc3.pcStepUniqueId);
						if(pc3Fabric.length > 0){
							pc3Fabric.forEach(fabric => {
								let compositions: GarmentFabricComposition[] = [];
								if (fabric.yarnFabric.length>0) {
									fabric.yarnFabric.forEach(yarnF => {
										compositions.push({'composition': yarnF.yarnRefNumber.referenceNumber, 'fabricPercent': yarnF.yarnPercent.count  !== -1 ? yarnF.yarnPercent.count.toString() : ''});
									})
								}
								if (fabric.fiberFabric.length>0) {
									fabric.fiberFabric.forEach(fibreF => {
										const refText = this.fiberList.filter(f => f.value===fibreF.fiberType.type).length > 0 ? this.fiberList.filter(f => f.value===fibreF.fiberType.type)[0].text : '';
										compositions.push({'composition': refText, 'fabricPercent': fibreF.fiberValue.value !== -1 ? fibreF.fiberValue.value.toString() : ''});
									})
								}
								compositionsGroups.push(compositions);
							})
						}
					})
				}
				const pc4FabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === garmentSteps[0].mcStepUniqueId && s.pcOfMC === 4 && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
				if(pc4FabricStep.length > 0){
					pc4FabricStep.forEach(pc4 => {
						const pc4Fabric = order.fabric.fabricFootprint.filter(f => f.stepID === pc4.pcStepUniqueId);
						if(pc4Fabric.length > 0){
							pc4Fabric.forEach(fabric => {
								let compositions: GarmentFabricComposition[] = [];
								if (fabric.yarnFabric.length>0) {
									fabric.yarnFabric.forEach(yarnF => {
										compositions.push({'composition': yarnF.yarnRefNumber.referenceNumber, 'fabricPercent': yarnF.yarnPercent.count !== -1 ? yarnF.yarnPercent.count.toString() : ''});
									})
								}
								if (fabric.fiberFabric.length>0) {
									fabric.fiberFabric.forEach(fibreF => {
										const refText = this.fiberList.filter(f => f.value===fibreF.fiberType.type).length > 0 ? this.fiberList.filter(f => f.value===fibreF.fiberType.type)[0].text : '';
										compositions.push({'composition': refText, 'fabricPercent': fibreF.fiberValue.value !== -1 ? fibreF.fiberValue.value.toString() : ''});
									})
								}
								compositionsGroups.push(compositions);
							})
						}
					})
				}
			} else if(this.allFootprintSteps.filter(a => a.mcStepUniqueId === garmentSteps[0].mcStepUniqueId).length === 1){
				const fabricStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
				if(fabricStep.length > 0){
					fabricStep.forEach(fs => {	
						const mcFabricStep = order.fabric.fabricFootprint.filter(f => f.stepID === fs.mcStepUniqueId);
						if(mcFabricStep.length > 0){
							mcFabricStep.forEach(fabric => {
								let compositions: GarmentFabricComposition[] = [];
								if (fabric.yarnFabric.length>0) {
									fabric.yarnFabric.forEach(yarnF => {
										compositions.push({'composition': yarnF.yarnRefNumber.referenceNumber, 'fabricPercent': yarnF.yarnPercent.count  !== -1 ? yarnF.yarnPercent.count.toString() : ''});
									})
								}
								if (fabric.fiberFabric.length>0) {
									fabric.fiberFabric.forEach(fibreF => {
										const refText = this.fiberList.filter(f => f.value===fibreF.fiberType.type).length > 0 ? this.fiberList.filter(f => f.value===fibreF.fiberType.type)[0].text : '';
										compositions.push({'composition': refText, 'fabricPercent': fibreF.fiberValue.value  !== -1 ? fibreF.fiberValue.value.toString() : ''});
									})
								}
								compositionsGroups.push(compositions);
							})
						}
					})
				}
			}
		}
		return compositionsGroups;
	}

	private get getProductTypes(): DropdownModel[] {
		return FootprintDropdownHelper.getProductType(this.garment.garmentDetails.productCategory.category);
	}

	private async mounted(): Promise<void> {
		this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
			if(collapseId === 'transport-0'){
				if(this.garmentTransport.length > 0){
					const transport = this.garmentTransport[0];
					if(transport !== undefined){
						transport.showTransport = isJustShown;
						this.garmentTransport.splice(0, 1);
						this.garmentTransport.splice(0, 0, transport);
					}
				}
			}
		});
	}

	private isFilledByPrimarySource(filledBy: string, toCompanyID: string): boolean {
		//Spint-22 TTD-4212
		if(this.tabIndex === 1){
			return true;
		}
		if(this.user && this.user.companyType === 1){
			if((filledBy !== Guid.Empty || filledBy !== '') && (filledBy === toCompanyID) && (toCompanyID !== this.userCompanyId)){
				return true;
			}
			return false;
		} else {
			if (filledBy === Guid.Empty || filledBy === '') {
				return false;
			} else if((filledBy !== Guid.Empty || filledBy !== '') && (filledBy === this.userCompanyId || this.userCompanyId === toCompanyID)) {
				return false;
			} else {
				return true;
			}
		}
		// return filledBy !== Guid.Empty && filledBy !== '' && filledBy === toCompanyID ? filledBy === this.userCompanyId ? false : true : false;
	}

	private getAgentPermissions(filledBy: string): boolean {
		return this.companyType === 5 ? FootprintPermissionsHelper.getAgentFootprintRole(this.companyType, filledBy, this.userCompanyId): false;
	}

	private canDeletePackaging(packaging: PackagingModel): boolean {
		const wrapper = this.stepForWrapper.filter(w => w.mcStepUniqueId === packaging.stepID);
		if(wrapper.length > 0){
			if(this.userCompanyId === wrapper[0].toCompanyId || packaging.filledByCompanyID === this.userCompanyId){
				return true;
			}
		}
		return false;
	}

	private canDeleteAccessories(accessories: AccessoriesModel): boolean {
		const wrapper = this.stepForWrapper.filter(w => w.mcStepUniqueId === accessories.stepID);
		if(wrapper.length > 0){
			if(this.userCompanyId === wrapper[0].toCompanyId || accessories.filledByCompanyID === this.userCompanyId){
				return true;
			}
		}
		return false;
	}

	private canDeletePostTreatment(postTreatment: PostTreatmentModel): boolean {
		const wrapper = this.stepForWrapper.filter(w => w.mcStepUniqueId === postTreatment.stepID);
		if(wrapper.length > 0){
			if(this.userCompanyId === wrapper[0].toCompanyId || postTreatment.filledByCompanyID === this.userCompanyId){
				return true;
			}
		}
		return false;
	}

	private canDeleteModeTrnsprt(mode: ModeOfTransportModel, wrapperStepID: string): boolean {
		const wrapper = this.stepForWrapper.filter(yw => yw.mcStepUniqueId === wrapperStepID);
		if(this.userCompanyId === wrapper[0].toCompanyId || (mode.distance.filledByCompanyID === this.userCompanyId && mode.distanceUnit.filledByCompanyID === this.userCompanyId && mode.transportType.filledByCompanyID === this.userCompanyId)){
			return true;
		}
		return false;
	}

	private isFiledByClient(filledBy: string): boolean {
		if (this.orders.length > 0 && filledBy === this.orders.filter(o => o.orderId === this.orderId)[0].fromCompanyUser.ID && this.tabIndex !==1) {
			return true;
		}
		return false;
	}

	private garmentFpStatus(): void {
		const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		if (order.garment.footprintStatus === FootPrintCompletedConstant.COMPLETED && this.garmentWrapper.stepID === order.garment.garmentDetails.stepID) {
			this.footprintStatus = 'green';
		}
		else if (order.garment.footprintStatus === FootPrintCompletedConstant.PARTIAL && this.garmentWrapper.stepID === order.garment.garmentDetails.stepID) {
			this.footprintStatus = 'orange';
		}
		else {
			this.footprintStatus = 'red';
		}
	}

	private garmentWrapperTpStatus(stepID: string): string {
		let status = 'red';
		let count = 0;
		let filledCount = 0;
		const relativeTrans = this.garmentTransport.filter(y => y.stepID === stepID);
		relativeTrans.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					count++;
				}
				if(mode.distance.value !== '' || mode.distanceUnit.unit !== '' || mode.transportType.value !== ''){
					filledCount++;
				}
			});
			const tpIndex = this.garmentTransport.findIndex(y => y.stepID === stepID && y.ID === tp.ID);
			if(tpIndex !== -1){
				if (count>0 && filledCount>0) {
					this.garmentTransport[tpIndex].status = FootPrintCompletedConstant.PARTIAL;
				} else if(count === 0) {
					this.garmentTransport[tpIndex].status = FootPrintCompletedConstant.COMPLETED;
				} else {
					this.garmentTransport[tpIndex].status = FootPrintCompletedConstant.INCOMPLETE;
				}
			}
		})
		if (count>0 && filledCount>0) {
			status = "orange";
			this.tpStatus = "orange";
		} else if(count === 0 && filledCount>0) {
			status = "green";
			this.tpStatus = "green";
		}
		return status;
	}

	private garmentTpStatus(): void {
		const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		if(order.garment.transportStatus === FootPrintCompletedConstant.COMPLETED){
			let tpEmptyField = false;
			this.garmentTransport.forEach(tp => {
				if(tp.toCompanyUser.toCompanyName === ''){
					tpEmptyField = true;
				}
				tp.modeOfTransport.forEach(mode => {
					if(mode.transportType.value === '' || mode.distance.value === '' || mode.distanceUnit.unit === ''){
						tpEmptyField = true;
					}
				});
			});
			if(tpEmptyField){
				this.transportStatus = 'orange';
			}
			else{
				this.transportStatus = 'green';
			}
		}
		else if(order.garment.transportStatus === FootPrintCompletedConstant.PARTIAL){
			this.transportStatus = 'orange';
		}
		else{
			this.transportStatus = 'red';
		}
	}

	private garmentWrapperFpStatus(stepID: string): string {
		let status = 'red';
		let count = 0;
		let filledCount = 0;
		const garment = this.garment;
		if(garment.garmentDetails.garmentTotalWeight.weight === ""){
			count++;
		}
		else{
			filledCount++
		}
		garment.garmentDetails.garmentFabric.forEach(fabric => {
			if(fabric.fabricPercent.percent === ""){
				count++;
			}
			else{
				filledCount++;
			}
		});
		if(garment.garmentDetails.total.totalcount === "" || garment.garmentDetails.total.totalcount === 0){
			count++;
		} else {
			filledCount++;
		}
		garment.cuttingWaste.forEach(cut => {
			if(cut.fabricWastePercent.percent === ""){
				count++;
			} else {
				filledCount++;
			}
		});
		garment.packaging.forEach(packaging => {
			if(packaging.weight.value === ""){
				count++;
			}
			else{
				filledCount++;
			}
			if(packaging.colliPerPackaging.number === ""){
				count++;
			} else {
				filledCount++;
			}
		});
		garment.postTreatment.forEach(treat => {
			if(treat.totalEnergyUsed.value !== ""){
				filledCount++;
			}
			else{
				count++;
			}
			if(treat.windEnergy.value !== ""){
				filledCount++;
			}
			else{
				count++;
			}
			if(treat.solarEnergy.value !== ""){
				filledCount++;
			}
			else{
				count++;
			}
			if(treat.thermalEnergyNew.length > 0){
				treat.thermalEnergyNew.forEach((te, teIndex) => {
					if(te.value !== "" && treat.thermalEnergySourceNew[teIndex].value!==''){
						filledCount++;
					}else{
						count++;
					}
				})
			}
			if(treat.waterUsedInFabric.amount!== ""){
				filledCount++;
			}
			else{
				count++;
			}
		});
		garment.accessories.forEach(acc => {
			if(acc.accessoriesPergarment.number !== ""){
				filledCount++;
			}
			else{
				count++;
			}
			if(acc.weight.value !== ""){
				filledCount++;
			}
			else{
				count++;
			}
			if(acc.accessoriesType.value === ""){
				count++;
			} else {
				filledCount++;
			}
			if(acc.matterial.value === ""){
				count++;
			} else {
				filledCount++;
			}
			if(acc.originCountry.country === ""){
				count++;
			} else {
				filledCount++;
			}
			if(acc.reference.referenceNo === ""){
				count++;
			} else {
				filledCount++;
			}
		});
		if (count>0 && filledCount>0) {
			status = "orange";
			this.fpStatus = "orange";
		} else if(count === 0 && filledCount>0) {
			status = "green";
			this.fpStatus = "green";
		}
		return status;
	}

	private defaultGarmtTransport(): void {
		if(this.garmentTransport.length === 0){
			const garmentTp: TransportFootprintModel = new TransportFootprintModel;
			garmentTp.ID = uuidv4();
			garmentTp.stepID = this.stepForWrapper[0].mcStepUniqueId;
			garmentTp.footprintID = this.garment.garmentDetails.ID;
			garmentTp.modeOfTransport.push(new ModeOfTransportModel());
			garmentTp.toCompanyUser.toCompanyID = this.garment.garmentDetails.toCompanyUser.toCompanyID;
			garmentTp.toCompanyUser.toCompanyName = this.garment.garmentDetails.toCompanyUser.toCompanyName;
			garmentTp.toCompanyUser.filledByCompanyID = this.garment.garmentDetails.toCompanyUser.filledByCompanyID;
			garmentTp.toCompanyUser.filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.filledByPrimarySource;
			garmentTp.filledByCompanyID = this.userCompanyId;
			garmentTp.filledByPrimarySource = this.userCompanyId === this.garment.garmentDetails.toCompanyUser.toCompanyID ? true : false;
			this.garmentTransport.push(garmentTp);
		}

		this.transportToBeDeleted = false;
		this.garmentTransport.forEach(transport => {
			transport.showTransport = false;
			transport.modeOfTransport.forEach(m => {
				m.distance.isValid = true;
				m.transportType.isValid = true;
				m.distanceUnit.isValid = true;
				if(m.distance.value === 0){
					m.distance.value = '';
				}
			});
		});
		this.garmentTransport[this.garmentTransport.length-1].showTransport = true;
		this.garmentTransportClone = lodash.cloneDeep(this.garmentTransport);
		this.garmentTransportCloneInitialState = lodash.cloneDeep(this.garmentTransport);
		this.showGarmentTransport = false;
		this.checkTransportEmptyFields();
		this.garmentTpStatus();
	}

	private async validateGarmentFootprint(event: any, fieldname: string, index: number, thermalIndex:number): Promise<void> {
		switch (fieldname) {
			// Added for TTD-3061 Sprint-19
			case "category": {
				event !== '' ? this.garment.garmentDetails.productCategory.isValid = true : this.garment.garmentDetails.productCategory.isValid = false;
				this.garment.garmentDetails.productCategory.isValid === false ? this.disableSave = true : this.disableSave = false;
				const selectCat = this.productCategory.filter(p => p.value === event)[0];
				this.garment.garmentDetails.productCategory.category = selectCat.value.toString();
				this.garment.garmentDetails.productCategory.filledByCompanyID = this.userCompanyId;
				this.garment.garmentDetails.productCategory.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				// Empty product type when new category is selected
				this.garment.garmentDetails.productType.type = "";
				this.garment.garmentDetails.productType.filledByCompanyID = "";
				this.garment.garmentDetails.productType.filledByPrimarySource = false;
				break;
			}
			// Added for TTD-3061 Sprint-19
			case "product-type": {
				event !== '' ? this.garment.garmentDetails.productType.isValid = true : this.garment.garmentDetails.productType.isValid = false;
				this.garment.garmentDetails.productType.isValid === false ? this.disableSave = true : this.disableSave = false;
				const selectType = this.getProductTypes.filter(p => p.value === event)[0];
				this.garment.garmentDetails.productType.type = selectType !== undefined ? selectType.value.toString() : "";
				this.garment.garmentDetails.productType.filledByCompanyID = this.userCompanyId;
				this.garment.garmentDetails.productType.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "garmt-weigth": {
				/* eslint-disable-next-line */
				(event.target.value.match(/^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/) && event.target.value.length <= 10) || event.target.value === '' ? this.garment.garmentDetails.garmentTotalWeight.isValid = true : this.garment.garmentDetails.garmentTotalWeight.isValid = false;
				this.garment.garmentDetails.garmentTotalWeight.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.garmentDetails.garmentTotalWeight.weight = event.target.value;
				this.garment.garmentDetails.garmentTotalWeight.filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.garmentDetails.garmentTotalWeight.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "fabric-percent": {
				event.target.value.match(/^([0-9]|[0-9][1-9]|[1-9][0-9]|100)$/) || event.target.value === '' ? this.garment.garmentDetails.garmentFabric[index].fabricPercent.isValid = true : this.garment.garmentDetails.garmentFabric[index].fabricPercent.isValid = false;
				this.garment.garmentDetails.garmentFabric[index].fabricPercent.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.garmentDetails.garmentFabric[index].fabricPercent.filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.garmentDetails.garmentFabric[index].fabricPercent.percent = event.target.value;
				let totalFabric = 0;
				this.garment.garmentDetails.garmentFabric.forEach(fabric => {
						totalFabric = totalFabric + (Number(fabric.fabricPercent.percent) ? Number(fabric.fabricPercent.percent) : 0);
				});
				this.garment.garmentDetails.total.totalcount = totalFabric;
				this.garment.garmentDetails.total.filledByCompanyID = this.userCompanyId;
				this.garment.garmentDetails.total.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				this.garment.garmentDetails.total.totalcount === 100 ? this.garment.garmentDetails.total.isValid = true : this.garment.garmentDetails.total.isValid = false;
				!this.garment.garmentDetails.total.isValid ? this.disableSave=true : this.disableSave=false;
				this.garment.garmentDetails.garmentFabric[index].fabricPercent.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "cutting-percent": {
				/* eslint-disable-next-line */
				event.target.value.match(/^([0-9]|[0-9][1-9]|[1-9][0-9]|100)$/) || event.target.value === '' ? this.garment.cuttingWaste[index].fabricWastePercent.isValid = true : this.garment.cuttingWaste[index].fabricWastePercent.isValid = false;
				this.garment.cuttingWaste[index].fabricWastePercent.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				if (this.garment.cuttingWaste[index].fabricWastePercent.isValid) {
					this.garment.cuttingWaste[index].fabricWastePercent.percent = event.target.value !== '' ? Number(event.target.value) : '';
				}
				this.garment.cuttingWaste[index].fabricWastePercent.filledByCompanyID = this.userCompanyId;
				this.garment.cuttingWaste[index].fabricWastePercent.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "cutting-recycle": {
				event !== '' ? this.garment.cuttingWaste[index].cuttingWasteRecycle.isValid = true : this.garment.cuttingWaste[index].cuttingWasteRecycle.isValid = false;
				this.garment.cuttingWaste[index].cuttingWasteRecycle.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.cuttingWaste[index].cuttingWasteRecycle.value = event;
				this.garment.cuttingWaste[index].cuttingWasteRecycle.filledByCompanyID = this.userCompanyId;
				this.garment.cuttingWaste[index].cuttingWasteRecycle.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "packaging-ref": {
				event.target.value.match(/^[a-zA-Z0-9- ]+(?:[\w -]*[a-zA-Z0-9- ]+)*$/) || event.target.value === '' ? this.garment.packaging[index].reference.isValid = true : this.garment.packaging[index].reference.isValid = false;
				this.garment.packaging[index].reference.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.packaging[index].reference.referenceNo = event.target.value;
				this.garment.packaging[index].reference.filledByCompanyID = this.userCompanyId;
				this.garment.packaging[index].reference.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "material": {
				/* eslint-disable-next-line */
				event !== '' ? this.garment.packaging[index].matterial.isValid = true : this.garment.packaging[index].reference.isValid = false;
				this.garment.packaging[index].matterial.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.packaging[index].matterial.value = event;
				this.garment.packaging[index].matterial.filledByCompanyID = this.userCompanyId;
				this.garment.packaging[index].matterial.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "weight": {
				(event.target.value.match(/^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/) && event.target.value.length <= 10) || event.target.value === '' ? this.garment.packaging[index].weight.isValid = true : this.garment.packaging[index].weight.isValid = false;
				this.garment.packaging[index].weight.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.packaging[index].weight.value = event.target.value;
				this.garment.packaging[index].weight.filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.packaging[index].weight.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "colli": {
				/* eslint-disable-next-line */
				(event.target.value.match(/^[0-9]*$/) && event.target.value.length <= 10) || event.target.value === '' ? this.garment.packaging[index].colliPerPackaging.isValid = true : this.garment.packaging[index].colliPerPackaging.isValid = false;
				this.garment.packaging[index].colliPerPackaging.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				if (this.garment.packaging[index].colliPerPackaging.isValid) {
					this.garment.packaging[index].colliPerPackaging.number = event.target.value !== '' ? Number(event.target.value) : '';
				}
				this.garment.packaging[index].colliPerPackaging.filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.packaging[index].colliPerPackaging.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "treatment-ref": {
				/* eslint-disable-next-line */
				event.target.value.match(/^[a-zA-Z0-9- ]+(?:[\w -]*[a-zA-Z0-9- ]+)*$/) || event.target.value === '' ? this.garment.postTreatment[index].reference.isValid = true : this.garment.postTreatment[index].reference.isValid = false;
				this.garment.postTreatment[index].reference.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.postTreatment[index].reference.referenceNo = event.target.value;
				this.garment.postTreatment[index].reference.filledByCompanyID = this.userCompanyId;
				this.garment.postTreatment[index].reference.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "treatment-type": {
				/* eslint-disable-next-line */
				event !== '' ? this.garment.postTreatment[index].treatmentType.isValid = true : this.garment.postTreatment[index].treatmentType.isValid = false;
				this.garment.postTreatment[index].treatmentType.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.postTreatment[index].treatmentType.value = event;
				this.garment.postTreatment[index].treatmentType.filledByCompanyID = this.userCompanyId;
				this.garment.postTreatment[index].treatmentType.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "treatment-water": {
				/* eslint-disable-next-line */
				(event.target.value.match(/^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/) && event.target.value.length <= 10) || event.target.value === '' ? this.garment.postTreatment[index].waterUsedInFabric.isValid = true : this.garment.postTreatment[index].waterUsedInFabric.isValid = false;
				this.garment.postTreatment[index].waterUsedInFabric.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				if (this.garment.postTreatment[index].waterUsedInFabric.isValid) {
					this.garment.postTreatment[index].waterUsedInFabric.amount = event.target.value;
				}
				this.garment.postTreatment[index].waterUsedInFabric.filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.postTreatment[index].waterUsedInFabric.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "energy-fabric": {
				/* eslint-disable-next-line */
				(event.target.value.match(/^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/) && event.target.value.length <= 10) || event.target.value === '' ? this.garment.postTreatment[index].totalEnergyUsed.isValid = true : this.garment.postTreatment[index].totalEnergyUsed.isValid = false;
				this.garment.postTreatment[index].totalEnergyUsed.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.postTreatment[index].totalEnergyUsed.value = event.target.value;
				this.garment.postTreatment[index].totalEnergyUsed.filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.postTreatment[index].totalEnergyUsed.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "wind-energy": {
				/* eslint-disable-next-line */
				event.target.value.match(/^([0-9]|[0-9][1-9]|[1-9][0-9]|100)$/) || event.target.value === '' ? this.garment.postTreatment[index].windEnergy.isValid = true : this.garment.postTreatment[index].windEnergy.isValid = false;
				this.garment.postTreatment[index].windEnergy.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.postTreatment[index].windEnergy.value = parseInt(event.target.value) === 0 || parseInt(event.target.value) ? parseInt(event.target.value) : "";
				this.garment.postTreatment[index].windEnergy.filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.postTreatment[index].windEnergy.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "solar-energy": {
				/* eslint-disable-next-line */
				event.target.value.match(/^([0-9]|[0-9][1-9]|[1-9][0-9]|100)$/) || event.target.value === '' ? this.garment.postTreatment[index].solarEnergy.isValid = true : this.garment.postTreatment[index].solarEnergy.isValid = false;
				this.garment.postTreatment[index].solarEnergy.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.postTreatment[index].solarEnergy.value = parseInt(event.target.value) === 0 || parseInt(event.target.value) ? parseInt(event.target.value) : "";
				this.garment.postTreatment[index].solarEnergy.filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.postTreatment[index].solarEnergy.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "thermal-energy": {
				/* eslint-disable-next-line */
				event.target.value.match(/^([0-9]|[0-9][1-9]|[1-9][0-9]|100)$/) || event.target.value === '' ? this.garment.postTreatment[index].thermalEnergyNew[thermalIndex].isValid = true : this.garment.postTreatment[index].thermalEnergyNew[thermalIndex].isValid = false;
				this.garment.postTreatment[index].thermalEnergyNew[thermalIndex].isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.postTreatment[index].thermalEnergyNew[thermalIndex].value = event.target.value !== '' && !isNaN(event.target.value) ? parseFloat(event.target.value) : '';
				this.garment.postTreatment[index].thermalEnergyNew[thermalIndex].filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.postTreatment[index].thermalEnergyNew[thermalIndex].filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "thermal-source": {
				/* eslint-disable-next-line */
				event !== '' ? this.garment.postTreatment[index].thermalEnergySourceNew[thermalIndex].isValid = true : this.garment.postTreatment[index].thermalEnergySourceNew[thermalIndex].isValid = false;
				this.garment.postTreatment[index].thermalEnergySourceNew[thermalIndex].isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.postTreatment[index].thermalEnergySourceNew[thermalIndex].value = event;
				this.garment.postTreatment[index].thermalEnergySourceNew[thermalIndex].filledByCompanyID = this.userCompanyId;
				this.garment.postTreatment[index].thermalEnergySourceNew[thermalIndex].filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "acc-ref": {
				/* eslint-disable-next-line */
				event.target.value.match(/^[a-zA-Z0-9- ]+(?:[\w -]*[a-zA-Z0-9- ]+)*$/) || event.target.value === '' ? this.garment.accessories[index].reference.isValid = true : this.garment.accessories[index].reference.isValid = false;
				this.garment.accessories[index].reference.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.accessories[index].reference.referenceNo = event.target.value;
				this.garment.accessories[index].reference.filledByCompanyID = this.userCompanyId;
				this.garment.accessories[index].reference.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "acc-type": {
				/* eslint-disable-next-line */
				event !== '' ? this.garment.accessories[index].accessoriesType.isValid = true : this.garment.postTreatment[index].treatmentType.isValid = false;
				this.garment.accessories[index].accessoriesType.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.accessories[index].accessoriesType.value = event;
				this.garment.accessories[index].accessoriesType.filledByCompanyID = this.userCompanyId;
				this.garment.accessories[index].accessoriesType.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "acc-material": {
				/* eslint-disable-next-line */
				 (event.target.value.match(/^[a-zA-Z ]+(?:[\w -]*[a-zA-Z ]+)*$/) && event.target.value !== '' && event.target.value.length > 2) || event.target.value === '' ? this.garment.accessories[index].matterial.isValid = true : this.garment.accessories[index].matterial.isValid = false;
				this.garment.accessories[index].matterial.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.accessories[index].matterial.value = event.target.value;
				this.garment.accessories[index].matterial.filledByCompanyID = this.userCompanyId;
				this.garment.accessories[index].matterial.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "acc-weight": {
				/* eslint-disable-next-line */
				(event.target.value.match(/^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/) && event.target.value.length <= 10) || event.target.value === '' ? this.garment.accessories[index].weight.isValid = true : this.garment.accessories[index].weight.isValid = false;
				this.garment.accessories[index].weight.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.accessories[index].weight.value = event.target.value;
				this.garment.accessories[index].weight.filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.accessories[index].weight.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "acc-no": {
				/* eslint-disable-next-line */
				event.target.value.match(/^[0-9]*$/) || event.target.value === '' ? this.garment.accessories[index].accessoriesPergarment.isValid = true : this.garment.accessories[index].accessoriesPergarment.isValid = false;
				this.garment.accessories[index].accessoriesPergarment.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.accessories[index].accessoriesPergarment.number = Number(event.target.value) ? Number(event.target.value) : "";
				this.garment.accessories[index].accessoriesPergarment.filledByCompanyID = event.target.value !=='' ? this.userCompanyId : Guid.Empty;
				this.garment.accessories[index].accessoriesPergarment.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			case "acc-origin": {
				/* eslint-disable-next-line */
				event !== '' ? this.garment.accessories[index].originCountry.isValid = true : this.garment.accessories[index].originCountry.isValid = false;
				this.garment.accessories[index].originCountry.isValid === false || this.garment.garmentDetails.total.isValid === false ? this.disableSave = true : this.disableSave = false;
				this.garment.accessories[index].originCountry.country = event;
				this.garment.accessories[index].originCountry.filledByCompanyID = this.userCompanyId;
				this.garment.accessories[index].originCountry.filledByPrimarySource = this.userCompanyId === this.stepForWrapper[0].toCompanyId ? true : false;
				break;
			}
			default:
				break;
		}
		this.compareGarmentFootprintClone();
		this.checkNonEmptyFields();
		if(this.disableSave || this.garment.garmentDetails.productCategory.category === "" || this.garment.garmentDetails.productType.type === ""){
			this.$store.commit('setIncorrectFootprintData', true);
		}else{
			this.$store.commit('setIncorrectFootprintData', false);
		}
	}

	private async validateGarmentFootprintRound(event: any, fieldname: string, index: number, thermalIndex:number): Promise<void> {
		switch (fieldname) {
			case 'garmt-weigth': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					rounded = Number(val).toFixed(2);
				}
				this.garment.garmentDetails.garmentTotalWeight.weight = rounded;
				break;
			}
			case 'weight': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					rounded = Number(val).toFixed(2);
				}
				this.garment.packaging[index].weight.value = rounded;
				break;
			}
			case 'treatment-water': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					rounded = Number(val).toFixed(2);
				}
				this.garment.postTreatment[index].waterUsedInFabric.amount = rounded;
				break;
			}
			case 'energy-fabric': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					rounded = Number(val).toFixed(2);
				}
				this.garment.postTreatment[index].totalEnergyUsed.value = rounded;
				break;
			}
			case 'thermal-energy': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					rounded = Number(val).toFixed(2);
				}
				this.garment.postTreatment[index].thermalEnergyNew[thermalIndex].value = rounded !== '' && !isNaN(rounded) ? parseFloat(rounded) : '';
				break;
			}
			case 'acc-weight': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					rounded = Number(val).toFixed(2);
				}
				this.garment.accessories[index].weight.value = rounded;
				break;
			}
			default:
				break;
		}
	}

	private compareGarmentFootprintClone() {
		let changes: number = 0;
		const garment: [string, any][] = Object.entries(this.garment);
		const garmentClone: [string, any][] = Object.entries(this.garmentFootprintClone);

		const getObjectDiff = (obj1: any, obj2: any) => {
		const obj1Props = Object.keys(obj1);
		const obj2Props = Object.keys(obj2);

		const keysWithDiffValue = obj1Props.reduce((keysWithDiffValueAccumulator, key) => {
			// eslint-disable-next-line no-prototype-builtins
			const propExistsOnObj2 = obj2.hasOwnProperty(key);
			const hasNestedValue = obj1[key] instanceof Object && obj2[key] instanceof Object;
			const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];

			if (!propExistsOnObj2) {
			keysWithDiffValueAccumulator.push(key);
			} else if (hasNestedValue) {
			const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
			if (keyIndex >= 0) {
				keysWithDiffValueAccumulator.splice(keyIndex, 1);
			}
			const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
			for (const diff of nestedDiffs) {
				keysWithDiffValueAccumulator.push(`${key}.${diff}`);
			}
			} else if (keyValuePairBetweenBothObjectsIsEqual) {
			const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
			keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
			}
			return keysWithDiffValueAccumulator;
		}, obj2Props);

		return keysWithDiffValue;
		};
		const differences = getObjectDiff(garment, garmentClone);
		changes += differences.length;
		differences.forEach(element => {
			if (element.includes('status') || element.includes('isValid') 
			|| element.includes('filledByCompanyID')
			|| element.includes('filledByPrimarySource') || element.includes('toCompanyName')
			|| element.includes('showGarment') || element.includes('showPackaging')
			|| element.includes('showPostTreatment') || element.includes('showAccessories') || element.includes('msdsDoc') || element.includes('energySupportingDocument')) {
				changes = changes-1;
			}
		});
		this.garmentFpChangedCounter = changes + this.FpPackDeletedCounter + this.FpAccDeletedCounter + this.FpPostDeletedCounter;
		changes > 0? this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA): '';
		changes > 0? this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.GARMENT_FP): '';
		this.checkNonEmptyFields();
	}

	private toggleGarmentFootprint(): void {
		this.showGarmtFootprint = !this.showGarmtFootprint;
		if (this.showGarmtFootprint) {
			this.showGarmentTransport = false;
		}
	}

	private toggleGarmentFootprintWrapper(): void {
		this.activeGarmentStep = Guid.Empty;
		this.garmentWrapper.showWrapper = !this.garmentWrapper.showWrapper;
	}

	private toggleGarmentTransport(): void {
		this.showGarmentTransport = !this.showGarmentTransport;
		if(this.showGarmentTransport){
			this.showGarmtFootprint = false;
		}
	}

	private toggleGarment(): void {
		this.showGarment = !this.showGarment;
	}

	private toggleCutting(): void {
		this.showCutting = !this.showCutting;
	}

	private addAccessories(): void {
		if (this.isSaving || this.userRole === 'Reader') {
			return;
		}
		if (this.garment.accessories.length<10) {
			this.garment.accessories.push(new AccessoriesModel());
			this.garment.accessories[this.garment.accessories.length - 1].showAccessories = true;
			this.garment.accessories[this.garment.accessories.length - 1].stepID = this.stepForWrapper[0].mcStepUniqueId;
			this.garment.accessories[this.garment.accessories.length - 1].filledByCompanyID = this.userCompanyId;
			this.garment.accessories[this.garment.accessories.length - 1].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
			this.garmentFootprintClone.accessories.push(new AccessoriesModel());
			this.garmentFootprintClone.accessories[this.garmentFootprintClone.accessories.length - 1].showAccessories = true;
			this.garmentFootprintClone.accessories[this.garmentFootprintClone.accessories.length - 1].stepID = this.stepForWrapper[0].mcStepUniqueId;
			this.garmentFootprintClone.accessories[this.garmentFootprintClone.accessories.length - 1].filledByCompanyID = this.userCompanyId;
			this.garmentFootprintClone.accessories[this.garmentFootprintClone.accessories.length - 1].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
			this.compareGarmentFootprintClone();
			this.checkNonEmptyFields();
		}
	}

	private addPostTreatment(): void {
		if (this.isSaving || this.userRole === 'Reader') {
			return;
		}
		const postTUuid = uuidv4();
		this.garment.postTreatment.push(new PostTreatmentModel());
		this.garment.postTreatment[this.garment.postTreatment.length - 1].ID = postTUuid;
		this.garment.postTreatment[this.garment.postTreatment.length - 1].stepID = this.stepForWrapper[0].mcStepUniqueId;
		this.garment.postTreatment[this.garment.postTreatment.length - 1].showPostTreatment = true;
		this.garment.postTreatment[this.garment.postTreatment.length - 1].filledByCompanyID = this.userCompanyId;
		this.garment.postTreatment[this.garment.postTreatment.length - 1].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
		this.garmentFootprintClone.postTreatment.push(new PostTreatmentModel());
		this.garmentFootprintClone.postTreatment[this.garmentFootprintClone.postTreatment.length - 1].ID = postTUuid;
		this.garmentFootprintClone.postTreatment[this.garmentFootprintClone.postTreatment.length - 1].stepID = this.stepForWrapper[0].mcStepUniqueId;
		this.garmentFootprintClone.postTreatment[this.garmentFootprintClone.postTreatment.length - 1].showPostTreatment = true;
		this.garmentFootprintClone.postTreatment[this.garmentFootprintClone.postTreatment.length - 1].filledByCompanyID = this.userCompanyId;
		this.garmentFootprintClone.postTreatment[this.garmentFootprintClone.postTreatment.length - 1].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
		this.compareGarmentFootprintClone();
		this.checkNonEmptyFields();
	}

	private addPackaging(): void {
		if (this.isSaving || this.userRole === 'Reader') {
			return;
		}
		this.garment.packaging.push(new PackagingModel());
		this.garment.packaging[this.garment.packaging.length - 1].showPackaging = true;
		this.garment.packaging[this.garment.packaging.length - 1].stepID = this.stepForWrapper[0].mcStepUniqueId;
		this.garment.packaging[this.garment.packaging.length - 1].filledByCompanyID = this.userCompanyId;
		this.garment.packaging[this.garment.packaging.length - 1].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
		this.garmentFootprintClone.packaging.push(new PackagingModel()); 
		this.garmentFootprintClone.packaging[this.garmentFootprintClone.packaging.length - 1].showPackaging = true;
		this.garmentFootprintClone.packaging[this.garmentFootprintClone.packaging.length - 1].stepID = this.stepForWrapper[0].mcStepUniqueId;
		this.garmentFootprintClone.packaging[this.garmentFootprintClone.packaging.length - 1].filledByCompanyID = this.userCompanyId;
		this.garmentFootprintClone.packaging[this.garmentFootprintClone.packaging.length - 1].filledByPrimarySource = this.garment.garmentDetails.toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
		this.compareGarmentFootprintClone();
		this.checkNonEmptyFields();
	}

	private removePackaging(index: number): void {
		if (this.isSaving || this.userRole === 'Reader') {
			return;
		}
		this.isTabRemoved = true;
		this.garment.packaging.splice(index, 1);
		this.garmentFootprintClone.packaging.splice(index, 1);
		this.FpPackDeletedCounter++;
		this.compareGarmentFootprintClone();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.GARMENT_FP);
	}

	private removeTreatment(index: number): void {
		if (this.isSaving || this.userRole === 'Reader') {
			return;
		}
		this.isTabRemoved = true;
		const beforeDelete = this.garment.postTreatment[index];
		this.garment.postTreatment.splice(index, 1);
		this.garmentFootprintClone.postTreatment.splice(index, 1);
		if (this.garmentFootprintCloneInitialState.postTreatment.filter(i => i.ID === beforeDelete.ID).length === 1) {
			this.FpPostDeletedCounter++;
		}
		this.compareGarmentFootprintClone();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.GARMENT_FP);
	}

	private removeAccessories(index: number): void {
		if (this.isSaving || this.userRole === 'Reader') {
			return;
		}
		this.isTabRemoved = true;
		this.garment.accessories.splice(index, 1);
		this.garmentFootprintClone.accessories.splice(index, 1);
		this.FpAccDeletedCounter++;
		this.compareGarmentFootprintClone();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.GARMENT_FP);
	}

	private openUploadDocModal(index: number): void {
		if(this.isSaving){
			return;
		}
		this.showUploadDocModal = true;
		this.treatmentIndex = index;
		this.isMsdsDoc = false;
		this.docFile = null;
	}

	private openUploadMsdsDocModal(index: number): void {
		this.showUploadDocModal = true;
		this.treatmentIndex = index;
		this.isMsdsDoc = true;
		this.docFile = null;
	}

	private closeUploadDocModal(): void {
		this.showUploadDocModal = false;
		this.isMsdsDoc = false;
		this.docFile = null;
	}

	private openPDFModal(doc: EnergySupportingDocumentModel): void {
		if(this.isSaving){
			return;
		}
		this.docPreview = doc;
		this.showPdfPreviewModal = true;
		this.showMsdsPdfPreviewModal = false;
	}

	private openMsdsPDFModal(doc: EnergySupportingDocumentModel): void {
		if (this.isSaving) {
			return;
		}
		this.docPreview = doc;
		this.showPdfPreviewModal = true;
		this.showMsdsPdfPreviewModal = true;
	}

	private closePdfModal(): void {
		this.showPdfPreviewModal = false;
		this.showMsdsPdfPreviewModal = false;
	}

	private async downloadPDF(): Promise<void> {
		if (this.isPdfReady) {
			this.pdfDocument.contentDispositionFilename = this.docPreview.fileName;
			this.pdfDocument.download();
		}
	}

	private async pdfReady(pdfApp: any): Promise<void> {
		this.isPdfReady = true;
		this.pdfDocument = pdfApp;
	}

	private showDeletePopup(treatmentIndex: number, docIndex: number, isMsds: boolean){
		if(this.isSaving){
			return;
		}
		this.showDeleteModal = true;
		this.treatmentDelIndex = treatmentIndex;
		this.treatmentDelDocIndex = docIndex;
		this.deleteMsds = isMsds;
	}

	private async deleteMsdsDoc(): Promise<void> {
		this.isDeletingDoc = true;
		const doc: DeleteDocRequestModel = {
			footprintID: this.garment.postTreatment[this.treatmentDelIndex].ID,
			footprintType: FootPrintConstant.MSDSDOCS_GARMENT_POST_TREATMENT,
			docID: this.garment.postTreatment[this.treatmentDelIndex].msdsDoc[this.treatmentDelDocIndex].ID
		};
		const result: FootprintDataResponseModel = await this.clientRequestFootPrintData.deleteMsdsDocs(this.orderId, doc);
		if(result.success){
			this.garment.postTreatment[this.treatmentDelIndex].msdsDoc = result.order.garment.postTreatment[this.treatmentDelIndex].msdsDoc;
			if(this.garmentFootprintCloneInitialState.postTreatment[this.treatmentDelIndex]){
				this.garmentFootprintCloneInitialState.postTreatment[this.treatmentDelIndex].msdsDoc = result.order.garment.postTreatment[this.treatmentDelIndex].msdsDoc;
			}
		}
		this.isDeletingDoc = false;
		this.showDeleteModal = false;
	}

	private async deleteSupDoc(): Promise<void> {
		this.isDeletingDoc = true;
		const doc: DeleteDocRequestModel = {
			footprintID: this.garment.postTreatment[this.treatmentDelIndex].ID,
			footprintType: FootPrintConstant.SUPPORTINGDOCS_GARMENT_POST_TREATMENT,
			docID: this.garment.postTreatment[this.treatmentDelIndex].energySupportingDocument[this.treatmentDelDocIndex].ID
		};
		const result: FootprintDataResponseModel = await this.clientRequestFootPrintData.deleteSupportDocs(this.orderId, doc);
		if(result.success){
			this.garment.postTreatment[this.treatmentDelIndex].energySupportingDocument = result.order.garment.postTreatment[this.treatmentDelIndex].energySupportingDocument;
			if(this.garmentFootprintCloneInitialState.postTreatment[this.treatmentDelIndex]){
				this.garmentFootprintCloneInitialState.postTreatment[this.treatmentDelIndex].energySupportingDocument = result.order.garment.postTreatment[this.treatmentDelIndex].energySupportingDocument;
			}
		}
		this.isDeletingDoc = false;
		this.showDeleteModal = false;
	}

	private async uploadSupportingDoc(): Promise<void> {
		this.isDocUploading = true;
		const docFormData = new FormData();
		docFormData.append("upload", this.docFile);
		docFormData.append("geoLong", '0');
		docFormData.append("geoLat", '0');
		docFormData.append("docID", uuidv4());
		docFormData.append("footprintID", this.garment.postTreatment[this.treatmentIndex].ID);
		docFormData.append("footprintType", FootPrintConstant.SUPPORTINGDOCS_GARMENT_POST_TREATMENT);
		docFormData.append("stepID", this.stepForWrapper[0].mcStepUniqueId);
		docFormData.append("filledByCompanyID", this.userCompanyId);
		docFormData.append("filledByPrimarySource", this.userCompanyId === this.garment.garmentDetails.toCompanyUser.toCompanyID ? "true" : "false");
		const result = await this.clientRequestFootPrintData.uploadSupportingDocsAsync(this.orderId, docFormData);
		if(result.success){
			this.garment.postTreatment[this.treatmentIndex].energySupportingDocument = result.order.garment.postTreatment[this.treatmentIndex].energySupportingDocument;
			if(this.garmentFootprintCloneInitialState.postTreatment[this.treatmentIndex]){
				this.garmentFootprintCloneInitialState.postTreatment[this.treatmentIndex].energySupportingDocument = result.order.garment.postTreatment[this.treatmentIndex].energySupportingDocument;
			}
			this.closeUploadDocModal();
		}
		this.isDocUploading = false;
	}

	private async uploadMsdsTreatmentDoc(): Promise<void> {
		this.isDocUploading = true;
		const docFormData = new FormData();
		docFormData.append("upload", this.docFile);
		docFormData.append("geoLong", '0');
		docFormData.append("geoLat", '0');
		docFormData.append("docID", uuidv4());
		docFormData.append("footprintID", this.garment.postTreatment[this.treatmentIndex].ID);
		docFormData.append("footprintType", FootPrintConstant.MSDSDOCS_GARMENT_POST_TREATMENT);
		docFormData.append("stepID", this.stepForWrapper[0].mcStepUniqueId);
		docFormData.append("filledByCompanyID", this.userCompanyId);
		docFormData.append("filledByPrimarySource", this.userCompanyId === this.garment.garmentDetails.toCompanyUser.toCompanyID ? "true" : "false");
		const result = await this.clientRequestFootPrintData.uploadWetMsdsDocsAsync(this.orderId, docFormData);
		if(result.success){
			this.garment.postTreatment[this.treatmentIndex].msdsDoc = result.order.garment.postTreatment[this.treatmentIndex].msdsDoc;
			if(this.garmentFootprintCloneInitialState.postTreatment[this.treatmentIndex]){
				this.garmentFootprintCloneInitialState.postTreatment[this.treatmentIndex].msdsDoc = result.order.garment.postTreatment[this.treatmentIndex].msdsDoc;
			}
			this.closeUploadDocModal();
		}
		this.isDocUploading = false;
	}

	private checkNonEmptyFields(): void {
		this.isGarmentFieldEmpty = false;
		const garmentFinalFp = lodash.cloneDeep(this.garment);
		if(garmentFinalFp.garmentDetails.toCompanyUser.toCompanyID === '' || garmentFinalFp.garmentDetails.garmentTotalWeight.weight === ''){
			this.isGarmentFieldEmpty = true;
		}
		// Commented for TTD-3061
		// garmentFinalFp.garmentDetails.garmentFabric.forEach(fabric => {
		// 	if(fabric.fabricReference.referenceNo === '' || fabric.fabricPercent.percent === ''){
		// 		this.isGarmentFieldEmpty = true;
		// 	}
		// });
		garmentFinalFp.cuttingWaste.forEach(cutting => {
			if(cutting.fabricWastePercent.percent === '' || cutting.cuttingWasteRecycle.value === ''){
				this.isGarmentFieldEmpty = true;
			}
		});
		garmentFinalFp.postTreatment.forEach(pt => {
			if(pt.reference.referenceNo === '' || pt.treatmentType.value === '' || pt.waterUsedInFabric.amount === '' || pt.totalEnergyUsed.value === '' || pt.windEnergy.value === '' || pt.solarEnergy.value === '' || pt.thermalEnergy.value === '' || pt.thermalEnergySource.value === ''){
				this.isGarmentFieldEmpty = true;
			}
		});
		garmentFinalFp.packaging.forEach(pack => {
			if(pack.reference.referenceNo === '' || pack.matterial.value === '' || pack.weight.value === '' || pack.colliPerPackaging.number === ''){
				this.isGarmentFieldEmpty = true;
			}
		});
		garmentFinalFp.accessories.forEach(acc => {
			if(acc.reference.referenceNo === '' || acc.accessoriesType.value === '' || acc.originCountry.country === '' || acc.matterial.value === '' || acc.weight.value === '' || acc.accessoriesPergarment.number === ''){
				this.isGarmentFieldEmpty = true;
			}
		});

	}

	private removeMode(transportID: string, modeIndex: number): void {
		this.garmentTransport.filter(t => t.ID === transportID)[0].modeOfTransport.splice(modeIndex, 1);
		this.compareGarmentTransportClone();
	}

	private checkTransportEmptyFields(): void {
		this.transportFieldEmpty = false;
		const transport = lodash.cloneDeep(this.garmentTransport);
		transport.forEach(tp => {
			if(tp.toCompanyUser.toCompanyID === Guid.Empty || tp.toCompanyUser.toCompanyID === ''){
				this.transportFieldEmpty = true;
			}
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					this.transportFieldEmpty = true;
				}
			});
		});
	}

	private checkIfValid(): boolean {
		if (this.garment.garmentDetails.productCategory.category === "") {
			this.garment.garmentDetails.productCategory.isValid = false;
			this.garment.garmentDetails.productType.isValid = false;
			this.disableSave = true;
		}
		if (this.garment.garmentDetails.productType.type === "") {
			this.garment.garmentDetails.productType.isValid = false;
			this.disableSave = true;
		}
		if (this.disableSave) {
			return false;
		}
		return true;
	}

	private beforeSave(): void {
		let showWarning: boolean = false;
		const showSaveWarning: boolean = false;
		if(!this.checkIfValid()) {
			return;
		}
		else {
			this.saveGarmtFootprintData();
		}
	}

	private async saveGarmtFootprintData(): Promise<void> {
		this.disableSave = false;
		let garmtFieldEmpty = "";
		const garment = lodash.cloneDeep(this.garment);
		if(garment.garmentDetails.garmentTotalWeight.weight === ""){
			garmtFieldEmpty = "weight";
			garment.garmentDetails.garmentTotalWeight.weight = -1;
		}
		else{
			garment.garmentDetails.garmentTotalWeight.weight = Number(garment.garmentDetails.garmentTotalWeight.weight);
		}
		garment.garmentDetails.garmentFabric.forEach(fabric => {
			if(fabric.fabricPercent.percent === ""){
				garmtFieldEmpty = "fabricPercent";
				fabric.fabricPercent.percent = -1;
			}
			else{
				fabric.fabricPercent.percent = Number(fabric.fabricPercent.percent);
			}
		});
		if(garment.garmentDetails.total.totalcount === ""){
			garmtFieldEmpty = "totalcount";
			garment.garmentDetails.total.totalcount = 0;
		}
		garment.cuttingWaste.forEach(cut => {
			if(cut.fabricWastePercent.percent === ""){
				garmtFieldEmpty = "percent";
				cut.fabricWastePercent.percent = -1;
			}
		});
		garment.packaging.forEach(packaging => {
			if(packaging.weight.value === ""){
				garmtFieldEmpty = "packageWeight";
				packaging.weight.value = -1;
			}
			else{
				packaging.weight.value = Number(packaging.weight.value);
			}
			if(packaging.colliPerPackaging.number === ""){
				garmtFieldEmpty = "packageColli";
				packaging.colliPerPackaging.number = -1;
			}
		});
		garment.postTreatment.forEach(treat => {
			if(treat.totalEnergyUsed.value !== ""){
				if(treat.totalEnergyUsed.value.toString().indexOf(".") !== -1){
					treat.totalEnergyUsed.value = parseFloat(treat.totalEnergyUsed.value.toString());
				}
				else{
					treat.totalEnergyUsed.value = parseInt(treat.totalEnergyUsed.value.toString());
				}
			}
			else{
				garmtFieldEmpty = "totalEnergyUsed";
				treat.totalEnergyUsed.value = -1;
			}
			if(treat.windEnergy.value !== ""){
				treat.windEnergy.value = Number(treat.windEnergy.value);
			}
			else{
				garmtFieldEmpty = "windEnergy";
				treat.windEnergy.value = -1;
			}
			if(treat.solarEnergy.value !== ""){
				treat.solarEnergy.value = Number(treat.solarEnergy.value);
			}
			else{
				garmtFieldEmpty = "solarEnergy";
				treat.solarEnergy.value = -1;
			}
			if(treat.thermalEnergy.value !== ""){
				treat.thermalEnergy.value = Number(treat.thermalEnergy.value);
			}
			else{
				// garmtFieldEmpty = "thermalEnergy";
				treat.thermalEnergy.value = -1;
			}
			if(treat.thermalEnergyNew.length > 0){
				treat.thermalEnergyNew.forEach((te, teIndex) => {
					if(te.value === ''){
						garmtFieldEmpty = "thermalEnergy";
						te.value = -1;
					}
					if(treat.thermalEnergySourceNew[teIndex].value === ''){
						garmtFieldEmpty = "thermalEnergySource";
					}
				})
			}
			if(treat.waterUsedInFabric.amount === ""){
				garmtFieldEmpty = "waterUsed";
			}
		});
		garment.accessories.forEach(acc => {
			if(acc.accessoriesPergarment.number !== ""){
				acc.accessoriesPergarment.number = Number(acc.accessoriesPergarment.number);
			}
			else{
				garmtFieldEmpty = "accpergram";
				acc.accessoriesPergarment.number = -1;
			}
			if(acc.weight.value !== ""){
				if(acc.weight.value.toString().indexOf(".") !== -1){
					acc.weight.value = parseFloat(acc.weight.value.toString());
				}
				else{
					acc.weight.value = parseInt(acc.weight.value.toString());
				}
			}
			else{
				garmtFieldEmpty = "accweight";
				acc.weight.value = -1;
			}
			if(acc.accessoriesType.value === ""){
				garmtFieldEmpty = "accType";
			}
			if(acc.matterial.value === ""){
				garmtFieldEmpty = "accmaterial";
			}
			if(acc.originCountry.country === ""){
				garmtFieldEmpty = "acccountry";
			}
			if(acc.reference.referenceNo === ""){
				garmtFieldEmpty = "accreference";
			}
		});
		this.isSaving = true;
		const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		garment.garmentDetails.toCompanyUser.filledByCompanyID = this.userCompanyId;
		garment.garmentDetails.toCompanyUser.toCompanyID = this.stepForWrapper[0].toCompanyId;
		garment.garmentDetails.toCompanyUser.toCompanyName = this.stepForWrapper[0].toCompanyName;
		const garmentData: GarmentFootprintRequestModel = {
			footprintDeadline: order.footprintDeadline !== (this.deadline !== "" ? new Date(this.deadline).toISOString() : "") ? new Date(this.deadline).toISOString(): order.footprintDeadline,
			garment: {
				footprintStatus: garmtFieldEmpty === "" ? FootPrintCompletedConstant.COMPLETED : FootPrintCompletedConstant.PARTIAL,
				garmentDetails: garment.garmentDetails,
				cuttingWaste: garment.cuttingWaste,
				packaging: garment.packaging,
				postTreatment: garment.postTreatment,
				accessories: garment.accessories
			}
		};
		const result = await this.clientRequestFootPrintData.saveGarmentFootprintDataAsync(this.orderId, garmentData);
		if(result.success){
			this.showGarmtFootprint = false;
			this.garment = lodash.cloneDeep(result.order.garment);
			// garment
			this.garment.garmentDetails.toCompanyUser.isValid = true;
			this.garment.garmentDetails.garmentTotalWeight.isValid = true;
			this.garment.garmentDetails.total.isValid = true;
			this.garment.garmentDetails.productCategory.isValid = true;
			this.garment.garmentDetails.productType.isValid = true;
			if(this.garment.garmentDetails.garmentTotalWeight.weight === -1){
				this.garment.garmentDetails.garmentTotalWeight.weight = '';
			}
			this.garment.garmentDetails.garmentFabric.forEach(fabric => {
				fabric.fabricReference.isValid = true;
				fabric.fabricPercent.isValid = true;
				if(fabric.fabricPercent.percent === -1){
					fabric.fabricPercent.percent = '';
				}
			});
			this.garment.cuttingWaste.forEach(cut => {
				cut.fabricWastePercent.isValid = true;
				cut.cuttingWasteRecycle.isValid = true;
				if(cut.fabricWastePercent.percent === -1){
					cut.fabricWastePercent.percent = '';
				}
			});
			this.garment.packaging.forEach(pack => {
				pack.reference.isValid = true;
				pack.colliPerPackaging.isValid = true;
				pack.matterial.isValid = true;
				pack.weight.isValid = true;
				if(pack.weight.value === -1){
					pack.weight.value = '';
				}
				if(pack.colliPerPackaging.number === -1){
					pack.colliPerPackaging.number = '';
				}
			});
			this.garment.postTreatment.forEach(post => {
				post.reference.isValid = true;
				post.solarEnergy.isValid = true;
				post.thermalEnergy.isValid = true;
				post.windEnergy.isValid = true;
				post.totalEnergyUsed.isValid = true;
				post.treatmentType.isValid = true;
				post.waterUsedInFabric.isValid = true;
				post.thermalEnergySource.isValid = true;
				if(post.totalEnergyUsed.value === -1){
					post.totalEnergyUsed.value = '';
				}
				if(post.windEnergy.value === -1){
					post.windEnergy.value = '';
				}
				if(post.solarEnergy.value === -1){
					post.solarEnergy.value = '';
				}
				if(post.thermalEnergy.value === -1){
					post.thermalEnergy.value = '';
				}
				if(post.thermalEnergyNew.length > 0){
					post.thermalEnergyNew.forEach((te) =>{
						if(te.value === -1){
							te.value = '';
						}
						te.isValid = true;
					});
					post.thermalEnergySourceNew.forEach((ts) => {
						ts.isValid = true;
					});
				}
			});
			this.garment.accessories.forEach(acc => {
				acc.accessoriesPergarment.isValid = true;
				acc.accessoriesType.isValid = true;
				acc.matterial.isValid = true;
				acc.originCountry.isValid = true;
				acc.reference.isValid = true;
				acc.weight.isValid = true;
				if(acc.weight.value === -1){
					acc.weight.value = '';
				}
				if(acc.accessoriesPergarment.number === -1){
					acc.accessoriesPergarment.number = '';
				}
			});
			// garment clone
			this.garmentFootprintClone = lodash.cloneDeep(this.garment);
			// garment clone initial
			this.garmentFootprintCloneInitialState = lodash.cloneDeep(this.garment);
			this.defaultGarmtTransport();
			this.garmentFpStatus();
			this.showGarmtFootprint = true;
			this.garmentFpChangedCounter = 0;
			this.FpPackDeletedCounter = 0;
			this.FpAccDeletedCounter = 0;
			this.FpPostDeletedCounter = 0;
		}
		this.$store.commit("setFootprintChanged", '');
		this.$store.commit("setChangedFootprintName", '');
		this.$store.commit('setIncorrectFootprintData', false);
		this.isSaving = false;
		this.showWarningModal = false;
	}

	// TTD-3061 Sprint19
	private handleFpPrevious(): void {
		this.showGarmtFootprint = false;
		this.navigateTab(2);
	}

	// TTD-3061 Sprint19
	private handleTpPrevious(): void {
		this.showGarmtFootprint = true;
		this.showGarmentTransport = false;
	}

	// TTD-3061 Sprint19
	private handleFpNext(): void {
		this.showGarmtFootprint = false;
		this.showGarmentTransport = true;
	}

	private clearGarmentFootprintData(): void {
		if (this.garmentFpChangedCounter>0 || this.isTabRemoved) {
			this.garment = lodash.cloneDeep(this.garmentFootprintCloneInitialState);
			this.garmentFootprintClone = lodash.cloneDeep(this.garmentFootprintCloneInitialState);
			this.defaultGarmtTransport();
			this.garmentFpChangedCounter = 0;
			this.FpPackDeletedCounter = 0;
			this.FpAccDeletedCounter = 0;
			this.FpPostDeletedCounter = 0;
			this.$store.commit("setFootprintChanged", '');
			this.$store.commit("setChangedFootprintName", '');
			this.$store.commit('setIncorrectFootprintData', false);
			this.isTabRemoved = false;
		}
	}

	private removeTransportFootprint(index: number): void {
		let flag: boolean = false;
		this.garmentTransport.splice(index, 1);
		this.garmentTransportClone.splice(index, 1);
		this.TpDeletedCounter++;
		this.compareGarmentTransportClone();
		this.transportToBeDeleted = flag;
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.GARMENT_TP);
	}

	private addModeTransport(index: number): void {
		if(this.isSaving || this.userRole === 'Reader'){
			return;
		}
		this.garmentTransport[index].modeOfTransport.push(new ModeOfTransportModel);
		this.garmentTransportClone[index].modeOfTransport.push(new ModeOfTransportModel);
		this.compareGarmentTransportClone();
		this.checkTransportEmptyFields();
	}

	private async validateGarmentTransport(event: any, fieldname: string, transportIndex: number, modeTransportIndex: number): Promise<void> {
        switch (fieldname) {
            case "transport-type": {
				event !== '' ? this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.isValid = true : this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.isValid = false;
				!this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.isValid ? this.disableSave=true : this.disableSave=false;
				this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.value = event;
				this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.filledByCompanyID = this.userCompanyId;
				this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].transportType.filledByPrimarySource = this.userCompanyId === this.garmentTransport[transportIndex].toCompanyUser.toCompanyID ? true : false;
                break;
			}
			case "distance": {
				/* eslint-disable-next-line */
				event.target.value.match(/^([0-9][0-9]{0,4})$/) || event.target.value === '' ? Number(event.target.value) <= 50000 ? this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.isValid = true : this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.isValid = false : this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.isValid = false;
				event.target.value !== "" && event.target.value.match(/^([0-9][0-9]{0,4})$/) === null ? this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.isValid = true : '';
				!this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.isValid ? this.disableSave=true : this.disableSave=false;
				this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.value = event.target.value !== "" && event.target.value.match(/^([0-9][0-9]{0,4})$/) !== null ? Number(event.target.value)? Number(event.target.value): "" : "";
				this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.filledByCompanyID = this.userCompanyId;
				this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distance.filledByPrimarySource = this.userCompanyId === this.garmentTransport[transportIndex].toCompanyUser.toCompanyID ? true : false;
                break;
			}
			case "distance-unit": {
				event !== '' ? this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.isValid = true : this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.isValid = false;
				!this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.isValid ? this.disableSave=true : this.disableSave=false;
				this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.unit = event;
				this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.filledByCompanyID = this.userCompanyId;
				this.garmentTransport[transportIndex].modeOfTransport[modeTransportIndex].distanceUnit.filledByPrimarySource = this.userCompanyId === this.garmentTransport[transportIndex].toCompanyUser.toCompanyID ? true : false;
                break;
			}
			default:
                break;
        }
		this.compareGarmentTransportClone();
    }

	private compareGarmentTransportClone() {
		let changes: number = 0;
		const garmentTransport: [string, any][] = Object.entries(this.garmentTransport);
		const garmentTransportClone: [string, any][] = Object.entries(this.garmentTransportClone);

		const getObjectDiff = (obj1: any, obj2: any) => {
		const obj1Props = Object.keys(obj1);
		const obj2Props = Object.keys(obj2);

		const keysWithDiffValue = obj1Props.reduce((keysWithDiffValueAccumulator, key) => {
			// eslint-disable-next-line no-prototype-builtins
			const propExistsOnObj2 = obj2.hasOwnProperty(key);
			const hasNestedValue = obj1[key] instanceof Object && obj2[key] instanceof Object;
			const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];

			if (!propExistsOnObj2) {
			keysWithDiffValueAccumulator.push(key);
			} else if (hasNestedValue) {
			const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
			if (keyIndex >= 0) {
				keysWithDiffValueAccumulator.splice(keyIndex, 1);
			}
			const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
			for (const diff of nestedDiffs) {
				keysWithDiffValueAccumulator.push(`${key}.${diff}`);
			}
			} else if (keyValuePairBetweenBothObjectsIsEqual) {
			const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
			keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
			}
			return keysWithDiffValueAccumulator;
		}, obj2Props);

		return keysWithDiffValue;
		};
		const differences = getObjectDiff(garmentTransport, garmentTransportClone);

		changes += differences.length;
		differences.forEach(element => {
			if (element.includes('status') || element.includes('isValid') || element.includes('filledByCompanyID') || element.includes('showTransport') || element.includes('filledByPrimarySource')) {
				changes = changes-1;
			}
		});
		this.garmentTpChangedCounter = changes + this.TpDeletedCounter;
		changes > 0?this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA): '';
		changes > 0?this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.GARMENT_TP): '';
		this.checkTransportEmptyFields();
	}

	private async saveGarmentTransportData(): Promise<void> {
		this.isSaving = true;
		let transportFieldEmpty = "";
		const garmentTp = lodash.cloneDeep(this.garmentTransport);
		garmentTp.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.transportType.value === ""){
					transportFieldEmpty = "transportType,";
				}
				if(mode.distance.value === ""){
					mode.distance.value = -1;
					transportFieldEmpty = "distance,";
				}
				if(mode.distanceUnit.unit === ""){
					transportFieldEmpty = "unit,";
				}
			});
		});
		const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		const garmentTransport: TransportFootprintRequestModel = {
			footprintDeadline: order.footprintDeadline !== (this.deadline !== "" ? new Date(this.deadline).toISOString() : "") ? new Date(this.deadline).toISOString(): order.footprintDeadline,
			data: {
				transportType: FootPrintConstant.GARMENT,
				transportStatus: transportFieldEmpty === "" ? FootPrintCompletedConstant.COMPLETED : FootPrintCompletedConstant.PARTIAL,
				transport: garmentTp
			}
		};
		const result = await this.clientRequestFootPrintData.saveYarnTransportAsync(this.orderId, garmentTransport);
		if(result.success){
			this.garmentTpStatus();
			this.garmentTransport = lodash.cloneDeep(result.order.garment.transport);
			this.garmentTransport.forEach(tp => {
				tp.showTransport = garmentTp.filter(g => tp.ID === g.ID && g.showTransport===true).length>0 ? garmentTp.filter(g => tp.ID === g.ID && g.showTransport===true)[0].showTransport : false;
				tp.toCompanyUser.isValid = true;
				tp.modeOfTransport.forEach(mode => {
					mode.distance.isValid = true;
					if(mode.distance.value === -1){
						mode.distance.value = '';
					}
					mode.distanceUnit.isValid = true;
					mode.transportType.isValid = true;
				});
			});
			this.garmentTransportClone = lodash.cloneDeep(this.garmentTransport);
			this.garmentTransportCloneInitialState = lodash.cloneDeep(this.garmentTransport);
			this.garmentTpChangedCounter  = 0;
			this.TpDeletedCounter = 0;
		}
		this.$store.commit("setFootprintChanged", '');
		this.$store.commit("setChangedFootprintName", '');
		this.$store.commit('setIncorrectFootprintData', false);
		this.isSaving = false;
	}

	private clearGarmtTransportData() {
		if (this.garmentTpChangedCounter > 0) {
			this.garmentTransport = lodash.cloneDeep(this.garmentTransportCloneInitialState);
			this.garmentTransportClone = lodash.cloneDeep(this.garmentTransportCloneInitialState);
			this.garmentTpChangedCounter = 0;
			this.TpDeletedCounter = 0;
			this.$store.commit("setFootprintChanged", '');
			this.$store.commit("setChangedFootprintName", '');
			this.$store.commit('setIncorrectFootprintData', false);
		}
		this.compareGarmentTransportClone();
	}

	private get selectedCard(): string {
		if(this.garmentWrapper.stepID === this.activeGarmentStep){
			this.garmentWrapper.showWrapper = true;
			this.showGarment = true;
		}
		return this.activeGarmentStep;
	}

	private addSource(index:number){
		this.garment.postTreatment[index].thermalEnergyNew.push(new ThermalEnergyModel());
		this.garment.postTreatment[index].thermalEnergySourceNew.push(new ThermalEnergyModel());
	}

	@Emit()
	private navigateTab(index: number): void {}

	@Watch('activeStep')
    private openCardForSelected(){
		this.activeGarmentStep = this.activeStep;
    }
}