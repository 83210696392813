var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top-supplychain-partner"},[_c('div',{staticClass:"supplychain-title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("pages.home.top_supplychain_partner")))]),_c('div',{staticClass:"supplier-filter"},[_c('div',{staticClass:"productGroup-align",on:{"click":_vm.openProductGroupDropdown}},[_c('div',{staticClass:"filter"},[_vm._v(_vm._s(_vm.productGroupName))]),_c('div',{staticClass:"arrow"})]),_c('div',{staticClass:"supplier-filter-list"},[(_vm.showProductGroup)?_c('Drop-down',{attrs:{"items":_vm.productGroupList,"search-placeholder":_vm.$t('pages.home.search_placeholder')},on:{"value-selected":_vm.valueSelected,"close-drop-down":_vm.closeDropDown}}):_vm._e()],1)])]),_c('div',{staticClass:"content"},[(_vm.isLoading)?_c('div',{staticClass:"supplier-header"},[_c('div',{staticClass:"row margin"},[_c('div',{staticClass:"col-10"},[_c('b-skeleton',{attrs:{"width":"20%"}})],1),_c('div',{staticClass:"col-2"},[_c('b-skeleton')],1)]),_vm._l((6),function(index){return _c('div',{key:index,staticClass:"row margin"},[_c('div',{staticClass:"col-10"},[_c('b-skeleton',{attrs:{"width":"40%"}})],1),_c('div',{staticClass:"col-2"},[_c('b-skeleton',{staticClass:"float-right",attrs:{"width":"50%"}})],1)])})],2):(_vm.topSuppliers.length === 0 & !_vm.isLoading)?_c('div',{staticClass:"supplier-header"},[_c('h3',{staticClass:"col-12 float-left margin"},[_vm._v(_vm._s(_vm.$t("pages.home.no_data")))])]):_c('div',[_c('div',{staticClass:"contentTitle"},[_c('h3',[_vm._v(_vm._s(_vm.$t("pages.home.name")))]),_c('h3',[_vm._v(_vm._s(_vm.$t("pages.home.style_processed")))])]),_vm._l((_vm.topSuppliers),function(supplier,index){return _c('div',{key:index,staticClass:"body contentBody"},[_c('div',[_vm._v(_vm._s(supplier.companyName))]),_c('div',{staticClass:"d-flex"},[_c('div',{class:[
            'col-1',
            {
              'top-1': index === 0,
              'top-2': index === 1,
              'top-3': index === 2,
            },
          ]}),_c('div',{staticClass:"col-1 value"},[_vm._v(_vm._s(supplier.styleProcessed))])])])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }