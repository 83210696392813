import { Vue, Component, Prop } from "vue-property-decorator";

// Components
import StepsLoader from '@/views/orderLines/components/stepsLoader.vue';

@Component({
    components: { StepsLoader }
})
export default class OrderLineLoader extends Vue {
@Prop()
private isSetting!: boolean;
}