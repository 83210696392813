import { Guid } from "./guid";
import { OrderLineDisplayModel } from "./orderLineDisplayModel";
import { TransportFootprintModel} from "./transportFootprintModel";

export class YarnFootprintRequestModel {
    public footprintDeadline!: string;
    public yarn!: YarnModel;
}

export class YarnDataModel {
    public footprint!: YarnFootprintModel[];
    public footprintStatus!: string;
    public transport!: TransportFootprintModel[];
    public transportStatus!: string;
}

export class YarnModel {
    public constructor(){
        this.footprint = [];
        this.footprintStatus = "";
    }
    public footprint!: YarnFootprintModel[];
    public footprintStatus!: string;
}

export class YarnStepsDataModel {
    public constructor(){
        this.stepID = "";
        this.supplierName = "";
        this.referenceNo = "";
        this.referenceNoPrefix = "";
        this.showWrapper = false;
        this.supplierCompanyId = Guid.Empty;
    }
    public stepID!: string;
    public supplierName!: string;
    public referenceNo!: string;
    public referenceNoPrefix?: string;
    public showWrapper!: boolean;
    public supplierCompanyId!: string;
}

export class YarnFootprintModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.toCompanyUser = {
            toCompanyID: Guid.Empty,
            toCompanyName: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true,
            isUnique: true
        };
        this.spinning = {
            spinningMethod: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.yarnCount = {
            count: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.yarnUnit = {
            unit: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.fiber = [
            {
                ID: Guid.Empty,
                fiberType: {
                    type: "",
                    filledByCompanyID: Guid.Empty,
                    filledByPrimarySource: false,
                    isValid: true
                },
                originOfFiber: {
                    value: "",
                    filledByCompanyID: Guid.Empty,
                    filledByPrimarySource: false,
                    isValid: true
                },
                fiberValue: {
                    value: "",
                    filledByCompanyID: Guid.Empty,
                    filledByPrimarySource: false,
                    isValid: true
                },
                filledByCompanyID: Guid.Empty,
                filledByPrimarySource: false
            }
        ];
        this.provideEnergyFlag = true;
        this.total = {
            totalcount: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.totalElectricEnergy = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.windEnergy = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.solarEnergy = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.energySupportingDocument = [];
        this.showYarn = false;
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
        this.stepID = "";
        this.status = "";
        this.sequence = 0;
    }
    public ID!: string;
    public toCompanyUser!: ToCompanyUserModel;
    public reference!: ReferenceModel;
    public spinning!: SpinningModel;
    public yarnCount!: YarnCountModel;
    public yarnUnit!: YarnUnitModel;
    public fiber!: FiberModel[];
    public total!: TotalModel;
    public provideEnergyFlag!: boolean;
    public totalElectricEnergy!: TotalElectricEnergyModel;
    public windEnergy!: windEnergyModel;
    public solarEnergy!: SolarEnergyModel;
    public energySupportingDocument!: EnergySupportingDocumentModel[];
    public showYarn!: boolean;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public stepID!: string;
    public status!: string;
    public sequence!: number;
}

export class ToCompanyUserModel{
    public constructor(){
        this.toCompanyID = Guid.Empty;
        this.toCompanyName = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public toCompanyID!: string;
    public toCompanyName!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class ReferenceModel{
    public constructor(){
        this.referenceNo = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
        this.isUnique = true;
    }
    public referenceNo!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
    public isUnique!: boolean;
}

export class SpinningModel{
    public constructor(){
        this.spinningMethod = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public spinningMethod!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class YarnCountModel{
    public constructor(){
        this.count = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public count!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class YarnUnitModel{
    public constructor(){
        this.unit = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public unit!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class FiberModel{
    public constructor(){
        this.ID = Guid.Empty;
        this.fiberType = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.originOfFiber = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.fiberValue = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
    }
    public ID!: string;
    public fiberType!: FiberTypeModel;
    public originOfFiber!: OriginOfFiber;
    public fiberValue!: FiberValueModel;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

export class FiberTypeModel{
    public constructor(){
        this.type = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public type!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class OriginOfFiber{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public value!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class FiberValueModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public value!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class TotalModel{
    public constructor(){
        this.totalcount = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public totalcount!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class TotalElectricEnergyModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public value!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class windEnergyModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public value!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class SolarEnergyModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public value!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class EnergySupportingDocumentModel{
    public ID!: string;
    public createdAt!: string;
    public createdBy!: string;
    public docURL!: string;
    public fileHash!: string;
    public fileName!: string;
    public fileType!: string;
    public geoLat!: number;
    public geoLong!: number;
    public referenceNo!: string;
    public rejectionReason!: string;
    public status!: number;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

export class YarnFootprintResponseModel {
    public order!: OrderLineDisplayModel;
    public success!: boolean;
}

export class WetProcessFootprintResponseModel {
    public order!: OrderLineDisplayModel;
    public success!: boolean;
}