import { ProductPictureDisplayModel } from "./productPictureDisplayModel";

export class OrderLineModel {
    public id!: string;
    public orderId?: string;
    public orderLineId?: string;
    public orderNumber?: string;
    public companyName!: string;
    public brandName!: string;
    public productGroup!: string;
    public styleNumber!: string;
    public styleName!: string;
    public colourway?: string;
    public quantity!: number;
    public compositionMainFabric!: string;
    public compositionSecondFabric?: string;
    public expectedTimeOfDelivery!: string;
    public deliveryTerms!: string;
    public certificate?: string;
    public productPictures?: ProductPictureDisplayModel[];
    public fromCompanyId!: string;
    public fromCompanyName!: string;
    public toCompanyId!: string;
    public toCompanyName!: string;
    public season!: string;
    public repeat!: string;
}