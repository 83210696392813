import i18n from '@/i18n';
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { ActionTypes } from '@/store/actions/actions';
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import store from '@/store';
import lodash from 'lodash';
import { mapGetters } from 'vuex';
import _ from 'underscore';
import { debounce } from "ts-debounce";

// Components
import Stencil from "@/views/profile/components/stencil.vue";
import ProfileZoom from "@/views/profile/components/profileZoom.vue";

// Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';

// Models
import { UserModel, UserModelRequest } from '@/models/userModel';
import { ContactModel } from '@/models/contactModel';
import { CompanyModel } from '@/models/companyModel';

// Services
import { InvitationService } from '@/services/invitationService';
import { ClientService } from '@/services/clientService';
import { UserService } from '@/services/userService';

@Component({
  components: { Cropper, Stencil, ProfileZoom },
  computed: {
    ...mapGetters(['userRole']),
  }
})
export default class MyDetails extends Vue {

  private isLoading: boolean = false;

  private user: ContactModel = new ContactModel();

  private userClone: ContactModel = new ContactModel();

  private company: CompanyModel = new CompanyModel();

  private userService: UserService;

  private clientService: ClientService;

  private invitationService: InvitationService;

  private isValid: boolean = true;

  private invitationId: string | null = '';

  private fileSrc: string = '';

  private croppedimage: string = '';

  private showProfileModal: boolean = false;

  private longitude: number = 0;

  private latitude: number = 0;

  private isProfileSaving: boolean = false;

  private showDeleteModal: boolean = false;

  private isProfileDeleting: boolean = false;

  private userProfile: string = '';

  private zoom: number = 0;

  private minW: number = 300;

  private minH: number = 300;

  private isSaving: boolean = false;

  private changedSettingsCounter: number = 0;

  private profilePictureName: string = '';

  private userRole!: string;
  private checkFieldsDebounced: any;
  private firstNameError: boolean = false;
  private lastNameError: boolean = false;
  private fNameSpecCharErr: boolean = false;

  public constructor() {
    super();
    this.userService = new UserService();
    this.clientService = new ClientService();
    this.invitationService = new InvitationService();
  }

  private async created(): Promise<void> {
    try {
      this.isLoading = true;
      this.invitationId = localStorage.getItem('invitationId');
      this.checkFieldsDebounced = debounce(this.validateField, 250, { maxWait: 250, isImmediate: true });
      if (this.invitationId) {
        if (store.getters.company != null) {
          this.company = store.getters.company;
          this.setUserInformation();
        } else {
          this.company =
            await this.invitationService.getClientByInvitationIdAsync(
              this.invitationId
            );
          this.setUserInformation();
        }
        store.commit("setCompanyType", this.company.companyType);
      } else {
        if (store.getters.company != null) {
          this.company = store.getters.company;
          this.setUserInformation();
        } else {
          this.company = await this.clientService.getClientAsync();
          this.setUserInformation();
        }
      }
      store.commit('setMyAccount', this.company);
      this.userClone = lodash.cloneDeep(this.user);
    } catch (e) {
      // empty
    } finally {
      this.isLoading = false;
      this.isFormValid(true);
    }
  }

  private async validateField(event: any, fieldname: string): Promise<void> {
    var specials=/[@()[\];:<>, ]/;
    switch (fieldname) {
      case "firstName":
        if (event.length < 2) {
            this.firstNameError = true;
        } else {
            this.firstNameError = false;
        }
        // TTD-4477, for adding validations on firstname
        if (specials.test(event) && !(event.length < 2)) {
            this.fNameSpecCharErr = true;
        } else {
            this.fNameSpecCharErr = false;
        }
      break;

      case "lastName":
        if (event.length < 2) {
            this.lastNameError = true;
          } else {
            this.lastNameError = false;
          }
      break;

      default:
        break;
    }
  }

  // TTD-4477, for adding validations on firstname
  private get relatedValidations(): boolean {
    return !this.isValid || this.firstNameError || this.fNameSpecCharErr || this.lastNameError;
  }

  private takePicture(): void {
    if (this.invitationId) {
      return;
    }
    const inputFile = <HTMLDivElement>document.querySelector('.camera-file');
    inputFile.click();
  }

  private uploadProfileFromGallery(event: any): void {
    const app = this;
    const maxHeight: number = 480;
    const imgObject = new Image();
    for (let index = 0; index < event.target.files.length; index++) {
      this.profilePictureName = event.target.files[index].name;
      const selectedFile = event.target.files[index];
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (function (oldFile: any, app: any) {
        return (): void => {
          app.fileSrc = reader.result as string;
          app.showProfileModal = true;
        };
      })(selectedFile, app);
      reader.onerror = (): void => {
        NotificationHelper.createErrorNotification(
          i18n.t('errors.image_invalid').toString()
        );
      };
    }
  }

  private getCropImage(coordinates: any, canvas: any): void {
    this.croppedimage = coordinates.canvas.toDataURL();
  }

  private async saveProfile(): Promise<void> {
    this.isProfileSaving = true;
    const file = this.dataURItoFile(this.profilePictureName, this.croppedimage);
    const formData = new FormData();
    formData.append('geoLat', '0');
    formData.append('geoLong', '0');
    formData.append('upload', file);
    const user: any = this.$store.getters.user;
    const response = false;
    if (this.userProfile) {
      formData.append('createdAt', user.userProfilePics[user.userProfilePics.length-1].createdAt);
      formData.append('createdBy', user.userProfilePics[user.userProfilePics.length-1].createdBy);
      const response = await this.clientService.updateProfilePictureAsync(
        user.userId,
        formData
      );
      if (response) {
        const info = await this.userService.getUserInfoByIdAsync(
          user.userId
        );
        const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}');
        localStorageUser.userProfilePics = info.userProfilePics;
        localStorageUser.companyType = this.company.companyType;
        localStorage.setItem('user', JSON.stringify(localStorageUser));
        this.$store.commit('setUser', info);
        this.profilePictureName = '';
        this.showProfileModal = false;
      }
    } else {
      const response = await this.clientService.uploadProfilePictureAsync(
        user.userId,
        formData
      );
      if (response) {
        const info = await this.userService.getUserInfoByIdAsync(
          user.userId
        );
        const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}');
        localStorageUser.userProfilePics = info.userProfilePics;
        localStorage.setItem('user', JSON.stringify(localStorageUser));
        this.$store.commit('setUser', info);
        this.profilePictureName = '';
        this.showProfileModal = false;
      }
    }
    this.isProfileSaving = false;
  }

  private showDelete(): void {
    this.showDeleteModal = true;
  }

  private closeDeleteModal(): void {
    this.showDeleteModal = false;
  }

  private get brands(): string {
    let brands = '';
    const user = this.$store.getters.user as UserModel;
    if (user && user.topBrands && user.topBrands.length > 0) {
      brands = _.pluck(user.topBrands, 'brandName').join(', ');
    }
    else{
      if(this.userRole === "Admin" && user && user.userType === "COMPANY_ADMIN"){
        brands = _.pluck(this.company.topBrands, 'brandName').join(', ');
      }
    }
    return brands;
  }

  private async deletePicture(): Promise<void> {
    this.isProfileDeleting = true;
    const user: any = this.$store.getters.user;
    const profileId = user.userProfilePics[user.userProfilePics.length - 1].ID;
    const response = await this.clientService.deleteProfilePictureAsync(
      user.userId,
      profileId
    );
    const info = await this.userService.getUserInfoByIdAsync(
      user.userId
    );
    const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}');
    localStorageUser.userProfilePics = info.userProfilePics;
    localStorage.setItem('user', JSON.stringify(localStorageUser));
    this.$store.commit('setUser', info);
    this.userProfile = "";
    this.isProfileDeleting = true;
    this.showDeleteModal = false;
  }

  private get profileUploaded(): boolean {
    const user = this.$store.getters.user as UserModel;
    if (user && user.userProfilePics && user.userProfilePics.length > 0) {
      this.userProfile = user.userProfilePics[user.userProfilePics.length - 1].docURL;
      return true;
    } else {
      return false;
    }
  }

  private get isAgent(): boolean {
    if(this.company.companyType === 5){
      return true;
    }
    else{
      return false;
    }
  }

  private dataURItoFile(filename: string, dataURI: string): File {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const fileBlob = new Blob([ab], { type: mimeString });
    return new File([fileBlob], filename, { type: mimeString });
  }

  private closeProfileModal(): void {
    this.showProfileModal = false;
    this.croppedimage = "";
  }

  private setUserInformation(): void {
    const user = this.$store.getters.user as UserModel;
    this.user.firstName = user.firstName;
    this.user.lastName = user.lastName;
    this.user.email = user.email;
    user.phone = user.phone === undefined || user.phone === null ? '' : user.phone;
    this.user.phone = this.invitationId ? user.phone : user.phone === '' ? this.company.admin.phone : user.phone;
    if (this.user.phone === '' || this.user.phone === undefined) {
      this.isValid = false;
    }
    this.company.admin = this.user;
  }

  private cancelChanges(): void {
    this.user = this.userClone;
    this.updateStore(true);
  }

  private updateStore(valid: boolean): void {
    if (this.user.phone !== '' && this.user.phone.length >= 10) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
    this.company.admin = this.user;
    store.commit('setMyAccount', this.company);
    this.compareUserClone();
    this.isFormValid(valid);
  }

  private async next(): Promise<void> {
    this.nextTab();
  }

  private compareUserClone() {
    if(this.invitationId === '' || this.invitationId === null || this.invitationId === undefined){
      let changes: number = 0;
      const user: [string, any][] = Object.entries(this.user);
      const userClone: [string, any][] = Object.entries(this.userClone);

      const getObjectDiff = (obj1: any, obj2: any) => {
        const obj1Props = Object.keys(obj1);
        const obj2Props = Object.keys(obj2);

        const keysWithDiffValue = obj1Props.reduce((keysWithDiffValueAccumulator, key) => {
          // eslint-disable-next-line no-prototype-builtins
          const propExistsOnObj2 = obj2.hasOwnProperty(key);
          const hasNestedValue = obj1[key] instanceof Object && obj2[key] instanceof Object;
          const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];

          if (!propExistsOnObj2) {
            keysWithDiffValueAccumulator.push(key);
          } else if (hasNestedValue) {
            const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
            if (keyIndex >= 0) {
              keysWithDiffValueAccumulator.splice(keyIndex, 1);
            }
            const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
            for (const diff of nestedDiffs) {
              keysWithDiffValueAccumulator.push(`${key}.${diff}`);
            }
          } else if (keyValuePairBetweenBothObjectsIsEqual) {
            const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
            keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
          }
          return keysWithDiffValueAccumulator;
        }, obj2Props);

        return keysWithDiffValue;
      };
      changes += getObjectDiff(user, userClone).length;
      this.changedSettingsCounter = changes;
      this.changedSettings(changes);
    }
  }

  private onZoom(value: number, asc: boolean) {
    this.minW = 130;
    this.minH = 130;
    const cropper: any = this.$refs.cropper;
    if (cropper) {
      if (!asc) {
        if (value>=1.9) {
          cropper.zoom(.9);
        } else if(value>=1.5 && value<1.9){
          cropper.zoom(.9);
        } else if(value>=1.4 && value<1.5){
          cropper.zoom(.8);
        } else if(value>=1.3 && value<1.4){
          cropper.zoom(.8);
        } else if(value>=1.2 && value<1.3){
          cropper.zoom(.8);
        } else if(value>=1.1 && value<1.2){
          cropper.zoom(.8);
        } else if(value>=1 && value<1.1){
          cropper.zoom(0);
        }
      } else {
        cropper.zoom(value);
      }

    }
  }

  private async save(): Promise<void> {
    try{
      this.isSaving = true;
      const userDetails: UserModelRequest = new UserModelRequest;
      const user = this.$store.getters.user as UserModel;
      userDetails.userId = user.userId;
      userDetails.firstName = this.user.firstName.trim();
      userDetails.lastName = this.user.lastName.trim();
      userDetails.email = this.user.email.trim();
      userDetails.phone = this.user.phone.trim();
      this.user.phone = this.user.phone.trim();
      if(this.user.phone.length < 10){
        return;
      }
      const response = await this.userService.updateUser(userDetails);
      if(response.result === 'success'){
        this.user.firstName = this.user.firstName.trim();
        this.user.lastName = this.user.lastName.trim();
        this.user.email = this.user.email.trim();
        this.user.phone = this.user.phone.trim();
        user.firstName = this.user.firstName;
        user.lastName = this.user.lastName;
        user.phone = this.user.phone;
        user.name = this.user.firstName + ' ' + this.user.lastName;
        store.commit('setUser', user);
        this.userClone = lodash.cloneDeep(this.user);
        this.changedSettingsCounter = 0;
        this.changedSettings(this.changedSettingsCounter);
      }
    }
    finally{
      this.isSaving = false;
    }
  }

  private async isFormValid(valid: boolean): Promise<void> {
    let formValid = true;
    formValid = valid;
    if(!this.isValid){
      formValid = false;
    }
    this.formValid(formValid);
  }

  @Emit()
  private nextTab(): void { }

  @Emit()
  private changedSettings(value: number): void {}

  @Emit()
  private formValid(valid: boolean): void {}

  @Watch('user')
  private async onContactChanged(user: ContactModel): Promise<void> {
    this.userClone = lodash.cloneDeep(user);
    this.compareUserClone();
  }

}
