import i18n from "@/i18n";
import { DropdownModel } from "@/models/dropdownModel";

export class CertificateTcHelper {
    public static getCertificateTypes(): DropdownModel[] {
        const types: DropdownModel[] = [
            {text: "OCS 100", value: "OCS 100"},
            {text: "OCS Blended", value: "OCS Blended"},
            {text: "RCS 100", value: "RCS 100"},
            {text: "RCS blended", value: "RCS blended"},
            {text: "GRS", value: "GRS"},
            {text: "RWS", value: "RWS"},
            {text: "RMS", value: "RMS"},
            {text: "RAS", value: "RAS"},
            {text: "RDS", value: "RDS"},
            {text: "CCS", value: "CCS"},
            {text: "GOTS", value: "GOTS"},
            {text: "SRCCS", value: "SRCCS"},
            {text: "BCI", value: "BCI"},
            {text: "FSC", value: "FSC"},
        ];
        return types;
    }

    public static getCertificateOptions(): DropdownModel[] {
        const types: DropdownModel[] = [
            {text: "Product", value: "Product"},
            {text: "Component", value: "Component"}
        ];
        return types;
    }
}
