import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import Multiselect from 'vue-multiselect';
import i18n from "@/i18n";
import _ from "lodash";
import { mapState } from "vuex";

import DropDownEditable from "@/components/general/drop-down-editable/dropDownEditable.vue";
import InviteNew from "@/components/general/invite-new/inviteNew.vue";

// Services
import { InvitationService } from '@/services/invitationService';
import { SupplierService } from "@/services/supplierService";
import { GeolocationService } from '@/services/geolocationService';
import { CountryStateCityService } from '@/services/countryStateCityService';

// Helpers
import { NotificationHelper } from "@/helpers/notificationHelper";
import { CountryHelper } from "@/helpers/countryHelper";

// Models
import { DropdownModel } from '@/models/dropdownModel';
import { InvitationModel } from '@/models/invitationModel';
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { InvitedSupplierModel } from "@/models/invitedSupplierModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { debounce } from "ts-debounce";
import { UserModel } from "@/models/userModel";
import { InviteDropdownModel } from "@/models/inviteDropdownModel";

@Component({
    components: { Multiselect, DropDownEditable, InviteNew },
    computed: mapState(['user', 'companyType', 'orderLoading', 'orders', 'orderLineStepsLoading', 'orderLineSteps']),
})
export default class SearchSupplier extends Vue {
    @Prop()
    private items!: DropdownModel[];

    @Prop()
    private isLoading!: boolean;

    @Prop()
    private suppliers!: OrderCompanyModel[];

    @Prop()
    private isTier1!: boolean;

    @Prop()
    private title!: string;

    @Prop()
    private agent!: boolean;

    @Prop()
    private searchPlaceholder!: string;

    @Prop()
    private isForPendingOrders!: boolean;

    @Prop()
    private toCompanyId!: string;

    @Prop()
    private refNum!: string;

    @Prop({default: false})
    private isCp!: boolean;

    private companyType!: number;
    private countries: DropdownModel[] = [];
    private countriesDropdown: DropdownModel[] = [];
    private statesDropdown: DropdownModel[] = [];
    private citiesDropdown: DropdownModel[] = [];

    private searchText: string = '';

    private inviteSendToContactEmail: string = '';

    private invitationService: InvitationService;

    private supplierService: SupplierService;

    private invitationModel: InvitationModel = new InvitationModel();

    private showSearchSupplierModal: boolean = true;

    private showInviteSupplierModal: boolean = false;

    private isInviting: boolean = false;

    private cscService: CountryStateCityService;
    private geolocationService: GeolocationService;

    private mapboxAccessToken: string = "";

    private mapboxGeolocationCoordinates: any;

    private selectedPartnerType: number = 0;

    //  error handling and validation
    private contactEmailError: boolean = false;

    private contactNameError: boolean = false;

    private companyNameError: boolean = false;

    private companyRegistrationNumberError: boolean = false;

    private cityError: boolean = false;

    private stateError: boolean = false;

    private countryError: boolean = false;

    private partnerTypeError: boolean = false;

    private showAllSuppliers: boolean = false;

    private showListLoading: boolean = false;

    private checkFieldsDebounced: any;

    private alteredSuppliers!: OrderCompanyModel[];

    private listItems: InviteDropdownModel[] = [];

    private user!: UserModel;

    private orderLoading!: boolean;

    private orders!: OrderLineDisplayModel[];

    private orderLineStepsLoading!: boolean;

    private orderLineSteps!: OrderLineStepDisplayModel[];

    private referenceNumber: string = '';

    public constructor() {
        super();
        this.invitationService = new InvitationService();
        this.supplierService = new SupplierService();
        this.cscService = new CountryStateCityService();
        this.geolocationService = new GeolocationService();
    }

    private async created(): Promise<void> {
        this.countries = CountryHelper.getCountryList();
        this.countriesDropdown = [];
        this.countriesDropdown = _.orderBy(CountryHelper.getCountryList(), ['text'], ['asc']);
        this.setPartnerAndRef();
    }

    private toggleSupplierFilter(): void {
        if (!this.showAllSuppliers) {
            let totalSuppliers: OrderCompanyModel[] = this.$store.getters.suppliers;
            if (this.isTier1 === false) {
                this.listItems = totalSuppliers.map(s => ({ value: s.id, text: s.isCompliant ? (s.id === this.user.companyId ? `${s.companyName} (me)` : `${s.companyName} (${s.country})`) : `${s.companyName} (${this.$t('pages.home.pending_invite')})`, selected: false }));
            } else {
                totalSuppliers = totalSuppliers.filter(t => t.isTier1 === true);
                this.listItems = totalSuppliers.map(s => ({ value: s.id, text: s.isCompliant ? `${s.companyName} (${s.country})` : `${s.companyName} (${this.$t('pages.home.pending_invite')})`, selected: false }));
            }
            this.showAllSuppliers = true;
        } else {
            this.listItems = this.filterSuppliers.map(s => ({ value: s.id, text: s.isCompliant ? (s.id === this.user.companyId ? `${s.companyName} (me)` : `${s.companyName} (${s.country})`) : `${s.companyName} (${this.$t('pages.home.pending_invite')})`, selected: false }));
            this.showAllSuppliers = false;
        }
    }

    // Sprint 23 - TTD-3647, updated
    private get filteredItems(): InviteDropdownModel[] {
        this.showListLoading = true;
        let totalSuppliers: OrderCompanyModel[] = this.$store.getters.suppliers;
        if (this.isTier1 === false) {
            if (this.agent) {
                totalSuppliers = totalSuppliers.filter(t => (t.isAgent === true));
            } else {
                totalSuppliers = totalSuppliers.filter(t => (t.isAgent === false));
            }
            this.listItems = totalSuppliers.map(s => ({ value: s.id, text: s.isCompliant ? (s.id === this.user.companyId ? `${s.companyName} (me)` : `${s.companyName} (${s.country})`) : `${s.companyName} (${this.$t('pages.home.pending_invite')})`, selected: false }));
        } else {
            totalSuppliers = totalSuppliers.filter(t => t.isTier1 === true);
            this.listItems = totalSuppliers.map(s => ({ value: s.id, text: s.isCompliant ? `${s.companyName} (${s.country})` : `${s.companyName} (${this.$t('pages.home.pending_invite')})`, selected: false }));
        }
        let finalList = this.listItems.filter(f => {
            const sanitizedItemText = f.text.replace(/[^a-zA-ZıİğĞşŞçÇüÜöÖ]/g, '');
            return f.text.toLowerCase().match(this.searchText.toLowerCase()) || f.text.replace(/[^a-zA-ZıİğĞşŞçÇüÜöÖ]/g, '').toLocaleLowerCase("tr-TR").includes(this.searchText.replace(/[^a-zA-ZıİğĞşŞçÇüÜöÖ]/g, '').toLocaleLowerCase("tr-TR"));
        });
        finalList = finalList.sort((user1,user2) => {
            return user2.text.toLowerCase() > user1.text.toLowerCase()
              ? -1
              : user2.text.toLowerCase() < user1.text.toLowerCase()
              ? 1
              : 0;
          });
        this.showListLoading = false;
        return finalList;
    }

    private get supplierLoading(): boolean {
        if (this.companyType === 5) {
            return this.isLoading;
        }
        if (this.isTier1 === false && (!this.agent || this.companyType !== 5)) {
            return this.showListLoading || this.orderLineStepsLoading;
        }
        else if (this.isTier1 === false && this.agent) {
            return false;
        } else {
            return this.showListLoading || this.orderLoading;
        }
    }

    private get filterSuppliers(): OrderCompanyModel[] {
        let uniqueToCompanyId: string[] = [];
        if (this.isTier1 === false) {
            uniqueToCompanyId = [... new Set(this.orderLineSteps.map(o => o.toCompanyId))];
        } else {
            uniqueToCompanyId = [... new Set(this.orders.map(o => o.toCompanyId))];
        }
        if (this.agent || this.companyType === 5) {
            const agents = this.suppliers.filter((s) => s.isAgent === true);
            return agents;
        }
        return this.suppliers.filter(s => uniqueToCompanyId.includes(s.id) && s.isAgent === false);
    }

    private get partnerType(): DropdownModel[] {
        return [
            { value: 1, text: i18n.t('pages.invitation.tier1_partner').toString() },
            { value: 2, text: i18n.t('pages.invitation.nominated_partner').toString() }
        ];
    }

    private get confirmDisabled(): boolean {
        return this.listItems.filter(a => a.selected === true).length > 0 ? false : true;
    }

    private changeSelected(item: InviteDropdownModel, state: boolean): void {
        this.listItems.forEach(e => {
            e.selected = false;
        });
        this.listItems.filter(f => f.value === item.value)[0].selected = state;
    }

    private closeInviteSupplierModal(isInvited: boolean): void {
        if (isInvited) {
            this.invitationSend();
        }
        this.showInviteSupplierModal = false;
        this.showSearchSupplierModal = true;
    }

    private openInviteSupplierModal(): void {
        this.invitationModel = new InvitationModel();
        this.inviteSendToContactEmail = "";
        this.showSearchSupplierModal = false;
        this.showInviteSupplierModal = true;
        // this.getMapboxTokenAsync();
    }

    private sendResponse(): void {
        const id = this.listItems.filter(a => a.selected === true)[0].value;
        this.valueSelected(id.toString(), this.referenceNumber);
    }

    private filterInput(event: Event) {
        const input = event.target as HTMLInputElement;
        const filteredValue = input.value.replace(/[^a-zA-Z0-9.,+/&-]/g, '');
        input.value = filteredValue;
        this.referenceNumber = filteredValue;
    }

    private setPartnerAndRef() {
        if (this.toCompanyId !== '' || this.refNum !== '') {
            const index = this.filteredItems.findIndex((scp) => scp.value === this.toCompanyId);
            if (index !== -1) {
                this.filteredItems[index].selected = true;
                this.referenceNumber = this.refNum;
            }
        }
    }

    @Watch('suppliers')
    private reloadOrders(): void {
        if (this.companyType === 5) {
            this.toggleSupplierFilter();
        }
    }

    @Emit()
    private valueSelected(value: string, refNum: string): void {
        this.searchText = '';
    }

    @Emit()
    private closeSearch(): void {
    }

    // Removed because token api is already being called from <invite-new> component
    // private async getMapboxTokenAsync(): Promise<void> {
    //     this.mapboxAccessToken = (await this.geolocationService.getMapboxToken()).token;
    //     return;
    // }

    @Emit()
    private invitationSend(): void {
    }
}