import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { mapState } from 'vuex';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import store from "@/store";
import { ActionTypes } from "@/store/actions/actions";
import lodash, { uniqBy } from 'lodash';

// Modals
import { DropdownModel } from "@/models/dropdownModel";
import { SetMandatoryStepsModel } from "@/models/orderLineStepModel";
import { cloneDeep, result } from "lodash";
import { StepsCategoryModel } from "@/models/stepsCategoryModel";
import { SupplierOverviewModel } from "@/models/supplierOverviewModel";
import { UserModel } from "@/models/userModel";
import { CompanyModel } from "@/models/companyModel";
import { MandatoryStepType, MandatoryStepsProcessModel, MandatoryProcessStepIDsModel, MandatoryStepsModel, MandatoryStepsRuleModel, MandatoryStepsListModel,
  MandatoryStepRules, MandatoryStepsResponseModel, addNewMandatoryStep, stepDeleteResponse
 } from "@/models/mandatoryStepsModel";
 import { Guid } from "@/models/guid";

// Services
import { SupplierService } from "@/services/supplierService";

// Components
import MultiSelectDropDown from '@/components/input/multiSelectDropDown.vue';
import { UserPermissionModel } from "@/models/permissionModel";
import OrderLineLoader from '@/views/orderLines/components/orderLineLoader.vue';

// Helpers
import { StepsHelper } from "@/helpers/stepsHelper";
import { NotificationHelper } from "@/helpers/notificationHelper";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { promises } from "dns";

@Component({
    components: { MultiSelectDropDown, OrderLineLoader },
    computed: mapState(['userRole', 'user', 'company', 'userPermissions', 'suppliers', 'mandatoryStepRules']),
})
export default class setMandatorySteps extends Vue {

    @Prop()
    private searchFilter!: string;

    private setMandatoryToogle: boolean = false;

    private selectLevel: string = MandatoryStepType.CONT_PART; // TTD-5017, sprint 28 commenting sprint-28 brand changes

    // private levels: DropdownModel[] = [{ value: MandatoryStepType.CONT_PART, text: 'Contractual partner(s)' },
    //                                     { value: MandatoryStepType.BRAND, text: 'Brand(s)'}];
    private levels: DropdownModel[] = [{ value: MandatoryStepType.CONT_PART, text: 'Contractual partner(s)' }];
    private userRole!: string;

    private mandatorySteps: MandatoryStepRules = new MandatoryStepRules();

    private mandatoryStepsClone: MandatoryStepRules = new MandatoryStepRules();

    private showMandatoryStepsModel: boolean = false;

    private mandatoryStepsCategory: MandatoryStepsProcessModel[] = [];

    private partners: OrderCompanyModel[] = [];

    private selectedPartners: string = '';

    private supplierService: SupplierService;

    private isDropdownOpen: boolean = false;

    private partnersList: DropdownModel[] = [];

    private brandList: DropdownModel[] = [];

    private selectedPartner: any[] = [];

    private selectedBrand: any[] = [];

    private showEditMandatorySteps: boolean = false;

    private showdeleteMandatorySteps: boolean = false;

    private user!: UserModel;

    private company!: CompanyModel;

    private userPermissions!: UserPermissionModel | null;

    private suppliers!: OrderCompanyModel[];

    private mandatoryStepRules!: MandatoryStepRules | null;

    private selectedCategory: MandatoryStepsProcessModel[] = [];

    private isStepsSaving: boolean = false;

    private mandatoryStepsToEdit: MandatoryStepsRuleModel = new MandatoryStepsRuleModel();

    private mandatoryStepsTodelete: MandatoryStepsRuleModel = new MandatoryStepsRuleModel();

    private isEdit: boolean = false;

    private isStepRuleDelete: boolean = false;

    private EmptyID:string = Guid.Empty;

    private isGetStepRules: boolean = false;

    private isAllSelected: boolean = false;

    // TTD-5017
    private mandatoryStepsType: string = "";

    public constructor() {
        super();
        this.supplierService = new SupplierService();
    }

    private async created(): Promise<void>{
      this.setMandatoryToogle = true;
      this.isGetStepRules = true;
      if(this.mandatoryStepRules === null){
        await store.dispatch(ActionTypes.SET_MANDATORY_STEP_RULES);
      }
        // TTD-5017, sprint 28 commenting sprint-28 brand changes
      // const mandatoyStepsTypeResp = await this.supplierService.getMandatoryStepsTypeAsync();
      // if(mandatoyStepsTypeResp.success){
      //   this.mandatoryStepsType = mandatoyStepsTypeResp.mandatoryStepRuleType;
      // }
      this.isGetStepRules = false;
      this.mandatorySteps = this.mandatoryStepRules !== null ? cloneDeep(this.mandatoryStepRules) : new MandatoryStepRules;
      if(this.mandatorySteps.ID !== Guid.Empty){
        // commenting sprint 28 brand changes
        // this.selectLevel = this.mandatoryStepsType;
        this.mandatoryStepsClone = cloneDeep(this.mandatorySteps);
      }
      this.getSupplierList();
      // this.getBrandList(); commenting sprint 28 brand changes
      this.mandatoryStepsCategory = StepsHelper.getMandatoryStepProcess();
    }

    private get createMandStepsPermission(): number {
      return this.userPermissions !== null ? this.userPermissions.createMandatorySteps : 0;
    }

    private get updateMandStepsPermission(): number {
      return this.userPermissions !== null ? this.userPermissions.updateMandatorySteps : 0;
    }

    private get readMandStepsPermission(): number {
      return this.userPermissions !== null ? this.userPermissions.readMandatorysteps : 0;
    }

    private get deleteMandStepsPermission(): number {
      return this.userPermissions !== null ? this.userPermissions.deleteMandatorySteps : 0;
    }

    private toggleInfo(): void{
        this.setMandatoryToogle = !this.setMandatoryToogle;
    }

    private toggleDropdown(): void{
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    private getSteps(step:MandatoryProcessStepIDsModel[]): string{
        return step.map(s => s.stepName).join(', ');
    }

    private selectPartnerValues(types: number[]): void {
        this.selectedPartner = types;
      }

    private getProcess(process:string[]): string {
      // if(process.length > 1){
        const str:string[] = [];
        process.forEach(p => {
          str.push(this.$t(`enums.process.${p}`).toString());
        });
        return str.join(', ');
      // }
      // return this.$t(`enums.process.${process}`).toString();
    }

    private getNames(names:MandatoryStepsListModel[]): string{
      return names.map(n => n.name).join(', ');
    }

    private selectedBrandValues(types: number[]): void{
      this.selectedBrand = types;
    }

    // Sprint 28 not required as brand releted changes commented
    // private getBrandList(): void {
    //   let brands: string[] = [];
    //   if (this.user && this.user.topBrands && this.user.topBrands.length > 0) {
    //     brands = _.pluck(this.user.topBrands, 'brandName');
    //   }
    //   else{
    //     if(this.userRole === "Admin" && this.user && this.user.userType === "COMPANY_ADMIN" && this.company !== null){
    //       brands = _.pluck(this.company.topBrands, 'brandName');
    //     }
    //   }
    //   this.brandList.push(...brands.map(brand => new DropdownModel(brand,brand)));
    //   if(this.brandList.length > 0){
    //     this.brandList.sort((a, b) => a.text.localeCompare(b.text));
    //     this.brandList = lodash.uniqBy(this.brandList, 'value');
    //     this.brandList.splice(0, 0, { value: "", text: this.$t("pages.order_lines.all_brands").toString(), disabled: false, selected: false });
    //   }
    // }

    private cancelSetMandatorySteps(): void{
      this.selectedPartner = [];
      this.selectedBrand = [];
      this.showMandatoryStepsModel = false;
      this.mandatoryStepsCategory = StepsHelper.getMandatoryStepProcess();
      this.partnersList = this.partnersList.map(o => ({...o, selected: false, disabled: false}));
      // this.brandList = this.brandList.map(b => ({...b, selected: false, disabled: false})); commenting sprint 28 brand changes
      this.isAllSelected = false;
    }

    private resetAllOptions(): void{
      this.partnersList = this.partnersList.map(o => ({...o, isSelected: false}));
      this.isEdit = false;
    }

    private setDisable(val:string): void{
        if(this.selectLevel === MandatoryStepType.BRAND){
          if(this.isEdit && this.mandatoryStepsToEdit.list.findIndex(s => s.name === val)!== -1 && val!==''){
            return;
          }
          this.brandList.filter(b => b.value === val)[0].disabled = true;
          if(this.brandList.filter(b => b.disabled === false).length === 1){
            this.brandList[this.brandList.findIndex(b => b.value === '')].disabled = true;
          }
        }else{
           if(this.isEdit && this.mandatoryStepsToEdit.list.findIndex(s => s.ID === val)!== -1 && val!==''){
            return;
          }
          this.partnersList.filter(p => p.value === val)[0].disabled = true;
          if(this.partnersList.filter(b => b.disabled === false).length === 1){
            this.partnersList[this.partnersList.findIndex(b => b.value === '')].disabled = true;
          }
        }
    }

    private setSelected(option:DropdownModel, isSelected:boolean, isAllSelected:boolean): void{
      if(this.selectLevel === MandatoryStepType.BRAND){
        if(isSelected){
          if(option.value===''){
            this.brandList.forEach(b => {
              if(!b.disabled){
                b.selected = true;
              }
            });
          }else{
            this.brandList.filter(b => b.value === option.value)[0].selected = true;
            if(this.brandList.filter(b => b.selected === false && !b.disabled).length === 1){
              this.brandList[this.brandList.findIndex(b => b.value === '')].selected = true;
              this.isAllSelected = true;
              this.selectedBrand.push('');
            }else{
              this.isAllSelected = false;
            }
          }
        }else{
          if(option.value===''){
            this.brandList.forEach(b => {
              if(!b.disabled){
                b.selected = false;
              }
            });
          }else{
            this.brandList.filter(b => b.value === option.value)[0].selected = false;
            if(this.brandList[this.brandList.findIndex(b => b.value === '')].selected === true){
              this.brandList[this.brandList.findIndex(b => b.value === '')].selected = false;
              this.isAllSelected = false;
              const emptyIndex = this.selectedBrand.findIndex(b => b === '');
              if(emptyIndex!==-1){
                this.selectedBrand.splice(emptyIndex, 1);
              }
            }
          }
        }
      }else{
        if(isSelected){
          if(option.value === ''){
            this.partnersList.forEach(p => {
              if(!p.disabled){
                  p.selected = true;
              }
            });
          }else{
            this.partnersList.filter(b => b.value === option.value)[0].selected = true;
            if(this.partnersList.filter(b => b.selected === false && !b.disabled).length === 1){
              this.partnersList[this.partnersList.findIndex(b => b.value === '')].selected = true;
              this.isAllSelected = true;
              this.selectedPartner.push('');
            }else{
              this.isAllSelected = false;
            }
          }
        }else{
          if(option.value === ''){
            this.partnersList.forEach(p => {
              if(!p.disabled){
                  p.selected = false;
              }
            });
          }else{
            this.partnersList.filter(b => b.value === option.value)[0].selected = false;
            if(this.partnersList[this.partnersList.findIndex(b => b.value === '')].selected === true){
              this.partnersList[this.partnersList.findIndex(b => b.value === '')].selected = false;
              this.isAllSelected = false;
              const emptyIndex = this.selectedPartner.findIndex(p => p === '');
              if(emptyIndex!==-1){
                this.selectedPartner.splice(emptyIndex, 1);
              }
            }
          }
        }
      }
    }

    private get isProcessNotSelected(): boolean{
      return this.mandatoryStepsCategory.findIndex(c => c.selected === true)=== -1;
    }

    private get isPartnerOrBrandSelected(): boolean{
      if((this.selectLevel === MandatoryStepType.BRAND && this.selectedBrand.length > 0) || (this.selectLevel === MandatoryStepType.CONT_PART && this.selectedPartner.length > 0)){
        return true;
      }
      return false;
    }

    private async setMandatorySteps(): Promise<void>{
      this.isStepsSaving = true;
      let res:MandatoryStepsResponseModel = new MandatoryStepsResponseModel();
      if(this.isEdit){
            const EditMandatorySteps = new addNewMandatoryStep();
            EditMandatorySteps.ID = this.mandatorySteps.ID;
            EditMandatorySteps.mandatoryStepRuleID = this.mandatoryStepsToEdit.ID;
            EditMandatorySteps.processes = this.mandatoryStepsCategory.filter(ma => ma.selected === true).map(m => m.processID);
            EditMandatorySteps.list = [];
            if(this.selectLevel === MandatoryStepType.CONT_PART){
                this.selectedPartner.forEach(sp => {
                const cp = new MandatoryStepsListModel();
                cp.type = MandatoryStepType.CONT_PART;
                if(sp!== ''){
                  cp.name = this.partnersList.filter(p => p.value === sp)[0].text;
                  cp.ID = this.partnersList.filter(p => p.value === sp)[0].value.toString();
                  EditMandatorySteps.list.push(cp);
                }
              });
            }else{
              this.selectedBrand.forEach(br => {
              const brand = new MandatoryStepsListModel();
              brand.type = MandatoryStepType.BRAND;
                if(br!==''){
                  brand.name = this.brandList.filter(b => b.value === br && br!== '')[0].text;
                  brand.ID = uuidv4();
                  EditMandatorySteps.list.push(brand);
                }
              });
            }
          res = await this.supplierService.editMandatoryStepsAsync(EditMandatorySteps);
      }else{
        if(this.mandatorySteps.ID === Guid.Empty){
          const mandatorySteps = new MandatoryStepsModel();
          mandatorySteps.ID = this.user.companyId;
          mandatorySteps.clientID = this.user.companyId;
          mandatorySteps.clientName = this.user.companyName;
          mandatorySteps.mandatoryStepType = this.selectLevel;
          const mandatoryStepRule = new MandatoryStepsRuleModel();
          mandatoryStepRule.ID = uuidv4();
          mandatoryStepRule.processes = this.mandatoryStepsCategory.filter(ma => ma.selected === true).map(m => m.processID);
          mandatoryStepRule.createdAt = '0001-01-01T00:00:00Z';
          mandatoryStepRule.createdBy = '';
          mandatoryStepRule.list = [];
          if(this.selectLevel === MandatoryStepType.CONT_PART){
            this.selectedPartner = _.uniq(this.selectedPartner);
            this.selectedPartner.forEach(sp => {
            const cp = new MandatoryStepsListModel();
            cp.type = MandatoryStepType.CONT_PART;
            if(sp!== ''){
              cp.name = this.partnersList.filter(p => p.value === sp)[0].text;
              cp.ID = this.partnersList.filter(p => p.value === sp)[0].value.toString();
              mandatoryStepRule.list.push(cp);
            }
            });
            mandatorySteps.mandatoryStepRule = [mandatoryStepRule];
          }else{
            this.selectedBrand = _.uniq(this.selectedBrand);
            this.selectedBrand.forEach(br => {
            const brand = new MandatoryStepsListModel();
            brand.type = MandatoryStepType.BRAND;
            if(br!==''){
              brand.name = this.brandList.filter(b => b.value === br && br!== '')[0].text;
              brand.ID = uuidv4();
              mandatoryStepRule.list.push(brand);
            }
            });
            mandatorySteps.mandatoryStepRule = [mandatoryStepRule];
          }
          res = await this.supplierService.saveMandatoryStepsAsync(mandatorySteps);
        }else{
          const addNewMandatorySteps = new addNewMandatoryStep();
            addNewMandatorySteps.ID = this.mandatorySteps.ID;
            addNewMandatorySteps.mandatoryStepRuleID = uuidv4();
            addNewMandatorySteps.processes = this.mandatoryStepsCategory.filter(ma => ma.selected === true).map(m => m.processID);
            addNewMandatorySteps.list = [];
            if(this.selectLevel === MandatoryStepType.CONT_PART){
                this.selectedPartner = _.uniq(this.selectedPartner);
                this.selectedPartner.forEach(sp => {
                const cp = new MandatoryStepsListModel();
                cp.type = MandatoryStepType.CONT_PART;
                if(sp!== ''){
                  cp.name = this.partnersList.filter(p => p.value === sp)[0].text;
                  cp.ID = this.partnersList.filter(p => p.value === sp)[0].value.toString();
                  addNewMandatorySteps.list.push(cp);
                }
              });
            }else{
              this.selectedBrand = _.uniq(this.selectedBrand);
              this.selectedBrand.forEach(br => {
              const brand = new MandatoryStepsListModel();
              brand.type = MandatoryStepType.BRAND;
                if(br!==''){
                  brand.name = this.brandList.filter(b => b.value === br && br!== '')[0].text;
                  brand.ID = uuidv4();
                  addNewMandatorySteps.list.push(brand);
                }
              });
            }
          res = await this.supplierService.addNewMandatoryStepsAsync(addNewMandatorySteps);
        }
      }
      if(res.success){
        if(this.isEdit){
          NotificationHelper.createSucceededNotification('Mandatory steps successfully updated');
        }else{
          NotificationHelper.createSucceededNotification('Mandatory steps successfully saved');
        }
        if(this.mandatoryStepRules === null || this.mandatoryStepRules?.ID === Guid.Empty){
          await store.commit(ActionTypes.SET_MANDATORY_STEP_RULES, res.mandatoryStepRules);
        } else {
          const stepRules = cloneDeep(this.mandatoryStepRules);
          stepRules.mandatoryStepRule = res.mandatoryStepRules.mandatoryStepRule;
          await store.commit(ActionTypes.SET_MANDATORY_STEP_RULES, stepRules);
        }
        this.mandatorySteps = this.mandatoryStepRules !== null ? cloneDeep(this.mandatoryStepRules) : new MandatoryStepRules;
        this.mandatoryStepsClone = cloneDeep(this.mandatorySteps);
      } else {
        NotificationHelper.createErrorNotification('Failed to save mandatory steps. Please try again!');
      }
      this.isStepsSaving = false;
      this.showMandatoryStepsModel = false;
      this.mandatoryStepsCategory = StepsHelper.getMandatoryStepProcess();
      this.mandatorySteps = res.mandatoryStepRules;
      this.mandatoryStepsClone = cloneDeep(this.mandatorySteps);
      this.selectedPartner = [];
      this.selectedBrand = [];
      this.isAllSelected = false;
      this.partnersList = this.partnersList.map(o => ({...o, selected: false, disabled: false}));
      // this.brandList = this.brandList.map(b => ({...b, selected: false, disabled: false})); // commenting sprint-28 brand changes
    }

    private formattedDate(date: string): string {
      return moment(date).format("D MMM YYYY");
    }
    // commenting sprint-28 brand changes
    // private get isMandatroyStepsExist(): boolean{
    //   if(this.mandatoryStepsType!==''){
    //     if(this.mandatorySteps.mandatoryStepRule.length > 0){
    //       return true;
    //     }
    //   }
    //   return false;
    // }

    private getSupplierList(): void{
      this.partners = cloneDeep(this.suppliers);
      if(this.partners.length > 0){
        this.partners = this.partners.filter(p => p.isTier1 === true && p.isAgent === false && p.id!== this.user.companyId);
        this.partnersList = this.partners.map(p => ({value: p.id, text: p.companyName, disabled: false, selected: false}));
        this.partnersList = lodash.uniqBy(this.partnersList, 'value');
        if(this.partnersList.length > 0){
          this.partnersList.sort((a, b) => a.text.localeCompare(b.text));
          this.partnersList.splice(0, 0, { value: "", text: 'All contractual partners', disabled: false, selected: false});
        }
      }
    }

    private editMandatorySteps(ms:MandatoryStepsRuleModel): void{
      this.showEditMandatorySteps = true;
      this.mandatoryStepsToEdit = ms;
      this.isEdit = true;
      ms.processes.forEach(p => {
        this.mandatoryStepsCategory.filter(m => m.processID === p)[0].selected = true;
      });
      if(this.mandatorySteps.mandatoryStepType === MandatoryStepType.BRAND){
        ms.list.forEach(l => {
          this.selectedBrand.push(l.name);
          this.brandList.filter(b => b.text === l.name)[0].selected = true;
        });
      }else{
        ms.list.forEach(l => {
          this.selectedPartner.push(l.ID.toString());
          this.partnersList.filter(b => b.value === l.ID && b.value!== '')[0].selected = true;
        });
      }
    }

    private showMandatoryStepModal(): void{
      this.showMandatoryStepsModel = true;
      this.isEdit = false;
      this.selectedPartner = [];
      this.selectedBrand = [];
      this.partnersList = this.partnersList.map(o => ({...o, selected: false, disabled: false}));
      // this.brandList = this.brandList.map(b => ({...b, selected: false, disabled: false})); // commenting sprint-28 brand changes
      this.mandatoryStepsToEdit = new MandatoryStepsRuleModel();
    }

    private showDeleteMandatoryStepsPopup(ms:MandatoryStepsRuleModel): void{
      this.showdeleteMandatorySteps = true;
      this.mandatoryStepsTodelete = ms;
    }

    private async deleteMandatorySteps(): Promise<void>{
      this.isStepRuleDelete = true;
      if(this.mandatorySteps.mandatoryStepRule.length === 1){
        const result = await this.supplierService.deleteLastMandatorySteps(this.mandatorySteps.ID);
        if(result.success){
          this.mandatorySteps = new MandatoryStepRules();
          this.mandatoryStepsClone = new MandatoryStepRules();
          this.mandatoryStepsType = '';
          // commenting sprint-28 brand changes
          // this.selectLevel = '';
          NotificationHelper.createSucceededNotification('Mandatory steps successfully deleted.');
          await store.commit(ActionTypes.SET_MANDATORY_STEP_RULES, this.mandatorySteps);
        }
      }else{
        const res = await this.supplierService.deleteMandatoryStepsAsync(this.mandatorySteps.ID, this.mandatoryStepsTodelete.ID);
        if(res.success){
          NotificationHelper.createSucceededNotification('Mandatory steps successfully deleted.');
          await store.commit(ActionTypes.SET_MANDATORY_STEP_RULES, res.mandatoryStepRules);
          this.mandatorySteps = this.mandatoryStepRules !== null ? cloneDeep(this.mandatoryStepRules) : new MandatoryStepRules;
          this.mandatoryStepsClone = cloneDeep(this.mandatorySteps);
        }else{
          NotificationHelper.createErrorNotification('Failed to delete mandatory steps rule. Please try again!');
        }
      }
      this.isStepRuleDelete = false;
      this.showdeleteMandatorySteps = false;
      this.cancelSetMandatorySteps();
    }

    @Watch('searchFilter')
    private searchMandatorySteps(): void{
        if(this.searchFilter!=='' && this.mandatoryStepsClone){
            const searchChar = this.searchFilter.toLowerCase();
            if(this.mandatoryStepsClone.mandatoryStepRule.length > 0){
              this.mandatorySteps.mandatoryStepRule = this.mandatoryStepsClone.mandatoryStepRule.filter(ms => ms.createdByUser.toLowerCase().includes(searchChar) || this.getProcess(ms.processes).toString().toLowerCase().includes(searchChar) ||
            this.getNames(ms.list).toLowerCase().includes(searchChar));
            }
        }else{
          this.mandatorySteps = cloneDeep(this.mandatoryStepsClone);
        }
    }

    @Watch('suppliers')
    private setSuppliers(): void{
      this.getSupplierList();
    }
}
