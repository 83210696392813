import { AddressModel } from "./addressModel";
import { CompanyPhotoModel } from "./companyPhotoModel";
import { ComplianceDataModel } from "./complianceDataModel";
import { ContactModel } from "./contactModel";
import { OrderCompanyModel } from "./orderCompanyModel";
import { SupplierCertificateModal } from "./supplierCertificateModal";

export class SupplierModel {
    public id!: string;
    public companyName!: string;
    public contact!: ContactModel;
    public admin!: ContactModel;
    public companyType?: number;
    public companySubtypes!: number[];
    public companyId!: string;
    public categories!: number[];
    public monthlyCapacity!: number | string;
    public turnover!: number;
    public employees!: number | string;
    public brands!: string;
    public productGroups!: number[];
    public profile!: string;
    public officeAddress!: AddressModel[];
    public factoryAddresses!: AddressModel[];
    public factoryAddressDiffers!: boolean;
    public social!: ComplianceDataModel[];
    public sustainability!: ComplianceDataModel[];
    public profilePictures!: CompanyPhotoModel[];
    public turnoverCurrency!: string;
    public newFacilityData!: string;
    public maleEmployees!: number | string;
    public femaleEmployees!: number | string;

    public constructor() {
        this.id = "";
        this.companyName = "";
        this.contact = new ContactModel();
        this.admin = new ContactModel();
        this.companyType = undefined;
        this.companySubtypes = [];
        this.companyId = "";
        this.categories = [];
        this.brands = "";
        this.productGroups = [];
        this.profile = "";
        this.officeAddress = [new AddressModel()];
        this.factoryAddresses = [];
        this.factoryAddressDiffers = false;
        this.social = [];
        this.sustainability = [];
        this.profilePictures = [];
    }
}

export class SuppliersListModel {
    public success!: boolean;
    public supplier!: OrderCompanyModel[];
    public getT1SCP!: SupplierCertificateModal[];
}

export class SupplierAddressResponseModel{
    public companies!: SupplierAddressModel;
    public success!: boolean;
}

export class SupplierAddressModel {
    public ID!: string;
    public companyID!: string;
    public companyType!: number;
    public name!: string;
    public officeAddresses!: officLocationModel;
}

export class officLocationModel {
    public country!: string;
    public state!: string;
    public city!: string;
}

export class supplierLocationModel {
    public constructor(ID: string, country: string) {
        this.ID = ID;
        this.country = country;
    }
    public ID!: string;
    public country!: string;
}

//Sprint-26 TTD-3608 profile data
export class SupplierContactInfoList{
 public success!: boolean;
 public userDetails!: ContactUserList[];
}

export class ContactUserList{
    public company!: ContacCompany;
    public companyID!: string;
    public companyRole!: string;
    public firstName!: string;
    public lastName!: string;
    public phone!: string;
    public status!: string;
    public userID!: string;
}

export class ContacCompany{
   public companyType!: string;
   public name!: string;
}
