import { OrderLineStepParallelModel } from './orderLineStepParallelModel';
import { OrderLineStepProof } from './orderLineStepProof';
import { OrderLineStepStatus } from './orderLineStepStatus';
import { ProductGroup } from './productGroup';
import { StepHistory } from './stepHistoryModal';
import { StepState } from './stepState';

export class OrderLineStepDisplayModel {

    public id!: string;
    public orderId!: string;
    public orderLineId!: string;
    public orderNumber!: string;
    public stepId!: number;
    public displayName!: string;
    public productGroup!: ProductGroup;
    public sequence!: number;
    public status!: OrderLineStepStatus;
    public fromCompanyId!: string;
    public toCompanyId!: string;
    public toCompanyName!: string;
    public evidencePhotos: OrderLineStepProof[] = [];
    public validSocialCertificates: number[] = [];
    public validSustainabilityCertificates: number[] = [];
    public showDetailsCount!: number;
    public showNewsLetter!: boolean;
    public showFactoryAddress!: boolean;
    public showDetails!: boolean;
    public showProfile!: boolean;
    public rejectionReason!: string;
    public compliantAtVerificationDate!: boolean;
    public currentlyValidCertificates!: null;
    public stepState!: StepState;
    public stepHistory!: StepHistory[];
    public isLastStep!: string;
    public parallelChainCount!: number;
    public isSelected?: boolean;
    public clientID!: string;
    public cpSupplierID!: string;
    public approvalRequestedTo!: string;
    //TTD-2620, Agent orderlines changes
    public addedByAgent?: boolean;
    public updatedByAgent?: boolean;
    public deletedByAgent?: boolean;
    //TTD-2620, Agent orderlines changes
    //TTD-3591, new keys added
    public agentID?: string;
    public delegatedCompanyID?: string;
    //TTD-3591, new keys added
    //TTD-3794, orderRefId & brandName field added
    public brandName?: string;
    //TTD-3794, orderRefId & brandName field added
    public parallelSupplyChain0!: OrderLineStepParallelModel[];
    public parallelSupplyChain1!: OrderLineStepParallelModel[];
    public parallelSupplyChain2!: OrderLineStepParallelModel[];
    public parallelSupplyChain3!: OrderLineStepParallelModel[];
    public parallelSupplyChain4!: OrderLineStepParallelModel[];
    // Sprint 20, TTD-3932 & TTD-2721
    public footprintRefNo!: string;
    public footprintRefID!: string;
    public footprintRefNoUpdatedBy!: string;
    // Sprint 20, TTD-3940
    public footprintRefNoPrefix?: string;
}

export class OrderLineStepResponseModel {
    public success!: boolean;
    public orderLineSteps!: OrderLineStepDisplayModel[];
    public count!: number;
    public bookmark!: string;
}

export class FootprintPartnerModel {
    public id!: string;
    public orderId!: string;
    public orderLineId!: string;
    public orderNumber!: string;
    public stepId!: number;
    public displayName!: string;
    public status!: OrderLineStepStatus;
    public toCompanyId!: string;
    public toCompanyName!: string;
    public fromCompanyID!: string;
    public stepState!: StepState;
    public agentID!: string;
    public delegatedCompanyID!: string;
    public sequence!: number;
}

export class FootprintPartnerResponseModel {
    public success!: boolean;
    public orderLineSteps!: FootprintPartnerModel[];
}

export class OrderStepsResponseModel {
    public success!: boolean;
    public orderLineSteps!: OrderLineStepDisplayModel[];
    public count!: number;
    public bookmark!: string;
}