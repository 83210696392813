import { debounce } from "ts-debounce";
import { Vue, Component } from "vue-property-decorator";
import store from '@/store';
import { mapState } from 'vuex';
import CryptoJS from 'crypto-js';

// Components
import Authpage from "@/components/general/authpage.vue";

// Services
import { MiddlewareService } from '@/services/middlewareService';

@Component({
  components: { Authpage }
})

export default class ResetPassword extends Vue {

  private isLoading: boolean = false;

  private otpError: boolean = false;
  private emailError: boolean = false;
  private emailAddress: string = "";
  private checkFieldsDebounced: any;
  private weakPasswordError: boolean = false;

  private newpassword: string = "";
  private passwordError: boolean = false;

  private passwordConfirm: string = "";
  private passwordConfirmError: boolean = false;

  private allowConfirm: boolean = false;

  private middlewareService: MiddlewareService;
  public constructor() {
    super();
    this.middlewareService = new MiddlewareService();
  }

  private async created(): Promise<void> {
    const emailAddress=localStorage.getItem("maincontactemail");
    if (emailAddress !== undefined && emailAddress !== null) {
      this.emailAddress = emailAddress;
    }

    this.checkFieldsDebounced = debounce(this.checkFields, 350);
  }

  private async checkFields(event: any, fieldname: string): Promise<void> {
    this.allowConfirm = false;
    this.weakPasswordError = false;

    if (fieldname === 'newpassword') {
      const matches = event.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);
      this.passwordError = (matches == null) ? true : false;
      if (this.passwordError) {
        return;
      }
    }

    if (fieldname === 'passwordConfirm') {
      this.passwordConfirmError = (event.target.value.length < 8) ? true : false;
      if (this.passwordConfirmError) {
        return;
      }

      this.passwordConfirmError = (event.target.value !== this.newpassword) ? true : false;
      if (this.passwordConfirmError) {
        return;
      }
    }

    this.allowConfirm = true;
    if ((this.newpassword.length < 8) || (this.passwordConfirm.length < 8) ||
      (this.passwordConfirm !== this.newpassword)) {
      this.allowConfirm = false;
    }

    return;
  }

private async updatePassword(): Promise<void> {
    localStorage.setItem("newpassword", this.newpassword);
    this.isLoading = true;
    this.weakPasswordError = false;
    const result = await this.middlewareService.updatePassword(this.emailAddress, this.newpassword);
    if (result.result == "success") {
      this.$router.push({ name: 'ResetSentEmail', params: { emailAddress: this.emailAddress } });
    }
    else if(result.result == "weak password") {
     this.weakPasswordError = true;
    } else{
      this.$router.push({ name: 'ResetPassword' });
    }
    this.isLoading = false;

    return;
  }
}
