//Sprint-24 TTD-4483
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import moment from "moment";
import { mapState } from 'vuex';
import { cloneDeep, uniqBy, uniq } from "lodash";
import i18n from "@/i18n";
import store from '@/store';
import { DropdownModel } from "@/models/dropdownModel";

// Services
import { ComplianceService } from "@/services/complianceService";
import { ClientService } from "@/services/clientService";

// Modals
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";
import { ComplianceDocModel } from "@/models/complianceDocModel";
import { UserModel } from "@/models/userModel";
import { UserPermissionModel } from "@/models/permissionModel";
import { TCDocumentModel, TCModel } from "../../../models/transcationCertificateModal";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { Guid } from "@/models/guid";
import { TCtableModel, TCConstant, TCType } from "../../../models/tcTableModel";
import RemindComplianceDocument from "@/views/compliance/components/remindComplianceDocument.vue";

// Components
import ViewTcCertificates from "@/views/compliance/components/viewTcCertificates.vue";

//Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';
import { updateTCStatusRequestModel, orderListDetailsModel, TcMailRequestModel, SupplierOrderDataModel, TcMailModel } from "@/models/transactionCertsModel";
import { CompanyNameModel } from "@/models/companyModel";


@Component({
    components: { RemindComplianceDocument, ViewTcCertificates },
	computed: mapState(['userPermissions','userRole', 'companyNameList'])
})

export default class SentComplianceDocuments extends Vue {

	@Prop()
	private isLoading!: boolean;

	@Prop()
	private selectedTab!: number;

	@Prop()
    private searchFilter!: string;

	@Prop()
    private selectedBrand!: string;

    @Prop()
	private selectedType!: string;

    @Prop()
	private selectedPartner!: string;

    @Prop()
    private orders!: OrderLineDisplayModel[];

	private complianceService: ComplianceService;

    private showPDFModal: boolean = false;

    private currentDocument!: ComplianceDocSentResponseModel;

	private userPermissions!: UserPermissionModel | null;

    private showDeleteDocModal: boolean = false;

    private user: UserModel = new UserModel;

    private clientService: ClientService;

	private userRole!: string;

    private isOpenTCSection: boolean = false;

    private isOpen: boolean = false;

    // private selectedSortOption: string = "";

    // private isSortAtoZ: boolean = false;

    // private isSortZtoA: boolean = false;

    private isDeleting: boolean = false;

    private pendingRequests: OrderLineDisplayModel[] = [];

    private drafts: TCtableModel[] = [];

    private finalTC: TCtableModel[] = [];

    private archive:  TCtableModel[] = [];

    // private sortType: string = 'asc';

    private pendingTc: TCtableModel[] = [];

    private initialPendingTC: TCtableModel[] = [];

    private initialDrafts: TCtableModel[] = [];

    private initialFinalTC: TCtableModel[] = [];

    private initialArchive: TCtableModel[] = [];

    private isShowSendRemainder: boolean = false;

    private selectedToCompnay: string[] = [];

    private showReminderPopup: boolean = false;

    private documentName: string = '';

    private documentForReminder!: ComplianceDocModel;

    private selectedTCIndex: number = -1;

    private showCerts: boolean = false;

    private selectedCertificate!: TCtableModel;

    private showArchive: boolean = false;

    private selectedTCType: string = '';

    private selectedTC: string = '';

    private sectionIndex: number = 0;

    private documentDetails: TCDocumentModel = new TCDocumentModel();

    private selectedDocument: TCtableModel = new TCtableModel('', '', '', '', '', '', moment(), [], [], [], [], [], [], Guid.Empty, '', '', '', '', '', '','','');

    private showConfirmPopupLinkedOrders: boolean = false;

    private statusOrder = ['UPLOADED', 'REJECTED', 'APPROVED'];

    private tcSections: TCModel[] = [
        {tcSection: this.$t(`pages.compliance.pending_request`).toString(), isSelectedAll: false, isCollapse: false, isOpen:false, isSortAtoZ: false, isSortZtoA: false, sortType: 'asc', selectedSortOption: ""},
        {tcSection: this.$t(`pages.compliance.drafts`).toString(), isSelectedAll: false, isCollapse: false, isOpen: false,  isSortAtoZ: false, isSortZtoA: false, sortType: 'asc', selectedSortOption: ""},
        {tcSection: this.$t(`pages.compliance.finaltc`).toString(), isSelectedAll: false, isCollapse: false, isOpen:false, isSortAtoZ: false, isSortZtoA: false, sortType: 'asc', selectedSortOption: ""},
        {tcSection: this.$t(`pages.compliance.archive`).toString(), isSelectedAll: false, isCollapse: false, isOpen:false, isSortAtoZ: false, isSortZtoA: false, sortType: 'asc', selectedSortOption: ""},
    ]

    private companyNameList!: CompanyNameModel[]; // TTD-3961

	public constructor() {
		super();
		this.complianceService = new ComplianceService();
        this.clientService = new ClientService();
	}

	private created(): void {
        this.user = this.$store.getters.user as UserModel;
        this.getFilteredOrders();
	}

    private getFilteredOrders():void {
        if(this.orders.length > 0){
            this.pendingRequests = [];
            this.pendingTc = [];
            this.initialPendingTC = [];
            this.drafts = [];
            this.initialDrafts = [];
            this.finalTC = [];
            this.initialFinalTC = [];
            this.archive = [];
            this.initialArchive = [];
            this.pendingRequests = this.orders.filter(order => (order.tc1Status === TCConstant.PENDING || order.tc2Status === TCConstant.PENDING ) && order.toCompanyId!==Guid.Empty && order.orderLinePhase !== 0);
            if(this.pendingRequests.length > 0){
                // this.tcSections[0].isCollapse = true;
                this.pendingRequests.forEach(pr => {
                    const colour = pr.orderLines[0].colourway ? pr.orderLines[0].colourway : ''
                    if(pr.tranCertificate1!=='' && pr.levelTC1!=='' && pr.tc1Status === TCConstant.PENDING){
                        this.pendingTc.push(new TCtableModel(pr.orderId, pr.tranCertificate1, '', pr.levelTC1, '', this.companyNameList.filter(c => c.ID === pr.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === pr.toCompanyId)[0].name : pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, pr.tranCertificate1RefId, '', pr.tc1Status, '', pr.tcReferenceNumber, '', pr.tc1Type, ''));
                    }
                    if(pr.tranCertificate2!=='' && pr.levelTC2!=='' && pr.tc2Status === TCConstant.PENDING){
                        this.pendingTc.push(new TCtableModel(pr.orderId,'', pr.tranCertificate2, '', pr.levelTC2, this.companyNameList.filter(c => c.ID === pr.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === pr.toCompanyId)[0].name : pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, '', pr.tranCertificate2RefId, '', pr.tc2Status, '', pr.tcReferenceNumber1, '', pr.tc2Type));
                    }
                })
                this.initialPendingTC = cloneDeep(this.pendingTc);
                // this.pendingTc.forEach(pt => pt.isSelectedTC = false);
                if(this.pendingTc.length > 0){
                    this.tcSections[0].isCollapse = true;
                }
            }else{
                this.tcSections[0].isCollapse = false;
            }

            const filteredDrafts = this.orders.filter(order => (order.tc1Status === TCConstant.UPLOAD || order.tc2Status === TCConstant.UPLOAD  || order.tc1Status === TCConstant.APPROVED || order.tc2Status === TCConstant.APPROVED || order.tc1Status === TCConstant.REJECTED || order.tc2Status === TCConstant.REJECTED)
             && (order.tc1Type === TCType.DRAFT || order.tc2Type === TCType.DRAFT) && (order.tranCertificate1RefId !== '' || order.tranCertificate2RefId !== '') && order.toCompanyId!==Guid.Empty);
            if(filteredDrafts.length > 0){
                filteredDrafts.forEach(pr => {
                    const colour = pr.orderLines[0].colourway ? pr.orderLines[0].colourway : ''
                    if(pr.tranCertificate1 !== '' && pr.levelTC1 !== '' && pr.tc1Status !== TCConstant.PENDING && pr.tc1Status !== TCConstant.ARCHIVED && pr.tc1Type === TCType.DRAFT){
                        const tc1Index = this.drafts.findIndex(d => d.tranCertificate1RefId === pr.tranCertificate1RefId);
                        if(tc1Index === -1){
                            this.drafts.push(new TCtableModel(pr.orderId,pr.tranCertificate1, '', pr.levelTC1, '', this.companyNameList.filter(c => c.ID === pr.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === pr.toCompanyId)[0].name : pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, pr.tranCertificate1RefId, '', pr.tc1Status, '', pr.tcReferenceNumber, '', pr.tc1Type, ''));
                        } else {
                            this.drafts[tc1Index].brandNames.push(pr.brandName);
                            this.drafts[tc1Index].orderNumbers.push(pr.orderNumber);
                            this.drafts[tc1Index].styleNumbers.push(pr.styleNumber);
                            this.drafts[tc1Index].styleNames.push(pr.styleName);
                            this.drafts[tc1Index].colours.push(colour);
                            this.drafts[tc1Index].linkedOrderIDs.push(pr.orderId);
                        }
                    }
                    if(pr.tranCertificate2 !== '' && pr.levelTC2 !== '' && pr.tc2Status !== TCConstant.PENDING && pr.tc2Status !== TCConstant.ARCHIVED && pr.tc2Type === TCType.DRAFT){
                        const tc2Index = this.drafts.findIndex(d => d.tranCertificate2RefId === pr.tranCertificate2RefId);
                        if(tc2Index === -1){
                            this.drafts.push(new TCtableModel(pr.orderId,'', pr.tranCertificate2, '', pr.levelTC2, this.companyNameList.filter(c => c.ID === pr.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === pr.toCompanyId)[0].name : pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, '', pr.tranCertificate2RefId, '', pr.tc2Status, '', pr.tcReferenceNumber1, '', pr.tc2Type));
                        } else {
                            this.drafts[tc2Index].brandNames.push(pr.brandName);
                            this.drafts[tc2Index].orderNumbers.push(pr.orderNumber);
                            this.drafts[tc2Index].styleNumbers.push(pr.styleNumber);
                            this.drafts[tc2Index].styleNames.push(pr.styleName);
                            this.drafts[tc2Index].colours.push(colour);
                            this.drafts[tc2Index].linkedOrderIDs.push(pr.orderId);
                        }
                    }
                })
                const sortedDrafts: TCtableModel[] = [];
                const sortedUploadDrafts: TCtableModel[] = [];
                sortedUploadDrafts.push(...this.drafts.filter(d => d.tc1Status === TCConstant.UPLOAD || d.tc2Status === TCConstant.UPLOAD));
                sortedUploadDrafts.sort((a: TCtableModel, b: TCtableModel) => {
                    if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                        return -1;
                    } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                        return 1;
                    }
                    return 0;
                });
                const sortedRejectedDrafts: TCtableModel[] = [];
                sortedRejectedDrafts.push(...this.drafts.filter(d => d.tc1Status === TCConstant.REJECTED || d.tc2Status === TCConstant.REJECTED));
                sortedRejectedDrafts.sort((a: TCtableModel, b: TCtableModel) => {
                    if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                        return -1;
                    } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                        return 1;
                    }
                    return 0;
                });
                const sortedApprovedDrafts: TCtableModel[] = [];
                sortedApprovedDrafts.push(...this.drafts.filter(d => d.tc1Status === TCConstant.APPROVED || d.tc2Status === TCConstant.APPROVED));
                sortedApprovedDrafts.sort((a: TCtableModel, b: TCtableModel) => {
                    if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                        return -1;
                    } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                        return 1;
                    }
                    return 0;
                });
                sortedDrafts.push(...sortedUploadDrafts, ...sortedRejectedDrafts, ...sortedApprovedDrafts);
                this.drafts = cloneDeep(sortedDrafts);
                this.initialDrafts = cloneDeep(this.drafts);
                if(this.drafts.length > 0){
                    this.tcSections[1].isCollapse = true;
                }
            }else{
                this.tcSections[1].isCollapse = false;
            }

            const filteredFilnalTC =  this.orders.filter(order => (order.tc1Status === TCConstant.UPLOAD || order.tc2Status === TCConstant.UPLOAD  || order.tc1Status === TCConstant.APPROVED || order.tc2Status === TCConstant.APPROVED || order.tc1Status === TCConstant.REJECTED || order.tc2Status === TCConstant.REJECTED)
            && (order.tc1Type === TCType.FINALTC || order.tc2Type === TCType.FINALTC) && (order.tranCertificate1RefId !== '' || order.tranCertificate2RefId !== '') && order.toCompanyId!==Guid.Empty);
            if(filteredFilnalTC.length > 0){
                filteredFilnalTC.forEach(pr => {
                    const colour = pr.orderLines[0].colourway ? pr.orderLines[0].colourway : ''
                    if(pr.tranCertificate1 !== '' && pr.levelTC1 !== '' && pr.tc1Status !== TCConstant.PENDING && pr.tc1Status !== '' && pr.tc1Status !== TCConstant.ARCHIVED && pr.tc1Type === TCType.FINALTC){
                        const tc1Index = this.finalTC.findIndex(d => d.tranCertificate1RefId === pr.tranCertificate1RefId);
                        if(tc1Index === -1){
                            this.finalTC.push(new TCtableModel(pr.orderId,pr.tranCertificate1, '', pr.levelTC1, '', this.companyNameList.filter(c => c.ID === pr.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === pr.toCompanyId)[0].name : pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, pr.tranCertificate1RefId, '', pr.tc1Status, '', pr.tcReferenceNumber, '', pr.tc1Type, ''));
                        } else {
                            this.finalTC[tc1Index].brandNames.push(pr.brandName);
                            this.finalTC[tc1Index].orderNumbers.push(pr.orderNumber);
                            this.finalTC[tc1Index].styleNumbers.push(pr.styleNumber);
                            this.finalTC[tc1Index].styleNames.push(pr.styleName);
                            this.finalTC[tc1Index].colours.push(colour);
                            this.finalTC[tc1Index].linkedOrderIDs.push(pr.orderId);
                        }
                    }
                    if(pr.tranCertificate2!=='' && pr.levelTC2!=='' && pr.tc2Status!== TCConstant.PENDING && pr.tc2Status !== TCConstant.ARCHIVED  && pr.tc2Status !== '' && pr.tc2Type === TCType.FINALTC){
                        const tc2Index = this.finalTC.findIndex(d => d.tranCertificate2RefId === pr.tranCertificate2RefId);
                        if(tc2Index === -1){
                            this.finalTC.push(new TCtableModel(pr.orderId,'', pr.tranCertificate2, '', pr.levelTC2, this.companyNameList.filter(c => c.ID === pr.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === pr.toCompanyId)[0].name : pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, '', pr.tranCertificate2RefId, '', pr.tc2Status, '', pr.tcReferenceNumber1, '', pr.tc2Type));
                        } else {
                            this.finalTC[tc2Index].brandNames.push(pr.brandName);
                            this.finalTC[tc2Index].orderNumbers.push(pr.orderNumber);
                            this.finalTC[tc2Index].styleNumbers.push(pr.styleNumber);
                            this.finalTC[tc2Index].styleNames.push(pr.styleName);
                            this.finalTC[tc2Index].colours.push(colour);
                            this.finalTC[tc2Index].linkedOrderIDs.push(pr.orderId);
                        }
                    }
                })
                const sortedUploadFinalTcs: TCtableModel[] = [];
                sortedUploadFinalTcs.push(...this.finalTC.filter(d => d.tc1Status === TCConstant.UPLOAD || d.tc2Status === TCConstant.UPLOAD));
                sortedUploadFinalTcs.sort((a: TCtableModel, b: TCtableModel) => {
                    if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                        return -1;
                    } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                        return 1;
                    }
                    return 0;
                });
                const sortedRejectedFinalTcs: TCtableModel[] = [];
                sortedRejectedFinalTcs.push(...this.finalTC.filter(d => d.tc1Status === TCConstant.REJECTED || d.tc2Status === TCConstant.REJECTED));
                sortedRejectedFinalTcs.sort((a: TCtableModel, b: TCtableModel) => {
                    if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                        return -1;
                    } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                        return 1;
                    }
                    return 0;
                });
                const sortedApprovedFinalTcs: TCtableModel[] = [];
                sortedApprovedFinalTcs.push(...this.finalTC.filter(d => d.tc1Status === TCConstant.APPROVED || d.tc2Status === TCConstant.APPROVED));
                sortedApprovedFinalTcs.sort((a: TCtableModel, b: TCtableModel) => {
                    if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                        return -1;
                    } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                        return 1;
                    }
                    return 0;
                });
                const sortedFinalTcs: TCtableModel[] = [];
                sortedFinalTcs.push(...sortedUploadFinalTcs, ...sortedRejectedFinalTcs, ...sortedApprovedFinalTcs);
                this.finalTC = sortedFinalTcs;
                this.initialFinalTC = cloneDeep(this.finalTC);
                if(this.finalTC.length > 0){
                    this.tcSections[2].isCollapse = true;
                }
            }else{
                this.tcSections[2].isCollapse = false;
            }

            const filteredArchive = this.orders.filter(order => (order.tc1Status === TCConstant.ARCHIVED || order.tc2Status === TCConstant.ARCHIVED ) && order.toCompanyId!==Guid.Empty);
            if(filteredArchive.length > 0){
                filteredArchive.forEach(pr => {
                    const colour = pr.orderLines[0].colourway ? pr.orderLines[0].colourway : ''
                    if(pr.tranCertificate1!=='' && pr.levelTC1!=='' && pr.tc1Status === TCConstant.ARCHIVED){
                        const tc1Index = this.archive.findIndex(d => d.tranCertificate1RefId === pr.tranCertificate1RefId);
                        if(tc1Index === -1){
                            this.archive.push(new TCtableModel(pr.orderId,pr.tranCertificate1, '', pr.levelTC1, '', this.companyNameList.filter(c => c.ID === pr.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === pr.toCompanyId)[0].name : pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, pr.tranCertificate1RefId, '', pr.tc1Status, '', pr.tcReferenceNumber, '', pr.tc1Type, ''));
                        } else {
                            this.archive[tc1Index].brandNames.push(pr.brandName);
                            this.archive[tc1Index].orderNumbers.push(pr.orderNumber);
                            this.archive[tc1Index].styleNumbers.push(pr.styleNumber);
                            this.archive[tc1Index].styleNames.push(pr.styleName);
                            this.archive[tc1Index].colours.push(colour);
                            this.archive[tc1Index].linkedOrderIDs.push(pr.orderId);
                        }
                    }
                    if(pr.tranCertificate2!=='' && pr.levelTC2!=='' && pr.tc2Status === TCConstant.ARCHIVED){
                        const tc2Index = this.archive.findIndex(d => d.tranCertificate2RefId === pr.tranCertificate2RefId);
                        if(tc2Index === -1){
                            this.archive.push(new TCtableModel(pr.orderId,'', pr.tranCertificate2, '', pr.levelTC2, this.companyNameList.filter(c => c.ID === pr.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === pr.toCompanyId)[0].name : pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, '', pr.tranCertificate2RefId, '', pr.tc2Status, '', pr.tcReferenceNumber1, '', pr.tc2Type));
                        } else {
                            this.archive[tc2Index].brandNames.push(pr.brandName);
                            this.archive[tc2Index].orderNumbers.push(pr.orderNumber);
                            this.archive[tc2Index].styleNumbers.push(pr.styleNumber);
                            this.archive[tc2Index].styleNames.push(pr.styleName);
                            this.archive[tc2Index].colours.push(colour);
                            this.archive[tc2Index].linkedOrderIDs.push(pr.orderId);
                        }
                    }
                })
                this.initialArchive = cloneDeep(this.archive);
                // this.archive.forEach(pt => pt.isSelectedTC = false);
            }
        }
    }

    private formattedDate(date: string): string {
		return moment(date).format("D MMM YYYY");
	}

    private getBrands(brands: string[]): string {
        return uniq(brands).join(', ');
    }

    private getOrderNumbers(orderNumbers: string[]): string {
        return uniq(orderNumbers).join(', ');
    }

    private getStyleNumbers(styleNumbers: string[]): string {
        return uniq(styleNumbers).join(', ');
    }

    private getStyleNames(styleNames: string[]): string {
        return uniq(styleNames).join(', ');
    }

    private getColors(colours: string[]): string {
        return uniq(colours).join(', ');
    }

    private selectAll(index: number): void{
        if(index===0){
            this.tcSections[index].isSelectedAll = true;
            this.pendingTc.forEach(pt => {
                if(!pt.isSelectedTC){
                    pt.isSelectedTC = true;
                    this.selectedToCompnay.push(pt.toCompanyId);
                }
            });
        }else if(index === 1){
            this.tcSections[index].isSelectedAll = true;
            this.drafts.forEach(pt => {
                if(pt.tc1Status !== TCConstant.APPROVED && pt.tc1Status !== TCConstant.UPLOAD && pt.tc2Status !== TCConstant.APPROVED && pt.tc2Status!== TCConstant.UPLOAD ){
                    if(!pt.isSelectedTC){
                        pt.isSelectedTC = true;
                        this.selectedToCompnay.push(pt.toCompanyId);
                    }
                }
            });
        }else if(index === 2){
            this.tcSections[index].isSelectedAll = true;
            this.finalTC.forEach(pt => {
                if(pt.tc1Status !== TCConstant.APPROVED && pt.tc1Status !== TCConstant.UPLOAD && pt.tc2Status !== TCConstant.APPROVED && pt.tc2Status!== TCConstant.UPLOAD ){
                    if(!pt.isSelectedTC){
                        pt.isSelectedTC = true;
                        this.selectedToCompnay.push(pt.toCompanyId);
                    }
                }
            });
        }else{
            // this.tcSections[index].isSelectedAll = true;
            // this.archive.forEach(pt => {
            //     pt.isSelectedTC = true;
            //     this.selectedToCompnay.push(pt.toCompanyId);
            // });
        }
    }

    private deSelectAll(index: number): void{
        if(index===0){
            this.tcSections[index].isSelectedAll = false;
            this.pendingTc.forEach(pt => {
                if(this.selectedToCompnay.length > 0){
                    const index = this.selectedToCompnay.findIndex(s => s === pt.toCompanyId && pt.isSelectedTC);
                    if(index!==-1){
                        this.selectedToCompnay.splice(index, 1);
                    }
                }
                pt.isSelectedTC = false
            });
        }else if(index === 1){
            this.tcSections[index].isSelectedAll = false;
            this.drafts.forEach(pt => {
                if(this.selectedToCompnay.length > 0){
                    const index = this.selectedToCompnay.findIndex(s => s === pt.toCompanyId && pt.isSelectedTC);
                    if(index!==-1){
                        this.selectedToCompnay.splice(index, 1);
                    }
                }
                pt.isSelectedTC = false
            });
        }else if(index === 2){
            this.tcSections[index].isSelectedAll = false;
            this.finalTC.forEach(pt => {
                if(this.selectedToCompnay.length > 0){
                    const index = this.selectedToCompnay.findIndex(s => s === pt.toCompanyId && pt.isSelectedTC);
                    if(index!==-1){
                        this.selectedToCompnay.splice(index, 1);
                    }
                }
                pt.isSelectedTC = false
            });
        }
        // else{
        //     this.tcSections[index].isSelectedAll = false;
        //     this.archive.forEach(pt => pt.isSelectedTC = false);
        //     // this.selectedToCompnay = [];
        // }
    }

    private toggleSelectTc(sectionIndex:number, tc:TCtableModel, tcIndex:number): void{
        if(tc.tc1Status=== TCConstant.UPLOAD || tc.tc2Status === TCConstant.UPLOAD || tc.tc1Status === TCConstant.APPROVED
            || tc.tc2Status === TCConstant.APPROVED || tc.tc1Status === TCConstant.ARCHIVED || tc.tc2Status === TCConstant.ARCHIVED){
                return
        }
        tc.isSelectedTC=!tc.isSelectedTC;
        if(sectionIndex === 0){
            const tempTc= cloneDeep(tc);
            this.pendingTc.splice(tcIndex, 1);
            this.pendingTc.splice(tcIndex, 0, tempTc);
            if(this.pendingTc.every(pt => pt.isSelectedTC === true)){
                this.tcSections[sectionIndex].isSelectedAll = true;
            }else{
                this.tcSections[sectionIndex].isSelectedAll = false;
            }
            if(tempTc.isSelectedTC){
                this.selectedToCompnay.push(tempTc.toCompanyId);
            }else{
                if(this.selectedToCompnay.length > 0){
                    const toCompanyIndex = this.selectedToCompnay.findIndex(s => s === tempTc.toCompanyId);
                    if(toCompanyIndex!==-1){
                        this.selectedToCompnay.splice(toCompanyIndex,1);
                    }
                }
            }
        }else if(sectionIndex === 1){
            const tempTc= cloneDeep(tc);
            this.drafts.splice(tcIndex, 1);
            this.drafts.splice(tcIndex, 0, tempTc);
            if(this.drafts.every(pt => pt.isSelectedTC === true)){
                this.tcSections[sectionIndex].isSelectedAll = true;
            }else{
                this.tcSections[sectionIndex].isSelectedAll = false;
            }
            if(tempTc.isSelectedTC){
                this.selectedToCompnay.push(tempTc.toCompanyId);
            }else{
                if(this.selectedToCompnay.length > 0){
                    const toCompanyIndex = this.selectedToCompnay.findIndex(s => s === tempTc.toCompanyId);
                    if(toCompanyIndex!==-1){
                        this.selectedToCompnay.splice(toCompanyIndex,1);
                    }
                }
            }
        }else if(sectionIndex === 2){
            const tempTc= cloneDeep(tc);
            this.finalTC.splice(tcIndex, 1);
            this.finalTC.splice(tcIndex, 0, tempTc);
            if(this.finalTC.every(pt => pt.isSelectedTC === true)){
                this.tcSections[sectionIndex].isSelectedAll = true;
            }else{
                this.tcSections[sectionIndex].isSelectedAll = false;
            }
            if(tempTc.isSelectedTC){
                this.selectedToCompnay.push(tempTc.toCompanyId);
            }else{
                if(this.selectedToCompnay.length > 0){
                    const toCompanyIndex = this.selectedToCompnay.findIndex(s => s === tempTc.toCompanyId);
                    if(toCompanyIndex!==-1){
                        this.selectedToCompnay.splice(toCompanyIndex,1);
                    }
                }
            }
        }else{
            const tempTc= cloneDeep(tc);
            this.archive.splice(tcIndex, 1);
            this.archive.splice(tcIndex, 0, tempTc);
            if(this.archive.every(pt => pt.isSelectedTC === true)){
                this.tcSections[sectionIndex].isSelectedAll = true;
            }else{
                this.tcSections[sectionIndex].isSelectedAll = false;
            }
            if(tempTc.isSelectedTC){
                this.selectedToCompnay.push(tempTc.toCompanyId);
            }else{
                if(this.selectedToCompnay.length > 0){
                    const toCompanyIndex = this.selectedToCompnay.findIndex(s => s === tempTc.toCompanyId);
                    if(toCompanyIndex!==-1){
                        this.selectedToCompnay.splice(toCompanyIndex,1);
                    }
                }
            }
        }
    }

	private toggleComplianceDoc(index:number): void {
        if((index === 0 && this.pendingRequests.length > 0) || (index === 1 && this.drafts.length > 0 ) || (index === 2 && this.finalTC.length > 0 ) || (index === 3 && this.archive.length > 0 )){
            this.tcSections[index].isCollapse = !this.tcSections[index].isCollapse;
        }
    }

    private sortedList(sort:string, val: string, index:number) {
        let sortingOrders: TCtableModel[]= []
        if(index === 0){
            sortingOrders = this.pendingTc;
        }else if(index === 1){
            sortingOrders = this.drafts
        }else if(index === 2){
            sortingOrders = this.finalTC
        }else {
            sortingOrders = this.archive
        }
        sortingOrders.forEach(order => {
            if (order[val] && Array.isArray(order[val])) {
              order[val].sort((a:any, b:any) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
              });
            }
          });
        if(val!==''){
            this.tcSections[index].selectedSortOption = val;
            this.tcSections[index].sortType = sort;
            if(this.tcSections[index].selectedSortOption === 'date'){
              this.sortByDate(sortingOrders, index);
            }else{
                if(val==='tranCertificate1'){
                    return sortingOrders.sort((a, b) => {
                        const aValue = a.tranCertificate1 || a.tranCertificate2;
                        const bValue = b.tranCertificate1 || b.tranCertificate2;
                        if(sort === 'asc'){
                            this.tcSections[index].isSortAtoZ = true;
                            this.tcSections[index].isSortZtoA = false;
                            return aValue.localeCompare(bValue);
                        }
                        this.tcSections[index].isSortZtoA = true;
                        this.tcSections[index].isSortAtoZ = false;
                        return bValue.localeCompare(aValue);
                      });
                }else{
                    sortingOrders = sortingOrders.slice().sort((a, b) => {
                        if(this.tcSections[index].selectedSortOption){
                            if(sort === 'asc'){
                                this.tcSections[index].isSortAtoZ = true;
                                this.tcSections[index].isSortZtoA = false;
                              if(Array.isArray(a[this.tcSections[index].selectedSortOption]) && Array.isArray(b[this.tcSections[index].selectedSortOption])){
                                if (!isNaN(+a[this.tcSections[index].selectedSortOption][0].trim()) && !isNaN(+b[this.tcSections[index].selectedSortOption][0].trim())) {
                                    return +a[this.tcSections[index].selectedSortOption][0].trim() - +b[this.tcSections[index].selectedSortOption][0].trim();
                                  }
                                  return a[this.tcSections[index].selectedSortOption][0].trim().localeCompare(b[this.tcSections[index].selectedSortOption][0].trim());
                              }else{
                                if (!isNaN(+a[this.tcSections[index].selectedSortOption].trim()) && !isNaN(+b[this.tcSections[index].selectedSortOption].trim())) {
                                    return +a[this.tcSections[index].selectedSortOption].trim() - +b[this.tcSections[index].selectedSortOption].trim();
                                  }
                                  return a[this.tcSections[index].selectedSortOption].trim().localeCompare(b[this.tcSections[index].selectedSortOption].trim());
                              }
                            }else{
                                this.tcSections[index].isSortZtoA = true;
                                this.tcSections[index].isSortAtoZ = false;
                              if(Array.isArray(a[this.tcSections[index].selectedSortOption]) && Array.isArray(b[this.tcSections[index].selectedSortOption])){
                                if (!isNaN(+a[this.tcSections[index].selectedSortOption][0].trim()) && !isNaN(+b[this.tcSections[index].selectedSortOption][0].trim())) {
                                    return +b[this.tcSections[index].selectedSortOption][0].trim() - +a[this.tcSections[index].selectedSortOption][0].trim();
                                  }
                                  return b[this.tcSections[index].selectedSortOption][0].trim().localeCompare(a[this.tcSections[index].selectedSortOption][0].trim());
                              }else{
                                if (!isNaN(+a[this.tcSections[index].selectedSortOption].trim()) && !isNaN(+b[this.tcSections[index].selectedSortOption].trim())) {
                                    return +b[this.tcSections[index].selectedSortOption].trim() - +a[this.tcSections[index].selectedSortOption].trim();
                                  }
                                  return b[this.tcSections[index].selectedSortOption].trim().localeCompare(a[this.tcSections[index].selectedSortOption].trim());
                              }
                            }
                        }
                    });
                }
          }
          if(index === 0){
            this.pendingTc =  sortingOrders.slice(0, this.pendingTc.length);
          }else if(index === 1){
            this.drafts = sortingOrders.slice(0, this.drafts.length);
          }else if(index === 2){
            this.finalTC = sortingOrders.slice(0, this.finalTC.length);
          }else{
            this.archive = sortingOrders.slice(0, this.archive.length);
          }

        }
      }

      private sortByDate(sortingOrders:TCtableModel[], index:number) {
        if(this.tcSections[index].sortType === 'asc'){
            this.tcSections[index].isSortAtoZ = true;
            this.tcSections[index].isSortZtoA = false;
            sortingOrders = sortingOrders.sort((a: TCtableModel, b: TCtableModel) => {
            if (moment(a.delieveryDate).isBefore(b.delieveryDate)) {
              return -1;
            } else if (moment(a.delieveryDate).isAfter(b.delieveryDate)) {
              return 1;
            }
            return 0;
          });
        }else{
            this.tcSections[index].isSortZtoA = true;
            this.tcSections[index].isSortAtoZ = false;
            sortingOrders = sortingOrders.sort((a: TCtableModel, b: TCtableModel) => {
            if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
              return -1;
            } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
              return 1;
            }
            return 0;
          });
        }
        // this.pendingTc =  sortingOrders.slice(0, this.pendingTc.length);
    }

    private get isTCsExist(): boolean {
        if(this.pendingTc.length > 0 || this.drafts.length > 0 || this.finalTC.length > 0 || this.archive.length > 0){
            return true
        }
        return false;
    }

    private toggleDropdown(index:number) {
    this.tcSections[index].isOpen = !this.tcSections[index].isOpen;
    }

    private closeReminderPopup(): void {
		this.showReminderPopup = false;
        this.isShowSendRemainder = false;
	}

    private showPreview(): void{
        this.selectedToCompnay = uniqBy(this.selectedToCompnay, 'toCompanyID');
        this.showReminderPopup = true;
    }

    private showRemovePopup(pt:TCtableModel,index:number): void{
        this.selectedTCIndex = index;
        this.showDeleteDocModal = true;
        this.isShowSendRemainder = false;
    }

    private showArchivePopup(pt:TCtableModel, index:number, docIndex: number): void{
        this.selectedTCIndex = index;
        this.selectedDocument = pt;
        switch(docIndex){
            case 1:
                this.selectedTCType = TCType.DRAFT;
                break;
            case 2:
                this.selectedTCType = TCType.FINALTC;
                break;
            case 3:
                this.selectedTCType = TCConstant.ARCHIVED;
                break;
            default:
                this.selectedTCType = '';
        }
        this.showArchive = true;
        this.isShowSendRemainder = false;
    }

    private async archiveRequest(): Promise<void>{
        try{
            this.isDeleting = true;
            const request: updateTCStatusRequestModel = new updateTCStatusRequestModel();
            request.tranCertificateRefID = this.selectedDocument.tranCertificate1RefId !== '' ? this.selectedDocument.tranCertificate1RefId : this.selectedDocument.tranCertificate2RefId;
            request.tcStatus = TCConstant.ARCHIVED;
            request.tcType = this.selectedTCType;
            request.rejectionReason = '';
            request.orderList = [];
            request.tcReferenceNumber = this.selectedDocument.tcReferenceNumber !== '' ? this.selectedDocument.tcReferenceNumber : this.selectedDocument.tcReferenceNumber1;
            this.selectedDocument.linkedOrderIDs.forEach(id => {
                const order = this.orders.find(o => o.orderId === id);
                if(order){
                    const orderList = new orderListDetailsModel();
                    orderList.ID = order.orderId;
                    orderList.tcType = this.selectedTCType;
                    orderList.tcStatusValue = TCConstant.ARCHIVED;
                    orderList.tranCertificate = this.selectedDocument.tranCertificate1RefId !== '' ? '1' : '2';
                    orderList.tranCertificateRefID = this.selectedDocument.tranCertificate1RefId !== '' ? this.selectedDocument.tranCertificate1RefId : this.selectedDocument.tranCertificate2RefId;
                    request.orderList.push(orderList);
                }
            })
            const resp = await this.complianceService.approveRejectArchiveTC(request);
            if(resp.success){
                const storeOrders = this.$store.getters.orders as OrderLineDisplayModel[];
                resp.order.forEach(order => {
                    const orderIndex = storeOrders.findIndex(o => o.orderId === order.orderId);
                    if(orderIndex !== -1){
                        storeOrders.splice(orderIndex, 1, order);
                    }
                    store.commit('setAllOrders', storeOrders);
                });
                this.getFilteredOrders();
                this.showArchive = false;
                this.isDeleting = false;
            }
        }
        finally{
            this.showArchive = false;
            this.isDeleting = false;
        }
    }

    private async deletePendingRequest(): Promise<void>{
        try{
            this.isDeleting = true;
            const order = this.pendingRequests.filter(p => p.orderId === this.pendingTc[this.selectedTCIndex].orderId)[0];
            if(this.pendingTc[this.selectedTCIndex].tranCertificate1!==''){
                order.tranCertificate1 = ''
                order.levelTC1 = ''
            }
            if(this.pendingTc[this.selectedTCIndex].tranCertificate2!==''){
                order.tranCertificate2 = ''
                order.levelTC2 = ''
            }
            const data: any = {
                "orderID": order.orderId,
                "tranCertificate1": order.tranCertificate1,
                "tranCertificate2": order.tranCertificate2,
                "levelTC1": order.levelTC1,
                "levelTC2": order.levelTC2,
                "tc1Status": order.tc1Status,
                "tc2Status": order.tc2Status
            }
            const res = await this.complianceService.deletePendingTC(data);
            if(res){
                this.pendingTc.splice(this.selectedTCIndex, 1);
                this.showDeleteDocModal = false;
                this.isDeleting = false;
            }
        }
        finally{
            this.showDeleteDocModal = false;
            this.isDeleting = false;
        }
    }

    private showConfirmPopupForLinkedOrders(certificate: TCtableModel, docIndex: number, selectedTC: string, linkedOrders:number): void{
        // if(linkedOrders > 1){
        //     this.showConfirmPopupLinkedOrders = true;
        //     this.selectedCertificate = certificate;
        //     this.selectedTC = selectedTC;
        //     this.sectionIndex = docIndex;
        // }else{
            this.viewCertificate(certificate, docIndex, selectedTC);
        // }
    }

    private async viewCertificate(certificate: TCtableModel, docIndex: number, selectedTC: string): Promise<void> {
        this.showConfirmPopupLinkedOrders = false;
        this.selectedCertificate = certificate;
        this.selectedTC = selectedTC;
        switch(docIndex){
            case 1:
                this.selectedTCType = TCType.DRAFT;
                break;
            case 2:
                this.selectedTCType = TCType.FINALTC;
                break;
            case 3:
                this.selectedTCType = TCConstant.ARCHIVED;
                break;
            default:
                this.selectedTCType = '';
        }
        await this.getTCDocument(certificate);
        this.showCerts = true;
    }

    private async getTCDocument(document: TCtableModel): Promise<void> {
        let tcRefID: string = '';
        if(document.tranCertificate1RefId !== ''){
            tcRefID = document.tranCertificate1RefId;
        } else {
            tcRefID = document.tranCertificate2RefId;
        }
        const documentDetails = await this.complianceService.getViewTCDocument(tcRefID);
        if(documentDetails.success){
            this.documentDetails = documentDetails;
        }
    }

    private async getDocUrl(doc: TCtableModel): Promise<void> {
        await this.getTCDocument(doc);
        if(this.documentDetails.success && this.documentDetails.docUrl !== ''){
            const tcElem = document.getElementById('tcAnchor');
            if(tcElem){
                tcElem.click();
            }
        }
    }

    private async confirmApproveOrReject(confirmType:string, document: TCtableModel, rejectReason: string): Promise<void>{
        const request: updateTCStatusRequestModel = new updateTCStatusRequestModel();
        request.tranCertificateRefID = document.tranCertificate1RefId !== '' ? document.tranCertificate1RefId : document.tranCertificate2RefId;
        request.tcStatus = confirmType;
        request.tcType = this.selectedTCType;
        request.rejectionReason = rejectReason;
        request.orderList = [];
        request.tcReferenceNumber = document.tcReferenceNumber !== '' ? document.tcReferenceNumber : document.tcReferenceNumber1;
        document.linkedOrderIDs.forEach(id => {
            const order = this.orders.find(o => o.orderId === id);
            if(order){
                const orderList = new orderListDetailsModel();
                orderList.ID = order.orderId;
                orderList.tcType = this.selectedTCType;
                orderList.tcStatusValue = confirmType;
                orderList.tranCertificate = document.tranCertificate1RefId !== '' ? '1' : '2';
                orderList.tranCertificateRefID = document.tranCertificate1RefId !== '' ? document.tranCertificate1RefId : document.tranCertificate2RefId;
                request.orderList.push(orderList);
            }
        })
        const resp = await this.complianceService.approveRejectArchiveTC(request);
        if(resp.success){
            const storeOrders = this.$store.getters.orders as OrderLineDisplayModel[];
            resp.order.forEach(order => {
                const orderIndex = storeOrders.findIndex(o => o.orderId === order.orderId);
                if(orderIndex !== -1){
                    storeOrders.splice(orderIndex, 1, order);
                }
                store.commit('setAllOrders', storeOrders);
            });
            const mailRequest: TcMailModel = new TcMailModel();
            mailRequest.clientCompanyName = this.user.companyName;
            mailRequest.clientUserFirstName = this.user.firstName;
            mailRequest.clientUserLastName = this.user.lastName;
            mailRequest.tcDocType = this.selectedTCType;
            mailRequest.tcType = document.tranCertificate1 !== '' ? document.tranCertificate1 : document.tranCertificate2;
            mailRequest.tcReferenceNumber = document.tcReferenceNumber !== '' ? document.tcReferenceNumber : document.tcReferenceNumber1;
            const supplierTCOrder: SupplierOrderDataModel = {
                supplierID: document.toCompanyId,
                orderNumbers: document.orderNumbers.join(', ')
            }
            mailRequest.supplierOrderData.push(supplierTCOrder);
            const mailResponse = await this.clientService.sendTCMail(mailRequest, confirmType);
        }
        this.getFilteredOrders();
        this.showCerts = false;
    }

    private transcationCertificates(index:number): TCtableModel[]{
        if(index === 0){
            return this.pendingTc;
        }else if(index === 1){
            return this.drafts;
        }else if(index === 2){
            return this.finalTC;
        }else if(index === 3){
            return this.archive;
        }
        return []
    }

    private collapseSection(){
        if(this.pendingTc.length === 0){
            this.tcSections[0].isCollapse = false;
        }else{
            this.tcSections[0].isCollapse = true;
        }
        if(this.drafts.length === 0){
            this.tcSections[1].isCollapse = false;
        }else{
            this.tcSections[1].isCollapse = true;
        }
        if(this.finalTC.length === 0){
            this.tcSections[2].isCollapse = false;
        }else{
            this.tcSections[2].isCollapse = true;
        }
        if(this.archive.length === 0){
            this.tcSections[3].isCollapse = false;
        }else{
            this.tcSections[3].isCollapse = true;
        }
    }

    private getStatusOrder(status:string): number{
        return this.statusOrder.indexOf(status);
      }


    private cancelSendRemainder(): void{
        this.isShowSendRemainder = false;
        for(let i=0; i<=3; i++){
            this.deSelectAll(i);
        }
    }

    private isShowActionIcon(type:string): boolean{
        if(type === 'draft'){
            if(this.drafts.filter(d => (d.tc1Status === 'UPLOADED' || d.tc2Status === 'UPLOADED')).length > 0){
                return true;
            }else{
                return false;
            }
        }
        if(type === 'final'){
            if(this.finalTC.filter(d => (d.tc1Status === 'UPLOADED' || d.tc2Status === 'UPLOADED')).length > 0){
                return true;
            }else{
                return false;
            }
        }
        return false
    }

    private  getToolTip(val:string): string{
        if(val.length > 20){
            return val
        }
        return '';
    }

    @Watch("searchFilter", { deep: true })
    private selectedItem(): void {
        if(this.searchFilter!==''){
            const lowerCaseFilter = this.searchFilter.toLowerCase();
            if(this.pendingRequests.length > 0){
                this.pendingTc = this.initialPendingTC.filter((req) => req.tranCertificate1.toLowerCase().includes(lowerCaseFilter) || req.tranCertificate2.toLowerCase().includes(lowerCaseFilter)
                || req.partner.toLowerCase().includes(lowerCaseFilter) || req.brandNames.map(b => b.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNames.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNumbers.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter)
                || req.orderNumbers.map(o => o.toLowerCase()).join(',').includes(lowerCaseFilter) || req.colours.map(c => c.toLowerCase()).join(',').includes(lowerCaseFilter) || req.levelTC1.toLocaleLowerCase().includes(lowerCaseFilter) || req.levelTC2.toLocaleLowerCase().includes(lowerCaseFilter));
            }
            if(this.drafts.length > 0){
                this.drafts = this.initialDrafts.filter((req) => req.tranCertificate1.toLowerCase().includes(lowerCaseFilter) || req.tranCertificate2.toLowerCase().includes(lowerCaseFilter)
                || req.partner.toLowerCase().includes(lowerCaseFilter)
                || req.brandNames.map(b => b.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNames.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNumbers.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter)
                || req.orderNumbers.map(o => o.toLowerCase()).join(',').includes(lowerCaseFilter) || req.colours.map(c => c.toLowerCase()).join(',').includes(lowerCaseFilter) || req.levelTC1.toLocaleLowerCase().includes(lowerCaseFilter) || req.levelTC2.toLocaleLowerCase().includes(lowerCaseFilter)
                || req.tcReferenceNumber.toLocaleLowerCase().includes(lowerCaseFilter));
            }
            if(this.finalTC.length > 0){
                this.finalTC = this.initialFinalTC.filter((req) => req.tranCertificate1.toLowerCase().includes(lowerCaseFilter) || req.tranCertificate2.toLowerCase().includes(lowerCaseFilter)
                || req.partner.toLowerCase().includes(lowerCaseFilter)
                || req.brandNames.map(b => b.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNames.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNumbers.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter)
                || req.orderNumbers.map(o => o.toLowerCase()).join(',').includes(lowerCaseFilter) || req.colours.map(c => c.toLowerCase()).join(',').includes(lowerCaseFilter) || req.levelTC1.toLocaleLowerCase().includes(lowerCaseFilter) || req.levelTC2.toLocaleLowerCase().includes(lowerCaseFilter)
                || req.tcReferenceNumber.toLocaleLowerCase().includes(lowerCaseFilter));
            }
            if(this.archive.length > 0){
                this.archive = this.initialArchive.filter((req) => req.tranCertificate1.toLowerCase().includes(lowerCaseFilter) || req.tranCertificate2.toLowerCase().includes(lowerCaseFilter)
                || req.partner.toLowerCase().includes(lowerCaseFilter)
                || req.brandNames.map(b => b.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNames.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNumbers.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter)
                || req.orderNumbers.map(o => o.toLowerCase()).join(',').includes(lowerCaseFilter) || req.colours.map(c => c.toLowerCase()).join(',').includes(lowerCaseFilter) || req.levelTC1.toLocaleLowerCase().includes(lowerCaseFilter) || req.levelTC2.toLocaleLowerCase().includes(lowerCaseFilter)
                || req.tcReferenceNumber.toLocaleLowerCase().includes(lowerCaseFilter));
            }
        }else{
            this.pendingTc = this.initialPendingTC;
            this.drafts = this.initialDrafts;
            this.finalTC = this.initialFinalTC;
            this.archive = this.initialArchive;
        }
        this.collapseSection();
    }

    @Watch("selectedBrand", { deep: true })
    private filterBrand(): void {
        if(this.selectedBrand !== ""){
            if(this.initialPendingTC.length > 0){
                this.pendingTc = this.initialPendingTC.filter(req => req.brandNames.map(b => b.toLowerCase()).join(',').includes(this.selectedBrand.toLocaleLowerCase()));
            }
            if(this.initialDrafts.length > 0){
                this.drafts = this.initialDrafts.filter(req => req.brandNames.map(b => b.toLowerCase()).join(',').includes(this.selectedBrand.toLocaleLowerCase()));
            }
            if(this.initialFinalTC.length > 0){
                this.finalTC = this.initialFinalTC.filter(req => req.brandNames.map(b => b.toLowerCase()).join(',').includes(this.selectedBrand.toLocaleLowerCase()));
            }
            if(this.initialArchive.length > 0){
                this.archive = this.initialArchive.filter(req => req.brandNames.map(b => b.toLowerCase()).join(',').includes(this.selectedBrand.toLocaleLowerCase()));
            }
		}else{
            this.pendingTc = this.initialPendingTC;
            this.drafts = this.initialDrafts;
            this.finalTC = this.initialFinalTC;
            this.archive = this.initialArchive;
        }
        this.collapseSection();
    }

    @Watch("selectedPartner", { deep: true })
    private filterPartner(): void {
        if(this.selectedPartner !== ""){
            if(this.initialPendingTC.length > 0){
                this.pendingTc = this.initialPendingTC.filter(req => req.toCompanyId === this.selectedPartner);
            }
            if(this.initialDrafts.length > 0){
                this.drafts = this.initialDrafts.filter(req => req.toCompanyId === this.selectedPartner);
            }
            if(this.initialFinalTC.length > 0){
                this.finalTC = this.initialFinalTC.filter(req => req.toCompanyId === this.selectedPartner);
            }
            if(this.initialArchive.length > 0){
                this.archive = this.initialArchive.filter(req => req.toCompanyId === this.selectedPartner);
            }
		}else{
            this.pendingTc = this.initialPendingTC;
            this.drafts = this.initialDrafts;
            this.finalTC = this.initialFinalTC;
            this.archive = this.initialArchive;
        }
        this.collapseSection();
    }

    @Watch("selectedType", { deep: true })
    private filterType(): void {
        if(this.selectedType !== ""){
            const certificates = i18n.t(`enums.certificates_types`) as any;
            const result: DropdownModel[] = [];
            for (const key of Object.keys(certificates)) {
                const certifcateName = certificates[key];
                result.push({ value: key, text: certifcateName });
            }
            const filteredType = result.filter(r => r.value === this.selectedType)[0]
            if(this.initialPendingTC.length > 0){
                this.pendingTc = this.initialPendingTC.filter(req => req.tranCertificate1 === filteredType.text || req.tranCertificate2 === filteredType.text);
            }
            if(this.initialDrafts.length > 0){
                this.drafts = this.initialDrafts.filter(req => req.tranCertificate1 === filteredType.text || req.tranCertificate2 === filteredType.text);
            }
            if(this.initialFinalTC.length > 0){
                this.finalTC = this.initialFinalTC.filter(req => req.tranCertificate1 === filteredType.text || req.tranCertificate2 === filteredType.text);
            }
            if(this.initialArchive.length > 0){
                this.archive = this.initialArchive.filter(req => req.tranCertificate1 === filteredType.text || req.tranCertificate2 === filteredType.text);
            }
		}else{
            this.pendingTc = this.initialPendingTC;
            this.drafts = this.initialDrafts;
            this.finalTC = this.initialFinalTC;
            this.archive = this.initialArchive;
        }
        this.collapseSection();
    }
}
