import { CountryStateCityClient } from '@/clients/countryStateCityClient';
import { CountryListModel } from '@/models/countryLanguageModel';
import store from '@/store';

export class CountryStateCityService {
  private readonly client: CountryStateCityClient =
    new CountryStateCityClient();

  public async getAllCountries(): Promise<any> {
    try {
      const result: CountryListModel[] = await this.client.getAllCountries();
      store.commit('setCountryList', result);
      return result;
    } catch (error) {
      return [];
    }
  }

  public async getStatesOfCountry(ciso: string): Promise<any> {
    try {
      const result = await this.client.getStatesOfCountry(ciso);
      return result;
    } catch (error) {
      return [];
    }
  }

  public async getCitiesOfState(ciso: string, siso: string): Promise<any> {
    try {
      const result = await this.client.getCitiesOfState(ciso, siso);
      return result;
    } catch (error) {
      return [];
    }
  }
}
