import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

// State
import { IState } from './state';

// Models
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { UserModel } from '@/models/userModel';
import { FootprintPartnerModel, OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { OrderModel } from '@/models/orderModel';
import { CompanyModel, CompanyNameModel } from '@/models/companyModel';
import { SupplierCertificateModal } from '@/models/supplierCertificateModal';
import { SocialEffortModal } from '@/models/socialEffortModal';
import { ClientRelatedSupplierModel } from '@/models/clientRelatedSupplierModel';
import { UserPermissionModel, UserRolePermissionsModel } from '@/models/permissionModel';
import { Actions } from './actions/actions';
import { TransparencyEffortModal } from '@/models/transparencyEffortModal';
import { ComplianceDocResponseModal } from '@/models/complianceDocResponseModal';
import { ComplianceDocSentResponseModel } from '@/models/complianceDocSentResponseModel';
import { BlockchainAccessModal } from '@/models/blockchainAccessModal';
import { CountryListModel } from '@/models/countryLanguageModel';
import { CPSCPModel, PartnersModel, UniqueSupplierWithStepModel } from '@/models/supplierPartnerModel';
import { BrandWithSupplierModel, SupplierPartnersBrandsModel } from '../models/brandWithSupplierModel';
import { SocialCertificatesModel } from '@/models/socialCertificatesModel';
import { SustainableCertificatesModel } from '@/models/sustainableCertificatesModel';
import { CertificateModel } from '@/models/certificateModel';
import { DropdownModel } from '@/models/dropdownModel';
import { EsgEnvModel, EsgSocialModel } from "@/models/esgModel";
import { MandatoryStepRules } from '@/models/mandatoryStepsModel';

Vue.use(Vuex);

export default new Vuex.Store<IState>({
  state: {
    isAppOnline: window.navigator.onLine,
    orders: [],
    company: null,
    user: null,
    orderLineSteps: [],
    suppliers: [],
    pendingOrders: [],
    profile: null,
    orderConfirmed: false,
    orderLoading: false,
    orderLineStepsLoading: false,
    totalQrCodeLive: 0,
    pendingFootprintCount: 0,
    totalStyles: 0,
    suppliersCertificate: [],
    socialEfforts: [],
    transparencyEfforts: [],
    socialCertificates: [],
    sustainableCertificate: [],
    clientRelatedSupplierIds: [],
    supplierCompanies: [],
    canNavigate: true,
    userRole: "",
    userPermissions: null,
    documents: [],
    documentsLoading: false,
    sentArchiveDocs: [],
    sentArchiveDocsLoading: false,
    uniqueSuppliersListLoading: false,
    deletedOrderIds: [],
    suppliersLoading: false,
    suppliersCertificateLoading: false,
    socialEffortsLoading: false,
    waitingT1OrderStepsLoading: false,
    inprogressOrderStepsLoading: false,
    completedOrderStepsLoading: false,
    agentStepsUpdated: false,
    companyType: 0,
    encryptPwd: '',
    collapsedSidebar: false,
    changedFootprintName: '',
    isFootprintChanged: "",
    showFootprintConfirmation: "",
    footprintPartner: [],
    accessToken: null,
    isAgentPilot: false,
    countryList: [],
    incorrectFootprintData: false,
    t1AssociatedPartners: [],
    agentAssociatedPartners: [],
    supplierPartnersBrands: null,
    associatedPartners: [],
    brandWithT1SCP: [],
    brandWithAgent: [],
    brandWithSCP: [],
    companyNameList: [],
    clientPartners: [],
    esgSocial: [],
    esgDataLoading: false,
    esgEnv: [],
    mandatoryStepRules: null
  },
  mutations: {
    setInitialState(state: IState): void {
      state.orders = [],
      state.company = null,
      state.user = null,
      state.orderLineSteps = [],
      state.suppliers = [],
      state.pendingOrders = [],
      state.profile = null,
      state.orderConfirmed = false,
      state.orderLoading = false,
      state.orderLineStepsLoading = false,
      state.totalQrCodeLive = 0,
      state.pendingFootprintCount = 0,
      state.totalStyles = 0,
      state.suppliersCertificate = [],
      state.socialEfforts = [],
      state.transparencyEfforts = [],
      state.socialCertificates = [],
      state.sustainableCertificate = [],
      state.clientRelatedSupplierIds = [],
      state.supplierCompanies = [],
      state.canNavigate = true,
      state.documentsLoading = false,
      state.sentArchiveDocs = [],
      state.sentArchiveDocsLoading = false,
      state.uniqueSuppliersListLoading = false,
      state.deletedOrderIds = [],
      state.suppliersLoading = false,
      state.suppliersCertificateLoading = false,
      state.socialEffortsLoading = false,
      state.waitingT1OrderStepsLoading = false,
      state.inprogressOrderStepsLoading = false,
      state.completedOrderStepsLoading = false,
      state.agentStepsUpdated = false,
      state.encryptPwd = '',
      state.userRole = '',
      state.collapsedSidebar = false,
      state.changedFootprintName = '',
      state.isFootprintChanged = '',
      state.showFootprintConfirmation = '',
      state.footprintPartner = [],
      state.accessToken = null,
      state.isAgentPilot = false,
      state.countryList = [],
      state.incorrectFootprintData = false,
      state.t1AssociatedPartners = [],
      state.agentAssociatedPartners = [],
      state.supplierPartnersBrands = null,
      state.associatedPartners = [],
      state.brandWithT1SCP = [],
      state.brandWithAgent = [],
      state.brandWithSCP = [],
      state.companyNameList = [],
      state.clientPartners = [],
      state.esgSocial = [],
      state.esgDataLoading = false,
      state.esgEnv = [],
      state.mandatoryStepRules = null
    },
    setOrderLoading(state: IState, orderLoading: boolean){
      state.orderLoading = orderLoading;
    },
    setOrderLineStepsLoading(state: IState, orderLineStepsLoading: boolean){
      state.orderLineStepsLoading = orderLineStepsLoading;
    },
    setDocumentsLoading(state: IState, documentsLoading: boolean){
      state.documentsLoading = documentsLoading;
    },
    setSentArchiveDocsLoading(state: IState, sentArchiveDocsLoading: boolean){
      state.sentArchiveDocsLoading = sentArchiveDocsLoading;
    },
    setUniqueSuppliersListLoading(state: IState, uniqueSuppliersListLoading: boolean){
      state.uniqueSuppliersListLoading = uniqueSuppliersListLoading;
    },
    setAgentStepsUpdated(state: IState, isUpdated: boolean) {
      state.agentStepsUpdated = isUpdated;
    },
    setOrders(state: IState, orders: OrderLineDisplayModel[]): void {
      const ordersClone = _.cloneDeep(state.orders);
      ordersClone.push(...orders);
      state.orders = ordersClone;
    },
    setCanNavigate(state: IState, status: boolean): void {
      state.canNavigate = status;
    },
    setMyAccount(state: IState, company: CompanyModel): void {
      state.company = company;
    },
    setSteps(state: IState, steps: OrderLineStepDisplayModel[]): void {
      const orderLineSteps = _.cloneDeep(state.orderLineSteps);
      orderLineSteps.push(...steps);
      state.orderLineSteps = orderLineSteps;
    },
    setUser(state: IState, user: UserModel): void {
      state.user = user;
    },
    setOrderConfirmed(state: IState, orderConfirmed: boolean): void {
      state.orderConfirmed = orderConfirmed;
    },
    setSuppliers(state: IState, suppliers: OrderCompanyModel[]): void {
      state.suppliers = suppliers;
    },
    setPendingOrders(state: any, pendingOrders: OrderModel[]): void {
      state.pendingOrders = pendingOrders;
    },
    setProfile(state: IState, profile: CompanyModel): void{
      state.profile = profile;
    },
    setQrCodeCount(state: IState, totalQrCodeLive: number): void{
      let totalQrCodeLiveClone = state.totalQrCodeLive;
      totalQrCodeLiveClone += totalQrCodeLive;
      state.totalQrCodeLive = totalQrCodeLiveClone;
    },
    setPendingFootprintCount(state: IState, count: number): void{
      //Sprint-22 TTD-4212
      // let pendingFootprintClone = state.pendingFootprintCount;
      // pendingFootprintClone += count;
      state.pendingFootprintCount = count;
    },
    setTotalStyleCount(state: IState, totalStyles: number): void{
      let totalStylesClone = state.totalStyles;
      totalStylesClone += totalStyles;
      state.totalStyles = totalStylesClone;
    },
    setSuppliersCertificate(state: IState, suppliersCertificate: SupplierCertificateModal[]): void {
      state.suppliersCertificate = suppliersCertificate;
    },
    setSocialEfforts(state: IState, socialEfforts: SocialEffortModal[]): void {
      const socialEffortsClone = _.cloneDeep(state.socialEfforts);
      socialEffortsClone.push(...socialEfforts);
      state.socialEfforts = socialEffortsClone;
    },
    setDocuments(state: IState, documents: ComplianceDocResponseModal[]): void {
      state.documents = documents;
    },
    setSentArchiveDocs(state: IState, sentArchiveDocs: ComplianceDocSentResponseModel[]): void {
      state.sentArchiveDocs = sentArchiveDocs;
    },
    setTransparencyEfforts(state: IState, transparencyEfforts: TransparencyEffortModal[]): void {
      const transparencyEffortsClone = _.cloneDeep(state.transparencyEfforts);
      transparencyEffortsClone.push(...transparencyEfforts);
      state.transparencyEfforts = transparencyEffortsClone;
    },
    setSocialCertificates(state: IState, socialCertificates: CertificateModel[]): void {
      state.socialCertificates = socialCertificates;
    },
    setSustainableCertificate(state: IState, sustainableCertificate: CertificateModel[]): void {
      state.sustainableCertificate = sustainableCertificate;
    },
    setClientRelatedSupplierIds(state: IState, clientRelatedSupplierIds: string[]): void {
      state.clientRelatedSupplierIds = clientRelatedSupplierIds;
    },
    setSupplierCompanies(state: IState, supplierCompanies: ClientRelatedSupplierModel[]): void {
      state.supplierCompanies = supplierCompanies;
    },
    setUserRolePermissions(state: IState, userRolePermissions: UserRolePermissionsModel): void {
      if (userRolePermissions !== null) {
        state.userRole = userRolePermissions.groupName;
        state.userPermissions = new UserPermissionModel(userRolePermissions.permissions);
      } else {
        state.userRole = '';
        state.userPermissions = null;
      }
    },
    setAllOrders(state: IState, orders: OrderLineDisplayModel[]): void {
      state.orders = orders;
    },
    setAllOrderlineSteps(state: IState, steps: OrderLineStepDisplayModel[]): void {
      state.orderLineSteps = steps;
    },
    setAllTransparencyEfforts(state: IState, transparencyEfforts: TransparencyEffortModal[]): void {
      state.transparencyEfforts = transparencyEfforts;
    },
    setSocialEffortsLoading(state: IState, isLoading: boolean): void {
      state.socialEffortsLoading = isLoading;
    },
    setAllSocialEfforts(state: IState, socialEfforts: SocialEffortModal[]): void {
      state.socialEfforts = socialEfforts;
    },
    setAllStyleCount(state: IState, styleCount: number): void {
      state.totalStyles = styleCount;
    },
    setAllQrCodeCount(state: IState, qrCodeCount: number): void {
      state.totalQrCodeLive = qrCodeCount;
    },
    setDeletedOrderIds(state: IState, orderIds: string[]): void {
      state.deletedOrderIds = orderIds;
    },
    setSuppliersCertificateLoading(state: IState, isCertificateLoading: boolean): void {
      state.suppliersCertificateLoading = isCertificateLoading;
    },
    setWaitingT1OrderStepsLoading(state: IState, isLoading: boolean): void {
      state.waitingT1OrderStepsLoading = isLoading;
    },
    setInprogressOrderStepsLoading(state: IState, isLoading: boolean): void {
      state.inprogressOrderStepsLoading = isLoading;
    },
    setCompletedOrderStepsLoading(state: IState, isLoading: boolean): void {
      state.completedOrderStepsLoading = isLoading;
    },
    setCompanyType(state: IState, companyType: number): void {
      state.companyType = companyType === undefined ? 0 : companyType;
    },
    setEncryptedPwd(state: IState, encryptedPwd: string): void {
      state.encryptPwd = encryptedPwd;
    },
    setCollapsedSidebar(state: IState, isSideBarCollapsed: boolean): void {
      state.collapsedSidebar = isSideBarCollapsed;
    },
    setChangedFootprintName(state: IState, changedFootprintName: string): void {
      state.changedFootprintName = changedFootprintName;
    },
    setFootprintChanged(state: IState, isFootprintChanged: string): void {
      state.isFootprintChanged = isFootprintChanged;
    },
    setFootprintConfirmation(state: IState, showFootprintConfirmation: string): void {
      state.showFootprintConfirmation = showFootprintConfirmation;
    },
    setFootprintPartner(state: IState, footprintPartner: FootprintPartnerModel[]): void {
      state.footprintPartner = footprintPartner;
    },
    setAccessToken(state: IState, accessToken: BlockchainAccessModal): void {
      state.accessToken = accessToken;
    },
    setSuppliersLoading(state: IState, isLoading: boolean): void {
      state.suppliersLoading = isLoading;
    },
    setAgentPilotStatus(state: IState, isAgentPilot: boolean): void {
      state.isAgentPilot = isAgentPilot;
    },
    setCountryList(state: IState, countryList: CountryListModel[]): void {
      state.countryList = countryList;
    },
    // Sprint-22 TTD-4290
    setIncorrectFootprintData(state: IState, incorrectFootprintData: boolean): void {
      state.incorrectFootprintData = incorrectFootprintData;
    },
    // Sprint-23 TTD-4339
    setT1AssociatedPartners(state: IState, t1SCPpartners: CPSCPModel[]): void {
      state.t1AssociatedPartners = t1SCPpartners;
    },
    setAgentAssociatedPartners(state: IState, agentPartners: CPSCPModel[]): void {
      state.agentAssociatedPartners = agentPartners;
    },
    setSupplierPartnersBrands(state: IState, supplierPartnersBrands: SupplierPartnersBrandsModel): void {
      state.supplierPartnersBrands = supplierPartnersBrands;
    },
    setAssociatedPartners(state: IState, associatedPartners: PartnersModel): void {
      state.associatedPartners.push(associatedPartners);
    },
    setBrandWithT1SCP(state: IState, brandWithT1SCP: BrandWithSupplierModel[]): void {
      state.brandWithT1SCP = brandWithT1SCP;
    },
    setBrandWithAgent(state: IState, brandWithAgent: BrandWithSupplierModel[]): void {
      state.brandWithAgent = brandWithAgent;
    },
    setBrandWithSCP(state: IState, brandWithSCP: BrandWithSupplierModel[]): void {
      state.brandWithSCP = brandWithSCP;
    },
    setCompanyNameList(state: IState, companyName: CompanyNameModel[]): void {
      state.companyNameList = companyName;
    },
    setClientPartners(state: IState, clientPartners: DropdownModel[]): void {
      state.clientPartners = clientPartners;
    },
    setEsgSocialData(state: IState, esgSocial: EsgSocialModel[]): void{
      state.esgSocial = esgSocial;
    },
    setEsgDataLoading(state: IState, bool:boolean): void{
      state.esgDataLoading = bool
    },
    setEsgEnvData(state: IState, esgEnv: EsgEnvModel[]): void{
      state.esgEnv = esgEnv;
    },
    setMandatoryStepRules(state: IState, stepRules: MandatoryStepRules): void {
      state.mandatoryStepRules = stepRules;
    }
  },
  getters: {
    // eslint-disable-next-line
    orderLoading: state => {
      return state.orderLoading;
    },
    uniqueSuppliersListLoading: state => {
      return state.uniqueSuppliersListLoading;
    },
    orderLineStepsLoading: state => {
      return state.orderLineStepsLoading;
    },
    orders: state => {
      return state.orders;
    },
    canNavigate: state => {
      return state.canNavigate;
    },
    userRole: state => {
      return state.userRole;
    },
    // eslint-disable-next-line
    company: (state) => {
      return state.company;
    },
    getAgentStepsUpdated: state => {
      return state.agentStepsUpdated
    },
    // eslint-disable-next-line
    ordersCount: state => {
      return state.orders.length;
    },
    // eslint-disable-next-line
    orderLineSteps: state => {
      return state.orderLineSteps;
    },
    // eslint-disable-next-line
    user: state => {
      return state.user;
    },
    orderConfirmed: state => {
      return state.orderConfirmed;
    },
    // eslint-disable-next-line
    suppliers: state => {
      return state.suppliers;
    },
    // eslint-disable-next-line
    pendingOrders: state => {
      return state.pendingOrders;
    },
    // eslint-disable-next-line
    profile: state => {
      return state.profile;
    },
    // eslint-disable-next-line
    qrCodeCount: state => {
      return state.totalQrCodeLive;
    },
    // eslint-disable-next-line
    pendingFootprintCount: state => {
      return state.pendingFootprintCount;
    },
    // eslint-disable-next-line
    totalStyleCount: state => {
      return state.totalStyles;
    },
    // eslint-disable-next-line
    suppliersCertificate: state => {
      return state.suppliersCertificate;
    },
    // eslint-disable-next-line
    socialEfforts: state => {
      return state.socialEfforts;
    },
    // eslint-disable-next-line
    documents: state => {
      return state.documents;
    },
    // eslint-disable-next-line
    transparencyEfforts: state => {
      return state.transparencyEfforts;
    },
    // eslint-disable-next-line
    socialCertificates: state => {
      return state.socialCertificates;
    },
    // eslint-disable-next-line
    sustainableCertificate: state => {
      return state.sustainableCertificate;
    },
    // eslint-disable-next-line
    clientRelatedSupplierIds: state => {
      return state.clientRelatedSupplierIds;
    },
    // eslint-disable-next-line
    supplierCompanies: state => {
      return state.supplierCompanies;
    },
    // eslint-disable-next-line
    userPermissions: state => {
      return state.userPermissions;
    },
    // eslint-disable-next-line
    companyType: state => {
      return state.companyType;
    },
    // eslint-disable-next-line
    encryptPwd: state => {
      return state.encryptPwd;
    },
    // eslint-disable-next-line
    isFootprintChanged: state => {
      return state.isFootprintChanged;
    },
    // eslint-disable-next-line
    footprintPartner: state => {
      return state.footprintPartner;
    },
    // eslint-disable-next-line
    accessToken: state => {
      return state.accessToken;
    },
    // eslint-disable-next-line
    t1AssociatedPartners: state => {
      return state.t1AssociatedPartners;
    },
    // eslint-disable-next-line
    agentAssociatedPartners: state => {
      return state.agentAssociatedPartners;
    },
    // eslint-disable-next-line
    supplierPartnersBrands: state => {
      return state.supplierPartnersBrands;
    },
    // eslint-disable-next-line
    associatedPartners: state => {
      return state.associatedPartners
    },
    // eslint-disable-next-line
    esgSocial: state => {
      return state.esgSocial
    },
    // eslint-disable-next-line
    esgDataLoading: state => {
      return state.esgDataLoading
    },
    esgEnv: state => {
      return state.esgEnv
    },
    mandatoryStepRules: state => {
      return state.mandatoryStepRules
    }
  },
  actions: {
    setUserRolePermissions: Actions.setUserRolePermissions,
    setOrderDetail: Actions.setOrderDetail,
    setOrderlineSteps: Actions.setOrderlineSteps,
    // setWaitingT1OrderlineSteps: Actions.setWaitingT1OrderlineSteps,
    // setInprogressOrderlineSteps: Actions.setInprogressOrderlineSteps,
    // setCompletedOrderlineSteps: Actions.setCompletedOrderlineSteps,
    setUniqueSupplierList: Actions.setUniqueSupplierList,
    setDefaultOrderState: Actions.setDefaultOrderState,
    removePendingOrders: Actions.removePendingOrders,
    removeNonPendingOrders: Actions.removeNonPendingOrders,
    setDocuments: Actions.setDocuments,
    setSentArchiveDocs: Actions.setSentArchiveDocs,
    setSuppliersCertificate: Actions.setSuppliersCertificate,
    setSocialEfforts: Actions.setSocialEfforts,
    setMyAccount: Actions.setMyAccount,
    setAgentPilotPermission: Actions.setAgentPilotPermission,
    setAssociatedPartners: Actions.setAssociatedPartners,
    getClientRelatedSuppliers: Actions.getClientRelatedSuppliers,
    setPartnersWithBrands: Actions.setPartnersWithBrands,
    setCompanyNameList: Actions.setCompanyNameList,
    setEsgSocialData: Actions.setEsgSocialData,
    setEsgEnvData: Actions.setEsgEnvData,
    setMandatoryStepRules: Actions.setMandatoryStepRules
  },
  modules: {
  }
});
