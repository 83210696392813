import { ContactModel } from "./contactModel";
import { Guid } from "./guid";

export class FromCompanyUserModel {

    public constructor (ID: string, contact: ContactModel, userId: string) {
        this.ID = ID;
        this.email = contact.email;
        this.firstName = contact.firstName;
        this.geoLat = 0;
        this.geoLong = 0;
        this.lastName = contact.lastName;
        this.name = contact.name;
        this.userId = userId;
    }

    public ID!: string;
    public email!: string;
    public firstName!: string;
    public geoLat!: number;
    public geoLong!: number;
    public lastName!: string;
    public name!: string;
    public userId!: string;
}