import { DashboardApiAuthenticator } from '@/authenticators/dashboardApiAuthenticator';
import { ApplicationConfig } from '@/config';
import { UserModel, UserModelRequest, UserModelResponse } from '@/models/userModel';
import { BlockchainAccessModal } from '@/models/blockchainAccessModal';
import { BaseClient } from './baseClient';
import { CompanyUsersModel } from '@/models/companyUserModel';

export class UserClient extends BaseClient {
    private readonly endPoint: string = "api/user";

    public constructor() {
        super(ApplicationConfig.endpoints.dashboardApi, new DashboardApiAuthenticator());
    }

    public async getUserInfoAsync(): Promise<UserModel> {
        return await this.get<UserModel>(`${this.endPoint}/info`);
    }

    public async getUserInfoByIdAsync(userId: string): Promise<UserModel> {
        return await this.get<UserModel>(`${this.endPoint}/info/${userId}`);
    }

    public async getBlockchainToken(): Promise<BlockchainAccessModal> {
        return await this.get<BlockchainAccessModal>(`${this.endPoint}/getToken`);
    }

    public async updateUser(userDetails: UserModelRequest): Promise<UserModelResponse> {
        return await this.putWithResponse<UserModelResponse>(`${this.endPoint}/updateUserInBlockchainAndAzureAD`, userDetails);
    }

    public async getAllCompanyUsers(companyId: string): Promise<CompanyUsersModel> {
        return await this.get<CompanyUsersModel>(`${this.endPoint}/getAllUsersOfCompany/${companyId}`);
    }

}