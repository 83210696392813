import { ApplicationConfig } from '@/config';
import { ComplianceResponseModal } from '@/models/complianceResponseModal';
import { ConfirmComplianceDocumentModal } from '@/models/confirmComplianceDocumentModal';
import { ClientRelatedSupplierResponseModel } from '@/models/clientRelatedSupplierResponseModel';
import { BaseClientBlockchain } from './baseClientBlockchain';
import { OrderLineStepResponseModel, OrderStepsResponseModel } from '@/models/orderLineStepDisplayModel';
import { SocialEffortResponseModel } from '@/models/socialEffortModal';
import { DeleteOrderRequestModel, DeleteOrderResponseModel } from '@/models/deleteOrderRequestModel';
import { OrdersWithStepsModal } from '@/models/ordersWithStepsModel';
import { EditOrderModel, EditOrderResponseModel } from '@/models/bulkOrderModel';
import { FootprintDataResponseModel } from '@/models/footprintModel';
import { GenericResponseModel } from '@/models/genericResponseModel';
import { PartnerRequestModel, PartnerResponseModel, SupplierPartnersBrandsRespModel } from '@/models/supplierPartnerModel';
import { OrderPictureQualityReportModel } from '@/models/orderModel';
import { SupplierAddressResponseModel, SuppliersListModel } from '@/models/supplierModel';
import { SupplierPartnersBrandsModel } from '@/models/brandWithSupplierModel';
import { InvitedCompaniesModel } from '@/models/invitedCompaniesModel';
import { TcConfirmResponseModel, TcResponseModel } from '@/models/tcResponseModel';
import { TCDocumentModel } from '@/models/transcationCertificateModal';
import { updateTCStatusRequestModel } from '@/models/transactionCertsModel';
import { CompanyNameResponseModel } from '@/models/companyModel';

export class ClientBlockchain extends BaseClientBlockchain {
    private readonly endPoint: string = "v1/api";

    public constructor() {
        super(ApplicationConfig.endpoints.blockchainApi);
    }

    public async getOrdersAsync(clientId: string,bookmark: string, pageSize: number, brands: string): Promise<OrdersWithStepsModal> {
        const encodedBrands: string = encodeURIComponent(brands);
        return await this.get(`${this.endPoint}/client/${clientId}/dashboard?etdFromDate=2020-01-01&bookmark=${bookmark}&pageSize=${pageSize}&phase=0,1,2&status=0,1,2,3,4,5,6&brands=${encodedBrands}`);
    }

    public async getOrdersForAgentAsync(clientId: string): Promise<OrdersWithStepsModal> {
        return await this.get(`${this.endPoint}/agent/${clientId}/agentDashboard`);
    }

    // Sprint 24, TTD-4471
    public async confirmTcAsync(data: object): Promise<TcResponseModel> {
        return await this.putWithResponse<TcResponseModel>(`${this.endPoint}/order/updateTCData`, data, false);
    }

    public async uploadDocAsync(uploadData: FormData): Promise<ComplianceResponseModal> {
        return await this.postWithResponse(`${this.endPoint}/compliance/uploadDoc`, uploadData, true);
    }

    public async getDocumentsAsync(companyId: string): Promise<ComplianceResponseModal>{
        return await this.get<ComplianceResponseModal>(`${this.endPoint}/company/${companyId}/getComplainceDoc`);
    }

    public async confirmComplianceDocumentAsync(companyId: string, data: ConfirmComplianceDocumentModal): Promise<ComplianceResponseModal>{
        return await this.postWithResponse(`${this.endPoint}/client/${companyId}/createComplianceDoc`, data, false);
    }

    public async createRecepientDocAsync(companyId: string, data: ConfirmComplianceDocumentModal): Promise<ComplianceResponseModal>{
        return await this.putWithResponse(`${this.endPoint}/createComplianceDoc`, data, false);
    }

    public async getSentDocumentsAsync(): Promise<ComplianceResponseModal>{
        return await this.get<ComplianceResponseModal>(`${this.endPoint}/complianceDoc/client`);
    }

    public async archiveComplianceDocumentAsync(companyId: string, data: object): Promise<Response>{
        return await this.patch(`${this.endPoint}/company/${companyId}/complianceDoc/archieve`, data);
    }

    public async updateNotifyDaysAsync(data: object): Promise<Response>{
        return await this.patch(`${this.endPoint}/complianceDoc/notifyDays`, data);
    }

    public async getActivityLogAsync(clientId: string, supplierId: string): Promise<ComplianceResponseModal>{
        return await this.get<ComplianceResponseModal>(`${this.endPoint}/complianceDoc/client/${clientId}/supplier/${supplierId}/activityLog?pageSize=500&bookmark=`);
    }

    // Sprint 23 - TTD-3647
    public async getCompanyFromNameAsync(company: string): Promise<InvitedCompaniesModel>{
        return await this.get<InvitedCompaniesModel>(`${this.endPoint}/company/${company}/search`);
    }

    // Sprint 23 - TTD-3647
    public async getCompanyFromRegAsync(reg: string): Promise<InvitedCompaniesModel>{
        return await this.get<InvitedCompaniesModel>(`${this.endPoint}/company/regNumber/${reg}/search`);
    }

    public async setSuppliersListAsync(suppliers: string[], clientId: string): Promise<ClientRelatedSupplierResponseModel>{
        return await this.put<ClientRelatedSupplierResponseModel>(`${this.endPoint}/client/${clientId}/report/supplierList`, suppliers);
    }

    // response used in supplier report
    public async setSuppliersListForReportAsync(suppliers: string[], clientId: string): Promise<ClientRelatedSupplierResponseModel>{
        return await this.put<ClientRelatedSupplierResponseModel>(`${this.endPoint}/client/${clientId}/report/allSupplierList`, suppliers);
    }

    public uploadProfilePictureAsync( userId: string, data: FormData ): Promise<Response> {
        return this.postWithResponse( `${this.endPoint}/user/${userId}/profilePics`, data, true);
    }

    public updateProfilePictureAsync( userId: string, data: FormData ): Promise<Response> {
        return this.putWithResponse( `${this.endPoint}/user/${userId}/updateProfilePics`, data, true );
    }

    public deleteCompolianceDoc( clientId: string, ID: string ): Promise<GenericResponseModel> {
        return this.putWithResponse<GenericResponseModel>( `${this.endPoint}/client/${clientId}/complianceDoc/${ID}/delete`, [], false );
    }

    public deleteSentCompolianceDoc( ID: string, scpId: string ): Promise<GenericResponseModel> {
        return this.putWithResponse<GenericResponseModel>( `${this.endPoint}/supplier/${scpId}/complianceDoc/${ID}/delete`, [], false );
    }

    public deleteProfilePictureAsync( userId: string, profileId: string ): Promise<Response> {
        return this.delete( `${this.endPoint}/user/${userId}/deleteProfilePics/${profileId}` );
    }

    // api to fetch steps for all orders
    public async getAllStepsAsync(clientId: string): Promise<OrderLineStepResponseModel> {
        return this.get<OrderLineStepResponseModel>(`${this.endPoint}/client/${clientId}/orderlineSteps/allStepsForClient`);
    }

    // api to fetch social effort data for dashboard graph
    public async getSocialEffort(supplierIds: string[], clientId: string): Promise<SocialEffortResponseModel> {
        return this.putWithResponse<SocialEffortResponseModel>(`${this.endPoint}/client/${clientId}/social/socialEffort`, supplierIds, false);
    }

    // api to delete orders at once
    public async deleteOrderAsync(data: DeleteOrderRequestModel): Promise<DeleteOrderResponseModel> {
        return this.deleteWithData<DeleteOrderResponseModel>(`${this.endPoint}/order/deleteOrder`, data);
    }

    // api to update order style-name, colour, season, composition
    public async updateOrdersAsync(clientId: string, data: EditOrderModel[]): Promise<EditOrderResponseModel> {
        return this.putWithResponse<EditOrderResponseModel>(`${this.endPoint}/client/${clientId}/updateOrerLineData`, data, false);
    }

    // api to get all partners of contractual partner
    public async getPartners(data: PartnerRequestModel): Promise<PartnerResponseModel> {
        return this.putWithResponse<PartnerResponseModel>(`${this.endPoint}/invitedSupliers/getAllScp`, data, false);
    }
    // api to get product picture & product quality report on order basis
    public async getPictureQualityReportAsync(orderId: string): Promise<OrderPictureQualityReportModel> {
        return this.get<OrderPictureQualityReportModel>(`${this.endPoint}/order/${orderId}/getOrder`);
    }

    // api to get suppliers list and supplier certificate list
    public async getSuppliersListAsync(id: string): Promise<SuppliersListModel>{
        return this.get<SuppliersListModel>(`${this.endPoint}/client/${id}/dashboard/getSupplierList`);
    }

    // api to get suppliers list for agent
    public async getAgentSuppliersListAsync(id: string): Promise<SuppliersListModel>{
        return this.get<SuppliersListModel>(`${this.endPoint}/agent/${id}/dashboard/getSupplierList`);
    }

    // TTD-3853 api to get steps on order id from blockchain
    public async getStepsOnOrderID(orderIds: string[]): Promise<OrderStepsResponseModel> {
        return this.putWithResponse<OrderStepsResponseModel>(`${this.endPoint}/orderlineStep/onOrderID`, orderIds, false);
    }
    // Sprint-24 TTD-4433
    public deletePendingTC( data:any): Promise<GenericResponseModel> {
        return this.putWithResponse<GenericResponseModel>( `${this.endPoint}/order/updateTCData`, data, false );
    }
    // Sprint-24 TTD-4484
    public async approveRejectArchiveTC( tcData: updateTCStatusRequestModel): Promise<TcConfirmResponseModel> {
        return this.patchWithResponse<TcConfirmResponseModel>( `${this.endPoint}/transaction-certificate/updateTCStatus`, tcData, false );
    }
    // Sprint-24 TTD-4484
    public async getViewTCDocument(tcRefID: string): Promise<TCDocumentModel> {
        return this.get<TCDocumentModel>(`${this.endPoint}/transaction-certificate/${tcRefID}/getTranCertificate`);
    }

    // TTD-3941 api to get supplier company address details
    public async getSupplierAddressAsync(companyID: string): Promise<SupplierAddressResponseModel> {
        return this.get<SupplierAddressResponseModel>(`${this.endPoint}/company/${companyID}/companyData/footprintReport`);
    }

    // TTD-3691 get company name
    public async getCompanyNameAsync(): Promise<CompanyNameResponseModel> {
        return this.get(`${this.endPoint}/company/redis/getAllCompany`);
    }
}
