import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { mapState } from 'vuex';
import lodash, { indexOf } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import _ from 'underscore';

// Components
import VuePdfApp from "vue-pdf-app";

// Services
import { ClientRequestFootPrintData } from "@/services/footPrintRequestService";

// Helpers
import { FootprintDropdownHelper } from "@/helpers/footprintDropdownHelper";
import { CountryHelper } from "@/helpers/countryHelper";
import { FootprintPermissionsHelper } from "@/helpers/footprintPermissionsHelper";

// Modals
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { EnergySupportingDocumentModel, FiberModel, YarnFootprintModel, YarnFootprintRequestModel, YarnModel,  YarnStepsDataModel} from "@/models/yarnFootprintModel";
import { UserModel } from "@/models/userModel";
import { DropdownModel } from "@/models/dropdownModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { FootprintPartnerModel, OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { DeleteDocRequestModel, FootPrintChangedConstant, FootPrintChangedTabConstant, FootPrintCompletedConstant, FootPrintConstant, FootprintDataResponseModel } from "@/models/footprintModel";
import { ModeOfTransportModel, TransportFootprintModel, TransportFootprintRequestModel, TransportStepsDataModel } from "@/models/transportFootprintModel";
import { Guid } from "@/models/guid";
import { FootprintStepsModel, StepReferenceModel } from "@/models/orderLineStepModel";

@Component({
    components: { VuePdfApp },
	computed: mapState(['orderLoading', 'companyType', 'orders', 'suppliers', 'user', 'inprogressOrderStepsLoading', 'completedOrderStepsLoading', 'footprintPartner', 'userRole', 'isFootprintChanged'])
})
export default class YarnFootprint extends Vue {

	@Prop()
	private orderId!: string;

	@Prop()
	private deadline!: string;

	// @Prop()
	// private steps!: OrderLineStepDisplayModel[];

	@Prop()
	private allFootprintSteps!: StepReferenceModel[];

	@Prop()
	private activeStep!: string;
	//Spint-22 TTD-4212
	@Prop()
	private tabIndex!: number;

	private companyType!: number;

	private clientRequestFootPrintData: ClientRequestFootPrintData;

	private orders!: OrderLineDisplayModel[];

	private inprogressOrderStepsLoading!: boolean;

	private completedOrderStepsLoading!: boolean;

	private footprintPartner!: FootprintPartnerModel[];

	private user!: UserModel;

	private suppliers!: OrderCompanyModel[];

	private orderRelatedPartners: OrderCompanyModel[] = [];

	private yarnFootprint: YarnFootprintModel[] = [];

	private yarnFootprintClone: YarnFootprintModel[] = [];

	private yarnIndex: number = -1;

	private spinningMethodList: DropdownModel[] = [];

	private unitList: DropdownModel[] = [];

	private fiberList: DropdownModel[] = [];

	private fiberOriginList: DropdownModel[] = [];

	// private showAddYarn: boolean = true;

	// private showAddYarnFiber: boolean = true;

	private showYarnFootprint: boolean = false;

	private showUploadDocModal: boolean = false;

	private docFile: any = "";

	private isDocUploading: boolean = false;

	private yarnFpChangedCounter: number = 0;

	private yarnTpChangedCounter: number = 0;

	private docPreview: EnergySupportingDocumentModel = new EnergySupportingDocumentModel();

	private pdfDocument!: any;

    private isPdfReady: boolean = false;

	private showYarnTransport: boolean = false;

	private yarnTransport: TransportFootprintModel[] = [];

	private yarnTransportClone: TransportFootprintModel[] = [];

	private yarnTransportCloneInitialState: TransportFootprintModel[] = [];

	private yarnFootprintCloneInitialState: YarnFootprintModel[] = [];

	private modeOfTransportList: DropdownModel[] = [];

	private transportUnitList: DropdownModel[] = [];

	private disableSave: boolean = false;

	private showWarningModal: boolean = false;

	private isSaving: boolean = false;

	private isDeletingDoc: boolean = false;

	private deletedDocID: string = "";

	private footprintStatus: string = 'red';

	private transportStatus: string = 'red';

	private showDeleteModal: boolean = false;

	private isTabRemoved: boolean = false;

	private yarnDeleIndex: number = 0;

	private yarnlDeleDocIndex: number = 0;

	private userRole!: string;

	private transportToBeDeleted: boolean = false;

	private isFootprintChanged!: string;

	private userCompanyId!: string;

	private yarnFieldEmpty: string = "";

	private transportFieldEmpty: boolean = false;

	private footprintOrder!: OrderLineDisplayModel;

    private config: any = {
        toolbar: false
    };

	private showPdfPreviewModal: boolean = false;

	private FpDeletedCounter: number = 0;

	private TpDeletedCounter: number = 0;

	private guidEmpty: string = Guid.Empty;

	private yarnWrapperData: YarnStepsDataModel[] = [];

	private transportWrapperData: TransportStepsDataModel[] = [];

	private yarnSteps: number = 0;

	private isYarnTradingStep: boolean = false;

	private showNoYarnStepMsg: boolean = false; // TTD-3934

	private activeYarnStep: string = Guid.Empty;

	public constructor() {
		super();
		this.clientRequestFootPrintData = new ClientRequestFootPrintData();
	}

	private async created(): Promise<void> {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		this.userCompanyId = this.user.companyId !== undefined && this.user.companyId !== '' ? this.user.companyId : Guid.Empty;
		this.spinningMethodList = FootprintDropdownHelper.getSpinningMethodList();
		this.unitList = FootprintDropdownHelper.getUnitList();
		this.fiberList = FootprintDropdownHelper.getFiberTypeList();
		this.fiberOriginList = lodash.orderBy(CountryHelper.getCountryList(),['text'],['asc']);
		this.modeOfTransportList = FootprintDropdownHelper.getModeOfTransportList();
		this.transportUnitList = FootprintDropdownHelper.getTransportUnit();
		const allOrders = lodash.cloneDeep(this.orders);
		this.footprintOrder = allOrders.filter(o => o.orderId === this.orderId)[0];
		if(this.footprintOrder.yarn.footprint.length > 0){
			this.yarnFootprint = lodash.cloneDeep(this.footprintOrder.yarn.footprint);
			this.yarnFootprint.forEach((yarn, index) => {
				yarn.toCompanyUser.isValid = true;
				yarn.reference.isValid = true;
				yarn.spinning.isValid = true;
				yarn.yarnCount.isValid = true;
				if(yarn.yarnCount.count === -1){
					yarn.yarnCount.count = '';
				}
				yarn.yarnUnit.isValid = true;
				if(yarn.fiber === null || yarn.fiber.length === 0){
					yarn.fiber = [new FiberModel()];
					yarn.fiber[0].ID = uuidv4();
				}
				yarn.fiber.forEach(fiber => {
					fiber.fiberType.isValid = true;
					fiber.fiberValue.isValid = true;
					fiber.originOfFiber.isValid = true;
					if(fiber.fiberValue.value === -1){
						fiber.fiberValue.value = '';
					}
				});
				yarn.total.isValid = true;
				if(yarn.total.totalcount === -1){
					yarn.total.totalcount = '';
				}
				yarn.totalElectricEnergy.isValid = true;
				if(yarn.totalElectricEnergy.value === -1){
					yarn.totalElectricEnergy.value = '';
				}
				yarn.windEnergy.isValid = true;
				if(yarn.windEnergy.value === -1){
					yarn.windEnergy.value = '';
				}
				yarn.solarEnergy.isValid = true;
				if(yarn.solarEnergy.value === -1){
					yarn.solarEnergy.value = '';
				}
				if(index === 0){
					yarn.showYarn = true;
				} else {
					yarn.showYarn = false;
				}
			});
		}
		if(this.footprintOrder.yarn.transport.length > 0){
			this.yarnTransport = this.footprintOrder.yarn.transport;
			this.yarnTransport.forEach((transport, index) => {
				transport.toCompanyUser.isValid = true;
				transport.modeOfTransport.forEach(mode => {
					if(mode.distance.value === -1){
						mode.distance.value = '';
					}
					mode.distance.isValid = true;
					mode.distanceUnit.isValid = true;
					mode.transportType.isValid = true;
				})
			})
		}
		this.createYarnCards();
		this.yarnFootprintClone = lodash.cloneDeep(this.yarnFootprint);
		this.yarnTransportClone = lodash.cloneDeep(this.yarnTransport);
		this.yarnFootprintCloneInitialState = lodash.cloneDeep(this.yarnFootprint);
		this.yarnTransportCloneInitialState = lodash.cloneDeep(this.yarnTransport);
		this.yarnFpStatus();
		this.yarnTpStatus();
		this.checkNonEmptyFields();
	}

	private createYarnCards(): void {
		const stepSrNo = this.allFootprintSteps.filter(a => a.stepId === 3).length > 0 ? 3 : this.allFootprintSteps.filter(a => a.stepId === 22 ).length > 0 ? 22 : -1;
		// const stepSrNoPC = this.allFootprintSteps.filter(a => a.stepId === 3 && a.pcStepUniqueId !== Guid.Empty).length > 0 ? 3 : this.allFootprintSteps.filter(a => a.stepId === 22 && a.pcStepUniqueId !== Guid.Empty).length > 0 ? 22 : -1;
		if(stepSrNo === -1){
			this.showNoYarnStepMsg = true;
		} else {
			const mcStep = this.allFootprintSteps.filter(a => a.stepId === 3 && a.pcStepUniqueId === Guid.Empty).length > 0 ? this.allFootprintSteps.filter(a => a.stepId === 3 && a.pcStepUniqueId === Guid.Empty) : this.allFootprintSteps.filter(a => a.stepId === 22 && a.pcStepUniqueId === Guid.Empty).length > 0 ? this.allFootprintSteps.filter(a => a.stepId === 22 && a.pcStepUniqueId === Guid.Empty) : [];
			if(mcStep.length > 0){
				this.defaultYarnWrapper(mcStep[0]);
				this.defaultYarnTpWrapper(mcStep[0]);
			}
			const pcSteps = this.allFootprintSteps.filter(a => a.pcStepUniqueId !== Guid.Empty);
			if(pcSteps.length > 0){
				const pcStepsUnique = lodash.uniqBy(pcSteps, 'mcStepUniqueId');
				pcStepsUnique.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1);
				pcStepsUnique.forEach(step => {
					const pc0allStep = pcSteps.filter(a => a.mcStepUniqueId === step.mcStepUniqueId && a.pcOfMC === 0);
					if(pc0allStep.length > 0){
						const pc0Step = pc0allStep.filter(a => a.stepId === 3).length > 0 ? pc0allStep.filter(a => a.stepId === 3) : pc0allStep.filter(a => a.stepId === 22).length > 0 ? pc0allStep.filter(a => a.stepId === 22) : [];
						if(pc0Step.length > 0){
							this.defaultYarnWrapper(pc0Step[0]);
							this.defaultYarnTpWrapper(pc0Step[0]);
						}
						const pc1allStep = pcSteps.filter(a => a.mcStepUniqueId === step.mcStepUniqueId && a.pcOfMC === 1);
						if(pc1allStep.length > 0){
							const pc1Step = pc1allStep.filter(a => a.stepId === 3).length > 0 ? pc1allStep.filter(a => a.stepId === 3) : pc1allStep.filter(a => a.stepId === 22).length > 0 ? pc1allStep.filter(a => a.stepId === 22) : [];
							if(pc1Step.length > 0){
								this.defaultYarnWrapper(pc1Step[0]);
								this.defaultYarnTpWrapper(pc1Step[0]);
							}
							const pc2allStep = pcSteps.filter(a => a.mcStepUniqueId === step.mcStepUniqueId && a.pcOfMC === 2);
							if(pc2allStep.length > 0){
								const pc2Step = pc2allStep.filter(a => a.stepId === 3).length > 0 ? pc2allStep.filter(a => a.stepId === 3) : pc2allStep.filter(a => a.stepId === 22).length > 0 ? pc2allStep.filter(a => a.stepId === 22) : [];
								if(pc2Step.length > 0){
									this.defaultYarnWrapper(pc2Step[0]);
									this.defaultYarnTpWrapper(pc2Step[0]);
								}
								const pc3allStep = pcSteps.filter(a => a.mcStepUniqueId === step.mcStepUniqueId && a.pcOfMC === 3);
								if(pc3allStep.length > 0){
									const pc3Step = pc3allStep.filter(a => a.stepId === 3).length > 0 ? pc3allStep.filter(a => a.stepId === 3) : pc3allStep.filter(a => a.stepId === 22).length > 0 ? pc3allStep.filter(a => a.stepId === 22) : [];
									if(pc3Step.length > 0){
										this.defaultYarnWrapper(pc3Step[0]);
										this.defaultYarnTpWrapper(pc3Step[0]);
									}
									const pc4allStep = pcSteps.filter(a => a.mcStepUniqueId === step.mcStepUniqueId && a.pcOfMC === 4);
									if(pc4allStep.length > 0){
										const pc4Step = pc4allStep.filter(a => a.stepId === 3).length > 0 ? pc4allStep.filter(a => a.stepId === 3) : pc4allStep.filter(a => a.stepId === 22).length > 0 ? pc4allStep.filter(a => a.stepId === 22) : [];
										if(pc4Step.length > 0){
											this.defaultYarnWrapper(pc4Step[0]);
											this.defaultYarnTpWrapper(pc4Step[0]);
										}
									}
								}
							}
						}
					}
				});
			}
			this.showYarnFootprint = true;
			if (this.yarnWrapperData.length>0) {
				this.yarnWrapperData[0].showWrapper = true;
				const yarnSteps = this.yarnFootprint.filter(y => y.stepID === this.yarnWrapperData[0].stepID);
				yarnSteps.sort((a, b)=> a.sequence < b.sequence ? -1 : 1);
				const yarnIndex = this.yarnFootprint.findIndex(yf => yf.ID === yarnSteps[0].ID);
				this.yarnFootprint[yarnIndex].showYarn = true;
			}
		}
		this.showYarnFootprint = true;
	}

	private defaultYarnWrapper(step: StepReferenceModel): void {
		this.yarnWrapperData.push(new YarnStepsDataModel());
		const lastIndex = this.yarnWrapperData.length - 1;
		this.yarnWrapperData[lastIndex].stepID = step.pcStepUniqueId !== Guid.Empty ? step.pcStepUniqueId : step.mcStepUniqueId;
		this.yarnWrapperData[lastIndex].referenceNo = step.refNo;
		this.yarnWrapperData[lastIndex].supplierName = step.toCompanyName;
		this.yarnWrapperData[lastIndex].supplierCompanyId = step.toCompanyId;
		this.yarnWrapperData[lastIndex].showWrapper = false;
		this.yarnWrapperData[lastIndex].referenceNoPrefix = step.footprintRefNoPrefix;
		if((this.yarnFootprint.length === 0) || (this.yarnFootprint.length > 0 && this.yarnFootprint.filter(y => y.stepID === this.yarnWrapperData[lastIndex].stepID).length === 0)){
			this.defaultYarnFootprint(step);
		}
	}

	private defaultYarnTpWrapper(step: StepReferenceModel): void {
		this.transportWrapperData.push(new TransportStepsDataModel());
		const lastIndex = this.transportWrapperData.length - 1;
		this.transportWrapperData[lastIndex].stepID = step.pcStepUniqueId !== Guid.Empty ? step.pcStepUniqueId : step.mcStepUniqueId;
		this.transportWrapperData[lastIndex].referenceNo = step.refNo;
		this.transportWrapperData[lastIndex].supplierName = step.toCompanyName;
		this.transportWrapperData[lastIndex].showWrapper = false;
		this.transportWrapperData[lastIndex].referenceNoPrefix = step.footprintRefNoPrefix;
		this.transportWrapperData[lastIndex].supplierCompanyId = step.toCompanyId;
		if((this.yarnTransport.length === 0) || (this.yarnTransport.length > 0 && this.yarnTransport.filter(y => y.stepID === this.yarnWrapperData[lastIndex].stepID).length === 0)){
			this.defaultYarnTransport(step);
		}
	}

	private getIndex(stepId: string, id: string): number {
		const relevantYarns = this.yarnFootprint.filter(y => y.stepID === stepId);
		return relevantYarns.findIndex(r => r.ID === id)+1;
	}

	private async mounted(): Promise<void> {
		this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
			if(collapseId === 'yarn-0'){
				if(this.yarnFootprint.length > 0){
					const yarn = this.yarnFootprint[0];
					yarn.showYarn = isJustShown;
					this.yarnFootprint.splice(0, 1);
					this.yarnFootprint.splice(0, 0, yarn);
				}
			}
			if(collapseId === 'transport-0'){
				if(this.yarnTransport.length > 0){
					const transport = this.yarnTransport[0];
					if(transport !== undefined){
						transport.showTransport = isJustShown;
						this.yarnTransport.splice(0, 1);
						this.yarnTransport.splice(0, 0, transport);
					}
				}
			}
		});
	}

	private get agentPermissions(): boolean {
		return FootprintPermissionsHelper.agentFootprintRole(this.companyType);
	}

	private isFilledByPrimarySource(filledBy: string, toCompanyID: string): boolean {
		//Spint-22 TTD-4212
		if(this.tabIndex === 1){
			return true;
		}
		if(this.user && this.user.companyType === 1){
			if((filledBy !== Guid.Empty || filledBy !== '') && (filledBy === toCompanyID) && (toCompanyID !== this.userCompanyId)){
				return true;
			}
			return false;
		} else {
			if (filledBy === Guid.Empty || filledBy === '') {
				return false;
			} else if((filledBy !== Guid.Empty || filledBy !== '') && (filledBy === this.userCompanyId || this.userCompanyId === toCompanyID)) {
				return false;
			} else {
				return true;
			}
		}
		// return filledBy !== Guid.Empty && filledBy !== '' && filledBy === toCompanyID ? filledBy === this.userCompanyId ? false : true : false;
	}

	// private getAgentPermissions(filledBy: string): boolean {
	// 	return this.companyType === 5 ? FootprintPermissionsHelper.getAgentFootprintRole(this.companyType, filledBy, this.userCompanyId): false;
	// }

	private canDeleteYarn(yarn: YarnFootprintModel): boolean {
		const wrapper = this.yarnWrapperData.filter(w => w.stepID === yarn.stepID);
		if(wrapper.length > 0){
			if(this.userCompanyId === wrapper[0].supplierCompanyId || yarn.filledByCompanyID === this.userCompanyId){
				return true;
			}
		}
		return false;
	}

	private getYarnLength(stepID: string): number {
		return this.yarnFootprint.filter(y => y.stepID === stepID).length;
	}

	private canDeleteYarnFiber(fiber: FiberModel, yarn: YarnFootprintModel): boolean {
		const wrapper = this.yarnWrapperData.filter(w => w.stepID === yarn.stepID);
		if(wrapper.length > 0){
			if(this.userCompanyId === wrapper[0].supplierCompanyId || fiber.filledByCompanyID === this.userCompanyId){
				return true;
			}
		}
		return false;
	}

	private canDeleteModeTrnsprt(mode: ModeOfTransportModel, wrapperStepID: string): boolean {
		const wrapper = this.yarnWrapperData.filter(yw => yw.stepID === wrapperStepID);
		if(this.userCompanyId === wrapper[0].supplierCompanyId || (mode.distance.filledByCompanyID === this.userCompanyId && mode.distanceUnit.filledByCompanyID === this.userCompanyId && mode.transportType.filledByCompanyID === this.userCompanyId)){
			return true;
		}
		return false;
	}

	private isFiledByClient(filledBy: string): boolean {
		if(this.footprintOrder.fromCompanyUser.ID === filledBy && this.tabIndex !==1){
			return true;
		}
		return false;
	}

	private defaultYarnFootprint(step: StepReferenceModel): void {
		this.yarnFootprint.push(new YarnFootprintModel());
		const lastIndex = this.yarnFootprint.length - 1;
		this.yarnFootprint[lastIndex].ID = uuidv4();
		this.yarnFootprint[lastIndex].stepID = step.pcStepUniqueId !== Guid.Empty ? step.pcStepUniqueId : step.mcStepUniqueId;
		this.yarnFootprint[lastIndex].toCompanyUser.toCompanyID = step.toCompanyId;
		this.yarnFootprint[lastIndex].toCompanyUser.toCompanyName = step.toCompanyName;
		this.yarnFootprint[lastIndex].toCompanyUser.filledByCompanyID = this.userCompanyId;
		this.yarnFootprint[lastIndex].toCompanyUser.filledByPrimarySource = step.toCompanyId === this.userCompanyId ? true : false;
		this.yarnFootprint[lastIndex].fiber[0].ID = uuidv4();
		this.yarnFootprint[lastIndex].fiber[0].filledByCompanyID = this.userCompanyId;
		this.yarnFootprint[lastIndex].fiber[0].filledByPrimarySource = step.toCompanyId === this.userCompanyId ? true : false;
		this.yarnFootprint[lastIndex].filledByCompanyID = this.userCompanyId;
		this.yarnFootprint[lastIndex].filledByPrimarySource = step.toCompanyId === this.userCompanyId ? true : false;
		this.yarnFootprint[lastIndex].showYarn = true;
		this.yarnFootprint[lastIndex].status = FootPrintCompletedConstant.INCOMPLETE;
		const yarnFootprints = this.yarnFootprint.filter(y => y.stepID === this.yarnFootprint[lastIndex].stepID);
		if(yarnFootprints.length > 1){
			this.yarnFootprint[lastIndex].sequence = yarnFootprints[yarnFootprints.length - 1].sequence + 1;
		} else {
			this.yarnFootprint[lastIndex].sequence = 1;
		}
	}

	private defaultYarnTransport(step: StepReferenceModel): void {
		const yarnTp: TransportFootprintModel = new TransportFootprintModel;
		yarnTp.ID = uuidv4();
		yarnTp.stepID = step.pcStepUniqueId !== Guid.Empty ? step.pcStepUniqueId : step.mcStepUniqueId;
		yarnTp.footprintID = Guid.Empty;
		yarnTp.modeOfTransport.push(new ModeOfTransportModel());
		yarnTp.toCompanyUser.toCompanyID = step.toCompanyId;
		yarnTp.toCompanyUser.toCompanyName = step.toCompanyName;
		yarnTp.toCompanyUser.filledByCompanyID = this.userCompanyId;
		yarnTp.toCompanyUser.filledByPrimarySource = step.toCompanyId === this.userCompanyId ? true : false;
		yarnTp.filledByCompanyID = this.userCompanyId;
		yarnTp.filledByPrimarySource = step.toCompanyId === this.userCompanyId ? true : false;
		yarnTp.status = FootPrintCompletedConstant.INCOMPLETE;
		this.yarnTransport.push(yarnTp);
		this.checkTransportEmptyFields();
	}

	private yarnFpStatus(): void {
		// const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		if(this.footprintOrder.yarn.footprintStatus === FootPrintCompletedConstant.COMPLETED){
			this.footprintStatus = 'green';
		}
		else if(this.footprintOrder.yarn.footprintStatus === FootPrintCompletedConstant.PARTIAL){
			this.footprintStatus = 'orange';
		}
		else{
			this.footprintStatus = 'red';
		}
		// this.yarnWrapperData.forEach(yw => {
		// 	if (this.yarnFootprint.filter(yt => yt.stepID === yw.stepID).length === this.yarnFootprint.filter(yt => yt.stepID === yw.stepID && yt.status === FootPrintCompletedConstant.COMPLETED).length) {
		// 		yw.footprintStatus = 'green';
		// 	} else if(this.yarnFootprint.filter(yt => yt.stepID === yw.stepID && yt.status === FootPrintCompletedConstant.PARTIAL).length > 0) {
		// 		yw.footprintStatus = 'orange';
		// 	} else {
		// 		yw.footprintStatus = 'red';
		// 	}
		// })
	}

	private yarnTpStatus(): void {
		this.transportWrapperData.forEach(tr => {
			if (this.yarnTransport.filter(yt => yt.stepID === tr.stepID).length === this.yarnTransport.filter(yt => yt.stepID === tr.stepID && yt.status === FootPrintCompletedConstant.COMPLETED).length) {
				tr.transportStatus = 'green';
			} else if(this.yarnTransport.filter(yt => yt.stepID === tr.stepID && yt.status === FootPrintCompletedConstant.PARTIAL).length > 0) {
				tr.transportStatus = 'orange';
			} else {
				tr.transportStatus = 'red';
			}
		})
		// const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		if(this.footprintOrder.yarn.transportStatus === FootPrintCompletedConstant.COMPLETED){
			let tpEmptyField = false;
			this.yarnTransport.forEach(tp => {
				tp.modeOfTransport.forEach(mode => {
					if(mode.transportType.value === '' || mode.distance.value === '' || mode.distanceUnit.unit === ''){
						tpEmptyField = true;
					}
				});
			});
			if(tpEmptyField){
				this.transportStatus = 'orange';
			}
			else{
				this.transportStatus = 'green';
			}
		}
		else if(this.footprintOrder.yarn.transportStatus === FootPrintCompletedConstant.PARTIAL){
			this.transportStatus = 'orange';
		}
		else{
			this.transportStatus = 'red';
		}
	}

	private showAddYarn(stepID: string): boolean {
		return this.yarnFootprint.filter(yf => yf.stepID === stepID).length < 6 ? true : false;
	}

	private addYarn(yarnWrapper: YarnStepsDataModel): void {
		if(this.isSaving){
			return;
		}
		const yarnUuid = uuidv4();
		const fiberUuid = uuidv4();
		let yarnSequence = -1;
		this.yarnFootprint.push(new YarnFootprintModel());
		this.yarnFootprint.forEach((yarn, index) => {
			if(index === (this.yarnFootprint.length - 1)){
				yarn.ID = yarnUuid;
				yarn.stepID = yarnWrapper.stepID;
				yarn.toCompanyUser.toCompanyID = yarnWrapper.supplierCompanyId;
				yarn.toCompanyUser.toCompanyName = yarnWrapper.supplierName;
				yarn.toCompanyUser.filledByCompanyID = this.userCompanyId;
				yarn.toCompanyUser.filledByPrimarySource = yarnWrapper.supplierCompanyId === this.userCompanyId ? true : false;
				yarn.fiber[0].ID = fiberUuid;
				yarn.fiber[0].filledByCompanyID = this.userCompanyId;
				yarn.fiber[0].filledByPrimarySource = yarnWrapper.supplierCompanyId === this.userCompanyId ? true : false;
				yarn.filledByCompanyID = this.userCompanyId;
				yarn.filledByPrimarySource = yarnWrapper.supplierCompanyId === this.userCompanyId ? true : false;
				yarn.showYarn = true;
				const yarnFootprints = this.yarnFootprint.filter(y => y.stepID === yarn.stepID);
				if(yarnFootprints.length > 1){
					yarn.sequence = yarnFootprints[yarnFootprints.length - 1].sequence + 1;
				} else {
					yarn.sequence = 1;
				}
				yarnSequence=yarn.sequence
			}
			else{
				yarn.showYarn = false;
			}
		});
		this.yarnFootprintClone.push(new YarnFootprintModel());
		this.yarnFootprintClone.forEach((yarn, index) => {
			if(index === (this.yarnFootprint.length - 1)){
				yarn.ID = yarnUuid;
				yarn.stepID = yarnWrapper.stepID;
				yarn.toCompanyUser.toCompanyID = yarnWrapper.supplierCompanyId;
				yarn.toCompanyUser.toCompanyName = yarnWrapper.supplierName;
				yarn.toCompanyUser.filledByCompanyID = this.userCompanyId;
				yarn.toCompanyUser.filledByPrimarySource = yarnWrapper.supplierCompanyId === this.userCompanyId ? true : false;
				yarn.fiber[0].ID = fiberUuid;
				yarn.fiber[0].filledByCompanyID = this.userCompanyId;
				yarn.fiber[0].filledByPrimarySource = yarnWrapper.supplierCompanyId === this.userCompanyId ? true : false;
				yarn.filledByCompanyID = this.userCompanyId;
				yarn.filledByPrimarySource = yarnWrapper.supplierCompanyId === this.userCompanyId ? true : false;
				yarn.showYarn = true;
				yarn.sequence = yarnSequence;
			}
			else{
				yarn.showYarn = false;
			}
		});
		this.compareYarnFootprintClone();
		this.checkNonEmptyFields();
	}

	private showAddYarnFiber(yarnIndex: number): boolean {
		return this.yarnFootprint[yarnIndex].fiber.length < 5 ? true : false;
	}

	private addYarnFiber(index: number): void {
		if(this.isSaving || this.userRole === 'Reader'){
			return;
		}
		const uuidFiber = uuidv4();
		this.yarnFootprint[index].fiber.push(new FiberModel);
		this.yarnFootprint[index].fiber[this.yarnFootprint[index].fiber.length - 1].ID = uuidFiber;
		this.yarnFootprint[index].fiber[this.yarnFootprint[index].fiber.length - 1].filledByCompanyID = this.userCompanyId;
		this.yarnFootprint[index].fiber[this.yarnFootprint[index].fiber.length - 1].filledByPrimarySource = this.yarnFootprint[index].toCompanyUser.toCompanyID === this.userCompanyId ? true : false;

		this.yarnFootprintClone[index].fiber.push(new FiberModel);
		this.yarnFootprintClone[index].fiber[this.yarnFootprintClone[index].fiber.length - 1].ID = uuidFiber;
		this.yarnFootprintClone[index].fiber[this.yarnFootprintClone[index].fiber.length - 1].filledByCompanyID = this.userCompanyId;
		this.yarnFootprintClone[index].fiber[this.yarnFootprintClone[index].fiber.length - 1].filledByPrimarySource = this.yarnFootprintClone[index].toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
		// if(this.yarnFootprint[index].fiber.length === 5){
		// 	this.showAddYarnFiber = false;
		// }
		this.compareYarnFootprintClone();
		this.checkNonEmptyFields();
	}

	private addModeTransport(stepID: string): void {
		if(this.isSaving || this.userRole === 'Reader'){
			return;
		}
		const tpIndex = this.yarnTransport.findIndex(tp => tp.stepID === stepID);
		this.yarnTransport[tpIndex].modeOfTransport.push(new ModeOfTransportModel);
		this.yarnTransportClone[tpIndex].modeOfTransport.push(new ModeOfTransportModel);
		this.compareYarnTransportClone();
		this.checkTransportEmptyFields();
	}

	private removeYarnFootprint(index: number): void {
		if(this.isSaving){
			return;
		}
		this.isTabRemoved = true;
		const beforeDelete = this.yarnFootprint[index];
		this.yarnFootprint.splice(index, 1);
		this.yarnFootprintClone.splice(index, 1);
		if (this.yarnFootprintCloneInitialState.filter(i => i.ID === beforeDelete.ID).length === 1) {
			this.FpDeletedCounter++;
		}
		this.compareYarnFootprintClone();
		// if(this.yarnFootprint.length < 6){
		// 	this.showAddYarn = true;
		// }
		this.checkNonEmptyFields();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.YARN_FP);
	}

	private removeYarnFiber(yarnIndex: number, fiberindex: number): void {
		if(this.yarnFootprint[yarnIndex].fiber[fiberindex].fiberValue.value !== ""){
			this.yarnFootprint[yarnIndex].total.totalcount = Number(this.yarnFootprint[yarnIndex].total.totalcount) - Number(this.yarnFootprint[yarnIndex].fiber[fiberindex].fiberValue.value);
			if(this.yarnFootprint[yarnIndex].total.totalcount !== 100){
				this.yarnFootprint[yarnIndex].total.isValid = false;
				this.disableSave = true;
			}
			else{
				this.yarnFootprint[yarnIndex].total.isValid = true;
			}
			this.yarnFootprintClone[yarnIndex].total.totalcount = Number(this.yarnFootprintClone[yarnIndex].total.totalcount) - Number(this.yarnFootprintClone[yarnIndex].fiber[fiberindex].fiberValue.value);
			if(this.yarnFootprintClone[yarnIndex].total.totalcount !== 100){
				this.yarnFootprintClone[yarnIndex].total.isValid = false;
				this.disableSave = true;
			}
		}
		this.yarnFootprint[yarnIndex].fiber.splice(fiberindex, 1);
		this.yarnFootprintClone[yarnIndex].fiber.splice(fiberindex, 1);
		// if(this.yarnFootprint[yarnIndex].fiber.length < 5){
		// 	this.showAddYarnFiber = true;
		// }
		this.compareYarnFootprintClone();
		this.checkNonEmptyFields();
	}

	private toggleYarnFootprint(): void {
		// if(this.yarnFpChangedCounter > 0 || (this.yarnFootprint.length !== this.yarnFootprintCloneInitialState.length)){
		// 	this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
		// }
		// else{
			this.showYarnFootprint = !this.showYarnFootprint;
			if(this.showYarnFootprint){
				this.showYarnTransport = false;
			}
		// }
	}

	private toggleYarnWrapper(index: number): void {
		this.activeYarnStep = Guid.Empty;
		const showWrapperValue = this.yarnWrapperData[index].showWrapper;
		this.yarnWrapperData.every(y => y.showWrapper = false);
		this.yarnWrapperData[index].showWrapper = !showWrapperValue;
		const yfSteps = this.yarnFootprint.filter(yf => yf.stepID === this.yarnWrapperData[index].stepID);
		yfSteps.sort((a, b)=> a.sequence < b.sequence ? -1 : 1);
		const yfStepIndex = this.yarnFootprint.findIndex(yf => yf.ID === yfSteps[0].ID);
		this.yarnFootprint[yfStepIndex].showYarn = true;
		// if(this.yarnFpChangedCounter > 0 || (this.yarnFootprint.length !== this.yarnFootprintCloneInitialState.length)){
		// 	this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
		// }
		// else{
			// this.yarnWrapperData.filter(w => w.stepID === stepId)[0].showWrapper = !this.yarnWrapperData.filter(w => w.stepID === stepId)[0].showWrapper;
		// }
	}

	private checkUniqueness(value: string): boolean {
		const count =  this.yarnFootprint.filter(yf => yf.reference.referenceNo === value).length;
		return count > 1 ? false : true;
	}

	private async validateYarnFootprint(event: any, fieldname: string, yarnIndex: number, fiberIndex: number): Promise<void> {
        switch (fieldname) {
			case "reference": {
				/* eslint-disable-next-line */
				!event.target.value.match(/^[a-zA-Z0-9- ]+(?:[\w -]*[a-zA-Z0-9- ]+)*$/) || event.target.value === '' ? this.yarnFootprint[yarnIndex].reference.isValid = false : this.yarnFootprint[yarnIndex].reference.isValid = true;
				if(event.target.value !== ''){
					this.yarnFootprint[yarnIndex].reference.isUnique = this.checkUniqueness(event.target.value);
				} else {
					this.yarnFootprint[yarnIndex].reference.isUnique = true;
				}
				this.yarnFootprint[yarnIndex].reference.isValid === false || !this.yarnFootprint[yarnIndex].reference.isUnique || this.yarnFootprint[yarnIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.yarnFootprint[yarnIndex].reference.referenceNo = event.target.value;
				this.yarnFootprint[yarnIndex].reference.filledByCompanyID = this.yarnFootprint[yarnIndex].reference.referenceNo!=='' ? this.userCompanyId : Guid.Empty;
				this.yarnFootprint[yarnIndex].reference.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID && this.yarnFootprint[yarnIndex].reference.referenceNo!=='' ? true : false;
                break;
			}
			case "spinning": {
				event !== '' ? this.yarnFootprint[yarnIndex].spinning.isValid = true : this.yarnFootprint[yarnIndex].spinning.isValid = false;
				this.yarnFootprint[yarnIndex].spinning.isValid === false || this.yarnFootprint[yarnIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.yarnFootprint[yarnIndex].spinning.spinningMethod = event;
				this.yarnFootprint[yarnIndex].spinning.filledByCompanyID = this.yarnFootprint[yarnIndex].spinning.spinningMethod!=='' ? this.userCompanyId : Guid.Empty;
				this.yarnFootprint[yarnIndex].spinning.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID && this.yarnFootprint[yarnIndex].spinning.spinningMethod!=='' ? true : false;
                break;
			}
			case "yarn-count": {
				/* eslint-disable-next-line */
				(event.target.value.match(/^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/) && event.target.value.length <=10) || event.target.value === '' ? this.yarnFootprint[yarnIndex].yarnCount.isValid = true : this.yarnFootprint[yarnIndex].yarnCount.isValid = false;
				this.yarnFootprint[yarnIndex].yarnCount.isValid === false || this.yarnFootprint[yarnIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.yarnFootprint[yarnIndex].yarnCount.count = event.target.value;
				this.yarnFootprint[yarnIndex].yarnCount.filledByCompanyID = this.yarnFootprint[yarnIndex].yarnCount.count!=='' ? this.userCompanyId : Guid.Empty;
				this.yarnFootprint[yarnIndex].yarnCount.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID && this.yarnFootprint[yarnIndex].yarnCount.count!=='' ? true : false;
                break;
			}
			case "yarn-unit": {
				event !== '' ? this.yarnFootprint[yarnIndex].yarnUnit.isValid = true : this.yarnFootprint[yarnIndex].yarnUnit.isValid = false;
				this.yarnFootprint[yarnIndex].yarnUnit.isValid ? this.yarnFootprint[yarnIndex].yarnCount.isValid = true : this.yarnFootprint[yarnIndex].yarnCount.isValid = false;
				this.yarnFootprint[yarnIndex].yarnUnit.isValid === false || this.yarnFootprint[yarnIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.yarnFootprint[yarnIndex].yarnUnit.unit = event;
				this.yarnFootprint[yarnIndex].yarnUnit.filledByCompanyID = this.yarnFootprint[yarnIndex].yarnUnit.unit!=='' ? this.userCompanyId : Guid.Empty;
				this.yarnFootprint[yarnIndex].yarnUnit.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID && this.yarnFootprint[yarnIndex].yarnUnit.unit!=='' ? true : false;
                break;
			}
			case "fiber-type": {
				event !== '' ? this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberType.isValid = true : this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberType.isValid = false;
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberType.isValid === false || this.yarnFootprint[yarnIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberType.type = event;
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberType.filledByCompanyID = this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberType.type!=='' ? this.userCompanyId : Guid.Empty;
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberType.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID && this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberType.type!=='' ? true : false;
                break;
			}
			case "fiber-value": {
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberValue.value = event.target.value!=='' ? parseInt(event.target.value) ? parseInt(event.target.value): "" : "";
				if(Number(this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberValue.value) <= 100 || this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberValue.value === ''){
					this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberValue.isValid = true;
					this.disableSave = false;
				}
				else{
					this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberValue.isValid = false;
					this.disableSave = true;
				}
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberValue.filledByCompanyID = this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberValue.value!=='' ? this.userCompanyId : Guid.Empty;
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberValue.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID && this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberValue.value!=='' ? true : false;
				let totalFiber = 0;
				this.yarnFootprint[yarnIndex].fiber.forEach(fiber => {
					totalFiber = totalFiber + (Number(fiber.fiberValue.value) ? Number(fiber.fiberValue.value) : 0) ;
				});
				this.yarnFootprint[yarnIndex].total.totalcount = totalFiber;
				this.yarnFootprint[yarnIndex].total.filledByCompanyID = this.userCompanyId;
				this.yarnFootprint[yarnIndex].total.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID ? true : false;
				this.yarnFootprint[yarnIndex].total.totalcount === 100 ? this.yarnFootprint[yarnIndex].total.isValid = true : this.yarnFootprint[yarnIndex].total.isValid = false;
				// !this.yarnFootprint[yarnIndex].fiber[fiberIndex].fiberValue.isValid ? this.disableSave=true : this.disableSave=false;
				!this.yarnFootprint[yarnIndex].total.isValid ? this.disableSave=true : this.disableSave=false;
                break;
			}
			case "fiber-origin": {
				event !== '' ? this.yarnFootprint[yarnIndex].fiber[fiberIndex].originOfFiber.isValid = true : this.yarnFootprint[yarnIndex].fiber[fiberIndex].originOfFiber.isValid = false;
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].originOfFiber.isValid === false || this.yarnFootprint[yarnIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].originOfFiber.value = event;
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].originOfFiber.filledByCompanyID = this.yarnFootprint[yarnIndex].fiber[fiberIndex].originOfFiber.value!=='' ? this.userCompanyId : Guid.Empty;
				this.yarnFootprint[yarnIndex].fiber[fiberIndex].originOfFiber.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID && this.yarnFootprint[yarnIndex].fiber[fiberIndex].originOfFiber.value!=='' ? true : false;
                break;
			}
			case "electrical-energy": {
				/* eslint-disable-next-line */
				this.yarnFootprint[yarnIndex].totalElectricEnergy.value = parseInt(event.target.value) === 0 || parseInt(event.target.value) ? parseInt(event.target.value) : "";
				if(Number(this.yarnFootprint[yarnIndex].totalElectricEnergy.value) <= 100 || this.yarnFootprint[yarnIndex].windEnergy.value === ''){
					this.yarnFootprint[yarnIndex].totalElectricEnergy.isValid = true;
					this.yarnFootprint[yarnIndex].total.isValid === false ? this.disableSave = true : this.disableSave = false;
				} else {
					this.yarnFootprint[yarnIndex].totalElectricEnergy.isValid = false;
					this.disableSave = true;
				}
				this.yarnFootprint[yarnIndex].totalElectricEnergy.filledByCompanyID = this.userCompanyId;
				this.yarnFootprint[yarnIndex].totalElectricEnergy.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID ? true : false;
				break;
			}
			case "wind-energy": {
				this.yarnFootprint[yarnIndex].windEnergy.value = parseInt(event.target.value) === 0 || parseInt(event.target.value) ? parseInt(event.target.value) : "";
				if(Number(this.yarnFootprint[yarnIndex].windEnergy.value) <= 100 || this.yarnFootprint[yarnIndex].windEnergy.value === ''){
					this.yarnFootprint[yarnIndex].windEnergy.isValid = true;
					this.yarnFootprint[yarnIndex].total.isValid === false ? this.disableSave = true : this.disableSave = false;
				} else {
					this.yarnFootprint[yarnIndex].windEnergy.isValid = false;
					this.disableSave = true;
				}
				this.yarnFootprint[yarnIndex].windEnergy.filledByCompanyID = this.yarnFootprint[yarnIndex].windEnergy.value!=='' && this.yarnFootprint[yarnIndex].windEnergy.value!==0 ? this.userCompanyId : Guid.Empty;
				this.yarnFootprint[yarnIndex].windEnergy.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID && 
				this.yarnFootprint[yarnIndex].windEnergy.value!=='' && this.yarnFootprint[yarnIndex].windEnergy.value!==0 ? true : false;
				break;
			}
			case "solar-energy": {
				this.yarnFootprint[yarnIndex].solarEnergy.value = parseInt(event.target.value) === 0 || parseInt(event.target.value) ? parseInt(event.target.value) : "";
				if(Number(this.yarnFootprint[yarnIndex].solarEnergy.value) <= 100 || this.yarnFootprint[yarnIndex].solarEnergy.value === ''){
					this.yarnFootprint[yarnIndex].solarEnergy.isValid = true;
					this.yarnFootprint[yarnIndex].total.isValid === false ? this.disableSave = true : this.disableSave = false;
				} else {
					this.yarnFootprint[yarnIndex].solarEnergy.isValid = false;
					this.disableSave = true;
				}
				this.yarnFootprint[yarnIndex].solarEnergy.filledByCompanyID = this.yarnFootprint[yarnIndex].solarEnergy.value!=='' && this.yarnFootprint[yarnIndex].solarEnergy.value!==0 ? this.userCompanyId : Guid.Empty;
				this.yarnFootprint[yarnIndex].solarEnergy.filledByPrimarySource = this.userCompanyId === this.yarnFootprint[yarnIndex].toCompanyUser.toCompanyID && this.yarnFootprint[yarnIndex].solarEnergy.value!=='' &&
				this.yarnFootprint[yarnIndex].solarEnergy.value!==0 ? true : false;
				break;
			}
			default:
                break;
        }
		this.compareYarnFootprintClone();
		this.checkNonEmptyFields();
    }

	private async validateYarnFootprintRound(event: any, fieldname: string, yarnIndex: number): Promise<void> {
		switch (fieldname) {
			case 'yarn-count': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					rounded = Number(val).toFixed(2);
				}
				this.yarnFootprint[yarnIndex].yarnCount.count = rounded !== '' && !isNaN(rounded) ? parseFloat(rounded) : '';
				this.yarnFootprint[yarnIndex].yarnCount.filledByCompanyID = rounded === '' && rounded!== '0' ? Guid.Empty : this.yarnFootprint[yarnIndex].yarnCount.filledByCompanyID;
				this.yarnFootprint[yarnIndex].yarnCount.filledByPrimarySource = rounded === '' && rounded!== '0' ? false : this.yarnFootprint[yarnIndex].yarnCount.filledByPrimarySource;
				break;
			}
			case 'electrical-energy': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					rounded = Number(val).toFixed(2);
				}
				this.yarnFootprint[yarnIndex].totalElectricEnergy.value = rounded !== '' && !isNaN(rounded) ? parseFloat(rounded) : '';
				this.yarnFootprint[yarnIndex].totalElectricEnergy.filledByCompanyID = rounded === '' && rounded!== '0' ? Guid.Empty : this.yarnFootprint[yarnIndex].totalElectricEnergy.filledByCompanyID;
				this.yarnFootprint[yarnIndex].totalElectricEnergy.filledByPrimarySource = rounded === '' && rounded!== '0' ? false : this.yarnFootprint[yarnIndex].totalElectricEnergy.filledByPrimarySource;
				break;
			}
			default:
				break;
		}
	}

	private compareYarnFootprintClone() {
		let changes: number = 0;
		const yarnFootprint: [string, any][] = Object.entries(this.yarnFootprint);
		const yarnFootprintClone: [string, any][] = Object.entries(this.yarnFootprintClone);

		const getObjectDiff = (obj1: any, obj2: any) => {
		const obj1Props = Object.keys(obj1);
		const obj2Props = Object.keys(obj2);

		const keysWithDiffValue = obj1Props.reduce((keysWithDiffValueAccumulator, key) => {
			// eslint-disable-next-line no-prototype-builtins
			const propExistsOnObj2 = obj2.hasOwnProperty(key);
			const hasNestedValue = obj1[key] instanceof Object && obj2[key] instanceof Object;
			const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];

			if (!propExistsOnObj2) {
			keysWithDiffValueAccumulator.push(key);
			} else if (hasNestedValue) {
			const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
			if (keyIndex >= 0) {
				keysWithDiffValueAccumulator.splice(keyIndex, 1);
			}
			const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
			for (const diff of nestedDiffs) {
				keysWithDiffValueAccumulator.push(`${key}.${diff}`);
			}
			} else if (keyValuePairBetweenBothObjectsIsEqual) {
			const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
			keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
			}
			return keysWithDiffValueAccumulator;
		}, obj2Props);

		return keysWithDiffValue;
		};
		const differences = getObjectDiff(yarnFootprint, yarnFootprintClone);
		changes += differences.length;
		differences.forEach(element => {
			if (element.includes('status') || element.includes('isValid') || element.includes('filledByCompanyID') || element.includes('showYarn') || element.includes('filledByPrimarySource') || element.includes('toCompanyName') || element.includes('isUnique') || element.includes('msdsDoc') || element.includes('energySupportingDocument')) {
				changes = changes-1;
			}
		});
		this.yarnFpChangedCounter = changes + this.FpDeletedCounter;
		if(changes > 0){
			this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
			this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.YARN_FP);
		}
	}

	private openUploadDocModal(yarnIndex: number): void {
		this.showUploadDocModal = true;
		this.yarnIndex = yarnIndex;
		this.docFile = "";
	}

	private get uploadDisabled(): boolean {
		return this.docFile == "";
	}

	private closeUploadDocModal(): void {
		this.showUploadDocModal = false;
		this.docFile = null;
	}

	private openPDFModal(doc: EnergySupportingDocumentModel): void {
		this.docPreview = doc;
		this.showPdfPreviewModal = true;
	}

	private closePdfModal(): void {
		this.showPdfPreviewModal = false;
	}

	private async downloadPDF(): Promise<void> {
        if (this.isPdfReady) {
            this.pdfDocument.contentDispositionFilename = this.docPreview.fileName;
            this.pdfDocument.download();
        }
    }

    private async pdfReady(pdfApp: any): Promise<void> {
        this.isPdfReady = true;
        this.pdfDocument = pdfApp;
    }

	private checkNonEmptyFields(): void {
		this.yarnFieldEmpty = "";
		this.disableSave = false;
		
		let finalYarnFp = lodash.cloneDeep(this.yarnFootprint);
		finalYarnFp = finalYarnFp.filter(y => this.yarnWrapperData.filter(w => w.stepID === y.stepID).length > 0);
		this.yarnWrapperData.forEach(wrapper => {
			let filledField = false;
			finalYarnFp.filter(yrn => yrn.stepID === wrapper.stepID).forEach(yarn => {
				yarn.spinning.spinningMethod === "" ? this.yarnFieldEmpty = "spinnig," : filledField = true;
				yarn.yarnUnit.unit === "" ? this.yarnFieldEmpty = "yarnunit," : filledField = true;
				yarn.totalElectricEnergy.value === "" ? this.yarnFieldEmpty = "electric," : filledField = true;
				yarn.yarnCount.count === "" ? this.yarnFieldEmpty = "yarncount," : filledField = true;
				yarn.total.totalcount === "" ? this.yarnFieldEmpty = "total," : filledField = true;
				yarn.windEnergy.value === "" ? this.yarnFieldEmpty = "wind," : filledField = true;
				yarn.solarEnergy.value === "" ? this.yarnFieldEmpty = "solar," : filledField = true;
				yarn.fiber.forEach(f => {
					f.fiberType.type === "" ? this.yarnFieldEmpty = "fibertype," : filledField = true;
					f.fiberValue.value === "" ? this.yarnFieldEmpty = "fibervalue," : filledField = true;
					f.originOfFiber.value === "" ? this.yarnFieldEmpty = "fiberorigin," : filledField = true;
					// Sptint-22 TTD-4290
					if((!f.fiberValue.isValid || !yarn.windEnergy.isValid || !yarn.solarEnergy.isValid || !yarn.total.isValid) && filledField) {
						this.disableSave = true;
					}
				});
				if (filledField && yarn.reference.referenceNo === '') {
					if ((!yarn.reference.isValid || yarn.reference.referenceNo === '') || 
						(yarn.reference.isUnique !== undefined && !yarn.reference.isUnique) || 
						!yarn.solarEnergy.isValid || 
						!yarn.totalElectricEnergy.isValid || 
						!yarn.windEnergy.isValid ||
						!yarn.yarnCount.isValid ||
						!yarn.total.isValid
						) {
						this.disableSave = true;
					}
				}
				
			});
		});
		if(this.disableSave){
			this.$store.commit('setIncorrectFootprintData', true);
		}else{
			this.$store.commit('setIncorrectFootprintData', false);
		}
	}

	private checkTransportEmptyFields(): void {
		this.transportFieldEmpty = false;
		const transport = lodash.cloneDeep(this.yarnTransport);
		transport.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					this.transportFieldEmpty = true;
				}
			});
		});
	}

	// private beforeSave(): void {
		// let showWarning: boolean = false;
		// const showSaveWarning: boolean = false;

		// this.yarnFootprint.forEach(yarn => {
		// 	if ((yarn.reference.referenceNo === "" || yarn.reference.referenceNo === null)) {
		// 		this.disableSave = true;
		// 		if (yarn.reference.referenceNo === "") {
		// 			yarn.reference.isValid = false;
		// 		}
		// 	}
		// 	if (yarn.totalElectricEnergy.value !== "") {
		// 		if(yarn.totalElectricEnergy.value.toString().indexOf(".") !== -1){
		// 			yarn.totalElectricEnergy.value = parseFloat(yarn.totalElectricEnergy.value.toString());
		// 		}
		// 		else{
		// 			yarn.totalElectricEnergy.value = parseInt(yarn.totalElectricEnergy.value.toString());
		// 		}
		// 	}
		// 	else{
		// 		yarn.totalElectricEnergy.value = -1;
		// 	}
		// 	if (yarn.yarnCount.count !== "") {
		// 		if(yarn.yarnCount.count.toString().indexOf(".") !== -1){
		// 			yarn.yarnCount.count = parseFloat(yarn.yarnCount.count.toString());
		// 		}
		// 		else{
		// 			yarn.yarnCount.count = parseInt(yarn.yarnCount.count.toString());
		// 		}
		// 	}
		// 	else{
		// 		yarn.yarnCount.count = -1;
		// 	}
		// 	if(yarn.total.totalcount === ""){
		// 		yarn.total.totalcount = -1;
		// 	}
		// 	if(yarn.windEnergy.value === ""){
		// 		yarn.windEnergy.value = -1;
		// 	}
		// 	if(yarn.solarEnergy.value === ""){
		// 		yarn.solarEnergy.value = -1;
		// 	}
		// 	yarn.fiber.forEach(f => {
		// 		if(f.fiberValue.value === ""){
		// 			f.fiberValue.value = -1;
		// 		}
		// 	});
		// });
		// if (this.disableSave) {
		// 	return;
		// }
		// else {
		// 	this.saveYarnFootprintData();
		// }
	// 	this.saveYarnFootprintData();
	// }

	private async saveYarnFootprintData(): Promise<void> {
		this.disableSave = false;
		this.isSaving = true;
		const yarnFP = lodash.cloneDeep(this.yarnFootprint);
		yarnFP.forEach(yarn => {
			// if ((yarn.reference.referenceNo === "" || yarn.reference.referenceNo === null)) {
			// 	this.disableSave = true;
			// 	if (yarn.reference.referenceNo === "") {
			// 		yarn.reference.isValid = false;
			// 	}
			// }
			if (yarn.totalElectricEnergy.value !== "") {
				if(yarn.totalElectricEnergy.value.toString().indexOf(".") !== -1){
					yarn.totalElectricEnergy.value = parseFloat(yarn.totalElectricEnergy.value.toString());
				}
				else{
					yarn.totalElectricEnergy.value = parseInt(yarn.totalElectricEnergy.value.toString());
				}
			}
			else{
				yarn.totalElectricEnergy.value = -1;
			}
			if (yarn.yarnCount.count !== "") {
				if(yarn.yarnCount.count.toString().indexOf(".") !== -1){
					yarn.yarnCount.count = parseFloat(yarn.yarnCount.count.toString());
				}
				else{
					yarn.yarnCount.count = parseInt(yarn.yarnCount.count.toString());
				}
			}
			else{
				yarn.yarnCount.count = -1;
			}
			if(yarn.total.totalcount === ""){
				yarn.total.totalcount = -1;
			}
			if(yarn.windEnergy.value === ""){
				yarn.windEnergy.value = -1;
			}
			if(yarn.solarEnergy.value === ""){
				yarn.solarEnergy.value = -1;
			}
			yarn.fiber.forEach(f => {
				if(f.fiberValue.value === ""){
					f.fiberValue.value = -1;
				}
			});
		});
		// if (this.disableSave) {
		// 	return;
		// }
		// const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		const yarnData: YarnFootprintRequestModel = {
			footprintDeadline: this.footprintOrder.footprintDeadline !== (this.deadline !== "" ? new Date(this.deadline).toISOString() : "") ? new Date(this.deadline).toISOString(): this.footprintOrder.footprintDeadline,
			yarn: {
				footprint: yarnFP,
				footprintStatus: this.yarnFootprint.filter(y => y.status === FootPrintCompletedConstant.COMPLETED).length === this.yarnFootprint.length ? FootPrintCompletedConstant.COMPLETED : this.yarnFootprint.filter(y => y.status === FootPrintCompletedConstant.INCOMPLETE).length === this.yarnFootprint.length ? FootPrintCompletedConstant.INCOMPLETE : FootPrintCompletedConstant.PARTIAL,
			}
		};
		const result = await this.clientRequestFootPrintData.saveYarnFootprintData(this.orderId, yarnData);
		if(result.success){
			this.yarnFootprint = lodash.cloneDeep(result.order.yarn.footprint);
			this.footprintOrder.yarn.footprintStatus = result.order.yarn.footprintStatus;
			this.yarnFootprint.forEach((yarn, index) => {
				yarn.toCompanyUser.isValid = true;
				yarn.reference.isValid = true;
				yarn.spinning.isValid = true;
				yarn.yarnCount.isValid = true;
				if(yarn.yarnCount.count === -1){
					yarn.yarnCount.count = '';
				}
				yarn.yarnUnit.isValid = true;
				if(yarn.fiber === null || yarn.fiber.length === 0){
					yarn.fiber = [new FiberModel()];
					yarn.fiber[0].ID = uuidv4();
				}
				yarn.fiber.forEach(fiber => {
					fiber.fiberType.isValid = true;
					fiber.fiberValue.isValid = true;
					fiber.originOfFiber.isValid = true;
					if(fiber.fiberValue.value === -1){
						fiber.fiberValue.value = '';
					}
				});
				yarn.total.isValid = true;
				if(yarn.total.totalcount === -1){
					yarn.total.totalcount = '';
				}
				yarn.totalElectricEnergy.isValid = true;
				if(yarn.totalElectricEnergy.value === -1){
					yarn.totalElectricEnergy.value = '';
				}
				yarn.windEnergy.isValid = true;
				if(yarn.windEnergy.value === -1){
					yarn.windEnergy.value = '';
				}
				yarn.solarEnergy.isValid = true;
				if(yarn.solarEnergy.value === -1){
					yarn.solarEnergy.value = '';
				}
				if(index === 0){
					yarn.showYarn = true;
				} else {
					yarn.showYarn = false;
				}
			});
			this.yarnFootprintClone = lodash.cloneDeep(this.yarnFootprint);
			this.yarnFootprintCloneInitialState = lodash.cloneDeep(this.yarnFootprint);
			this.yarnFpStatus();
			this.showYarnFootprint = true;
			this.yarnFpChangedCounter = 0;
			this.FpDeletedCounter = 0;
		}
		this.$store.commit("setFootprintChanged", '');
		this.$store.commit("setChangedFootprintName", '');
		this.$store.commit('setIncorrectFootprintData', false);
		this.isSaving = false;
		this.showWarningModal = false;
	}

	// TTD-3061 Sprint19
	private handleTpPrevious(): void {
		this.showYarnFootprint = true;
		this.showYarnTransport = false;
	}

	// TTD-3061 Sprint19
	private handleTpNext(): void {
		this.yarnTpStatus();
		this.showYarnTransport = false;
		//TTD-4276 issue1
		if(this.isFootprintChanged === FootPrintChangedConstant.DATA){
			this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
			this.saveChanges();
			this.showYarnFootprint = true;
			return;
		}else{
			this.navigateTab(1);
		}
	}

	// TTD-3061 Sprint19
	private handleFpNext(): void {
		this.showYarnFootprint = false;
		this.showYarnTransport = true;
		this.transportWrapperData.length>0 ? this.transportWrapperData[0].showWrapper = true : "";
	}

	private async uploadSupportingDoc(): Promise<void>{
		this.isDocUploading = true;
		const docFormData = new FormData();
		docFormData.append("upload", this.docFile);

		docFormData.append("geoLong", '0');
		docFormData.append("geoLat", '0');
		docFormData.append("docID", uuidv4());
		docFormData.append("footprintID", this.yarnFootprint[this.yarnIndex].ID);
		docFormData.append("stepID", this.yarnFootprint[this.yarnIndex].stepID);
		docFormData.append("footprintType", FootPrintConstant.YARN);
		docFormData.append("filledByCompanyID", this.userCompanyId);
		docFormData.append("filledByPrimarySource", this.userCompanyId === this.yarnFootprint[this.yarnIndex].toCompanyUser.toCompanyID ? "true" : "false");
		const result = await this.clientRequestFootPrintData.uploadSupportingDocsAsync(this.orderId, docFormData);
		if(result.success){
			this.yarnFootprint[this.yarnIndex].energySupportingDocument = result.order.yarn.footprint[this.yarnIndex].energySupportingDocument;
			if(this.yarnFootprintCloneInitialState[this.yarnIndex]){
				this.yarnFootprintCloneInitialState[this.yarnIndex].energySupportingDocument = result.order.yarn.footprint[this.yarnIndex].energySupportingDocument;
			}
			this.closeUploadDocModal();
		}
		this.isDocUploading = false;
	}

	private clearYarnFootprintData(): void {
		if (this.yarnFpChangedCounter>0 || this.isTabRemoved) {
			this.yarnFootprint = lodash.cloneDeep(this.yarnFootprintCloneInitialState);
			this.yarnFootprintClone = lodash.cloneDeep(this.yarnFootprintCloneInitialState);
			// this.defaultYarnTransport();
			this.yarnFpChangedCounter = 0;
			this.FpDeletedCounter = 0;
			this.isTabRemoved = false;
			this.$store.commit("setFootprintChanged", '');
			this.$store.commit("setChangedFootprintName", '');
			this.$store.commit('setIncorrectFootprintData', false);
		}
	}

	private yarnWrapperFpStatus(stepID: string): string {
		let status = 'red';
		let count = 0;
		let filledCount = 0;
		const relativeYarns = this.yarnFootprint.filter(y => y.stepID === stepID);
		relativeYarns.forEach(yarn => {
			let yarnCount = 0;
			let yarnFilledCount = 0;
			if(yarn.reference.referenceNo === ""){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
			if(yarn.spinning.spinningMethod === ""){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
			if(yarn.yarnUnit.unit === ""){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
			if(yarn.totalElectricEnergy.value === ""){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
			if(yarn.yarnCount.count === ""){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
			if(yarn.total.totalcount === "" ){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
			if(yarn.windEnergy.value === "" ){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
			if(yarn.solarEnergy.value === "" ){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
			yarn.fiber.forEach(f => {
				if(f.fiberType.type === "" ){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
				if(f.fiberValue.value === "" ){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
				if(f.originOfFiber.value === "" ){count++; yarnCount++;} else {filledCount++;yarnFilledCount++;}
			});
			const stepIndex = this.yarnFootprint.findIndex(y => y.stepID === stepID && y.ID === yarn.ID);
			if(stepIndex !== -1){
				if (yarnCount>0 && yarnFilledCount>0) {
					this.yarnFootprint[stepIndex].status = FootPrintCompletedConstant.PARTIAL;
				} else if(yarnCount === 0) {
					this.yarnFootprint[stepIndex].status = FootPrintCompletedConstant.COMPLETED;
				} else {
					this.yarnFootprint[stepIndex].status = FootPrintCompletedConstant.INCOMPLETE;
				}
			}
		})
		if (count>0 && filledCount>0) {
			status = "orange";
		} else if(count === 0) {
			status = "green";
		}
		return status;
	}

	private yarnWrapperTpStatus(stepID: string): string {
		let status = 'red';
		let count = 0;
		let filledCount = 0;
		const relativeTrans = this.yarnTransport.filter(y => y.stepID === stepID);
		relativeTrans.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					count++;
				}
				if(mode.distance.value !== '' || mode.distanceUnit.unit !== '' || mode.transportType.value !== ''){
					filledCount++;
				}
			});
			const tpIndex = this.yarnTransport.findIndex(y => y.stepID === stepID && y.ID === tp.ID);
			if(tpIndex !== -1){
				if (count>0 && filledCount>0) {
					this.yarnTransport[tpIndex].status = FootPrintCompletedConstant.PARTIAL;
				} else if(count === 0) {
					this.yarnTransport[tpIndex].status = FootPrintCompletedConstant.COMPLETED;
				} else {
					this.yarnTransport[tpIndex].status = FootPrintCompletedConstant.INCOMPLETE;
				}
			}
		})
		if (count>0 && filledCount>0) {
			status = "orange";
		} else if(count === 0) {
			status = "green";
		}
		return status;
	}

	private get showWarningFootprint(): boolean {
		let count = 0;
		this.yarnFootprint.forEach(yarn => {
			if(yarn.reference.referenceNo === ""){
				count++;
			}
			if(yarn.spinning.spinningMethod === ""){
				count++;
			}
			if(yarn.yarnUnit.unit === ""){
				count++;
			}
			if (yarn.totalElectricEnergy.value === "") {
				count++;
			}
			if (yarn.yarnCount.count === "") {
				count++;
			}
			if(yarn.total.totalcount === ""){
				count++;
			}
			if(yarn.windEnergy.value === ""){
				count++;
			}
			if(yarn.solarEnergy.value === ""){
				count++;
			}
			yarn.fiber.forEach(f => {
				if(f.fiberType.type === ""){
					count++;
				}
				if(f.fiberValue.value === ""){
					count++;
				}
				if(f.originOfFiber.value === ""){
					count++;
				}
			});
		})
		if (count > 0) {
			return true;
		}
		return false;
	}

	private get showWarningTransport(): boolean {
		let count = 0;
		this.yarnTransport.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					count++;
				}
			});
		});
		if (count > 0) {
			return true;
		}
		return false;
	}

	private toggleYarnTransport(): void {
		// const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		// if(this.footprintOrder.yarn.footprint.length > 0 && this.yarnFpChangedCounter === 0 && (this.yarnFootprint.length === this.yarnFootprintCloneInitialState.length)){
			this.showYarnTransport = !this.showYarnTransport;
			if(this.showYarnTransport){
				this.showYarnFootprint = false;
			}
		// }
		// else{
		// 	this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
		// }
	}

	private toggleTransportWrapper(index: number): void {
		this.transportWrapperData[index].showWrapper = !this.transportWrapperData[index].showWrapper;
	}

	private toggleTransport(index: number): void {
		this.yarnTransport.forEach((tp, tpIndex) => {
			if(tpIndex === index){
				tp.showTransport = true;
			}
			else{
				tp.showTransport = false;
			}
		});
	}

	private removeTransportFootprint(index: number): void {
		let flag: boolean = false;
		this.yarnTransport.splice(index, 1);
		this.yarnTransportClone.splice(index, 1);
		this.TpDeletedCounter++;
		this.compareYarnTransportClone();
		this.yarnTransport.findIndex(t => t.showDelete===true) !== -1 ? flag = true: flag = false;
		this.transportToBeDeleted = flag;
	}

	private yarnTransportMode(stepID: string): ModeOfTransportModel[] {
		const yarnTransport = this.yarnTransport.filter(yt => yt.stepID === stepID)[0];
		return yarnTransport.modeOfTransport;
	}

	private async validateYarnTransport(event: any, fieldname: string, stepID: string, modeTransportIndex: number): Promise<void> {
        const tpindex = this.yarnTransport.findIndex(tp => tp.stepID === stepID);
		switch (fieldname) {
            case "transport-type": {
				event !== '' ? this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.isValid = true : this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.isValid = false;
				!this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.isValid ? this.disableSave=true : this.disableSave=false;
				this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.value = event;
				this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.filledByCompanyID = this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.value!=='' ? this.userCompanyId : Guid.Empty;
				this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.filledByPrimarySource = this.userCompanyId === this.yarnTransport[tpindex].toCompanyUser.toCompanyID && this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.value!=='' ? true : false;
                break;
			}
			case "distance": {
				/* eslint-disable-next-line */
				event.target.value.match(/^([0-9][0-9]{0,4})$/) ? Number(event.target.value) <= 50000 ? this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.isValid = true : this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.isValid = false : this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.isValid = false;
				event.target.value !== "" && event.target.value.match(/^([0-9][0-9]{0,4})$/) === null ? this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.isValid = true : '';
				!this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.isValid ? this.disableSave=true : this.disableSave=false;
				this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.value = event.target.value !== "" && event.target.value.match(/^([0-9][0-9]{0,4})$/) !== null  ? Number(event.target.value): "";
				this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.value = event.target.value !== "" ? Number(event.target.value)? Number(event.target.value): "" : "";
				this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.filledByCompanyID = this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.value!=='' ? this.userCompanyId : Guid.Empty;
				this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.filledByPrimarySource = this.userCompanyId === this.yarnTransport[tpindex].toCompanyUser.toCompanyID && this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distance.value!=='' ? true : false;
                break;
			}
			case "distance-unit": {
				event !== '' ? this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.isValid = true : this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.isValid = false;
				!this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.isValid ? this.disableSave=true : this.disableSave=false;
				this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.unit = event;
				this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.filledByCompanyID = this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.unit!=='' ? this.userCompanyId : Guid.Empty;
				this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.filledByPrimarySource = this.userCompanyId === this.yarnTransport[tpindex].toCompanyUser.toCompanyID && this.yarnTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.unit!=='' ? true : false;
                break;
			}
			default:
                break;
        }
		this.compareYarnTransportClone();
    }

	private compareYarnTransportClone() {
		let changes: number = 0;
		const yarnTransport: [string, any][] = Object.entries(this.yarnTransport);
		const yarnTransportClone: [string, any][] = Object.entries(this.yarnTransportClone);

		const getObjectDiff = (obj1: any, obj2: any) => {
		const obj1Props = Object.keys(obj1);
		const obj2Props = Object.keys(obj2);

		const keysWithDiffValue = obj1Props.reduce((keysWithDiffValueAccumulator, key) => {
			// eslint-disable-next-line no-prototype-builtins
			const propExistsOnObj2 = obj2.hasOwnProperty(key);
			const hasNestedValue = obj1[key] instanceof Object && obj2[key] instanceof Object;
			const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];

			if (!propExistsOnObj2) {
			keysWithDiffValueAccumulator.push(key);
			} else if (hasNestedValue) {
			const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
			if (keyIndex >= 0) {
				keysWithDiffValueAccumulator.splice(keyIndex, 1);
			}
			const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
			for (const diff of nestedDiffs) {
				keysWithDiffValueAccumulator.push(`${key}.${diff}`);
			}
			} else if (keyValuePairBetweenBothObjectsIsEqual) {
			const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
			keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
			}
			return keysWithDiffValueAccumulator;
		}, obj2Props);

		return keysWithDiffValue;
		};
		const differences = getObjectDiff(yarnTransport, yarnTransportClone);

		changes += differences.length;
		differences.forEach(element => {
			if (element.includes('status') || element.includes('isValid') || element.includes('filledByCompanyID') || element.includes('showTransport') || element.includes('filledByPrimarySource')) {
				changes = changes-1;
			}
		});
		this.yarnTpChangedCounter = changes + this.TpDeletedCounter;
		if(changes > 0){
			this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
			this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.YARN_TP);
		}
		this.checkTransportEmptyFields();
	}

	private async saveYarnTransportData(): Promise<void> {
		this.isSaving = true;
		const transport = lodash.cloneDeep(this.yarnTransport);
		transport.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value !== ""){
					mode.distance.value = Number(mode.distance.value);
				}
				else{
					mode.distance.value = -1;
				}
			});
		});
		const yarnTransport: TransportFootprintRequestModel = {
			footprintDeadline: this.footprintOrder.footprintDeadline !== (this.deadline !== "" ? new Date(this.deadline).toISOString() : "") ? new Date(this.deadline).toISOString(): this.footprintOrder.footprintDeadline,
			data: {
				transportType: FootPrintConstant.YARN,
				transportStatus: this.yarnTransport.filter(tp => tp.status === FootPrintCompletedConstant.COMPLETED).length === this.yarnTransport.length ? FootPrintCompletedConstant.COMPLETED : this.yarnTransport.filter(tp => tp.status === FootPrintCompletedConstant.INCOMPLETE).length === this.yarnTransport.length ? FootPrintCompletedConstant.INCOMPLETE : FootPrintCompletedConstant.PARTIAL,
				transport: transport
			}
		};
		const result = await this.clientRequestFootPrintData.saveYarnTransportAsync(this.orderId, yarnTransport);
		if(result.success){
			this.footprintOrder.yarn.transportStatus = result.order.yarn.transportStatus;
			this.yarnTransport = result.order.yarn.transport;
			this.yarnTransport.forEach((transport, index) => {
				transport.toCompanyUser.isValid = true;
				transport.modeOfTransport.forEach(mode => {
					if(mode.distance.value === -1){
						mode.distance.value = '';
					}
					mode.distance.isValid = true;
					mode.distanceUnit.isValid = true;
					mode.transportType.isValid = true;
				})
			});
			this.yarnTransportClone = lodash.cloneDeep(this.yarnTransport);
			this.yarnTransportCloneInitialState = lodash.cloneDeep(this.yarnTransport);
			this.yarnTpStatus();
			this.TpDeletedCounter = 0;
			this.yarnTpChangedCounter = 0;
		}
		this.isSaving = false;
		this.$store.commit("setFootprintChanged", '');
		this.$store.commit("setChangedFootprintName", '');
		this.$store.commit('setIncorrectFootprintData', false);
	}

	private removeMode(stepID: string, modeIndex: number): void {
		const tpIndex = this.yarnTransport.findIndex(tp => tp.stepID === stepID);
		this.yarnTransport[tpIndex].modeOfTransport.splice(modeIndex, 1);
		this.yarnTransportClone[tpIndex].modeOfTransport.splice(modeIndex, 1);
		this.TpDeletedCounter++;
		// if (this.yarnTransportCloneInitialState[tpIndex].modeOfTransport.filter(i => i. === beforeDelete.ID).length === 1) {
		// 	this.FpDeletedCounter++;
		// }
		this.compareYarnTransportClone();
	}

	private showDeletePopup(yarnIndex: number, yarnDocIndex: number){
		this.showDeleteModal = true;
		this.yarnDeleIndex = yarnIndex;
		this.yarnlDeleDocIndex = yarnDocIndex;
	}
	private async deleteSupDoc(): Promise<void> {
		this.isDeletingDoc = true;
		this.deletedDocID = this.yarnFootprint[this.yarnDeleIndex].energySupportingDocument[this.yarnlDeleDocIndex].ID;
		this.isDeletingDoc = true;
		const doc: DeleteDocRequestModel = {
			footprintID: this.yarnFootprint[this.yarnDeleIndex].ID,
			footprintType: FootPrintConstant.YARN,
			docID: this.yarnFootprint[this.yarnDeleIndex].energySupportingDocument[this.yarnlDeleDocIndex].ID
		};
		const result: FootprintDataResponseModel = await this.clientRequestFootPrintData.deleteSupportDocs(this.orderId, doc);
		if(result.success){
			this.yarnFootprint[this.yarnDeleIndex].energySupportingDocument = result.order.yarn.footprint[this.yarnDeleIndex].energySupportingDocument;
			if(this.yarnFootprintCloneInitialState[this.yarnDeleIndex]){
				this.yarnFootprintCloneInitialState[this.yarnDeleIndex].energySupportingDocument = result.order.yarn.footprint[this.yarnDeleIndex].energySupportingDocument;
			}
		}
		this.isDeletingDoc = false;
		this.showDeleteModal = false;
	}

	private clearYarnTransportData() {
		if (this.yarnTpChangedCounter > 0) {
			this.yarnTransport = lodash.cloneDeep(this.yarnTransportCloneInitialState);
			this.yarnTransportClone = lodash.cloneDeep(this.yarnTransportCloneInitialState);
			this.yarnTpChangedCounter = 0;
			this.TpDeletedCounter = 0;
			this.$store.commit("setFootprintChanged", '');
			this.$store.commit("setChangedFootprintName", '');
			this.$store.commit('setIncorrectFootprintData', false);
		}
		this.compareYarnTransportClone();
	}

	private get selectedCard(): string {
		const wrapperIndex = this.yarnWrapperData.findIndex(w => w.stepID === this.activeYarnStep);
		const yarnIndex = this.yarnFootprint.findIndex(y => y.stepID === this.activeYarnStep);
		if(wrapperIndex !== -1 && yarnIndex !== -1){
			this.yarnWrapperData.forEach(w => w.showWrapper = false);
			this.yarnFootprint.forEach(w => w.showYarn= false);
			this.yarnWrapperData[wrapperIndex].showWrapper = true;
			this.yarnFootprint[yarnIndex].showYarn = true;
		}
		return this.activeYarnStep;
	}

	@Emit()
	private navigateTab(index: number): void {}

	//TTD-4276 issue1
	@Emit()
	private saveChanges(): void {}

	@Watch('activeStep')
    private openCardForSelected(){
		this.activeYarnStep = this.activeStep;
    }
}