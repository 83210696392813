import { ContactModel } from "./contactModel";

export class ConfirmOrderCompanyModel {

    public constructor (id: string, country: string, name: string){
        this.ID = id;
        this.contact = new ContactModel();
        this.country = country;
        this.name = name;
    }

    public ID!: string;
    public contact!: ContactModel;
    public country!: string;
    public name!: string;
}