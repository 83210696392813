import { FileModel } from "./fileModel";
import { TopBrandModelSteps } from "./topBrandModelSteps";

export class TopBrandModel {
    public constructor(
        public brandName: string,
        public brandURL: string,
        public disabled: boolean,
        public logoFile: File | null,
        public brandLogoUrl: string,
        public brandLogo: FileModel | null,
        public productFootprintDesc: string,
        public nextStepSubtitle: string,
        public nextStepIntroText: string,
        public steps: TopBrandModelSteps[],
        public stepNote: string,
    )
    {}
}