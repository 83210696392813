import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { Drag, DropList } from "vue-easy-dnd";

import { FileHelper } from "@/helpers/fileHelper";
import { ProductPictureModel } from "@/models/productPictureModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { ClientService } from "@/services/clientService";
import { NotificationHelper } from "@/helpers/notificationHelper";
import CompanyProvider from "@/providers/companyProvider";
import i18n from "@/i18n";
import { ProductPictureDisplayModel } from "@/models/productPictureDisplayModel";
import moment from "moment";

@Component({
  components: { Drag, DropList}
})
export default class ProductPictureUpload extends Vue {

  @Prop()
  private orderLine!: OrderLineDisplayModel;

  @Prop()
  private currentPictures!: ProductPictureDisplayModel[];

  private clientService: ClientService;

  private files: File[] = [];

  private isLoading: boolean = false;

  private allPictures: ProductPictureModel[] = [];

  private uploadedPictures: ProductPictureModel[] = [];

  private isEmpty: boolean = false;

  private isRemoved: boolean = false;

  private removedPicturesIds: string[] = [];

  public constructor() {
    super();
    this.clientService = new ClientService();
  }

  private created(): void {
    this.currentPictures = this.currentPictures.sort((item1, item2) => {
      return item1.sequence - item2.sequence;
    });
    this.allPictures = this.currentPictures.map((p, index) => new ProductPictureModel(p.fileName, p.fileType, p.docUrl, index, p.sequence, p.shown, p.id));
  }

  private get uploadDisabled(): boolean {
    this.isEmpty = this.allPictures.filter(p => p.shown).length > 0 ? false : true;
    const result = this.allPictures.length === 0 || this.picturesToShow.length > 25 || (this.isEmpty && !this.isRemoved);
    return result;
  }

  private get showFileLengthErrorMessage(): boolean {
      return this.picturesToShow.length > 25;
  }

  private get picturesToShow(): ProductPictureModel[] {
    return this.allPictures.filter(p => p.shown);
  }

  private removePicture(removeItem: ProductPictureModel): void {
    const index = removeItem.key;
    if(this.allPictures[index].id === ""){
      this.allPictures.splice(index, 1);
      const uploadedFilesIndex = this.files.findIndex(f => f.name === removeItem.name);
      this.files.splice(uploadedFilesIndex, 1);
    }
    else{
      this.allPictures[index].shown = false;
      this.removedPicturesIds.push(this.allPictures[index].id);
    }
    this.isRemoved = true;
  }

  private getImageSrc(picture: ProductPictureModel): string {
    if (picture.base64Content.startsWith("http")) {
      return picture.base64Content;
    }
    return `data:${picture.type};base64,${picture.base64Content}`;
  }

  private async updateFiles(files: File[]): Promise<void> {
    let currentIndex = this.allPictures.length;
    let sequence = this.allPictures.length;
    for (const file of files) {
      const documentAsBase64 = await FileHelper.getBase64(file) as string;
      const fileToAdd = new ProductPictureModel(moment().format() + '-' + file.name, file.type, documentAsBase64, currentIndex++, sequence++);
      this.allPictures.push(fileToAdd);
    }
  }

  private async upload(): Promise<void> {
    if (this.uploadDisabled) {
      return;
    }

    try {
      this.isLoading = true;
      const companyId = await CompanyProvider.get().getCompanyIdAsync();
      const pictureSize = this.files.filter(f => f.size / 1024 / 1024 > 0.1);
      if(pictureSize.length > 0){
        NotificationHelper.createErrorNotification(i18n.t("errors.image_requirement_violated", ["FileSize", "100kB"]).toString());
        return;
      }
      this.picturesToShow.forEach((picture, index) => {
        picture.sequence = index;
        if(picture.id !== ""){
          const currentPicture = this.currentPictures.filter(p => p.id === picture.id)[0];
          if(picture.sequence !== currentPicture.sequence || picture.shown !== currentPicture.shown){
            this.uploadedPictures.push(picture);
          }
        }
        else{
          this.uploadedPictures.push(picture);
        }
      });
      if(this.removedPicturesIds.length > 0){
        this.removedPicturesIds.forEach(id => {
          const pictureData = this.allPictures.filter(p => p.id === id)[0];
          pictureData.sequence = 0;
          this.uploadedPictures.push(pictureData);
        });
      }
      const updatedPictures: ProductPictureModel[] = [];
      const result = await this.clientService.uploadOrderProductPicturesAsync(companyId, this.orderLine.orderId, this.orderLine.id, this.uploadedPictures);
      if(result.length > 0){
        const filteredPictures = result.filter(p => p.shown === true);
        filteredPictures.forEach(picture => {
          const picIndex = this.uploadedPictures.findIndex(p => p.id === picture.id);
          if(picIndex !== -1){
            updatedPictures.push(new ProductPictureModel(this.uploadedPictures[picIndex].fileName, this.uploadedPictures[picIndex].documentType, this.uploadedPictures[picIndex].docUrl, this.uploadedPictures[picIndex].sequence, this.uploadedPictures[picIndex].sequence, this.uploadedPictures[picIndex].shown, this.uploadedPictures[picIndex].id));
          }
          else{
            updatedPictures.push(new ProductPictureModel(picture.fileName, 'image/jpeg', picture.docUrl, picture.key, picture.sequence, picture.shown, picture.id));
          }
        });
      }
      this.selectedPicturesUpdated(updatedPictures);
    }
    finally {
      this.isLoading = false;
      this.close();
    }
  }

  @Emit()
  private close(): void { }

  @Emit()
  private selectedPicturesUpdated(pictures: ProductPictureModel[]): void {}
}