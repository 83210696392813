import { Component, Vue, Watch } from 'vue-property-decorator';
import store from './store';
import VueIntercom from 'vue-intercom';
import Hotjar from 'vue-hotjar'
import { mapState } from 'vuex';
import { UserModel } from './models/userModel';

Vue.use(VueIntercom, { appId: 'sxkbyfk6' });
//TTD-3208
Vue.use (Hotjar, {
    id: process.env.VUE_APP_hotjar_id === undefined ? '3667685' : process.env.VUE_APP_hotjar_id,
    isProduction: process.env.VUE_APP_hotjar_isPROD === 'true',
    snippetVersion: 6
})

@Component({
    computed: mapState(['user'])
})
export default class App extends Vue {
    private $intercom: any;
    private user!: UserModel;

    private async created(): Promise<void> {
        window.addEventListener('beforeunload', function (e) {
            store.commit('setInitialState');
        });
    }

    private async initIntercom(): Promise<void> {
        this.$intercom.boot({
            user_id: this.user.userId,
            name: this.user.firstName + ' ' + this.user.lastName,
            email: this.user.email,
            company: {
                id: this.user.companyId,
                name: this.user.companyName,
            }
        });
        // this.$intercom.show();
    }

    @Watch('user', {deep: true})
    private intercom(): void {
        if(window.location.origin.includes('my.tex-tracer.com') || window.location.origin.includes('my-demo.tex-tracer.com')){
            if(this.user && this.user.userId !== "" && (this.user.companyType === 1 || this.user.companyType === 5)){
                this.initIntercom();
            }
            else{
                this.$intercom.shutdown();
            }
        }
    }
}
