import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import lodash from 'lodash';
import { mapState } from "vuex";

// Components
import SearchSupplier from '@/components/general/search-supplier/searchSupplier.vue';
import CreateSupplyChain from '@/views/home/components/createSupplyChain.vue';
import SearchPartner from '@/components/general/search-partner/searchPartner.vue';

// Modals
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { BulkOrderModel } from "@/models/bulkOrderModel";
import { DropdownModel } from "@/models/dropdownModel";
import { Guid } from "@/models/guid";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderLineStepModel } from "@/models/orderLineStepModel";
import { updatePhaseStatusRequestModel } from "@/models/publishUnpublishOrderModel";


// Services
import { OrderService } from '@/services/orderService';
import { SupplierService } from "@/services/supplierService";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    components: { SearchSupplier, CreateSupplyChain, SearchPartner },
    computed: mapState(['companyType', 'companyNameList'])
})

export default class CreateBulkOrder extends Vue {
    @Prop()
    private orders!: CombinedOrderLineModel;

    @Prop()
    private suppliers!: OrderCompanyModel[];

    @Prop()
    private tier1SupplierList!: DropdownModel[];

    @Prop()
    private suppliersLoading!: boolean;

    @Prop()
    private orderConfirming!: boolean;

    @Prop()
    private reviewSelectType!: string;

    @Prop()
    private pendingOrdersBlkSelect!: boolean;

    private orderService: OrderService;

    private supplierService: SupplierService;

    private combinedOrders: BulkOrderModel[];

    private order: BulkOrderModel = new BulkOrderModel();

    private companyType!: number;

    private selected: boolean = false;

    private selectAll: boolean = false;

    private isLoading: boolean = false;

    private showAddStepModal: boolean = false;

    private btnCustomize: string;

    private orderIds: string[] = [];

    private showSearch: boolean = false;

    private partnerNameIndex: number = -1;

    private isDeleting: boolean = false;

    private isConfirming: boolean = false;

    private customiseOrders: CombinedOrderLineModel;

    private isOrderNotSelected: boolean = false;

    private draftOrderLineSteps: OrderLineStepModel[] = [];

    private isForwardToCp: boolean = false;

    private agentName: string = '';

    private forwardToCPLoading: boolean = false;

    private removedOrderId: string = '';

    private isSupplierUpdated: boolean = false;

    private companyNameList!: CompanyNameModel[];

    public constructor() {
        super();
        this.orderService = new OrderService();
        this.supplierService = new SupplierService();
        this.customiseOrders = new CombinedOrderLineModel;
        this.combinedOrders = [];
        this.btnCustomize = this.$t("pages.home.bulk_customize").toString();
    }

    private async created(): Promise<void> {
        try {
            this.isLoading = true;
            this.removedOrderId = '';
            await this.getOrderList();
        }
        finally {
            this.isLoading = false;
        }
    }

    private getOrderList() {
        this.orders.orderDetails.sort((item1, item2) => {
            if(item1.orderNumber < item2.orderNumber || item1.styleNumber < item2.styleNumber) { return -1; }
            if(item1.orderNumber > item2.orderNumber || item1.styleNumber > item2.styleNumber) { return 1; }
            return 0;
        });
        this.orders.orderDetails.forEach(item => {
            this.order = new BulkOrderModel();
            this.order.orderId = item.orderId;
            item.orderLines.forEach(orderItem => {
                this.order.styleNumber = orderItem.styleNumber;
            });
            this.order.orderNumber = item.orderNumber;
            this.order.styleName = item.styleName;
            this.order.colourway = item.orderLines[0].colourway === undefined || item.orderLines[0].colourway.trim() === '' ? '-' : item.orderLines[0].colourway.trim();
            this.order.supplierId = item.toCompanyId;
            this.order.season = item.orderLines[0].season;
            this.order.compositionMainFabric = item.orderLines[0].compositionMainFabric;
            this.order.supplierName = item.toCompanyId === Guid.Empty ? '' : item.toCompanyName;
            this.order.supplierCountry = this.orders.supplierCountry;
            this.order.groupId = this.orders.groupId;
            this.order.checked = false;
            this.combinedOrders.push(this.order);
        });
        this.combinedOrders.forEach((order)=> {
            order.checked = true;
            this.orderIds.push(order.orderId)
        });
        this.selectAll = true;
    }

    private openAddStepModal(): void {
        this.showAddStepModal = true;
    }

    private get customisedOrder(): CombinedOrderLineModel {
        let selectedSupplierName: string;
        const orderIds: string[] = [];
        const orderDetails: OrderLineDisplayModel[] = [];
        this.orderIds.forEach(orderId => {
            if(this.orders.orderIds.indexOf(orderId) !== -1){
                orderIds.push(orderId);
            }
            const order = this.orders.orderDetails.filter(order => order.orderId === orderId)[0];
            if(order !== undefined && order !== null){
                selectedSupplierName = this.combinedOrders.filter(s => s.orderId === orderId)[0].supplierName;
                const supplier = this.suppliers.filter(supplier => supplier.companyName === selectedSupplierName)[0];
                //TTD-2620, Agent orderlines changes, removed code
                order.toCompanyId = this.companyType !== 5 ? supplier.id : order.toCompanyId;
                orderDetails.push(order);
            }
        });
        this.customiseOrders.orderCount = this.orderIds.length;
        this.customiseOrders.productGroup = this.orders.productGroup;
        this.customiseOrders.userName = this.orders.userName;
        this.customiseOrders.orderIds = orderIds;
        //TTD-2620, Agent orderlines changes
        this.customiseOrders.toCompanyId = this.orders.toCompanyId;
        this.customiseOrders.toCompanyName = this.orders.toCompanyName;
        this.customiseOrders.orderDetails = orderDetails;
        this.customiseOrders.groupId = this.orders.groupId;
        return this.customiseOrders;
    }

    private select(): void {
        if (!this.selectAll) {
            this.selectAll = true;
            this.btnCustomize = this.$t("pages.home.bulk_customize").toString();
            this.combinedOrders.forEach(order => {
                order.checked = true;
                if(this.orderIds.indexOf(order.orderId) === -1){
                    this.orderIds.push(order.orderId);
                }
            });
            this.checkContractualPartner()
            return;
        }
        this.combinedOrders.forEach(order => {
            order.checked = false;
            if(this.orderIds.indexOf(order.orderId) !== -1){
                const orderIndex = this.orderIds.indexOf(order.orderId);
                this.orderIds.splice(orderIndex, 1);
            }
        });
        this.selectAll = false;
        this.btnCustomize = this.$t("pages.home.bulk_customize").toString();
        this.isOrderNotSelected = true;
    }

    private orderValue(orderId: string, value: boolean): void {
        if(value){
            const index = this.combinedOrders.findIndex(o => o.orderId === orderId);
            this.combinedOrders[index].checked = value;
            this.orderIds.push(orderId);
            this.isOrderNotSelected = false;
            if(this.orderIds.length === this.combinedOrders.length){
                if(this.orderIds.length === 1){
                    this.btnCustomize = this.$t("pages.home.customize").toString();
                }
                else{
                    this.btnCustomize = this.$t("pages.home.bulk_customize").toString();
                }
                this.selectAll = true;
            }
            else{
                if(this.orderIds.length === 1){
                    this.btnCustomize = this.$t("pages.home.customize").toString();
                }
                else{
                    this.btnCustomize = this.$t("pages.home.bulk_customize").toString();
                }
                this.selectAll = false;
            }
            const allOrders = this.combinedOrders;
            this.combinedOrders = [];
            this.combinedOrders = allOrders;
            this.checkContractualPartner();
        }
        else{
            const index = this.combinedOrders.findIndex(o => o.orderId === orderId);
            this.combinedOrders[index].checked = value;
            const orderIndex = this.orderIds.indexOf(orderId);
            this.orderIds.splice(orderIndex, 1);
            this.selectAll = false;
            if(this.orderIds.length === 1){
                this.btnCustomize = this.$t("pages.home.customize").toString();
            }
            else{
                this.btnCustomize = this.$t("pages.home.bulk_customize").toString();
                if(this.orderIds.length === 0){
                    this.isOrderNotSelected = true;
                }
            }
            const allOrders = this.combinedOrders;
            this.combinedOrders = [];
            this.combinedOrders = allOrders;
            this.checkContractualPartner();
        }
    }

    private openSearchSelectHandler(index: number): void {
        if (this.isDeleting) {
            return;
        }
        this.showSearch = true;
        this.partnerNameIndex = index;
    }

    private setSupplierNameHandler(value: string): void {
        const supplier = this.suppliers.filter(s => s.id === value)[0];
        this.combinedOrders[this.partnerNameIndex].supplierName = supplier.companyName;
        this.combinedOrders[this.partnerNameIndex].supplierId = supplier.id;
        this.combinedOrders[this.partnerNameIndex].supplierCountry = supplier.country;
        this.checkContractualPartner();
        this.showSearch = false;
    }

    private closeSearchHandler(): void {
        this.showSearch = false;
    }

    private invitationSendHandler(): void {
        this.suppliersChanged();
    }

    private remove(orderId: string, index: number): void {
        const confirmationText = this.$t('pages.home.delete_confirmation').toString();
        this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false}).then(() => {
            this.combinedOrders[index].isDeleted = true;
            const combinedOrders = this.combinedOrders;
            this.combinedOrders = [];
            this.combinedOrders = combinedOrders;
            this.deleteOrders([orderId]);
            this.removedOrderId = orderId;
        }).catch(onrejected => {
            this.isDeleting = false;
        });
    }

    private get isOrderConfirming(): boolean {
        if(this.orderConfirming === false && this.isConfirming === true){
            this.isConfirming = false;
            this.close(false);
            return false;
        }
        else{
            return this.orderConfirming;
        }
    }

    private get productGroup(): string {
        return this.orders.productGroup.split(/(?=[A-Z])/).join(' ');
    }

    private draftStepModal(orderLineSteps: OrderLineStepModel[]): void {
        this.showAddStepModal = false;
        this.draftOrderLineSteps = orderLineSteps;
    }

    // check if orders has multiple contractual partner or not
    private checkContractualPartner(): void {
        let uniqueSupplier: string[] = this.combinedOrders.filter(o => o.checked === true).map(o => o.supplierId);
        uniqueSupplier = lodash.uniq(uniqueSupplier);
        uniqueSupplier.length === 1 ? this.isOrderNotSelected = false : this.isOrderNotSelected = true;
    }

    private closeAll(created: boolean){
        this.draftOrderLineSteps = [];
        this.close(created);
    }

    private save(){
        this.isSupplierUpdated = false;
        for(let order of this.orders.orderDetails){
            for(let combinedOrder of this.combinedOrders){
                if(order.orderId === combinedOrder.orderId){
                    order.supplierName =  combinedOrder.supplierName
                    order.supplierId =  combinedOrder.supplierId
                    order.supplierCountry =  combinedOrder.supplierCountry
                    if(order.toCompanyId !== combinedOrder.supplierId){
                        order.toCompanyId = combinedOrder.supplierId
                        order.toCompanyName = combinedOrder.supplierName
                        this.isSupplierUpdated = true;
                    }
                }
            }
        }
        this.confirmSave(this.orders,this.orderIds, this.isSupplierUpdated);
    }

    private async forwardOrdersToCP(): Promise<void> {
        this.forwardToCPLoading = true;
        const selectedOrderIds = this.combinedOrders.filter(o => o.checked).map(or => or.orderId);
        const orderIds: updatePhaseStatusRequestModel = {
            orderIdList: selectedOrderIds
        }
        const response = await this.supplierService.updateOrderlinePhaseStatus(orderIds, 1, 0);
        this.forwardToCPLoading = false;
        if(response.success){
            this.isForwardToCp = false;
            this.close(true);
        }
    }

    private openSearchHandlerForBulk(){
        if(this.selectAll === true){
            this.resetShowBulkSelect([])
        }else{
            this.resetShowBulkSelect(this.orderIds)
        }

    }

    @Watch('orders', {deep: true})
    private reloadOrders(): void {
        if(this.orders.orderDetails.length !== this.combinedOrders.length){
            this.combinedOrders.forEach((order, index) => {
                const orderIndex = this.orders.orderDetails.findIndex(o => order.orderId === o.orderId);
                if(orderIndex === -1){
                    this.combinedOrders.splice(index, 1);
                }
                return;
            });
            if(this.removedOrderId!==''){
                const orderIdIndex = this.orderIds.findIndex((id) => id === this.removedOrderId);
                if(orderIdIndex!== -1){
                    this.orderIds.splice(orderIdIndex, 1);
                    this.removedOrderId = '';
                }
                this.isDeleting = false;
            }
        }
    }

    @Emit()
    private suppliersChanged(): void {
    }

    @Emit()
    private confirmed(order: CombinedOrderLineModel, confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean, agentDetails: OrderCompanyModel): void {
    }

    @Emit()
    private deleteOrders(orderIds: string[]): void {
    }

    @Emit()
    private close(created: boolean): void {
    }

    @Emit()
    private confirmSave(combinedOrders: CombinedOrderLineModel,orderIds:string[], isSupplierUpdated:boolean):void { }

    @Emit()
    private resetShowBulkSelect(orderIds:string[]):void{}

    @Emit()
    private statusOnPendingOrder(confrim:boolean[]):void{}

}
