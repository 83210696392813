import { ComplianceAdditionalInfoModel } from "./complianceAdditionalInfoModel";
import { ComplianceDocSentResponseModel } from "./complianceDocSentResponseModel";

export class ComplianceDocModel{
    public ID!: string;
    public brands!: string[];
    public complianceDocID!: string;
    public createdAt!: string;
    public documentName!: string;
    public additionalInfo!: ComplianceAdditionalInfoModel[];
    public documentStatus!: string;
    public documentVersion!: number;
    public validtoDate!: string;
    public fromCompanyID!: string;
    public fromCompanyName!: string;
    public notifyDays!: number;
    public originalFileHash!: string;
    public originalFileName!: string;
    public originalFileType!: string;
    public docURL!: string;
    public allSCP!: ComplianceDocSentResponseModel[];
    public toCompanyID?: string;
    public toCompanyName?: string;
    public toCompanyContactName?: string;
    public toCompanyContactEmail?: string;
    public signatureRequested?: string;
    public actualOwnerID!: string;
    public originalDocURL!: string;
    public isComplianceCollapse!: boolean;
    public loadingArchieveDoc!: boolean;
}
