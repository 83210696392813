import { Vue, Component, Prop, Watch, Ref, Emit } from "vue-property-decorator";
import lodash from "lodash";
import { mapState } from "vuex";
import { ActionTypes } from "@/store/actions/actions";
import _ from 'underscore';
import ClickOutside from 'vue-click-outside';
import { cloneDeep, uniqBy } from "lodash";
import * as XLSX from 'xlsx';
import moment from "moment";

// Components
import InProgressData from "@/views/footprint/components/inProgressData.vue";
import yarnFootprint from "@/views/footprint/components/yarnFootprint.vue";
import fabricFootprint from "@/views/footprint/components/fabricFootprint.vue";
import wetProcessFootprint from "@/views/footprint/components/wetProcessFootprint.vue";
import garmentFootprint from "@/views/footprint/components/garmentFootprint.vue";
// Sprint 20, TTD-3932
import Steps from '@/views/footprint/components/orderlineSteps.vue';
import StepsLoader from '@/views/orderLines/components/stepsLoader.vue';

// Services
import { ClientRequestFootPrintData } from '@/services/footPrintRequestService';
import { SupplierService } from "@/services/supplierService";
import { OrderService } from "@/services/orderService";
import { ClientService } from "@/services/clientService";

// Helpers
import { FootprintPermissionsHelper } from "@/helpers/footprintPermissionsHelper";
import { StepsHelper } from "@/helpers/stepsHelper";
import { FootprintDropdownHelper } from "@/helpers/footprintDropdownHelper";
import { CountryHelper } from "@/helpers/countryHelper";

// Models
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { ProductGroup } from "@/models/productGroup";
import { DropdownModel } from "@/models/dropdownModel";
import { UserModel } from "@/models/userModel";
import { CompanyModel } from "@/models/companyModel";
import { UserPermissionModel } from "@/models/permissionModel";
import { FootPrintChangedConstant, FootPrintChangedTabConstant, FootPrintCompletedConstant, SaveFootprintDeadlineModel } from "@/models/footprintModel";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { FootprintPartnerModel, OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { Guid } from "@/models/guid";
import { StepState } from "@/models/stepState";
import { FootprintStepsModel, StepReferenceModel } from "@/models/orderLineStepModel";
import { GarmentFabricComposition } from "@/models/garmentFootprintModel";

@Component({
  components: { InProgressData, yarnFootprint, fabricFootprint, wetProcessFootprint, garmentFootprint, Steps, StepsLoader },
  directives: {
    ClickOutside
  },
  computed: mapState(['userRole', 'companyType', 'isFootprintChanged', 'suppliers', 'userPermissions', 'orderLoading', 'orders', 'user', 'company', 'showFootprintConfirmation', 'footprintPartner', 'changedFootprintName', 'orderLineSteps', 'orderLineStepsLoading', 'incorrectFootprintData'])
})
export default class Footprint extends Vue {

  private orderService: OrderService;

  private footprintService: ClientRequestFootPrintData;

  private supplierService: SupplierService;

  private clientService: ClientService;

  private tabIndex: number = 0;

  private selectedTabName: string = "";

  private orders!: OrderLineDisplayModel[];

  private footprintPartner!: FootprintPartnerModel[];

  private orderLoading!: boolean;

  private companyType!: number;

  private suppliers!: OrderCompanyModel[];

  private join: boolean = false;

  private isFootprintChanged!: string;

  private footprintOrders: OrderLineDisplayModel[] = [];

  private searchFilter: string = "";

  private selectedBrand: string = "";

  private selectedProductGroup: ProductGroup = ProductGroup.None;

  private selectedUsername: string = "";

  private selectedSeason: string = "";

  private selectedPartnerID: string = "";

  private dateFrom: string | Date = "";

  private dateTo: string | Date = "";

  private showAllUsers: boolean = true;

  private showAllSeasons: boolean = true;

  private showFilters: boolean = false;

  private selectedFilters: number = 0;

  private user!: UserModel;

  private userRole!: string;

  private company!: CompanyModel;

  private showClearFilter: boolean = false;

  private inProgressCount: number = 0;

  private completedCount: number = 0;

  private showDetails: boolean = false;

  private detailTabIndex: number = 0;

  private footprintOrderDeadline: string = "";

  private footprintFilledByCompanyID: string = "";

  private today: Date = new Date();

  private orderDetail: OrderLineDisplayModel = new OrderLineDisplayModel();

  private showSaveConfirmPopUp: boolean = false;

  private isSaving: boolean = false;

  private userPermissions!: UserPermissionModel | null;

  private showFootprintConfirmation!: string;

  private changedFootprintName!: string;

  private isLoading: boolean = false;

  private isTabLoading: boolean = true;

  private isAllUsersSelected: boolean = false;

  private isAllSeasonsSelected: boolean = false;

  private isPageReload: boolean = false;

  private userCompanyId!: string;

  private orderLineStepsLoading!: boolean;

  private orderLineSteps!: OrderLineStepDisplayModel[];

  private isParallelChainAdded: boolean = false;

  private isAgentStepsLoading: boolean = false;

  private allFootprintSteps: StepReferenceModel[] = [];

  // Sprint 20, TTD-3932
  private hideChain: boolean = false;
  private orderStepsForAgent: OrderLineStepDisplayModel[] = [];

  private totalParallelChains: number = 0;

  private activeStep: string = Guid.Empty;
  //Sprint-22, TTD-3739
  private showViewData: boolean = true;

  // TTD-3941 sprint-24
  private paFootprintOrderID: string = '';
  private paFootprintOrderIDError: boolean = false;
  private paExportFootprintDisabled: boolean = true;
  private mergeCells: any[] = [];
  private mergeYarnCells: any[] = [];
  private mergeFabricCells: any[] = [];
  private mergeWetProcessCells: any[] = [];
  private mergeProductCells: any[] = [];

  @Ref('contentElement') contentElement!: HTMLDivElement;

  public constructor() {
    super();
    this.supplierService = new SupplierService();
    this.footprintService = new ClientRequestFootPrintData();
    this.orderService = new OrderService();
    this.clientService = new ClientService();
  }

  private async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.join = true;
    this.userCompanyId = this.user.companyId !== undefined && this.user.companyId !== '' ? this.user.companyId : Guid.Empty;
    if (this.orderLoading === false && this.orders.length === 0) {
      await this.$store.dispatch(ActionTypes.SET_ORDER_DETAIL);
    }
    if (this.suppliers && this.suppliers.length === 0) {
      this.getSuppliers();
    }
    if (this.$route.params.data == 'true') {
      this.selectATab(0);
      this.join = true;
    }
    //TTD-3795 issue 4
    window.addEventListener('beforeunload', this.pageReload);
  }

  // TTD-3932 Sprint 20
  private get stepsLoading(): boolean {
    let isloading = false;
    if (this.orderLineSteps.length > 0) {
      const steps = this.orderLineSteps.filter(s => s.orderId === this.orderDetail.orderId);
      if (steps.length > 0) {
        isloading = false;
      }
      else {
        isloading = this.orderLineStepsLoading ? true : false;
      }
    } else if (this.isAgentStepsLoading) {
      isloading = this.isAgentStepsLoading;
    }
    else {
      isloading = this.orderLineStepsLoading;
    }
    return isloading;
  }

  // TTD-3932 Sprint 20
  private stepSelected(id: string, stepId: number): void {
    if (this.isFootprintChanged === FootPrintChangedConstant.DATA) {
      this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
      this.showSaveConfirmPopUp = true;
    } else {
      if (stepId === 3 || stepId === 22) {
        this.detailTabIndex = 0;
      } else if (stepId === 4 || stepId === 5 || stepId === 23) {
        this.detailTabIndex = 1;
      } else if (stepId === 6 || stepId === 7 || stepId === 8 || stepId === 31) {
        this.detailTabIndex = 2;
      } else if (stepId === 10 || stepId === 25 || stepId === 32) {
        this.detailTabIndex = 3;
      }
      setTimeout(() => this.$nextTick(() => {
        this.activeStep = id;
      }), 0);
    }
  }

  // TTD-3932 Sprint 20
  private async fetchAgentSteps(): Promise<OrderLineStepDisplayModel[]> {
    this.isAgentStepsLoading = true;
    if (this.orderLineSteps.filter(s => s.orderId === this.orderDetail.orderId).length === 0) {
      this.orderStepsForAgent = await this.orderService.getOrderLineStepsAsync(this.orderDetail.orderId, this.orderDetail.orderLines[0].lineID);
    } else {
      this.orderStepsForAgent = this.orderLineSteps.filter(s => s.orderId === this.orderDetail.orderId);
    }
    this.isAgentStepsLoading = false;
    return this.orderStepsForAgent;
  }

  private async fetchOrderSteps(): Promise<void> {
    await this.clientService.getStepsOnOrderID([this.orderDetail.orderId]);
  }

  // TTD-3932 Sprint 20
  private get steps(): OrderLineStepDisplayModel[] {
    if (this.companyType === 5) {
      this.fetchAgentSteps();
    } else {
      if (this.orderLineSteps.filter(s => s.orderId === this.orderDetail.orderId).length === 0) {
        this.fetchOrderSteps();
      }
    }
    const allSteps = this.companyType === 5 ? this.orderStepsForAgent : this.orderLineSteps.filter(s => s.orderId === this.orderDetail.orderId);
    const steps = allSteps.filter(s => (s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER));
    let count = 0;
    steps.sort((step1, step2) => {
      return step1.sequence - step2.sequence;
    });
    steps.forEach(o => {
      o.parallelChainCount = count;
      if (o.parallelSupplyChain0!.length > 0) {
        count++;
      }
      if (o.parallelSupplyChain1!.length > 0) {
        count++;
      }
      if (o.parallelSupplyChain2!.length > 0) {
        count++;
      }
      if (o.parallelSupplyChain3!.length > 0) {
        count++;
      }
      if (o.parallelSupplyChain4!.length > 0) {
        count++;
      }
    });
    // this.allFootprintSteps = this.getAllFootprintSteps(steps)
    this.totalParallelChains = count;
    count > 0 ? this.isParallelChainAdded = true : this.isParallelChainAdded = false;
    return steps;
  }

  //TTD-3795 issue 4
  private beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.pageReload);
  }

  private get agentPermissions(): boolean {
    return FootprintPermissionsHelper.agentFootprintRole(this.companyType);
  }

  private async getSuppliers(): Promise<void> {
    await this.supplierService.getSupplierOverviewAsync(true);
  }

  private mounted(): void {
    if (window.innerWidth <= 1140) {
      this.showAllUsers = false;
      this.showAllSeasons = false;
      if (this.companyType === 1 && this.userRole !== 'Reader') {
        this.showViewData = false;
      }
    } else if (window.innerWidth < 1280 && window.innerWidth > 1140) {
      this.showAllUsers = true;
      this.showAllSeasons = false;
      if (this.companyType === 1 && this.userRole !== 'Reader') {
        this.showViewData = false;
      }
    } else {
      this.showAllUsers = true;
      this.showAllSeasons = true;
      this.showViewData = true;
    }
    window.onresize = () => {
      if (window.innerWidth <= 1140) {
        this.showAllUsers = false;
        this.showAllSeasons = false;
        if (this.companyType === 1 && this.userRole !== 'Reader') {
          this.showViewData = false;
        }
      } else if (window.innerWidth <= 1280 && window.innerWidth > 1140) {
        this.showAllUsers = true;
        this.showAllSeasons = false;
        if (this.companyType === 1 && this.userRole !== 'Reader') {
          this.showViewData = false;
        }
      } else {
        this.showAllUsers = true;
        this.showAllSeasons = true;
        this.showViewData = true;
      }
    };
  }

  private getSelectedFiltersCount(type: string, value: string) {
    const element = this.contentElement;
    if (type == 'user' && value !== '') {
      this.selectedFilters += 1;
      this.isAllUsersSelected = false;
    } else if (!this.isAllUsersSelected && type == 'user' && value == '') {
      this.isAllUsersSelected = true;
      this.selectedFilters > 0 ? this.selectedFilters = --this.selectedFilters : '';
    }
    if (type == 'season' && value !== '') {
      this.selectedFilters += 1;
      this.isAllSeasonsSelected = false;
    } else if (!this.isAllSeasonsSelected && type == 'season' && value == '') {
      this.isAllSeasonsSelected = true;
      this.selectedFilters > 0 ? this.selectedFilters = --this.selectedFilters : '';
    }
    element.click();
  }

  private displayFiltersList() {
    const dropDown = document.querySelector('.dropdown-menu') as HTMLInputElement;
    if (dropDown != null) {
      dropDown.style.display = 'block';
    }
  }

  private get filterOrders(): OrderLineDisplayModel[] {
    this.showClearFilter = false;
    this.footprintOrders = this.orders.filter(o => o.footprintFlag === "TRUE");
    let orders: OrderLineDisplayModel[] = [];
    const inProgress = this.footprintOrders.filter(o => (o.yarn.footprintStatus !== FootPrintCompletedConstant.COMPLETED
      || o.yarn.transportStatus !== FootPrintCompletedConstant.COMPLETED
      || o.fabric.footprintStatus !== FootPrintCompletedConstant.COMPLETED
      || o.fabric.footprintStatus !== FootPrintCompletedConstant.COMPLETED
      || o.wetprocess.footprintStatus !== FootPrintCompletedConstant.COMPLETED
      || o.wetprocess.footprintStatus !== FootPrintCompletedConstant.COMPLETED
      || o.garment.footprintStatus !== FootPrintCompletedConstant.COMPLETED
      || o.garment.footprintStatus !== FootPrintCompletedConstant.COMPLETED || o.actionOnFootprint.status === FootPrintCompletedConstant.FORCEINPROGRESS) && o.actionOnFootprint.status !== FootPrintCompletedConstant.FORCECOMPLETED);

    const completed = this.footprintOrders.filter(o => (o.yarn.footprintStatus === FootPrintCompletedConstant.COMPLETED
      && o.yarn.transportStatus === FootPrintCompletedConstant.COMPLETED
      && o.fabric.footprintStatus === FootPrintCompletedConstant.COMPLETED
      && o.fabric.footprintStatus === FootPrintCompletedConstant.COMPLETED
      && o.wetprocess.footprintStatus === FootPrintCompletedConstant.COMPLETED
      && o.wetprocess.footprintStatus === FootPrintCompletedConstant.COMPLETED
      && o.garment.footprintStatus === FootPrintCompletedConstant.COMPLETED
      && o.garment.footprintStatus === FootPrintCompletedConstant.COMPLETED
      && o.actionOnFootprint.status !== FootPrintCompletedConstant.FORCEINPROGRESS) || o.actionOnFootprint.status === FootPrintCompletedConstant.FORCECOMPLETED);

    this.inProgressCount = inProgress.length;
    this.completedCount = completed.length;
    if (this.tabIndex === 0) {
      orders = inProgress;
    }
    else {
      orders = completed;
    }
    if (this.searchFilter !== "") {
      this.showClearFilter = true;
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      orders = orders.filter(o => o.orderNumber.toLowerCase().includes(lowerCaseFilter)
        || (o.styleName != null && o.styleName.toLowerCase().includes(lowerCaseFilter))
        || (o.styleNumber != null && o.styleNumber.toLowerCase().includes(lowerCaseFilter))
        || (o.styleNumber != null && o.styleNumber.toLowerCase().includes(lowerCaseFilter))
        || (o.brandName != null && o.brandName.toLowerCase().includes(lowerCaseFilter)));
    }
    if (this.selectedBrand !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => o.brandName === this.selectedBrand);
    }
    if (this.selectedProductGroup !== ProductGroup.None) {
      this.showClearFilter = true;
      orders = orders.filter(o => o.productGroup === this.selectedProductGroup);
    }
    if (this.selectedUsername !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => o.fromCompanyUser.userId === this.selectedUsername);
    }
    if (this.selectedSeason !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => o.orderLines[0].season === this.selectedSeason);
    }
    if (this.selectedPartnerID !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => o.toCompanyId === this.selectedPartnerID);
    }
    if (this.tabIndex === 0) {
      this.inProgressCount = orders.length;
    } else {
      this.completedCount = orders.length;
    }
    //Sprint-22 TTD-3739
    if (orders.length > 0) {
      orders.forEach(o => o.isFpOrderSelected = false);
    }
    return orders;
  }

  private clickedOutsideFilters(): void {
    this.showFilters = false;
  }

  private filtersClicked(): void {
    if (this.showFilters) {
      this.showFilters = false;
    } else {
      this.showFilters = true;
    }
  }

  private get brands(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_brands").toString() }];
    const distinctBrands: any[] = [];
    this.orders.filter(o => o.footprintFlag === "TRUE").filter((item) => {
      const i = distinctBrands.findIndex(x => (x.orderLines[0].brandName == item.orderLines[0].brandName));
      if (i <= -1 && item.orderLines[0].brandName !== null && item.orderLines[0].brandName !== "") {
        distinctBrands.push(item);
      }
      return null;
    });
    distinctBrands.forEach(line => {
      if (line.orderLines[0].season !== null) {
        options.push(new DropdownModel(line.orderLines[0].brandName, line.orderLines[0].brandName));
      }
    });

    return options;
  }

  private get seasons(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_seasons").toString() }];
    const distinctSeasons: any[] = [];
    this.orders.filter(o => o.footprintFlag === "TRUE").filter((item) => {
      const i = distinctSeasons.findIndex(x => (x.orderLines[0].season == item.orderLines[0].season));
      if (i <= -1 && item.orderLines[0].season !== null && item.orderLines[0].season !== "") {
        distinctSeasons.push(item);
      }
      return null;
    });
    distinctSeasons.forEach(line => {
      if (line.orderLines[0].season !== null) {
        options.push(new DropdownModel(line.orderLines[0].season, line.orderLines[0].season));
      }
    });

    return options;
  }

  private get productGroups(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: 0, text: this.$t("pages.order_lines.all_productgroups").toString() }];
    this.orders.filter(o => o.footprintFlag === "TRUE").forEach(line => {
      if (options.findIndex(option => option.value === line.productGroup) === -1) {
        const productGroupName = this.$t(`enums.product_group.${line.productGroup}`).toString().split(/(?=[A-Z])/).join(" ");
        options.push(new DropdownModel(line.productGroup, productGroupName.charAt(0).toUpperCase() + productGroupName.slice(1).toLowerCase()));
      }
    });

    return options;
  }

  private get username(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_usernames").toString() }];
    const distinctUsers: any[] = [];
    this.orders.filter(o => o.footprintFlag === "TRUE").filter((item) => {
      const i = distinctUsers.findIndex(x => (x.fromCompanyUser.userId == item.fromCompanyUser.userId));
      if (i <= -1) {
        distinctUsers.push(item);
      }
      return null;
    });
    distinctUsers.forEach(line => {
      let fullname = line.fromCompanyUser.firstName + ' ' + line.fromCompanyUser.lastName;
      if (fullname.length > 20) {
        fullname = fullname.slice(0, 14).concat('...');
      }
      options.push(new DropdownModel(line.fromCompanyUser.userId, fullname));
    });
    return options;
  }

  private get openDate(): Date {
    return this.today;
  }

  private get supplyChainPartner(): DropdownModel[] {
    let options: DropdownModel[] = [];
    options = this.orders.filter(o => o.footprintFlag === "TRUE").filter(o => (o.orderLinePhase !== 0 && o.orderLineStatus !== 0) || (o.orderLinePhase !== 0 && o.orderLineStatus == 0)).map(o => new DropdownModel(o.toCompanyId, o.toCompanyName));
    options = lodash.uniqBy(options, 'value');
    options.splice(0, 0, { value: "", text: "All partners" });
    return options;
  }

  private async clearAllFilters(): Promise<void> {
    this.searchFilter = "";
    this.selectedBrand = "";
    this.selectedProductGroup = ProductGroup.None;
    this.selectedUsername = "";
    this.selectedSeason = "";
    this.dateFrom = "";
    this.dateTo = "";
    this.selectedPartnerID = "";
    this.showClearFilter = false;
    this.selectedFilters = 0;
    this.footprintOrders = this.orders.filter(o => o.footprintFlag === "TRUE");
    this.isAllSeasonsSelected = false;
    this.isAllUsersSelected = false;
  }

  private selectATab(index: number): void {
    this.tabIndex = index;
    this.join = true;
  }

  private selectDetailTab(newTabIndex: number, prevTabIndex: number, bvEvent: any): void {
    const order: OrderLineDisplayModel = this.orders.filter(o => o.orderId === this.orderDetail.orderId)[0];
    switch (newTabIndex) {
      case 0:
        if (this.isFootprintChanged === FootPrintChangedConstant.DATA) {
          bvEvent.preventDefault();
          this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
          this.showSaveConfirmPopUp = true;
        }
        else {
          this.detailTabIndex = newTabIndex;
        }
        break;

      case 1:
        if (this.isFootprintChanged === FootPrintChangedConstant.DATA) {
          bvEvent.preventDefault();
          this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
          this.showSaveConfirmPopUp = true;
        }
        else {
          if (this.isLoading || this.isFootprintChanged === FootPrintChangedConstant.DATA) {
            if (this.isFootprintChanged === FootPrintChangedConstant.DATA) {
              this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
              this.showSaveConfirmPopUp = true;
            }
            bvEvent.preventDefault();
          }
        }
        break;

      case 2:
        if (this.isFootprintChanged === FootPrintChangedConstant.DATA) {
          bvEvent.preventDefault();
          this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
          this.showSaveConfirmPopUp = true;
        }
        else {
          if (this.isLoading || this.isFootprintChanged === FootPrintChangedConstant.DATA) {
            if (this.isFootprintChanged === FootPrintChangedConstant.DATA) {
              this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
              this.showSaveConfirmPopUp = true;
            }
            bvEvent.preventDefault();
          }
        }
        break;

      case 3:
        if (this.isFootprintChanged === FootPrintChangedConstant.DATA) {
          bvEvent.preventDefault();
          this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
          this.showSaveConfirmPopUp = true;
        }
        else {
          if (this.isLoading || this.isFootprintChanged === FootPrintChangedConstant.DATA) {
            if (this.isFootprintChanged === FootPrintChangedConstant.DATA) {
              this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
              this.showSaveConfirmPopUp = true;
            }
            bvEvent.preventDefault();
          }
        }
        break;
    }
  }

  private joinBeta(): void {
    this.join = true;
  }

  private async viewFootprintDataHandler(order: OrderLineDisplayModel): Promise<void> {
    this.isLoading = true;
    this.showDetails = true;
    this.isTabLoading = true;
    setTimeout(() => this.$nextTick(() => {
      this.contentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }), 0);
    this.detailTabIndex = 0;
    this.orderDetail = this.orders.filter(o => o.orderId === order.orderId)[0];
    this.footprintOrderDeadline = this.orderDetail.footprintDeadline;
    this.footprintFilledByCompanyID = this.orderDetail.footprintFilledByCompanyID;
    const footprintPartner = this.footprintPartner.filter(p => p.orderId === this.orderDetail.orderId);
    if (footprintPartner.length === 0) {
      await this.footprintService.getFootprintPartnerAsync(this.orderDetail.orderId, this.orderDetail.id);
    }
    this.isLoading = false;
  }

  private get yarnStatusColor(): string {
    let status = "red";
    const order = this.orders.filter(o => o.orderId === this.orderDetail.orderId)[0];
    if (order && (order.yarn.footprintStatus === FootPrintCompletedConstant.COMPLETED && order.yarn.transportStatus === FootPrintCompletedConstant.COMPLETED)) {
      status = 'green';
    }
    else if (order && (((order.yarn.footprintStatus === "" || order.yarn.footprintStatus === FootPrintCompletedConstant.INCOMPLETE) && order.yarn.transportStatus === "") || (order.yarn.footprintStatus === FootPrintCompletedConstant.INCOMPLETE && (order.yarn.transportStatus === FootPrintCompletedConstant.INCOMPLETE || order.yarn.transportStatus === '')))) {
      status = 'red';
    }
    else {
      status = 'orange';
    }
    return status;
  }

  private get fabricStatusColor(): string {
    const order = this.orders.filter(o => o.orderId === this.orderDetail.orderId)[0];
    let status = "red";
    if (order && (order.fabric.footprintStatus === FootPrintCompletedConstant.COMPLETED && order.fabric.transportStatus === FootPrintCompletedConstant.COMPLETED)) {
      status = 'green';
    }
    else if (order && (((order.fabric.footprintStatus === "" || order.fabric.footprintStatus === FootPrintCompletedConstant.INCOMPLETE) && order.fabric.transportStatus === "") || (order.fabric.footprintStatus === FootPrintCompletedConstant.INCOMPLETE && (order.fabric.transportStatus === FootPrintCompletedConstant.INCOMPLETE || order.fabric.transportStatus === '')))) {
      status = 'red';
    }
    else {
      status = 'orange';
    }
    return status;
  }

  private get wetStatusColor(): string {
    const order = this.orders.filter(o => o.orderId === this.orderDetail.orderId)[0];
    let status = "red";
    if (order && (order.wetprocess.footprintStatus === FootPrintCompletedConstant.COMPLETED && order.wetprocess.transportStatus === FootPrintCompletedConstant.COMPLETED)) {
      status = 'green';
    }
    else if (order && (((order.wetprocess.footprintStatus === "" || order.wetprocess.footprintStatus === FootPrintCompletedConstant.INCOMPLETE) && order.wetprocess.transportStatus === "") || (order.wetprocess.footprintStatus === FootPrintCompletedConstant.INCOMPLETE && (order.wetprocess.transportStatus === FootPrintCompletedConstant.INCOMPLETE || order.wetprocess.transportStatus === '')))) {
      status = 'red';
    }
    else {
      status = 'orange';
    }
    return status;
  }

  private get garmentStatusColor(): string {
    const order = this.orders.filter(o => o.orderId === this.orderDetail.orderId)[0];
    let status = "red";
    if (order && (order.garment.footprintStatus === FootPrintCompletedConstant.COMPLETED && order.garment.transportStatus === FootPrintCompletedConstant.COMPLETED)) {
      status = 'green';
    }
    else if (order && ((order.garment.footprintStatus === "" && order.garment.transportStatus === "") || (order.garment.footprintStatus === FootPrintCompletedConstant.INCOMPLETE && order.garment.transportStatus === FootPrintCompletedConstant.INCOMPLETE))) {
      status = 'red';
    }
    else {
      status = 'orange';
    }
    return status;
  }

  private showFootprintOrderList(): void {
    if (this.isFootprintChanged !== '') {
      this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
      this.showSaveConfirmPopUp = true;
    }
    else {
      this.$store.commit("setFootprintChanged", '');
      this.$store.commit("setChangedFootprintName", '');
      this.$store.commit('setIncorrectFootprintData', false);
      this.showDetails = false;
      this.selectATab(this.tabIndex);
    }
  }

  private setFpDeadline(date: Date | null): void {
    if (this.$store.getters.isFootprintChanged !== FootPrintChangedConstant.DATA) {
      this.$store.commit('setFootprintChanged', FootPrintChangedConstant.DEADLINE);
    }
  }

  private closeSaveFootprintModal(): void {
    this.$store.commit('setFootprintChanged', '');
    this.$store.commit('setFootprintConfirmation', '');
    this.showSaveConfirmPopUp = false;
    this.footprintOrderDeadline = this.orderDetail.footprintDeadline;
  }

  private async saveFootprintDeadline(): Promise<void> {
    this.isSaving = true;
    const saveFootprintDeadline: SaveFootprintDeadlineModel = {
      footprintDeadline: new Date(this.footprintOrderDeadline).toISOString()
    };
    const response = await this.footprintService.saveFootprintDeadline(this.orderDetail.orderId, saveFootprintDeadline, this.user.companyId);
    if (response.success) {
      this.$store.commit('setFootprintChanged', '');
      this.$store.commit('setFootprintConfirmation', '');
      this.isSaving = false;
      if (this.showSaveConfirmPopUp) {
        this.showSaveConfirmPopUp = false;
        this.showDetails = false;
        this.selectATab(0);
      }
      else {
        this.showSaveConfirmPopUp = false;
      }
    }
    else {
      this.isSaving = false;
      this.showSaveConfirmPopUp = false;
    }
  }

  private getAgentPermissions(filledBy: string): boolean {
    return this.companyType === 5 ? FootprintPermissionsHelper.getAgentFootprintRole(this.companyType, filledBy, this.userCompanyId) : false;
  }

  private saveFootprintData(): void {
    switch (this.changedFootprintName) {
      case FootPrintChangedTabConstant.YARN_FP: {
        const func = this.$refs.yarn as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveYarnFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.YARN_TP: {
        const func = this.$refs.yarn as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveYarnTransportData();
        break;
      }
      case FootPrintChangedTabConstant.FABRIC_FP: {
        const func = this.$refs.fabric as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveFabricFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.FABRIC_TP: {
        const func = this.$refs.fabric as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveFabricTransportData();
        break;
      }
      case FootPrintChangedTabConstant.WET_FP: {
        const func = this.$refs.wet as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.beforeSave();
        break;
      }
      case FootPrintChangedTabConstant.WET_TP: {
        const func = this.$refs.wet as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveWetTransportData();
        break;
      }
      case FootPrintChangedTabConstant.GARMENT_FP: {
        const func = this.$refs.garment as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.beforeSave();
        break;
      }
      case FootPrintChangedTabConstant.GARMENT_TP: {
        const func = this.$refs.garment as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveGarmentTransportData();
        break;
      }
      default:
        this.$store.commit('setFootprintChanged', '');
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        break;
    }
  }

  private clearFootprintData(): void {
    switch (this.changedFootprintName) {
      case FootPrintChangedTabConstant.YARN_FP: {
        const func = this.$refs.yarn as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearYarnFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.YARN_TP: {
        const func = this.$refs.yarn as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearYarnTransportData();
        break;
      }
      case FootPrintChangedTabConstant.FABRIC_FP: {
        const func = this.$refs.fabric as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearFabricFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.FABRIC_TP: {
        const func = this.$refs.fabric as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearFabricTransportData();
        break;
      }
      case FootPrintChangedTabConstant.WET_FP: {
        const func = this.$refs.wet as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearWetFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.WET_TP: {
        const func = this.$refs.wet as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearWetTransportData();
        break;
      }
      case FootPrintChangedTabConstant.GARMENT_FP: {
        const func = this.$refs.garment as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearGarmentFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.GARMENT_TP: {
        const func = this.$refs.garment as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearGarmtTransportData();
        break;
      }
      default:
        this.$store.commit('setFootprintChanged', '');
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        break;
    }
  }

  private get getfootprintPermission(): boolean {
    if (this.userPermissions !== null) {
      return this.userPermissions.accessFootprintScreen === 1 ? true : false;
    }
    return false;
  }

  private navigateTabHandler(index: number): void {
    this.detailTabIndex = index;
  }

  //TTD-3795 issue 4
  private pageReload() {
    this.isPageReload = !this.isPageReload;
  }

  // Sprint 20, TTD-3932
  private hideSection(): boolean {
    this.hideChain = !this.hideChain;
    return this.hideChain;
  }

  private isFootprintStep(stepId: number): boolean {
    const allFootprintStepIds = StepsHelper.getFootprintSteps().map(s => s.id);
    if (allFootprintStepIds.includes(stepId)) {
      return true;
    }
    return false;
  }

  private async getRefNumber(steps: StepReferenceModel[]): Promise<void> {
    this.allFootprintSteps = steps;
    if (this.orderDetail.yarn.footprint === undefined) {
      const result = await this.footprintService.getFootprintOnOrderAsync(this.orderDetail.orderId);
      if (result) {
        this.orderDetail = this.orders.filter(o => o.orderId === this.orderDetail.orderId)[0];
        this.footprintOrderDeadline = this.orderDetail.footprintDeadline;
      }
    }
    this.isTabLoading = false;
  }

  //Sptint-22 TTD-4290
  private resetConfirmationText(): void {
    this.showSaveConfirmPopUp = false;
    this.$store.commit('setFootprintConfirmation', '');
  }

  //TTD-4276 issue1
  private showConfirmationPopup(): void {
    this.showSaveConfirmPopUp = true;
  }

  // TTD-3941 Sprint 24
  private get isPlatformAdmin(): boolean {
    return this.user && (this.user.email === process.env.VUE_APP_platform_admin_email) ? true : false;
  }

  private validateOrderID(): void {
    if (this.paFootprintOrderID.length !== 36) {
      this.paFootprintOrderID.length === 0 ? this.paFootprintOrderIDError = false : this.paFootprintOrderIDError = true;
      this.paExportFootprintDisabled = true;
    } else {
      this.paFootprintOrderIDError = false;
      this.paExportFootprintDisabled = false;
    }
  }

  private async exportSelectedFootprint(selectedFootprintOrders: OrderLineDisplayModel[]): Promise<void> {
    const wb = XLSX.utils.book_new();
    for (let fpOrderIndex = 0; fpOrderIndex < selectedFootprintOrders.length; fpOrderIndex++) {
      const orderWS = await this.getFootprintOrder(selectedFootprintOrders[fpOrderIndex]);
      if (orderWS) {
        let wsName = `${selectedFootprintOrders[fpOrderIndex].orderNumber.replaceAll('/', '-').replaceAll('[', '-').replaceAll(']', '-').replaceAll('?', '-').replaceAll('*', '-')}-${selectedFootprintOrders[fpOrderIndex].styleNumber.replaceAll('/', '-').replaceAll('[', '-').replaceAll(']', '-').replaceAll('?', '-').replaceAll('*', '-')}`;
        if(wsName.length > 30){
          wsName = wsName.slice(0, 30);
        }
        XLSX.utils.book_append_sheet(wb, orderWS, wsName);
      }
    }
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    XLSX.writeFile(wb, `footprint report - ${moment().format('DDMMYYYY')}.xlsx`);
  }

  private async getFootprintOrder(order: OrderLineDisplayModel): Promise<any> {
    const result = await this.footprintService.getFootprintOnOrderAsync(order.orderId);
    if (result) {
      const footprintOrder = this.orders.filter(o => o.orderId === order.orderId)[0];
      const orderWS = await this.exportLCA(footprintOrder);
      return orderWS;
    }
    return undefined;
  }

  private async paExportFootprint(): Promise<void> {
    if (this.paFootprintOrderID !== '' && !this.paFootprintOrderIDError) {
      const result = await this.footprintService.getPAFootprintOnOrderAsync(this.paFootprintOrderID);
      if (result.success) {
        const orderWS = await this.exportLCA(result.orders);
        const wb = XLSX.utils.book_new();
        let wsName = `${result.orders.orderNumber.replaceAll('/', '-').replaceAll('[', '-').replaceAll(']', '-').replaceAll('?', '-').replaceAll('*', '-')}-${result.orders.styleNumber.replaceAll('/', '-').replaceAll('[', '-').replaceAll(']', '-').replaceAll('?', '-').replaceAll('*', '-')}`;
        if(wsName.length > 30){
          wsName = wsName.slice(0, 30);
        }
        XLSX.utils.book_append_sheet(wb, orderWS, wsName);
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        XLSX.writeFile(wb, `footprint report - ${moment().format('DDMMYYYY')}.xlsx`);
      }
    }
  }

  private async exportLCA(footprintOrder: OrderLineDisplayModel): Promise<any> {
    try {
      let order = footprintOrder;
      const orderData = [
        ['Client name', 'CP', 'Delegated CP', 'Agent', 'OrderNumber', 'StyleNumber', 'StyleName', 'ColourWay', 'BrandName', 'Season', 'Quantity'],
        [order.fromCompanyName, order.toCompanyName, order.delegateCompanyUser.companyName, order.agentCompanyUser.name, order.orderNumber, order.styleNumber, order.styleName, order.orderLines[0].colourway, order.orderLines[0].brandName, order.orderLines[0].season, order.orderLines[0].totalQuantityPerCw.toString()],
        [],
      ];
      this.mergeCells = [];
      this.mergeYarnCells = [];
      this.mergeFabricCells = [];
      this.mergeWetProcessCells = [];
      this.mergeProductCells = [];
      const [yarnData, fabricData, wetProcessData, productData] = await Promise.all([
        this.fetchYarnData(order),
        this.fetchFabricData(order),
        this.fetchWetProcessData(order),
        this.fetchProductData(order),
      ]);
      orderData.push(...yarnData);
      if(fabricData.length > 0){
        orderData.push([]);
      }
      orderData.push(...fabricData);
      if(wetProcessData.length > 0){
        orderData.push([]);
      }
      orderData.push(...wetProcessData);
      if(productData.length > 0){
        orderData.push([]);
      }
      orderData.push(...productData);
      const orderWS = XLSX.utils.aoa_to_sheet(orderData);
      this.mergeCells.push(...this.mergeYarnCells);
      this.mergeCells.push(...this.mergeFabricCells);
      this.mergeCells.push(...this.mergeWetProcessCells);
      this.mergeCells.push(...this.mergeProductCells);
      orderWS["!merges"] = this.mergeCells;
      return orderWS;
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  }

  private async fetchYarnData(order: OrderLineDisplayModel) {
    const spinningMethod = FootprintDropdownHelper.getSpinningMethodList();
    const transportType = FootprintDropdownHelper.getModeOfTransportList();
    const fiberType = FootprintDropdownHelper.getFiberTypeList();
    const fiberOriginList = CountryHelper.getCountryList();
    const transportUnitList = FootprintDropdownHelper.getTransportUnit();
    if (order.yarn.footprint.length > 0) {
      const yarnData = [
        ['YARN', '', 'Assigned to supplier'],
        ['', '', 'Location of supplier'],
        ['', '', '',],
        ['', '', 'Yarn reference',],
        ['', '', 'Spinning method'],
        ['', '', 'Yarn count'],
        ['', '', 'Unit'],
        ['', '', ''],
        ['', 'Fiber', 'Type of Fiber'],
        ['', '', '% of fiber in yarn'],
        ['', '', 'Origin of fiber'],
        ['', 'Energy use', 'Energy use per km yarn'],
        ['', '', '% wind energy'],
        ['', '', '% solar energy'],
        ['', 'Transport', 'Mode of transport'],
        ['', '', 'Distance'],
        ['', '', 'Unit'],
      ];
      this.mergeYarnCells.push({ s: { r: 3, c: 0 }, e: { r: 19, c: 0 } }, { s: { r: 3, c: 1 }, e: { r: 10, c: 1 } }, { s: { r: 11, c: 1 }, e: { r: 13, c: 1 } }, { s: { r: 14, c: 1 }, e: { r: 16, c: 1 } }, { s: { r: 17, c: 1 }, e: { r: 19, c: 1 } });
      const uniqStepID = uniqBy(order.yarn.footprint, 'stepID').map(u => u.stepID);
      for (let i = 0; i < uniqStepID.length; i++) {
        const yarnFootprint = order.yarn.footprint.filter(y => y.stepID === uniqStepID[i]);
        if (yarnFootprint.length > 0) {
          const transport = order.yarn.transport.filter(t => t.stepID === uniqStepID[i]);
          const modeOfTransport = transport.length > 0 ? transport[0].modeOfTransport : [];
          const startIndex = yarnData[0].length;
          let count = 0;
          yarnFootprint.forEach(yf => {
            count = count + (yf.fiber.length > 0 ? yf.fiber.length + 1 : 0);
          });
          if (count >= modeOfTransport.length) {
            let mTCount = 0;
            for (let yarnIndex = 0; yarnIndex < yarnFootprint.length; yarnIndex++) {
              yarnData[0].splice(yarnData[0].length, 0, '');
              yarnData[1].splice(yarnData[1].length, 0, '');
              this.mergeYarnCells.push({ s: { r: 5, c: yarnData[2].length }, e: { r: 5, c: yarnData[2].length + yarnFootprint[yarnIndex].fiber.length } });
              yarnData[2].splice(yarnData[2].length, 0, `Yarn ${yarnIndex + 1}`);
              this.mergeYarnCells.push({ s: { r: 6, c: yarnData[3].length }, e: { r: 6, c: yarnData[3].length + yarnFootprint[yarnIndex].fiber.length } });
              yarnData[3].splice(yarnData[3].length, 0, yarnFootprint[yarnIndex].reference.referenceNo);
              this.mergeYarnCells.push({ s: { r: 7, c: yarnData[4].length }, e: { r: 7, c: yarnData[4].length + yarnFootprint[yarnIndex].fiber.length } });
              yarnData[4].splice(yarnData[4].length, 0, yarnFootprint[yarnIndex].spinning.spinningMethod !== '' ? (spinningMethod.filter(s => s.value === yarnFootprint[yarnIndex].spinning.spinningMethod).length > 0 ? spinningMethod.filter(s => s.value === yarnFootprint[yarnIndex].spinning.spinningMethod)[0].text : '') : '');
              this.mergeYarnCells.push({ s: { r: 8, c: yarnData[5].length }, e: { r: 8, c: yarnData[5].length + yarnFootprint[yarnIndex].fiber.length } });
              yarnData[5].splice(yarnData[5].length, 0, yarnFootprint[yarnIndex].yarnCount.count !== -1 ? yarnFootprint[yarnIndex].yarnCount.count.toString() : '');
              this.mergeYarnCells.push({ s: { r: 9, c: yarnData[6].length }, e: { r: 9, c: yarnData[6].length + yarnFootprint[yarnIndex].fiber.length } });
              yarnData[6].splice(yarnData[6].length, 0, yarnFootprint[yarnIndex].yarnUnit.unit !== '' ? yarnFootprint[yarnIndex].yarnUnit.unit : '');
              this.mergeYarnCells.push({ s: { r: 14, c: yarnData[11].length }, e: { r: 14, c: yarnData[11].length + yarnFootprint[yarnIndex].fiber.length } });
              yarnData[11].splice(yarnData[11].length, 0, yarnFootprint[yarnIndex].totalElectricEnergy.value !== -1 ? yarnFootprint[yarnIndex].totalElectricEnergy.value.toString() : '');
              this.mergeYarnCells.push({ s: { r: 15, c: yarnData[12].length }, e: { r: 15, c: yarnData[12].length + yarnFootprint[yarnIndex].fiber.length } });
              yarnData[12].splice(yarnData[12].length, 0, yarnFootprint[yarnIndex].windEnergy.value !== -1 ? yarnFootprint[yarnIndex].windEnergy.value.toString() : '');
              this.mergeYarnCells.push({ s: { r: 16, c: yarnData[13].length }, e: { r: 16, c: yarnData[13].length + yarnFootprint[yarnIndex].fiber.length } });
              yarnData[13].splice(yarnData[13].length, 0, yarnFootprint[yarnIndex].solarEnergy.value !== -1 ? yarnFootprint[yarnIndex].solarEnergy.value.toString() : '');
              yarnFootprint[yarnIndex].fiber.forEach((fiber, fiberIndex) => {
                yarnData[0].splice(yarnData[0].length, 0, '');
                yarnData[1].splice(yarnData[1].length, 0, '');
                yarnData[2].splice(yarnData[2].length, 0, '');
                yarnData[3].splice(yarnData[3].length, 0, '');
                yarnData[4].splice(yarnData[4].length, 0, '');
                yarnData[5].splice(yarnData[5].length, 0, '');
                yarnData[6].splice(yarnData[6].length, 0, '');
                yarnData[7].splice(yarnData[7].length, 0, `Fiber-${fiberIndex + 1}`);
                const fiberTypeValue = fiberType.find(f => f.value === fiber.fiberType.type);
                if (fiberTypeValue) {
                  yarnData[8].splice(yarnData[8].length, 0, fiberTypeValue.text);
                } else {
                  yarnData[8].splice(yarnData[8].length, 0, '');
                }
                yarnData[9].splice(yarnData[9].length, 0, fiber.fiberValue.value !== -1 ? fiber.fiberValue.value.toString() : '');
                const fiberOrigin = fiberOriginList.find(f => f.value === fiber.originOfFiber.value);
                if (fiberOrigin) {
                  yarnData[10].splice(yarnData[10].length, 0, fiberOrigin.text);
                } else {
                  yarnData[10].splice(yarnData[10].length, 0, '');
                }
                yarnData[11].splice(yarnData[11].length, 0, '');
                yarnData[12].splice(yarnData[12].length, 0, '');
                yarnData[13].splice(yarnData[13].length, 0, '');
                if (modeOfTransport.length > 0) {
                  if (modeOfTransport[mTCount]) {
                    if (modeOfTransport[mTCount].transportType.value !== '') {
                      const transportValue = transportType.find(t => t.value === modeOfTransport[mTCount].transportType.value);
                      if (transportValue) {
                        yarnData[14].splice(yarnData[14].length, 0, transportValue.text);
                      } else {
                        yarnData[14].splice(yarnData[14].length, 0, '');
                      }
                    } else {
                      yarnData[14].splice(yarnData[14].length, 0, '');
                    }
                    if (modeOfTransport[mTCount].distance.value !== -1) {
                      yarnData[15].splice(yarnData[15].length, 0, modeOfTransport[mTCount].distance.value.toString());
                    } else {
                      yarnData[15].splice(yarnData[15].length, 0, '');
                    }
                    if (modeOfTransport[mTCount].distanceUnit.unit !== '') {
                      const unit = transportUnitList.find(u => u.value === modeOfTransport[mTCount].distanceUnit.unit);
                      if (unit) {
                        yarnData[16].splice(yarnData[16].length, 0, unit.text);
                      }
                    } else {
                      yarnData[16].splice(yarnData[16].length, 0, '');
                    }
                  } else {
                    yarnData[14].splice(yarnData[14].length, 0, '');
                    yarnData[15].splice(yarnData[15].length, 0, '');
                    yarnData[16].splice(yarnData[16].length, 0, '');
                  }
                  mTCount++;
                } else {
                  yarnData[14].splice(yarnData[14].length, 0, '');
                  yarnData[15].splice(yarnData[15].length, 0, '');
                  yarnData[16].splice(yarnData[16].length, 0, '');
                }
              });
              yarnData[7].splice(yarnData[7].length, 0, 'Total');
              yarnData[8].splice(yarnData[8].length, 0, '');
              yarnData[9].splice(yarnData[9].length, 0, yarnFootprint[yarnIndex].total.totalcount !== -1 ? yarnFootprint[yarnIndex].total.totalcount.toString() : '');
              yarnData[10].splice(yarnData[10].length, 0, '');
              if (modeOfTransport.length > 0) {
                if (modeOfTransport[mTCount]) {
                  if (modeOfTransport[mTCount].transportType.value !== '') {
                    const transportValue = transportType.find(t => t.value === modeOfTransport[mTCount].transportType.value);
                    if (transportValue) {
                      yarnData[14].splice(yarnData[14].length, 0, transportValue.text);
                    } else {
                      yarnData[14].splice(yarnData[14].length, 0, '');
                    }
                  } else {
                    yarnData[14].splice(yarnData[14].length, 0, '');
                  }
                  if (modeOfTransport[mTCount].distance.value !== -1) {
                    yarnData[15].splice(yarnData[15].length, 0, modeOfTransport[mTCount].distance.value.toString());
                  } else {
                    yarnData[15].splice(yarnData[15].length, 0, '');
                  }
                  if (modeOfTransport[mTCount].distanceUnit.unit !== '') {
                    const unit = transportUnitList.find(u => u.value === modeOfTransport[mTCount].distanceUnit.unit);
                    if (unit) {
                      yarnData[16].splice(yarnData[16].length, 0, unit.text);
                    }
                  } else {
                    yarnData[16].splice(yarnData[16].length, 0, '');
                  }
                } else {
                  yarnData[14].splice(yarnData[14].length, 0, '');
                  yarnData[15].splice(yarnData[15].length, 0, '');
                  yarnData[16].splice(yarnData[16].length, 0, '');
                }
                mTCount++;
              } else {
                yarnData[14].splice(yarnData[14].length, 0, '');
                yarnData[15].splice(yarnData[15].length, 0, '');
                yarnData[16].splice(yarnData[16].length, 0, '');
              }
            }
          } else {
            for (let modeIndex = 0; modeIndex < modeOfTransport.length; modeIndex++) {
              if(yarnFootprint[modeIndex]){
                yarnData[0].splice(yarnData[0].length, 0, '');
                yarnData[1].splice(yarnData[1].length, 0, '');
                this.mergeYarnCells.push({ s: { r: 5, c: yarnData[2].length }, e: { r: 5, c: yarnData[2].length + (modeOfTransport.length - 1) } });
                yarnData[2].splice(yarnData[2].length, 0, `Yarn ${modeIndex + 1}`);
                this.mergeYarnCells.push({ s: { r: 6, c: yarnData[3].length }, e: { r: 6, c: yarnData[3].length + (modeOfTransport.length - 1) } });
                yarnData[3].splice(yarnData[3].length, 0, yarnFootprint[modeIndex].reference.referenceNo);
                this.mergeYarnCells.push({ s: { r: 7, c: yarnData[4].length }, e: { r: 7, c: yarnData[4].length + (modeOfTransport.length - 1) } });
                yarnData[4].splice(yarnData[4].length, 0, yarnFootprint[modeIndex].spinning.spinningMethod !== '' ? (spinningMethod.filter(s => s.value === yarnFootprint[modeIndex].spinning.spinningMethod).length > 0 ? spinningMethod.filter(s => s.value === yarnFootprint[modeIndex].spinning.spinningMethod)[0].text : '') : '');
                this.mergeYarnCells.push({ s: { r: 8, c: yarnData[5].length }, e: { r: 8, c: yarnData[5].length + (modeOfTransport.length - 1) } });
                yarnData[5].splice(yarnData[5].length, 0, yarnFootprint[modeIndex].yarnCount.count.toString());
                this.mergeYarnCells.push({ s: { r: 9, c: yarnData[6].length }, e: { r: 9, c: yarnData[6].length + (modeOfTransport.length - 1) } });
                yarnData[6].splice(yarnData[6].length, 0, yarnFootprint[modeIndex].yarnUnit.unit !== '' ? yarnFootprint[modeIndex].yarnUnit.unit : '');
                this.mergeYarnCells.push({ s: { r: 14, c: yarnData[11].length }, e: { r: 14, c: yarnData[11].length + (modeOfTransport.length - 1) } });
                yarnData[11].splice(yarnData[11].length, 0, yarnFootprint[modeIndex].totalElectricEnergy.value !== -1 ? yarnFootprint[modeIndex].totalElectricEnergy.value.toString() : '');
                this.mergeYarnCells.push({ s: { r: 15, c: yarnData[12].length }, e: { r: 15, c: yarnData[12].length + (modeOfTransport.length - 1) } });
                yarnData[12].splice(yarnData[12].length, 0, yarnFootprint[modeIndex].windEnergy.value !== -1 ? yarnFootprint[modeIndex].windEnergy.value.toString() : '');
                this.mergeYarnCells.push({ s: { r: 16, c: yarnData[13].length }, e: { r: 16, c: yarnData[13].length + (modeOfTransport.length - 1) } });
                yarnData[13].splice(yarnData[13].length, 0, yarnFootprint[modeIndex].solarEnergy.value !== -1 ? yarnFootprint[modeIndex].solarEnergy.value.toString() : '');
                if (yarnFootprint[modeIndex].fiber.length > 0) {
                  yarnFootprint[modeIndex].fiber.forEach((fiber, index) => {
                    yarnData[0].splice(yarnData[0].length, 0, '');
                    yarnData[1].splice(yarnData[1].length, 0, '');
                    yarnData[2].splice(yarnData[2].length, 0, '');
                    yarnData[3].splice(yarnData[3].length, 0, '');
                    yarnData[4].splice(yarnData[4].length, 0, '');
                    yarnData[5].splice(yarnData[5].length, 0, '');
                    yarnData[6].splice(yarnData[6].length, 0, '');
                    yarnData[7].splice(yarnData[7].length, 0, `Fiber ${index + 1}`);
                    const fiberTypeValue = fiberType.find(f => f.value === fiber.fiberType.type);
                    if (fiberTypeValue) {
                      yarnData[8].splice(yarnData[8].length, 0, fiberTypeValue.text);
                    } else {
                      yarnData[8].splice(yarnData[8].length, 0, '');
                    }
                    yarnData[9].splice(yarnData[9].length, 0, fiber.fiberValue.value !== -1 ? fiber.fiberValue.value.toString() : '');
                    const fiberOrigin = fiberOriginList.find(f => f.value === fiber.originOfFiber.value);
                    if (fiberOrigin) {
                      yarnData[10].splice(yarnData[10].length, 0, fiberOrigin.text);
                    } else {
                      yarnData[10].splice(yarnData[10].length, 0, '');
                    }
                    yarnData[11].splice(yarnData[11].length, 0, '');
                    yarnData[12].splice(yarnData[12].length, 0, '');
                    yarnData[13].splice(yarnData[13].length, 0, '');
                  });
                  yarnData[7].splice(yarnData[7].length, 0, 'Total');
                  yarnData[8].splice(yarnData[8].length, 0, '');
                  yarnData[9].splice(yarnData[9].length, 0, yarnFootprint[modeIndex].total.totalcount !== -1 ? yarnFootprint[modeIndex].total.totalcount.toString() : '');
                  yarnData[10].splice(yarnData[10].length, 0, '');
                } else {
                  yarnData[7].splice(yarnData[7].length, 0, '');
                  yarnData[8].splice(yarnData[8].length, 0, '');
                  yarnData[9].splice(yarnData[9].length, 0, '');
                  yarnData[10].splice(yarnData[10].length, 0, '');
                }
              } else {
                yarnData[0].splice(yarnData[0].length, 0, '');
                yarnData[1].splice(yarnData[1].length, 0, '');
                yarnData[2].splice(yarnData[2].length, 0, '');
                yarnData[3].splice(yarnData[3].length, 0, '');
                yarnData[4].splice(yarnData[4].length, 0, '');
                yarnData[5].splice(yarnData[5].length, 0, '');
                yarnData[6].splice(yarnData[6].length, 0, '');
                yarnData[7].splice(yarnData[7].length, 0, '');
                yarnData[8].splice(yarnData[8].length, 0, '');
                yarnData[9].splice(yarnData[9].length, 0, '');
                yarnData[10].splice(yarnData[10].length, 0, '');
                yarnData[11].splice(yarnData[11].length, 0, '');
                yarnData[12].splice(yarnData[12].length, 0, '');
                yarnData[13].splice(yarnData[13].length, 0, '');
              }
              if (transport[0].modeOfTransport[modeIndex].transportType.value !== '') {
                const transportValue = transportType.find(t => t.value === transport[0].modeOfTransport[modeIndex].transportType.value);
                if (transportValue) {
                  yarnData[14].splice(yarnData[14].length, 0, transportValue.text);
                } else {
                  yarnData[14].splice(yarnData[14].length, 0, '');
                }
              } else {
                yarnData[14].splice(yarnData[14].length, 0, '');
              }
              if (transport[0].modeOfTransport[modeIndex].distance.value !== -1) {
                yarnData[15].splice(yarnData[15].length, 0, transport[0].modeOfTransport[modeIndex].distance.value.toString());
              } else {
                yarnData[15].splice(yarnData[15].length, 0, '');
              }
              if (transport[0].modeOfTransport[modeIndex].distanceUnit.unit !== '') {
                const unit = transportUnitList.find(u => u.value === transport[0].modeOfTransport[modeIndex].distanceUnit.unit);
                if (unit) {
                  yarnData[16].splice(yarnData[16].length, 0, unit.text);
                }
              } else {
                yarnData[16].splice(yarnData[16].length, 0, '');
              }
            }
          }
          let endIndex = 0;
          if (count >= modeOfTransport.length) {
            endIndex = yarnData[7].length - 1;
          } else {
            endIndex = yarnData[14].length - 1;
          }
          this.mergeYarnCells.push({ s: { r: 3, c: startIndex }, e: { r: 3, c: endIndex } });
          yarnData[0].splice(startIndex, 1, yarnFootprint[0].toCompanyUser.toCompanyName);
          this.mergeYarnCells.push({ s: { r: 4, c: startIndex }, e: { r: 4, c: endIndex } });
          const location = await this.getCompanyLocation(yarnFootprint[0].toCompanyUser.toCompanyID);
          yarnData[1].splice(startIndex, 1, location);
        }
      }
      return yarnData;
    }
    return [];
  }

  private async fetchFabricData(order: OrderLineDisplayModel) {
    const fabricTypeList = FootprintDropdownHelper.getFabricTypes();
    const transportType = FootprintDropdownHelper.getModeOfTransportList();
    const fiberList = FootprintDropdownHelper.getFiberTypeList();
    const fiberOriginList = CountryHelper.getCountryList();
    const transportUnitList = FootprintDropdownHelper.getTransportUnit();
    if (order.fabric.fabricFootprint.length > 0) {
      const fabricData = [
        ['FABRIC', '', 'Assigned to supplier'],
        ['', '', 'Location of supplier'],
        ['', '', '',],
        ['', '', 'Fabric reference',],
        ['', '', 'Type of Fabric'],
        ['', '', ''],
        ['', 'Yarn/Fiber', 'Type of yarn / Type of fiber'],
        ['', '', '% of yarn / % of fiber'],
        ['', '', 'Origin of fiber'],
        ['', '', 'Fabric weight'],
        ['', '', 'Unit'],
        ['', '', 'Fabric width in cm'],
        ['', 'Energy use', 'Energy use per km yarn'],
        ['', '', '% wind energy'],
        ['', '', '% solar energy'],
        ['', 'Transport', 'Mode of transport'],
        ['', '', 'Distance'],
        ['', '', 'Unit'],
      ];
      this.mergeFabricCells.push({ s: { r: 21, c: 0 }, e: { r: 38, c: 0 } }, { s: { r: 21, c: 1 }, e: { r: 26, c: 1 } }, { s: { r: 27, c: 1 }, e: { r: 32, c: 1 } }, { s: { r: 33, c: 1 }, e: { r: 35, c: 1 } }, { s: { r: 36, c: 1 }, e: { r: 38, c: 1 } });
      const uniqStepID = uniqBy(order.fabric.fabricFootprint, 'stepID').map(u => u.stepID);
      for (let i = 0; i < uniqStepID.length; i++) {
        const fabricFootprint = order.fabric.fabricFootprint.filter(f => f.stepID === uniqStepID[i]);
        if (fabricFootprint.length > 0) {
          const transport = order.fabric.transport.filter(t => t.stepID === uniqStepID[i]);
          const modeOfTransport = transport.length > 0 ? transport[0].modeOfTransport : [];
          const startIndex = fabricData[0].length;
          let count = 0;
          fabricFootprint.forEach(ff => {
            if (ff.fabricType.type === 'NON-WOVEN-NP' || ff.fabricType.type === 'NON-WOVEN-SB') {
              count = count + (ff.fiberFabric.length > 0 ? ff.fiberFabric.length + 1 : 0);
            } else {
              count = count + (ff.yarnFabric.length > 0 ? ff.yarnFabric.length + 1 : 0);
            }
          });
          if (count >= modeOfTransport.length) {
            let mTCount = 0;
            for (let fabricIndex = 0; fabricIndex < fabricFootprint.length; fabricIndex++) {
              const fabricTypeData = fabricFootprint[fabricIndex].fabricType.type === 'NON-WOVEN-NP' || fabricFootprint[fabricIndex].fabricType.type === 'NON-WOVEN-SB' ? 'FIBER' : 'YARN';
              fabricData[0].splice(fabricData[0].length, 0, '');
              fabricData[1].splice(fabricData[1].length, 0, '');
              this.mergeFabricCells.push({ s: { r: 23, c: fabricData[2].length }, e: { r: 23, c: fabricData[2].length + (fabricTypeData === 'FIBER' ? fabricFootprint[fabricIndex].fiberFabric.length : fabricFootprint[fabricIndex].yarnFabric.length) } });
              fabricData[2].splice(fabricData[2].length, 0, `Fabric ${fabricIndex + 1}`);
              this.mergeFabricCells.push({ s: { r: 24, c: fabricData[3].length }, e: { r: 24, c: fabricData[3].length + (fabricTypeData === 'FIBER' ? fabricFootprint[fabricIndex].fiberFabric.length : fabricFootprint[fabricIndex].yarnFabric.length) } });
              fabricData[3].splice(fabricData[3].length, 0, fabricFootprint[fabricIndex].reference.referenceNo);
              this.mergeFabricCells.push({ s: { r: 25, c: fabricData[4].length }, e: { r: 25, c: fabricData[4].length + (fabricTypeData === 'FIBER' ? fabricFootprint[fabricIndex].fiberFabric.length : fabricFootprint[fabricIndex].yarnFabric.length) } });
              fabricData[4].splice(fabricData[4].length, 0, fabricFootprint[fabricIndex].fabricType.type !== '' ? (fabricTypeList.filter(s => s.value === fabricFootprint[fabricIndex].fabricType.type).length > 0 ? fabricTypeList.filter(s => s.value === fabricFootprint[fabricIndex].fabricType.type)[0].text : '') : '');
              this.mergeFabricCells.push({ s: { r: 30, c: fabricData[9].length }, e: { r: 30, c: fabricData[9].length + (fabricTypeData === 'FIBER' ? fabricFootprint[fabricIndex].fiberFabric.length : fabricFootprint[fabricIndex].yarnFabric.length) } });
              fabricData[9].splice(fabricData[9].length, 0, fabricFootprint[fabricIndex].fabricWeight.weight !== -1 ? fabricFootprint[fabricIndex].fabricWeight.weight.toString() : '');
              this.mergeFabricCells.push({ s: { r: 31, c: fabricData[10].length }, e: { r: 31, c: fabricData[10].length + (fabricTypeData === 'FIBER' ? fabricFootprint[fabricIndex].fiberFabric.length : fabricFootprint[fabricIndex].yarnFabric.length) } });
              fabricData[10].splice(fabricData[10].length, 0, fabricFootprint[fabricIndex].fabricUnit.unit.toString());
              this.mergeFabricCells.push({ s: { r: 32, c: fabricData[11].length }, e: { r: 32, c: fabricData[11].length + (fabricTypeData === 'FIBER' ? fabricFootprint[fabricIndex].fiberFabric.length : fabricFootprint[fabricIndex].yarnFabric.length) } });
              fabricData[11].splice(fabricData[11].length, 0, fabricFootprint[fabricIndex].fabricWidth.width !== -1 ? fabricFootprint[fabricIndex].fabricWidth.width.toString() : '');
              this.mergeFabricCells.push({ s: { r: 33, c: fabricData[12].length }, e: { r: 33, c: fabricData[12].length + (fabricTypeData === 'FIBER' ? fabricFootprint[fabricIndex].fiberFabric.length : fabricFootprint[fabricIndex].yarnFabric.length) } });
              fabricData[12].splice(fabricData[12].length, 0, fabricFootprint[fabricIndex].totalElectricEnergy.value !== -1 ? fabricFootprint[fabricIndex].totalElectricEnergy.value.toString() : '');
              this.mergeFabricCells.push({ s: { r: 34, c: fabricData[13].length }, e: { r: 34, c: fabricData[13].length + (fabricTypeData === 'FIBER' ? fabricFootprint[fabricIndex].fiberFabric.length : fabricFootprint[fabricIndex].yarnFabric.length) } });
              fabricData[13].splice(fabricData[13].length, 0, fabricFootprint[fabricIndex].windEnergy.value !== -1 ? fabricFootprint[fabricIndex].windEnergy.value.toString() : '');
              this.mergeFabricCells.push({ s: { r: 35, c: fabricData[14].length }, e: { r: 35, c: fabricData[14].length + (fabricTypeData === 'FIBER' ? fabricFootprint[fabricIndex].fiberFabric.length : fabricFootprint[fabricIndex].yarnFabric.length) } });
              fabricData[14].splice(fabricData[14].length, 0, fabricFootprint[fabricIndex].solarEnergy.value !== -1 ? fabricFootprint[fabricIndex].solarEnergy.value.toString() : '');
              if (fabricTypeData === 'FIBER') {
                if (fabricFootprint[fabricIndex].fiberFabric.length > 0) {
                  fabricFootprint[fabricIndex].fiberFabric.forEach((fiber, fiberIndex) => {
                    fabricData[0].splice(fabricData[0].length, 0, '');
                    fabricData[1].splice(fabricData[1].length, 0, '');
                    fabricData[2].splice(fabricData[2].length, 0, '');
                    fabricData[3].splice(fabricData[3].length, 0, '');
                    fabricData[4].splice(fabricData[4].length, 0, '');
                    fabricData[5].splice(fabricData[5].length, 0, `Fiber ${fiberIndex + 1}`);
                    const fiberTypeValue = fiberList.find(f => f.value === fiber.fiberType.type);
                    if (fiberTypeValue) {
                      fabricData[6].splice(fabricData[6].length, 0, fiberTypeValue.text);
                    } else {
                      fabricData[6].splice(fabricData[6].length, 0, '');
                    }
                    fabricData[7].splice(fabricData[7].length, 0, fiber.fiberValue.value !== -1 ? fiber.fiberValue.value.toString() : '');
                    const fiberOrigin = fiberOriginList.find(f => f.value === fiber.originOfFiber.value);
                    if (fiberOrigin) {
                      fabricData[8].splice(fabricData[8].length, 0, fiberOrigin.text);
                    } else {
                      fabricData[8].splice(fabricData[8].length, 0, '');
                    }
                    fabricData[9].splice(fabricData[9].length, 0, '');
                    fabricData[10].splice(fabricData[10].length, 0, '');
                    fabricData[11].splice(fabricData[11].length, 0, '');
                    fabricData[12].splice(fabricData[12].length, 0, '');
                    fabricData[13].splice(fabricData[13].length, 0, '');
                    fabricData[14].splice(fabricData[14].length, 0, '');
                    if (modeOfTransport.length > 0) {
                      if (modeOfTransport[mTCount]) {
                        if (modeOfTransport[mTCount].transportType.value !== '') {
                          const transportValue = transportType.find(t => t.value === modeOfTransport[mTCount].transportType.value);
                          if (transportValue) {
                            fabricData[15].splice(fabricData[15].length, 0, transportValue.text);
                          } else {
                            fabricData[15].splice(fabricData[15].length, 0, '');
                          }
                        } else {
                          fabricData[15].splice(fabricData[15].length, 0, '');
                        }
                        if (modeOfTransport[mTCount].distance.value !== -1) {
                          fabricData[16].splice(fabricData[16].length, 0, modeOfTransport[mTCount].distance.value.toString());
                        } else {
                          fabricData[16].splice(fabricData[16].length, 0, '');
                        }
                        if (modeOfTransport[mTCount].distanceUnit.unit !== '') {
                          const unit = transportUnitList.find(u => u.value === modeOfTransport[mTCount].distanceUnit.unit);
                          if (unit) {
                            fabricData[17].splice(fabricData[17].length, 0, unit.text);
                          }
                        } else {
                          fabricData[17].splice(fabricData[17].length, 0, '');
                        }
                      } else {
                        fabricData[15].splice(fabricData[15].length, 0, '');
                        fabricData[16].splice(fabricData[16].length, 0, '');
                        fabricData[17].splice(fabricData[17].length, 0, '');
                      }
                      mTCount++;
                    }
                  });
                  fabricData[5].splice(fabricData[5].length, 0, 'Total');
                  fabricData[6].splice(fabricData[6].length, 0, '');
                  fabricData[7].splice(fabricData[7].length, 0, fabricFootprint[fabricIndex].total.totalcount !== -1 ? fabricFootprint[fabricIndex].total.totalcount.toString() : '');
                  fabricData[8].splice(fabricData[8].length, 0, '');
                  if (modeOfTransport.length > 0) {
                    if (modeOfTransport[mTCount]) {
                      if (modeOfTransport[mTCount].transportType.value !== '') {
                        const transportValue = transportType.find(t => t.value === modeOfTransport[mTCount].transportType.value);
                        if (transportValue) {
                          fabricData[15].splice(fabricData[15].length, 0, transportValue.text);
                        } else {
                          fabricData[15].splice(fabricData[15].length, 0, '');
                        }
                      } else {
                        fabricData[15].splice(fabricData[15].length, 0, '');
                      }
                      if (modeOfTransport[mTCount].distance.value !== -1) {
                        fabricData[16].splice(fabricData[16].length, 0, modeOfTransport[mTCount].distance.value.toString());
                      } else {
                        fabricData[16].splice(fabricData[16].length, 0, '');
                      }
                      if (modeOfTransport[mTCount].distanceUnit.unit !== '') {
                        const unit = transportUnitList.find(u => u.value === modeOfTransport[mTCount].distanceUnit.unit);
                        if (unit) {
                          fabricData[17].splice(fabricData[17].length, 0, unit.text);
                        }
                      } else {
                        fabricData[17].splice(fabricData[17].length, 0, '');
                      }
                    } else {
                      fabricData[15].splice(fabricData[15].length, 0, '');
                      fabricData[16].splice(fabricData[16].length, 0, '');
                      fabricData[17].splice(fabricData[17].length, 0, '');
                    }
                    mTCount++;
                  }
                } else {
                  fabricData[5].splice(fabricData[5].length, 0, '');
                  fabricData[6].splice(fabricData[6].length, 0, '');
                  fabricData[7].splice(fabricData[7].length, 0, '');
                  fabricData[8].splice(fabricData[8].length, 0, '');
                }
              } else {
                if (fabricFootprint[fabricIndex].yarnFabric.length > 0) {
                  fabricFootprint[fabricIndex].yarnFabric.forEach((yarn, yarnIndex) => {
                    fabricData[0].splice(fabricData[0].length, 0, '');
                    fabricData[1].splice(fabricData[1].length, 0, '');
                    fabricData[2].splice(fabricData[2].length, 0, '');
                    fabricData[3].splice(fabricData[3].length, 0, '');
                    fabricData[4].splice(fabricData[4].length, 0, '');
                    fabricData[5].splice(fabricData[5].length, 0, `Yarn ${yarnIndex + 1}`);
                    const yarnRef = order.yarn.footprint.find(f => f.ID === yarn.yarnRefNumber.yarnID);
                    if (yarnRef) {
                      fabricData[6].splice(fabricData[6].length, 0, yarnRef.reference.referenceNo);
                    } else {
                      fabricData[6].splice(fabricData[6].length, 0, '');
                    }
                    fabricData[7].splice(fabricData[7].length, 0, yarn.yarnPercent.count !== -1 ? yarn.yarnPercent.count.toString() : '');
                    fabricData[8].splice(fabricData[8].length, 0, '');
                    fabricData[9].splice(fabricData[9].length, 0, '');
                    fabricData[10].splice(fabricData[10].length, 0, '');
                    fabricData[11].splice(fabricData[11].length, 0, '');
                    fabricData[12].splice(fabricData[12].length, 0, '');
                    fabricData[13].splice(fabricData[13].length, 0, '');
                    fabricData[14].splice(fabricData[14].length, 0, '');
                    if (modeOfTransport.length > 0) {
                      if (modeOfTransport[mTCount]) {
                        if (modeOfTransport[mTCount].transportType.value !== '') {
                          const transportValue = transportType.find(t => t.value === modeOfTransport[mTCount].transportType.value);
                          if (transportValue) {
                            fabricData[15].splice(fabricData[15].length, 0, transportValue.text);
                          } else {
                            fabricData[15].splice(fabricData[15].length, 0, '');
                          }
                        } else {
                          fabricData[15].splice(fabricData[15].length, 0, '');
                        }
                        if (modeOfTransport[mTCount].distance.value !== -1) {
                          fabricData[16].splice(fabricData[16].length, 0, modeOfTransport[mTCount].distance.value.toString());
                        } else {
                          fabricData[16].splice(fabricData[16].length, 0, '');
                        }
                        if (modeOfTransport[mTCount].distanceUnit.unit !== '') {
                          const unit = transportUnitList.find(u => u.value === modeOfTransport[mTCount].distanceUnit.unit);
                          if (unit) {
                            fabricData[17].splice(fabricData[17].length, 0, unit.text);
                          }
                        } else {
                          fabricData[17].splice(fabricData[17].length, 0, '');
                        }
                      } else {
                        fabricData[15].splice(fabricData[15].length, 0, '');
                        fabricData[16].splice(fabricData[16].length, 0, '');
                        fabricData[17].splice(fabricData[17].length, 0, '');
                      }
                      mTCount++;
                    }
                  });
                  fabricData[5].splice(fabricData[5].length, 0, 'Total');
                  fabricData[6].splice(fabricData[6].length, 0, '');
                  fabricData[7].splice(fabricData[7].length, 0, fabricFootprint[fabricIndex].total.totalcount !== -1 ? fabricFootprint[fabricIndex].total.totalcount.toString() : '');
                  fabricData[8].splice(fabricData[8].length, 0, '');
                  if (modeOfTransport.length > 0) {
                    if (modeOfTransport[mTCount]) {
                      if (modeOfTransport[mTCount].transportType.value !== '') {
                        const transportValue = transportType.find(t => t.value === modeOfTransport[mTCount].transportType.value);
                        if (transportValue) {
                          fabricData[15].splice(fabricData[15].length, 0, transportValue.text);
                        } else {
                          fabricData[15].splice(fabricData[15].length, 0, '');
                        }
                      } else {
                        fabricData[15].splice(fabricData[15].length, 0, '');
                      }
                      if (modeOfTransport[mTCount].distance.value !== -1) {
                        fabricData[16].splice(fabricData[16].length, 0, modeOfTransport[mTCount].distance.value.toString());
                      } else {
                        fabricData[16].splice(fabricData[16].length, 0, '');
                      }
                      if (modeOfTransport[mTCount].distanceUnit.unit !== '') {
                        const unit = transportUnitList.find(u => u.value === modeOfTransport[mTCount].distanceUnit.unit);
                        if (unit) {
                          fabricData[17].splice(fabricData[17].length, 0, unit.text);
                        }
                      } else {
                        fabricData[17].splice(fabricData[17].length, 0, '');
                      }
                    } else {
                      fabricData[15].splice(fabricData[15].length, 0, '');
                      fabricData[16].splice(fabricData[16].length, 0, '');
                      fabricData[17].splice(fabricData[17].length, 0, '');
                    }
                    mTCount++;
                  }
                } else {
                  fabricData[5].splice(fabricData[5].length, 0, '');
                  fabricData[6].splice(fabricData[6].length, 0, '');
                  fabricData[7].splice(fabricData[7].length, 0, '');
                  fabricData[8].splice(fabricData[8].length, 0, '');
                }
              }
            }
          } else {
            for (let modeIndex = 0; modeIndex < modeOfTransport.length; modeIndex++) {
              const fabricTypeData = fabricFootprint[modeIndex].fabricType.type === 'NON-WOVEN-NP' || fabricFootprint[modeIndex].fabricType.type === 'NON-WOVEN-SB' ? 'FIBER' : 'YARN';
              fabricData[0].splice(fabricData[0].length, 0, '');
              fabricData[1].splice(fabricData[1].length, 0, '');
              this.mergeFabricCells.push({ s: { r: 21, c: fabricData[2].length }, e: { r: 21, c: fabricData[2].length + (modeOfTransport.length - 1) } });
              fabricData[2].splice(fabricData[2].length, 0, `Fabric ${modeIndex + 1}`);
              this.mergeFabricCells.push({ s: { r: 22, c: fabricData[3].length }, e: { r: 22, c: fabricData[3].length + (modeOfTransport.length - 1) } });
              fabricData[3].splice(fabricData[3].length, 0, fabricFootprint[modeIndex].reference.referenceNo);
              this.mergeFabricCells.push({ s: { r: 23, c: fabricData[4].length }, e: { r: 23, c: fabricData[4].length + (modeOfTransport.length - 1) } });
              fabricData[4].splice(fabricData[4].length, 0, fabricFootprint[modeIndex].fabricType.type !== '' ? (fabricTypeList.filter(s => s.value === fabricFootprint[modeIndex].fabricType.type).length > 0 ? fabricTypeList.filter(s => s.value === fabricFootprint[modeIndex].fabricType.type)[0].text : '') : '');
              this.mergeFabricCells.push({ s: { r: 30, c: fabricData[9].length }, e: { r: 30, c: fabricData[9].length + (modeOfTransport.length - 1) } });
              fabricData[9].splice(fabricData[9].length, 0, fabricFootprint[modeIndex].fabricWeight.weight !== -1 ? fabricFootprint[modeIndex].fabricWeight.weight.toString() : '');
              this.mergeFabricCells.push({ s: { r: 31, c: fabricData[10].length }, e: { r: 31, c: fabricData[10].length + (modeOfTransport.length - 1) } });
              fabricData[10].splice(fabricData[10].length, 0, fabricFootprint[modeIndex].fabricUnit.unit.toString());
              this.mergeFabricCells.push({ s: { r: 32, c: fabricData[11].length }, e: { r: 32, c: fabricData[11].length + (modeOfTransport.length - 1) } });
              fabricData[11].splice(fabricData[11].length, 0, fabricFootprint[modeIndex].fabricWidth.width !== -1 ? fabricFootprint[modeIndex].fabricWidth.width.toString() : '');
              this.mergeFabricCells.push({ s: { r: 33, c: fabricData[12].length }, e: { r: 33, c: fabricData[12].length + (modeOfTransport.length - 1) } });
              fabricData[12].splice(fabricData[12].length, 0, fabricFootprint[modeIndex].totalElectricEnergy.value !== -1 ? fabricFootprint[modeIndex].totalElectricEnergy.value.toString() : '');
              this.mergeFabricCells.push({ s: { r: 34, c: fabricData[13].length }, e: { r: 34, c: fabricData[13].length + (modeOfTransport.length - 1) } });
              fabricData[13].splice(fabricData[13].length, 0, fabricFootprint[modeIndex].windEnergy.value !== -1 ? fabricFootprint[modeIndex].windEnergy.value.toString() : '');
              this.mergeFabricCells.push({ s: { r: 35, c: fabricData[14].length }, e: { r: 35, c: fabricData[14].length + (modeOfTransport.length - 1) } });
              fabricData[14].splice(fabricData[14].length, 0, fabricFootprint[modeIndex].solarEnergy.value !== -1 ? fabricFootprint[modeIndex].solarEnergy.value.toString() : '');
              if (fabricFootprint[modeIndex]) {
                if (fabricTypeData === 'FIBER') {
                  if (fabricFootprint[modeIndex].fiberFabric.length > 0) {
                    fabricFootprint[modeIndex].fiberFabric.forEach((fiber, fiberIndex) => {
                      fabricData[0].splice(fabricData[0].length, 0, '');
                      fabricData[1].splice(fabricData[1].length, 0, '');
                      fabricData[2].splice(fabricData[2].length, 0, '');
                      fabricData[3].splice(fabricData[3].length, 0, '');
                      fabricData[4].splice(fabricData[4].length, 0, '');
                      fabricData[5].splice(fabricData[5].length, 0, `Fiber ${fiberIndex + 1}`);
                      const fiberTypeValue = fiberList.find(f => f.value === fiber.fiberType.type);
                      if (fiberTypeValue) {
                        fabricData[6].splice(fabricData[6].length, 0, fiberTypeValue.text);
                      } else {
                        fabricData[6].splice(fabricData[6].length, 0, '');
                      }
                      fabricData[7].splice(fabricData[7].length, 0, fiber.fiberValue.value !== -1 ? fiber.fiberValue.value.toString() : '');
                      const fiberOrigin = fiberOriginList.find(f => f.value === fiber.originOfFiber.value);
                      if (fiberOrigin) {
                        fabricData[8].splice(fabricData[8].length, 0, fiberOrigin.text);
                      } else {
                        fabricData[8].splice(fabricData[8].length, 0, '');
                      }
                      fabricData[9].splice(fabricData[9].length, 0, '');
                      fabricData[10].splice(fabricData[10].length, 0, '');
                      fabricData[11].splice(fabricData[11].length, 0, '');
                      fabricData[12].splice(fabricData[12].length, 0, '');
                      fabricData[13].splice(fabricData[13].length, 0, '');
                      fabricData[14].splice(fabricData[14].length, 0, '');
                    });
                    fabricData[5].splice(fabricData[5].length, 0, 'Total');
                    fabricData[6].splice(fabricData[6].length, 0, '');
                    fabricData[7].splice(fabricData[7].length, 0, fabricFootprint[modeIndex].total.totalcount !== -1 ? fabricFootprint[modeIndex].total.totalcount.toString() : '');
                    fabricData[8].splice(fabricData[8].length, 0, '');
                  } else {
                    fabricData[5].splice(fabricData[5].length, 0, '');
                    fabricData[6].splice(fabricData[6].length, 0, '');
                    fabricData[7].splice(fabricData[7].length, 0, '');
                    fabricData[8].splice(fabricData[8].length, 0, '');
                  }
                } else {
                  if (fabricFootprint[modeIndex].yarnFabric.length > 0) {
                    fabricFootprint[modeIndex].yarnFabric.forEach((yarn, yarnIndex) => {
                      fabricData[0].splice(fabricData[0].length, 0, '');
                      fabricData[1].splice(fabricData[1].length, 0, '');
                      fabricData[2].splice(fabricData[2].length, 0, '');
                      fabricData[3].splice(fabricData[3].length, 0, '');
                      fabricData[4].splice(fabricData[4].length, 0, '');
                      fabricData[5].splice(fabricData[5].length, 0, `Yarn ${yarnIndex + 1}`);
                      const yarnRef = order.yarn.footprint.find(f => f.ID === yarn.yarnRefNumber.yarnID);
                      if (yarnRef) {
                        fabricData[6].splice(fabricData[6].length, 0, yarnRef.reference.referenceNo);
                      } else {
                        fabricData[6].splice(fabricData[6].length, 0, '');
                      }
                      fabricData[7].splice(fabricData[7].length, 0, yarn.yarnPercent.count !== -1 ? yarn.yarnPercent.count.toString() : '');
                      fabricData[8].splice(fabricData[8].length, 0, '');
                      fabricData[9].splice(fabricData[9].length, 0, '');
                      fabricData[10].splice(fabricData[10].length, 0, '');
                      fabricData[11].splice(fabricData[11].length, 0, '');
                      fabricData[12].splice(fabricData[12].length, 0, '');
                      fabricData[13].splice(fabricData[13].length, 0, '');
                      fabricData[14].splice(fabricData[14].length, 0, '');
                    });
                    fabricData[5].splice(fabricData[5].length, 0, 'Total');
                    fabricData[6].splice(fabricData[6].length, 0, '');
                    fabricData[7].splice(fabricData[7].length, 0, fabricFootprint[modeIndex].total.totalcount !== -1 ? fabricFootprint[modeIndex].total.totalcount.toString() : '');
                    fabricData[8].splice(fabricData[8].length, 0, '');
                  } else {
                    fabricData[5].splice(fabricData[5].length, 0, '');
                    fabricData[6].splice(fabricData[6].length, 0, '');
                    fabricData[7].splice(fabricData[7].length, 0, '');
                    fabricData[8].splice(fabricData[8].length, 0, '');
                  }
                }
              } else {
                fabricData[0].splice(fabricData[0].length, 0, '');
                fabricData[1].splice(fabricData[1].length, 0, '');
                fabricData[2].splice(fabricData[2].length, 0, '');
                fabricData[3].splice(fabricData[3].length, 0, '');
                fabricData[4].splice(fabricData[4].length, 0, '');
                fabricData[5].splice(fabricData[5].length, 0, '');
                fabricData[6].splice(fabricData[6].length, 0, '');
                fabricData[7].splice(fabricData[7].length, 0, '');
                fabricData[8].splice(fabricData[8].length, 0, '');
                fabricData[9].splice(fabricData[9].length, 0, '');
                fabricData[10].splice(fabricData[10].length, 0, '');
                fabricData[11].splice(fabricData[11].length, 0, '');
                fabricData[12].splice(fabricData[12].length, 0, '');
                fabricData[13].splice(fabricData[13].length, 0, '');
                fabricData[14].splice(fabricData[14].length, 0, '');
              }
              if (modeOfTransport[modeIndex].transportType.value !== '') {
                const transportValue = transportType.find(t => t.value === modeOfTransport[modeIndex].transportType.value);
                if (transportValue) {
                  fabricData[15].splice(fabricData[15].length, 0, transportValue.text);
                } else {
                  fabricData[15].splice(fabricData[15].length, 0, '');
                }
              } else {
                fabricData[15].splice(fabricData[15].length, 0, '');
              }
              if (modeOfTransport[modeIndex].distance.value !== -1) {
                fabricData[16].splice(fabricData[16].length, 0, modeOfTransport[modeIndex].distance.value.toString());
              } else {
                fabricData[16].splice(fabricData[16].length, 0, '');
              }
              if (modeOfTransport[modeIndex].distanceUnit.unit !== '') {
                const unit = transportUnitList.find(u => u.value === modeOfTransport[modeIndex].distanceUnit.unit);
                if (unit) {
                  fabricData[17].splice(fabricData[17].length, 0, unit.text);
                }
              } else {
                fabricData[17].splice(fabricData[17].length, 0, '');
              }
            }
          }
          let endIndex = 0;
          if (count >= modeOfTransport.length) {
            endIndex = fabricData[5].length - 1;
          } else {
            endIndex = fabricData[15].length - 1;
          }
          this.mergeFabricCells.push({ s: { r: 21, c: startIndex }, e: { r: 21, c: endIndex } });
          fabricData[0].splice(startIndex, 1, fabricFootprint[0].toCompanyUser.toCompanyName);
          this.mergeFabricCells.push({ s: { r: 22, c: startIndex }, e: { r: 22, c: endIndex } });
          const location = await this.getCompanyLocation(fabricFootprint[0].toCompanyUser.toCompanyID);
          fabricData[1].splice(startIndex, 1, location);
        }
      }
      return fabricData;
    }
    return [];
  }

  private async fetchWetProcessData(order: OrderLineDisplayModel) {
    if (order.wetprocess.wetprocessFootprint.length > 0) {
      const dyeTypeList = FootprintDropdownHelper.getTypesofDye();
      const shadeList = FootprintDropdownHelper.getShadesList();
      const dyeingProcessList = FootprintDropdownHelper.getProcessForDyeing();
      const preProcessList = FootprintDropdownHelper.getProcessForPreTreatment();
      const printProcessList = FootprintDropdownHelper.getProcessForPrinting();
      const coatingList = FootprintDropdownHelper.getTypesofCoating();
      const printingList = FootprintDropdownHelper.getTypesofPrinting();
      const transportType = FootprintDropdownHelper.getModeOfTransportList();
      const transportUnitList = FootprintDropdownHelper.getTransportUnit();
      const wetProcessData: any = [];
      let isYarnExist = false;
      let isFabricExist = false;
      let startYarnRow = 0;
      let startFabricRow = 0;
      let startTransportRow = 0;
      let endYarnRow = 0;
      let endFabricRow = 0;
      let endTransportRow = 0;
      let startColumn = 0;
      let wetProcessFootprint = cloneDeep(order.wetprocess.wetprocessFootprint);
      wetProcessFootprint = uniqBy(wetProcessFootprint, 'ID');
      if (wetProcessFootprint.find(wp => wp.yarnWetprocess.length > 0)) {
        startYarnRow = 40;
        wetProcessData.push(['WET PROCESS', 'YARN', '', 'Assigned to supplier'],
          ['', '', '', 'Location of supplier'],
          ['', '', '', ''],
          ['', '', '', 'Yarn reference'],
          ['', '', '', ''],
          ['', '', '', 'Reference of dyeing treatment'],
          ['', '', '', 'Type of dye'],
          ['', '', '', 'Shade'],
          ['', '', '', 'Process'],
          ['', '', '', 'Water used (l/kg)'],
          ['', '', 'Energy use', 'Energy use per kg fabric'],
          ['', '', '', '% wind energy'],
          ['', '', '', '% solar energy'],
          ['', '', '', 'thermal energy'],
          ['', '', '', ''],
          ['', '', '', ''],
          ['', '', '', ''],
          ['', '', '', 'Reference of pre treatment'],
          ['', '', '', 'Type of process'],
          ['', '', '', 'Water used (l/kg)'],
          ['', '', 'Energy use', 'Energy use per kg fabric'],
          ['', '', '', '% wind energy'],
          ['', '', '', '% solar energy'],
          ['', '', '', 'thermal energy'],
          ['', '', '', ''],
          ['', '', '', ''],
          ['', '', '', ''],
          ['', '', '', 'Reference of coating/finishing'],
          ['', '', '', 'Type of coating/finishing'],
          ['', '', '', 'Water used (l/kg)'],
          ['', '', 'Energy use', 'Energy use per kg fabric'],
          ['', '', '', '% wind energy'],
          ['', '', '', '% solar energy'],
          ['', '', '', 'thermal energy'],
          ['', '', '', ''],
          ['', '', '', '']
        );
        this.mergeWetProcessCells.push({ s: { r: startYarnRow, c: 1 }, e: { r: startYarnRow + wetProcessData.length - 1, c: 1 } },
          { s: { r: startYarnRow, c: 2 }, e: { r: startYarnRow + 3, c: 2 } },
          { s: { r: startYarnRow + 4, c: 2 }, e: { r: startYarnRow + 9, c: 2 } },
          { s: { r: startYarnRow + 10, c: 2 }, e: { r: startYarnRow + 15, c: 2 } },
          { s: { r: startYarnRow + 16, c: 2 }, e: { r: startYarnRow + 19, c: 2 } },
          { s: { r: startYarnRow + 20, c: 2 }, e: { r: startYarnRow + 25, c: 2 } },
          { s: { r: startYarnRow + 26, c: 2 }, e: { r: startYarnRow + 29, c: 2 } },
          { s: { r: startYarnRow + 30, c: 2 }, e: { r: startYarnRow + 35, c: 2 } },
          { s: { r: startYarnRow + 13, c: 3 }, e: { r: startYarnRow + 15, c: 3 } },
          { s: { r: startYarnRow + 23, c: 3 }, e: { r: startYarnRow + 25, c: 3 } },
          { s: { r: startYarnRow + 33, c: 3 }, e: { r: startYarnRow + 35, c: 3 } }
        )
        isYarnExist = true;
        endYarnRow = 40 + (wetProcessData.length - 1);
      }
      if (wetProcessFootprint.find(wp => wp.fabricWetprocess.length > 0)) {
        if (isYarnExist) {
          startFabricRow = endYarnRow + 1;
          wetProcessData.push(['', 'FABRIC', '', ''],
            ['', '', '', 'Fabric reference'],
            ['', '', '', ''],
            ['', '', '', 'Reference of dyeing treatment'],
            ['', '', '', 'Type of dye'],
            ['', '', '', 'Shade'],
            ['', '', '', 'Process'],
            ['', '', '', 'Water used (l/kg)'],
            ['', '', 'Energy use', 'Energy use per kg fabric'],
            ['', '', '', '% wind energy'],
            ['', '', '', '% solar energy'],
            ['', '', '', 'thermal energy'],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', 'Reference of pre treatment'],
            ['', '', '', 'Type of process'],
            ['', '', '', 'Water used (l/kg)'],
            ['', '', 'Energy use', 'Energy use per kg fabric'],
            ['', '', '', '% wind energy'],
            ['', '', '', '% solar energy'],
            ['', '', '', 'thermal energy'],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', 'Reference of printing treatment'],
            ['', '', '', 'Type of print'],
            ['', '', '', 'Shade'],
            ['', '', '', 'Process'],
            ['', '', '', 'Water used (l/kg)'],
            ['', '', 'Energy use', 'Energy use per kg fabric'],
            ['', '', '', '% wind energy'],
            ['', '', '', '% solar energy'],
            ['', '', '', 'thermal energy'],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', 'Reference of coating/finishing'],
            ['', '', '', 'Type of coating/finishing'],
            ['', '', '', 'Water used (l/kg)'],
            ['', '', 'Energy use', 'Energy use per kg fabric'],
            ['', '', '', '% wind energy'],
            ['', '', '', '% solar energy'],
            ['', '', '', 'thermal energy'],
            ['', '', '', ''],
            ['', '', '', '']
          );
          this.mergeWetProcessCells.push({ s: { r: startFabricRow, c: 1 }, e: { r: startYarnRow + wetProcessData.length - 1, c: 1 } },
            { s: { r: startFabricRow, c: 2 }, e: { r: startFabricRow + 1, c: 2 } },
            { s: { r: startFabricRow + 2, c: 2 }, e: { r: startFabricRow + 7, c: 2 } },
            { s: { r: startFabricRow + 8, c: 2 }, e: { r: startFabricRow + 13, c: 2 } },
            { s: { r: startFabricRow + 14, c: 2 }, e: { r: startFabricRow + 17, c: 2 } },
            { s: { r: startFabricRow + 18, c: 2 }, e: { r: startFabricRow + 23, c: 2 } },
            { s: { r: startFabricRow + 24, c: 2 }, e: { r: startFabricRow + 29, c: 2 } },
            { s: { r: startFabricRow + 30, c: 2 }, e: { r: startFabricRow + 35, c: 2 } },
            { s: { r: startFabricRow + 37, c: 2 }, e: { r: startFabricRow + 39, c: 2 } },
            { s: { r: startFabricRow + 40, c: 2 }, e: { r: startFabricRow + 45, c: 2 } },
            { s: { r: startFabricRow + 11, c: 3 }, e: { r: startFabricRow + 13, c: 3 } },
            { s: { r: startFabricRow + 21, c: 3 }, e: { r: startFabricRow + 23, c: 3 } },
            { s: { r: startFabricRow + 33, c: 3 }, e: { r: startFabricRow + 35, c: 3 } },
            { s: { r: startFabricRow + 43, c: 3 }, e: { r: startFabricRow + 45, c: 3 } }
          )
        } else {
          startFabricRow = 40;
          wetProcessData.push(['WET PROCESS', 'FABRIC', '', 'Assigned to supplier'],
            ['', '', '', 'Location of supplier'],
            ['', '', '', ''],
            ['', '', '', 'Fabric reference'],
            ['', '', '', ''],
            ['', '', '', 'Reference of dyeing treatment'],
            ['', '', '', 'Type of dye'],
            ['', '', '', 'Shade'],
            ['', '', '', 'Process'],
            ['', '', '', 'Water used (l/kg)'],
            ['', '', 'Energy use', 'Energy use per kg fabric'],
            ['', '', '', '% wind energy'],
            ['', '', '', '% solar energy'],
            ['', '', '', 'thermal energy'],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', 'Reference of pre treatment'],
            ['', '', '', 'Type of process'],
            ['', '', '', 'Water used (l/kg)'],
            ['', '', 'Energy use', 'Energy use per kg fabric'],
            ['', '', '', '% wind energy'],
            ['', '', '', '% solar energy'],
            ['', '', '', 'thermal energy'],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', 'Reference of printing treatment'],
            ['', '', '', 'Type of print'],
            ['', '', '', 'Shade'],
            ['', '', '', 'Process'],
            ['', '', '', 'Water used (l/kg)'],
            ['', '', 'Energy use', 'Energy use per kg fabric'],
            ['', '', '', '% wind energy'],
            ['', '', '', '% solar energy'],
            ['', '', '', 'thermal energy'],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', ''],
            ['', '', '', 'Reference of coating/finishing'],
            ['', '', '', 'Type of coating/finishing'],
            ['', '', '', 'Water used (l/kg)'],
            ['', '', 'Energy use', 'Energy use per kg fabric'],
            ['', '', '', '% wind energy'],
            ['', '', '', '% solar energy'],
            ['', '', '', 'thermal energy'],
            ['', '', '', ''],
            ['', '', '', '']
          );
          this.mergeWetProcessCells.push({ s: { r: startFabricRow, c: 1 }, e: { r: startFabricRow + wetProcessData.length - 1, c: 1 } },
            { s: { r: startFabricRow, c: 2 }, e: { r: startFabricRow + 3, c: 2 } },
            { s: { r: startFabricRow + 4, c: 2 }, e: { r: startFabricRow + 9, c: 2 } },
            { s: { r: startFabricRow + 10, c: 2 }, e: { r: startFabricRow + 15, c: 2 } },
            { s: { r: startFabricRow + 16, c: 2 }, e: { r: startFabricRow + 19, c: 2 } },
            { s: { r: startFabricRow + 20, c: 2 }, e: { r: startFabricRow + 25, c: 2 } },
            { s: { r: startFabricRow + 26, c: 2 }, e: { r: startFabricRow + 31, c: 2 } },
            { s: { r: startFabricRow + 33, c: 2 }, e: { r: startFabricRow + 37, c: 2 } },
            { s: { r: startFabricRow + 38, c: 2 }, e: { r: startFabricRow + 41, c: 2 } },
            { s: { r: startFabricRow + 42, c: 2 }, e: { r: startFabricRow + 47, c: 2 } },
            { s: { r: startFabricRow + 13, c: 3 }, e: { r: startFabricRow + 15, c: 3 } },
            { s: { r: startFabricRow + 23, c: 3 }, e: { r: startFabricRow + 25, c: 3 } },
            { s: { r: startFabricRow + 35, c: 3 }, e: { r: startFabricRow + 37, c: 3 } },
            { s: { r: startFabricRow + 45, c: 3 }, e: { r: startFabricRow + 47, c: 3 } }
          )
        }
        isFabricExist = true;
        endFabricRow = 40 + (wetProcessData.length - 1);
      }
      if (wetProcessData.length > 0) {
        wetProcessData.push(['', '', 'Transport', 'Mode of transport'],
          ['', '', '', 'Distance'],
          ['', '', '', 'Unit']
        );
      } else {
        wetProcessData.push(['WET PROCESS', '', 'Transport', 'Assigned to supplier'],
          ['', '', '', 'Location of supplier'],
          ['', '', '', 'Mode of transport'],
          ['', '', '', 'Distance'],
          ['', '', '', 'Unit']
        );
      }
      if (isYarnExist && isFabricExist) {
        startTransportRow = endFabricRow + 1;
      } else if (isYarnExist) {
        startTransportRow = endYarnRow + 1;
      } else if (isFabricExist) {
        startTransportRow = endFabricRow + 1;
      } else {
        startTransportRow = 40;
      }
      this.mergeWetProcessCells.push({ s: { r: 40, c: 0 }, e: { r: 40 + (wetProcessData.length - 1), c: 0 } },
        { s: { r: startTransportRow, c: 1 }, e: { r: 40 + wetProcessData.length - 1, c: 1 } },
        { s: { r: startTransportRow, c: 2 }, e: { r: 40 + wetProcessData.length - 1, c: 2 } }
      )
      for (let wpIndex = 0; wpIndex < wetProcessFootprint.length; wpIndex++) {
        const transport = order.wetprocess.transport.filter(t => t.stepID === wetProcessFootprint[wpIndex].stepID);
        const modeOfTransport = transport.length > 0 ? transport[0].modeOfTransport : [];
        startColumn = wetProcessData[0].length;
        let yarnSize = 0;
        let fabricSize = 0;
        wetProcessData[0].splice(wetProcessData[0].length, 0, wetProcessFootprint[wpIndex].toCompanyUser.toCompanyName);
        const location = await this.getCompanyLocation(wetProcessFootprint[wpIndex].toCompanyUser.toCompanyID);
        wetProcessData[1].splice(wetProcessData[1].length, 0, location);
        wetProcessFootprint[wpIndex].yarnWetprocess.forEach(ywp => {
          const size = [ywp.dyiengTreatment.length, ywp.preTreatment.length, ywp.coatingFinishing.length];
          const sizeSort = size.sort((a, b) => b - a);
          yarnSize = yarnSize + sizeSort[0];
        });
        wetProcessFootprint[wpIndex].fabricWetprocess.forEach(fwp => {
          const size = [fwp.dyiengTreatment.length, fwp.preTreatment.length, fwp.printTreatment.length, fwp.coatingFinishing.length];
          const sizeSort = size.sort((a, b) => b - a);
          fabricSize = fabricSize + sizeSort[0];
        });
        if ((yarnSize >= fabricSize ? yarnSize : fabricSize) >= modeOfTransport.length) {
          let yarnCount = 0;
          let fabricCount = 0;
          let treatmentCount = 0;
          for (let fp = 0; fp < (yarnSize >= fabricSize ? yarnSize : fabricSize); fp++) {
            if (isYarnExist) {
              if (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount]) {
                if (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount]) {
                  wetProcessData[4].splice(wetProcessData[4].length, 0, `Dyeing treatment ${treatmentCount + 1}`);
                  wetProcessData[5].splice(wetProcessData[5].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].reference.referenceNo);
                  const dyeType = dyeTypeList.find(d => d.value === wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].dyeType.type);
                  if (dyeType) {
                    wetProcessData[6].splice(wetProcessData[6].length, 0, dyeType.text);
                  } else {
                    wetProcessData[6].splice(wetProcessData[6].length, 0, '');
                  }
                  const shade = shadeList.find(s => s.value === wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].shade.type);
                  if (shade) {
                    wetProcessData[7].splice(wetProcessData[7].length, 0, shade.text);
                  } else {
                    wetProcessData[7].splice(wetProcessData[7].length, 0, '');
                  }
                  const process = dyeingProcessList.find(p => p.value === wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].process.type);
                  if (process) {
                    wetProcessData[8].splice(wetProcessData[8].length, 0, process.text);
                  } else {
                    wetProcessData[8].splice(wetProcessData[8].length, 0, '');
                  }
                  wetProcessData[9].splice(wetProcessData[9].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                  wetProcessData[10].splice(wetProcessData[10].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].energyUsed.value.toString() : '');
                  wetProcessData[11].splice(wetProcessData[11].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].windEnergy.value.toString() : '');
                  wetProcessData[12].splice(wetProcessData[12].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].solarEnergy.value.toString() : '');
                  wetProcessData[13].splice(wetProcessData[13].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                  wetProcessData[14].splice(wetProcessData[14].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                  wetProcessData[15].splice(wetProcessData[15].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                } else {
                  wetProcessData[4].splice(wetProcessData[4].length, 0, '');
                  wetProcessData[5].splice(wetProcessData[5].length, 0, '');
                  wetProcessData[6].splice(wetProcessData[6].length, 0, '');
                  wetProcessData[7].splice(wetProcessData[7].length, 0, '');
                  wetProcessData[8].splice(wetProcessData[8].length, 0, '');
                  wetProcessData[9].splice(wetProcessData[9].length, 0, '');
                  wetProcessData[10].splice(wetProcessData[10].length, 0, '');
                  wetProcessData[11].splice(wetProcessData[11].length, 0, '');
                  wetProcessData[12].splice(wetProcessData[12].length, 0, '');
                  wetProcessData[13].splice(wetProcessData[13].length, 0, '');
                  wetProcessData[14].splice(wetProcessData[14].length, 0, '');
                  wetProcessData[15].splice(wetProcessData[15].length, 0, '');
                }
                if (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount]) {
                  wetProcessData[16].splice(wetProcessData[16].length, 0, `Pre treatment ${treatmentCount + 1}`);
                  wetProcessData[17].splice(wetProcessData[17].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].reference.referenceNo);
                  const preType = preProcessList.find(p => p.value === wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].process.type);
                  if (preType) {
                    wetProcessData[18].splice(wetProcessData[18].length, 0, preType.text);
                  } else {
                    wetProcessData[18].splice(wetProcessData[18].length, 0, '');
                  }
                  wetProcessData[19].splice(wetProcessData[19].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                  wetProcessData[20].splice(wetProcessData[20].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].energyUsed.value.toString() : '');
                  wetProcessData[21].splice(wetProcessData[21].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].windEnergy.value.toString() : '');
                  wetProcessData[22].splice(wetProcessData[22].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].solarEnergy.value.toString() : '');
                  wetProcessData[23].splice(wetProcessData[23].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                  wetProcessData[24].splice(wetProcessData[24].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                  wetProcessData[25].splice(wetProcessData[25].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                } else {
                  wetProcessData[16].splice(wetProcessData[16].length, 0, '');
                  wetProcessData[17].splice(wetProcessData[17].length, 0, '');
                  wetProcessData[18].splice(wetProcessData[18].length, 0, '');
                  wetProcessData[19].splice(wetProcessData[19].length, 0, '');
                  wetProcessData[20].splice(wetProcessData[20].length, 0, '');
                  wetProcessData[21].splice(wetProcessData[21].length, 0, '');
                  wetProcessData[22].splice(wetProcessData[22].length, 0, '');
                  wetProcessData[23].splice(wetProcessData[23].length, 0, '');
                  wetProcessData[24].splice(wetProcessData[24].length, 0, '');
                  wetProcessData[25].splice(wetProcessData[25].length, 0, '');
                }
                if (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount]) {
                  wetProcessData[26].splice(wetProcessData[26].length, 0, `Coating/finishing ${treatmentCount + 1}`);
                  wetProcessData[27].splice(wetProcessData[27].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].reference.referenceNo);
                  const coatingType = coatingList.find(c => c.value === wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].coatingOrFinishing.type);
                  if (coatingType) {
                    wetProcessData[28].splice(wetProcessData[28].length, 0, coatingType.text);
                  } else {
                    wetProcessData[28].splice(wetProcessData[28].length, 0, '');
                  }
                  wetProcessData[29].splice(wetProcessData[29].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount.toString() : '');
                  wetProcessData[30].splice(wetProcessData[30].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].energyUsed.value.toString() : '');
                  wetProcessData[31].splice(wetProcessData[31].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].windEnergy.value.toString() : '');
                  wetProcessData[32].splice(wetProcessData[32].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].solarEnergy.value.toString() : '');
                  wetProcessData[33].splice(wetProcessData[33].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                  wetProcessData[34].splice(wetProcessData[34].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                  wetProcessData[35].splice(wetProcessData[35].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                } else {
                  wetProcessData[26].splice(wetProcessData[26].length, 0, '');
                  wetProcessData[27].splice(wetProcessData[27].length, 0, '');
                  wetProcessData[28].splice(wetProcessData[28].length, 0, '');
                  wetProcessData[29].splice(wetProcessData[29].length, 0, '');
                  wetProcessData[30].splice(wetProcessData[30].length, 0, '');
                  wetProcessData[31].splice(wetProcessData[31].length, 0, '');
                  wetProcessData[32].splice(wetProcessData[32].length, 0, '');
                  wetProcessData[33].splice(wetProcessData[33].length, 0, '');
                  wetProcessData[34].splice(wetProcessData[34].length, 0, '');
                  wetProcessData[35].splice(wetProcessData[35].length, 0, '');
                }
              }
            }
            if (isFabricExist) {
              if (!isYarnExist) {
                if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount]) {
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount]) {
                    wetProcessData[4].splice(wetProcessData[4].length, 0, `Dyeing treatment ${treatmentCount + 1}`);
                    wetProcessData[5].splice(wetProcessData[5].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].reference.referenceNo);
                    const dyeType = dyeTypeList.find(d => d.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].dyeType.type);
                    if (dyeType) {
                      wetProcessData[6].splice(wetProcessData[6].length, 0, dyeType.text);
                    } else {
                      wetProcessData[6].splice(wetProcessData[6].length, 0, '');
                    }
                    const shade = shadeList.find(s => s.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].shade.type);
                    if (shade) {
                      wetProcessData[7].splice(wetProcessData[7].length, 0, shade.text);
                    } else {
                      wetProcessData[7].splice(wetProcessData[7].length, 0, '');
                    }
                    const process = dyeingProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].process.type);
                    if (process) {
                      wetProcessData[8].splice(wetProcessData[8].length, 0, process.text);
                    } else {
                      wetProcessData[8].splice(wetProcessData[8].length, 0, '');
                    }
                    wetProcessData[9].splice(wetProcessData[9].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[10].splice(wetProcessData[10].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[11].splice(wetProcessData[11].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[12].splice(wetProcessData[12].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[13].splice(wetProcessData[13].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[14].splice(wetProcessData[14].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[15].splice(wetProcessData[15].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[4].splice(wetProcessData[4].length, 0, '');
                    wetProcessData[5].splice(wetProcessData[5].length, 0, '');
                    wetProcessData[6].splice(wetProcessData[6].length, 0, '');
                    wetProcessData[7].splice(wetProcessData[7].length, 0, '');
                    wetProcessData[8].splice(wetProcessData[8].length, 0, '');
                    wetProcessData[9].splice(wetProcessData[9].length, 0, '');
                    wetProcessData[10].splice(wetProcessData[10].length, 0, '');
                    wetProcessData[11].splice(wetProcessData[11].length, 0, '');
                    wetProcessData[12].splice(wetProcessData[12].length, 0, '');
                    wetProcessData[13].splice(wetProcessData[13].length, 0, '');
                    wetProcessData[14].splice(wetProcessData[14].length, 0, '');
                    wetProcessData[15].splice(wetProcessData[15].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount]) {
                    wetProcessData[16].splice(wetProcessData[16].length, 0, `Pre treatment ${treatmentCount + 1}`);
                    wetProcessData[17].splice(wetProcessData[17].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].reference.referenceNo);
                    const preType = preProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].process.type);
                    if (preType) {
                      wetProcessData[18].splice(wetProcessData[18].length, 0, preType.text);
                    } else {
                      wetProcessData[18].splice(wetProcessData[18].length, 0, '');
                    }
                    wetProcessData[19].splice(wetProcessData[19].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[20].splice(wetProcessData[20].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[21].splice(wetProcessData[21].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[22].splice(wetProcessData[22].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[23].splice(wetProcessData[23].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[24].splice(wetProcessData[24].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[25].splice(wetProcessData[25].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[16].splice(wetProcessData[16].length, 0, '');
                    wetProcessData[17].splice(wetProcessData[17].length, 0, '');
                    wetProcessData[18].splice(wetProcessData[18].length, 0, '');
                    wetProcessData[19].splice(wetProcessData[19].length, 0, '');
                    wetProcessData[20].splice(wetProcessData[20].length, 0, '');
                    wetProcessData[21].splice(wetProcessData[21].length, 0, '');
                    wetProcessData[22].splice(wetProcessData[22].length, 0, '');
                    wetProcessData[23].splice(wetProcessData[23].length, 0, '');
                    wetProcessData[24].splice(wetProcessData[24].length, 0, '');
                    wetProcessData[25].splice(wetProcessData[25].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount]) {
                    wetProcessData[26].splice(wetProcessData[26].length, 0, `Printing treatment ${treatmentCount + 1}`);
                    wetProcessData[27].splice(wetProcessData[27].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].reference.referenceNo);
                    const printType = printingList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].printType.type);
                    if (printType) {
                      wetProcessData[28].splice(wetProcessData[28].length, 0, printType.text);
                    } else {
                      wetProcessData[28].splice(wetProcessData[28].length, 0, '');
                    }
                    const shade = shadeList.find(s => s.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].shade.type);
                    if (shade) {
                      wetProcessData[29].splice(wetProcessData[29].length, 0, shade.text);
                    } else {
                      wetProcessData[29].splice(wetProcessData[29].length, 0, '');
                    }
                    const process = printProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].process.type);
                    if (process) {
                      wetProcessData[30].splice(wetProcessData[30].length, 0, process.text);
                    } else {
                      wetProcessData[30].splice(wetProcessData[30].length, 0, '');
                    }
                    wetProcessData[31].splice(wetProcessData[31].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[32].splice(wetProcessData[32].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[33].splice(wetProcessData[33].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[34].splice(wetProcessData[34].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[35].splice(wetProcessData[35].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[36].splice(wetProcessData[36].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[37].splice(wetProcessData[37].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[26].splice(wetProcessData[26].length, 0, '');
                    wetProcessData[27].splice(wetProcessData[27].length, 0, '');
                    wetProcessData[28].splice(wetProcessData[28].length, 0, '');
                    wetProcessData[29].splice(wetProcessData[29].length, 0, '');
                    wetProcessData[30].splice(wetProcessData[30].length, 0, '');
                    wetProcessData[31].splice(wetProcessData[31].length, 0, '');
                    wetProcessData[32].splice(wetProcessData[32].length, 0, '');
                    wetProcessData[33].splice(wetProcessData[33].length, 0, '');
                    wetProcessData[34].splice(wetProcessData[34].length, 0, '');
                    wetProcessData[35].splice(wetProcessData[35].length, 0, '');
                    wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                    wetProcessData[37].splice(wetProcessData[37].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount]) {
                    wetProcessData[38].splice(wetProcessData[38].length, 0, `Coating/finishing ${treatmentCount + 1}`);
                    wetProcessData[39].splice(wetProcessData[39].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].reference.referenceNo);
                    const coatingType = coatingList.find(c => c.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].coatingOrFinishing.type);
                    if (coatingType) {
                      wetProcessData[40].splice(wetProcessData[40].length, 0, coatingType.text);
                    } else {
                      wetProcessData[40].splice(wetProcessData[40].length, 0, '');
                    }
                    wetProcessData[41].splice(wetProcessData[41].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[42].splice(wetProcessData[42].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[43].splice(wetProcessData[43].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[44].splice(wetProcessData[44].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[45].splice(wetProcessData[45].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[46].splice(wetProcessData[46].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[47].splice(wetProcessData[47].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                  } else {
                    wetProcessData[38].splice(wetProcessData[38].length, 0, '');
                    wetProcessData[39].splice(wetProcessData[39].length, 0, '');
                    wetProcessData[40].splice(wetProcessData[40].length, 0, '');
                    wetProcessData[41].splice(wetProcessData[41].length, 0, '');
                    wetProcessData[42].splice(wetProcessData[42].length, 0, '');
                    wetProcessData[43].splice(wetProcessData[43].length, 0, '');
                    wetProcessData[44].splice(wetProcessData[44].length, 0, '');
                    wetProcessData[45].splice(wetProcessData[45].length, 0, '');
                    wetProcessData[46].splice(wetProcessData[46].length, 0, '');
                    wetProcessData[47].splice(wetProcessData[47].length, 0, '');
                  }
                  if( (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount] && (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount + 1]))
                    ){
                      treatmentCount = treatmentCount + 1;
                      wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                      wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                    } else {
                      if(wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount]){
                        wetProcessData[2].splice(wetProcessData[2].length - treatmentCount, 1, `Fabric ${fabricCount + 1}`);
                        wetProcessData[3].splice(wetProcessData[3].length - treatmentCount, 1, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].reference.referenceNo);
                        this.mergeWetProcessCells.push({ s: { r: endYarnRow + 1, c: wetProcessData[2].length - treatmentCount }, e: { r: endYarnRow + 1, c: wetProcessData[2].length } },
                          { s: { r: endYarnRow + 2, c: wetProcessData[3].length - treatmentCount }, e: { r: endYarnRow + 2, c: wetProcessData[3].length } }
                        )
                      } else {
                        wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                        wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                      }
                      treatmentCount = 0;
                      fabricCount = fabricCount + 1;
                    }
                }
              } else {
                if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount]) {
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount]) {
                    wetProcessData[38].splice(wetProcessData[38].length, 0, `Dyeing treatment ${treatmentCount + 1}`);
                    wetProcessData[39].splice(wetProcessData[39].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].reference.referenceNo);
                    const dyeType = dyeTypeList.find(d => d.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].dyeType.type);
                    if (dyeType) {
                      wetProcessData[40].splice(wetProcessData[40].length, 0, dyeType.text);
                    } else {
                      wetProcessData[40].splice(wetProcessData[40].length, 0, '');
                    }
                    const shade = shadeList.find(s => s.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].shade.type);
                    if (shade) {
                      wetProcessData[41].splice(wetProcessData[41].length, 0, shade.text);
                    } else {
                      wetProcessData[41].splice(wetProcessData[41].length, 0, '');
                    }
                    const process = dyeingProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].process.type);
                    if (process) {
                      wetProcessData[42].splice(wetProcessData[42].length, 0, process.text);
                    } else {
                      wetProcessData[42].splice(wetProcessData[42].length, 0, '');
                    }
                    wetProcessData[43].splice(wetProcessData[43].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[44].splice(wetProcessData[44].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[45].splice(wetProcessData[45].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[46].splice(wetProcessData[46].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[47].splice(wetProcessData[47].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[48].splice(wetProcessData[48].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[49].splice(wetProcessData[49].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[38].splice(wetProcessData[38].length, 0, '');
                    wetProcessData[39].splice(wetProcessData[39].length, 0, '');
                    wetProcessData[40].splice(wetProcessData[40].length, 0, '');
                    wetProcessData[41].splice(wetProcessData[41].length, 0, '');
                    wetProcessData[42].splice(wetProcessData[42].length, 0, '');
                    wetProcessData[43].splice(wetProcessData[43].length, 0, '');
                    wetProcessData[44].splice(wetProcessData[44].length, 0, '');
                    wetProcessData[45].splice(wetProcessData[45].length, 0, '');
                    wetProcessData[46].splice(wetProcessData[46].length, 0, '');
                    wetProcessData[47].splice(wetProcessData[47].length, 0, '');
                    wetProcessData[48].splice(wetProcessData[48].length, 0, '');
                    wetProcessData[49].splice(wetProcessData[49].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount]) {
                    wetProcessData[50].splice(wetProcessData[50].length, 0, `Pre treatment ${treatmentCount + 1}`);
                    wetProcessData[51].splice(wetProcessData[51].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].reference.referenceNo);
                    const preType = preProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].process.type);
                    if (preType) {
                      wetProcessData[52].splice(wetProcessData[52].length, 0, preType.text);
                    } else {
                      wetProcessData[52].splice(wetProcessData[52].length, 0, '');
                    }
                    wetProcessData[53].splice(wetProcessData[53].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[54].splice(wetProcessData[54].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[55].splice(wetProcessData[55].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[56].splice(wetProcessData[56].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[57].splice(wetProcessData[57].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[58].splice(wetProcessData[58].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[59].splice(wetProcessData[59].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[50].splice(wetProcessData[50].length, 0, '');
                    wetProcessData[51].splice(wetProcessData[51].length, 0, '');
                    wetProcessData[52].splice(wetProcessData[52].length, 0, '');
                    wetProcessData[53].splice(wetProcessData[53].length, 0, '');
                    wetProcessData[54].splice(wetProcessData[54].length, 0, '');
                    wetProcessData[55].splice(wetProcessData[55].length, 0, '');
                    wetProcessData[56].splice(wetProcessData[56].length, 0, '');
                    wetProcessData[57].splice(wetProcessData[57].length, 0, '');
                    wetProcessData[58].splice(wetProcessData[58].length, 0, '');
                    wetProcessData[59].splice(wetProcessData[59].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount]) {
                    wetProcessData[60].splice(wetProcessData[60].length, 0, `Printing treatment ${treatmentCount + 1}`);
                    wetProcessData[61].splice(wetProcessData[61].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].reference.referenceNo);
                    const printType = printingList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].printType.type);
                    if (printType) {
                      wetProcessData[62].splice(wetProcessData[62].length, 0, printType.text);
                    } else {
                      wetProcessData[62].splice(wetProcessData[62].length, 0, '');
                    }
                    const shade = shadeList.find(s => s.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].shade.type);
                    if (shade) {
                      wetProcessData[63].splice(wetProcessData[63].length, 0, shade.text);
                    } else {
                      wetProcessData[63].splice(wetProcessData[63].length, 0, '');
                    }
                    const process = printProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].process.type);
                    if (process) {
                      wetProcessData[64].splice(wetProcessData[64].length, 0, process.text);
                    } else {
                      wetProcessData[64].splice(wetProcessData[64].length, 0, '');
                    }
                    wetProcessData[65].splice(wetProcessData[65].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[66].splice(wetProcessData[66].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[67].splice(wetProcessData[67].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[68].splice(wetProcessData[68].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[69].splice(wetProcessData[69].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[70].splice(wetProcessData[70].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[71].splice(wetProcessData[71].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[60].splice(wetProcessData[60].length, 0, '');
                    wetProcessData[61].splice(wetProcessData[61].length, 0, '');
                    wetProcessData[62].splice(wetProcessData[62].length, 0, '');
                    wetProcessData[63].splice(wetProcessData[63].length, 0, '');
                    wetProcessData[64].splice(wetProcessData[64].length, 0, '');
                    wetProcessData[65].splice(wetProcessData[65].length, 0, '');
                    wetProcessData[66].splice(wetProcessData[66].length, 0, '');
                    wetProcessData[67].splice(wetProcessData[67].length, 0, '');
                    wetProcessData[68].splice(wetProcessData[68].length, 0, '');
                    wetProcessData[69].splice(wetProcessData[69].length, 0, '');
                    wetProcessData[70].splice(wetProcessData[70].length, 0, '');
                    wetProcessData[71].splice(wetProcessData[71].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount]) {
                    wetProcessData[72].splice(wetProcessData[72].length, 0, `Coating/finishing ${treatmentCount + 1}`);
                    wetProcessData[73].splice(wetProcessData[73].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].reference.referenceNo);
                    const coatingType = coatingList.find(c => c.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].coatingOrFinishing.type);
                    if (coatingType) {
                      wetProcessData[74].splice(wetProcessData[74].length, 0, coatingType.text);
                    } else {
                      wetProcessData[74].splice(wetProcessData[74].length, 0, '');
                    }
                    wetProcessData[75].splice(wetProcessData[75].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[76].splice(wetProcessData[76].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[77].splice(wetProcessData[77].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[78].splice(wetProcessData[78].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[79].splice(wetProcessData[79].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[80].splice(wetProcessData[80].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[81].splice(wetProcessData[81].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[72].splice(wetProcessData[72].length, 0, '');
                    wetProcessData[73].splice(wetProcessData[73].length, 0, '');
                    wetProcessData[74].splice(wetProcessData[74].length, 0, '');
                    wetProcessData[75].splice(wetProcessData[75].length, 0, '');
                    wetProcessData[76].splice(wetProcessData[76].length, 0, '');
                    wetProcessData[77].splice(wetProcessData[77].length, 0, '');
                    wetProcessData[78].splice(wetProcessData[78].length, 0, '');
                    wetProcessData[79].splice(wetProcessData[79].length, 0, '');
                    wetProcessData[80].splice(wetProcessData[80].length, 0, '');
                    wetProcessData[81].splice(wetProcessData[81].length, 0, '');
                  }
                  if( (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount] && (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount + 1]
                  || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount + 1]
                  || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount + 1]
                  || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount + 1]))
                  || ( wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount] && (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount + 1]
                  || wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount + 1]
                  || wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount + 1]))
                  ){
                    treatmentCount = treatmentCount + 1;
                    wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                    wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                    wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                    wetProcessData[37].splice(wetProcessData[37].length, 0, '');
                  } else {
                    if(wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount]){
                      wetProcessData[2].splice(wetProcessData[2].length - treatmentCount, 1, `Yarn ${yarnCount + 1}`);
                      wetProcessData[3].splice(wetProcessData[3].length - treatmentCount, 1, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].reference.referenceNo);
                      this.mergeWetProcessCells.push({ s: { r: 42, c: wetProcessData[2].length - treatmentCount }, e: { r: 42, c: wetProcessData[2].length } },
                        { s: { r: 43, c: wetProcessData[3].length - treatmentCount }, e: { r: 43, c: wetProcessData[3].length } }
                      )
                    } else {
                      wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                      wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                    }
                    if(wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount]){
                      wetProcessData[36].splice(wetProcessData[36].length - treatmentCount, 1, `Fabric ${fabricCount + 1}`);
                      wetProcessData[37].splice(wetProcessData[37].length - treatmentCount, 1, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].reference.referenceNo);
                      this.mergeWetProcessCells.push({ s: { r: endYarnRow + 1, c: wetProcessData[36].length - treatmentCount }, e: { r: endYarnRow + 1, c: wetProcessData[36].length } },
                        { s: { r: endYarnRow + 2, c: wetProcessData[37].length - treatmentCount }, e: { r: endYarnRow + 2, c: wetProcessData[36].length } }
                      )
                    } else {
                      wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                      wetProcessData[37].splice(wetProcessData[37].length, 0, '');
                    }
                    treatmentCount = 0;
                    yarnCount = yarnCount + 1;
                    fabricCount = fabricCount + 1;
                  }
                }
              }
            } else {
              if(( wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount] && (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount + 1]
                || wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount + 1]
                || wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount + 1]))
                ){
                  treatmentCount = treatmentCount + 1;
                  wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                  wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                } else {
                  if(wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount]){
                    wetProcessData[2].splice(wetProcessData[2].length - treatmentCount, 1, `Yarn ${yarnCount + 1}`);
                    wetProcessData[3].splice(wetProcessData[3].length - treatmentCount, 1, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].reference.referenceNo);
                    this.mergeWetProcessCells.push({ s: { r: 42, c: wetProcessData[2].length - treatmentCount }, e: { r: 42, c: wetProcessData[2].length } },
                      { s: { r: 43, c: wetProcessData[3].length - treatmentCount }, e: { r: 43, c: wetProcessData[3].length } }
                    )
                  } else {
                    wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                    wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                  }
                  treatmentCount = 0;
                  yarnCount = yarnCount + 1;
                }
            }
            if (modeOfTransport.length > 0) {
              if (isYarnExist && isFabricExist) {
                if (modeOfTransport[fp]) {
                  if (modeOfTransport[fp].transportType.value !== '') {
                    const transportValue = transportType.find(t => t.value === modeOfTransport[fp].transportType.value);
                    if (transportValue) {
                      wetProcessData[82].splice(wetProcessData[82].length, 0, transportValue.text);
                    } else {
                      wetProcessData[82].splice(wetProcessData[82].length, 0, '');
                    }
                  } else {
                    wetProcessData[82].splice(wetProcessData[82].length, 0, '');
                  }
                  if (modeOfTransport[fp].distance.value !== -1) {
                    wetProcessData[83].splice(wetProcessData[83].length, 0, modeOfTransport[fp].distance.value.toString());
                  } else {
                    wetProcessData[83].splice(wetProcessData[83].length, 0, '');
                  }
                  if (modeOfTransport[fp].distanceUnit.unit !== '') {
                    const unit = transportUnitList.find(u => u.value === modeOfTransport[fp].distanceUnit.unit);
                    if (unit) {
                      wetProcessData[84].splice(wetProcessData[84].length, 0, unit.text);
                    }
                  } else {
                    wetProcessData[84].splice(wetProcessData[84].length, 0, '');
                  }
                } else {
                  wetProcessData[82].splice(wetProcessData[82].length, 0, '');
                  wetProcessData[83].splice(wetProcessData[83].length, 0, '');
                  wetProcessData[84].splice(wetProcessData[84].length, 0, '');
                }
              } else if (isYarnExist || isFabricExist) {
                if (isYarnExist) {
                  if (modeOfTransport[fp]) {
                    if (modeOfTransport[fp].transportType.value !== '') {
                      const transportValue = transportType.find(t => t.value === modeOfTransport[fp].transportType.value);
                      if (transportValue) {
                        wetProcessData[36].splice(wetProcessData[36].length, 0, transportValue.text);
                      } else {
                        wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                      }
                    } else {
                      wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                    }
                    if (modeOfTransport[fp].distance.value !== -1) {
                      wetProcessData[37].splice(wetProcessData[37].length, 0, modeOfTransport[fp].distance.value.toString());
                    } else {
                      wetProcessData[37].splice(wetProcessData[37].length, 0, '');
                    }
                    if (modeOfTransport[fp].distanceUnit.unit !== '') {
                      const unit = transportUnitList.find(u => u.value === modeOfTransport[fp].distanceUnit.unit);
                      if (unit) {
                        wetProcessData[38].splice(wetProcessData[38].length, 0, unit.text);
                      }
                    } else {
                      wetProcessData[38].splice(wetProcessData[38].length, 0, '');
                    }
                  } else {
                    wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                    wetProcessData[37].splice(wetProcessData[37].length, 0, '');
                    wetProcessData[38].splice(wetProcessData[38].length, 0, '');
                  }
                } else {
                  if (modeOfTransport[fp]) {
                    if (modeOfTransport[fp].transportType.value !== '') {
                      const transportValue = transportType.find(t => t.value === modeOfTransport[fp].transportType.value);
                      if (transportValue) {
                        wetProcessData[48].splice(wetProcessData[48].length, 0, transportValue.text);
                      } else {
                        wetProcessData[48].splice(wetProcessData[48].length, 0, '');
                      }
                    } else {
                      wetProcessData[48].splice(wetProcessData[48].length, 0, '');
                    }
                    if (modeOfTransport[fp].distance.value !== -1) {
                      wetProcessData[49].splice(wetProcessData[49].length, 0, modeOfTransport[fp].distance.value.toString());
                    } else {
                      wetProcessData[49].splice(wetProcessData[49].length, 0, '');
                    }
                    if (modeOfTransport[fp].distanceUnit.unit !== '') {
                      const unit = transportUnitList.find(u => u.value === modeOfTransport[fp].distanceUnit.unit);
                      if (unit) {
                        wetProcessData[50].splice(wetProcessData[50].length, 0, unit.text);
                      }
                    } else {
                      wetProcessData[50].splice(wetProcessData[50].length, 0, '');
                    }
                  } else {
                    wetProcessData[48].splice(wetProcessData[48].length, 0, '');
                    wetProcessData[49].splice(wetProcessData[49].length, 0, '');
                    wetProcessData[50].splice(wetProcessData[50].length, 0, '');
                  }
                }
              } else {
                if (modeOfTransport[fp]) {
                  if (modeOfTransport[fp].transportType.value !== '') {
                    const transportValue = transportType.find(t => t.value === modeOfTransport[fp].transportType.value);
                    if (transportValue) {
                      wetProcessData[2].splice(wetProcessData[2].length, 0, transportValue.text);
                    } else {
                      wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                    }
                  } else {
                    wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                  }
                  if (modeOfTransport[fp].distance.value !== -1) {
                    wetProcessData[3].splice(wetProcessData[3].length, 0, modeOfTransport[fp].distance.value.toString());
                  } else {
                    wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                  }
                  if (modeOfTransport[fp].distanceUnit.unit !== '') {
                    const unit = transportUnitList.find(u => u.value === modeOfTransport[fp].distanceUnit.unit);
                    if (unit) {
                      wetProcessData[4].splice(wetProcessData[4].length, 0, unit.text);
                    }
                  } else {
                    wetProcessData[4].splice(wetProcessData[4].length, 0, '');
                  }
                } else {
                  wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                  wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                  wetProcessData[4].splice(wetProcessData[4].length, 0, '');
                }
              }
            }
            if(fp > 0){
              wetProcessData[0].splice(wetProcessData[0].length, 0, '');
              wetProcessData[1].splice(wetProcessData[1].length, 0, '');
            }
          }
        } else {
          let yarnCount = 0;
          let fabricCount = 0;
          let treatmentCount = 0;
          for (let fp = 0; fp < modeOfTransport.length; fp++) {
            if (isYarnExist) {
              if (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount]) {
                if (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount]) {
                  wetProcessData[4].splice(wetProcessData[4].length, 0, `Dyeing treatment ${treatmentCount + 1}`);
                  wetProcessData[5].splice(wetProcessData[5].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].reference.referenceNo);
                  const dyeType = dyeTypeList.find(d => d.value === wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].dyeType.type);
                  if (dyeType) {
                    wetProcessData[6].splice(wetProcessData[6].length, 0, dyeType.text);
                  } else {
                    wetProcessData[6].splice(wetProcessData[6].length, 0, '');
                  }
                  const shade = shadeList.find(s => s.value === wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].shade.type);
                  if (shade) {
                    wetProcessData[7].splice(wetProcessData[7].length, 0, shade.text);
                  } else {
                    wetProcessData[7].splice(wetProcessData[7].length, 0, '');
                  }
                  const process = dyeingProcessList.find(p => p.value === wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].process.type);
                  if (process) {
                    wetProcessData[8].splice(wetProcessData[8].length, 0, process.text);
                  } else {
                    wetProcessData[8].splice(wetProcessData[8].length, 0, '');
                  }
                  wetProcessData[9].splice(wetProcessData[9].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                  wetProcessData[10].splice(wetProcessData[10].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].energyUsed.value.toString() : '');
                  wetProcessData[11].splice(wetProcessData[11].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].windEnergy.value.toString() : '');
                  wetProcessData[12].splice(wetProcessData[12].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].solarEnergy.value.toString() : '');
                  wetProcessData[13].splice(wetProcessData[13].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                  wetProcessData[14].splice(wetProcessData[14].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                  wetProcessData[15].splice(wetProcessData[15].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                } else {
                  wetProcessData[4].splice(wetProcessData[4].length, 0, '');
                  wetProcessData[5].splice(wetProcessData[5].length, 0, '');
                  wetProcessData[6].splice(wetProcessData[6].length, 0, '');
                  wetProcessData[7].splice(wetProcessData[7].length, 0, '');
                  wetProcessData[8].splice(wetProcessData[8].length, 0, '');
                  wetProcessData[9].splice(wetProcessData[9].length, 0, '');
                  wetProcessData[10].splice(wetProcessData[10].length, 0, '');
                  wetProcessData[11].splice(wetProcessData[11].length, 0, '');
                  wetProcessData[12].splice(wetProcessData[12].length, 0, '');
                  wetProcessData[13].splice(wetProcessData[13].length, 0, '');
                  wetProcessData[14].splice(wetProcessData[14].length, 0, '');
                  wetProcessData[15].splice(wetProcessData[15].length, 0, '');
                }
                if (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount]) {
                  wetProcessData[16].splice(wetProcessData[16].length, 0, `Pre treatment ${treatmentCount + 1}`);
                  wetProcessData[17].splice(wetProcessData[17].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].reference.referenceNo);
                  const preType = preProcessList.find(p => p.value === wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].process.type);
                  if (preType) {
                    wetProcessData[18].splice(wetProcessData[18].length, 0, preType.text);
                  } else {
                    wetProcessData[18].splice(wetProcessData[18].length, 0, '');
                  }
                  wetProcessData[19].splice(wetProcessData[19].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                  wetProcessData[20].splice(wetProcessData[20].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].energyUsed.value.toString() : '');
                  wetProcessData[21].splice(wetProcessData[21].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].windEnergy.value.toString() : '');
                  wetProcessData[22].splice(wetProcessData[22].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].solarEnergy.value.toString() : '');
                  wetProcessData[23].splice(wetProcessData[23].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                  wetProcessData[24].splice(wetProcessData[24].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                  wetProcessData[25].splice(wetProcessData[25].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                } else {
                  wetProcessData[16].splice(wetProcessData[16].length, 0, '');
                  wetProcessData[17].splice(wetProcessData[17].length, 0, '');
                  wetProcessData[18].splice(wetProcessData[18].length, 0, '');
                  wetProcessData[19].splice(wetProcessData[19].length, 0, '');
                  wetProcessData[20].splice(wetProcessData[20].length, 0, '');
                  wetProcessData[21].splice(wetProcessData[21].length, 0, '');
                  wetProcessData[22].splice(wetProcessData[22].length, 0, '');
                  wetProcessData[23].splice(wetProcessData[23].length, 0, '');
                  wetProcessData[24].splice(wetProcessData[24].length, 0, '');
                  wetProcessData[25].splice(wetProcessData[25].length, 0, '');
                }
                if (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount]) {
                  wetProcessData[26].splice(wetProcessData[26].length, 0, `Coating/finishing ${treatmentCount + 1}`);
                  wetProcessData[27].splice(wetProcessData[27].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].reference.referenceNo);
                  const coatingType = coatingList.find(c => c.value === wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].coatingOrFinishing.type);
                  if (coatingType) {
                    wetProcessData[28].splice(wetProcessData[28].length, 0, coatingType.text);
                  } else {
                    wetProcessData[28].splice(wetProcessData[28].length, 0, '');
                  }
                  wetProcessData[29].splice(wetProcessData[29].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount.toString() : '');
                  wetProcessData[30].splice(wetProcessData[30].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].energyUsed.value.toString() : '');
                  wetProcessData[31].splice(wetProcessData[31].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].windEnergy.value.toString() : '');
                  wetProcessData[32].splice(wetProcessData[32].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].solarEnergy.value.toString() : '');
                  wetProcessData[33].splice(wetProcessData[33].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                  wetProcessData[34].splice(wetProcessData[34].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                  wetProcessData[35].splice(wetProcessData[35].length, 0, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                } else {
                  wetProcessData[26].splice(wetProcessData[26].length, 0, '');
                  wetProcessData[27].splice(wetProcessData[27].length, 0, '');
                  wetProcessData[28].splice(wetProcessData[28].length, 0, '');
                  wetProcessData[29].splice(wetProcessData[29].length, 0, '');
                  wetProcessData[30].splice(wetProcessData[30].length, 0, '');
                  wetProcessData[31].splice(wetProcessData[31].length, 0, '');
                  wetProcessData[32].splice(wetProcessData[32].length, 0, '');
                  wetProcessData[33].splice(wetProcessData[33].length, 0, '');
                  wetProcessData[34].splice(wetProcessData[34].length, 0, '');
                  wetProcessData[35].splice(wetProcessData[35].length, 0, '');
                }
              }
            }
            if (isFabricExist) {
              if (!isYarnExist) {
                if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount]) {
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount]) {
                    wetProcessData[4].splice(wetProcessData[4].length, 0, `Dyeing treatment ${treatmentCount + 1}`);
                    wetProcessData[5].splice(wetProcessData[5].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].reference.referenceNo);
                    const dyeType = dyeTypeList.find(d => d.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].dyeType.type);
                    if (dyeType) {
                      wetProcessData[6].splice(wetProcessData[6].length, 0, dyeType.text);
                    } else {
                      wetProcessData[6].splice(wetProcessData[6].length, 0, '');
                    }
                    const shade = shadeList.find(s => s.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].shade.type);
                    if (shade) {
                      wetProcessData[7].splice(wetProcessData[7].length, 0, shade.text);
                    } else {
                      wetProcessData[7].splice(wetProcessData[7].length, 0, '');
                    }
                    const process = dyeingProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].process.type);
                    if (process) {
                      wetProcessData[8].splice(wetProcessData[8].length, 0, process.text);
                    } else {
                      wetProcessData[8].splice(wetProcessData[8].length, 0, '');
                    }
                    wetProcessData[9].splice(wetProcessData[9].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[10].splice(wetProcessData[10].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[11].splice(wetProcessData[11].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[12].splice(wetProcessData[12].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[13].splice(wetProcessData[13].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[14].splice(wetProcessData[14].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[15].splice(wetProcessData[15].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[4].splice(wetProcessData[4].length, 0, '');
                    wetProcessData[5].splice(wetProcessData[5].length, 0, '');
                    wetProcessData[6].splice(wetProcessData[6].length, 0, '');
                    wetProcessData[7].splice(wetProcessData[7].length, 0, '');
                    wetProcessData[8].splice(wetProcessData[8].length, 0, '');
                    wetProcessData[9].splice(wetProcessData[9].length, 0, '');
                    wetProcessData[10].splice(wetProcessData[10].length, 0, '');
                    wetProcessData[11].splice(wetProcessData[11].length, 0, '');
                    wetProcessData[12].splice(wetProcessData[12].length, 0, '');
                    wetProcessData[13].splice(wetProcessData[13].length, 0, '');
                    wetProcessData[14].splice(wetProcessData[14].length, 0, '');
                    wetProcessData[15].splice(wetProcessData[15].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount]) {
                    wetProcessData[16].splice(wetProcessData[16].length, 0, `Pre treatment ${treatmentCount + 1}`);
                    wetProcessData[17].splice(wetProcessData[17].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].reference.referenceNo);
                    const preType = preProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].process.type);
                    if (preType) {
                      wetProcessData[18].splice(wetProcessData[18].length, 0, preType.text);
                    } else {
                      wetProcessData[18].splice(wetProcessData[18].length, 0, '');
                    }
                    wetProcessData[19].splice(wetProcessData[19].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[20].splice(wetProcessData[20].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[21].splice(wetProcessData[21].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[22].splice(wetProcessData[22].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[23].splice(wetProcessData[23].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[24].splice(wetProcessData[24].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[25].splice(wetProcessData[25].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                  } else {
                    wetProcessData[16].splice(wetProcessData[16].length, 0, '');
                    wetProcessData[17].splice(wetProcessData[17].length, 0, '');
                    wetProcessData[18].splice(wetProcessData[18].length, 0, '');
                    wetProcessData[19].splice(wetProcessData[19].length, 0, '');
                    wetProcessData[20].splice(wetProcessData[20].length, 0, '');
                    wetProcessData[21].splice(wetProcessData[21].length, 0, '');
                    wetProcessData[22].splice(wetProcessData[22].length, 0, '');
                    wetProcessData[23].splice(wetProcessData[23].length, 0, '');
                    wetProcessData[24].splice(wetProcessData[24].length, 0, '');
                    wetProcessData[25].splice(wetProcessData[25].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount]) {
                    wetProcessData[26].splice(wetProcessData[26].length, 0, `Printing treatment ${treatmentCount + 1}`);
                    wetProcessData[27].splice(wetProcessData[27].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].reference.referenceNo);
                    const printType = printingList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].printType.type);
                    if (printType) {
                      wetProcessData[28].splice(wetProcessData[28].length, 0, printType.text);
                    } else {
                      wetProcessData[28].splice(wetProcessData[28].length, 0, '');
                    }
                    const shade = shadeList.find(s => s.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].shade.type);
                    if (shade) {
                      wetProcessData[29].splice(wetProcessData[29].length, 0, shade.text);
                    } else {
                      wetProcessData[29].splice(wetProcessData[29].length, 0, '');
                    }
                    const process = printProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].process.type);
                    if (process) {
                      wetProcessData[30].splice(wetProcessData[30].length, 0, process.text);
                    } else {
                      wetProcessData[30].splice(wetProcessData[30].length, 0, '');
                    }
                    wetProcessData[31].splice(wetProcessData[31].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[32].splice(wetProcessData[32].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[33].splice(wetProcessData[33].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[34].splice(wetProcessData[34].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[35].splice(wetProcessData[35].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[36].splice(wetProcessData[36].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[37].splice(wetProcessData[37].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[26].splice(wetProcessData[26].length, 0, '');
                    wetProcessData[27].splice(wetProcessData[27].length, 0, '');
                    wetProcessData[28].splice(wetProcessData[28].length, 0, '');
                    wetProcessData[29].splice(wetProcessData[29].length, 0, '');
                    wetProcessData[30].splice(wetProcessData[30].length, 0, '');
                    wetProcessData[31].splice(wetProcessData[31].length, 0, '');
                    wetProcessData[32].splice(wetProcessData[32].length, 0, '');
                    wetProcessData[33].splice(wetProcessData[33].length, 0, '');
                    wetProcessData[34].splice(wetProcessData[34].length, 0, '');
                    wetProcessData[35].splice(wetProcessData[35].length, 0, '');
                    wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                    wetProcessData[37].splice(wetProcessData[37].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount]) {
                    wetProcessData[38].splice(wetProcessData[38].length, 0, `Coating/finishing ${treatmentCount + 1}`);
                    wetProcessData[39].splice(wetProcessData[39].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].reference.referenceNo);
                    const coatingType = coatingList.find(c => c.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].coatingOrFinishing.type);
                    if (coatingType) {
                      wetProcessData[40].splice(wetProcessData[40].length, 0, coatingType.text);
                    } else {
                      wetProcessData[40].splice(wetProcessData[40].length, 0, '');
                    }
                    wetProcessData[41].splice(wetProcessData[41].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[42].splice(wetProcessData[42].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[43].splice(wetProcessData[43].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[44].splice(wetProcessData[44].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[45].splice(wetProcessData[45].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[46].splice(wetProcessData[46].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[47].splice(wetProcessData[47].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[38].splice(wetProcessData[38].length, 0, '');
                    wetProcessData[39].splice(wetProcessData[39].length, 0, '');
                    wetProcessData[40].splice(wetProcessData[40].length, 0, '');
                    wetProcessData[41].splice(wetProcessData[41].length, 0, '');
                    wetProcessData[42].splice(wetProcessData[42].length, 0, '');
                    wetProcessData[43].splice(wetProcessData[43].length, 0, '');
                    wetProcessData[44].splice(wetProcessData[44].length, 0, '');
                    wetProcessData[45].splice(wetProcessData[45].length, 0, '');
                    wetProcessData[46].splice(wetProcessData[46].length, 0, '');
                    wetProcessData[47].splice(wetProcessData[47].length, 0, '');
                  }
                  if( (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount] && (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount + 1]))
                    ){
                      treatmentCount = treatmentCount + 1;
                      wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                      wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                    } else {
                      if(wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount]){
                        wetProcessData[2].splice(wetProcessData[2].length - treatmentCount, 1, `Fabric ${fabricCount + 1}`);
                        wetProcessData[3].splice(wetProcessData[3].length - treatmentCount, 1, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].reference.referenceNo);
                        this.mergeWetProcessCells.push({ s: { r: endYarnRow + 1, c: wetProcessData[2].length - treatmentCount }, e: { r: endYarnRow + 1, c: wetProcessData[2].length } },
                          { s: { r: endYarnRow + 2, c: wetProcessData[3].length - treatmentCount }, e: { r: endYarnRow + 2, c: wetProcessData[3].length } }
                        )
                      } else {
                        wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                        wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                      }
                      treatmentCount = 0;
                      fabricCount = fabricCount + 1;
                    }
                }
              } else {
                if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount]) {
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount]) {
                    wetProcessData[38].splice(wetProcessData[38].length, 0, `Dyeing treatment ${treatmentCount + 1}`);
                    wetProcessData[39].splice(wetProcessData[39].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].reference.referenceNo);
                    const dyeType = dyeTypeList.find(d => d.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].dyeType.type);
                    if (dyeType) {
                      wetProcessData[40].splice(wetProcessData[40].length, 0, dyeType.text);
                    } else {
                      wetProcessData[40].splice(wetProcessData[40].length, 0, '');
                    }
                    const shade = shadeList.find(s => s.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].shade.type);
                    if (shade) {
                      wetProcessData[41].splice(wetProcessData[41].length, 0, shade.text);
                    } else {
                      wetProcessData[41].splice(wetProcessData[41].length, 0, '');
                    }
                    const process = dyeingProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].process.type);
                    if (process) {
                      wetProcessData[42].splice(wetProcessData[42].length, 0, process.text);
                    } else {
                      wetProcessData[42].splice(wetProcessData[42].length, 0, '');
                    }
                    wetProcessData[43].splice(wetProcessData[43].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[44].splice(wetProcessData[44].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[45].splice(wetProcessData[45].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[46].splice(wetProcessData[46].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[47].splice(wetProcessData[47].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[48].splice(wetProcessData[48].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[49].splice(wetProcessData[49].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[38].splice(wetProcessData[38].length, 0, '');
                    wetProcessData[39].splice(wetProcessData[39].length, 0, '');
                    wetProcessData[40].splice(wetProcessData[40].length, 0, '');
                    wetProcessData[41].splice(wetProcessData[41].length, 0, '');
                    wetProcessData[42].splice(wetProcessData[42].length, 0, '');
                    wetProcessData[43].splice(wetProcessData[43].length, 0, '');
                    wetProcessData[44].splice(wetProcessData[44].length, 0, '');
                    wetProcessData[45].splice(wetProcessData[45].length, 0, '');
                    wetProcessData[46].splice(wetProcessData[46].length, 0, '');
                    wetProcessData[47].splice(wetProcessData[47].length, 0, '');
                    wetProcessData[48].splice(wetProcessData[48].length, 0, '');
                    wetProcessData[49].splice(wetProcessData[49].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount]) {
                    wetProcessData[50].splice(wetProcessData[50].length, 0, `Pre treatment ${treatmentCount + 1}`);
                    wetProcessData[51].splice(wetProcessData[51].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].reference.referenceNo);
                    const preType = preProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].process.type);
                    if (preType) {
                      wetProcessData[52].splice(wetProcessData[52].length, 0, preType.text);
                    } else {
                      wetProcessData[52].splice(wetProcessData[52].length, 0, '');
                    }
                    wetProcessData[53].splice(wetProcessData[53].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[54].splice(wetProcessData[54].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[55].splice(wetProcessData[55].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[56].splice(wetProcessData[56].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[57].splice(wetProcessData[57].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[58].splice(wetProcessData[58].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[59].splice(wetProcessData[59].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[50].splice(wetProcessData[50].length, 0, '');
                    wetProcessData[51].splice(wetProcessData[51].length, 0, '');
                    wetProcessData[52].splice(wetProcessData[52].length, 0, '');
                    wetProcessData[53].splice(wetProcessData[53].length, 0, '');
                    wetProcessData[54].splice(wetProcessData[54].length, 0, '');
                    wetProcessData[55].splice(wetProcessData[55].length, 0, '');
                    wetProcessData[56].splice(wetProcessData[56].length, 0, '');
                    wetProcessData[57].splice(wetProcessData[57].length, 0, '');
                    wetProcessData[58].splice(wetProcessData[58].length, 0, '');
                    wetProcessData[59].splice(wetProcessData[59].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount]) {
                    wetProcessData[60].splice(wetProcessData[60].length, 0, `Printing treatment ${treatmentCount + 1}`);
                    wetProcessData[61].splice(wetProcessData[61].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].reference.referenceNo);
                    const printType = printingList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].printType.type);
                    if (printType) {
                      wetProcessData[62].splice(wetProcessData[62].length, 0, printType.text);
                    } else {
                      wetProcessData[62].splice(wetProcessData[62].length, 0, '');
                    }
                    const shade = shadeList.find(s => s.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].shade.type);
                    if (shade) {
                      wetProcessData[63].splice(wetProcessData[63].length, 0, shade.text);
                    } else {
                      wetProcessData[63].splice(wetProcessData[63].length, 0, '');
                    }
                    const process = printProcessList.find(p => p.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].process.type);
                    if (process) {
                      wetProcessData[64].splice(wetProcessData[64].length, 0, process.text);
                    } else {
                      wetProcessData[64].splice(wetProcessData[64].length, 0, '');
                    }
                    wetProcessData[65].splice(wetProcessData[65].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[66].splice(wetProcessData[66].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].energyUsed.value !== -1 ?wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[67].splice(wetProcessData[67].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[68].splice(wetProcessData[68].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[69].splice(wetProcessData[69].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[70].splice(wetProcessData[70].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[71].splice(wetProcessData[71].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[60].splice(wetProcessData[60].length, 0, '');
                    wetProcessData[61].splice(wetProcessData[61].length, 0, '');
                    wetProcessData[62].splice(wetProcessData[62].length, 0, '');
                    wetProcessData[63].splice(wetProcessData[63].length, 0, '');
                    wetProcessData[64].splice(wetProcessData[64].length, 0, '');
                    wetProcessData[65].splice(wetProcessData[65].length, 0, '');
                    wetProcessData[66].splice(wetProcessData[66].length, 0, '');
                    wetProcessData[67].splice(wetProcessData[67].length, 0, '');
                    wetProcessData[68].splice(wetProcessData[68].length, 0, '');
                    wetProcessData[69].splice(wetProcessData[69].length, 0, '');
                    wetProcessData[70].splice(wetProcessData[70].length, 0, '');
                    wetProcessData[71].splice(wetProcessData[71].length, 0, '');
                  }
                  if (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount]) {
                    wetProcessData[72].splice(wetProcessData[72].length, 0, `Coating/finishing ${treatmentCount + 1}`);
                    wetProcessData[73].splice(wetProcessData[73].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].reference.referenceNo);
                    const coatingType = coatingList.find(c => c.value === wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].coatingOrFinishing.type);
                    if (coatingType) {
                      wetProcessData[74].splice(wetProcessData[74].length, 0, coatingType.text);
                    } else {
                      wetProcessData[74].splice(wetProcessData[74].length, 0, '');
                    }
                    wetProcessData[75].splice(wetProcessData[75].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].waterUsedInFabric.amount.toString() : '');
                    wetProcessData[76].splice(wetProcessData[76].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].energyUsed.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].energyUsed.value.toString() : '');
                    wetProcessData[77].splice(wetProcessData[77].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].windEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].windEnergy.value.toString() : '');
                    wetProcessData[78].splice(wetProcessData[78].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].solarEnergy.value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].solarEnergy.value.toString() : '');
                    wetProcessData[79].splice(wetProcessData[79].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[0].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[0].value.toString() : '');
                    wetProcessData[80].splice(wetProcessData[80].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[1].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[1].value.toString() : '');
                    wetProcessData[81].splice(wetProcessData[81].length, 0, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2] && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== '' && wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value !== -1 ? wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergyNew[2].value.toString() + '% ' + wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount].thermalEnergySourceNew[2].value.toString() : '');
                  } else {
                    wetProcessData[72].splice(wetProcessData[72].length, 0, '');
                    wetProcessData[73].splice(wetProcessData[73].length, 0, '');
                    wetProcessData[74].splice(wetProcessData[74].length, 0, '');
                    wetProcessData[75].splice(wetProcessData[75].length, 0, '');
                    wetProcessData[76].splice(wetProcessData[76].length, 0, '');
                    wetProcessData[77].splice(wetProcessData[77].length, 0, '');
                    wetProcessData[78].splice(wetProcessData[78].length, 0, '');
                    wetProcessData[79].splice(wetProcessData[79].length, 0, '');
                    wetProcessData[80].splice(wetProcessData[80].length, 0, '');
                    wetProcessData[81].splice(wetProcessData[81].length, 0, '');
                  }
                  if( (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount] && (wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].dyiengTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].preTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].printTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].coatingFinishing[treatmentCount + 1]))
                    || ( wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount] && (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount + 1]
                    || wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount + 1]))
                    ){
                      treatmentCount = treatmentCount + 1;
                      wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                      wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                      wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                      wetProcessData[37].splice(wetProcessData[37].length, 0, '');
                    } else {
                      if(wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount]){
                        wetProcessData[2].splice(wetProcessData[2].length - treatmentCount, 1, `Yarn ${yarnCount + 1}`);
                        wetProcessData[3].splice(wetProcessData[3].length - treatmentCount, 1, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].reference.referenceNo);
                        this.mergeWetProcessCells.push({ s: { r: 42, c: wetProcessData[2].length - treatmentCount }, e: { r: 42, c: wetProcessData[2].length } },
                          { s: { r: 43, c: wetProcessData[3].length - treatmentCount }, e: { r: 43, c: wetProcessData[3].length } }
                        )
                      } else {
                        wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                        wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                      }
                      if(wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount]){
                        wetProcessData[36].splice(wetProcessData[36].length - treatmentCount, 1, `Fabric ${fabricCount + 1}`);
                        wetProcessData[37].splice(wetProcessData[37].length - treatmentCount, 1, wetProcessFootprint[wpIndex].fabricWetprocess[fabricCount].reference.referenceNo);
                        this.mergeWetProcessCells.push({ s: { r: endYarnRow + 1, c: wetProcessData[36].length - treatmentCount }, e: { r: endYarnRow + 1, c: wetProcessData[36].length } },
                          { s: { r: endYarnRow + 2, c: wetProcessData[37].length - treatmentCount }, e: { r: endYarnRow + 2, c: wetProcessData[36].length } }
                        )
                      } else {
                        wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                        wetProcessData[37].splice(wetProcessData[37].length, 0, '');
                      }
                      treatmentCount = 0;
                      yarnCount = yarnCount + 1;
                      fabricCount = fabricCount + 1;
                    }
                }
              }
            } else {
              if(( wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount] && (wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].dyiengTreatment[treatmentCount + 1]
                || wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].preTreatment[treatmentCount + 1]
                || wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].coatingFinishing[treatmentCount + 1]))
                ){
                  treatmentCount = treatmentCount + 1;
                  wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                  wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                } else {
                  if(wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount]){
                    wetProcessData[2].splice(wetProcessData[2].length - treatmentCount, 1, `Yarn ${yarnCount + 1}`);
                    wetProcessData[3].splice(wetProcessData[3].length - treatmentCount, 1, wetProcessFootprint[wpIndex].yarnWetprocess[yarnCount].reference.referenceNo);
                    this.mergeWetProcessCells.push({ s: { r: 42, c: wetProcessData[2].length - treatmentCount }, e: { r: 42, c: wetProcessData[2].length } },
                      { s: { r: 43, c: wetProcessData[3].length - treatmentCount }, e: { r: 43, c: wetProcessData[3].length } }
                    )
                  } else {
                    wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                    wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                  }
                  treatmentCount = 0;
                  yarnCount = yarnCount + 1;
                }
            }
            if (isYarnExist && isFabricExist) {
              if (modeOfTransport[fp]) {
                if (modeOfTransport[fp].transportType.value !== '') {
                  const transportValue = transportType.find(t => t.value === modeOfTransport[fp].transportType.value);
                  if (transportValue) {
                    wetProcessData[82].splice(wetProcessData[82].length, 0, transportValue.text);
                  } else {
                    wetProcessData[82].splice(wetProcessData[82].length, 0, '');
                  }
                } else {
                  wetProcessData[82].splice(wetProcessData[82].length, 0, '');
                }
                if (modeOfTransport[fp].distance.value !== -1) {
                  wetProcessData[83].splice(wetProcessData[83].length, 0, modeOfTransport[fp].distance.value.toString());
                } else {
                  wetProcessData[83].splice(wetProcessData[83].length, 0, '');
                }
                if (modeOfTransport[fp].distanceUnit.unit !== '') {
                  const unit = transportUnitList.find(u => u.value === modeOfTransport[fp].distanceUnit.unit);
                  if (unit) {
                    wetProcessData[84].splice(wetProcessData[84].length, 0, unit.text);
                  }
                } else {
                  wetProcessData[84].splice(wetProcessData[84].length, 0, '');
                }
              } else {
                wetProcessData[82].splice(wetProcessData[82].length, 0, '');
                wetProcessData[83].splice(wetProcessData[83].length, 0, '');
                wetProcessData[84].splice(wetProcessData[84].length, 0, '');
              }
            } else if (isYarnExist || isFabricExist) {
              if (isYarnExist) {
                if (modeOfTransport[fp]) {
                  if (modeOfTransport[fp].transportType.value !== '') {
                    const transportValue = transportType.find(t => t.value === modeOfTransport[fp].transportType.value);
                    if (transportValue) {
                      wetProcessData[36].splice(wetProcessData[36].length, 0, transportValue.text);
                    } else {
                      wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                    }
                  } else {
                    wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                  }
                  if (modeOfTransport[fp].distance.value !== -1) {
                    wetProcessData[37].splice(wetProcessData[37].length, 0, modeOfTransport[fp].distance.value.toString());
                  } else {
                    wetProcessData[37].splice(wetProcessData[37].length, 0, '');
                  }
                  if (modeOfTransport[fp].distanceUnit.unit !== '') {
                    const unit = transportUnitList.find(u => u.value === modeOfTransport[fp].distanceUnit.unit);
                    if (unit) {
                      wetProcessData[38].splice(wetProcessData[38].length, 0, unit.text);
                    }
                  } else {
                    wetProcessData[38].splice(wetProcessData[38].length, 0, '');
                  }
                } else {
                  wetProcessData[36].splice(wetProcessData[36].length, 0, '');
                  wetProcessData[37].splice(wetProcessData[37].length, 0, '');
                  wetProcessData[38].splice(wetProcessData[38].length, 0, '');
                }
              } else {
                if (modeOfTransport[fp]) {
                  if (modeOfTransport[fp].transportType.value !== '') {
                    const transportValue = transportType.find(t => t.value === modeOfTransport[fp].transportType.value);
                    if (transportValue) {
                      wetProcessData[48].splice(wetProcessData[48].length, 0, transportValue.text);
                    } else {
                      wetProcessData[48].splice(wetProcessData[48].length, 0, '');
                    }
                  } else {
                    wetProcessData[48].splice(wetProcessData[48].length, 0, '');
                  }
                  if (modeOfTransport[fp].distance.value !== -1) {
                    wetProcessData[49].splice(wetProcessData[49].length, 0, modeOfTransport[fp].distance.value.toString());
                  } else {
                    wetProcessData[49].splice(wetProcessData[49].length, 0, '');
                  }
                  if (modeOfTransport[fp].distanceUnit.unit !== '') {
                    const unit = transportUnitList.find(u => u.value === modeOfTransport[fp].distanceUnit.unit);
                    if (unit) {
                      wetProcessData[50].splice(wetProcessData[50].length, 0, unit.text);
                    }
                  } else {
                    wetProcessData[50].splice(wetProcessData[50].length, 0, '');
                  }
                } else {
                  wetProcessData[48].splice(wetProcessData[48].length, 0, '');
                  wetProcessData[49].splice(wetProcessData[49].length, 0, '');
                  wetProcessData[50].splice(wetProcessData[50].length, 0, '');
                }
              }
            } else {
              if (modeOfTransport[fp]) {
                if (modeOfTransport[fp].transportType.value !== '') {
                  const transportValue = transportType.find(t => t.value === modeOfTransport[fp].transportType.value);
                  if (transportValue) {
                    wetProcessData[2].splice(wetProcessData[2].length, 0, transportValue.text);
                  } else {
                    wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                  }
                } else {
                  wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                }
                if (modeOfTransport[fp].distance.value !== -1) {
                  wetProcessData[3].splice(wetProcessData[3].length, 0, modeOfTransport[fp].distance.value.toString());
                } else {
                  wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                }
                if (modeOfTransport[fp].distanceUnit.unit !== '') {
                  const unit = transportUnitList.find(u => u.value === modeOfTransport[fp].distanceUnit.unit);
                  if (unit) {
                    wetProcessData[4].splice(wetProcessData[4].length, 0, unit.text);
                  }
                } else {
                  wetProcessData[4].splice(wetProcessData[4].length, 0, '');
                }
              } else {
                wetProcessData[2].splice(wetProcessData[2].length, 0, '');
                wetProcessData[3].splice(wetProcessData[3].length, 0, '');
                wetProcessData[4].splice(wetProcessData[4].length, 0, '');
              }
            }
            if(fp > 0){
              wetProcessData[0].splice(wetProcessData[0].length, 0, '');
              wetProcessData[1].splice(wetProcessData[1].length, 0, '');
            }
          }
        }
        this.mergeWetProcessCells.push({ s: { r: 40, c: startColumn }, e: { r: 40, c: wetProcessData[0].length - 1 } }, { s: { r: 41, c: startColumn }, e: { r: 41, c: wetProcessData[1].length - 1 } });
      }
      return wetProcessData;
    }
    return [];
  }

  private async fetchProductData(order: OrderLineDisplayModel) {
    const garment = order.garment;
    const productCategoryList = FootprintDropdownHelper.getProductCategory();
    const cuttingWaste = FootprintDropdownHelper.getCuttingWasteType();
    const materialList = FootprintDropdownHelper.getMaterialList();
    const accessoriesTypeList = FootprintDropdownHelper.getAccessoriesList();
    const countryList = CountryHelper.getCountryList();
    const treamentList = FootprintDropdownHelper.getTreatmentList();
    const modeTransportList = FootprintDropdownHelper.getModeOfTransportList();
    const transportUnitList = FootprintDropdownHelper.getTransportUnit();
    if (order.garment.garmentDetails.productCategory.category !== '') {
      const productData = [
        ['PRODUCT', '', 'Assigned to supplier'],
        ['', '', 'Location of supplier'],
        ['', '', 'Product category',],
        ['', '', 'Product type',],
        ['', '', 'Total product weight (gram)'],
        ['', 'Fabric composition', ''],
        ['', '', 'Composition'],
        ['', '', 'Percentage'],
        ['', 'Percentage of fabric in product', ''],
        ['', '', 'Fabric'],
        ['', '', 'Percentage'],
        ['', 'Cutting waste', ''],
        ['', '', 'Cutting waste (%)'],
        ['', '', 'Destination of cutting waste'],
        ['', 'Packaging', ''],
        ['', '', 'Reference of packaging'],
        ['', '', 'Material'],
        ['', '', 'Weight (gram)'],
        ['', '', 'Number of colli per package'],
        ['', 'Accessories', ''],
        ['', '', 'Reference accessories'],
        ['', '', 'Type of accessories'],
        ['', '', 'Material'],
        ['', '', 'Weight (gram)'],
        ['', '', 'Number of accessories per product'],
        ['', '', 'Country origin'],
        ['', 'Post-treatment', ''],
        ['', '', 'Reference treatment'],
        ['', '', 'Type of treatment'],
        ['', '', 'Water use (l/kg)'],
        ['', 'Energy use', 'Energy use per kg fabric'],
        ['', '', '% wind energy'],
        ['', '', '% solar energy'],
        ['', '', 'thermal energy'],
        ['', '', ''],
        ['', '', ''],
        ['', 'Transport', 'Mode of transport'],
        ['', '', 'Distance'],
        ['', '', 'Unit'],
      ];
      let startProductRow = 0;
      if (order.wetprocess.wetprocessFootprint.length > 0) {
        if (order.wetprocess.wetprocessFootprint.find(w => w.yarnWetprocess.length > 0) && order.wetprocess.wetprocessFootprint.find(w => w.fabricWetprocess.length > 0)) {
          startProductRow = 126;
        } else if (order.wetprocess.wetprocessFootprint.find(w => w.yarnWetprocess.length > 0)) {
          startProductRow = 80;
        } else {
          startProductRow = 92;
        }
      } else {
        startProductRow = 40;
      }
      this.mergeProductCells.push({ s: { r: startProductRow, c: 0 }, e: { r: startProductRow + 38, c: 0 } },
        { s: { r: startProductRow, c: 1 }, e: { r: startProductRow + 4, c: 1 } },
        { s: { r: startProductRow + 5, c: 1 }, e: { r: startProductRow + 7, c: 1 } },
        { s: { r: startProductRow + 8, c: 1 }, e: { r: startProductRow + 10, c: 1 } },
        { s: { r: startProductRow + 11, c: 1 }, e: { r: startProductRow + 13, c: 1 } },
        { s: { r: startProductRow + 14, c: 1 }, e: { r: startProductRow + 18, c: 1 } },
        { s: { r: startProductRow + 19, c: 1 }, e: { r: startProductRow + 25, c: 1 } },
        { s: { r: startProductRow + 26, c: 1 }, e: { r: startProductRow + 29, c: 1 } },
        { s: { r: startProductRow + 30, c: 1 }, e: { r: startProductRow + 35, c: 1 } },
        { s: { r: startProductRow + 36, c: 1 }, e: { r: startProductRow + 38, c: 1 } },
        { s: { r: startProductRow + 33, c: 2 }, e: { r: startProductRow + 35, c: 2 } },
      )
      const allLengths = [garment.accessories.length, garment.garmentDetails.garmentFabric.length + 1, garment.cuttingWaste.length, garment.packaging.length, garment.postTreatment.length];
      const maxLength = allLengths.sort((a, b) => b - a)[0];
      this.mergeProductCells.push({ s: { r: startProductRow, c: productData[0].length }, e: { r: startProductRow, c: productData[0].length + (maxLength - 1) } });
      productData[0].splice(productData[0].length, 0, garment.garmentDetails.toCompanyUser.toCompanyName);
      this.mergeProductCells.push({ s: { r: startProductRow + 1, c: productData[1].length }, e: { r: startProductRow + 1, c: productData[1].length + (maxLength - 1) } });
      const location = await this.getCompanyLocation(garment.garmentDetails.toCompanyUser.toCompanyID);
      productData[1].splice(productData[1].length, 0, location);
      this.mergeProductCells.push({ s: { r: startProductRow + 2, c: productData[2].length }, e: { r: startProductRow + 2, c: productData[2].length + (maxLength - 1) } });
      this.mergeProductCells.push({ s: { r: startProductRow + 3, c: productData[3].length }, e: { r: startProductRow + 3, c: productData[3].length + (maxLength - 1) } });
      if (garment.garmentDetails.productCategory.category !== '') {
        const category = productCategoryList.find(p => p.value === garment.garmentDetails.productCategory.category);
        if (category) {
          productData[2].splice(productData[2].length, 0, category.text);
          const productTypeList = FootprintDropdownHelper.getProductType(garment.garmentDetails.productCategory.category);
          const type = productTypeList.find(p => p.value === garment.garmentDetails.productType.type);
          if (type) {
            productData[3].splice(productData[3].length, 0, type.text);
          } else {
            productData[3].splice(productData[3].length, 0, '');
          }
        } else {
          productData[2].splice(productData[2].length, 0, '');
          productData[3].splice(productData[3].length, 0, '');
        }
      } else {
        productData[2].splice(productData[2].length, 0, '');
        productData[3].splice(productData[3].length, 0, '');
      }
      this.mergeProductCells.push({ s: { r: startProductRow + 4, c: productData[4].length }, e: { r: startProductRow + 4, c: productData[4].length + (maxLength - 1) } });
      productData[4].splice(productData[4].length, 0, garment.garmentDetails.garmentTotalWeight.weight !== -1 ? garment.garmentDetails.garmentTotalWeight.weight.toString() : '');
      const fiberList = FootprintDropdownHelper.getFiberTypeList();
      const steps = this.orderLineSteps.filter(s => s.orderId === order.orderId && (s.stepId === 4 || s.stepId === 5 || s.stepId === 23));
      order.fabric.fabricFootprint.forEach((fab, index) => {
        if(steps.findIndex(s => s.id === fab.stepID) !== -1){
          let count = 0;
          if (fab.fiberFabric.length > 0) {
            fab.fiberFabric.forEach((fiber) => {
              productData[5].splice(productData[5].length + (index + 1), 0, '');
              const refText = fiberList.filter(f => f.value === fiber.fiberType.type).length > 0 ? fiberList.filter(f => f.value === fiber.fiberType.type)[0].text : '';
              productData[6].splice(productData[6].length + (index + 1), 0, refText);
              productData[7].splice(productData[7].length + (index + 1), 0, fiber.fiberValue.value !== -1 ? fiber.fiberValue.value.toString() : '');
              count++;
            })
          }
          if (fab.yarnFabric.length > 0) {
            fab.yarnFabric.forEach(yarnF => {
              productData[5].splice(productData[5].length + (index + 1), 0, '');
              productData[6].splice(productData[6].length + (index + 1), 0, yarnF.yarnRefNumber.referenceNumber);
              productData[7].splice(productData[7].length + (index + 1), 0, yarnF.yarnPercent.count !== -1 ? yarnF.yarnPercent.count.toString() : '');
              count++;
            })
          }
          productData[5].splice(productData[5].length - count, 1, 'Fabric ' + (index + 1));
          this.mergeProductCells.push({ s: { r: startProductRow + 5, c: productData[5].length - count}, e: { r: startProductRow + 5, c: productData[5].length - 1} });
        }
      })
      garment.garmentDetails.garmentFabric.forEach((fabric, index) => {
        productData[8].splice(productData[8].length + (index + 1), 0, 'Fabric ' + (index + 1));
        productData[9].splice(productData[9].length + (index + 1), 0, fabric.fabricReference.referenceNo);
        productData[10].splice(productData[10].length + (index + 1), 0, fabric.fabricPercent.percent === -1 ? '0' : fabric.fabricPercent.percent.toString());
        if (index + 1 === garment.garmentDetails.garmentFabric.length) {
          productData[8].splice(productData[8].length + (index + 1), 0, 'Total');
          productData[10].splice(productData[10].length + (index + 1), 0, garment.garmentDetails.total.totalcount.toString());
        }
      })
      garment.cuttingWaste.forEach((cutting, index) => {
        productData[11].splice(productData[11].length + (index + 1), 0, 'Fabric ' + (index + 1));
        productData[12].splice(productData[12].length + (index + 1), 0, cutting.fabricWastePercent.percent === -1 ? '0' : cutting.fabricWastePercent.percent.toString());
        if (cutting.cuttingWasteRecycle.value !== '') {
          const waste = cuttingWaste.find(w => w.value === cutting.cuttingWasteRecycle.value);
          if (waste) {
            productData[13].splice(productData[13].length + (index + 1), 0, waste.text);
          } else {
            productData[13].splice(productData[13].length + (index + 1), 0, '');
          }
        } else {
          productData[13].splice(productData[13].length + (index + 1), 0, '');
        }
      })
      garment.packaging.forEach((pack, index) => {
        productData[14].splice(productData[14].length + (index + 1), 0, 'Packaging ' + (index + 1));
        productData[15].splice(productData[15].length + (index + 1), 0, pack.reference.referenceNo);
        if (pack.matterial.value !== '') {
          const material = materialList.find(m => m.value === pack.matterial.value);
          if (material) {
            productData[16].splice(productData[16].length + (index + 1), 0, material.text);
          } else {
            productData[16].splice(productData[16].length + (index + 1), 0, '');
          }
        } else {
          productData[16].splice(productData[16].length + (index + 1), 0, '');
        }

        productData[17].splice(productData[17].length + (index + 1), 0, pack.weight.value !== -1 ? pack.weight.value.toString() : '');
        productData[18].splice(productData[18].length + (index + 1), 0, pack.colliPerPackaging.number !== -1 ? pack.colliPerPackaging.number.toString() : '');
      })
      garment.accessories.forEach((acc, index) => {
        productData[19].splice(productData[19].length + (index + 1), 0, 'Accessories ' + (index + 1));
        productData[20].splice(productData[20].length + (index + 1), 0, acc.reference.referenceNo);
        if (acc.accessoriesType.value !== '') {
          const accessories = accessoriesTypeList.find(a => a.value === acc.accessoriesType.value);
          if (accessories) {
            productData[21].splice(productData[21].length + (index + 1), 0, accessories.text);
          } else {
            productData[21].splice(productData[21].length + (index + 1), 0, '');
          }
        } else {
          productData[21].splice(productData[21].length + (index + 1), 0, '');
        }
        productData[22].splice(productData[22].length + (index + 1), 0, acc.matterial.value.toString());
        productData[23].splice(productData[23].length + (index + 1), 0, acc.weight.value !== -1 ? acc.weight.value.toString() : '');
        productData[24].splice(productData[24].length + (index + 1), 0, acc.accessoriesPergarment.number !== -1 ? acc.accessoriesPergarment.number.toString() : '');
        if (acc.originCountry.country !== '') {
          const country = countryList.find(c => c.value === acc.originCountry.country);
          if (country) {
            productData[25].splice(productData[25].length + (index + 1), 0, country.text);
          } else {
            productData[25].splice(productData[25].length + (index + 1), 0, '');
          }
        } else {
          productData[25].splice(productData[25].length + (index + 1), 0, '');
        }
      })
      garment.postTreatment.forEach((treat, index) => {
        productData[26].splice(productData[26].length + (index + 1), 0, 'Post treatment ' + (index + 1));
        productData[27].splice(productData[27].length + (index + 1), 0, treat.reference.referenceNo);
        if (treat.treatmentType.value !== '') {
          const treatment = treamentList.find(t => t.value === treat.treatmentType.value);
          if (treatment) {
            productData[28].splice(productData[28].length + (index + 1), 0, treatment.text);
          } else {
            productData[28].splice(productData[28].length + (index + 1), 0, '');
          }
        } else {
          productData[28].splice(productData[28].length + (index + 1), 0, '');
        }
        productData[29].splice(productData[29].length + (index + 1), 0, treat.waterUsedInFabric.amount.toString());
        productData[30].splice(productData[30].length + (index + 1), 0, treat.totalEnergyUsed.value !== -1 ? treat.totalEnergyUsed.value.toString() : '');
        productData[31].splice(productData[31].length + (index + 1), 0, treat.windEnergy.value !== -1 ? treat.windEnergy.value.toString() : '');
        productData[32].splice(productData[32].length + (index + 1), 0, treat.solarEnergy.value !== -1 ? treat.solarEnergy.value.toString() : '');
        productData[33].splice(productData[33].length + (index + 1), 0, treat.thermalEnergyNew[0] && treat.thermalEnergyNew[0].value !== '' && treat.thermalEnergyNew[0].value !== -1 ? treat.thermalEnergyNew[0].value.toString() + '% ' + treat.thermalEnergySourceNew[0].value.toString() : '');
        productData[34].splice(productData[34].length + (index + 1), 0, treat.thermalEnergyNew[1] && treat.thermalEnergyNew[1].value !== '' && treat.thermalEnergyNew[1].value !== -1 ? treat.thermalEnergyNew[1].value.toString() + '% ' + treat.thermalEnergySourceNew[1].value.toString() : '');
        productData[35].splice(productData[35].length + (index + 1), 0, treat.thermalEnergyNew[2] && treat.thermalEnergyNew[2].value !== '' && treat.thermalEnergyNew[2].value !== -1 ? treat.thermalEnergyNew[2].value.toString() + '% ' + treat.thermalEnergySourceNew[2].value.toString() : '');
      })
      if (garment.transport.length > 0) {
        garment.transport[0].modeOfTransport.forEach((transportMode, index) => {
          if (transportMode.transportType.value !== '') {
            const transportValue = modeTransportList.find(t => t.value === transportMode.transportType.value);
            if (transportValue) {
              productData[36].splice(productData[36].length + (index + 1), 0, transportValue.text);
            } else {
              productData[36].splice(productData[36].length + (index + 1), 0, '');
            }
          } else {
            productData[36].splice(productData[36].length + (index + 1), 0, '');
          }
          productData[37].splice(productData[37].length + (index + 1), 0, transportMode.distance.value !== -1 ? transportMode.distance.value.toString() : '');
          const unit = transportUnitList.find(u => u.value === transportMode.distanceUnit.unit);
          if (unit) {
            productData[38].splice(productData[38].length + (index + 1), 0, unit.text);
          } else {
            productData[38].splice(productData[38].length + (index + 1), 0, '');
          }
        })
      }
      return productData;
    }
    return [];
  }

  private async getCompanyLocation(companyID: string): Promise<string> {
    const resp = await this.supplierService.getSupplierAddressAsync(companyID);
    if (resp && resp.companies) {
      const country = CountryHelper.getCountryList().find(c => c.value === resp.companies.officeAddresses.country);
      if (country) {
        return country.text;
      }
      return '';
    }
    return '';
  }

  @Watch('showClearFilter')
  private setJoin() {
    if (this.showClearFilter) {
      this.join = true;
    }
  }
}
