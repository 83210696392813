import { render, staticRenderFns } from "./confirmComplianceDocument.vue?vue&type=template&id=d630ff06"
import script from "./confirmComplianceDocument.ts?vue&type=script&lang=js&external"
export * from "./confirmComplianceDocument.ts?vue&type=script&lang=js&external"
import style0 from "./confirmComplianceDocument.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports