import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import lodash from "lodash";
import { mapState } from "vuex";

// Services
import { SupplierService } from "@/services/supplierService";
import { OrderService } from "@/services/orderService";

// Helpers
import { SuppliersHelper } from "@/helpers/suppliersHelper";

// Models
import { SupplierOverviewModel } from "@/models/supplierOverviewModel";
import { CPSCPModel, SupplierOrderCountModel, SupplierPartnerModel, UniqueSupplierWithStepModel } from "@/models/supplierPartnerModel";
import { UserModel } from "@/models/userModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { SupplierPartnersBrandsModel } from "@/models/brandWithSupplierModel";

@Component({
  computed: mapState(['user', 'orders', 'supplierPartnersBrands', 't1AssociatedPartners', 'agentAssociatedPartners'])
})
export default class SupplyChainPartner extends Vue {

  @Prop()
  private supplier!: SupplierOverviewModel;

  @Prop()
  private selectedPartnerType!: number;

  private orders!: OrderLineDisplayModel[];

  private isValid: boolean = false;

  private partnersLoading: boolean = false;

  private partnerCallEnd: boolean = false;

  private scpOrderDetailsCount: SupplierOrderCountModel = new SupplierOrderCountModel;

  private partners: SupplierPartnerModel[] = [];

  private scpPartner: any = {};

  private supplierService: SupplierService;

  private orderService: OrderService;

  private user!: UserModel;

  private supplierPartnersBrands!: SupplierPartnersBrandsModel;

  private t1AssociatedPartners!: CPSCPModel[];

  private agentAssociatedPartners!: CPSCPModel[];

  public constructor() {
    super();
    this.supplierService = new SupplierService();
    this.orderService = new OrderService();
  }

  private async getSupplierPartner(supplierId: string,e:any): Promise<void> {
    try {
    e.stopPropagation();
    this.isValid = this.isValid ? false : true;
    if(this.partners.length === 0){
      this.partnersLoading = true;
      // if(this.supplierPartnersBrands !== null){
      //   let associatedpartners: SupplierPartnerModel[] = [];
      //     const partners = this.supplierPartnersBrands.associatedPartners.filter(ap => ap.invitedByCompanyID === supplierId && ap.id !== supplierId);
      //     if(partners.length > 0){
      //       partners.forEach(p => {
      //         const ap: SupplierPartnerModel = {
      //           partnerName: p.name,
      //           companySubtypes: p.companySubType,
      //           ID: p.id
      //         }
      //         associatedpartners.push(ap);
      //       })
      //     }
      //   this.partners = associatedpartners;
      // } else {
        // this.partners = await this.supplierService.getSupplierPartnersAsync(this.supplier.id);
        // this.partners = this.partners.filter(p => p.ID !== this.supplier.id);
        // if(this.partners.length > 0){
          const associatedPartners: SupplierPartnerModel[] = [];
          let uniqPartners: UniqueSupplierWithStepModel[] = [];
          let allPartners: CPSCPModel[] = [];
          // TTD-3978: show suppliers associated with agent
          if(this.selectedPartnerType === 2){
            uniqPartners = SuppliersHelper.getAgentRelatedSuppliers(this.supplier.id);
            allPartners = this.agentAssociatedPartners.filter(ap => ap.companyID === this.supplier.id);
          } else {
            // TTD-3916: show suppliers based on client and cp
            uniqPartners = SuppliersHelper.getCPRelatedSuppliers(this.supplier.id);
            allPartners = this.t1AssociatedPartners.filter(ap => ap.companyID === this.supplier.id);
          }
          if(allPartners.length > 0){
            allPartners[0].suppliers.forEach(sup => {
              const partners = uniqPartners.filter(ap => ap.supplierId === sup.invitedCompanyID);
              if(partners.length > 0){
                const ap: SupplierPartnerModel = {
                  partnerName: sup.companyName,
                  companySubtypes: partners[0].steps,
                  ID: sup.invitedCompanyID,
                  onboarded: sup.onboarded
                }
                associatedPartners.push(ap);
              }
            })
          }
          this.partners = associatedPartners;
    }
      // }
    // }
    } finally {
      this.partnersLoading = false;
      this.partnerCallEnd = true;
    }
  }

  private getTypeText(type: number): string {
    return this.$t(`enums.steps.${type}`).toString();
  }

  private openScpDetails(partner: any, e: any): void {
    e.stopPropagation();
    let scpPartner:any;
     scpPartner = {
      id: partner.ID,
      name: partner.partnerName
    };
    this.openDetails(scpPartner);
  }

  private async getScpOrderCount(supplierId: string): Promise<void> {
    //TTD-3978
    this.scpOrderDetailsCount = new SupplierOrderCountModel;
    if(this.user.companyType === 1 && this.selectedPartnerType === 1){
      const cpRelatedOrders = this.orders.filter(o => o.toCompanyId === supplierId);
      this.scpOrderDetailsCount.inProgressCount = cpRelatedOrders.filter(o => o.orderLines[0].orderLinePhase === 1).length;
      this.scpOrderDetailsCount.publishedCount = cpRelatedOrders.filter(o => o.orderLines[0].orderLinePhase === 2).length;
    } else if(this.user.companyType === 1 && this.selectedPartnerType === 2){
      const agentRelatedOrders = this.orders.filter(o => o.agentCompanyID === supplierId);
      this.scpOrderDetailsCount.inProgressCount = agentRelatedOrders.filter(o => o.orderLines[0].orderLinePhase === 1).length;
      this.scpOrderDetailsCount.publishedCount = agentRelatedOrders.filter(o => o.orderLines[0].orderLinePhase === 2).length;
    } else {
      this.scpOrderDetailsCount.inProgressCount = 0;
      this.scpOrderDetailsCount.publishedCount = 0;
    }
  }

  @Emit()
    private openDetails(supplier: SupplierOverviewModel): void {
    }
}