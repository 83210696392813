import { Vue, Component } from "vue-property-decorator";

// Components
import Authpage from "@/components/general/authpage.vue";

@Component({
  components: { Authpage }
})

export default class SignupSuccess extends Vue {
  private showInviteSuccess: boolean = false;
  private emailAddress: string = "";

  public constructor() {
    super();
  }

  private async created(): Promise<void> {
    if (this.$route.params.resendInvite === "SUCCESS") {
      this.showInviteSuccess = true;
      this.emailAddress = this.$route.params.resendInviteEmail;
    }
  }

}
