import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import lodash from "lodash";
import { mapState } from "vuex";
import moment from "moment";

// Components
import Watermark from "@/views/compliance/components/watermark.vue";

// Helper
import { FileHelper } from '@/helpers/fileHelper';

// Model
import { UserModel } from "@/models/userModel";
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";

// Services
import { ClientService } from "@/services/clientService";
import { CompanyNameModel } from "@/models/companyModel";


@Component({
    components: { VuePdfApp, Watermark },
    computed: mapState(['companyNameList'])
})

export default class ViewSignedDocument extends Vue {

    @Prop()
    private document!: ComplianceDocSentResponseModel;

    private pdfDocument!: any;
    private isPdfReady: boolean = false;
    private config: any = {
        toolbar: false
    };

    private pdfZoom: string = "page-width";
    private maxPages: number = 0;
    private pdfWidth: number = 0;
    private pdfHeight: number = 0;
    private lDownloadPDF: boolean = false;
    private htmlToAdd: string = "";
    private user: UserModel = new UserModel;
    private downloadableHTML: string = "";
    private numPages: number = 0;
    // private loadingTask = VuePdf.createLoadingTask(this.document.docURL);
	private clientService: ClientService;
    private pdfBase64: string = "";
    private signedImg: NodeRequire = require("../../../assets/signed.png");
    private signedImgBase64: string | ArrayBuffer | null = "";
    private isDownloading: boolean = false;
    private companyNameList!: CompanyNameModel[]; // TTD-3961

    public constructor() {
        super();
		this.clientService = new ClientService();
    }

    private async created(): Promise<void> {
        this.user = this.$store.getters.user as UserModel;
        this.document.fromCompanyName = this.companyNameList.filter(c => c.ID == this.document.fromCompanyID).length > 0 ? this.companyNameList.filter(c => c.ID == this.document.fromCompanyID)[0].name : this.document.fromCompanyName;
    }

    private async mounted(): Promise<void> {
        // this.loadingTask.promise.then((pdf: any) => {

		// 	this.numPages = pdf.numPages;
		// });
    }

    private get brands(): string {
        const uniqBrands = lodash.uniq(this.document.brands);
        return uniqBrands.join(', ');
    }

    private get formattedDate(): string {
        return moment(this.document.scpSignedDate).format("D MMMM YYYY");
    }

    private formatteddate(): string {
        return moment(this.document.scpSignedDate).format("D MMMM YYYY");
    }

    private formatteddateRead(): string {
        return moment(this.document.scpReadDate).format("D MMMM YYYY");
    }

    private get fullname(): string {
        return (this.document.toCompanyContactName) ? this.document.toCompanyContactName : "";
    }

    private  fullName(): string {
        return (this.document.toCompanyContactName) ? this.document.toCompanyContactName : "";
    }

    private formatDate(dateToBeFormatted: string): string {
        if (dateToBeFormatted==="9999-01-01T00:00:00Z") {
            return "No deadline";
        }
        return moment(dateToBeFormatted).format("D MMMM YYYY");
    }

    private async downloadPDF(): Promise<void> {
        const app = this;
        this.isPdfReady = false;
        this.lDownloadPDF = true;
        if (this.pdfDocument.pdfViewer.currentScaleValue !== "page-width") {
            this.pdfDocument.pdfViewer.currentScaleValue = "page-width";
        } else {
            await this.downloadWatermarkedPDF();
        }
    }

    private async imgToBase64(): Promise<any> {
        var app = this;
        var xhr = new XMLHttpRequest();
        const image = require("../../../assets/signed.png");
        xhr.open("GET", image, true);
        xhr.responseType = "blob";
        xhr.onload = await function (e) {
                var reader = new FileReader();
                reader.onload = function(event) {
                var res = event.target!.result;
                app.signedImgBase64 = res;
                };
                var file = this.response;
                reader.readAsDataURL(file);
        };
        this.signedImgBase64 = app.signedImgBase64;
        xhr.send();
    }

    private async downloadWatermarkedPDF(): Promise<void> {
        this.isDownloading = true;
        const app = this;
        app.isPdfReady = false;
        let downloadName = `${app.document.documentName.charAt(0).toUpperCase()}${app.document.documentName.slice(1).toLowerCase()}`;
        downloadName = downloadName.replace(".pdf", "");
        downloadName += ` signed by ${app.document.toCompanyName} ${app.formattedDate}.pdf`;

        await fetch(app.document.docURL).then((res) => { return res.arrayBuffer(); }).then(data => {
            this.pdfBase64 = this._arrayBufferToBase64(data);
        });
        // For converting svg to base64
        const svg = document.querySelector('svg');
        if (svg) {
            const svgStr = new XMLSerializer().serializeToString(svg);
            const encodedData = window.btoa(unescape(encodeURIComponent(svgStr)));
            this.signedImgBase64 = encodedData;
        }
        const data = {"SignedBy":this.fullName(),"SignedOn":this.formatteddate(),"DocName": downloadName, "Base64PDFDoc": this.pdfBase64, "StampPNG": this.signedImgBase64};
        const result = await this.clientService.downloadSignedPdf(data);
        const linkSource = `data:application/pdf;base64,${result.signedPdfResponse}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = downloadName;
        downloadLink.click();
        this.isDownloading = false;
        app.isPdfReady = true;
    }

    private _arrayBufferToBase64( buffer: ArrayBuffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    private async updateWatermark(): Promise<void> {
        const app: any = this;
        app.isPdfReady = false;

        setTimeout((app: any) => {
            app.pdfWidth = parseFloat(app.$refs.vuepdfapp.$el.querySelector('[class="page"][data-page-number="' + app.maxPages + '"] ').style.width);
            app.pdfHeight = parseFloat(app.$refs.vuepdfapp.$el.querySelector('[class="page"][data-page-number="' + app.maxPages + '"] ').style.height);

            app.$refs.vuepdfapp.$el.querySelector('[class="page"][data-page-number="' + app.maxPages + '"] > [class="textLayer"] ').style.opacity = 0.8;
            app.$refs.vuepdfapp.$el.querySelector('[class="page"][data-page-number="' + app.maxPages + '"] > [class="textLayer"] > [class="endOfContent"]').insertAdjacentHTML("afterend", `<div id="watermark-${app.maxPages}"></div>`);

            new Watermark({
                propsData: {
                    width: app.pdfWidth * 0.41,
                    height: app.pdfHeight * 0.43,
                    signdate: app.formattedDate,
                    signedontext: 'Signed on',
                    fullname: app.fullname,
                }
            }).$mount(`#watermark-${app.maxPages}`);

            const lenWatermarkSignon: number = app.$refs.vuepdfapp.$el.querySelector('[id="watermark-signon"]').innerHTML.length;
            const lenWatermarkSigndate: number = app.$refs.vuepdfapp.$el.querySelector('[id="watermark-signdate"]').innerHTML.length;
            const padWatermarkSignon: number = (lenWatermarkSigndate - lenWatermarkSignon) / 2;

            const posWatermarkSignon = parseFloat(app.$refs.vuepdfapp.$el.querySelector('[id="watermark-signon"]').getAttribute("x"));
            app.$refs.vuepdfapp.$el.querySelector('[id="watermark-signon"]').setAttribute("x", posWatermarkSignon + (35));

            const posWatermarkSigndate = parseFloat(app.$refs.vuepdfapp.$el.querySelector('[id="watermark-signdate"]').getAttribute("x"));
            app.$refs.vuepdfapp.$el.querySelector('[id="watermark-signdate"]').setAttribute("x", posWatermarkSigndate + (35 - (padWatermarkSignon * 5)));

            const lenWatermarkFirstname: number = app.$refs.vuepdfapp.$el.querySelector('[id="watermark-firstname"]').innerHTML.length;
            const posWatermarkFirstname = parseFloat(app.$refs.vuepdfapp.$el.querySelector('[id="watermark-firstname"]').getAttribute("x"));
            if (lenWatermarkFirstname <= 20) {
                app.$refs.vuepdfapp.$el.querySelector('[id="watermark-firstname"]').setAttribute("x", posWatermarkFirstname + (35 - lenWatermarkFirstname));
            }

            const lenWatermarkLastname: number = app.$refs.vuepdfapp.$el.querySelector('[id="watermark-lastname"]').innerHTML.length;
            const posWatermarkLastname = parseFloat(app.$refs.vuepdfapp.$el.querySelector('[id="watermark-lastname"]').getAttribute("x"));
            if (lenWatermarkLastname <= 20) {
                app.$refs.vuepdfapp.$el.querySelector('[id="watermark-lastname"]').setAttribute("x", posWatermarkLastname + (35 - lenWatermarkLastname));
            }

            if (lenWatermarkLastname == 0) {
                app.$refs.vuepdfapp.$el.querySelector('[id="watermark-firstname"]').setAttribute("x", posWatermarkFirstname + (35 - lenWatermarkFirstname));
            }

            if (app.lDownloadPDF) {
                setTimeout((appInner: any) => {
                    appInner.downloadWatermarkedPDF();
                }, 100, app);

            }
            app.isPdfReady = true;

        }, 100 * app.maxPages, app);

    }

    private async pdfPagesRendered(pdf: any): Promise<void> {
        if (this.document.signatureRequested === 'true') {
            setTimeout(() => (pdf.pdfViewer.currentScaleValue = "page-width"));
            setTimeout((appInner: any) => {
                appInner.updateWatermark();
            }, 200 * pdf.pdfDocument.numPages, this);
        }
    }

    private async pdfReady(pdfApp: any): Promise<void> {
        const app = this;
        app.pdfDocument = pdfApp;
        if (this.document.signatureRequested === 'true') {
            app.pdfDocument.pdfViewer.currentScaleValue = app.pdfZoom;
            app.maxPages = await app.pdfDocument.pdfDocument.numPages;

            app.pdfDocument.pdfViewer.eventBus.on("pagerendered", function (event: any) {
                if (app.pdfZoom !== app.pdfDocument.pdfViewer.currentScaleValue) {
                    app.pdfZoom = app.pdfDocument.pdfViewer.currentScaleValue;
                    app.updateWatermark();
                }
            });

            for (let i = 0; i < app.maxPages; i++) {
                setTimeout((appInner: any) => {
                    app.pdfDocument.page = i + 1;

                    if (i === app.maxPages - 1) {
                        appInner.$refs.vuepdfappContainer.focus();
                    }
                }, 250 * (i + 1), app);
            }
        } else {
            app.isPdfReady = true;
        }

    }

    @Emit()
    private close(success: boolean) {
    }
}
