import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import store from '@/store';
import { mapGetters } from 'vuex';

// Components
import Multiselect from '@/components/input/multiselect.vue';

// Helpers
import { CertificateTcHelper } from "@/helpers/certificateTcHelper";

// Services
import { OrderService } from '@/services/orderService';
import { ClientService } from '@/services/clientService';

// Models
import { UserModel } from '@/models/userModel';
import { DropdownModel } from '@/models/dropdownModel';
import { TcMailRequestModel, TransactionCertsModel } from '@/models/transactionCertsModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { TcResponseModel } from '@/models/tcResponseModel';

@Component({
  components: {Multiselect},
  computed: {
    ...mapGetters(['companyType', 'user']),
  }
})
export default class AddTCCertificate extends Vue {

    @Prop()
    private orderId!: string;

    private certificateTypes: DropdownModel[] = [];

    private certificateOptions: DropdownModel[] = [];

    private disableConfirm: boolean = false;

    private isLoading: boolean = false;

    private transactionCertData: TransactionCertsModel = new TransactionCertsModel('','','','', '', '');

    private orderService: OrderService;

    private clientService: ClientService;

    private user!: UserModel;

    public constructor() {
        super();
        this.orderService = new OrderService();
        this.clientService = new ClientService();
    }

    private created(): void {
        this.disableConfirm = true;
        this.certificateTypes = CertificateTcHelper.getCertificateTypes();
        this.certificateOptions = CertificateTcHelper.getCertificateOptions();
    }

    private validateForm(field: string): void {
      if((this.transactionCertData.levelTC1 === '' && this.transactionCertData.tranCertificate1 !== '') || (this.transactionCertData.levelTC1 !== '' && this.transactionCertData.tranCertificate1 === '') ||
        (this.transactionCertData.levelTC2 === '' && this.transactionCertData.tranCertificate2 !== '') || (this.transactionCertData.levelTC2 !== '' && this.transactionCertData.tranCertificate2 === '')){
        this.disableConfirm = true;
      }else{
        this.disableConfirm = false;
      }
    }

    private async confirmSave(): Promise<void> {
      if (this.disableConfirm) {
        return;
      }
      this.isLoading = true;
      let tc1Status = '';
      let tc2Status = '';
      if(this.transactionCertData.levelTC1!=='' && this.transactionCertData.tranCertificate1){
        tc1Status = 'PENDING'
      }
      if(this.transactionCertData.levelTC2!=='' && this.transactionCertData.tranCertificate2){
        tc2Status = 'PENDING'
      }
      const payload = {
        orderID: this.orderId,
        tranCertificate1: this.transactionCertData.tranCertificate1,
        tranCertificate2: this.transactionCertData.tranCertificate2,
        levelTC1: this.transactionCertData.levelTC1,
        levelTC2: this.transactionCertData.levelTC2,
        tc1Status: tc1Status,
        tc2Status: tc2Status
      }
      const response: TcResponseModel = await this.orderService.confirmTcAsync(payload);
      if (response.success) {
        const orders = store.getters.orders as OrderLineDisplayModel[];
        const orderIndex = orders.findIndex(o => o.orderId === response.order.orderId);
        if(orderIndex !== -1){
          orders[orderIndex].tranCertificate1 = response.order.tranCertificate1;
          orders[orderIndex].tranCertificate2 = response.order.tranCertificate2;
          orders[orderIndex].levelTC1 = response.order.levelTC1;
          orders[orderIndex].levelTC2 = response.order.levelTC2;
          orders[orderIndex].tc1Status = response.order.tc1Status;
          orders[orderIndex].tc2Status = response.order.tc2Status;
          orders[orderIndex].license = response.order.license;
          orders[orderIndex].tranCertificate1RefId = response.order.tranCertificate1RefId;
          orders[orderIndex].tranCertificate2RefId = response.order.tranCertificate2RefId;
          orders[orderIndex].customer = response.order.customer;
          orders[orderIndex].tranCertificate1Date = response.order.tranCertificate1Date;
          orders[orderIndex].tranCertificate2Date = response.order.tranCertificate2Date;
          orders[orderIndex].tranCertificateUpdatedBy = response.order.tranCertificateUpdatedBy;
          orders[orderIndex].tc1Type = response.order.tc1Type;
          orders[orderIndex].tc2Type = response.order.tc2Type;
          orders[orderIndex].tcReferenceNumber = response.order.tcReferenceNumber;
          orders[orderIndex].tcReferenceNumber1 = response.order.tcReferenceNumber1;
        }
        store.commit('setAllOrders', orders);
        const TC: string[] = [];
        if(this.transactionCertData.tranCertificate1){
          TC.push(this.transactionCertData.tranCertificate1);
        }
        if(this.transactionCertData.tranCertificate2){
          TC.push(this.transactionCertData.tranCertificate2);
        }
        const TcMailRequest: TcMailRequestModel = {
          clientCompanyName: this.user.companyName,
          clientUserFirstName: this.user.firstName,
          clientUserLastName: this.user.lastName,
          orderNumbers: response.order.orderNumber,
          tcType: TC.join(', '),
          supplierIDs: [orders[orderIndex].toCompanyId]
        }
        await this.clientService.sendTCRequestMail(TcMailRequest);
      }
      this.isLoading = false;
      this.close();
    }

    @Emit()
    private close() {}

}
