import { Vue, Component, Watch, Prop, Emit } from "vue-property-decorator";
import lodash, { map } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { mapState } from "vuex";

// Components
import PendingOrderLine from '@/views/orderLines/components/pendingOrderLine.vue';
import PendingOrderLineSelected from '@/views/orderLines/components/pendingOrderLineSelected.vue';
import OrderLineLoader from '@/views/orderLines/components/orderLineLoader.vue';

// Models
import { Guid } from "@/models/guid";
import { CombinedOrderLineModel, RemoveCombinedConfirmedOrders } from '@/models/combinedOrderLineModel';
import { DropdownModel } from '@/models/dropdownModel';
import { OrderModel } from '@/models/orderModel';
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { ConfirmOrderLineModel } from "@/models/confirmOrderLineModel";

// Services
import { OrderService } from '@/services/orderService';
import { SupplierService } from '@/services/supplierService';
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { OrderLineModel } from '@/models/orderLineModel';

// Helper
import { DateTimeHelper } from "@/helpers/dateTimeHelper";
import { ProductGroupHelper } from "@/helpers/productGroupHelper";
import { ActionTypes } from "@/store/actions/actions";

@Component({
    components: { PendingOrderLine, PendingOrderLineSelected, OrderLineLoader },
    computed: mapState(['companyType', 'collapsedSidebar'])
})
export default class PendingOrderLinesTile extends Vue {

    @Prop()
    private pendingOrders!: OrderLineDisplayModel[];

    @Prop()
    private isLoading!: boolean;

    @Prop()
    private orderConfirming!: boolean;

    @Prop()
    private isDeleted!: boolean;

    @Prop()
    private confirmedOrderIds!: RemoveCombinedConfirmedOrders[];

    @Prop()
    private isOrdersConfirmed!: RemoveCombinedConfirmedOrders[];

    //TTD-2620, Agent orderlines changes,
    private companyType!: number;

    private suppliersLoading: boolean = false;

    private orders: OrderLineDisplayModel[] = [];

    private combinedOrders: CombinedOrderLineModel[] = [];

    private suppliers: OrderCompanyModel[] = [];

    private orderService: OrderService;

    private supplierService: SupplierService;

    private imgSrc: NodeRequire = require("../../../assets/no-pending-order-lines.png");

    private supplierChanged: boolean = false;

    private selectedPendingReview: CombinedOrderLineModel[] = [];
    private selectedPendingAssign: CombinedOrderLineModel[] = [];
    private selectedWaitingForAgent: CombinedOrderLineModel[] = [];

    private pendingReviewCount: number = 0;
    private pendingAssignmentCount: number = 0;
    private pendingWaitingForAgentCount: number = 0;

    private selectedBulkIds: string[] = [];

    private pendingType: string = 'pending'

    // private collapsedSidebar!: boolean;

    private isCreateSupplyModal: boolean = false;

    private currentOrders:CombinedOrderLineModel[] = [];

    private allOrdersSelected: boolean = false;

    private collapsedSidebar!: boolean;

    public constructor() {
        super();
        this.orderService = new OrderService();
        this.supplierService = new SupplierService();
    }

    private async created(): Promise<void> {
        try {
            this.suppliersLoading = true;
            this.orders = this.pendingOrders;
            await this.fetchSuppliers();
            this.setCombinedOrders();
        }
        finally {
            this.suppliersLoading = false;
        }
    }

    private async fetchSuppliers(): Promise<void>{
        if (this.$store.getters.suppliers != null && this.$store.getters.suppliers.length > 0 && !this.supplierChanged) {
            this.suppliers = this.$store.getters.suppliers;
        }
        else{
            // const suppliers = await this.supplierService.getSuppliersAsync();
            await this.$store.dispatch(ActionTypes.SET_SUPPLIER_CERTIFICATE);
            this.suppliers = this.$store.getters.suppliers;
        }
    }

    private isSupplierConnected(order: CombinedOrderLineModel): boolean {
        //TTD-2620, Agent orderlines changes, added condition
        let isSupplierConnected: boolean = false;
        if (this.companyType === 5) {
            isSupplierConnected = order.toCompanyId !== '' || order.toCompanyId !== Guid.Empty ? true : false;
        } else {
            isSupplierConnected = this.suppliers.findIndex(s => s.id === order.supplierId && s.isTier1 === true) !== -1 ? true : false;
        }

        if (!isSupplierConnected) {
            order.supplierId = Guid.Empty;
        }
        return isSupplierConnected;
    }

    private checkIfSelected(pendingtype: string, order: CombinedOrderLineModel): boolean {
        if (pendingtype == "review") {
            return this.selectedPendingReview.findIndex(s => (s.supplierName === order.supplierName && s.productGroup === order.productGroup)) !== -1 ? true : false;
        }else if(pendingtype == "pending"){
            return this.selectedPendingAssign.findIndex(s => (s.supplierName === order.supplierName && s.productGroup === order.productGroup)) !== -1 ? true : false;
        }
        return this.selectedWaitingForAgent.findIndex(s => (s.supplierName === order.supplierName && s.productGroup === order.productGroup)) !== -1 ? true : false;

    }

    private toggleSelectAll(pendingtype: string): void {
        this.isDeleted = false;
        if (pendingtype == "review") {
            if (this.selectedPendingReview.length == this.combinedOrdersPendingReview.length) {
                this.selectedPendingReview = [];
                this.allCombineOrdersDeSelected(pendingtype);
            } else {
                this.selectAll(pendingtype);
                this.allCombineOrdersSelected(pendingtype)
            }
            this.allCombineOrdersDeSelected('pending');
            this.allCombineOrdersDeSelected('waitingAgent');
        } else if(pendingtype == "pending") {
            if (this.selectedPendingAssign.length == this.combinedOrdersPendingAssignment.length) {
                this.selectedPendingAssign = [];
                this.allCombineOrdersDeSelected(pendingtype);
            } else {
                this.selectAll(pendingtype);
                this.allCombineOrdersSelected(pendingtype)
            }
            this.allCombineOrdersDeSelected('review');
            this.allCombineOrdersDeSelected('waitingAgent');
        }else{
            if (this.selectedWaitingForAgent.length == this.combinedOrdersWaitingForAgent.length) {
                this.selectedWaitingForAgent = [];
                this.allCombineOrdersDeSelected(pendingtype);
            } else {
                this.selectAll(pendingtype);
                this.allCombineOrdersSelected(pendingtype)
            }
            this.allCombineOrdersDeSelected('review');
            this.allCombineOrdersDeSelected('pending');
        }
    }

    private allCombineOrdersSelected(type:string){
        if(type === 'pending' || type === 'assign'){
            this.combinedOrdersPendingAssignment.forEach((o) => {
                o.isCombineOrderSelected = true;
            })
        }else if(type === 'review'){
            this.combinedOrdersPendingReview.forEach((o) => {
                o.isCombineOrderSelected = true;
            })
        }else{
            this.combinedOrdersWaitingForAgent.forEach((o) => {
                o.isCombineOrderSelected = true;
            })
        }

    }
    private allCombineOrdersDeSelected(type: string){
        if(type === 'pending' || type === 'assign'){
            this.combinedOrdersPendingAssignment.forEach((o) => {
                o.isCombineOrderSelected = false;
            })
            this.pendingAssignmentCount = 0;
            this.selectedPendingAssign = [];
        }else if(type === 'review'){
            this.combinedOrdersPendingReview.forEach((o) => {
                o.isCombineOrderSelected = false;
            })
            this.pendingReviewCount = 0;
            this.selectedPendingReview = [];
        }else{
            this.combinedOrdersWaitingForAgent.forEach((o) => {
                o.isCombineOrderSelected = false;
            })
            this.pendingWaitingForAgentCount = 0;
            this.selectedWaitingForAgent = [];
        }

    }

    private selectAll(pendingtype: string): void {
        if (pendingtype == "review") {
            const allCombineOrders = [...this.combinedOrdersPendingReview]
            this.selectedPendingReview = allCombineOrders;
            this.selectedPendingAssign = [];
            this.selectedWaitingForAgent = [];
        } else if(pendingtype == "pending") {
            const allCombineOrders = [...this.combinedOrdersPendingAssignment]
            this.selectedPendingAssign = allCombineOrders;
            this.selectedPendingReview = [];
            this.selectedWaitingForAgent = [];
        }else{
            const allCombineOrders = [...this.combinedOrdersWaitingForAgent]
            this.selectedWaitingForAgent = allCombineOrders;
            this.selectedPendingReview = [];
            this.selectedPendingAssign = [];
        }
    }

    private toggleOrderSelection(pendingtype: string, order: CombinedOrderLineModel): void {
        this.isDeleted = false;
        if (pendingtype == "review") {
            this.selectedPendingAssign = [];
            this.selectedWaitingForAgent = [];
            if(this.checkIfSelected(pendingtype, order)){
                // this.selectedPendingReview = this.selectedPendingReview.filter(s => (s.supplierName !== order.supplierName && s.productGroup !== order.productGroup));
                let pendingReviewIndex = this.selectedPendingReview.findIndex((el) => el.groupId === order.groupId)
                if(pendingReviewIndex !== -1) {
                    this.selectedPendingReview.splice(pendingReviewIndex, 1);
                }
            } else {
                this.selectedPendingReview.push(order);
            }
            if(order.isCombineOrderSelected){
                order.isCombineOrderSelected = false;
            }else{
                order.isCombineOrderSelected = true;
            }
            this.allCombineOrdersDeSelected('pending');
            this.allCombineOrdersDeSelected('waitingAgent');
        } else if(pendingtype == "pending") {
            this.selectedPendingReview = [];
            this.selectedWaitingForAgent = [];
            if (this.checkIfSelected(pendingtype, order)) {
                // this.selectedPendingAssign = this.selectedPendingAssign.filter(s => (s.supplierName !== order.supplierName && s.productGroup !== order.productGroup));
                let pendingAssignIndex = this.selectedPendingAssign.findIndex((el) => el.groupId === order.groupId)
                if(pendingAssignIndex !== -1) {
                    this.selectedPendingAssign.splice(pendingAssignIndex, 1);
                }
            } else {
                this.selectedPendingAssign.push(order);
            }
            if(order.isCombineOrderSelected){
                order.isCombineOrderSelected = false;
            }else{
                order.isCombineOrderSelected = true;
            }
            this.allCombineOrdersDeSelected('review');
            this.allCombineOrdersDeSelected('waitingAgent');
        }else{
            this.selectedPendingReview = [];
            this.selectedPendingAssign = [];
            if (this.checkIfSelected(pendingtype, order)) {
                let waitingForAgentIndex = this.selectedWaitingForAgent.findIndex((el) => el.groupId === order.groupId)
                if(waitingForAgentIndex !== -1) {
                    this.selectedWaitingForAgent.splice(waitingForAgentIndex, 1);
                }
            } else {
                this.selectedWaitingForAgent.push(order);
            }
            if(order.isCombineOrderSelected){
                order.isCombineOrderSelected = false;
            }else{
                order.isCombineOrderSelected = true;
            }
            this.allCombineOrdersDeSelected('review');
            this.allCombineOrdersDeSelected('pending');
        }
    }

    private get combinedOrdersPendingAssignment(): CombinedOrderLineModel[] {
        this.pendingAssignmentCount = 0;
        //TTD-2620, Agent orderlines changes, added condition
        let pendingAssign: CombinedOrderLineModel[] = [];
        if (this.companyType !== 5) {
            pendingAssign = this.combinedOrders.filter(combinedOrder => {
                if (!this.isSupplierConnected(combinedOrder)) {
                    this.pendingAssignmentCount+=combinedOrder.orderCount;
                    return combinedOrder;
                }
            });
            pendingAssign.sort((a, b) => {
                const nameA = a.supplierName.toLowerCase(); // ignore upper and lowercase
                const nameB = b.supplierName.toLowerCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }

                // names must be equal
                return 0;
            });
        }
        return pendingAssign;
    }

    private get combinedOrdersPendingReview(): CombinedOrderLineModel[] {
        if(this.isCreateSupplyModal){
            return this.currentOrders;
        }else{
            this.pendingReviewCount = 0;
            const reviewOrders = this.combinedOrders.filter(combinedOrder => {
                //TTD-2620, Agent orderlines changes,
                if(this.companyType === 5){
                    if (this.isSupplierConnected(combinedOrder)) {
                        this.pendingReviewCount+=combinedOrder.orderCount;
                        return combinedOrder;
                    }
                }else{
                    if (this.isSupplierConnected(combinedOrder) && (combinedOrder.agentCompanyID === undefined || combinedOrder.agentCompanyID === ''
                    || combinedOrder.agentCompanyID === Guid.Empty)) {
                        this.pendingReviewCount+=combinedOrder.orderCount;
                        return combinedOrder;
                    }
                }
            });
            if(this.companyType !== 5){
                reviewOrders.sort((a, b) => {
                    const nameA = a.supplierName.toLowerCase(); // ignore upper and lowercase
                    const nameB = b.supplierName.toLowerCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
                reviewOrders.forEach(order => {
                    const filterOrder = reviewOrders.filter(o => o.productGroup === order.productGroup && o.supplierId === order.supplierId);
                    if(filterOrder.length > 1){
                        for(let i=1; i<filterOrder.length; i++){
                            order.orderCount += filterOrder[i].orderCount;
                            order.orderDetails.push(...filterOrder[i].orderDetails);
                            order.orderIds.push(...filterOrder[i].orderIds);
                            const index = reviewOrders.findIndex(o => o.groupId === filterOrder[i].groupId);
                            reviewOrders.splice(index, 1);
                        }
                    }
                    order.orderDetails.forEach(o => {
                        o.toCompanyId = this.companyType!==5 ? order.supplierId : o.toCompanyId;
                        o.toCompanyName = order.supplierName;
                    });
                });
            }
            this.currentOrders = reviewOrders;
            return reviewOrders;
        }
    }

    private get combinedOrdersWaitingForAgent():CombinedOrderLineModel[] {
        let waitingForAgentOrders: CombinedOrderLineModel[] = [];
        if (this.companyType !== 5) {
            this.pendingWaitingForAgentCount = 0;
            waitingForAgentOrders = this.combinedOrders.filter(combinedOrder => {
                if (this.isSupplierConnected(combinedOrder) && combinedOrder.agentCompanyID && combinedOrder.agentCompanyID !== Guid.Empty) {
                    this.pendingWaitingForAgentCount+=combinedOrder.orderCount;
                    return combinedOrder;
                }
            });
            waitingForAgentOrders.sort((a, b) => {
                const nameA = a.supplierName.toLowerCase(); // ignore upper and lowercase
                const nameB = b.supplierName.toLowerCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                return -1;
                }
                if (nameA > nameB) {
                return 1;
                }
                return 0;
            });
        }
        return waitingForAgentOrders;
    }

    private get combinedOrdersData(): CombinedOrderLineModel[] {
        return this.combinedOrders;
    }

    private get allSelectedCombinedOrders(): CombinedOrderLineModel[]{
        if(this.selectedPendingAssign.length>1){
            this.pendingType = 'pending'
            return this.selectedPendingAssign;
        }else if(this.selectedPendingReview.length > 1){
            this.pendingType = 'review'
            return this.selectedPendingReview;
        }
        this.pendingType = 'waitingAgent'
        return this.selectedWaitingForAgent;
    }

    private get supplierDropdownData(): DropdownModel[] {
        const suppliersOrdered = lodash.orderBy(this.suppliers, ['name'], ['asc']);
        return suppliersOrdered.map(s => new DropdownModel(s.id, this.getSupplierDropdownText(s)));
    }

    private getSupplierDropdownText(supplierModel: OrderCompanyModel): string {
        if (supplierModel.isCompliant)
        {
            return `${supplierModel.companyName} (${supplierModel.country})`;
        }

        return `${supplierModel.companyName} (${this.$t('pages.home.pending_invite')})`;
    }

    private getKey(order: CombinedOrderLineModel, index: number): string {
        return `${order.supplierName}-${order.supplierCountry}-${index}`;
    }

    private setCombinedOrders(): void {
        const combinedOrders: CombinedOrderLineModel[] = [];
        this.orders.forEach(order => {
            // order.createdAt = DateTimeHelper.convertDateStringToDesiredFormat(order.createdAt, "YYYY-MM-DD", "D MMM YYYY"); Commenting this due to point highlighted in TTD-4738 point-3
            order.orderLines.forEach(line => {
                const supplierId = order.toCompanyId;
                const supplier: OrderCompanyModel[] = this.suppliers.filter(s=>s.id==supplierId);
                const condition: boolean = supplier!==undefined && this.suppliers.filter(s=>s.id==supplierId).length>0;

                const toCompany: OrderCompanyModel = new OrderCompanyModel(condition?supplier[0].id:'', condition?supplier[0].companyName:order.toCompanyName, condition?supplier[0].country:'', condition?supplier[0].isCompliant:false, condition?supplier[0].onBoarded:false);
                const existingOrderForSupplier = this.getCombinedOrderLineForSupplier(combinedOrders, toCompany, line, order.agentCompanyID,
                    order.agentCompanyUser.name);
                if (existingOrderForSupplier == null) {
                    const combinedOrder = this.createNewCombinedOrderLine(order, toCompany, line);
                    combinedOrders.push(combinedOrder);
                } else {
                    this.addOrderlineToCombinedOrderLine(existingOrderForSupplier, order);
                }
            });
        });
        this.combinedOrders = combinedOrders;
    }

    private createNewCombinedOrderLine(order: OrderLineDisplayModel, tier1Supplier: OrderCompanyModel, orderline: ConfirmOrderLineModel): CombinedOrderLineModel {
        const contact = order.fromCompanyUser;
        return {
            supplierId: tier1Supplier.id,
            supplierName: tier1Supplier.companyName,
            supplierCountry: tier1Supplier.country,
            orderCount: 1,
            orderIds: [order.orderId],
            userName: `${contact.firstName} ${contact.lastName}`,
            isCompliant: false,
            productGroup: ProductGroupHelper.getProductGroups('en').filter(f=> f.id == orderline.productGroup)[0].description,
            orderDetails: [order],
            //TTD-2620, Agent orderlines changes,
            toCompanyId: order.toCompanyId,
            toCompanyName: order.toCompanyName,
            groupId: uuidv4(),
            isOrdersConfirmed: false,
            isCombineOrderSelected: false,
            agentCompanyID: order.agentCompanyID
        };
    }

    private addOrderlineToCombinedOrderLine(combinedOrder: CombinedOrderLineModel, order: OrderLineDisplayModel): void {
        // Order lines must be combined per order
        if (combinedOrder.orderIds.indexOf(order.orderId) === -1) {
            combinedOrder.orderIds.push(order.orderId);
            combinedOrder.orderDetails.push(order);
        }

        combinedOrder.orderCount++;
    }

    private getCombinedOrderLineForSupplier(combinedOrders: CombinedOrderLineModel[], supplier: OrderCompanyModel, orderLine: ConfirmOrderLineModel,
        agentId:string, agentName:string): CombinedOrderLineModel | null {
        const order = combinedOrders
            .filter(combinedOrder =>
                combinedOrder.supplierName === supplier.companyName
                && combinedOrder.supplierCountry === supplier.country && ProductGroupHelper.getIdFromProductGroupName(combinedOrder.productGroup).id === orderLine.productGroup &&
                combinedOrder.orderDetails.some((ord) => ord.agentCompanyID === agentId && ord.agentCompanyUser.name === agentName))[0];
        return order;
    }

    private selectedBulkOrdersIds(ids:string[]){
        this.selectedBulkIds = ids
        if(this.selectedBulkIds.length > 0){
            const orders = this.combinedOrders.filter(combinedOrder => {
                if (this.isSupplierConnected(combinedOrder) && combinedOrder.orderDetails.filter((f) => this.selectedBulkIds.includes(f.orderId)).length > 0) {
                    return combinedOrder;
                }
            });
            this.resetPendingAssing(orders,ids)
        }
    }

    private resetPendingAssing(orders:CombinedOrderLineModel[], orderIds:string[]){
        this.selectedPendingAssign = [];
        const selectedCombinedOrders:CombinedOrderLineModel[] = [];
        orders.forEach(obj => {
        const selectedOrderIds = obj.orderIds.filter((id)=> orderIds.includes(id));
        const selectedOrdetails = obj.orderDetails.filter((ord)=> orderIds.includes(ord.orderId));
        const newObject = {
            supplierId: obj.supplierId,
            supplierName: obj.supplierName,
            supplierCountry: obj.supplierCountry,
            orderCount: obj.orderDetails.length,
            orderIds: selectedOrderIds,
            userName: obj.userName,
            //TTD-2620, Agent orderlines changes,
            toCompanyId: obj.toCompanyId,
            toCompanyName: obj.toCompanyName,
            isCompliant: obj.isCompliant,
            productGroup: obj.productGroup,
            orderDetails: selectedOrdetails,
            groupId: uuidv4(),
            isOrdersConfirmed: obj.isOrdersConfirmed,
            isCombineOrderSelected: obj.isCombineOrderSelected,
            agentCompanyID: obj.agentCompanyID
        };
        obj.supplierId = Guid.Empty;
        obj.supplierName = "";
        obj.supplierCountry = ""
        selectedCombinedOrders.push(newObject)
    })
       selectedCombinedOrders.forEach((order) => {
        orders.push(order);
       })
       this.combinedOrders = orders;
    }

    private reloadOrderLinesAgent(){
        this.selectedWaitingForAgent = [];
        this.reloadOrderLines();
    }

    private createSupplyModalOpen(val:boolean){
        this.isCreateSupplyModal = val;
    }

    @Emit()
    private confirmed(bulkOrders: CombinedOrderLineModel[], confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean, agentDetails:OrderCompanyModel): void {
    }

    @Emit()
    private deleteOrders(orderIds: string[]): void {
    }

    @Emit()
    private reloadOrderLines(): void {
    }

    private async suppliersChangedHandler() : Promise<void>{
        try {
            this.suppliersLoading = true;
            this.supplierChanged = true;
            await this.fetchSuppliers();
        }
        finally {
            this.suppliersLoading = false;
        }
    }

    private checkIsAllOrdersSelected(pendingType: string):boolean {
        if (pendingType == "review") {
            if (this.selectedPendingReview.length == this.combinedOrdersPendingReview.length) {
                return true;
            }
        } else if(pendingType == "pending") {
            if (this.selectedPendingAssign.length == this.combinedOrdersPendingAssignment.length) {
                return true;
            }
        } else {
            if(this.selectedWaitingForAgent.length == this.combinedOrdersWaitingForAgent.length){
                return true;
            }
        }
        return false;
    }

    @Watch("pendingOrders", { deep: true })
    private ordersChanged(): void {
        this.orders = this.pendingOrders;
        this.setCombinedOrders();
        this.selectedPendingReview = [];
    }

    @Watch('confirmedOrderIds', {deep: true})
    private updateCombineOrders(): void {
        if(this.confirmedOrderIds.length > 0){
            this.isCreateSupplyModal = false;
        }
        this.confirmedOrderIds.forEach(id => {
            this.selectedPendingAssign = [];
            this.selectedPendingReview = [];
            this.selectedWaitingForAgent = [];
            const index = this.combinedOrders.findIndex(o => o.groupId === id.groupId);
            if(index !== -1){
                if(this.combinedOrders[index].orderIds.length === id.orderIds.length){
                    this.combinedOrders.splice(index, 1);
                }
                else{
                    id.orderIds.forEach(o => {
                        const orderIdIndex = this.combinedOrders[index].orderIds.findIndex(id => id === o);
                        this.combinedOrders[index].orderIds.splice(orderIdIndex, 1);
                        const orderDetailIdIndex = this.combinedOrders[index].orderDetails.findIndex(id => id.orderId === o);
                        this.combinedOrders[index].orderDetails.splice(orderDetailIdIndex, 1);
                        if(this.combinedOrders[index].orderCount > 0){
                            this.combinedOrders[index].orderCount = this.combinedOrders[index].orderCount - 1;
                        }
                    });
                }
            }
        });
    }
}
