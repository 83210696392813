import { AddressModel } from "./addressModel";
export class InvitedCompaniesModel {
    public success!: boolean;
    public companies!: CompaniesModel[];
    public constructor() {
        this.success = false;
        this.companies = [];
    }
}

export class CompaniesModel{
    public admin!: AdminModel;
    public blockReason!: string;
    public companyID!: string;
    public companyType!: number;
    public isBlocked!: boolean;
    public kycStatus!: string;
    public name!: string;
    public regNumber!: string;
    public officeAddresses!: AddressModel[];
    public countryCode!: string | number;
}

export class AdminModel{
    public email!: string;
    public firstName!: string;
}