import { Vue, Component, Emit, Prop } from 'vue-property-decorator';

@Component({
})
export default class ProfileZoom extends Vue {

    private value: number = 1;

    private temp: number = 0;

    private onChange(){
      const asc: boolean = this.value>this.temp?true:false;
      this.change(this.value, asc);
      this.temp = this.value;
    }

  @Emit()
  private change(value: number, asc: boolean): void {}

}