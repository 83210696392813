export class InvitationModel {
    public toContactName!: string;
    public toContactEmail!: string;
    public companyRegistrationNumber!: string;
    public companyName!: string;
    public isClient!: boolean;
    public language!: string;
    public country!: string;
    public state!: string;
    public city!: string;
    public latitude!: number;
    public longitude!: number;
    public isTier1!: boolean;
    public isAgent!: boolean;
    public companyType!: number;
    public invitedPersonEmailID!: string;
    public MainContactFirstName!: string;
    public MainContactLastName!: string;

    public constructor() {
        this.toContactName = "";
        this.toContactEmail = "";
        this.companyRegistrationNumber = "";
        this.companyName = "";
        this.language = "";
        this.country = "";
        this.state = "";
        this.city = "";
        this.isTier1 = true;
        this.isAgent = false;
        this.companyType = 0;
        this.invitedPersonEmailID = "";
        this.MainContactFirstName = "";
        this.MainContactLastName = "";
    }

    public get isValid(): boolean
    {
       return !(this.MainContactFirstName.length < 2 || this.MainContactLastName.length < 2 ||
                this.toContactEmail.length < 2 ||
                (this.toContactEmail && !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.toContactEmail))) || //eslint-disable-line
                this.companyRegistrationNumber.length < 2 ||
                this.companyName.length < 2 || this.country.length < 2 || this.state.length < 2 || this.city.length < 2);

       }

}