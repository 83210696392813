import { InvitationClient } from '@/clients/invitationClient';
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';
import { CompanyModel, companyResponseModel } from '@/models/companyModel';
import { InvitationModel } from '@/models/invitationModel';
import { ClientBlockchain } from '@/clients/clientBlockchain';
import { AddSecondUserResponeModel, InvitedSupplierModel, InvitedSupplierResponseModel } from '@/models/invitedSupplierModel';
import { InvitedCompaniesModel } from '@/models/invitedCompaniesModel';

export class InvitationService {
    private readonly client: InvitationClient = new InvitationClient();
    private readonly clientBlockchain: ClientBlockchain = new ClientBlockchain();

    public async getClientByInvitationIdAsync(invitationId: string): Promise<CompanyModel> {
        const result = await this.client.getClientByInvitationIdAsync(invitationId);
        return result;
    }

    // Sprint 23 - TTD-3647, updated
    public async getCompanyFromNameAsync(str: string): Promise<InvitedCompaniesModel> {
        const result = await this.clientBlockchain.getCompanyFromNameAsync(str);
        return result;
    }

    // Sprint 23 - TTD-3647, updated
    public async getCompanyFromRegAsync(str: string): Promise<InvitedCompaniesModel> {
        const result = await this.clientBlockchain.getCompanyFromRegAsync(str);
        return result;
    }

    public async updateClientAsync(invitationId: string, client: CompanyModel): Promise<companyResponseModel> {
        const response = await this.client.updateClientAsync(invitationId, client);
        if (response.result === "success" && response.responseObject.companyName!==""){ // Only NoContent delivers no result
            localStorage.removeItem("invitationId");
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.client_update_succeeded').toString());
        }
        return response;
    }

    public async sendInvitationAsync(invitationModel: InvitationModel): Promise<InvitedSupplierResponseModel> {
        if (invitationModel.latitude == undefined || invitationModel.latitude == null) {
            invitationModel.latitude = 0;
        }
        if (invitationModel.longitude == undefined || invitationModel.longitude == null) {
            invitationModel.longitude = 0;
        }
        const result = await this.client.sendInvitation(invitationModel);
        return result;
    }

    public async addSecondUserAsync(invitedSupplierModel: InvitationModel, userId: string): Promise<AddSecondUserResponeModel> {
        return await this.client.addSecondUserAsync(invitedSupplierModel, userId);
    }
}