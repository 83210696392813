import { ApplicationConfig } from '@/config';
import { BaseClientBlockchain } from './baseClientBlockchain';
import { OrdersWithStepsModal } from '@/models/ordersWithStepsModel';
import { OrderLineStepResponseModel } from '@/models/orderLineStepDisplayModel';

export class ClientBlockchainV2 extends BaseClientBlockchain {
    private readonly endPoint: string = "v2/api";

    public constructor() {
        super(ApplicationConfig.endpoints.blockchainApi);
    }

    public async getOrdersAsync(clientId: string,bookmark: string, pageSize: number, brands: string): Promise<OrdersWithStepsModal> {
        const encodedBrands: string = encodeURIComponent(brands);
        return await this.get(`${this.endPoint}/client/${clientId}/dashboard?etdFromDate=2020-01-01&bookmark=${bookmark}&pageSize=${pageSize}&phase=0,1,2&status=0,1,2,3,4,5,6&brands=${encodedBrands}`);
    }

    // api to fetch steps for all orders
    public async getAllStepsAsync(clientId: string): Promise<OrderLineStepResponseModel> {
        return this.get<OrderLineStepResponseModel>(`${this.endPoint}/client/${clientId}/orderlineSteps/allStepsForClient`);
    }

    public async getOrdersForAgentAsync(clientId: string): Promise<OrdersWithStepsModal> {
        return await this.get(`${this.endPoint}/agent/${clientId}/agentDashboard`);
    }
}
