import { DashboardApiAuthenticator } from "@/authenticators/dashboardApiAuthenticator";
import { ApplicationConfig } from "@/config";
import { MapboxTokenModel } from "@/models/mapboxTokenModel";
import { BaseClient } from "./baseClient";
import { NotificationHelper } from "@/helpers/notificationHelper";
import i18n from "@/i18n";
import { HttpError } from "@/models/httpError";

export class GeolocationClient extends BaseClient {
    private readonly endPoint: string = "api/geolocation";
    private readonly mapboxEndPoint: string = "https://api.mapbox.com/geocoding/v5/mapbox.places";

    public constructor() {
        super(ApplicationConfig.endpoints.dashboardApi, new DashboardApiAuthenticator());
    }

    public async getMapboxToken(): Promise<MapboxTokenModel> {
        return await this.get<MapboxTokenModel>(`${this.endPoint}/mapbox/token`);
    }

    public async getCoordinates(token: string, query: string): Promise<any> {
        return await this.request(token, query);
    }

    private async request (token: string, query: string) {

        const url = `${this.mapboxEndPoint}/${query}.json?access_token=${token}`;

        const request: RequestInit = {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
            method: "GET"
        };

        const response = await fetch(url, request);
        switch (response.status) {
            case 403:
                return "Request not found";
            case 204:
                return "Unauthorized";
            default:
                return await this.getResultFromMapBoxResponse(response);
        }
    }

    private async getResultFromMapBoxResponse(response: Response): Promise<any> {
        let result = {
            code:"",
            message: i18n.t("errors.general").toString(),
            parameters:[]
        };

        try {
            result = await response.json();
        }
        catch {
            throw new HttpError(response.status, result.message);
        }

        if (!response.ok) {
            // check whether this is a localized error response or a custom error response
            if ("code" in result) {
                NotificationHelper.createErrorNotification(i18n.t(result.code, result.parameters).toString());
                throw new HttpError(response.status, result.message);
            }
        }
        return result;
    }
}