export class DropdownModel {
    public value!: string | number;
    public text!: string;
    public disabled?: boolean;
    public selected?: boolean;

    public constructor(value: string | number, text: string) {
        this.value = value;
        this.text = text;
        this.disabled = false;
        this.selected = false;
    }
}