import { Vue, Component } from "vue-property-decorator";
import { UserModel } from "@/models/userModel";
import { Guid } from "@/models/guid";


export class FootprintPermissionsHelper extends Vue {

    public static agentFootprintRole(companyType: number): boolean {
        if (companyType === 5) {
            return true;
        }
        return false;
    }

    public static getAgentFootprintRole(companyType: number, filledBy: string, userId: string): boolean {
        if (companyType === 5 && filledBy === userId) {
            return false;
        } else if(companyType === 5 && (filledBy === Guid.Empty || filledBy === '')) {
            return false;
        }
        return true;
    }
}