// Sprint-26 TTD-3068
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import { mapState } from "vuex";
import store from "@/store";
import { ActionTypes } from "@/store/actions/actions";
import { uniqBy } from "lodash";

// Components
import EnvironmentalData from '@/views/supplyChainPartners/components/environmentalData.vue';
import SocialData from '@/views/supplyChainPartners/components/socialData.vue'

// Services
import { SupplierService } from "@/services/supplierService";

// Modals
import { EsgAddressStatus, EsgEnvModel, EsgSocialModel, EsgStatus } from "@/models/esgModel";
import { UserModel } from "@/models/userModel";

//Helpers
import { SupplierModel } from "@/models/supplierModel";
import { AddressModel } from "@/models/addressModel";
import { CertificateModel } from "@/models/certificateModel";

@Component({
  components: {
    VuePdfApp,
    EnvironmentalData,
    SocialData
  },
  computed: mapState(["user", "userRole", "userPermissions", "companyType", "esgSocial", "esgEnv"]),
})
export default class EsgData extends Vue {

  @Prop()
  private tabIndex!: number;

  @Prop()
  private selectedSupplier!: SupplierModel;

  @Prop()
  private allAddressess!: AddressModel[];

  @Prop()
  private documents!: {
    social: CertificateModel[];
    sustainable: CertificateModel[];
  };

  private esgSocial!: EsgSocialModel[];
  private esgEnv!: EsgEnvModel[];
  private companyType!: number;

  private supplierService: SupplierService;

  private isLoading: boolean = false;
  private addresses: AddressModel[] = [];
  private activeAddress: AddressModel = new AddressModel();
  private activateFacility: number = 0;
  private activeAddEsgSocial: EsgSocialModel = new EsgSocialModel();
  private activeAddEsgEnv: EsgEnvModel = new EsgEnvModel();
  private esgAddressStatus: EsgAddressStatus[] = [];
  private user!: UserModel;

  public constructor() {
    super();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    this.activeTabData();
  }

  private async activeTabData(): Promise<void> {
    try{
      this.isLoading = true;
      const officeAddresses = this.selectedSupplier.officeAddress.filter(add => add.addressStatus !== 'DISABLED');
      this.addresses.push(...officeAddresses);
      if(this.selectedSupplier.factoryAddressDiffers){
        const factoryAddresses = this.selectedSupplier.factoryAddresses.filter(add => add.addressStatus !== 'DISABLED');
        this.addresses.push(...factoryAddresses);
      }
      this.addresses = uniqBy(this.addresses, 'id');
      this.activeAddress = this.addresses[this.activateFacility];
      if((this.tabIndex === 3 && this.companyType !== 5) || (this.tabIndex === 2 && this.companyType === 5)){
        if(this.esgSocial.length == 0){
          await store.dispatch(ActionTypes.SET_ESG_SOCIAL_DATA, this.selectedSupplier.id);
        }
        this.activeAddSocialData();
      }
      if((this.tabIndex === 4 && this.companyType !== 5) || (this.tabIndex === 3 && this.companyType === 5)){
        if(this.esgEnv.length == 0){
          await store.dispatch(ActionTypes.SET_ESG_ENV_DATA, this.selectedSupplier.id);
        }
        this.activeAddEnvData();
      }
    }
    finally{
      this.isLoading = false;
    }
  }

  private get notStartedStatus(): string {
    return '';
  }

  private get inProgressStatus(): string {
    return EsgStatus.INPROGRESS;
  }

  private get completedStatus(): string {
    return EsgStatus.COMPLETED;
  }

  private async showEsgData(index:number): Promise<void>{
    if((this.tabIndex === 3 && this.companyType !== 5) || (this.tabIndex === 2 && this.companyType === 5)){
      await this.getEsgSocialOnFacility(index);
      this.activateFacility = index;
      this.activeAddress = this.addresses[index];
      this.activeAddSocialData();
    }
    if((this.tabIndex === 4 && this.companyType !== 5) || (this.tabIndex === 3 && this.companyType === 5)){
      await this.getEsgEnvOnFacility(index);
      this.activateFacility = index;
      this.activeAddress = this.addresses[index];
      this.activeAddEnvData();
    }
  }

  private async getEsgSocialOnFacility(index: number): Promise<void> {
    this.isLoading = true;
    await this.supplierService.getSocialEsgDataOnFacilityAsync(this.addresses[index].id);
    this.isLoading = false;
  }

  private async getEsgEnvOnFacility(index: number): Promise<void> {
    this.isLoading = true;
    await this.supplierService.getEnvEsgDataOnFacilityAsync(this.addresses[index].id);
    this.isLoading = false;
  }

  private addressStatus(addId: string): string {
    const index = this.esgAddressStatus.findIndex(add => add.addressID === addId);
    return index !== -1 ? this.esgAddressStatus[index].status : this.notStartedStatus;
  }

  private socialStatus(addId: string, status: string): void {
    const index = this.esgAddressStatus.findIndex(add => add.addressID === addId);
    this.esgAddressStatus[index].status = status;
  }

  private activeAddSocialData(): void {
    const socialData = this.esgSocial.find(esg => esg.addressID === this.activeAddress.id);
    if(socialData !== undefined){
      this.activeAddEsgSocial = socialData;
      this.activeAddEsgSocial.socialAuditExpanded = false;
      this.activeAddEsgSocial.healthAndSafetyExpanded = false;
      this.activeAddEsgSocial.childForcedLabourExpanded = false;
      this.activeAddEsgSocial.livingWageWorkingHoursExpanded = false;
      this.activeAddEsgSocial.workingConditionsExpanded = false;
      this.activeAddEsgSocial.discriminationExpanded = false;
      this.activeAddEsgSocial.sexualHarasmentExpanded = false;
      this.activeAddEsgSocial.grievanceMechanismExpanded = false;
      this.activeAddEsgSocial.diversityAndEqualOptyExpanded = false;
      if(this.activeAddEsgSocial.reminderSentDetails){
        const sentReminderDate = this.activeAddEsgSocial.reminderSentDetails.filter(f => f.reminderSentBy === this.user.companyId)[0];
        if(sentReminderDate){
            this.activeAddEsgSocial.reminderSentDate = sentReminderDate.reminderSentAt;
        }else{
          this.activeAddEsgSocial.reminderSentDate = '';
        }
      }
    } else {
      this.activeAddEsgSocial = new EsgSocialModel();
    }
    this.esgAddressStatus = [];
    this.addresses.forEach(add => {
      const addSocialStatus = this.esgSocial.find(esg => esg.addressID === add.id);
      if(addSocialStatus !== undefined){
        this.esgAddressStatus.push(new EsgAddressStatus(add.id, addSocialStatus.status));
      } else {
        this.esgAddressStatus.push(new EsgAddressStatus(add.id, this.notStartedStatus));
      }
    });
  }

  private activeAddEnvData(): void {
    const envData = this.esgEnv.find(esg => esg.addressID === this.activeAddress.id);
    if(envData !== undefined){
      this.activeAddEsgEnv = envData;
      this.activeAddEsgEnv.energyUseExpanded = false;
      this.activeAddEsgEnv.waterUseExpanded = false;
      this.activeAddEsgEnv.wastageExpanded = false;
      this.activeAddEsgEnv.circularEconomyExpanded = false;
      this.activeAddEsgEnv.carbonEmissionExpanded = false;
      if(this.activeAddEsgEnv.reminderSentDetails){
        const sentReminderDate = this.activeAddEsgEnv.reminderSentDetails.filter(f => f.reminderSentBy === this.user.companyId)[0];
        if(sentReminderDate){
          this.activeAddEsgEnv.reminderSentDate = sentReminderDate.reminderSentAt;
        }else{
          this.activeAddEsgEnv.reminderSentDate = '';
        }
      }
    } else {
      this.activeAddEsgEnv = new EsgEnvModel();
    }
    this.esgAddressStatus = [];
    this.addresses.forEach(add => {
      const addEnvStatus = this.esgEnv.find(esg => esg.addressID === add.id);
      if(addEnvStatus !== undefined){
        this.esgAddressStatus.push(new EsgAddressStatus(add.id, addEnvStatus.status));
      } else {
        this.esgAddressStatus.push(new EsgAddressStatus(add.id, this.notStartedStatus));
      }
    });
  }

  @Watch('tabIndex', { deep: true })
  private onTabIndexChanged(): void {
    this.activeTabData();
  }

}
