import { BaseClient } from "./baseClient";
import { DashboardApiAuthenticator } from "../authenticators/dashboardApiAuthenticator";
import { ApplicationConfig } from "../config";
import { ClientStepModel } from "@/models/clientStepModel";

export class ClientStep extends BaseClient {
    private readonly endPoint: string = "api/supplychainstep";

    public constructor() {
        super(ApplicationConfig.endpoints.dashboardApi, new DashboardApiAuthenticator());
    }

    public async getClientStepsAsync(): Promise<ClientStepModel[]> {
        return await this.get<ClientStepModel[]>(`${this.endPoint}`);
    }
}