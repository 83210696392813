import * as msal from "@azure/msal-browser";
import { ApplicationConfig, textracerPolicies } from "../config";
import { ServerError } from "@azure/msal-common";
import MsalFactory from '@/factories/msalFactory';
import { RedirectRequest } from "@azure/msal-browser";
import { AppInsightsHelper } from "@/helpers/appInsightsHelper";

const RESET_PASSWORD_CODE = "AADB2C90118";

export class MsalAuthenticator {
    protected async signInAsync(): Promise<msal.AccountInfo | null> {
        const account = await this.checkCacheForExisingAccountAsync();
        if (account == null) {
            const request = this.getRequestPolicy();
            request.scopes = ApplicationConfig.scopes.dashboardApi;
            MsalFactory.getMsalContext().loginRedirect(request);
            return null;
        } else {
            return account;
        }
    }

    protected async acquireTokenAsync(scopes: string[]): Promise<string | null> {
        const account = this.getLoggedInAccount();
        if (account == null) {
            return null;
        }

        try {
            const silentRequest = {
                account: account,
                scopes: scopes,
                forceRefresh: false
            };

            const result = await MsalFactory.getMsalContext().acquireTokenSilent(silentRequest);
            if (!result.accessToken) {
                throw Error();
            }

            return result.accessToken;
        }
        catch {
            const redirectRequest = {
                account: account,
                scopes: scopes,
                forceRefresh: false
            };
            // todo: figure out how to handle this callback. Workaround = request all desired scopes while loggin in.
            MsalFactory.getMsalContext().acquireTokenRedirect(redirectRequest);
            return null;
        }
    }

    private tryParseInvitationId(msalState: string | undefined): void {
        if (!msalState) {
            return;
        }

        const params = new URLSearchParams(msalState);
        const inviteId = params.get("#invitation");
        if (inviteId) {
            localStorage.setItem("invitationId", inviteId);
        }
    }

    private getRequestPolicy(): RedirectRequest {

        const params = new URLSearchParams(window.location.hash);
        const invitationId = params.get("#invitation");
        const b2cPolicy = params.get("p");

        let desiredPolicy = textracerPolicies.authorities.signUpSignIn;
        if (invitationId && b2cPolicy) {
            desiredPolicy = textracerPolicies.authorities.invitation;
            desiredPolicy.authority += b2cPolicy;
            desiredPolicy.state = `#invitation=${invitationId}&p=${b2cPolicy}`;
        }

        return desiredPolicy;
    }

    private async checkCacheForExisingAccountAsync(): Promise<msal.AccountInfo | null> {
        try {
            const response = await MsalFactory.getMsalContext().handleRedirectPromise();
            if (response !== null) {
                this.tryParseInvitationId(response.state);
                return response.account;
            } else {
                const loggedInAccount = this.getLoggedInAccount();
                // todo: check token expiration, return null if token is expired. (get exp claim from idToken)
                return loggedInAccount;
            }
        } catch (err) {
            const error = err as ServerError;
            // More about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
            if (error.errorMessage.indexOf(RESET_PASSWORD_CODE) > -1) {
                try {
                    MsalFactory.getMsalContext().loginRedirect(textracerPolicies.authorities.forgotPassword);
                } catch (err) {
                    // console.log(err);
                }
            }
            else {
                // console.log("Unexpected error occurred");
                // console.log(err);
            }

            return null;
        }
    }

    private getLoggedInAccount(): msal.AccountInfo | null {
        /**
        * See here for more info on account retrieval:
        * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
        */
        const allAccounts = MsalFactory.getMsalContext().getAllAccounts();
        if (allAccounts.length == 1) {
            return allAccounts[0];
        }
        else if (allAccounts.length > 1) {
            AppInsightsHelper.trackError(
                new Error("Multipe logged in accounts detected. Logging out user"));
            MsalFactory.logoutAllAccounts(null);
        }

        return null;
    }
}